require('ember-svg').registerHelpers()
require('./helpers/br')
require('./helpers/date')
require('./helpers/datetime')
require('./helpers/email-link')
require('./helpers/linkify')
require('./helpers/money')
require('./helpers/number')
require('./helpers/render-component')
require('./helpers/constraints')
require('./helpers/if-eq')
require('./helpers/if-t')
require('./helpers/if-authorized')
require('./helpers/if-advanced-user')

Ember.Handlebars.helper('bb-table', require('./ui/list/table/table-view'))
Ember.Handlebars.helper('bb-table-header', require('./ui/list/table/header-view'))
