var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['bank-imports-upload-progress'],

    layout: Window.proto().layout,

    template: require('../templates/components/bank-imports-upload-progress'),

    closable: false,

    height: 362,

    importedLines: 0,

    totalLines: 0,

    abortInProgress: false,

    abortProgress: null,

    progress: function() {
        if (this.get('abortInProgress', true)) {
            return this.get('abortProgress')
        } else {
            return this.get('uploadProgress')
        }
    }.property('uploadProgress', 'abortProgress'),

    uploadProgress: function() {
        var totalLines = this.get('totalLines')
        var importedLines = this.get('importedLines')

        if (!totalLines) {
            return 0
        }

        return importedLines / totalLines
    }.property('importedLines', 'totalLines'),

    doAbortProgress: function(abortProgress, decimalStep) {
        var self = this

        if (!this.get('abortInProgress')) {
            this.set('abortInProgress', true)
        }

        return new Ember.RSVP.Promise(function(resolve) {
            setTimeout(function() {
                if (abortProgress > 0) {
                    var totalLineBatches
                    if (!decimalStep) {
                        totalLineBatches = Math.ceil(self.get('importedLines') / 10)
                        decimalStep = abortProgress / totalLineBatches
                    }

                    abortProgress -= decimalStep
                    self.set('abortProgress', abortProgress)

                    var linesMinusTen = self.get('importedLines') - 10
                    var linesRemaining = linesMinusTen > 0 ? linesMinusTen : 0
                    self.set('importedLines', linesRemaining)

                    resolve(self.doAbortProgress(abortProgress, decimalStep))
                } else {
                    self.set('abortInProgress', false)
                    resolve()
                }
            }, 50)
        })
    },

    actions: {
        abort: function() {
            this.trigger('importAborted')
        }
    }
})
