var t = require('i18n').t
var SubjectWrapper = require('../utils/subject-wrapper')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/possible-subjects',

    classNames: ['bank-sync-v2-possible-subjects'],

    // state
    organization: null,
    account: null,
    subjects: Em.A(),
    deleteMode: false,
    searchMode: false,

    // FILTERING
    // query params
    query: null,
    period: null,
    sortProperty: null,
    invoices: null,
    bills: null,
    postings: null,
    paid: null,
    unpaid: null,
    draft: null,

    // local filters
    search: null,
    typeFilters: Em.Object.create({
        invoices: Em.Object.create({
            label: t('invoices'),
            value: true,
            enabled: true
        }),
        bills: Em.Object.create({
            label: t('bills'),
            value: true,
            enabled: true
        }),
        postings: Em.Object.create({
            label: t('transactions'),
            value: true,
            enabled: true
        })
    }),
    stateFilters: Em.Object.create({
        paid: Em.Object.create({
            label: 'Betalte',
            value: true,
            enabled: true
        }),
        unpaid: Em.Object.create({
            label: 'Ubetalte',
            value: true,
            enabled: true
        }),
        draft: Em.Object.create({
            label: 'Kladder',
            value: true,
            enabled: true
        })
    }),

    // FETCHING DATA
    isLoading: true,

    load: function() {
        var self = this
        var subjectsToLoad = []

        this.set('isLoading', true)

        if (this.get('invoices')) {
            subjectsToLoad.push(this.loadInvoices())
        }

        if (this.get('bills')) {
            subjectsToLoad.push(this.loadBills())
        }

        if (this.get('postings')) {
            subjectsToLoad.push(this.loadPostings())
        }

        subjectsToLoad.push(this.loadSalesTaxReturns())
        subjectsToLoad.push(this.loadFilteredSubjectReferences())

        Em.RSVP.all(subjectsToLoad).then(function(arrayOfSubjects) {
            var filteredReferences = arrayOfSubjects.pop()
            var allSubjects = Em.A()

            arrayOfSubjects.forEach(function(subjects) {
                subjects.forEach(function(subject) {
                    if (!filteredReferences[subject.get('reference')]) {
                        subject = SubjectWrapper.create({ subject: subject })
                        allSubjects.pushObject(subject)
                    }
                })

                if (typeof subjects.destroy === 'function') {
                    subjects.destroy()
                }
            })

            // Filter subjects
            allSubjects = allSubjects.filter(function(subjectWrapper) {
                var subject = subjectWrapper.get('subject')

                if (subject instanceof Billy.Posting) {
                    var originator = subject.get('transaction.originator')

                    if (originator instanceof Billy.ConversionBalance) {
                        return false
                    }
                }

                if (!subject.get('amount')) {
                    return false
                }

                return true
            })

            /// Sort subjects based on either sideAmount or entryDate
            if (self.get('sortProperty') === 'sideAmount') {
                allSubjects = allSubjects.sortBy('sideAmount')
            } else {
                allSubjects = allSubjects.sort(function(a, b) {
                    return a.get('entryDate').diff(b.get('entryDate'))
                })
            }

            self.set('subjects', allSubjects)
                .set('isLoading', false)
        }, function() {
            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_LOAD, t('util.request.default_error'))
            self.set('isLoading', false)
        })
    },

    loadInvoices: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'balance'
            sortDirection = 'ASC'
        } else {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        }
        var query = {
            organizationId: this.get('account.organization.id'),
            currencyId: this.get('account.currency.id'),
            state: 'approved',
            isPaid: false,
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }
        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        }
        if (this.get('query')) {
            query.q = this.get('query')
        }
        return Billy.Invoice.find(query).promise
    },

    loadBills: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'balance'
            sortDirection = 'DESC'
        } else {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        }
        var query = {
            organizationId: this.get('account.organization.id'),
            currencyId: this.get('account.currency.id'),
            state: ['approved', 'draft'],
            isPaid: false,
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }
        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        }
        if (this.get('query')) {
            query.q = this.get('query')
        }
        return Billy.Bill.find(query).promise
    },

    loadSalesTaxReturns: function() {
        // Only load sales tax returns when the account is the same currency as the organizations
        // base currency, and if we're not searching for anything specific
        // Choosing to exclude sales tax returns from specific searches, since it doesn't actually
        // contain any text in its data representation
        // If we want to introduce sales tax returns in search results later on, we should do this
        // by filtering client-side
        if (this.get('account.currency') === this.get('organization.baseCurrency') && !this.get('query')) {
            var query = {
                organizationId: this.get('account.organization.id'),
                isSettled: true,
                isPaid: false
            }
            if (this.get('entryDatePeriod.value')) {
                query.startDatePeriod = this.get('entryDatePeriod.value')
                query.endDatePeriod = this.get('entryDatePeriod.value')
            }
            return Billy.SalesTaxReturn.find(query).promise
        } else {
            return Em.RSVP.resolve([])
        }
    },

    loadPostings: function() {
        var sortProperty
        var sortDirection
        if (this.get('sortProperty') === 'sideAmount') {
            sortProperty = 'sideAmount'
            sortDirection = 'ASC'
        } else {
            sortProperty = 'entryDate'
            sortDirection = 'ASC'
        }
        var query = {
            accountId: this.get('account.id'),
            isBankMatched: false,
            isVoided: false,
            include: 'posting.transaction',
            sortProperty: sortProperty,
            sortDirection: sortDirection
        }

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')

        if (this.get('entryDatePeriod.value')) {
            query.entryDatePeriod = this.get('entryDatePeriod.value')
        } else if (bankSyncStartDate) {
            query.minEntryDate = bankSyncStartDate.format('YYYY-MM-DD')
        }

        if (this.get('query')) {
            query.q = this.get('query')
        }

        return Billy.Posting.find(query).promise
    },

    loadFilteredSubjectReferences: function() {
        var self = this
        var api = this.container.lookup('api:billy')
        return api.get('/bankLineMatches?include=bankLineMatch.subjectAssociations:embed&isApproved=false&accountId=' + self.get('account.id'))
            .then(function(payload) {
                return payload.bankLineMatches.reduce(function(result, match) {
                    match.subjectAssociations.forEach(function(association) {
                        result[association.subjectReference] = true
                    })
                    return result
                }, {})
            })
    },

    // LIFECYCLE HOOKS
    initFn: function() {
        // init filters
        this.set('search', this.get('query'))
        this.set('searchMode', !!this.get('search'))

        // init filters
        this.set('typeFilters.invoices.value', this.get('invoices'))
        this.set('typeFilters.bills.value', this.get('bills'))
        this.set('typeFilters.postings.value', this.get('postings'))
        this.set('stateFilters.paid.value', this.get('paid'))
        this.set('stateFilters.unpaid.value', this.get('unpaid'))
        this.set('stateFilters.draft.value', this.get('draft'))

        // Set load debounce function
        // this.set('loadDebounced', _.debounce(this.load.bind(this), 1000))

        // Trigger initial load
        this.load()
    }.on('init'),

    updateSort: function() {
        this.load()
    }.observes('sortProperty'),

    updateQuery: function() {
        if (this.get('search') !== this.get('query')) {
            this.set('query', this.get('search') || null)
            this.load()
        }
    }.observes('search'),

    updateFilters: function() {
        this.set('invoices', this.get('typeFilters.invoices.value'))
        this.set('bills', this.get('typeFilters.bills.value'))
        this.set('postings', this.get('typeFilters.postings.value'))
        this.set('paid', this.get('stateFilters.paid.value'))
        this.set('unpaid', this.get('stateFilters.unpaid.value'))
        this.set('draft', this.get('stateFilters.draft.value'))
        this.load()
    }.observes('typeFilters.invoices.value', 'typeFilters.bills.value', 'typeFilters.postings.value', 'stateFilters.paid.value', 'stateFilters.unpaid.value', 'stateFilters.draft.value'),

    actions: {
        toggleSearchMode: function() {
            this.set('searchMode', !this.get('searchMode'))
        }
    }
})
