var t = require('i18n').t

module.exports = require('./bank-sync-v2-abstract-subject-item').extend({
    subject: Em.computed.alias('model'),

    label: function() {
        var model = this.get('model')
        if (!(model instanceof Billy.Posting)) {
            return t('bank_sync.unpaid_label')
        }
    }.property('model')
})
