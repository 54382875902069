var t = require('i18n').t
var popupWindow = require('../utils/popup-window')
var ListModelController = require('billy-webapp/ui/list/model-controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var batmask = require('batmask')

module.exports = ListModelController.extend({
    needs: ['user'],

    api: Em.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    invoices: [],

    selection: [],

    query: function() {
        return {
            organization: this.get('organization')
        }
    }.property('organization'),

    init: function() {
        batmask.mask()
        this.fetchBillingData()
    }.on('init'),

    type: Billy.OrganizationInvoice,

    records: function() {
        return this.get('invoices').map(function(invoice) {
            var invoiceItem = Billy.OrganizationInvoice.createRecord(invoice)
            invoiceItem.set('currency', (invoice.currencyId ? invoice.currencyId : 'DKK'))
            return invoiceItem
        }).sort(function(a, b) {
            return new Date(b.get('createdTime')) - new Date(a.get('createdTime'))
        })
    }.property('invoices'),

    sortProperty: 'createdTime',

    sortDirection: 'DESC',

    click: function(invoice) {
        if (invoice.get('printUrl')) {
            popupWindow.open(invoice.get('printUrl'))
            return
        }
        this.fetchInvoicePdf(invoice.get('id').toString())
    },

    fetchInvoicePdf: function(invoiceId) {
        var url = '/integrations/billing/' + this.get('organization.id') + '/invoices/' + invoiceId

        batmask.mask()
        this.get('api').request('GET', url)
            .catch(function(e) {
                this.container.lookup('util:notification').notify('invoice-fetch-error', t('error_route.title'), 'error')
            }.bind(this))
            .then(function(resp) {
                var win = window.open()
                win.document.write('<iframe width="100%" height="100%" title="Invoice" src="data:application/pdf;base64, ' +
                resp.pdf + '"></iframe>')
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    fetchBillingData: function() {
        var url = '/integrations/billing/' + this.get('organization.id') + '/invoices'

        this.get('api').request('GET', url)
            .catch(function(e) {
                this.container.lookup('util:notification').notify('invoice-fetch-error', t('error_route.title'), 'error')
            }.bind(this))
            .then(function(data) {
                this.set('invoices', data)
            }.bind(this))
            .finally(function() {
                batmask.unmask()
            })
    },

    itemControllerClass: ListItemController.extend({
        grossAmountWithCurrency: function() {
            return Billy.money(this.get('grossAmount'), this.get('currency'))
        }.property('grossAmount', 'currency')
    }),

    columns: [
        DateColumn.create({
            header: t('organization_subscription.invoices.date'),
            name: 'createdTime',
            width: 150
        }),
        Column.create({
            header: t('organization_subscription.invoices.invoice_no'),
            name: 'invoiceNo',
            width: 150
        }),
        Column.create({
            header: t('organization_subscription.invoices.state'),
            name: 'stateText',
            width: 150
        }),
        Column.create({
            header: t('total_incl_vat'),
            align: 'right',
            name: 'grossAmountWithCurrency',
            width: 150
        })
    ]
})
