var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    requiredText: 'ready',

    confirmationText: null,

    isButtonDisabled: function() {
        return !this.get('canProceed')
    }.property('canProceed'),

    canProceed: function() {
        return this.get('confirmationText') === this.get('requiredText')
    }.property('confirmationText', 'requiredText'),

    actions: {
        didClickSubmit: function() {
            var self = this
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { bookkeeper_submit_for_processing: true })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
