var CustomEvent = require('../constants/customEvent')

module.exports = Em.Component.extend({
    organizationUpdater: Ember.inject.service(),

    updateOrganizationModelBound: null,

    updateOrganizationSettingsModelBound: null,

    updateOrganizationModel: function(event) {
        var organizationUpdater = this.get('organizationUpdater')
        var updateOrganizationData = event.detail

        if (updateOrganizationData) {
            organizationUpdater.updateOrganization(updateOrganizationData)
        }
    },

    updateOrganizationSettingsModel: function(event) {
        var organizationUpdater = this.get('organizationUpdater')
        var updateOrganizationSettingsData = event.detail

        if (updateOrganizationSettingsData) {
            organizationUpdater.updateOrganizationSettings(updateOrganizationSettingsData)
        }
    },

    initEvents: function() {
        var updateOrganizationModelFn = this.updateOrganizationModel.bind(this)
        this.set('updateOrganizationModelBound', updateOrganizationModelFn)

        var updateOrganizationSettingsModelFn = this.updateOrganizationSettingsModel.bind(this)
        this.set('updateOrganizationSettingsModelBound', updateOrganizationSettingsModelFn)

        window.addEventListener(CustomEvent.OrganizationUpdated, this.get('updateOrganizationModelBound'))
        window.addEventListener(CustomEvent.OrganizationSettingsUpdatedInReactApp, this.get('updateOrganizationSettingsModelBound'))
    }.on('didInsertElement'),

    destroyEvents: function() {
        window.removeEventListener(CustomEvent.OrganizationUpdated, this.get('updateOrganizationModelBound'))
        window.removeEventListener(CustomEvent.OrganizationSettingsUpdatedInReactApp, this.get('updateOrganizationSettingsModelBound'))
    }.on('willDestroyElement')
})
