var i18n = require('i18n')

module.exports = Em.Component.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    title: i18n.tProperty('title'),

    text: i18n.tProperty('text'),

    template: require('../templates/components/premium-feature'),

    actions: {
        upgrade: function() {
            this.container.lookup('router:main').transitionTo('organization_subscription')
        }
    }
})
