module.exports = Em.Controller.extend({
    needs: ['organization'],

    organization: Em.computed.alias('controllers.organization.model'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var options = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, options)
        },

        createContact: function() {
            var contactEditor = this.container.lookup('component:contactEditor')
            var organization = this.get('organization')
            contactEditor.set('content', Billy.Contact.createRecord({
                organization: organization,
                country: organization.get('country'),
                type: 'company'
            }))
            contactEditor.show()
        }
    }
})
