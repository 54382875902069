var qs = require('qs')
var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var ListAction = require('billy-webapp/ui/list/action')

module.exports = ListController.extend({
    needs: ['account', 'transaction', 'organization'],

    transaction: Em.computed.alias('controllers.transaction.model'),

    postings: Em.computed.alias('transaction.postings'),

    postingsSorting: ['priority'],

    organization: Em.computed.oneWay('controllers.organization.model'),

    records: Ember.computed.sort('postings', 'postingsSorting'),

    accountPeriod: Em.computed.alias('controllers.account.period'),

    isLazy: false,

    itemControllerClass: ListItemController.extend({
        transactionAmount: function() {
            return this.get('amount')
        }.property('amount'),

        isDebitSide: function() {
            return this.get('side.isDebit')
        }.property('side'),

        isCreditSide: function() {
            return this.get('side.isCredit')
        }.property('side')
    }),

    columns: function() {
        var self = this
        return [
            Column.create({
                name: 'account.accountNo',
                header: t('bill.index.account_number'),
                width: 100,
                align: 'left',
                paddingRight: 10
            }),
            Column.create({
                name: 'account.name',
                header: t('transactions.show.account'),
                flex: 1
            }),
            Column.create({
                name: 'text',
                header: t('transactions.show.description'),
                flex: 1
            }),
            Column.createWithMixins({
                name: 'baseAmount',
                header: function() {
                    return t('transactions.show.baseAmount', { currency: self.get('organization').get('baseCurrency.id') })
                }.property('organization'),
                align: 'right',
                width: 120,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if isDebitSide}}' +
                            '{{money baseAmount}}' +
                        '{{/if}}' +
                        '{{#if isCreditSide}}' +
                            '-{{money baseAmount}}' +
                        '{{/if}}'
                    )
                })
            }),
            MoneyColumn.create({
                name: 'debit',
                header: t('transactions.show.debit'),
                width: 120,
                align: 'right',
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if isDebitSide}}' +
                            '{{money transactionAmount currencyBinding="currency"}}' +
                        '{{/if}}'
                    )
                })
            }),
            MoneyColumn.create({
                name: 'credit',
                header: t('transactions.show.credit'),
                width: 120,
                align: 'right',
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if isCreditSide}}' +
                            '{{money transactionAmount currencyBinding="currency"}}' +
                        '{{/if}}'
                    )
                })
            })
        ]
    }.property('organization'),

    listActions: [
        ListAction.create({
            icon: 'icons/icon-arrow-out-of-square',
            tip: t('transaction.open_account'),
            click: 'openAccount',
            dataCy: 'open-account-icon'
        })
    ],

    totals: function() {
        var totals = []
        var currencyTotals = {}
        var postings = this.get('postings')
        postings.forEach(function(posting) {
            var currencyId = posting.get('currency.id')
            if (!currencyTotals[currencyId]) {
                currencyTotals[currencyId] = {
                    debit: 0,
                    credit: 0
                }
            }
            currencyTotals[currencyId][posting.get('side.value')] += posting.get('amount')
        })
        _.each(currencyTotals, function(total, currencyId) {
            totals.push({
                itemClass: 'bold dark-border-under',
                label: t('transactions.show.total_with_currency', { currency: currencyId }),
                values: {
                    debit: Billy.money(total.debit, currencyId),
                    credit: Billy.money(total.credit, currencyId)
                }
            })
        })
        return totals
    }.property('postings.@each.amount', 'postings.@each.side', 'postings.@each.currency'),

    addDarkBorderUnder: function(totals) {
        totals[totals.length - 1].itemClass = (totals[totals.length - 1].itemClass) + ' dark-border-under'
    },

    actions: {
        openAccount: function(posting) {
            var accountPeriod = this.get('accountPeriod.value')
            var queryString = accountPeriod ? { period: accountPeriod } : {}
            var params = qs.stringify(queryString)

            var currentPath = window.location.pathname
            var accountPath = currentPath.replace(/transactions.*$/, 'accounts/' + posting.get('account.id') + '?' + params)
            window.open(accountPath, '_blank')
        }

    }
})
