var Cookies = require('js-cookie')
var qs = require('qs')
var t = require('i18n').t
var batmask = require('batmask')
var cookieKey = require('../constants/cookieKey')
var tracking = require('../utils/tracking')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    intercom: Ember.inject.service(),

    templates: Ember.inject.service(),

    segment: Ember.inject.service(),

    preselectedTemplate: null,

    deletedLines: function() {
        return []
    }.property(),

    title: function() {
        return (
            (this.get('isNew') ? t('create') : t('edit')) +
            ' ' +
            this.get('localizedType')
        )
    }.property('isNew', 'localizedType'),

    attachmentsChangeset: function() {
        var changeset = this.container.lookup('util:v2-attachments-changeset', {
            singleton: false
        })
        changeset.set('trackingContext', 'create_quote')
        changeset.set('resource', this.get('model'))
        return changeset
    }.property('model'),

    setPreselectedTemplateOption: function() {
        var invoiceTemplateId = this.get('model.templateId')

        if (invoiceTemplateId) {
            this.set('preselectedTemplate', invoiceTemplateId)
            return
        }

        var templateOptions = this.get('templatesOptions')

        if (templateOptions) {
            this.set('preselectedTemplate', templateOptions[0].value)
        }
    }.observes('model'),

    validate: function() {
        if (this.get('attachmentsChangeset.isUploading')) {
            this.container
                .lookup('util:notification')
                .notify(NOTIFICATION_KEYS.STILL_UPLOADING_ATTACHMENTS, t('still_uploading_attachments'), NOTIFICATION_VARIANT.INFO)
            return false
        }

        var quote = this.get('model')
        var lines = quote.get('lines')
        this.validateQuote(quote)
        lines.forEach(this.validateLine.bind(this))
        return (
            quote.get('errors.isEmpty') &&
            lines.every(function(line) {
                return line.get('errors.isEmpty')
            })
        )
    },

    validateQuote: function(quote) {
        this.validatePresence(quote, ['contact', 'currency', 'entryDate'])
    },

    validateLine: function(line) {
        this.validatePresence(line, ['product', 'quantity', 'unitPrice'])
    },

    validatePresence: function(r, attributes) {
        var errors = r.get('errors')
        attributes.forEach(function(key) {
            if (Em.isEmpty(r.get(key))) {
                errors.add(key, t('required_field'))
            }
        })
    },

    save: function() {
        var self = this
        var quote = this.get('model').set('templateId', this.get('preselectedTemplate'))
        var wasNew = quote.get('isNew')
        var attachmentsChangeset = this.get('attachmentsChangeset')
        if (!this.validate()) {
            return
        }
        var templateId = quote.get('templateId')
        var template = this.get('templates').find(templateId)

        batmask.maskDelayed()
        return quote
            .save()
            .then(function(quote) {
                self.get('segment').track('Quote Create Completed (FE)', {
                    quote_id: quote.get('id')
                })

                // Save lines and attachments in parallel
                return Em.RSVP.all([
                    self.saveLines(),
                    attachmentsChangeset.save()
                ])
            })
            .then(function() {
                // On new quotes that use a template which includes a standard attachment,
                // create that attachment and add it on the quote.
                var standardAttachmentUrl =
                    template.variables.default.standardAttachmentUrl
                if (!wasNew || !standardAttachmentUrl) {
                    return
                }
                var attachment = self.container
                    .lookup('store:main')
                    .createRecord('v2-attachment', {
                        organization: self.get('organization'),
                        url: standardAttachmentUrl,
                        name: standardAttachmentUrl.replace(/^.*[\\\/]/, ''),
                        priority: 1
                    })
                quote.get('attachments').addObject(attachment)

                return attachment.save()
            })
            .finally(batmask.unmask.bind(batmask))
            .then(function() {
                if (wasNew) {
                    tracking.emitAnalyticsEvent(
                        'premium_activity',
                        'created_quote',
                        'created quote'
                    )
                    self.get('intercom').trackEvent(
                        self.get('organization'),
                        'created_quote'
                    )
                }
                // Make sure that we override new-route with the edit route for the newly created quote
                return self.replaceRoute('quote.edit', quote)
            })
            .then(function() {
                return self.transitionToRoute('quote', quote)
            })
    },

    saveLines: function() {
        var deletedLines = this.get('deletedLines')
        var allLines = this.get('lines')
            .map(function(line) {
                // Can only concat a normal array. `lines` is a record array
                return line
            })
            .concat(deletedLines)
        return Em.RSVP.all(
            allLines.map(function(line) {
                return line.save().then(function() {
                    deletedLines.removeObject(line)
                })
            })
        )
    },

    actions: {
        backToList: function() {
            var quotesListQuery = Cookies.get(cookieKey.quotesListQuery) || ''
            Cookies.remove(cookieKey.quotesListQuery)
            this.transitionToRoute('quotes.index', { queryParams: qs.parse(quotesListQuery, { ignoreQueryPrefix: true }) })
        },

        deleteLine: function(line) {
            this.get('deletedLines').pushObject(line)
        },

        save: function() {
            this.save()
        }
    }
})
