module.exports = Em.Component.extend({
    classNames: ['feature-list-feature-item'],

    tagName: 'li',

    iconPath: function() {
        var iconState = this.get('item.iconState')

        switch (iconState) {
        case 'isIncluded':
            return 'icons/circle-solid-check'
        case 'isNotIncluded':
            return 'icons/circle-cross'
        case 'isIncludedButLimited':
            return 'icons/circle-solid-check'
        case 'isOptIn':
            return 'icons/round-plus-black'
        default:
        }
    }.property('item.iconState'),

    featureTypeClassName: function() {
        var iconState = this.get('item.iconState')

        switch (iconState) {
        case 'isIncluded':
            return 'is-included'
        case 'isNotIncluded':
            return 'is-not-included'
        case 'isIncludedButLimited':
            return 'is-included-but-limited'
        case 'isOptIn':
            return 'is-opt-in'
        default:
        }
    }.property('item.iconState')
})
