// TODO: Replace all usage with `authorized-route.js` when all users got migrated to the new scope version
// Check authorization for users with any scope version
module.exports = Em.Mixin.create({
    auth: Em.inject.service(),

    scopes: null,

    beforeModel: function() {
        // If route's beforeModel is implemented, make sure to call a this._super() inside it
        this.isRouteAuthorized()
    },

    isRouteAuthorized: function() {
        var scopes = this.get('scopes')

        if (!Array.isArray(scopes)) {
            throw new Error('Invalid auth definition')
        }

        if (!this.get('auth').isAuthorized(scopes, true)) {
            // TODO: Show relevant error message, redirect user??
            throw new Error('Unauthorized')
        }
    }
})
