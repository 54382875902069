var Window = require('ember-window')
var postingSides = require('posting-sides')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/contact-prepayment-window'),

    classNames: ['transaction-window-view'],

    customEvent: Ember.inject.service(),

    type: null,

    isSupplier: null,

    showForContact: function(contact, isSupplier) {
        var payment = Billy.BankPayment.createRecord({
            organization: contact.get('organization'),
            contact: contact,
            entryDate: moment(),
            cashSide: isSupplier ? postingSides.credit : postingSides.debit
        })
        this.set('content', payment)
        this.set('isSupplier', isSupplier)
        return this.show()
    },

    content: null,

    isPaymentEnabledQuery: {
        isPaymentEnabled: true
    },

    errorMessage: '',

    callback: null,

    actions: {
        approve: function() {
            var content = this.get('content')
            var error = false
            var errorMessage = ''

            if (!content.get('entryDate')) {
                errorMessage = t('contact.prepayment.required.entry_date')
                content.set('errors.entryDate', t('required_field'))
                error = true
            }
            if (!content.get('cashAmount')) {
                errorMessage = t('contact.prepayment.required.amount')
                content.set('errors.cashAmount', t('required_field'))
                error = true
            }
            if (!content.get('cashAccount')) {
                errorMessage = t('contact.prepayment.required.account')
                content.set('errors.cashAccount', t('required_field'))
                error = true
            }

            // Reset error message
            this.set('errorMessage', errorMessage)
            if (error) {
                return
            }

            content.set('subjectCurrency', content.get('cashAccount.currency'))

            // Set content to approved and save
            var self = this
            content.save()
                .mask()
                .success(function() {
                    if (self.get('callback')) {
                        self.get('callback')()
                    }
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.CONTACT_PREPAYMENT_APPROVE, t('transaction.window.approval_success'))
                    self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
                    self.close()
                })
                .error(function(errorMessage, payload) {
                    // Set error message, if not an error on a field
                    if (!payload.validationErrors) {
                        self.set('errorMessage', errorMessage)
                    }
                })
        }
    }
})
