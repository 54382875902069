module.exports = Ember.Component.extend({
    paymentMethods: Em.inject.service(),

    paymentMethodName: function() {
        var properties = this.get('entry.properties')
        var paymentMethodsSvc = this.get('paymentMethods')
        var pm = paymentMethodsSvc && paymentMethodsSvc.find(properties.paymentMethodId)

        return pm ? '(' + pm.get('name') + ')' : ''
    }.property('entry.properties.paymentMethodId', 'entry.properties.paymentMethodType')
})
