var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    needs: ['user'],

    organization: Em.computed.oneWay('controllers.user.activeOrganization'),

    templatesUrl: function() {
        return '/organizations/' + this.get('organization.id') + '/templates'
    }.property('organization.id'),

    actions: {
        newTemplateClicked: function() {
            var self = this
            var standardTemplate = this.get('standardTemplate')
            var standardTemplateNameBase = standardTemplate.name
            var templatesWithBaseName = this.get('model').filter(function(item) {
                return (item.data.name.indexOf(standardTemplateNameBase) !== -1)
            })
            var maxPrepended = _.maxBy(templatesWithBaseName, function(o) {
                return o.data.name.match(/(\d+)$/g)
            })

            // prepend a running number to the name of the new template
            if (maxPrepended) {
                standardTemplate.name = standardTemplateNameBase + ' ' + (parseInt(maxPrepended.data.name.match(/(\d+)$/g)[0]) + 1)
            } else {
                standardTemplate.name = standardTemplate.name + ' 1'
            }

            this.get('api').post(this.get('templatesUrl'), {
                mask: true,
                payload: {
                    data: standardTemplate
                }
            })
                .then(function(payload) {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_CREATE, t('templates.created'))
                    self.transitionToRoute('template', payload.data.id)
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_CREATE, e.message)
                })
        },

        setTemplateAsDefault: function(template) {
            var self = this
            this.get('organization')
                .set('defaultTemplateId', template.id)
                .save()
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_SET_DEFAULT, t('templates.set_as_default_success'))
                    self.send('reloadTemplates')
                })
        },

        editTemplate: function(template) {
            this.transitionToRoute('template', template)
        },

        duplicateTemplate: function(template) {
            var self = this
            var copy = _.omit(template, ['id'])
            copy.name += t('templates.duplicate_prepend')
            self.get('api').post(self.get('templatesUrl'), {
                mask: true,
                payload: {
                    data: copy
                }
            })
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_DUBLICATE, t('templates.duplicated_on_overview'))
                    self.send('reloadTemplates')
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_DUBLICATE, e.message)
                })
        },

        deleteTemplate: function(template) {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('settings.organization_template.confirm_delete_template'), t('settings.organization_template.yes_delete'), t('cancel'))
                .then(function() {
                    return self.get('api').delete(self.get('templatesUrl') + '/' + template.id, { mask: true })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TEMPLATE_DELETE, t('templates.deleted'))
                    self.send('reloadTemplates')
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_DELETE, e.message)
                })
        }
    }
})
