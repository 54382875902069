module.exports = Em.Mixin.create({
    needs: ['bankAccount', 'organization', 'application'],

    selectedAccount: Em.computed.alias('controllers.bankAccount.model'),

    organization: Em.computed.oneWay('controllers.organization.model'),

    currentRouteName: Em.computed.oneWay('controllers.application.currentRouteName'),

    labs: function() {
        return this.container.lookup('controller:labs')
    }.property(),

    bankAccountOptions: function() {
        return Billy.Account.filter({
            query: {
                organization: this.get('organization'),
                isBankAccount: true,
                isArchived: false
            },
            sortProperty: 'name'
        }).map(function(bankAccount) {
            return Em.O({
                value: bankAccount,
                name: bankAccount.get('name')
            })
        })
    }.property('organization', 'organization.accounts.@each.isArchived'),

    hasMultipleBankAccounts: Em.computed.gt('bankAccountOptions.length', 1),

    hasReactBankReconciliationEnabled: function() {
        return this.get('labs.reactBankReconciliation')
    }.property('labs'),

    actions: {
        selectedAccountDidChange: function(account) {
            if (!account) {
                return
            }

            // Clear url_parameters from nordic-api
            this.set('nordicApiSessionId', undefined)
            this.set('nordicApiError', undefined)
            this.set('nordicApiSuccess', undefined)
            this.set('fromRenewing', undefined)

            this.replaceRoute(this.get('currentRouteName'), account)
        }
    }
})
