var t = require('i18n').t

Billy.ExternalInvoice.reopen({
    shortInvoiceNoWithCustomer: function() {
        var contactName = this.get('contact.name')
        return '#' + this.get('number') + (contactName ? ' - ' + contactName : '')
    }.property('number', 'contact.name'),

    genericDescription: function() {
        var name = this.get('contact.name')
        var number = this.get('number')
        if (this.get('type') === 'creditNote') {
            return t('invoice.description.approved_credit', { invoiceNo: number, name: name })
        } else {
            return t('invoice.description.approved_invoice', { invoiceNo: number, name: name })
        }
    }.property('number', 'contact.name')
})
