var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    intercom: Em.inject.service(),

    isCheckboxDisabled: false,

    isCompany: function() {
        return this.get('model.commitment.companyType') === 'company'
    }.property('model.commitment.companyType'),

    year: function() {
        var activeYear = this.get('model.commitment.year')

        return {
            active: activeYear,
            next: activeYear + 1
        }
    }.property('model.commitment.year'),

    isReported: Em.computed.equal('model.commitment.state', 'reported'),

    isNotReported: Em.computed.not('isReported'),

    lockList: function() {
        var state = this.get('model.commitment.state')

        return ['completed', 'delivered', 'received'].indexOf(state) > -1
    }.property('model.commitment.state'),

    stepsIsComplete: function() {
        var self = this
        var stepsIsCompleted = true

        this.get('steps').forEach(function(step) {
            if (!self.get('annualReport').isStepCompleted(step.route, self.get('model.commitment'))) {
                stepsIsCompleted = false
            }
        })

        return stepsIsCompleted
    }.property('steps.@each.{route,completed}'),

    disableReportedButton: function() {
        return !this.get('stepsIsComplete')
    }.property('stepsIsComplete'),

    actions: {
        showIntercomMessenger: function() {
            this.get('intercom').showNewMessage()
        }
    }
})
