module.exports = Em.Route.extend({
    model: function() {
        return Billy.Account.filter({
            query: {
                organization: this.modelFor('organization'),
                isBankAccount: true,
                isArchived: false
            },
            sortProperty: 'name'
        }).promise
    }
})
