var i18n = require('i18n')
var t = i18n.t
var _ = require('lodash')
var download = require('downloadjs')
var downloadExport = require('../utils/download-export')
var createExport = require('../utils/create-export')
var NOTIFICATION_KEYS = require('../notificationKeys')
var datePeriodToRange = require('../utils/date-period-to-range')
var moment = require('moment')

var FRENCH_CERTIFICATE_URL = 'https://assets.staging.billy.dk/assets/Attestation_CGI_AgerasDK.pdf'

module.exports = Em.Controller.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    segment: Em.inject.service(),

    api: Ember.inject.service(),

    filter: null, // Can be set by child route to limit which report to be shown

    hasShortcuts: filterHelper(),
    hasDebtorList: filterHelper(),
    hasCreditorList: filterHelper(),
    hasAccountStatement: filterHelper(),
    hasPostingJournal: filterHelper(),
    hasBalanceList: filterHelper(),
    hasChartOfAccounts: filterHelper(),
    hasContacts: filterHelper(),
    hasProducts: filterHelper(),
    hasDebtorAccountStatement: filterHelper(),
    hasCreditorAccountStatement: filterHelper(),
    hasBackup: filterHelper(),

    debtorListDate: moment(),

    creditorListDate: moment(),

    accountStatementPeriod: null,

    accountStatementIncludeVoided: false,

    postingJournalPeriod: null,

    postingJournalIncludeVoided: false,

    fecReportPeriod: null,

    // there is no fiscal year support in node js services implemented
    backupPeriodTypes: ['all', 'dates', 'month', 'quarter', 'year'],

    backupTransactionType: 'all',

    backupTransactionTypes: function() {
        var isSourceZervant = this.get('organization.isSourceZervant')

        var transactionTypes = [
            Em.O({
                name: t('exports.transaction_type.all'),
                value: 'all'
            }),
            Em.O({
                name: t('exports.transaction_type.bills'),
                value: 'bill'
            }),
            Em.O({
                name: t('exports.transaction_type.payments'),
                value: 'bankPayment'
            })
        ]

        if (isSourceZervant) {
            transactionTypes.push(Em.O({
                name: t('exports.transaction_type.invoices'),
                value: 'externalInvoice'
            }))
        } else {
            transactionTypes.push(Em.O({
                name: t('exports.transaction_type.invoices'),
                value: 'invoice'
            }))
        }

        return transactionTypes
    }.property(),

    isFECReportEnabled: function() {
        return this.get('organization.isFECReportEnabled')
    }.property('organization.isFECReportEnabled'),

    openExport: function(url, params) {
        var api = this.container.lookup('api:billy')

        params = _.merge({
            accessToken: api.storageAdapter.getValue('accessToken'),
            acceptLanguage: i18n.locale()
        }, params)

        var exportUrl = url + '?' + $.param(params)

        downloadExport(this.container, exportUrl)
    },

    sendExportEvent: function(exportType, format, period) {
        period = period ? period.value : 'all'

        // Remove organization id from the name of the event (should be fiscalyear:YYYY, instead of fiscalyear:ID,YYYY)
        if (period.startsWith('fiscalyear')) {
            period = 'fiscalyear:' + period.slice(-4)
        }

        this.get('segment').track('XXX - Mikkel - Exports', {
            export_type: exportType,
            file_format: format,
            period: period
        })
    },

    actions: {
        downloadCertificate: function() {
            download(FRENCH_CERTIFICATE_URL)
        },

        exportDebtorList: function(format) {
            format = format || 'xlsx'
            var params = { date: this.get('debtorListDate').format('YYYY-MM-DD') }
            this.openExport('/organizations/' + this.get('organization.id') + '/debtorList.' + format, params)
            this.sendExportEvent('Accounts receivable', format, { value: params.date })
        },

        exportCreditorList: function(format) {
            format = format || 'xlsx'
            var params = { date: this.get('creditorListDate').format('YYYY-MM-DD') }
            this.openExport('/organizations/' + this.get('organization.id') + '/creditorList.' + format, params)
            this.sendExportEvent('Accounts payable', format, { value: params.date })
        },

        exportAccountStatement: function(format) {
            format = format || 'xlsx'
            var params = {
                includeVoided: this.get('accountStatementIncludeVoided')
            }
            var period = this.get('accountStatementPeriod')
            if (period) {
                params.period = period.value
            }
            this.openExport('/organizations/' + this.get('organization.id') + '/accountStatement.' + format, params)
            this.sendExportEvent('Account statement', format, period)
        },

        exportPostingJournal: function(format) {
            format = format || 'xlsx'
            var params = {
                includeVoided: this.get('postingJournalIncludeVoided')
            }
            var period = this.get('postingJournalPeriod')
            if (period) {
                params.period = period.value
            }
            this.openExport('/organizations/' + this.get('organization.id') + '/postingJournal.' + format, params)
            this.sendExportEvent('Entry journal', format, period)
        },

        exportBalanceList: function(format) {
            format = format || 'xlsx'
            var params = {}
            var period = this.get('balanceListPeriod')
            if (period) {
                params.period = period.value
            }
            this.openExport('/organizations/' + this.get('organization.id') + '/balanceList.' + format, params)
            this.sendExportEvent('Balance list', format, period)
        },

        exportChartOfAccounts: function(format) {
            format = format || 'xlsx'
            this.openExport('/organizations/' + this.get('organization.id') + '/chartOfAccounts.' + format)
            this.sendExportEvent('Chart of accounts', format)
        },

        exportDaybook: function(daybookId, format) {
            format = format || 'xlsx'
            this.openExport('/organizations/' + this.get('organization.id') + '/daybooks/' + daybookId + '.' + format)
            this.sendExportEvent('Daybook', format)
        },

        exportContacts: function(format) {
            format = format || 'xlsx'
            this.openExport('/organizations/' + this.get('organization.id') + '/contacts.' + format)
            this.sendExportEvent('Contacts', format)
        },

        exportProducts: function(format) {
            format = format || 'xlsx'
            this.openExport('/organizations/' + this.get('organization.id') + '/products.' + format)
            this.sendExportEvent('Products', format)
        },

        exportDebtorAccountStatement: function(format) {
            format = format || 'xlsx'
            var params = {
            }
            var period = this.get('debtorAccountStatementPeriod')
            if (period) {
                params.period = period.value
            }
            this.openExport('/organizations/' + this.get('organization.id') + '/debtorAccountStatement.' + format, params)
            this.sendExportEvent('Customer account statement', format, period)
        },

        exportCreditorAccountStatement: function(format) {
            format = format || 'xlsx'
            var params = {
            }
            var period = this.get('creditorAccountStatementPeriod')
            if (period) {
                params.period = period.value
            }
            this.openExport('/organizations/' + this.get('organization.id') + '/creditorAccountStatement.' + format, params)
            this.sendExportEvent('Vendor account statement', format, period)
        },

        backup: function() {
            var params
            var period = this.get('backupPeriod')
            if (period) {
                params = _.assign({ period: period.value }, params)
            }
            var transactionType = this.get('backupTransactionType')
            if (transactionType !== 'all') {
                params = _.assign({ types: [transactionType] }, params)
            }
            createExport(this.container, t('exports.backup'), '/organizations/' + this.get('organization.id') + '/exports/backup', params)
            this.sendExportEvent('Backup', 'zip', period)
        },

        exportAsFEC: function() {
            var self = this
            var period = this.get('fecReportPeriod')

            var startDate
            var endDate

            if (period) {
                var dates = datePeriodToRange(period.value)
                startDate = moment(dates.start).format('YYYY-MM-DD')
                endDate = moment(dates.end).format('YYYY-MM-DD')
            } else {
                var firstFiscalYearStart = this.get('organization.firstFiscalYearStart')
                startDate = moment(firstFiscalYearStart).format('YYYY-MM-DD')
                endDate = moment().format('YYYY-MM-DD')
            }

            this.get('api').post('/v2/fecExports', {
                payload: {
                    fecExport: {
                        organizationId: this.get('organization.id'),
                        fromDate: startDate,
                        toDate: endDate
                    }
                }
            }).then(function() {
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.FEC_REPORT_SENT, t('exports.fec.report_sent'))
            }, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.FEC_REPORT_SENT, e.message)
            })
        },

        exportAsSaft: function() {
            this.container.lookup('component:export-as-saft-modal').show()
        }
    }
})

function filterHelper() {
    return Em.computed('filter', function(key) {
        var filter = this.get('filter')
        var v = Em.String.camelize(key.replace(/^has/, ''))
        return !filter || filter === v
    })
}
