var RestoreScrollingRoute = require('../mixins/scroll-memory-route')
var FiscalYear = require('../utils/period-selector/fiscal-year-period')

module.exports = require('./abstract-report').extend(RestoreScrollingRoute, {
    scrollingElement: '.section-body',

    queryParams: {
        period: {
            replace: true
        },
        compare_with: {
            replace: true
        },
        compare_count: {
            replace: true
        },
        hide_zeroes: {
            replace: true
        },
        inverted: {
            replace: true
        },
        ytd: {
            replace: true
        }
    },

    setupController: function(controller) {
        var organization = this.modelFor('organization')
        var period = controller.get('period')
        if (controller.get('periodValue') === 'default' || (period instanceof FiscalYear && period.value.indexOf(organization.get('id')) === -1)) {
            controller.set('period', FiscalYear.getCurrentPeriod(organization))
        }
        this._super(controller)
    }
})
