module.exports = Em.Component.extend({
    layoutName: 'components/alert-box',

    classNames: ['alert-box'],

    classNameBindings: ['type', 'expand', 'narrow'],

    type: 'info',

    dismissable: false,

    dismiss: null,

    expand: false,

    narrow: false,

    horizontal: false,

    actions: {
        dismiss: function() {
            this.sendAction('dismiss')
            this.destroy()
        }
    }
})
