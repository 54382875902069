var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['add-on', 'add-on-integer'],

    classNameBindings: ['isActive::addon-off'],

    layout: require('../templates/components/add-on-integer'),

    organization: null,

    model: null,

    min: 0,

    max: null,

    name: function() {
        return t('organization_subscription.addon.' + this.get('model.id') + '.title', { count: this.get('model.value') })
    }.property('model.{id,value}'),

    period: function() {
        return t('organization_subscription.addon.payment_period.' + this.get('model.period'))
    }.property('model.period'),

    price: function() {
        return this.get('model.price')
    }.property('model.price'),

    amount: function() {
        var quantity = this.get('model.value') || 1
        var unitMontlyPrice = this.get('model.price')
        var currencyCode = this.get('model.currencyId')

        return Billy.moneyRecurring(quantity * unitMontlyPrice, currencyCode, '0,0', 'monthly')
    }.property('model.{value,price,currencyId}'),

    isActive: function() {
        // Always show validated-scans addon as active to match the design
        return this.get('model.value') > 0 || this.get('model.id') === 'validated_scans'
    }.property('model.value'),

    isDisabled: function() {
        return false
    }.property(),

    actions: {
        toggle: function() {
            this.set('model.value', this.get('isActive') ? 0 : (this.get('min') || 1))
            this.sendAction('didChange', this.get('model'))
        },

        onInvalidDisable: function() {
            this.sendAction('invalidDisable', this.get('model'))
        }
    }
})
