var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['alert-window'],

    layout: Window.proto().layout,

    template: require('../templates/components/alert-window'),

    intercom: Em.inject.service(),

    title: '',

    description: '',

    showSupportLink: false,

    actions: {
        showIntercomMessenger: function() {
            this.get('intercom').showNewMessage()
        }
    }
})
