var storage = require('storage')
var t = require('i18n').t
var PeriodSelector = require('ember-period-selector')
var periodCp = require('../utils/period-computed-property')
var ReportLocalStorageFilters = require('../mixins/report-local-storage-filters')
var isValueTruthy = require('../utils/is-value-truthy')

module.exports = require('./abstract-report').extend(ReportLocalStorageFilters, {
    queryParams: {
        mode: 'mode',
        dateValue: 'date',
        compareWith: 'compare_with',
        compareCount: 'compare_count',
        periodValue: 'period',
        hideZeroes: 'hide_zeroes',
        invertAmounts: 'inverted'
    },

    error: null,

    mode: 'date',

    modeIsDate: Em.computed.equal('mode', 'date'),

    modeOptions: function() {
        return [
            Em.O({
                value: 'date',
                label: t('balance_sheet.mode.date')
            }),
            Em.O({
                value: 'period',
                label: t('balance_sheet.mode.period')
            })
        ]
    }.property(),

    dateValue: 'default', // route's setupController will set it to a proper default value

    // We need to handle this as a CP as `dateValue` maps to a query param
    date: dateCp('dateValue'),

    compareWith: 'month',

    compareCount: 1,

    periodValue: 'default',

    period: periodCp('periodValue'),

    // there is no 'all' support implemented
    periodTypes: ['fiscalYear', 'dates', 'month', 'quarter', 'year'],

    init: function() {
        this._super()
        this._initFiltersByLocalStorageValues()
    },

    reload: function() {
        this._super()
        this._initFiltersByLocalStorageValues()
    },

    _initFiltersByLocalStorageValues: function() {
        this.set('hideZeroes', this.isLocalStorageFilterApplied('hideZeroes'))
        this.set('invertAmounts', this.isLocalStorageFilterApplied('invertAmounts'))
    },

    monthOptions: function() {
        var d = moment.utc().endOf('month').startOf('day')
        var end = moment(this.get('organization.firstFiscalYearStart')).endOf('month').startOf('day')
        var options = Em.A()
        while (d.isAfterOrSame(end)) {
            options.pushObject(Em.Object.create({
                value: d,
                name: d.format('MMMM YYYY')
            }))
            d = moment(d).subtract(1, 'months').endOf('month').startOf('day')
        }
        return options
    }.property('organization.firstFiscalYearStart'),

    compareWithOptions: function() {
        return [
            Ember.Object.create({
                value: 'month',
                name: t('compare_with_period_field.month.period')
            }),
            Ember.Object.create({
                value: 'quarter',
                name: t('compare_with_period_field.quarter.period')
            }),
            Ember.Object.create({
                value: 'year',
                name: t('compare_with_period_field.year.period')
            })
        ]
    }.property(),

    compareCountOptions: function() {
        var options = Em.A()
        var i
        for (i = 0; i <= 12; i++) {
            options.pushObject(Ember.Object.create({
                value: i,
                name: i
            }))
        }
        return options
    }.property(),

    applyDefaults: function() {
        if (this.get('mode') === 'date') {
            if (this.get('dateValue') === 'default') {
                this.set('date', moment.utc().endOf('month').startOf('day'))
            }
        } else {
            if (this.get('periodValue') === 'default') {
                this.set('period', PeriodSelector.periods.Month.create({
                    value: 'month:' + moment.utc().format('YYYY-MM')
                }))
            }
        }
    }.observes('mode'),

    filterDidChange: function(state, filterName) {
        this.load()
        this.setFilterToLocalStorage(filterName, state[filterName])
    }.observes('mode', 'date', 'compareWith', 'compareCount', 'hideZeroes', 'invertAmounts', 'period'),

    getReportUrl: function(format) {
        this.set('error', null)

        var url = '/organizations/' + this.get('organization.id') + '/balanceSheet'
        if (format) {
            url += '.' + format
        }

        var params = {}

        if (this.get('mode') === 'date') {
            _.extend(params, {
                date: this.get('date').format('YYYY-MM-DD'),
                compareWith: this.get('compareWith'),
                compareCount: this.get('compareCount')
            })
        } else {
            var period = this.get('period')
            if (!period) {
                this.set('error', t('reports.must_select_period'))
                return null
            }
            _.extend(params, {
                period: period.value
            })
        }

        // since Ember checkboxes store their 'checked' values as strings, we need to check whether its boolean true or string true
        if (isValueTruthy(this.get('hideZeroes'))) {
            params.hideZeroes = true
        }

        if (isValueTruthy(this.get('invertAmounts'))) {
            params.inverted = true
        }

        url += '?' + $.param(params)
        return url
    },

    conversionBalances: function() {
        return Billy.ConversionBalance.findByQuery({
            organizationId: this.get('organization.id')
        })
    }.property('organization.id'),

    conversionBalancePromptVisible: function() {
        if (storage('dismissedConversionBalancePrompt:' + this.get('organization.id'))) {
            return false
        }
        var cbs = this.get('conversionBalances')
        return cbs.get('isLoaded') && cbs.get('length') === 0
    }.property('organization.id', 'conversionBalances.{isLoaded,length}'),

    actions: {
        didDismissConversionBalancePrompt: function() {
            storage('dismissedConversionBalancePrompt:' + this.get('organization.id'), true)
        }
    }
})

function dateCp(dateProperty) {
    return function(key, date) {
        if (arguments.length > 1) {
            this.set(dateProperty, date ? date.format('YYYY-MM-DD') : '')
            return date
        } else {
            var dateValue = this.get(dateProperty)
            return dateValue && dateValue !== 'default' && moment(dateValue)
        }
    }.property(dateProperty)
}
