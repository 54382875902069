module.exports = Ember.Mixin.create({
    actions: {
        assertPriorities: function() {
            this.get('lines').sortBy('priority').forEach(function(line, priority) {
                line.set('priority', priority + 1)
            })
        },

        updatePriorities: function(source, target) {
            var change = source < target ? -1 : 1
            this.get('lines').forEach(function(line) {
                var current = line.get('priority')
                if ((current >= target && current <= source) || (current >= source && current <= target)) {
                    if (current === source) {
                        line.set('priority', target)
                    } else {
                        line.set('priority', current + change)
                    }
                }
            })
        }
    }
})
