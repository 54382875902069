module.exports = Em.Component.extend({
    layoutName: 'components/trial-ended-modal',

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
