var _ = require('lodash')

module.exports = Em.Component.extend({
    classNames: ['credit-card-logo'],

    creditCardType: function() {
        var creditCardType = this.get('cardType')
        creditCardType = creditCardType.toLowerCase()

        var dankort = ['visa-dankort', 'dankort']
        var jcb = ['jcb']
        var maestro = ['maestro']
        var mastercard = ['mastercard-dk', 'mastercard', 'mastercard-debet-dk', '3d-mastercard-debet-dk', 'mastercard-debet', '3d-mastercard-dk']
        var visa = ['visa-electron-dk', 'visa-dk', 'visa', '3d-visa-dk', '3d-visa-electron-dk']
        var diners = ['diners']

        if (_.includes(dankort, creditCardType)) {
            return {
                class: 'dankort',
                path: 'icons/credit-card-logos/dankort'
            }
        } else if (_.includes(jcb, creditCardType)) {
            return {
                class: 'jcb',
                path: 'icons/credit-card-logos/jcb'
            }
        } else if (_.includes(maestro, creditCardType)) {
            return {
                class: 'maestro',
                path: 'icons/credit-card-logos/maestro'
            }
        } else if (_.includes(mastercard, creditCardType)) {
            return {
                class: 'mastercard',
                path: 'icons/credit-card-logos/mastercard'
            }
        } else if (_.includes(visa, creditCardType)) {
            return {
                class: 'visa',
                path: 'icons/credit-card-logos/visa'
            }
        } else if (_.includes(diners, creditCardType)) {
            return {
                class: 'diners',
                path: 'icons/credit-card-logos/diners'
            }
        }

        return {
            class: 'default',
            path: 'misc/billy-face-outline'
        }
    }.property('organization.subscriptionCardType')
})
