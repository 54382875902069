var t = require('i18n').t

Billy.BalanceModifier.reopen({
    description: function() {
        var modifier = this.get('modifier')
        if (!modifier || !modifier.get('isLoaded')) {
            return t('loading') + '...'
        }
        var modelName = modifier.constructor.toString().split('.').slice(1)[0].toLowerCase()
        var type = modifier.get('type')
        if (type && type === 'creditNote') {
            modelName = 'credit_note'
        }
        if (modelName === 'bankpayment') {
            return t('balance_modifier.type.payment_with_account_name', { accountName: modifier.get('cashAccount.name') })
        }
        return t('balance_modifier.type.' + modelName)
    }.property('modifier', 'modifier.isLoaded', 'modifier.type', 'modifier.cashAccount.name'),

    descriptionWithDate: function() {
        var modifier = this.get('modifier')
        if (!modifier || !modifier.get('isLoaded')) {
            return t('loading') + '...'
        }
        return t('balance_modifier.description_on_date', {
            description: this.get('description'),
            entryDate: this.get('entryDate').format('LL')
        })
    }.property('modifier', 'modifier.isLoaded', 'description', 'entryDate')
})
