var attr = DS.attr
var belongsTo = DS.belongsTo

module.exports = DS.Model.extend({
    createdTime: attr('moment-datetime'),
    updatedTime: attr('moment-datetime'),
    recurringInvoice: belongsTo('recurring-invoice'),
    product: attr('billy-data-product', { apiKey: 'productId' }),
    description: attr(),
    quantity: attr('number'),
    unitPrice: attr('number'),
    discountMode: attr(),
    discountText: attr(),
    discountValue: attr('number'),
    currentTaxRate: attr('number'),
    priority: attr('number'),
    isOnetime: attr('boolean', { defaultValue: false }),
    taxRate: attr('billy-data-tax-rate', { apiKey: 'taxRateId' })
})
