var moment = require('momentjs')
var _ = require('lodash')

module.exports = Em.ObjectController.extend({
    api: Em.inject.service(),

    groupsByMonth: [],

    userOrganizations: Em.inject.service(),

    entryDatePeriod: null,

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    isLoading: false,

    loadRecords: function() {
        this.set('isLoading', true)

        var query = {
            organizationId: this.get('organization.id')
        }

        if (this.get('entryDatePeriod')) {
            query.createdTimePeriod = this.get('entryDatePeriod.value')
        }

        var self = this

        return Billy.BankLineGroup.findByQuery(query)
            .promise
            .then(function(groups) {
                self.set('model', groups)
                self.set('groupsByMonth', self.groupLines(groups))
                self.set('isLoading', false)
            })
    },

    onEntryDateChanged: function() {
        this.loadRecords()
    }.observes('entryDatePeriod'),

    groupLines: function(groups) {
        var groupsByMonth = groups.reduce(function(acc, bankLineGroup) {
            var createdTime = moment(bankLineGroup.createdTime)
            var month = createdTime.format('MMMM')
            var year = createdTime.format('YYYY')
            var i = parseInt(createdTime.format('M'), 10)

            acc[i] = acc[i] || { name: month + ' ' + year, groups: [] }
            acc[i].groups.push(bankLineGroup)

            return acc
        }, [])

        return _.compact(groupsByMonth)
    },

    actions: {
        reload: function() {
            this.loadRecords()
        },

        backToReconcile: function() {
            this.transitionToRoute('bank_sync')
        }
    }
})
