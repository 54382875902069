var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Scope = require('../utils/scope')

module.exports = ListController.extend({
    needs: ['organization'],

    auth: Em.inject.service(),

    organization: Em.computed.alias('controllers.organization.model'),

    segment: Em.inject.service(),

    recurringInvoices: Em.inject.service(),

    writeScope: Scope.RecurringInvoiceWrite,

    spawningDisabled: function() {
        return !this.get('recurringInvoices.isEnabled') &&
            this.get('recurringInvoices.isLoaded') // to avoid flashing the banner
    }.property('recurringInvoices.isEnabled', 'recurringInvoices.isLoaded'),

    canCreateNew: function() {
        var scopes = [this.get('writeScope')]
        return this.get('auth').isAuthorized(scopes)
    }.property(),

    query: function() {
        var sortPropertyMap = {
            nextInvoiceDate: 'recurringDate',
            repeatHuman: 'recurringPeriod',
            recurringSpawnModeHuman: 'recurringSpawnMode',
            orderNo: 'orderNo',
            'contact.name': 'contactName',
            lineDescription: 'description',
            netAmount: 'netAmount',
            grossAmount: 'grossAmount'
        }

        var sortProperty = this.get('sortProperty')
        var sortDirection = this.get('sortDirection') === 'ASC' ? '' : '-'
        var sort = sortProperty
            ? sortDirection + sortPropertyMap[sortProperty]
            : undefined // default sort order

        var query = {
            organizationId: this.get('organization.id'),
            sort: sort,
            pageSize: 1000
        }

        if (this.get('q')) {
            query.q = this.get('q')
        }

        return query
    }.property('organization', 'q', 'sortProperty', 'sortDirection'),

    records: null,

    recordsInitial: null,

    shouldShowEmptyScreen: function() {
        var recordsInitialLength = this.get('recordsInitial.length')

        return !recordsInitialLength
    }.property('recordsInitialLength.length'),

    loadRecords: function() {
        var self = this

        var promise = this.store.findQuery('recurring-invoice', this.get('query'))
        var recordsInitial = this.get('recordsInitial')

        this._currentLoadPromise = promise

        promise.then(function(records) {
            // Don't resolve if another load promise was started before this one
            if (promise !== self._currentLoadPromise) {
                return
            }

            // Don't resolve if controller is destroying
            if (self.get('isDestroying')) {
                return
            }

            self.set('records', records)

            if (!recordsInitial) {
                self.set('recordsInitial', records)
            }
        })
    }.observes('query'),

    click: function(recurringInvoice) {
        this.transitionToRoute('recurring_invoice', recurringInvoice)
    },

    hasHeader: false,

    columnsTogglable: true,

    storageKey: 'recurring-invoices',

    getColumns: function() {
        var hasVat = this.get('organization.hasVat')

        return [
            Column.create({
                name: 'spawnState',
                width: 70,
                hideable: false,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{recurring-invoice-spawn-state-badge model=spawnState isSmall=true}}'
                    )
                })
            }),
            DateColumn.create({
                header: t('recurring_invoices.index.next_invoice_date'),
                name: 'nextInvoiceDate',
                width: 125,
                sortable: true
            }),
            Column.create({
                header: t('recurring_invoices.index.repeat'),
                name: 'repeatHuman',
                width: 110,
                sortable: true
            }),
            Column.create({
                header: t('order_no'),
                name: 'orderNo',
                width: 80,
                sortable: true,
                defaultVisible: false,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if orderNo}}' +
                            '#{{orderNo}}' +
                        '{{/if}}'
                    )
                })
            }),
            Column.create({
                header: t('customer'),
                name: 'contact.name',
                flex: 2,
                sortable: true
            }),
            Column.create({
                header: t('description'),
                name: 'lineDescription',
                flex: 3,
                sortable: true
            }),
            Column.create({
                header: t('recurring_invoices.index.action'),
                name: 'recurringSpawnModeHuman',
                width: 160,
                sortable: true
            }),
            MoneyColumn.create({
                header: hasVat ? t('excl_vat_short') : t('net_amount_header'),
                name: 'netAmount',
                width: 120,
                sortable: true,
                defaultVisible: hasVat,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money netAmount currencyBinding="currency"}}'
                    )
                })
            }),
            MoneyColumn.create({
                header: hasVat ? t('incl_vat_short') : t('gross_amount_header'),
                name: 'grossAmount',
                width: 120,
                sortable: true,
                defaultVisible: !hasVat,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money grossAmount currencyBinding="currency"}}'
                    )
                })
            })
        ]
    },

    activate: function() {
        this.set('columns', this.getColumns())
    },

    deactivate: function() {
        this.set('columns', [])
    },

    actions: {
        reactivate: function(preserveSpawns) {
            var recurringInvoices = this.get('recurringInvoices')

            return recurringInvoices.updateOrganizationSettings(
                this.get('organization.id'),
                { enabled: true }, // Enable spawning
                preserveSpawns
            )
                .finally(recurringInvoices.loadSafe.bind(recurringInvoices))
        },

        createRecurringInvoice: function() {
            if (!this.get('canCreateNew')) {
                this.container.lookup('component:upgrade-plan-overlay')
                    .set('trackingContext', 'create_recurring_invoice')
                    .show()
                return
            }

            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'recurring_invoice_list',
                action: 'create recurring invoice'
            })

            this.transitionTo('recurring_invoices.new')
        }
    },

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute(route, props)
        }
    }
})
