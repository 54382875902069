module.exports = Em.Component.extend({
    classNames: ['approve-bookkeeping-list-item'],

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    item: null,

    model: null,

    annualReport: Em.inject.service(),

    _resetChoiceState: function() {
        this.get('item.choices').forEach(function(value) {
            value.set('isSelected', false)
        })
    },

    inventoryAccountValue: function() {
        return Billy.money(this.get('model.inventoryAccountValue'), this.get('organization.subscriptionCurrency'), '0,0')
    }.property('model.inventoryAccountValue'),

    actions: {
        didClickChoice: function(choice) {
            var item = this.get('item')
            var previousValue = item.get('value')

            var answer = {
                name: item.get('name'),
                value: choice.get('value')
            }

            // Finds previous selected choice
            var prevChoice = item.get('choices').find(function(value) {
                return value.get('isSelected')
            })

            // Toggle isSelected class on choices
            this._resetChoiceState()

            choice.set('isSelected', true)
            item.set('value', answer.value)

            this.get('annualReport')
                .putAnswer(item.year, [answer])
                .catch(function() {
                    choice.set('isSelected', false)
                    item.set('value', previousValue)
                    if (prevChoice && choice.value !== prevChoice.value) {
                        prevChoice.set('isSelected', true)
                    }
                })
        }
    }
})
