var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/contact-editor-add-field-popover'),

    classNames: ['super-field-selector'],

    minWidth: 200,

    maxWidth: 200,

    actions: {
        select: function(field) {
            this.trigger('select', field)
        }
    }
})
