var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    year: function() {
        var year = this.get('commitment.year')

        return {
            current: year,
            prev: year - 1
        }
    }.property('commitment.year'),

    tangiblesIsInvalid: function() {
        return !this.get('fixedAssets.validates')
    }.property('fixedAssets'),

    actions: {
        didClickNext: function() {
            var self = this
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { tangible_fixed_assets: true })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        },

        updateAssets: function(res) {
            this.set('fixedAssets', res.data.fixedAssetsMovements.tangible)
        }
    }
})
