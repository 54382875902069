var t = require('i18n').t
var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    queryParams: {
        fromRenewing: {
            replace: true
        }
    },

    scopes: [Scope.BankReconciliationRead],

    bankConnections: Em.inject.service(),

    model: function() {
        return this.modelFor('bank_account')
    },

    setupController: function(controller, model) {
        var self = this
        var bankConnections = this.get('bankConnections')

        bankConnections.find(model.get('id'))
            .then(function(connection) {
                // if connection is there but the setup is not finished (not assigned bank account to use)
                // pass that info to controller so we can display the modal that allows user to select bank account to use
                controller.set('shouldFinishSetupIfNeeded', !connection.get('isConnected'))

                var session = connection.get('session')
                var connectionErrorCode = connection.get('errorCode')
                var hasConnectionError = !!connectionErrorCode
                var hasSessionError = session && !!session.errorCode

                if (hasConnectionError || hasSessionError ||
                    (session &&
                    session.loginTokenExpiryTime &&
                    moment().isAfter(moment(session.loginTokenExpiryTime)))
                ) {
                    self.container.lookup('util:dialog').confirmImportant(
                        t('bank_connection.expired_dialog.title'),
                        t('bank_connection.expired_dialog.text'),
                        t('bank_connection.expired_dialog.confirm_button'),
                        t('bank_connection.expired_dialog.cancel_button')
                    )
                        .then(function() {
                            if (connection.type === 'nordic_api') {
                                var organizationId = model.get('organization.id')
                                var bankId = session.bankId

                                self.get('bankConnections').renewNordicApi(organizationId, bankId)
                            }
                        })
                }
            })

        controller.set('model', model)
        this.controllerFor('bank-sync-matches').load()
        this.controllerFor('bank-sync-possible-subjects').load()
    }
})
