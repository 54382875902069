var Window = require('ember-window')

module.exports = Window.extend({
    template: require('../templates/components/first-invoice-modal'),

    modalVariant: 'firstInvoiceModal',

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    applyModalMask: false,

    customActions: {
        closeEmber: function(e) {
            this.close()
        }
    }
})
