var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['approve-financing-modal'],

    agerasLoan: Em.inject.service(),

    template: require('../templates/components/approve-financing-modal'),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    modalVariant: 'approveFinancingModal',

    applyModalMask: false,

    invoice: null,

    estimate: null,

    variables: '{}',

    setVariables: function() {
        var self = this
        var variables = JSON.stringify({
            amount: self.get('estimate.payout_amount'),
            currency: self.get('estimate.payout_currency')
        })

        this.set('variables', variables)
    }.observes('estimate'),

    getInvoiceFinancingEstimate: function() {
        var self = this
        var invoice = this.get('invoice')

        return self
            .get('agerasLoan')
            .getInvoiceFactoring(
                invoice.get('id'),
                invoice.get('invoiceNo'),
                invoice.get('currency.id'),
                invoice.get('grossAmount'),
                invoice.get('dueDate')
            )
            .then(function(estimate) {
                if (estimate && estimate.id) {
                    self.set('financingEstimate', estimate)
                }
            })
    }.observes('invoice'),

    approveEstimate: function() {
        var self = this

        self.get('agerasLoan').hasCustomerIncompleteData().then(function(hasIncompleteData) {
            return self.get('agerasLoan').approveInvoiceFactoring(self.get('financingEstimate.id'))
                .then(function() {
                    if (hasIncompleteData) {
                        self.get('agerasLoan').goToApplicationForm()
                        return
                    }

                    self.container.lookup('controller:invoice-index').send('invalidateModel')
                    self.container.lookup('controller:invoice-index').set('invoiceSubmittedForLoan', true)
                    self.close()
                })
        })
    },

    customActions: {
        navigate: function(e) {
            var self = this
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.close()

            this.one('didClose', function() {
                self.container.lookup('router:main').transitionTo(route, props)
            })
        },
        closeEmber: function(e) {
            this.close()
        },

        ok: function() {
            this.approveEstimate()
        }
    }
})
