var getUserType = require('../utils/get-user-type')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')
var SettingsOrganizationMixin = require('../mixins/settings-organization')
var SettingsInvoicingMixin = require('../mixins/settings-invoicing')
var SettingsProfileMixin = require('../mixins/settings-profile')
var SettingsSubscriptionMixin = require('../mixins/settings-subscription')
var SettingsChartOfAccountsMixin = require('../mixins/settings-chart-of-accounts')
var SettingsVatRatesMixin = require('../mixins/settings-vat-rates')

module.exports = Em.ObjectController.extend(
    ReactDirtyRouteMixin,
    SettingsOrganizationMixin,
    SettingsInvoicingMixin,
    SettingsProfileMixin,
    SettingsSubscriptionMixin,
    SettingsChartOfAccountsMixin,
    SettingsVatRatesMixin,
    {
        needs: ['user'],

        organization: Em.computed.alias('controllers.user.activeOrganization'),

        queryParams: [
            'activate',
            'coupon',
            'duration',
            'initialRoute',
            'message',
            'payment_cancelled',
            'payment_failed',
            'payment_successful',
            'period',
            'plan',
            'promocode',
            'openCreateVatRateModal',
            'userflow',
            'utm_campaign',
            'utm_medium',
            'utm_source'
        ],

        activate: null,
        coupon: null,
        duration: null,
        initialRoute: null,
        message: null,
        payment_cancelled: null,
        payment_failed: null,
        payment_successful: null,
        period: null,
        plan: null,
        promocode: null,
        openCreateVatRateModal: null,
        userflow: null,
        utm_campaign: null,
        utm_medium: null,
        utm_source: null,

        userOrganizations: Em.inject.service(),

        activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

        userType: function() {
            return getUserType(this.get('activeMembership'))
        }.property('activeMembership'),

        customActions: function() {
            var self = this

            return _.merge(
                this.get('_customActions'),
                this.get('_settingsOrganizationCustomActions'),
                this.get('_settingsInvoicingCustomActions'),
                this.get('_settingsProfileCustomActions'),
                this.get('_settingsSubscriptionCustomActions'),
                this.get('_settingsChartOfAccountsCustomActions'),
                this.get('_settingsVatRatesCustomActions'), {
                    navigate: function(e) {
                        var route = e.detail.route
                        var options = e.detail.props || { queryParams: {} }

                        if (options) {
                            self.transitionToRoute(route, options)
                        } else {
                            self.transitionToRoute(route)
                        }
                    }
                })
        }.property('_customActions')
    })
