module.exports = Em.Mixin.create({
    organization: Em.computed.alias('controllers.user.activeOrganization'),

    _settingsVatRatesCustomActions: {
        createRuleset: function() {
            var ruleset = Billy.SalesTaxRuleset.createRecord({
                organization: this.get('organization'),
                isActive: true
            })

            var rule = Billy.SalesTaxRule.createRecord({
                ruleset: ruleset,
                country: this.get('organization.country'),
                priority: 1
            })

            // Set clean so we can roll back if we need
            rule.resetClean()

            this.container.lookup('component:sales-tax-ruleset-editor')
                .set('content', ruleset)
                .show()
        },

        editRuleset: function(e) {
            var id = e.detail && e.detail.id
            if (id) {
                var salesTaxRuleset = Billy.SalesTaxRuleset.allLocal().find(function(item) {
                    return item.id === id
                })

                this.container.lookup('component:sales-tax-ruleset-editor')
                    .set('content', salesTaxRuleset)
                    .show()
            }
        },

        createRate: function(e) {
            var props = e.detail && e.detail.props
            var organization = this.get('organization')
            if (organization.get('useSimpleTaxRates')) {
                this.container.lookup('component:simple-tax-rate-editor')
                    .createAndShow(organization, props)
            } else {
                this.container.lookup('component:tax-rate-editor')
                    .createAndShow(organization, props)
            }
        },

        editRate: function(e) {
            var id = e.detail && e.detail.id
            if (id) {
                var taxRate = Billy.TaxRate.allLocal().find(function(item) {
                    return item.id === id
                })
                var useSimpleTaxRates = this.get('organization.useSimpleTaxRates')
                var editorType = useSimpleTaxRates ? 'simple-tax-rate-editor' : 'tax-rate-editor'
                var editor = this.container.lookup('component:' + editorType)
                    .set('content', taxRate)
                editor.show()
                if (useSimpleTaxRates) {
                    editor.set('type', 'sales')
                }
            }
        }
    }
})
