module.exports = Em.Controller.extend({
    showCouponOffer: false,

    coupons: Em.inject.service(),

    couponOffer: Em.inject.service(),

    organizationSubscription: Em.inject.service(),

    subscription: Ember.inject.service(),

    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    actions: {
        enterCardDetails: function() {
            var subscription = this.get('subscription')
            subscription.subscribeCard()
        },

        enterCardDetailsStripe: function() {
            this.get('organizationSubscription').stripeCheckout()
        },

        skip: function() {
            this.transitionTo('dashboard')
        }
    }
})
