module.exports = require('./dashboard-widget').extend({
    needs: ['organization', 'user'],

    bankConnections: Ember.inject.service(),

    constraints: Ember.inject.service(),

    userOrganizations: Em.inject.service(),

    segment: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    view: 'dashboard-widget-bank-connection',

    hasBankConnections: null,

    load: function() {
        var self = this

        if (this.get('hasBankConnections')) {
            return
        }

        return this.get('bankConnections').getBankIntegrations(this.get('organization.id'))
            .then(function(fetchedConnections) {
                self.set('hasBankConnections', fetchedConnections.length > 0 ? 'true' : 'false') // string as this is the only type passed to web component
            })
    },

    customActions: {
        showSubscriptionComparison: function() {
            this.get('constraints').showUpgradeOverlay('', 'dashboard_widget_bank_connection')
        },

        connectBankAccount: function() {
            var self = this

            Billy.Account.filter({
                query: {
                    organization: self.get('organization'),
                    isBankAccount: true,
                    isArchived: false
                },
                sortProperty: 'name'
            }).promise.then(function(accounts) {
                var account = accounts.get('firstObject')

                self.transitionToRoute('bank_account', self.get('organization'), account)
                self.get('bankConnections').setup(account, 'nordic_api')
            })
        }
    }
})
