// Eanble support for data attributes
// https://guides.emberjs.com/v1.10.0/templates/binding-element-attributes/#toc_adding-data-attributes
Em.View.reopen({
    attributeBindings: [],

    init: function() {
        this._super()
        var self = this
        // bind attributes beginning with 'data-'
        Em.keys(this).forEach(function(key) {
            if (key.substr(0, 5) === 'data-') {
                self.get('attributeBindings').pushObject(key)
            }
        })
    }
})
