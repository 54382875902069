var t = require('i18n').t
var f = require('float')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    reportTypes: null,

    willDestroy: function() {
        this.get('reportTypes').forEach(function(reportType) {
            reportType.get('natures').forEach(function(nature) {
                nature.get('accounts').forEach(function(account) {
                    account.destroy()
                })
                nature.destroy()
            })
            reportType.destroy()
        })
    },

    debitBalance: function() {
        return this.get('reportTypes').reduce(function(balance, reportType) {
            return balance + reportType.get('debitBalance')
        }, 0)
    }.property('reportTypes.@each.debitBalance'),

    creditBalance: function() {
        return this.get('reportTypes').reduce(function(balance, reportType) {
            return balance + reportType.get('creditBalance')
        }, 0)
    }.property('reportTypes.@each.creditBalance'),

    balance: function() {
        return f.round(this.get('debitBalance') - this.get('creditBalance'))
    }.property('debitBalance', 'creditBalance'),

    balanceHelpText: function() {
        var balance = this.get('balance')
        if (balance === 0) {
            return t('conversion_balance.balance_match')
        } else if (balance < 0) {
            return t('conversion_balance.balance_missing_debit', { amount: Billy.money(Math.abs(balance)) })
        } else if (balance > 0) {
            return t('conversion_balance.balance_missing_credit', { amount: Billy.money(balance) })
        }
    }.property('balance'),

    isSaveDisabled: function() {
        return this.get('balance') !== 0
    }.property('balance'),

    actions: {
        save: function() {
            var self = this
            this.get('model')
                .save({
                    embed: ['lines']
                })
                .mask()
                .on('success', function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.CONVERSION_BALANCE_UPDATE, t('conversion_balance.updated_successfully'))

                    // Make sure that the balance sheet page does not show its alert about no conversion balance having been entered yet
                    self.container.lookup('controller:balance_sheet').notifyPropertyChange('conversionBalances')
                })
        },

        navigate: function(route) {
            this.transitionToRoute(route)
        }
    }
})
