var storage = require('storage')
var getDeviceType = require('../utils/get-device-type')

var MOBILE_MODAL_DISABLED_ROUTES = [
    'organization_subscription.index',
    'organization_subscription.plans_selection'
]

module.exports = Em.ObjectController.extend(Em.Evented, {
    needs: ['application'],

    sideMenu: Em.inject.service(),

    userOrganizations: Ember.inject.service(),

    isAuthenticated: null,

    activeOrganization: Em.computed.alias('userOrganizations.activeOrganization'),

    withSideNavbar: function() {
        return this.get('sideMenu.withSideNavbar')
    }.property('sideMenu.withSideNavbar'),

    currentRouteName: Em.computed.oneWay('controllers.application.currentRouteName'),

    quickpayLanguage: function() {
        return this.get('locale') === 'en_US' ? 'en' : 'da'
    }.property(),

    shouldShowMobileModal: function() {
        var isDismissed = storage('mobile-experience-message-closed')
        var isDeviceMobile = getDeviceType() === 'mobile'
        var isDisabledOnRoute = MOBILE_MODAL_DISABLED_ROUTES.includes(this.get('currentRouteName'))

        return !isDismissed && isDeviceMobile && !isDisabledOnRoute
    }.property('currentRouteName')
})
