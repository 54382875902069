var t = require('i18n').t

module.exports = Em.Component.extend({
    layoutName: 'components/bills-index-simple-list',

    classNames: ['bills-index-simple-list'],

    filters: [{
        active: true,
        key: 'ALL',
        name: t('bills.simple_index.filter.all')
    }, {
        active: false,
        key: '&state=draft',
        name: t('bills.simple_index.filter.draft')
    }, {
        active: false,
        key: '&state=approved&isPaid=true',
        name: t('bills.simple_index.filter.paid')
    }, {
        active: false,
        key: '&state=approved&isPaid=false&isOverdue=false',
        name: t('bills.simple_index.filter.unpaid')
    }, {
        active: false,
        key: '&state=approved&isPaid=false&isOverdue=true',
        name: t('bills.simple_index.filter.overdue')
    }],

    sortFields: [
        {
            active: false,
            direction: 'desc',
            key: 'status',
            name: t('bills.simple_index.column.status'),
            usable: false
        }, {
            active: false,
            direction: 'desc',
            key: 'createdTime',
            name: t('bills.simple_index.column.submission_date'),
            usable: true
        }, {
            active: false,
            direction: 'desc',
            key: 'entryDate',
            name: t('bills.simple_index.column.entry_date'),
            usable: true
        }, {
            active: false,
            direction: 'desc',
            key: 'lineDescription',
            name: t('bills.simple_index.column.receipt_comment'),
            usable: false
        }, {
            active: false,
            direction: 'desc',
            key: 'vendor',
            name: t('vendor'),
            usable: false
        }, {
            active: false,
            direction: 'desc',
            key: 'category',
            name: t('bills.simple_index.column.category'),
            usable: false
        }, {
            active: false,
            direction: 'desc',
            key: 'amount',
            name: t('bills.simple_index.column.amount'),
            usable: false
        }
    ],

    entryDatePeriod: null,

    updatePagination: function() {
        var pages = []
        var page = this.get('page') || 1
        var pageCount = this.get('pageCount') || 1

        for (var number = 1; number <= pageCount; number++) {
            pages.push({
                isCurrentPage: number === page,
                pageNo: number
            })
        }

        this.set('pages', pages)
    }.observes('bills'),

    hasDraftBills: function() {
        return _.some(this.get('bills'), { state: 'draft' })
    }.property('bills'),

    numSelected: function() {
        var bills = this.get('bills') || []

        return _.size(bills.filter(function(bill) {
            return bill.selected
        }))
    }.property('bills.@each.selected'),

    someSelected: function() {
        return this.get('numSelected') > 0
    }.property('numSelected'),

    allSelected: function() {
        return this.get('bills.length') &&
        !_.some(this.get('bills'), { state: 'draft', selected: false })
    }.property('numSelected'),

    updateParams: function(name, update, key, field) {
        var subjects = this.get(name)

        field = field || 'active'

        subjects = subjects.map(function(subject) {
            subject[field] = update[key] === subject[key]
            return subject
        })

        this.set(name, subjects)
        this.sendParams()
    },

    sendParams: function() {
        var filter = this.get('filters').findBy('active', true)
        var sortField = this.get('sortFields').findBy('active', true)
        var currentPage = this.get('pages').findBy('isCurrentPage', true)
        var q = this.get('q')
        var entryDatePeriod = this.get('entryDatePeriod.value')

        this.sendAction('setParams', {
            filter: filter.key,
            page: currentPage ? currentPage.pageNo : 1,
            sortProperty: sortField && sortField.key,
            sortDirection: sortField && sortField.direction,
            entryDatePeriod: entryDatePeriod,
            q: q
        })
    },

    queryDidChange: function() {
        this.sendParams()
    }.observes('q'),

    updateEntryDateperiod: function() {
        this.sendParams()
    }.observes('entryDatePeriod'),

    uploadCountDidChange: function() {
        var resetFilter = this.get('filters').objectAt(0)
        this.updateParams('filters', resetFilter, 'name')
    }.observes('uploadCount'),

    actions: {
        applyFilter: function(updatedFilter) {
            this.updateParams('filters', updatedFilter, 'name')
        },

        setPage: function(pageNo) {
            var pages = this.get('pages')

            for (var p in pages) {
                if (pages[p]) {
                    pages[p].isCurrentPage = pages[p].pageNo === pageNo
                }
            }

            this.set('pages', pages)
            this.sendParams()
        },

        applySorting: function(updatedSortField) {
            var currentSortField = this.get('sortFields').findBy('active', true)

            if (!updatedSortField.usable) return

            if (currentSortField && updatedSortField.key === currentSortField.key) {
                updatedSortField.direction = updatedSortField.direction === 'desc' ? 'asc' : 'desc'
            }

            this.updateParams('sortFields', updatedSortField, 'name')
        },

        selectBill: function(id) {
            var bills = this.get('bills')

            bills = bills.map(function(bill) {
                if (bill.id === id) {
                    bill.selected = !bill.selected
                }
                return bill
            })

            this.set('bills', bills)
        },

        toggleAllSelected: function() {
            var allSelected = !this.get('allSelected')
            var bills = this.get('bills')

            bills = bills.map(function(bill) {
                if (bill.state === 'draft') {
                    bill.selected = allSelected
                }
                return bill
            })

            this.set('bills', bills)
        },

        deleteSelected: function() {
            var idsToDelete = _.map(_.filter(this.get('bills'), 'selected'), 'id')

            this.sendAction('deleteBills', idsToDelete)
        },

        goToBill: function(bill) {
            this.container.lookup('router:main').transitionTo('bill', bill.id)
        }
    }
})
