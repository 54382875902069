var Window = require('ember-window')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/contact-default-accounts-edit'),

    content: null,

    width: 600,

    accountNatures: function() {
        return [Billy.AccountNature.find('expense'), Billy.AccountNature.find('asset')]
    }.property(),

    actions: {
        save: function() {
            var self = this
            var contact = this.get('content')

            contact
                .save({
                    embed: ['contactPersons']
                })
                .mask()
                .success(function() {
                    self.close()
                })
        },

        cancel: function() {
            this.close()
        }
    }
})
