var t = require('i18n').t

module.exports = require('./dashboard-widget').extend({
    needs: ['application', 'user'],

    userUmbrellas: Em.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    view: 'dashboard-widget-bulletin',

    isLoaded: false,

    bulletins: null,

    tasks: null,

    isUmbrella: false,

    load: function() {
        var self = this
        var api = this.get('api')
        Em.RSVP.hash({
            bulletins: api.request('GET', '/v2/dashboard/bulletins?organizationId=' + this.get('organization.id')),
            tasks: api.getData('/organizations/' + this.get('organization.id') + '/tasks'),
            umbrellas: this.get('userUmbrellas').all()
        })
            .then(function(hash) {
                self.set('bulletins', hash.bulletins.bulletins)

                var tasks = hash.tasks.map(function(task) {
                    return Em.Object.create(task)
                })
                self.set('tasks', tasks)

                self.set('isUmbrella', hash.umbrellas.length > 0)

                self.set('isLoaded', true)
            })
    },

    shouldShowTasks: function() {
        // We show tasks if there are any
        // OR if the user is an umbrella user, then we always show them even if they're empty
        return this.get('tasks.length') > 0 || this.get('isUmbrella')
    }.property('tasks.length', 'isUmbrella'),

    isDk: function() {
        return this.get('organization.country.id') === 'DK'
    }.property('organization.country.id'),

    hasPhoneSupportAccess: function() {
        var organization = this.get('organization')

        return organization && this.get('isDk')
    }.property('isDk'),

    supportOpeningHours: function() {
        return t('support.opening_hours')
    }.property(),

    supportUrl: function() {
        if (!t('support.url')) {
            return null
        }

        return t('support.url')
    }.property(),

    supportEmailUrl: function() {
        return 'mailto:' + t('support.email')
    }.property(),

    supportEmailLabel: function() {
        return t('support.email')
    }.property(),

    supportPhone: function() {
        return t('support.phone')
    }.property()
})
