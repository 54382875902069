var i18n = require('i18n')
var tProperty = i18n.tProperty
var imageThumbnail = require('../utils/image-thumbnail')

module.exports = Em.Component.extend({
    layoutName: 'components/file-area',

    classNames: ['file-area'],
    classNameBindings: ['dropAreaSize', 'hasFile:has-file'],

    isUploading: false,
    hasFile: function() {
        return !this.get('isUploading') && this.get('fileUrl')
    }.property('isUploading', 'fileUrl'),

    uploadUrl: ENV.apiUrl + '/files',

    uploadHeaders: null,

    fileUrl: null,

    fileSizeLimit: null,

    isWriteEnabled: true,

    isPublic: false,

    thumbnailUrl: function() {
        var url = this.get('fileUrl')

        if (!url) {
            return null
        }

        return imageThumbnail(url, {
            size: '256x128'
        })
    }.property('fileUrl'),

    dropSelector: '.file-area',
    dropOverlaySelector: null,
    dropAreaSize: 'small',

    buttonText: tProperty('buttonText'),

    extensionsAccepted: function() {
        return this.get('accept') || 'image/png,image/jpeg,image/gif'
    }.property('accept'),

    actions: {
        didUploadFile: function(context) {
            this.sendAction('didUploadFile', context)
        },

        didStartUploading: function() {
            this.sendAction('didStartUploading', true)
        },

        removeFile: function() {
            this.sendAction('didRemoveFile')
        }
    }
})
