module.exports = Em.ObjectController.extend({
    formattedAmount: function() {
        return (this.get('side.isDebit') ? '+' : '-') + Billy.money(this.get('amount'))
    }.property('amount', 'side'),

    amountColorClass: function() {
        if (this.get('amount') === 0) {
            return ''
        }
        return this.get('side.isDebit') ? 'green-text' : 'red-text'
    }.property('amount', 'side'),

    canBeRemoved: false,
    isSelectable: false,

    isSelected: false,

    actions: {
        remove: Em.K,

        toggleSelect: Em.K
    }
})
