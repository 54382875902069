var Scope = require('../utils/scope')

module.exports = Em.Component.extend({
    auth: Ember.inject.service(),

    layout: require('../templates/components/bank-connection-status-buttons'),

    bankConnections: Em.inject.service(),

    organizationConsentEntries: Em.inject.service(),

    organization: null,

    account: null,

    hasConsent: false,

    consentEntryId: null,

    consentText: null,

    bankConnection: Em.computed.oneWay('account.bankConnection'),

    error: function() {
        var bankConnections = this.get('bankConnections')

        return bankConnections.getErrorReason(this.get('bankConnection.errorCode'))
    }.property('bankConnection.errorCode'),

    hasExpirationDate: function() {
        return !!this.get('account.bankConnection.session.loginTokenExpiryTime')
    }.property('account.bankConnection.session.loginTokenExpiryTime'),

    loadConsent: function() {
        var organizationId = this.get('account.organization.id')
        var self = this

        var consentService = this.get('organizationConsentEntries')
        var context = consentService.context.BANK_INTEGRATION
        var contextVersion = consentService.context.BANK_INTEGRATION_VERSION

        consentService.hasConsent(organizationId, context, contextVersion).then(function(res) {
            self.set('hasConsent', res.consent)
            self.set('consentEntryId', res.id)
            self.set('consentText', res.consentText)
        })
    }.on('init'),

    onLegalNoteConfirm: function() {
        var connection = this.get('account.bankConnection')
        var organizationId = this.get('account.organization.id')

        if (connection.type === 'nordic_api') {
            var bankId = connection.session.bankId
            this.get('bankConnections').renewNordicApi(organizationId, bankId)
        }
    },

    updateBankConnection: function(consentEntry) {
        var consentEntryId = consentEntry.id
        var currentBankConnection = this.get('account.bankConnection')
        var shouldUpdateBankConnection = currentBankConnection && consentEntryId && currentBankConnection.consentEntryId !== consentEntryId

        if (shouldUpdateBankConnection) {
            var changes = {
                type: currentBankConnection.type,
                consentEntryId: consentEntryId,
                accountName: currentBankConnection.accountName,
                accountNo: currentBankConnection.accountNo,
                referenceId: currentBankConnection.referenceId
            }

            this.get('bankConnections').updateBankConnection(this.get('account'), changes)
        }
    },

    withAuthorization: function(onAuthorized) {
        return this.get('auth').withAuthorization(
            onAuthorized,
            Scope.BankSyncWrite,
            'bank_account_overview_connect_bank'
        )
    },

    actions: {
        setupBankConnection: function() {
            this.withAuthorization(function() {
                // Only support new connections with Nordic API
                this.get('bankConnections').setup(this.get('account'), 'nordic_api')
            }.bind(this))
        },

        renewConnection: function() {
            this.withAuthorization(function() {
                var self = this

                this.container.lookup('component:bank-legal-note-modal')
                    .set('organizationId', self.get('account.organization.id'))
                    .set('consentContext', self.get('organizationConsentEntries.context.BANK_INTEGRATION'))
                    .set('consentContextVersion', self.get('organizationConsentEntries.context.BANK_INTEGRATION_VERSION'))
                    .set('onConfirm', function(consentEntry) {
                        self.updateBankConnection(consentEntry)
                        self.onLegalNoteConfirm()
                    })
                    .show()
            }.bind(this))
        },

        openSettings: function() {
            this.withAuthorization(function() {
                this.container.lookup('component:bank-settings-modal')
                    .set('accountId', this.get('account.id'))
                    .show()
            }.bind(this))
        }
    }
})
