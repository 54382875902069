module.exports = Em.ObjectController.extend(Em.Evented, {
    needs: ['organization'],

    actions: {
        createDaybook: function() {
            var self = this
            var daybook = Billy.Daybook.createRecord({
                organization: this.get('controllers.organization.model'),
                isTransactionSummaryEnabled: true
            })
            this.container.lookup('component:daybook-edit-window')
                .set('content', daybook)
                .set('callback', function(daybook) {
                    self.replaceRoute('daybook', daybook)
                })
                .show()
        }
    }
})
