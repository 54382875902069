var RestoreScrollingRoute = require('../mixins/scroll-memory-route')

module.exports = require('./abstract-report').extend(RestoreScrollingRoute, {
    scrollingElement: '.section-body',

    queryParams: {
        mode: {
            replace: true
        },
        date: {
            replace: true
        },
        compare_with: {
            replace: true
        },
        compare_count: {
            replace: true
        },
        period: {
            replace: true
        },
        hide_zeroes: {
            replace: true
        },
        inverted: {
            replace: true
        }
    },

    beforeModel: function() {
        this.transitionTo('reports_all', { queryParams: { initialRoute: '/balance' } })
    },

    setupController: function(controller) {
        controller.applyDefaults()
        this._super(controller)
    }
})
