var storage = require('storage')
var ieDetect = require('ie-detect')

module.exports = Em.View.extend({
    classNameBindings: [':application', 'isIe'],

    campaign: Ember.inject.service(),

    couponOffer: Ember.inject.service(),

    segment: Ember.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    isIe: function() {
        return ieDetect.isIe
    }.property(),

    topBars: function() {
        var organization = this.get('controller.organization')

        if (!organization) {
            return []
        }

        var bars = Em.A()
        this.get('controller.loadBankConnections')

        if (this.get('controller.hasBrowserUnsupportedBar')) {
            var unsupportedBar = this.container.lookup('component:unsupported-browser-bar')
            unsupportedBar.set('organization', organization)
            bars.push(unsupportedBar)
        }
        if (this.get('controller.bankConnectionErrors').length) {
            var bankSyncErrorBar = this.container.lookup('component:bank-connection-error-bar')
            bankSyncErrorBar.set('organization', organization)
            bankSyncErrorBar.set('bankConnectionErrors', this.get('controller.bankConnectionErrors'))
            bars.push(bankSyncErrorBar)
        }
        if (this.get('controller.hasTrialExpirationBar')) {
            var tewBar = this.container.lookup('component:trial-expiration-warning-bar')
            tewBar.set('organization', organization)

            // We can't put this in the trial-expiration-warning-bar as it gets
            // initialised several times when it is shown - we need to follow
            // the state from here
            if (!this.get('hasNotifiedSegmentAboutTrialBar')) {
                this.set('hasNotifiedSegmentAboutTrialBar', true)
                this.get('segment').track('Trial Message Viewed (FE)', {
                    context: 'top_banner'
                })
            }

            bars.push(tewBar)
        }
        if (this.get('controller.hasMissingCardBar')) {
            var mcBar = this.container.lookup('component:missing-card-warning-bar')
            mcBar.set('organization', organization)
            bars.push(mcBar)
        }
        if (this.get('controller.hasCampaignOfferBar')) {
            var coBar = this.container.lookup('component:campaign-offer-bar')
            coBar.set('organization', organization)
            coBar.set('campaign', this.get('campaign'))
            coBar.set('data', this.get('campaign').getContent())
            bars.push(coBar)
        }
        if (this.get('controller.hasCouponOfferBar')) {
            var couponBar = this.container.lookup('component:coupon-offer-bar')
            couponBar.set('organization', organization)
            couponBar.set('couponOffer', this.get('couponOffer'))
            couponBar.set('data', this.get('couponOffer').getContent())
            bars.push(couponBar)
        }

        return bars
    }.property(
        'controller.organization',
        'controller.hasTrialExpirationBar',
        'controller.hasMissingCardBar',
        'controller.hasCampaignOfferBar',
        'campaign',
        'controller.hasCouponOfferBar',
        'controller.hasBrowserUnsupportedBar',
        'controller.hasMaintenanceBar',
        'controller.bankConnectionErrors'
    ),

    maintenanceTopBar: function() {
        var organization = this.get('controller.organization')

        if (!organization) {
            return
        }

        var maintenanceBar = this.container.lookup('component:maintenance-bar')
        maintenanceBar.set('organization', organization)
        return maintenanceBar
    }.property(
        'controller.organization',
        'controller.hasMaintenanceBar'
    ),

    billyWelcomeModal: function() {
        var organization = this.get('controller.organization')

        if (!organization) {
            return
        }

        var planSelectedFromSignup = this.get('controller.planSelectedFromSignup')

        if (!storage('billy-welcome-modal') && planSelectedFromSignup) {
            var billyWelcomeModal = this.container.lookup('component:billy-welcome-modal')
            return billyWelcomeModal
        }
    }.property(
        'controller.organization',
        'controller.planSelectedFromSignup'
    ),

    trialEndedModal: function() {
        var organization = this.get('controller.organization')

        if (!organization) {
            return
        }

        var trialHasEnded = !organization.get('isTrial')
        var isBasic = organization.get('subscriptionPlan') === 'basic'

        if (trialHasEnded && isBasic) {
            return this.container.lookup('component:trial-ended-modal')
        }
    }.property(
        'controller.organization'
    ),

    externalInvoicesSyncTopBar: function() {
        var organization = this.get('controller.organization')
        var externalInvoicesSyncShowTopBar = this.get('controller.organization.settings.externalInvoicesSyncShowTopBar')
        var isBrandAgeras = this.get('controller.organization.isBrandAgeras')
        var shouldDisplayTopBar = isBrandAgeras && externalInvoicesSyncShowTopBar === '1'

        if (!shouldDisplayTopBar) {
            return
        }

        var externalInvoicesSyncTopBar = this.container.lookup('component:external-invoices-sync-top-bar')
        externalInvoicesSyncTopBar.set('organization', organization)

        return externalInvoicesSyncTopBar
    }.property(
        'controller.organization.isBrandAgeras',
        'controller.organization',
        'controller.organization.settings.externalInvoicesSyncShowTopBar'
    )
})
