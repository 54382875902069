var Window = require('ember-window')

module.exports = Window.extend({
    api: Ember.inject.service(),

    bankConnections: Ember.inject.service(),

    layout: Window.proto().layout,

    sessions: [],

    selectedSession: null,

    account: null,

    isLoading: false,

    error: null,

    showCreateNew: false,

    validationErrors: Em.Object.create(),

    showPromise: function() {
        this._deferred = Em.RSVP.defer()
        this.show()
        return this._deferred.promise
    },

    errorHandler: function(e) {
        this.set('error', {
            message: e.message
        })
    },

    actions: {
        errorAccept: function() {
            if (!this.get('account.bankIban')) {
                this.close()
                return
            }
            this.set('error', null)
        },

        sessionSelected: function(selectedSession) {
            this.set('selectedSession', selectedSession)
        }
    }
})
