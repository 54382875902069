var ListController = require('billy-webapp/ui/list/controller')

module.exports = ListController.extend(require('../mixins/bill-list-controller'), {
    needs: ['contact'],

    circles: false,

    contact: Em.computed.alias('controllers.contact.model'),

    hasStackedBarChart: true
})
