module.exports = Em.ObjectController.extend({
    needs: ['bankSyncV2', 'organization'],

    model: Em.computed.alias('controllers.bankSyncV2.model'),

    bankLineMatches: function() {
        var account = this.get('model')
        return Billy.BankLineMatch.filter({
            query: {
                account: account,
                isApproved: true
            },
            sortProperty: 'entryDate',
            remoteQuery: {
                include: 'bankLineMatch.lines,bankLineMatch.subjectAssociations,bankLineSubjectAssociation.subject'
            }
        })
    }.property('model', 'matchesSortProperty')
})
