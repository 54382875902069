module.exports = Em.Component.extend({
    layoutName: 'components/skeleton-box',

    classNames: ['skeleton-box'],

    classNameBindings: ['fullHeight:full-height', 'fullWidth:full-width'],

    height: 50,

    width: 150,

    fullHeight: false,

    fullWidth: false,

    didInsertElement: function() {
        var el = this.$()

        if (!this.get('fullHeight') && this.get('height')) {
            el.css('height', this.get('height') + 'px')
        }

        if (!this.get('fullWidth') && this.get('width')) {
            el.css('width', this.get('width') + 'px')
        }
    }
})
