var functionProxy = require('function-proxy')

module.exports = Em.Component.extend({
    classNames: ['floating-ct'],

    didInsertElement: function() {
        this.floatingHeader = FloatingHelper.create({
            el: this.$('.floating-el'),
            ct: this.$(),
            scrollCtSelector: this.get('scrollCtSelector')
        })
    },
    willDestroyElement: function() {
        this.floatingHeader.destroy()
    }
})

var FloatingHelper = Em.Object.extend({
    el: null,
    ct: null,
    scrollCtSelector: null,
    scrollCt: null,
    offset: 0,

    placeholder: null,

    isFloating: false,
    isAtBottom: false,

    init: function() {
        this._super()
        var scrollCt = this.get('scrollCt')
        if (!scrollCt) {
            scrollCt = $(this.get('scrollCtSelector') || '.section-body')
            this.set('scrollCt', scrollCt)
        }
        scrollCt.on('scroll', functionProxy(this.didScroll, this))
        $(window).on('resize', functionProxy(this.didResizeWindow, this))
    },

    willDestroy: function() {
        var scrollCt = this.get('scrollCt')
        scrollCt.off('scroll', functionProxy(this.didScroll, this))
        $(window).off('resize', functionProxy(this.didResizeWindow, this))
    },

    didScroll: function() {
        var el = this.get('el')
        var ct = this.get('ct')
        var scrollCt = this.get('scrollCt')
        var offset = this.get('offset')
        var placeholder = this.get('placeholder')
        var scrollCtTop = scrollCt.offset().top
        if (!this.get('isFloating')) {
            var elTop = el.offset().top

            if (elTop < scrollCtTop + offset) {
                placeholder = $('<div class="floating-placeholder" style="height:' + el.height() + 'px;"></div>')
                placeholder.insertAfter(el)
                this.set('placeholder', placeholder)
                var css = {
                    top: scrollCtTop + 'px',
                    width: el.width() + 'px',
                    paddingTop: offset + 'px'
                }
                el.addClass('floating-fixed')
                el.css(css)
                this.set('isFloating', true)
            }
        } else {
            var placeholderTop = placeholder.offset().top
            if (placeholderTop >= scrollCtTop + offset) {
                el.removeClass('floating-fixed')
                el.css({
                    top: '',
                    width: '',
                    paddingTop: ''
                })
                el.siblings('.floating-placeholder').remove()
                this.set('placeholder', null)
                this.set('isFloating', false)
                if (this.get('isAtBottom')) {
                    el.removeClass('floating-bottom')
                    this.set('isAtBottom', false)
                }
            }
        }
        if (this.get('isFloating')) {
            if (ct) {
                var ctBottom = ct.offset().top + ct.height()
                if (!this.get('isAtBottom')) {
                    if (ctBottom < scrollCtTop + el.height()) {
                        el.addClass('floating-bottom')
                        el.css({
                            top: ''
                        })
                        this.set('isAtBottom', true)
                    }
                } else {
                    if (ctBottom > scrollCtTop + el.height()) {
                        el.removeClass('floating-bottom')
                        el.css({
                            top: scrollCtTop + 'px'
                        })
                        this.set('isAtBottom', false)
                    }
                }
            }
        }
    },

    didResizeWindow: function() {
        if (this.get('isFloating')) {
            this.get('el').width(this.get('placeholder').width())
        }
    }
})
