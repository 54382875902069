var Window = require('ember-window')

module.exports = Window.extend({

    api: Ember.inject.service(),

    layout: require('../templates/components/approve-tax-modal'),

    classNames: ['approve-tax-modal'],

    width: 800,

    approvalLink: null,

    skatTransactionId: null,

    approvalCheckerInterval: null,

    initComponents: function() {
        var self = this
        var link = this.get('approvalLink')
        window.open(link)
        var approvalChecker = setInterval(function() {
            return self.get('api').request('GET', '/integrations/skat/organizations/' + self.get('organizationId') + '/status?registrationNumber=' + self.get('registrationNo') + '&transactionId=' + self.get('skatTransactionId'))
                .then(function(res) {
                    if (res.is_approved) {
                        self.onTaxApproved()
                        clearInterval(approvalChecker)
                        self.destroy()
                    }
                })
                .catch(function(err) {
                    clearInterval(approvalChecker)
                    self.displayErrorNotification(err.error.code)
                    self.destroy()
                })
        }, 3000)

        this.set('approvalCheckerInterval', approvalChecker)
    }.on('didInsertElement'),

    willDestroyElement: function() {
        var approvalChecker = this.get('approvalCheckerInterval')

        if (approvalChecker) {
            clearInterval(approvalChecker)
        }

        this._super()
    }
})
