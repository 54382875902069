var numeral = require('numeral')
var Liquid = require('liquid-js')

Liquid.registerFilters({
    number: function(input, format) {
        return numeral(input).format(format || '0,0')
    },
    money: function(input, currency) {
        return this.number(input, '0,0.00') + (currency ? ' ' + currency.toUpperCase() : '')
    },
    default: function(input, defaultValue) {
        return _.isEmpty(input) ? defaultValue : input
    }
})

// Date.strftime for Danish
Date.ext.locales['da-DK'] = {
    a: ['søn.', 'man.', 'tir.', 'ons.', 'tor.', 'fre.', 'lør.'],
    A: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    b: ['jan.', 'feb.', 'mar.', 'apr.', 'maj', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.'],
    B: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
    c: '%a %e. %b %Y %T %Z',
    p: ['AM', 'PM'],
    P: ['am', 'pm'],
    x: '%d/%m/%y',
    X: '%T'
}
