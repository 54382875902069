var whichTransitionEvent = require('which-transition-event')

module.exports = Em.Component.extend({
    layoutName: 'components/background-task',

    classNames: ['background-task'],

    classNameBindings: ['clickable'],

    task: null,

    hasProgress: function() {
        return typeof this.get('task.progress') === 'number'
    }.property('task.progress'),

    clickable: function() {
        return !!(this.get('task.click') || this.get('task.clickRoute'))
    }.property('task.click', 'task.clickRoute'),

    click: function(e) {
        if (e.within('.close')) {
            return
        }
        var task = this.get('task')
        var click = task.get('click')
        var clickRoute = task.get('clickRoute')
        if (task.get('closeOnClick')) {
            this.send('close')
        }
        if (click) {
            click(task)
        } else {
            this.container.lookup('router:main').transitionTo(clickRoute)
        }
    },

    actions: {
        close: function() {
            var self = this
            var el = this.$()
            var task = this.get('task')
            el.width(el.width())
            setTimeout(function() {
                el.addClass('exiting')
                el.one(whichTransitionEvent(), function() {
                    el.css({
                        'margin-left': '0px',
                        width: '0px'
                    })
                    el.one(whichTransitionEvent(), function() {
                        Em.run(function() {
                            if (task.didClose) {
                                task.didClose()
                            }
                            task.trigger('didClose')
                            self.container.lookup('controller:backgroundTasks').removeObject(task)
                        })
                    })
                })
            }, 0)
        }
    }
})
