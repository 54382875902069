var t = require('i18n').t

module.exports = require('ember-super-field').types.Api.extend({
    cvr: Em.inject.service(),

    noOptionsFoundText: t('ui.fields.superfield.no_companies_found'),

    supportsPaste: true,

    minSignsToEnable: 3,

    url: function(q) {
        return this.get('cvr').getCvrSearchUrl(q)
    },

    parser: function(data) {
        return data.map(function(item) {
            return _.merge(item, {
                cvr: item.id,
                addressOneLine: item.street + ' ' + item.number + ', ' + item.zipcode + ' ' + item.city,
                streetAndNumber: item.number ? item.street + ' ' + item.number : item.street
            })
        })
    },

    optionViewClass: require('ember-super-field').GroupedOptionView.extend({
        hasRawResult: true, // TODO: Find a way to set this flag from the type!! (eg. this.get('field.type.hasRawResult'))
        template: Ember.Handlebars.compile(
            '<div class="company-picker-item" data-cy="company-picker-item">' +
            '<span class="company-picker-item__name"><b>{{view.content.name}}</b></span>' +
            '<span class="company-picker-item__cvr">{{view.content.cvr}}</span>' +
            '<span class="company-picker-item__address">{{view.content.city}}</span>' +
            '</div>'
        )
    })
})
