var InlineCreator = require('./bank-sync-v2-inline-creator')

module.exports = InlineCreator.extend({
    layoutName: 'components/bank-sync-v2/invoice-creator',

    organization: null,

    account: null,

    match: null,

    invoice: null,

    isAttachmentUploading: false,

    lineAmount: function() {
        if (!this.get('match.lines.length')) {
            return 0
        }
        return this.get('match.lines').reduce(function(total, line) {
            return total + (line.get('side.isDebit') ? 1 : -1) * line.get('amount')
        }, 0)
    }.property('match.lines.@each.amount', 'match.lines.@each.side'),

    initModel: function() {
        var invoice = Billy.Invoice.createRecord({
            organization: this.get('organization'),
            currency: this.get('account.currency'),
            entryDate: this.get('match.entryDate'),
            taxMode: 'incl'
        })
        Billy.InvoiceLine.createRecord({
            invoice: invoice,
            quantity: 1,
            unitPrice: this.get('lineAmount')
        })

        this.set('invoice', invoice)
    }.on('init'),

    actions: {
        match: function() {
            var invoice = this.get('invoice')

            invoice.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            }).then(this.createSubjectAssociationAndMatch.bind(this, invoice))
        }
    }
})
