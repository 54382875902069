var t = require('i18n').t
var salesTaxPeriodOptions = require('../utils/sales-tax-period-options')

module.exports = Em.Component.extend({
    layoutName: 'components/organization-fiscal-settings',

    organization: null,
    hideSalesTaxPeriod: false,

    endYear: null,

    endYearError: null,

    monthOptions: function() {
        var options = []
        for (var i = 1; i <= 12; i++) {
            options.push(Em.Object.create({
                value: i,
                name: moment(i + '', 'M').format('MMMM')
            }))
        }
        return options
    }.property(),

    firstFiscalYearEndDidChange: function() {
        if (this._ignoreNextFirstFiscalYearEndDidChange) {
            this._ignoreNextFirstFiscalYearEndDidChange = false
            return
        }
        this._ignoreNextEndYearDidChange = true
        this.set('endYear', this.get('organization.firstFiscalYearEnd').format('YYYY'))
    }.observes('organization.firstFiscalYearEnd').on('init'),

    endYearDidChange: function() {
        if (this._ignoreNextEndYearDidChange) {
            this._ignoreNextEndYearDidChange = false
            return
        }
        var endYear = Number(this.get('endYear'))
        var organization = this.get('organization')
        var firstFiscalYearEnd = moment(endYear + '-' + organization.get('fiscalYearEndMonth') + '-01', 'YYYY-MM-DD').endOf('month')
        if (isNaN(endYear) || endYear < 1950 || endYear > 2050) {
            this.set('endYearError', t('setup.invalid_year'))
            return
        }
        if (!this.get('organization.firstFiscalYearEnd').isSame(firstFiscalYearEnd, 'day')) {
            this._ignoreNextFirstFiscalYearEndDidChange = true
            organization.set('firstFiscalYearEnd', firstFiscalYearEnd)
        }
    }.observes('endYear', 'organization.fiscalYearEndMonth'),

    salesTaxPeriodOptions: function() {
        return salesTaxPeriodOptions.get()
    }.property(),

    helpText: function() {
        return new Em.Handlebars.SafeString(t('organization_fiscal_settings.help_text'))
    }.property()
})
