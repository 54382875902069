var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organizationId}/recurringInvoices',

    urlParamMap: {
        organizationId: 'organization.id'
    },

    createRecord: removeLinks,

    updateRecord: removeLinks
})

// Workaround for https://github.com/emberjs/data/pull/2510
function removeLinks() {
    return this._super.apply(this, arguments)
        .then(function(payload) {
            if (payload && payload.data && payload.data.links) {
                delete payload.data.links.lines
            }
            return payload
        })
}
