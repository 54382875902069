var attr = DS.attr

module.exports = DS.Model.extend({
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    product: attr('billy-data-product', { apiKey: 'productId' }),
    createdTime: attr('moment-datetime'),
    approvedTime: attr('moment-datetime'),
    quantity: attr('number'),
    amount: attr('number'),
    description: attr()
})
