var _ = require('lodash')

module.exports = Em.Route.extend(require('../mixins/dirty-route'), {
    addedLine: false,

    organizationFeatures: Em.inject.service(),

    beforeModel: function(transition) {
        var self = this
        var billId = _.get(transition, 'params.bill.bill_id')

        self.transitionTo('bills', {
            queryParams: _.assign(
                { initialRoute: '/' + billId + '/edit' }
            )
        })
    }
})
