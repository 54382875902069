var i18n = require('i18n')
var t = i18n.t
var tProperty = i18n.tProperty

module.exports = Em.Component.extend({
    classNames: ['no-content'],

    title: tProperty('title', t('ui.list.nothing_here_yet')),

    subtitle: tProperty('subtitle'),

    text: tProperty('text'),

    icon: null,

    youtubeId: tProperty('youtubeId'),

    youtubeVideoSrc: function() {
        var id = this.get('youtubeId')
        if (!id) {
            return null
        }
        return '//www.youtube.com/embed/' + id + '?rel=0&color=white&showinfo=0&wmode=transparent'
    }.property('youtubeId')
})
