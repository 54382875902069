var ListController = require('billy-webapp/ui/list/controller')

module.exports = ListController.extend(require('../mixins/invoice-list-controller'), {
    needs: ['contact', 'organization'],

    circles: false,

    organization: Em.computed.alias('controllers.organization.model'),

    contact: Em.computed.alias('controllers.contact.model'),

    hasStackedBarChart: true,

    isBrandAgeras: function() {
        return this.get('organization.isBrandAgeras')
    }.property('organization.isBrandAgeras'),

    shouldShowRecurringInvoices: function() {
        return !this.get('isBrandAgeras')
    }.property('organization.isBrandAgeras', 'recurringInvoices'),

    shouldShowInvoices: function() {
        return !this.get('isBrandAgeras')
    }.property('organization.isBrandAgeras'),

    recurringInvoices: function() {
        return this.store.findQuery('recurring-invoice', {
            organizationId: this.get('organization.id'),
            contactId: this.get('contact.id')
        })
    }.property('organization.id', 'contact.id')
})
