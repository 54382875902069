var tracking = require('../utils/tracking')
var i18n = require('i18n')

module.exports = Em.Controller.extend({
    queryParams: ['isInternalFlow', 'isInternalUmbrellaFlow', 'umbrellaId'],

    userOrganizations: Em.inject.service(),

    user: null,

    // `umbrellaId` that is used for route in `react-router-dom`
    routeUmbrellaId: null,

    observeUmbrellaId: function() {
        var umbrellaId = this.get('umbrellaId')

        if (umbrellaId) {
            this.set('routeUmbrellaId', umbrellaId)
            this.set('umbrellaId', undefined)
            this.set('isInternalUmbrellaFlow', true)
        }
    }.observes('umbrellaId'),

    signupUmbrellaId: null,

    signupCouponParams: '{}',

    locale: function() {
        var userLocale = this.get('user.locale')
        return userLocale || i18n.locale()
    }.property('user'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }
            var extra = e.detail.extra || {}

            var routeParams = e.detail.extra ? (props || []).concat(extra) : props || []

            this.transitionToRoute.apply(this, [route].concat(routeParams))
        },
        authorizeUser: function(e) {
            var token = e.detail.token

            if (!token) {
                console.error('You have to pass "token" to authorize user')
                return
            }

            var api = this.container.lookup('api:billy')
            api.authorize(token, false)
            tracking.emitAnalyticsEvent('log_in', 'authenticated')
            tracking.emitAnalyticsEvent('conversion', 'user_signup_webapp')
        },
        registerOrganization: function(e) {
            var organizationUrl = e.detail.organizationUrl
            var onSuccess = e.detail.onSuccess
            var onError = e.detail.onError

            if (!organizationUrl) {
                console.error('You have to pass "organizationUrl" to register organization')
                return
            }

            if (!onSuccess || !onError) {
                console.error('You have to pass "onSuccess" and "onError" to register organization')
                return
            }

            var userOrganizations = this.get('userOrganizations')
            userOrganizations.resetAll()
            userOrganizations.bootstrapByUrl(organizationUrl)
                .then(function() {
                    tracking.emitAnalyticsEvent('conversion', 'signup')
                    onSuccess()
                })
                .catch(function(error) {
                    onError(error)
                })
        },
        changeLocale: function(e) {
            var locale = e.detail.locale

            if (!locale) {
                console.error('You have to pass "locale" to change the locale')
                return
            }

            i18n.locale(locale)
        }
    }
})
