var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')

module.exports = ListController.extend({
    needs: ['bill'],

    bill: Em.computed.alias('controllers.bill.model'),

    lines: Em.computed.alias('bill.lines'),

    records: function() {
        return this.get('bill.lines').sortBy('priority')
    }.property('bill.lines.@each.priority'),

    isLazy: false,

    columns: function() {
        return [
            Column.create({
                name: 'description',
                header: t('bill.index.description'),
                flex: 1,
                cellViewClass: TableCellView.extend({
                    extraClass: 'table-cell-multiline'
                })
            }),
            Column.create({
                name: 'account.accountNo',
                header: t('bill.index.account_number'),
                width: 150,
                align: 'right',
                paddingRight: 20
            }),
            Column.create({
                name: 'account.name',
                header: t('bill.index.account'),
                width: 200
            }),
            MoneyColumn.createWithMixins({
                billIndexLinesController: this,
                name: 'amount',
                header: function() {
                    var bill = this.get('billIndexLinesController.bill')
                    if (!bill) {
                        return
                    }
                    var taxMode = bill.get('taxMode')
                    var hasVat = bill.get('organization.hasVat')
                    if (!taxMode) {
                        return
                    }
                    if (hasVat || bill.get('tax') > 0) {
                        return t('amount_' + taxMode + '_' + (hasVat ? 'vat' : 'tax'))
                    } else {
                        return t('bill.index.amount')
                    }
                }.property('billIndexLinesController.bill.taxMode', 'billIndexLinesController.bill.organization.hasVat', 'billIndexLinesController.bill.tax'),
                width: 150,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money amount currencyBinding="invoice.currency"}}'
                    )
                })
            })
        ]
    }.property(),

    totals: function() {
        var totals = []
        var bill = this.get('bill')
        var hasVat = bill.get('organization.hasVat')
        var currency = bill.get('currency')
        var balanceModifiers = bill.get('balanceModifiers').filterBy('isNew', false)

        if (bill.get('organization.hasVat') || bill.get('tax') > 0) {
            // Has VAT
            totals.push({
                label: hasVat ? t('total_excl_vat') : t('total_excl_tax'),
                values: {
                    amount: Billy.money(bill.get('amount'), currency)
                }
            })
            totals.push({
                itemClass: 'dark-border-under',
                label: hasVat ? t('vat') : t('tax'),
                values: {
                    amount: Billy.money(bill.get('tax'), currency)
                }
            })
            totals.push({
                label: hasVat ? t('total_incl_vat') : t('total_incl_tax'),
                values: {
                    amount: Billy.money(bill.get('grossAmount'), currency)
                }
            })
        } else {
            // Does not have VAT, and tax is 0
            totals.push({
                label: t('total'),
                values: {
                    amount: Billy.money(bill.get('amount'), currency)
                }
            })
        }

        balanceModifiers.forEach(function(balanceModifier) {
            if (balanceModifier.get('isVoided')) {
                return
            }

            totals.push({
                labelViewClass: ModifierLabelView,
                balanceModifier: balanceModifier,
                values: {
                    amount: Billy.money(balanceModifier.get('amount'), currency)
                }
            })
        })
        this.addDarkBorderUnder(totals)
        totals.push({
            itemClass: 'dark-border-under bold',
            label: t('bill.index.amount_due'),
            values: {
                amount: Billy.money(bill.get('balance'), currency)
            }
        })
        return totals
    }.property('bill.amount', 'bill.currency', 'bill.creditNotes.@each.creditedAmount', 'bill.creditedAmount', 'bill.balanceModifiers.@each.modifier.amount', 'bill.balanceModifiers.@each.modifier.descriptionWithDate'),

    addDarkBorderUnder: function(totals) {
        totals[totals.length - 1].itemClass = (totals[totals.length - 1].itemClass) + ' dark-border-under'
    }
})

var ModifierLabelView = Em.View.extend({
    template: Em.Handlebars.compile(
        '{{#link-to view.content.balanceModifier.modifier.route view.content.balanceModifier.modifier}}{{view.content.balanceModifier.descriptionWithDate}}{{/link-to}}'
    )
})
