var ListController = require('billy-webapp/ui/list/controller')
var Column = require('billy-webapp/ui/list/columns/column')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var FiscalYearPeriod = require('../utils/period-selector/fiscal-year-period')
var FiscalYear = require('../utils/accounting/fiscal-year')
var periodCp = require('../utils/period-computed-property')
var numeral = require('numeral')
var t = require('i18n').t

module.exports = ListController.extend({
    queryParams: {
        sortProperty: 'sort_property',
        sortDirection: 'sort_direction',
        periodValue: 'period',
        q: ''
    },

    needs: ['organization'],

    organization: Em.computed.alias('controllers.organization.model'),

    sortProperty: 'name',

    sortDirection: 'ASC',

    periodValue: '',

    period: periodCp('periodValue'),

    q: '',

    isEmpty: function() {
        return !this.get('inventory.products').length
    }.property('inventory.products'),

    limit: 500,

    query: function() {
        var query = {
            organizationId: this.get('organization.id'),
            sortProperty: this.get('sortProperty'),
            sortDirection: this.get('sortDirection'),
            q: this.get('q')
        }
        var period = this.get('period')
        if (period) {
            var periodValue = period.get('value')
            if (period instanceof FiscalYearPeriod) {
                var fiscalYear = FiscalYear.parse(this.get('organization'), periodValue)
                periodValue = 'dates:' + fiscalYear.startDate.format('YYYY-MM-DD') +
                    '...' + fiscalYear.endDate.format('YYYY-MM-DD')
            }
            query.entryDatePeriod = periodValue
        }
        return query
    }.property('organization', 'sortProperty', 'sortDirection', 'period', 'q'),

    isLoaded: false,

    isTruncated: false,

    records: null,

    inventory: {
        quantity: 0,
        value: 0,
        products: []
    },

    total: 0,

    lastQueryPeriod: null,

    lastOrganizationId: null,

    loadRecords: function() {
        Em.run.once(this, this.loadOnced, true)
    }.observes('query'),

    loadOnced: function(force) {
        var self = this
        var organizationId = this.get('organization.id')
        var query = this.get('query')

        this.set('isLoaded', false)

        // Reset the records immediately if the last loaded products were from a different organization
        if (organizationId !== this.lastOrganizationId) {
            this.set('records', [])
            this.set('inventory', {
                quantity: 0,
                value: 0,
                products: []
            })
            this.set('total', 0)
            this.lastOrganizationId = organizationId
        }

        // Do not request the inventory if the period did not change
        if (query.entryDatePeriod === this.lastQueryPeriod && !force) {
            this.filter()
            this.set('isLoaded', true)
            return
        }
        this.lastQueryPeriod = query.entryDatePeriod

        var url = '/organizations/' + organizationId + '/inventory' +
            (query.entryDatePeriod ? '?period=' + encodeURIComponent(query.entryDatePeriod) : '')

        this.get('api').request('GET', url)
            .then(function(payload) {
                self.set('inventory', payload.data)
                self.filter()
                self.set('isLoaded', true)
            }, function() {})
    },

    filter: function() {
        var query = this.get('query')
        var limit = this.get('limit')
        var inventory = this.get('inventory')
        inventory.value = numeral(inventory.value).format('0,0.00')
        var products = inventory.products

        if (products.length < 1) {
            this.set('isLoaded', true)

            return
        }

        if (query.q) {
            var q = query.q.toLowerCase().split(' ')
            products = products.filter(function(product) {
                var name = product.name.toLowerCase()
                var no = product.no.toLowerCase()
                var includesName = true
                var includesNo = true
                q.forEach(function(t) {
                    includesName = includesName && name.includes(t)
                    includesNo = includesNo && no.includes(t)
                })
                return includesName || includesNo
            })
        }

        if (query.sortProperty) {
            products = products.sort(function(a, b) {
                var cmp = 0
                if (a[query.sortProperty] < b[query.sortProperty]) {
                    cmp = -1
                } else if (a[query.sortProperty] > b[query.sortProperty]) {
                    cmp = 1
                }
                if (query.sortDirection === 'DESC') {
                    cmp = -cmp
                }
                return cmp
            })
        }

        var total = products.length
        var content = Em.ArrayController.create({
            container: this.container,
            parentController: this,
            target: this,
            model: products.slice(0, limit)
        })
        this.set('records', content)
            .set('total', total)
            .set('isTruncated', total > limit)
    },

    hasHeader: false,

    columnsTogglable: true,

    storageKey: 'inventory',

    columns: function() {
        return [
            Column.create({
                header: t('inventory.product_name'),
                name: 'name',
                width: 100
            }),
            Column.create({
                header: t('inventory.product_no'),
                name: 'no',
                flex: 1
            }),
            Column.create({
                header: t('inventory.initial_quantity'),
                name: 'initialQuantity',
                flex: 1
            }),
            MoneyColumn.create({
                header: t('inventory.initial_value'),
                name: 'initialValue',
                flex: 1,
                defaultVisible: false
            }),
            Column.create({
                header: t('inventory.bought_quantity'),
                name: 'boughtQuantity',
                flex: 1
            }),
            MoneyColumn.create({
                header: t('inventory.bought_value'),
                name: 'boughtValue',
                flex: 1,
                defaultVisible: false
            }),
            Column.create({
                header: t('inventory.sold_quantity'),
                name: 'soldQuantity',
                flex: 1
            }),
            MoneyColumn.create({
                header: t('inventory.sold_value'),
                name: 'soldValue',
                flex: 1,
                defaultVisible: false
            }),
            Column.create({
                header: t('inventory.lost_quantity'),
                name: 'lostQuantity',
                flex: 1,
                defaultVisible: false
            }),
            MoneyColumn.create({
                header: t('inventory.lost_value'),
                name: 'lostValue',
                flex: 1,
                defaultVisible: false
            }),
            Column.create({
                header: t('inventory.quantity'),
                name: 'quantity',
                flex: 1
            }),
            MoneyColumn.create({
                header: t('inventory.value'),
                name: 'value',
                flex: 1
            })
        ]
    }.property(),

    actions: {
        createProduct: function() {
            var organization = this.get('organization')
            var product = Billy.Product.createRecord({
                organization: organization,
                account: organization.get('defaultSalesAccount'),
                salesTaxRuleset: organization.get('defaultInventoryTaxRuleset'),
                isInInventory: true
            })
            var self = this
            Billy.ProductPrice.createRecord({
                product: product,
                currency: organization.get('baseCurrency')
            })
            this.container.lookup('component:product-editor')
                .set('content', product)
                .set('callback', function(product) {
                    if (product.get('isInInventory')) {
                        self.loadRecords(true)
                    }
                })
                .show()
        },
        createStatus: function() {
            var organization = this.get('organization')
            var status = this.store.createRecord('inventory-status', {
                organization: organization
            })
            this.container.lookup('component:inventory-status-editor')
                .set('content', status)
                .set('callback', this.loadRecords.bind(this, true))
                .show()
        },
        createPrimo: function() {
            var organization = this.get('organization')
            var status = this.store.createRecord('inventory-primo', {
                organization: organization
            })
            this.container.lookup('component:inventory-primo-editor')
                .set('content', status)
                .set('callback', this.loadRecords.bind(this, true))
                .show()
        }
    }
})
