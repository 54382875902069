var periodCp = require('../utils/period-computed-property')
var t = require('i18n').t

module.exports = Em.ObjectController.extend({
    queryParams: {
        sortDirection: {
            as: 'sort_direction',
            scope: 'controller'
        },
        periodValue: {
            as: 'period',
            scope: 'controller'
        },
        includeVoided: {
            as: 'include_voided',
            scope: 'controller'
        },
        q: {
            scope: 'controller'
        }
    },

    needs: ['accountPostings', 'organization'],

    sortDirection: 'DESC',

    periodValue: '',

    period: periodCp('periodValue'),

    includeVoided: false,

    q: '',

    text: t('accounts.edit_account'),

    organization: Em.computed.alias('controllers.organization.model'),

    canCreateBill: function() {
        return this.get('nature.id') === 'expense'
    }.property('nature.id'),

    accountId: Em.computed.alias('model.id'),

    groupId: Em.computed.alias('model.group.id'),

    customActions: {
        onAccountSave: function() {
            this.set(
                'model',
                Billy.Account.findByIdQuery(this.get('model.id'))
            )
        }
    }
})
