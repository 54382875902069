var t = require('i18n').t

Billy.IndustryGroup.reopen({
    translatedName: function() {
        return t('startguide_company_info.industry_type.' + this.get('name'))
    }.property('name')
})

Billy.IndustryGroup.registerSortMacro('translatedName', ['translatedName'], function(a, b) {
    return a.get('translatedName').localeCompare(b.get('translatedName'))
})
