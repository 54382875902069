module.exports = Em.Component.extend({
    classNames: ['locked-subscription-plan'],

    classNameBindings: ['dim'],

    organizationSubscription: Em.inject.service(),

    organization: null,

    isSubscriptionPeriodYearly: true,

    isSelected: false,

    dim: null,

    didInsertElement: function() {
        this.send('didClickChangePeriod')
    },

    currentPlan: function() {
        return this.get('organizationSubscription').getAllPlans().findBy('id', 'premium')
    }.property(),

    formattedSubscriptionPrice: function() {
        var price = this.get('organizationSubscription').getSubscriptionPriceMonthly('premium', this.get('isSubscriptionPeriodYearly'))

        return Billy.moneyRecurring(price, this.get('organization.subscriptionCurrency'), '0,0', 'monthly')
    }.property('isSubscriptionPeriodYearly', 'organization.subscriptionCurrency', 'organizationSubscription', 'organization.discountPercent'),

    subscriptionPriceWithCoupon: function() {
        var couponDiscountPercent = this.get('organization.couponDiscountPercent')

        if (!couponDiscountPercent) {
            return null
        }

        var couponPeriodInMonths = {
            yearly: 12,
            monthly: 1
        }[this.get('organization.couponPeriod')]
        var price = this.get('organizationSubscription').getSubscriptionPriceMonthly('premium') * couponPeriodInMonths
        price = price * (100 - couponDiscountPercent) / 100

        return price
    }.property('subscriptionPlan', 'organization.couponDiscountPercent'),

    formattedSubscriptionPriceWithCoupon: function() {
        return Billy.money(this.get('subscriptionPriceWithCoupon'), this.get('organization.subscriptionCurrency'), '0,0')
    }.property('subscriptionPriceWithCoupon'),

    yearlySavings: function() {
        var savings = this.get('organizationSubscription').getYearlySavings()

        return Billy.money(savings, this.get('organization.subscriptionCurrency'), '0,0')
    }.property('organizationSubscription', 'organization.subscriptionCurrency'),

    formattedSubscriptionExpires: function() {
        if (this.get('isSubscriptionPeriodYearly')) {
            return moment().add(1, 'years').format('Do MMMM YYYY')
        }

        return moment().add(1, 'months').format('Do MMMM YYYY')
    }.property('isSubscriptionPeriodYearly'),

    actions: {
        toggleSubscriptionPeriod: function() {
            var currentValue = this.get('isSubscriptionPeriodYearly')

            this.set('isSubscriptionPeriodYearly', !currentValue)
            this.send('didClickChangePeriod')
        },

        didClickChangePeriod: function() {
            var monthlyPrice = this.get('currentPlan.monthlyPrice')
            var yearlyPrice = this.get('currentPlan.yearlyPrice')

            this.sendAction('didClickChangePeriod', {
                price: this.get('isSubscriptionPeriodYearly') ? yearlyPrice : monthlyPrice,
                period: this.get('isSubscriptionPeriodYearly') ? 'yearly' : 'monthly'
            })
        },

        didClickChoosePremium: function() {
            this.sendAction('didClickChoosePremium')
        }
    }
})
