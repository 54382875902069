var t = require('i18n').t

module.exports = require('./abstract-annual-report').extend({
    api: Em.inject.service(),

    model: function() {
        var model = this.modelFor('annual_report')

        return Em.RSVP.hash(Object.assign({
            items: this.get('annualReport').getApproveBookkeepingItems(model.commitment),
            inventoryAccountValue: this.get('annualReport').getInventoryAccountValue(model.commitment.year)
        }, model))
    },

    afterModel: function(model) {
        var items = []

        model.items.map(function(item) {
            item.title = t('annual_report.' + item.name + '.title')
            items.push(Em.Object.create(item))
        })

        return items
    },

    setupController: function(controller, model, transition) {
        this._super(controller, model, transition)
        controller.set('model', model)
        controller.set('items', model.items)
        controller.set('inventoryAccountValue', model.inventoryAccountValue)
    }
})
