var Window = require('ember-window')
var t = require('i18n').t

module.exports = Window.extend({
    classNames: ['doublet-checker-window'],

    layout: Window.proto().layout,

    template: require('../templates/components/doublet-checker-window'),

    width: 600,

    model: null,

    matches: [],

    isDuplicateCheckDisabled: function() {
        if (this.get('isInvoice')) {
            return !!this.get('organization.settings.isDuplicateInvoiceCheckDisabled')
        } else if (this.get('isBill')) {
            return !!this.get('organization.settings.isDuplicateBillCheckDisabled')
        }
    }.property('organization.settings.{isDuplicateInvoiceCheckDisabled,isDuplicateBillCheckDisabled}'),

    isInvoice: function() {
        return this.get('model.type') === 'invoice'
    }.property('model.type'),

    isBill: function() {
        return this.get('model.type') === 'bill'
    }.property('model.type'),

    setMatches: function(matches) {
        matches.forEach(function(model, index) {
            model.isFirstMatch = index === 0
        })
        return this.set('matches', matches)
    },

    translationKeyPrefix: function() {
        return 'doublet_checker_window.' + this.model.get('type')
    }.property('model.type'),

    contactTypeText: function() {
        return this.model.get('type') === 'invoice' ? t('customer') : t('vendor')
    }.property('model.type'),

    createdText: function() {
        return t(this.get('translationKeyPrefix') + '.created', {
            count: this.matches.get('length')
        })
    }.property('translationKeyPrefix', 'matches.@each'),

    newText: function() {
        return t(this.get('translationKeyPrefix') + '.new')
    }.property('translationKeyPrefix'),

    previousText: function() {
        return t(this.get('translationKeyPrefix') + '.previous', {
            count: this.matches.get('length')
        })
    }.property('translationKeyPrefix', 'matches.@each'),

    keepNewText: function() {
        return t(this.get('translationKeyPrefix') + '.keep_new')
    }.property('translationKeyPrefix'),

    saveButtonText: function() {
        return t(this.get('translationKeyPrefix') + '.button.save')
    }.property('translationKeyPrefix'),

    deleteButtonText: function() {
        return t(this.get('translationKeyPrefix') + '.button.delete')
    }.property('translationKeyPrefix'),

    actions: {
        didToggleDuplicateCheck: function() {
            var organization = this.get('organization')
            var updatedValue = ''

            if (this.get('isInvoice')) {
                updatedValue = organization.get('settings.isDuplicateInvoiceCheckDisabled') === '1' ? '' : '1'
                organization.set('settings.isDuplicateInvoiceCheckDisabled', updatedValue)
            } else if (this.get('isBill')) {
                updatedValue = organization.get('settings.isDuplicateBillCheckDisabled') === '1' ? '' : '1'
                organization.set('settings.isDuplicateBillCheckDisabled', updatedValue)
            }

            organization.save()
        },

        save: function() {
            this.saveCallback()
            this.close()
        },

        delete: function() {
            this.deleteCallback()
            this.close()
        }
    }
})
