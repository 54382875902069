var t = require('i18n').t
var AnnualReportPurchasing = require('../mixins/annual-report-purchasing')
var NOTIFICATION_KEYS = require('../notificationKeys')
var ADDONS = require('../utils/addons')
var checklistAdditionalQuestions = require('../data/annual-report').checklistAdditionalQuestions

var TAXATION_TYPE = {
    PERSONAL: 'personal',
    VSO: 'vso',
    KAO: 'kao'
}

var TAXATION_ADDON_FORMS = [TAXATION_TYPE.KAO, TAXATION_TYPE.VSO]

module.exports = Em.Component.extend(AnnualReportPurchasing, {
    classNames: ['ar-checklist-question'],

    classNameBindings: ['question.isLocked:is-locked', 'question.isHighlighted:is-highlighted'],

    annualReport: Em.inject.service(),

    cvr: Em.inject.service(),

    errors: [],

    year: null,

    question: null,

    source: 'checklist',

    isLocked: Em.computed.alias('question.isLocked'),

    isHighlighted: Em.computed.alias('question.isHighlighted'),

    isAnswered: function() {
        return !Em.isEmpty(this.get('question.value'))
    }.property('question.value'),

    hasPurchasablePackage: function() {
        return !Em.isEmpty(this.get('question.purchasablePackage'))
    }.property('question.value'),

    hasPurchasablePackageAndARToken: function() {
        return this.get('hasPurchasablePackage') && this.get('annualReport.hasARTokenScope')
    }.property('hasPurchasablePackage'),

    isFauxHidden: function() {
        return this.get('question.isFauxHidden') || checklistAdditionalQuestions.includes(this.get('question.name'))
    }.property('question.{isFauxHidden}'),

    isValid: function() {
        var question = this.get('question')

        if (!this.get('isAnswered')) {
            return false
        }

        return question.get('isStopQuestion')
            ? question.get('value') !== question.get('stopAnswer')
            : true
    }.property('isAnswered', 'errors', 'question.{name,value,isStopQuestion,stopAnswer}'),

    iconState: function() {
        var isAnswered = this.get('isAnswered')
        var isValid = this.get('isValid')

        if (isAnswered && isValid) {
            return 'is-valid'
        } else if (isAnswered && !isValid) {
            return 'is-invalid'
        }
    }.property('isAnswered', 'isValid'),

    title: function() {
        var question = this.get('question')
        var tContext = {
            prevYear: String(parseInt(this.get('year'), 10) - 1),
            year: this.get('year'),
            price: Billy.money(question.get('price'), 'DKK', '0,0.[00]')
        }

        return t(question.get('translationKeyPrefix') + '.title', tContext)
    }.property('year', 'question.{price,translationKeyPrefix}'),

    isTaxationQuestion: function() {
        return this.get('question.name') === 'checklist.wishes_to_use_business_tax_or_capital_return_scheme'
    }.property('question'),

    getTaxationTypeQuestion: function() {
        var self = this

        return this.get('question.sectionQuestions').find(function(question) {
            return question.name === self.get('question.name') + '.type'
        })
    },

    selectedTaxation: function() {
        var typeQuestion = this.getTaxationTypeQuestion()

        return typeQuestion ? typeQuestion.value : null
    }.property(),

    isTaxationPaid: function() {
        return TAXATION_ADDON_FORMS.includes(this.get('selectedTaxation'))
    }.property('selectedTaxation'),

    paidTaxationText: function() {
        return t('annual_report.multiple_choice.checklist.wishes_to_use_business_tax_or_capital_return_scheme.paid_text', {
            taxationForm: this.get('selectedTaxation').toUpperCase()
        })
    }.property('selectedTaxation'),

    taxationOptions: function() {
        return [
            Em.Object.create({
                name: t('annual_report.multiple_choice.checklist.wishes_to_use_business_tax_or_capital_return_scheme.choice.1'),
                value: 'personal'
            }),
            Em.Object.create({
                name: t('annual_report.multiple_choice.checklist.wishes_to_use_business_tax_or_capital_return_scheme.choice.2'),
                value: 'vso'
            }),
            Em.Object.create({
                name: t('annual_report.multiple_choice.checklist.wishes_to_use_business_tax_or_capital_return_scheme.choice.3'),
                value: 'kao'
            })
        ]
    }.property(),

    choices: function() {
        var question = this.get('question')

        return [
            Em.Object.create({
                label: t(question.get('translationKeyPrefix') + '.choice.1'),
                isSelected: question.get('value') === true,
                value: true
            }),
            Em.Object.create({
                label: t(question.get('translationKeyPrefix') + '.choice.2'),
                isSelected: question.get('value') === false,
                value: false
            })
        ]
    }.property('question.{value,translationKeyPrefix}'),

    description: function() {
        var annualReportService = this.get('annualReport')
        var question = this.get('question')
        var tContext = {
            prevYear: String(parseInt(this.get('year'), 10) - 1),
            year: this.get('year'),
            price: Billy.money(question.get('price'), 'DKK', '0,0.[00]'),
            accountingPackagePrice: annualReportService.getDisplayPriceAccountedForCoupons(ADDONS.ACCOUNTING_PACKAGE.unitPrice, ADDONS.ACCOUNTING_PACKAGE.id)
        }

        if (Em.isEmpty(question.get('value'))) {
            // unanswered questions have no description
            return
        }

        var value = question.get('value')
        var isYesDescription = question.get('stopAnswer')
        var description = ((isYesDescription && value) || (!isYesDescription && !value)) &&
            t(question.get('translationKeyPrefix') + '.content', tContext)

        return description || ''
    }.property('question.{value,price,translationKeyPrefix}', 'errors'),

    _toggleDropdown: function() {
        var self = this

        Em.run.scheduleOnce('afterRender', this, function() {
            var description = self.get('description')
            self.$('.collapse-body')
                .css({
                    height: description ? self.$('.collapse-body-inner').css('height') : 0
                })
        })
    }.observes('description'),

    didInsertElement: function() {
        this._toggleDropdown()
    },

    actions: {
        chooseTaxation: function(value) {
            var annualReportService = this.get('annualReport')
            var year = this.get('year')
            var taxationRequiresAddon = TAXATION_ADDON_FORMS.includes(value)

            // save taxation main question value
            this.send('saveQuestion', taxationRequiresAddon)

            // save type additionally to restore the select box
            annualReportService.putAnswer(year, [{ name: this.get('question.name') + '.type', value: value }])
                .catch(function() {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_CHECKLIST_CHOOSE, t('annual_report.request.validation_error'))
                })
        },
        choose: function(value) {
            this.send('saveQuestion', value)
        },
        saveQuestion: function(value) {
            var self = this
            var annualReportService = this.get('annualReport')
            var year = this.get('year')
            var question = this.get('question')
            var name = this.get('question.name')

            if (this.get('isLocked')) {
                this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_CHECKLIST_CHOOSE, t('annual_report.request.locked_error'))
                return
            }

            question.set('value', value)
            annualReportService.putAnswer(year, [{ name: name, value: value }])
                .catch(function() {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_CHECKLIST_CHOOSE, t('annual_report.request.validation_error'))
                    question.set('question.value', !value) // reset if it failed to save
                })
        },
        readMore: function() {
            var self = this
            var packageName = this.get('question.purchasablePackage')
            var annualReportService = this.get('annualReport')
            if (!packageName) return

            var modal = self.container.lookup('component:upgrade-buy-package-modal')
            return this.get('cvr')
                .loadSafe()
                .then(function(data) {
                    modal.set('companyType', data ? data.companyType : '')
                    modal.set('packageName', packageName)
                    modal.set('additionalPackageInfo', {
                        year: self.get('year'),
                        isSoldOutAccountingPackage: annualReportService.isPackageSoldOut(self.get('year'))
                    })
                    modal.set('source', self.get('source'))
                    modal.on('didCancel', function() {})
                    modal.show()
                })
        }
    }
})
