module.exports = Em.Mixin.create({
    cvr: Em.inject.service(),

    annualReport: Em.inject.service(),

    hasBoughtPackage: '',

    openPurchasedPackageModalOnce: function(source) {
        var self = this
        var packageName = this.get('hasBoughtPackage')
        var annualReportService = this.get('annualReport')

        if (!packageName) return

        var modal = self.container.lookup('component:upgrade-buy-package-modal')
        return this.get('cvr')
            .loadSafe()
            .then(function(data) {
                modal.set('companyType', data ? data.companyType : '')
                modal.set('hasBoughtPackage', packageName)
                modal.set('packageName', packageName)
                modal.set('additionalPackageInfo', Em.Object.create({
                    year: self.get('year'),
                    isSoldOutAccountingPackage: annualReportService.isPackageSoldOut(self.get('year'))
                }))
                modal.set('source', source)
                modal.on('didCancel', function() { })
                modal.show()
            })
    },

    openPurchasedPackageModal: function() {
        if (this.get('hasBoughtPackage')) {
            Em.run.once(this, this.openPurchasedPackageModalOnce, this.get('source'))
        }
    }.observes('hasBoughtPackage'),

    actions: {
        purchaseAccounting: function() {
            var self = this
            var annualReportService = this.get('annualReport')

            var modal = this.container.lookup('component:upgrade-buy-package-modal')
            return this.get('cvr')
                .loadSafe()
                .then(function(data) {
                    modal.set('companyType', data ? data.companyType : '')
                    modal.set('packageName', 'accountingPackage')
                    modal.set('additionalPackageInfo', {
                        year: self.get('year'),
                        isSoldOutAccountingPackage: annualReportService.isPackageSoldOut(self.get('year'))
                    })
                    modal.set('source', self.get('source'))
                    modal.on('didCancel', function() {})
                    modal.show()
                })
        }
    }
})
