var Window = require('ember-window')

module.exports = Window.extend({
    template: require('../templates/components/daybook-consequences-modal'),

    applyModalMask: false,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    daybookId: '',

    customActions: {
        closeEmber: function() {
            this.close()
        }
    }
})
