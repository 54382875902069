var active = false
var t = require('i18n').t

module.exports = Em.Mixin.create({
    navigationTitleKey: 'dirty_mixin.confirm_navigation',
    navigationMessageKey: 'dirty_mixin.changes_have_not_been_saved_yet',
    navigationYesKey: 'dirty_mixin.leave_this_page',
    navigationNoKey: 'dirty_mixin.stay_on_this_page',

    /**
     * Returns a promise if a window was created, or null if one was already active
     *
     * @returns {Ember.RSVP.Promise}|null
     */
    _getConfirmNavigationWindow: function() {
        // Don't show window if window is already active
        if (active) {
            return null
        }

        active = true
        var self = this
        return new Ember.RSVP.Promise(function(resolve) {
            self.container.lookup('util:dialog')
                .dialog(t(self.get('navigationTitleKey')), t(self.get('navigationMessageKey')), [
                    {
                        value: 'yes',
                        text: t(self.get('navigationYesKey')),
                        primary: false,
                        warning: true,
                        align: 'right'
                    },
                    {
                        value: 'no',
                        text: t(self.get('navigationNoKey')),
                        align: 'left'
                    }
                ], {
                    focusSelector: '.window-footer .right button',
                    closable: true
                })
                .then(function(value) {
                    // Resolve the confirm window promise
                    if (value === 'yes') {
                        resolve()
                    }

                    // Set active to false so window can be showed again
                    active = false
                })
        })
    }
})
