var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    organization: null,

    app: null,

    available: false,

    initInstalled: function() {
        var self = this
        this.queryAccount('GET')
            .then(function(result) {
                var isOrgFound = result && result.data && result.data.status !== 'NotFound'

                self.set('installed', isOrgFound)
                self.set('available', true)
            }, function(xhr) {
                if (xhr.status === 404 && Em.get(xhr, 'responseJSON.errors.0.code') === 'UNKNOWN_SPROOM_ACCOUNT') {
                    self.set('installed', false)
                    self.set('available', true)
                }
                // else there was an internal error when trying to retrieve SproomAccount
            })
    }.on('init').observes('organization', 'app.installAccount'),

    queryAccount: function(method) {
        var organizationId = this.get('organization.id')
        var accountName = this.get('app.installAccount')
        var host = ENV.isTest ? '' : ENV.newApiUrl
        var url = host + '/organizations/' + organizationId + '/' + accountName
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return Em.RSVP.resolve($.ajax({
            type: method,
            url: url,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }))
    },

    deleteAccount: function(organizationId, accountName) {
        var host = ENV.isTest ? '' : ENV.newApiUrl
        var url = host + '/organizations/' + organizationId + '/' + accountName
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return Em.RSVP.resolve($.ajax({
            type: 'DELETE',
            url: url,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }))
    },

    installAppStoreItem: function(appStoreItem) {
        var self = this

        return new Em.RSVP.Promise(function(resolve) {
            self.container.lookup('component:app-store-account-installer')
                .set('appStoreItem', appStoreItem)
                .set('callback', function() {
                    resolve()
                })
                .show()
        })
    },

    uninstallAppStoreItem: function(appStoreItem) {
        var self = this

        return new Em.RSVP.Promise(function(resolve, reject) {
            self.container.lookup('util:dialog').confirmWarning(t('app_store.uninstall_confirm_title'), t('app_store.uninstall_confirm_text'), t('app_store.uninstall_confirm_yes'))
                .then(function() {
                    batmask.maskDelayed()
                    self.deleteAccount(appStoreItem.organization.id, appStoreItem.app.installAccount)
                        .then(function() {
                            var uninstalledMessage = self.get('app.uninstalledMessage')
                            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, uninstalledMessage)
                            resolve()
                        }, function() {
                            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, t('util.request.default_error'))
                            reject()
                        })
                        .finally(function() {
                            batmask.unmask()
                        })
                })
        })
    },

    actions: {
        install: function() {
            this.container.lookup('component:app-store-account-installer')
                .set('appStoreItem', this)
                .show()
        },

        uninstall: function() {
            var self = this
            this.container.lookup('util:dialog').confirmWarning(t('app_store.uninstall_confirm_title'), t('app_store.uninstall_confirm_text'), t('app_store.uninstall_confirm_yes'))
                .then(function() {
                    batmask.maskDelayed()
                    self.queryAccount('DELETE')
                        .then(function() {
                            self.set('installed', false)
                            var uninstalledMessage = self.get('app.uninstalledMessage')
                            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, uninstalledMessage)
                        }, function() {
                            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, t('util.request.default_error'))
                        })
                        .finally(function() {
                            batmask.unmask()
                        })
                })
        }
    }
})
