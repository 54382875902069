/*
Makes sure the object is flat (no nested keys), since the Ember.I18n version
that we use does not support it.
*/
module.exports = function(hash) {
    handleHash('', hash, hash)
    return hash
}

function handleHash(path, hash, root) {
    var v

    for (var k in hash) {
        if (Object.prototype.hasOwnProperty.call(hash, k)) {
            v = hash[k]

            if (typeof v === 'string') {
                if (path) {
                    root[path + k] = v
                }
            } else {
                delete hash[k]
                handleHash(path + k + '.', v, root)
            }
        }
    }
}
