var first = require('lodash').first

module.exports = Em.Component.extend({
    layoutName: 'components/bank-connection-error-bar',

    classNames: ['top-bar', 'top-bar-yellow'],

    bankConnections: Em.inject.service(),

    organization: null,

    bankConnectionErrors: [],

    limited: false,

    currentBankConnectionError: function() {
        return first(this.get('bankConnectionErrors')) || {}
    }.property('bankConnectionErrors'),

    errorMessage: function() {
        var errorCode = this.get('currentBankConnectionError').errorCode
        var errorMessage = this.get('bankConnections').getErrorReason(errorCode)

        return errorMessage
    }.property('currentBankConnectionError'),

    actions: {
        renewSession: function() {
            var self = this
            var accountId = this.get('currentBankConnectionError').accountId

            self.container.lookup('router:main').transitionTo('bank_sync', self.get('organization'), accountId)
        }
    }
})
