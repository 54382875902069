module.exports = Em.Component.extend({
    classNames: ['feature-list-addon-item'],

    tagName: 'li',

    item: null,

    addonPrice: function() {
        var price = this.get('item.price')

        return price ? Billy.money(price, 'DKK', '0,0') : price
    }.property('item.price'),

    addonTypeClassName: function() {
        var available = this.get('item.available')
        var price = this.get('item.price')

        if (available && price) {
            return 'is-opt-in'
        } else if (available && !price) {
            return 'is-included'
        } else {
            return 'is-not-included'
        }
    }.property('item.{available,price}'),

    iconPath: function() {
        var className = this.get('addonTypeClassName')

        switch (className) {
        case 'is-included':
            return 'icons/rounded-check'
        case 'is-not-included':
            return 'icons/rounded-cross'
        default:
        }
    }.property('addonTypeClassName')
})
