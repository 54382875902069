var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var snapshotInvoiceMock = require('../utils/snapshot-invoice-mock')

module.exports = Em.Component.extend({
    needs: ['user'],

    templates: Ember.inject.service(),

    api: Em.inject.service(),

    userOrganizations: Ember.inject.service(),

    constraints: Ember.inject.service(),

    layout: require('../templates/components/template-list'),

    templatesUrl: function() {
        var organization = this.get('userOrganizations.activeOrganization')

        return '/organizations/' + organization.get('id') + '/templates'
    }.property('organization.id'),

    standardTemplate: null,

    getStandardTemplate: function() {
        var self = this
        var organization = this.get('userOrganizations.activeOrganization')

        return this.get('api').request('GET', '/v2/organizations/' + organization.get('id') + '/standardTemplate')
            .then(function(payload) {
                self.set('standardTemplate', payload.standardTemplate)
            })
    }.observes('userOrganizations', 'api').on('init'),

    templateList: [],

    getTemplateList: function() {
        var self = this

        var templates = this.get('templates')
        var organization = this.get('userOrganizations.activeOrganization')
        var organizationLocale = organization.get('locale.id')

        var documentLayoutPromise = this.get('api').request('GET', '/documentLayouts/invoice.liquid', { headers: { 'accept-language': organizationLocale } })

        return documentLayoutPromise.then(function(documentLayout) {
            return templates.load()
                .then(function() {
                    var templateList = templates.all().map(function(template) {
                        return {
                            isDefault: template.id === organization.get('defaultTemplateId'),
                            thumbHTML: snapshotInvoiceMock(template, documentLayout, organization, 'invoice-document', organizationLocale),
                            data: template
                        }
                    })

                    self.set('templateList', templateList)
                })
        })
    }.observes('userOrganizations', 'documentLayout').on('init'),

    actions: {
        reloadTemplates: function() {
            this.getTemplateList()
        },

        newTemplateClicked: function() {
            var constraintsService = this.get('constraints')
            var organization = this.get('userOrganizations.activeOrganization')
            if (organization.get('subscriptionPlan') === 'basic') {
                return constraintsService.showUpgradeOverlay('invoice_template', 'invoice_template_designer')
            }

            var self = this
            var standardTemplate = this.get('standardTemplate')
            var standardTemplateNameBase = standardTemplate.name
            var templatesWithBaseName = this.get('templateList').filter(function(item) {
                return item.data.name.indexOf(standardTemplateNameBase) !== -1
            })
            var maxPrepended = _.maxBy(templatesWithBaseName, function(o) {
                return o.data.name.match(/(\d+)$/g)
            })

            // prepend a running number to the name of the new template
            if (maxPrepended) {
                standardTemplate.name = standardTemplateNameBase + ' ' + (parseInt(maxPrepended.data.name.match(/(\d+)$/g)[0]) + 1)
            } else {
                standardTemplate.name = standardTemplate.name + ' 1'
            }

            this.get('api').post(this.get('templatesUrl'), {
                mask: true,
                payload: {
                    data: standardTemplate
                }
            })
                .then(function(payload) {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_CREATE, t('templates.created'))
                    self.container.lookup('router:main').transitionTo('template', payload.data.id)
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_CREATE, e.message)
                })
        },

        setTemplateAsDefault: function(template) {
            var self = this
            var organization = this.get('userOrganizations.activeOrganization')

            organization
                .set('defaultTemplateId', template.id)
                .save()
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_SET_DEFAULT, t('templates.set_as_default_success'))
                    self.send('reloadTemplates')
                })
        },

        editTemplate: function(template) {
            this.container.lookup('router:main').transitionTo('template', template)
        },

        duplicateTemplate: function(template) {
            var self = this
            var copy = _.omit(template, ['id'])
            copy.name += t('templates.duplicate_prepend')
            self.get('api').post(self.get('templatesUrl'), {
                mask: true,
                payload: {
                    data: copy
                }
            })
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_DUPLICATE, t('templates.duplicated_on_overview'))
                    self.send('reloadTemplates')
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_DUPLICATE, e.message)
                })
        },

        deleteTemplate: function(template) {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('settings.organization_template.confirm_delete_template'), t('settings.organization_template.yes_delete'), t('cancel'))
                .then(function() {
                    return self.get('api').delete(self.get('templatesUrl') + '/' + template.id, { mask: true })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TEMPLATE_DELETE, t('templates.deleted'))
                    self.send('reloadTemplates')
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_DELETE, e.message)
                })
        }
    }
})
