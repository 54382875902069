var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['collapse-panel'],

    classNameBindings: ['isOpen'],

    isOpen: false,

    title: function() {
        return t(this.get('titleT'))
    }.property('titleT'),

    actions: {
        toggleIsCollapsed: function() {
            var contentHeight = this.$('.collapse-body-inner').css('height')

            this.toggleProperty('isOpen')
            this.$('.collapse-body').css({
                height: this.get('isOpen') ? contentHeight : 0
            })
        }
    }
})
