var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organization}/recurringInvoices/{recurringInvoice}/lines',
    urlParamMap: {
        organization: 'recurringInvoice.organization.id',
        recurringInvoice: 'recurringInvoice.id'
    }
})
