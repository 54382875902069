var userProfilePic = require('../utils/user-profile-pic')

module.exports = Em.Component.extend({
    classNames: ['avatar'],

    src: null,

    name: null,

    fixedSrc: function() {
        return userProfilePic(this.get('src'))
    }.property('src')
})
