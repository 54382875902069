var t = require('i18n').t
var Window = require('ember-window')
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/invoice-ean-sender'),

    invoice: null,

    error: null,

    isLoaded: false,

    needsRegistrationNo: false,

    needsAttContactPerson: false,

    orderRef: null,

    needsAnything: function() {
        return this.get('needsRegistrationNo') || this.get('needsAttContactPerson')
    }.property('needsRegistrationNo', 'needsAttContactPerson'),

    load: function() {
        var self = this
        this.set('isLoaded', false)
        var invoice = this.get('invoice')
        if (!invoice) {
            return
        }
        invoice.promise
            .then(function() {
                return invoice.get('organization').promise
            })
            .then(function(organization) {
                self.set('needsRegistrationNo', Em.isEmpty(organization.get('registrationNo')))
                    .set('needsAttContactPerson', Em.isEmpty(invoice.get('attContactPerson')))
                    .set('isLoaded', true)
            })
    }.on('init').observes('invoice'),

    contactPersonsQuery: function() {
        return {
            contact: this.get('invoice.contact')
        }
    }.property('invoice.contact'),

    postEan: function() {
        var invoice = this.get('invoice')
        var host = ENV.isTest ? '' : ENV.newApiUrl
        var url = host + '/organizations/' + invoice.get('organization.id') + '/invoices/' + invoice.id + '/ean'
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return Em.RSVP.resolve($.ajax({
            type: 'POST',
            url: url,
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/vnd.billy.v2+json'
            },
            data: JSON.stringify({
                data: {
                    orderRef: this.get('orderRef')
                }
            })
        }))
    },

    getSproomAccount: function() {
        var organizationId = this.get('invoice.organization.id')
        var host = ENV.isTest ? '' : ENV.newApiUrl
        var url = host + '/organizations/' + organizationId + '/sproomAccount'
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return Em.RSVP.resolve($.ajax({
            type: 'GET',
            url: url,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }))
    },

    checkStatus: function() {
        var self = this
        return new Em.RSVP.Promise(function(resolve, reject) {
            self.getSproomAccount()
                .then(function(sproomAccount) {
                    if (sproomAccount && sproomAccount.data && sproomAccount.data.status === 'Accepted') {
                        resolve()
                    } else {
                        reject(t('invoice_ean_sender.invalid_status_error'))
                    }
                }, function(xhr) {
                    if (xhr.status === 404 && Em.get(xhr, 'responseJSON.errors.0.code') === 'UNKNOWN_SPROOM_ACCOUNT') {
                        resolve()
                    } else {
                        reject(t('util.request.default_error'))
                    }
                })
        })
    },

    markAsSent: function() {
        var invoice = this.get('invoice')
        invoice.set('sentState', 'sent')
        invoice.save()
    },

    willCancel: function() {
        var invoice = this.get('invoice')
        invoice.rollback()
        invoice.get('organization').rollback()
    },

    isValid: function() {
        var isValid = true
        var invoice = this.get('invoice')
        var organization = invoice.get('organization')

        if (Em.isEmpty(invoice.get('attContactPerson'))) {
            invoice.set('errors.attContactPerson', t('required_field'))
            isValid = false
        }

        if (Em.isEmpty(organization.get('registrationNo'))) {
            organization.set('errors.registrationNo', t('required_field'))
            isValid = false
        }

        return isValid
    },

    saveModels: function() {
        var invoice = this.get('invoice')
        var organization = invoice.get('organization')
        return Em.RSVP.all([
            invoice.save().promise,
            organization.save().promise
        ])
    },

    actions: {
        send: function() {
            var self = this
            this.set('error', null)
            if (!this.isValid()) {
                return
            }
            batmask.maskDelayed()
            this.saveModels()
                .then(this.checkStatus.bind(this))
                .then(function() {
                    self.postEan()
                        .then(function() {
                            self.markAsSent()
                            self.close()
                            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.INVOICE_EAN_SEND, t('invoice_ean_sender.was_sent'))
                        }, function(xhr) {
                            var error
                            if (xhr.status === 422) {
                                error = Em.get(xhr, 'responseJSON.errors.0.detail')
                            }
                            if (!error) {
                                error = t('util.request.default_error')
                            }
                            self.set('error', error)
                        })
                        .finally(function() {
                            batmask.unmask()
                        })
                }, function(error) {
                    self.set('error', error)
                    batmask.unmask()
                })
        }
    }
})
