var Window = require('ember-window')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    classNames: ['confirm-add-ons-purchase'],

    layout: Window.proto().layout,

    template: require('../templates/components/confirm-add-ons-purchase'),

    width: 600,

    focusSelector: null,

    callback: null,

    addons: null,

    totalCostText: null,

    error: null,

    intercom: Em.inject.service(),

    organizationCardSubscription: Em.inject.service(),

    organization: null,

    organizationSubscription: Em.inject.service(),

    renewalDay: null,

    defaultActiveCardSubscription: Em.computed.alias('organization.defaultActiveCardSubscription'),

    supportUrl: t('support_url'),

    addonList: function() {
        return (this.get('addons') || []).map(function(addon) {
            var now = moment.utc().startOf('day') // ignore time
            var periodUnit = (addon.period === 'monthly' && 'month') || (addon.period === 'yearly' && 'year') || null
            var estimatedExpirationDate = addon.expirationDate
                ? moment.utc(addon.expirationDate)
                : (periodUnit && moment.utc(now).add(1, periodUnit)) || null
            var chargeDays = estimatedExpirationDate && estimatedExpirationDate.diff(now, 'days')

            // Figure out daily price
            var periodStartDate = periodUnit && moment.utc(estimatedExpirationDate).subtract(1, periodUnit)
            var monthsInPeriod = periodUnit && estimatedExpirationDate.diff(periodStartDate, 'months')
            var daysInPeriod = periodUnit && estimatedExpirationDate.diff(periodStartDate, 'days')
            var dailyPrice = periodUnit && (monthsInPeriod * addon.price / daysInPeriod)

            // Figure out charge amount
            var valueChange = addon.value - addon.previousValue
            var isUpgrade = valueChange > 0
            var isDowngrade = valueChange < 0
            var chargeUnits = isUpgrade ? valueChange : 0
            var estimatedChargeAmount = periodUnit
                ? Math.round(chargeUnits * chargeDays * dailyPrice * 100) / 100
                : Math.round(chargeUnits * addon.price * 100) / 100

            return _.defaultsDeep({
                isBoolean: addon.type === 'boolean',
                isRenewable: !!periodUnit,
                isUpgrade: isUpgrade,
                isDowngrade: isDowngrade,
                valueChange: valueChange,
                absValueChange: Math.abs(valueChange),
                label: t('organization_subscription.confirm_addon_window.addons.' + addon.id + '.label'),
                periodLabel: t('organization_subscription.confirm_addon_window.addon_periods.' + addon.period + '.label'),
                unitLabel: addon.type !== 'boolean' && t('organization_subscription.confirm_addon_window.addons.' + addon.id + '.unit', { count: 1 }),
                monthlyPrice: addon.price,
                estimatedExpirationDate: estimatedExpirationDate && estimatedExpirationDate.format('LL'),
                estimatedChargeAmount: estimatedChargeAmount
            }, addon, {})
        })
    }.property('addons'),

    nonRenewableAddons: function() {
        return this.get('addonList').filter(function(addon) {
            return !addon.isRenewable
        })
    }.property('addonList'),

    upgradedAddons: function() {
        return this.get('addonList').filter(function(addon) {
            return addon.isRenewable && addon.isUpgrade
        })
    }.property('addonList'),

    downgradedAddons: function() {
        return this.get('addonList').filter(function(addon) {
            return addon.isRenewable && addon.isDowngrade
        })
    }.property('addonList'),

    totalAmount: function() {
        return (this.get('addonList') || []).reduce(function(sum, addon) {
            return Math.round((sum + (addon.estimatedChargeAmount || 0)) * 100) / 100
        }, 0)
    }.property('addonList'),

    actions: {
        confirmPayment: function() {
            var self = this
            var organizationSubscription = this.get('organizationSubscription')

            this.set('error', null) // reset api error

            return organizationSubscription.ensureLoaded()
                .then(function() {
                    return organizationSubscription.editSubscription(organizationSubscription.get('plan.id'), undefined, self.get('addons'))
                })
                .then(function() {
                    return organizationSubscription.load() // reload subscription
                })
                .then(function() {
                    // Emit change event since ember-window does not make the difference between dismissal and close.
                    self.trigger('addonsUpdated')

                    self.set('error', null) // reset api error
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_PAYMENT_CONFIRM, t('changes_saved'))
                    self.close()
                }, function(e) {
                    self.set('error', e.message)
                })
        },

        showIntercomMessenger: function() {
            this.get('intercom').showNewMessage()
        }
    }
})
