var storage = require('storage')

module.exports = Em.Component.extend({
    layoutName: 'components/missing-card-warning-bar',

    classNames: ['top-bar', 'top-bar-yellow'],

    subscription: Ember.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    canClose: function() {
        // Danish organizations cannot close it
        return this.get('organization.country.id') !== 'DK'
    }.property('organization.country.id'),

    actions: {
        close: function() {
            storage('hideMissingCardWarningBar:' + this.get('organization.id'), 'true')
            this.container.lookup('controller:application').notifyPropertyChange('hasMissingCardBar')
        },
        addCardSubscription: function() {
            var subscription = this.get('subscription')
            return subscription.subscribeCard()
        }
    }
})
