module.exports = Em.Component.extend({
    classNames: ['flip-switch'],

    classNameBindings: ['on:flip-switch-on', 'disabled:flip-switch-disabled'],

    param: null,

    on: false,

    onLabel: '',

    offLabel: '',

    disabled: false,

    eventManager: {
        click: function(e, self) {
            if (!self.get('disabled')) {
                self.sendAction('flip', self.get('param'))
            } else {
                self.sendAction('invalidToggle')
            }
        }
    },

    actions: {
        dismiss: function() {
            this.sendAction('dismiss')
            this.destroy()
        }
    }
})
