var BigNumber = require('bignumber.js')
var batmask = require('batmask')
var postingSides = require('posting-sides')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    needs: ['organization'],

    organization: Em.computed.alias('controllers.organization.model'),

    records: null,

    taxRateAccountId: null,

    error: null,

    isSolved: false,

    correctionTransaction: null,

    selection: null,

    allSelected: function() {
        var selected = this.get('selection.length')
        return selected > 0 && this.get('records.length') === selected
    }.property('records.length', 'selection.length'),

    someSelected: function() {
        return !this.get('allSelected') && this.get('selection.length') > 0
    }.property('selection.length', 'allSelected'),

    buttonDisabled: Em.computed.equal('selection.length', 0),

    accountCorrections: function() {
        var groups = this.get('selection').reduce(function(memo, r) {
            var accountId = r.get('accountId')
            var group = memo[accountId]
            if (!group) {
                group = memo[accountId] = {
                    accountId: accountId,
                    accountName: r.get('accountName'),
                    amount: 0
                }
            }
            var correction = toBigNum(r.get('tax')).minus(toBigNum(r.get('correctTax')))
            group.amount = toBigNum(group.amount).plus(correction).toNumber()
            return memo
        }, {})
        var values = _.values(groups)
        return values
    }.property('selection.@each.{accountId,accountName,tax,correctTax}'),

    totalCorrection: function() {
        return this.get('accountCorrections').reduce(function(total, item) {
            return total.plus(toBigNum(item.amount))
        }, toBigNum(0)).toNumber()
    }.property('accountCorrections'),

    createTransaction: function() {
        var extendedDescription = ['Korrigeringen omfatter flg. posteringer:']
        var records = this.get('selection').map(mapSelf)
        records.sort(function(a, b) {
            return a.get('entryDate').localeCompare(b.get('entryDate'))
        })
        records.forEach(function(r) {
            extendedDescription.push(moment(r.get('entryDate')).format('L') + ': ' + r.get('description') + '. Fra ' + Billy.money(r.get('tax')) + ' til ' + Billy.money(r.get('correctTax')) + ' i fradrag.')
        })
        extendedDescription = extendedDescription.join('\n')

        var transaction = Billy.DaybookTransaction.createRecord({
            organization: this.get('organization'),
            state: 'approved',
            entryDate: moment(),
            description: 'Korrigering af forkerte momssatser',
            extendedDescription: extendedDescription,
            apiType: 'billy.dkTaxRateCorrection201503'
        })

        this.get('accountCorrections').forEach(function(item) {
            Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: transaction,
                account: Billy.Account.find(item.accountId),
                amount: Math.abs(item.amount),
                side: item.amount > 0 ? postingSides.debit : postingSides.credit
            })
        })

        var totalCorrection = this.get('totalCorrection')
        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            account: Billy.Account.find(this.get('taxRateAccountId')),
            amount: Math.abs(totalCorrection),
            side: totalCorrection > 0 ? postingSides.credit : postingSides.debit
        })

        return transaction
    },

    actions: {
        toggleSelectAll: function() {
            if (this.get('selection.length') > 0) {
                this.get('selection').forEach(function(c) {
                    c.set('selected', false)
                })
                this.set('selection', [])
            } else {
                var selection = this.get('records').map(mapSelf)
                this.set('selection', selection)
                selection.forEach(function(c) {
                    c.set('selected', true)
                })
            }
        },

        toggleSelect: function(idx) {
            var c = this.get('records').objectAt(idx)
            var selection = this.get('selection')
            if (c.get('selected')) {
                selection.removeObject(c)
                c.set('selected', false)
            } else {
                selection.addObject(c)
                c.set('selected', true)
            }
        },

        create: function() {
            var self = this
            this.set('error', '')

            var transaction

            batmask.maskDelayed()

            transaction = self.createTransaction()

            transaction.save({ embed: ['lines'] })
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TAX_CORRECTION_CREATE, 'Korrigeringsposteringen blev gemt. Du har nu løst problemet.')
                    self.set('isSolved', true)
                    self.set('correctionTransaction', transaction)
                })
                .catch(function(e) {
                    if (transaction) {
                        transaction.rollback()
                    }

                    self.set('error', e.message)
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        toggleIsSolved: function() {
            this.toggleProperty('isSolved')
        }
    }
})

function toBigNum(n) {
    return new BigNumber('' + n)
}

function mapSelf(item) {
    return item
}
