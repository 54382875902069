var ListController = require('billy-webapp/ui/list/controller')

module.exports = ListController.extend({
    needs: ['organization'],

    queryParams: {
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        state: 'state'
    },

    organization: Em.computed.alias('controllers.organization.model'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var options = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, options)
        }
    }
})
