module.exports = Em.Component.extend({
    classNames: ['legend-dot'],

    classNameBindings: ['colorClass'],

    colorClass: function() {
        return 'color-' + this.get('color')
    }.property('color'),

    color: null
})
