module.exports = Em.Component.extend({
    organization: null,

    visible: function() {
        return !this.get('organization.settings.setupGuideFiscalYear')
    }.property('organization.settings.setupGuideFiscalYear'),

    actions: {
        dismiss: function() {
            this.get('organization.settings').set('setupGuideFiscalYear', '1')
        },

        save: function() {
            var organization = this.get('organization')
            organization.save()
                .then(function() {
                    organization.get('settings').set('setupGuideFiscalYear', '1')
                })
        }
    }
})
