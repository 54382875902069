Ember.Handlebars.registerBoundHelper('datetime', function(value, options) {
    if (!value) {
        return ''
    }

    // Convert to local timezone
    value = moment(value)
    value.zone(moment().zone())

    var format = options.hash.format || 'LLL'
    if (typeof value[format] === 'function') {
        return value[format]()
    } else {
        return value.format(format)
    }
})
