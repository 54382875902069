var reactDirtyRoute = require('../mixins/react-dirty-route')
var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(reactDirtyRoute, AuthorizedRoute, {
    scopes: [Scope.BillRead],

    organizationFeatures: Em.inject.service(),

    queryParams: {
        category: {
            replace: true
        },
        userflow: {
            replace: true
        }
    }
})
