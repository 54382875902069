var t = require('i18n').t
var _ = require('lodash')

module.exports = Em.Component.extend({
    layoutName: 'components/startguide-progress-menu',

    classNames: ['startguide-progress-menu'],

    startGuide: Em.inject.service(),

    couponOffer: Ember.inject.service(),

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    menuItems: function() {
        var self = this
        var steps = [].concat(this.get('startGuide.steps')) // Because mutations are coming below
        var signupCouponId = this.get('user.signupCouponId')
        var couponOffer = this.get('couponOffer')

        steps.unshift({ // First step always completed elsewhere
            name: 'login_information',
            completed: true
        })

        return _.compact(steps.map(function(step) {
            // This step is factored into the percent completed, but not visible in this progress menu.
            if (step.name === 'logo') {
                return
            }

            return _.merge(step, {
                route: 'startguide.' + step.name,
                displayName: t('startguide.steps.' + step.name),
                hasCouponBadge: (!!signupCouponId || couponOffer.isApplicable()) && step.name === 'payment_details',
                clickable: self.isStepClickable(step.name),
                // Include the active org if the step requires it, otherwise don't include this prop
                activeOrganization: (self.get('startGuide').isOrganizationBoundStep(step.name) && self.get('organization')) || undefined
            })
        }))
    }.property('startGuide.steps', 'user.signupCouponId', 'couponOffer'),

    isStepClickable: function(name) {
        var userName = this.get('user.name')
        var userPhone = this.get('user.phone')

        return (
            name === 'welcome' ||
            (name === 'company_info' && userName && userPhone) ||
            (this.get('startGuide').isOrganizationBoundStep(name) && this.get('organization'))
        )
    }
})
