module.exports = Em.Component.extend({
    classNames: ['required-add-ons'],

    classNameBindings: ['dim'],

    organization: null,

    addons: null,

    dim: null,

    consolidatedCost: function() {
        var cost = this.get('addons').reduce(function(result, addon) {
            var type = addon.type
            var value = addon.value
            var price = addon.price

            if (type === 'integer' && value > 0) {
                result += price * value
            } else if (type === 'boolean' && value) {
                result += price
            }

            return result
        }, 0)

        this.send('changeCost', cost)

        return cost
    }.property('addons.@each.value'),

    consolidatedCostText: function() {
        var price = this.get('consolidatedCost')

        return Billy.moneyRecurring(price, 'DKK', '0,0', 'monthly')
    }.property('consolidatedCost'),

    actions: {
        changeCost: function(cost) {
            this.sendAction('consolidatedCostChanged', cost)
        }
    }
})
