var ValueResolver = require('../utils/value-resolver')

module.exports = require('./dashboard-widget').extend({
    needs: ['dashboard'],

    view: 'dashboard-widget-invoices',

    summary: function() {
        var self = this
        return ValueResolver.create({
            invalidateOn: 'accounting-changed',
            resolver: function() {
                return new Em.RSVP.Promise(function(resolve, reject) {
                    var api = self.container.lookup('api:billy')
                    api.get('/invoices/summary?organizationId=' + self.get('organization.id'), {
                        success: function(payload) {
                            resolve(payload.summary)
                        },
                        error: reject
                    })
                })
            }
        })
    }.property(),

    willDestroy: function() {
        this.get('summary').destroy()
    },

    actions: {
        didSelectCategory: function(category) {
            this.transitionToRoute('invoices.index', { queryParams: { categoryKey: category && category.get('key') } })
        }
    }
})
