var _ = require('lodash')

Ember.Handlebars.registerHelper('if-scope', function(scope, options) {
    var constraints = this.container.lookup('service:constraints')

    if (constraints.hasScope(scope)) {
        return options.fn(this)
    } else {
        return options.inverse(this)
    }
})

Ember.Handlebars.registerHelper('unless-scope', function(scope, options) {
    var constraints = this.container.lookup('service:constraints')

    if (constraints.hasScope(scope)) {
        return options.inverse(this)
    } else {
        return options.fn(this)
    }
})

Ember.Handlebars.registerHelper('constrained-action', function(scope, actionName, options) {
    if (_.isNil(options)) {
        options = actionName
        actionName = null
    }

    var args = Array.prototype.slice.call(arguments, 1)
    var constraints = this.container.lookup('service:constraints')
    if (!constraints.hasScope(scope)) {
        return Ember.Handlebars.helpers.action.bind(constraints)('showUpgradeOverlay', '', scope, {
            hash: {
                target: 'controller'
            },
            types: options.types,
            data: {
                view: options.data.view,
                keywords: {
                    controller: constraints
                }
            }
        })
    }

    return !_.isNil(actionName) // if no action defined, let it bubble
        ? Ember.Handlebars.helpers.action.apply(this, args)
        : true
})
