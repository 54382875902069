module.exports = Em.Component.extend({
    classNames: ['button-toggle-filter'],

    label: null,

    value: null,

    enabled: null,

    actions: {
        toggleButton: function() {
            this.set('value', !this.get('value'))
        }
    }
})
