Billy.CountryGroup.reopen({
    iconUrl: function() {
        return Billy.flagIcon(this.get('icon'))
    }.property('icon'),

    containsCountry: function(country) {
        var countryId = typeof country === 'string' ? country : country.get('id')
        return this.get('memberCountryIds').contains(countryId)
    }
})
