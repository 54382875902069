var t = require('i18n').t
var batmask = require('batmask')

var NOTIFICATION_KEYS = require('../notificationKeys')
var ACTION_BATCH_SIZE = 50

module.exports = Em.Controller.extend({
    queryParams: ['currentPage', 'scanType', 'sortProperty', 'sortDirection'],

    needs: ['organization'],

    organizationSubscription: Em.inject.service(),

    organization: Em.computed.alias('controllers.organization.model'),

    addons: Em.computed.alias('organizationSubscription.addons'),

    areDraftBillsEmpty: function() {
        return this.get('draftBills').length > 0
    }.property('draftBills'),

    currentPage: 1,

    sortProperty: 'createdTime',

    sortDirection: 'ASC',

    tableColumns: [
        {
            name: 'selectCheckbox',
            width: 40
        },
        {
            name: 'attachmentImage',
            width: 60
        },
        {
            name: 'createdTime',
            header: t('bills.draft_overview.bill_date'),
            width: 120,
            sortable: true
        },
        {
            name: 'contact.name',
            header: t('vendor')
        },
        {
            name: 'expenseCategory',
            header: t('bills.draft_overview.expense_category')
        },
        {
            name: 'amount',
            header: t('excl_vat_short'),
            sortable: true,
            width: 120
        },
        {
            name: 'taxRate',
            header: t('vat'),
            width: 75
        },
        {
            name: 'balance',
            header: t('incl_vat_short'),
            sortable: true,
            width: 120
        }
    ],

    showPagination: function() {
        return this.get('model.bills.paging.pageCount') > 1
    }.property('model.bills.paging.pageCount'),

    pages: function() {
        var self = this
        var pages = Ember.ArrayProxy.create({ content: Ember.A(new Array(this.get('model.bills.paging.pageCount'))) })

        return pages.map(function(item, index) {
            return Ember.Object.create({
                pageNo: index + 1,
                isCurrentPage: index + 1 === self.get('currentPage')
            })
        })
    }.property('model.bills.paging.pageCount', 'currentPage', 'draftBills.@each'),

    draftBills: function() {
        var selectedDraftBills = this.get('selectedDraftBills')
        var records = this.get('model.bills')

        if (!records) {
            return []
        }

        return records.map(function(draftBill) {
            return Ember.Object.create({
                checked: selectedDraftBills.contains(draftBill),
                draftBill: draftBill
            })
        })
    }.property('model.bills', 'selectedDraftBills.@each'),

    selectedDraftBills: function() {
        return []
    }.property(),

    selectedScannableDraftBills: function() {
        return this.get('selectedDraftBills').filterBy('scanType', 'none').filterBy('attachments.length')
    }.property('selectedDraftBills.@each'),

    isAllSelected: function() {
        var selected = this.get('selectedDraftBills.length')
        return selected > 0 && this.get('draftBills.length') === selected
    }.property('draftBills.length', 'selectedDraftBills.length'),

    isSomeSelected: function() {
        return !this.get('isAllSelected') && this.get('selectedDraftBills.length') > 0
    }.property('selectedDraftBills.length', 'isAllSelected'),

    isOnlyScannableSelected: function() {
        var selected = this.get('selectedDraftBills.length')
        return selected > 0 && this.get('selectedScannableDraftBills.length') === selected
    }.property('selectedDraftBills.length', 'selectedScannableDraftBills.length'),

    pollDraftBills: function() {
        this.send('reloadDraftBills')
        this.schedulePoll()
    },

    cancelPoll: function() {
        Em.run.cancel(this._pollTimer)
    },

    schedulePoll: function() {
        this.cancelPoll()
        this._pollTimer = Em.run.later(this, this.pollDraftBills, 60 * 1000)
    },

    batchAction: function(draftBills, action) {
        var self = this
        this.cancelPoll()
        batmask.maskDelayed()

        var total = draftBills.length
        var promises = []
        var batch = null

        for (var i = 0; i < total; i += ACTION_BATCH_SIZE) {
            batch = draftBills.slice(i, i + ACTION_BATCH_SIZE)

            if (action === 'delete') {
                promises.push(BD.deleteRecords(batch))
            } else {
                throw new Error('action ' + action + ' unknown')
            }
        }

        Em.RSVP.Promise.all(promises)
            .then(function() {
                var userFeedback

                switch (action) {
                case 'delete':
                    userFeedback = t('bills.draft_overview.selected_bills.deleted')
                    break
                }
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.BILLS_DELETE, userFeedback)
            })
            .catch(function() {
                var userFeedback

                switch (action) {
                case 'delete':
                    userFeedback = t('bills.draft_overview.selected_bills.delete_failed')
                    break
                }
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BILLS_DELETE, userFeedback)
            })
            .finally(function() {
                batmask.unmask()

                if (self.get('currentPage') === 1) {
                    self.send('reloadDraftBills')
                } else {
                    self.set('currentPage', 1)
                }
            })
    },

    actions: {
        allSelectedChanged: function() {
            if (this.get('selectedDraftBills.length') > 0) {
                this.set('selectedDraftBills', [])
            } else {
                var allDraftBills = this.get('model.bills').map(mapSelf)
                this.set('selectedDraftBills', allDraftBills)
            }
        },

        didSelectDraftBill: function(checked, draftBill) {
            var selectedDraftBills = this.get('selectedDraftBills')
            if (checked && selectedDraftBills.indexOf(draftBill) === -1) {
                selectedDraftBills.pushObject(draftBill)
            } else {
                selectedDraftBills.removeObject(draftBill)
            }
        },

        deleteDraftBills: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('bills.draft_overview.selected_bills.confirm_delete'), t('bills.draft_overview.yes_delete'), t('cancel'))
                .then(function() {
                    self.batchAction(self.get('selectedDraftBills'), 'delete')
                })
        },

        transitionToDraftBill: function(draftBill) {
            this.transitionToRoute('bills', {
                queryParams: {
                    initialRoute: '/' + draftBill.id + '/edit'
                }
            })
        },

        transitionToBulkEdit: function() {
            var firstDraftBill = this.get('draftBills')[0].draftBill

            this.transitionToRoute('bills', {
                queryParams: {
                    initialRoute: '/' + firstDraftBill.id + '/bulk-edit'
                }
            })
        },

        changePage: function(pageNo) {
            this.set('currentPage', pageNo)
        },

        didSelectCategory: function() {
            this.set('currentPage', 1)
        },

        didClickColumn: function(name) {
            var dir = 'ASC'
            if (name === this.get('sortProperty')) {
                dir = this.get('sortDirection') === 'ASC' ? 'DESC' : 'ASC'
            }
            this.setProperties({
                currentPage: 1,
                sortProperty: name,
                sortDirection: dir
            })
        }
    }
})

function mapSelf(item) {
    return item
}
