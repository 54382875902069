var t = require('i18n').t
var postingSides = require('posting-sides')

Billy.Invoice.reopen(require('../mixins/due-model'), require('../mixins/state-model'), {
    comments: function() {
        var comments = Em.A([])
        this.get('commentAssociations').forEach(function(commentAssociation) {
            comments.pushObject(commentAssociation.get('comment'))
        })
        return Ember.ArrayController.create({
            content: comments,
            sortProperties: ['createdTime']
        })
    }.property('commentAssociations.@each'),

    // Computed properties
    isInvoice: Em.computed.equal('type', 'invoice'),
    isCreditNote: Em.computed.equal('type', 'creditNote'),
    isDraft: Em.computed.equal('state', 'draft'),
    isApproved: Em.computed.equal('state', 'approved'),
    isVoided: Em.computed.equal('state', 'voided'),

    // Generic stuff
    shortInvoiceNoWithCustomer: function() {
        return '#' + this.get('invoiceNo') + ' - ' + this.get('contact.name')
    }.property('invoiceNo', 'contact.name'),

    longInvoiceNo: function() {
        var tKey
        if (this.get('isCreditNote')) {
            if (this.get('state') === 'draft') {
                tKey = 'invoice.long_invoice_no.draft_credit_note'
            } else {
                tKey = 'invoice.long_invoice_no.credit_note_no'
            }
        } else {
            if (this.get('state') === 'draft') {
                tKey = 'invoice.long_invoice_no.draft_invoice'
            } else {
                tKey = 'invoice.long_invoice_no.invoice_no'
            }
        }
        return t(tKey, { invoiceNo: this.get('invoiceNo') })
    }.property('invoiceNo', 'state', 'isCreditNote'),
    postingSide: function() {
        return this.get('type') === 'creditNote' ? postingSides.debit : postingSides.credit
    }.property('type'),
    genericDescription: function() {
        var name = this.get('contact.name')
        var isDraft = this.get('isDraft')
        var invoiceNo = this.get('invoiceNo')
        if (this.get('isCreditNote')) {
            if (isDraft) {
                return t('invoice.description.draft_credit', { name: name })
            } else {
                return t('invoice.description.approved_credit', { invoiceNo: invoiceNo, name: name })
            }
        } else {
            if (isDraft) {
                return t('invoice.description.draft_invoice', { name: name })
            } else {
                return t('invoice.description.approved_invoice', { invoiceNo: invoiceNo, name: name })
            }
        }
    }.property('contact.name'),
    detailedDescription: Em.computed.alias('lineDescription'),

    customerPortalUrl: function() {
        return this.get('contact.customerPortalUrl') + '/invoices/' + this.get('id')
    }.property('contact.customerPortalUrl', 'id'),

    hasCashBasedAccounting: function() {
        return false
    }.property()
})
