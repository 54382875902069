Billy.SalesTaxRuleset.reopen({
    findTaxRate: function(country, state, city, contactType) {
        // Fallback
        var taxRate = this.get('fallbackTaxRate')

        // Copy and sort the rules
        var rules = this.get('rules').map(function(rule) { return rule })
        rules.sortBy('priority')

        // Iterate rules. Take the first one that matches
        rules.find(function(rule) {
            var ruleCountry = rule.get('country')
            var ruleCountryGroup = rule.get('countryGroup')
            var ruleState = rule.get('state')
            var ruleCity = rule.get('city')
            var ruleContactType = rule.get('contactType')

            // Check constraints
            if (ruleCountry && ruleCountry !== country) {
                return false
            }
            if (ruleCountryGroup && !ruleCountryGroup.containsCountry(country)) {
                return false
            }
            if (ruleState && ruleState !== state) {
                return false
            }
            if (ruleCity && ruleCity !== city) {
                return false
            }
            if (ruleContactType && ruleContactType !== contactType) {
                return false
            }

            // If nothing returned false, then we have a match
            taxRate = rule.get('taxRate')
            return true
        })
        return taxRate
    }
})
