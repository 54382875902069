var i18n = require('i18n')
var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    organizationConsentEntries: Em.inject.service(),

    fiscalYear: function() {
        return this.get('model.commitment.year')
    }.property('model'),

    organizationName: function() {
        return this.get('organization.name')
    }.property('organization'),

    organizationId: Em.computed.alias('organization.id'),

    termsAccepted: false,

    regnskabAccepted: false,

    termsContent: i18n.t('annual_report.approve_terms.terms.content'),

    regnskabContent: function() {
        return i18n.t('annual_report.approve_terms.regnskab.content', {
            organizationName: this.get('organizationName'),
            fiscalYear: this.get('fiscalYear')
        })
    }.property(),

    isAcceptDisabled: function() {
        return !(this.get('termsAccepted') && this.get('regnskabAccepted'))
    }.property('termsAccepted', 'regnskabAccepted'),

    saveConsentEntry: function(context, contextVersion, consentText, consentValue) {
        this.get('organizationConsentEntries').setConsent(
            this.get('organizationId'),
            context,
            contextVersion,
            consentText,
            consentValue
        )
    },

    initializeConsents: function() {
        var self = this

        return Em.RSVP.all([
            this.get('organizationConsentEntries').hasConsent(
                this.get('organizationId'),
                this.get('organizationConsentEntries.context.MENETO_AR_TERMS'),
                this.get('organizationConsentEntries.context.MENETO_AR_TERMS_VERSION')
            ),
            this.get('organizationConsentEntries').hasConsent(
                this.get('organizationId'),
                this.get('organizationConsentEntries.context.MENETO_AR_REGNSKAB'),
                this.get('organizationConsentEntries.context.MENETO_AR_REGNSKAB_VERSION')
            )]
        ).then(function(data) {
            var termsAccepted = data[0].consent
            var regnskabAccepted = data[1].consent
            self.set('termsAccepted', termsAccepted)
            self.set('regnskabAccepted', regnskabAccepted)
        })
    }.on('init'),

    actions: {
        termsChanged: function(value) {
            var context = this.get('organizationConsentEntries.context.MENETO_AR_TERMS')
            var contextVersion = this.get('organizationConsentEntries.context.MENETO_AR_TERMS_VERSION')
            var consentText = this.get('termsContent')
            this.set('termsAccepted', value)
            this.saveConsentEntry(context, contextVersion, consentText, value)
        },

        regnskabChanged: function(value) {
            var context = this.get('organizationConsentEntries.context.MENETO_AR_REGNSKAB')
            var contextVersion = this.get('organizationConsentEntries.context.MENETO_AR_REGNSKAB_VERSION')
            var consentText = this.get('regnskabContent')
            this.set('regnskabAccepted', value)
            this.saveConsentEntry(context, contextVersion, consentText, value)
        },

        continue: function() {
            var self = this
            var commitmentId = this.get('model.commitment.id')
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { approve_terms: true })
                    .then(function() {
                        return self.get('annualReport').putState(commitmentId, 'completed')
                    })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
