var Scope = require('../utils/scope')
var absoluteRouteUrl = require('../utils/absolute-route-url')
var getLanguageFromLocale = require('../utils/get-language-from-locale')

module.exports = Em.Object.extend({
    auth: Ember.inject.service(),

    isLoaded: false,

    data: null,

    api: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user').get('model')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    bankConnection: false,

    isLoadingBankConnection: true,

    /* keeping only new bank integration */
    /* because it is only used to load all connection for displaying the yellow error bar alert */
    /* the rest of ember flow using this.bankConnection, which is updated from React */
    load: function() {
        var self = this
        var organization = this.get('organization')
        var url = '/banking/' + organization.get('id') + '/accounts'

        if (this.get('isBankSyncReadAuthorized')) {
            return this.get('api').request('GET', url)
                .then(function(response) {
                    var connections = response

                    var bankConnections = connections
                        .map(function(c) {
                            c.isConnected = true

                            return c
                        })

                    self.set('data', bankConnections)

                    return bankConnections
                })
        }
    },

    ensureLoaded: function() {
        return this._loadPromise || this.load()
    },

    isBankSyncReadAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.BankSyncRead)
    }.property(),

    /* empty bank lines view setup button click */
    setup: function(account) {
        var locale = this.get('user.locale')
        var language = getLanguageFromLocale(locale)
        var organization = this.get('organization')
        var redirectUrl = absoluteRouteUrl(this.container, 'bank_sync', account.id)

        var url = '/banking/' + organization.get('id') + '/connect'

        var payload = {
            culture: language,
            redirectUrl: redirectUrl
        }

        return this.get('api').request('POST', url, { payload: payload, accept: 'application/json' }).then(
            function(data) {
                window.location.replace(data.authUrl)
            }
        )
    },

    connectBank: function(account) {
        var self = this

        if (!account) {
            console.error('No account specified for bank connection')
            return
        }

        self.setup(account)
    }
})
