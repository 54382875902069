var whitelistedTypes = {
    ClaimArchived: true,
    ClaimDetailsAccessed: true,
    ClaimDisputed: true,
    ClaimEscalated: true,
    ClaimFeeAdded: true,
    ClaimResumed: true,
    ClaimStarted: true,
    ClaimPaused: true,
    ClaimPaymentCompleted: true,
    Created: true,
    EmailFailed: true,
    EmailSent: true,
    EmailDelivered: true,
    EmailOpened: true,
    InvoiceEanFailed: true,
    InvoiceEanProcessed: true,
    InvoiceEanQueued: true,
    InvoiceEanReceived: true,
    InvoiceEanSent: true,
    InvoiceReminderPrinted: true,
    InvoiceReminderSent: true,
    InvoicePaymentAccepted: true,
    InvoicePaymentSucceeded: true,
    InvoicePaymentFailed: true,
    InvoicePaymentCanceled: true,
    InvoicePaymentChargedBack: true,
    QuoteSigned: true,
    UserCommented: true
}

module.exports = Ember.Component.extend({
    api: Ember.inject.service(),

    classNames: ['timeline'],

    url: null,

    lastEventNumber: null,

    fakeCreated: null,

    entries: null,

    loaded: false,

    loadError: false,

    commentMessage: '',

    commentIsSaving: false,

    commentError: null,

    fakeEntries: function() {
        return []
    }.property(),

    showAlert: function() {
        return this.get('loadError') || this.get('commentError')
    }.property('loadError', 'commentError'),

    start: function() {
        this.set('entries', [])
        // TODO: Some tests halt mysteriously when poll is enabled
        if (!Ember.testing) {
            this.poll()
        }
    }.on('didInsertElement'),

    reset: function() {
        this.set('loaded', false)
        this.set('lastEventNumber', null)
        this.start()
    }.observes('url'),

    poll: function() {
        var self = this
        if (this.get('isDestroying')) {
            return
        }
        this.pollRequest()
            .then(function(newEntries) {
                if (self.get('isDestroying')) {
                    return
                }

                var entries = self.get('entries')

                // Add a fake `created` event in the beginning?
                var fakeCreated = self.get('fakeCreated')
                if (!self.get('loaded') && fakeCreated) {
                    newEntries.push({
                        timestamp: fakeCreated,
                        type: 'Created',
                        properties: {}
                    })
                }

                // Filter and map entries
                var filteredEntries = newEntries
                    .map(function(entry) {
                        entry.type = self.normalizeEventType(entry.type)
                        return entry
                    })
                    .filter(function(entry) {
                        // Filter unknown event types
                        if (!whitelistedTypes[entry.type]) {
                            // eslint-disable-next-line no-console
                            console.warn('Filtering unknown timeline entry type: ' + entry.type, entry)
                            return false
                        }

                        // Check if we have faked this event earlier
                        if (self.checkFake(entry)) {
                            return false
                        }

                        return true
                    })
                    .map(self.mapEntry)

                // Update content
                if (filteredEntries.length > 0) {
                    entries.unshiftObjects(newEntries)
                }
                if (newEntries.length > 0) {
                    self.set('lastEventNumber', newEntries[0].number)
                }
                self.set('loadError', false)
                self.set('loaded', true)
            }, function(e) {
                if (self.get('isDestroying')) {
                    return
                }
                if (e.type === 'API_ERROR') {
                    self.set('loadError', true)
                } else {
                    throw e
                }
            })
    },

    normalizeEventType: function(type) {
        switch (type) {
        case 'InvoiceEmailFailed':
            return 'EmailFailed'
        case 'InvoiceEmailSent':
            return 'EmailSent'
        case 'InvoiceEmailDelivered':
            return 'EmailDelivered'
        case 'InvoiceEmailOpened':
            return 'EmailOpened'
        case 'UserCommentedOnBill':
        case 'UserCommentedOnContact':
        case 'UserCommentedOnInvoice':
        case 'UserCommentedOnTransaction':
        case 'UserCommentedOnSalesTaxReturn':
            return 'UserCommented'

        default:
            return type
        }
    },

    mapEntry: function(entry) {
        if (entry.properties.documentUrl) {
            entry.properties.documentUrl = entry.properties.documentUrl
                .replace('billy-files.s3', 'billy-files-eu.s3')
                .replace('billysbilling.s3', 'billysbilling-eu.s3')
        }

        entry.componentName = 'x-timeline-entry-' + Em.String.dasherize(entry.type)
        entry.formattedTime = moment(new Date(entry.timestamp)).format('LLL')
        return entry
    },

    checkFake: function(entry) {
        var fakeEntries = this.get('fakeEntries')
        var found = fakeEntries.find(function(fake) {
            var ep = entry.properties
            var fp = fake.properties
            if (entry.type === 'UserCommented') {
                return entry.type === fake.type && ep.user.id === fp.user.id && ep.comment === fp.comment
            }
            return false
        })
        if (found) {
            fakeEntries.removeObject(found)
            return true
        } else {
            return false
        }
    },

    currentUrl: function() {
        var url = '/v2' + this.get('url') + '?pageSize=1000'
        var number = this.get('lastEventNumber')
        if (number != null) {
            url += '&lastEventNumber=' + encodeURIComponent(number)
        }
        return url
    }.property('url', 'lastEventNumber'),

    pollRequest: function() {
        return this.get('api').getData(this.get('currentUrl'))
    },

    setupListeners: function() {
        var self = this
        var commentField = this.$('textarea')
        commentField.on('keydown', function(e) {
            if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { // Enter
                e.preventDefault()
                self.postComment()
            }
        })
    }.on('didInsertElement'),

    commentButtonDisabled: function() {
        return this.get('commentIsSaving') || !this.get('commentMessage')
    }.property('commentIsSaving', 'commentMessage'),

    postComment: function() {
        var self = this
        var comment = this.get('commentMessage').trim()
        if (!comment) {
            return
        }
        this.set('commentIsSaving', true)
        this.set('commentError', null)
        this.sendCommentRequest(comment)
            .then(function() {
                if (self.get('isDestroying')) {
                    return
                }
                self.closeComment()
                var user = self.get('user')
                var entry = self.mapEntry({
                    timestamp: moment().format(),
                    type: 'UserCommented',
                    properties: {
                        user: {
                            id: user.get('id'),
                            name: user.get('name'),
                            profilePicUrl: user.get('profilePicUrl')
                        },
                        comment: comment
                    }
                })
                self.get('fakeEntries').push(entry)
                self.get('entries').unshiftObject(entry)
            }, function(e) {
                if (self.get('isDestroying')) {
                    return
                }
                if (e.type === 'API_ERROR') {
                    self.set('commentError', e.message)
                } else {
                    throw e
                }
            })
            .finally(function() {
                if (self.get('isDestroying')) {
                    return
                }
                self.set('commentIsSaving', false)
            })
    },

    sendCommentRequest: function(comment) {
        return this.get('api').request('POST', this.get('url'), {
            payload: {
                data: {
                    comment: comment
                }
            }
        })
    },

    closeComment: function() {
        this.set('commentMessage', '')
        this.set('commentError', null)
    },

    user: function() {
        return this.container.lookup('controller:user').get('model')
    }.property(),

    actions: {
        postComment: function() {
            this.postComment()
        }
    }
})
