var Window = require('ember-window')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/access-token-viewer'),

    content: null,

    callback: null,

    didInsertElement: function() {
        this._super()

        this.$('input').select()
    }
})
