var t = require('i18n').t

module.exports = Em.Component.extend(require('ember-tooltip').Tooltipable, {
    layoutName: 'components/tooltip-wrapper',

    classNames: ['tooltip-wrapper'],

    attributeBindings: ['style'],

    inline: true,

    flex: false,

    style: function() {
        if (this.get('flex')) {
            return 'display: flex;'
        }

        if (this.get('inline')) {
            return 'display: inline-block;'
        }
    }.property('inline'),

    tooltipElement: function() {
        return this.$()
    }.property(),

    tip: function() {
        return this.get('textT') ? t(this.get('textT')) : this.get('text')
    }.property('text', 'textT')
})
