var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.BankReconciliationWrite],

    setupController: function(controller) {
        controller.loadRecords()
    }
})
