var _ = require('lodash')
var qs = require('qs')
var Cookies = require('js-cookie')
var cookieKey = require('../constants/cookieKey')

module.exports = Em.Route.extend({

    segment: Em.inject.service(),

    organizationFeatures: Em.inject.service(),

    beforeModel: function(transition) {
        var self = this
        var billIdToDuplicate = _.get(transition, 'queryParams.from_bill_id')
        var attachmentId = _.get(transition, 'queryParams.attachment_id')
        var contactId = _.get(transition, 'queryParams.contact_id')
        var attachmentIdBulk = _.get(transition, 'queryParams.attachment_id_bulk')
        var initialRoute = '/new'

        if (billIdToDuplicate) {
            initialRoute = '/duplicate/' + billIdToDuplicate
        }

        if (attachmentId) {
            initialRoute = '/new-from-attachment/' + attachmentId
        }

        if (contactId) {
            initialRoute = '/new-from-contact/' + contactId
        }

        if (attachmentIdBulk) {
            initialRoute = '/' + attachmentIdBulk + '/bulk-edit-attachments'
        }

        var bulkQuery = Cookies.get(cookieKey.bulkQuery) || ''
        var queryParams = _.assign(
            qs.parse(bulkQuery, { ignoreQueryPrefix: true })
        )
        Cookies.remove(cookieKey.bulkQuery)

        self.transitionTo('bills', {
            queryParams: _.assign(
                { initialRoute: initialRoute },
                queryParams
            )
        })
    }
})
