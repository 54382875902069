var t = require('i18n').t
var popupWindow = require('../utils/popup-window')
var batmask = require('batmask')
var BILLING_TYPES = require('../billingTypes')

module.exports = Em.Component.extend({
    layoutName: 'components/organization-overdue-invoice-locked-box',
    classNames: ['organization-locked-box'],

    organizationSubscription: Ember.inject.service(),

    subscription: Ember.inject.service(),

    organization: null,

    api: Em.inject.service(),

    _unpaidInvoices: [],

    totalDue: function() {
        return this.get('unpaidInvoices').reduce(function(result, invoice) {
            result += invoice.get('grossAmount')
            return result
        }, 0)
    }.property('unpaidInvoices.@each.grossAmount'),

    totalDueNet: function() {
        return this.get('unpaidInvoices').reduce(function(result, invoice) {
            result += invoice.get('amount')
            return result
        }, 0)
    }.property('unpaidInvoices.@each.amount'),

    emailUrl: function() {
        return 'mailto:' + t('support_email')
    }.property(),

    unpaidInvoices: function() {
        if (this.get('organization.billingType') === BILLING_TYPES.OWN) {
            return this.get('organization.unpaidInvoices')
        }

        return this.get('_unpaidInvoices').map(function(invoice) {
            var invoiceItem = Billy.OrganizationInvoice.createRecord(invoice)
            invoiceItem.set('currency', (invoice.currencyId ? invoice.currencyId : 'DKK'))
            return invoiceItem
        }).sort(function(a, b) {
            return new Date(b.get('createdTime')) - new Date(a.get('createdTime'))
        })
    }.property('_unpaidInvoices'),

    fetchUnpaidInvoices: function() {
        if (this.get('organization.billingType') === BILLING_TYPES.OWN) {
            return
        }

        var url = '/integrations/billing/' + this.get('organization.id') + '/invoices?state=unpaid'

        batmask.mask()
        this.get('api').request('GET', url)
            .catch(function(e) {
                this.container.lookup('util:notification').notify('invoice-fetch-error', t('error_route.title'), 'error')
            }.bind(this))
            .then(function(data) {
                this.set('_unpaidInvoices', data)
            }.bind(this))
            .finally(function() {
                batmask.unmask()
            })
    }.on('init'),

    fetchInvoicePdf: function(invoiceId) {
        var url = '/integrations/billing/' + this.get('organization.id') + '/invoices/' + invoiceId

        batmask.mask()
        this.get('api').request('GET', url)
            .catch(function(e) {
                this.container.lookup('util:notification').notify('invoice-fetch-error', t('error_route.title'), 'error')
            }.bind(this))
            .then(function(resp) {
                var win = window.open()
                win.document.write('<iframe width="100%" height="100%" title="Invoice" src="data:application/pdf;base64, ' +
                resp.pdf + '"></iframe>')
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    actions: {
        openInvoice: function(invoice) {
            if (invoice.get('printUrl')) {
                popupWindow.open(invoice.get('printUrl'))
                return
            }
            this.fetchInvoicePdf(invoice.get('id').toString())
        },
        pay: function() {
            var subscription = this.get('subscription')
            var canMigrate = this.get('organization.isAllowedForUpodiMigration')
            var subscriptionParams = {
                price: this.get('totalDueNet')
            }

            return subscription.subscribeCard(subscriptionParams, canMigrate)
        },
        enterCardDetailsStripe: function() {
            this.get('organizationSubscription').stripeCheckout()
        }
    }
})
