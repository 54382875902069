var Window = require('ember-window')
var storage = require('storage')

module.exports = Window.extend({
    classNames: ['bill-attachment-not-scanning-window'],

    layout: Window.proto().layout,

    template: require('../templates/components/bill-attachment-not-scanning-window'),

    dontShowAgain: function() {
        return storage('hideBillAttachmentNotScanningMessage') === 'true'
    }.property(),

    dontShowAgainDidChange: function() {
        if (this.get('dontShowAgain')) {
            storage('hideBillAttachmentNotScanningMessage', true)
        } else {
            storage.remove('hideBillAttachmentNotScanningMessage')
        }
    }.observes('dontShowAgain'),

    actions: {
        didToggleDontShowAgain: function() {
            this.set('dontShowAgain', !this.get('dontShowAgain'))
        }
    }
})
