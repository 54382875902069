var Window = require('ember-window')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-ruleset-editor'),

    classNames: ['sales-tax-ruleset-editor'],

    customEvent: Ember.inject.service(),

    callback: null,

    width: 800,

    rules: function() {
        return this.get('content.rules')
            .map(function(rule) {
                return rule
            })
            .sortBy('priority')
    }.property('content.rules.isLoaded'),

    scrollToBottom: function() {
        var body = this.element.querySelector('.window-body')
        body.scrollTop = body.scrollHeight
        var rules = body.querySelectorAll('.sales-tax-rule')
        rules[rules.length - 1].querySelector('input').focus()
    },

    moveRule: function(rule, delta) {
        var rules = this.get('rules')
        var oldIndex = rules.indexOf(rule)
        var newIndex = oldIndex + delta
        if (newIndex >= 0 && newIndex < rules.length) {
            rules.removeAt(oldIndex)
            rules.insertAt(newIndex, rule)
        }
    },

    actions: {
        addRule: function() {
            var ruleset = this.get('content')
            var lastPriority = ruleset.get('rules.length') ? Math.max.apply(null, ruleset.get('rules').mapProperty('priority')) : 0
            var rule = Billy.SalesTaxRule.createRecord({
                ruleset: ruleset,
                priority: lastPriority + 1
            })
            this.get('rules').pushObject(rule)
            this.get('customEvent').dispatchEvent(CustomEvent.TaxRulesetUpdated)
            Em.run.schedule('afterRender', this, this.scrollToBottom)
        },

        moveRuleUp: function(rule) {
            this.moveRule(rule, -1)
        },

        moveRuleDown: function(rule) {
            this.moveRule(rule, 1)
        },

        deleteRule: function(rule) {
            this.get('rules').removeObject(rule)
            rule.deleteRecord()
        },

        close: function() {
            this.close()
        },

        save: function() {
            var self = this
            var ruleset = this.get('content')
            var rules = this.get('rules')
            rules.forEach(function(rule, index) {
                rule.set('priority', index + 1)
            })
            ruleset
                .save({
                    embed: ['rules']
                })
                .mask()
                .on('success', function() {
                    self.close()
                    self.get('customEvent').dispatchEvent(CustomEvent.TaxRulesetUpdated)
                    if (self.get('callback')) {
                        self.get('callback')(ruleset)
                    }
                })
        }
    }
})
