var t = require('i18n').t
var BigNumber = require('bignumber.js')
var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    cvrLink: function() {
        return 'https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=' + this.get('organization.registrationNo')
    }.property('organization.registrationNo'),

    editLabel: function() {
        if (this.get('organization.isProprietorship')) {
            return t('edit_proprietary_information')
        } else if (this.get('organization.isCompany')) {
            return t('edit_owner_information')
        }
    }.property('organization.{isProprietorship,isCompany}'),

    flagOrganization: function() {
        return Billy.flagIcon(this.get('organization.country.icon'))
    }.property('organization.country.icon'),

    typeOwner: function() {
        if (this.get('organization.isProprietorship')) {
            return t('proprietors')
        } else if (this.get('organization.isCompany')) {
            return t('owners')
        }
    }.property('organization.{isProprietorship,isCompany}'),

    nominalOwnershipPercent: function() {
        return this.get('organizationOwners').reduce(function(sum, owner) {
            if (owner.get('nominalOwnershipPercent')) {
                return sum.plus(owner.get('nominalOwnershipPercent'))
            }

            return sum.plus(0)
        }, new BigNumber('0'))
    }.property('organizationOwners.@each.nominalOwnershipPercent'),

    showAlert: function() {
        return this.get('nominalOwnershipPercent').toNumber() !== 100 || this.get('organizationOwners').length > 1
    }.property('organizationOwners', 'nominalOwnershipPercent'),

    hasOrganizationOwners: function() {
        return this.get('organizationOwners').length > 0
    }.property('organizationOwners'),

    hasValidCompany: function() {
        return !(!this.get('organization.registrationNo') || !this.get('organization.name') || !this.get('organization.street') || !this.get('organization.zipcode') || !this.get('organization.city'))
    }.property('organization.{registrationNo,name,street,zipcode,city}'),

    hasValidOwners: function() {
        var self = this
        var isValid = true

        if (!this.get('hasOrganizationOwners')) {
            return false
        }

        if (this.get('organization.isCompany')) {
            this.get('organizationOwners').forEach(function(owner) {
                isValid = !(!self.get('hasValidNominalOwnership') || !owner.get('startDateText'))
            })
        }

        return isValid
    }.property('hasOrganizationOwners', 'hasValidNominalOwnership', 'organizationOwners.@each.startDateText', 'organization.isCompany'),

    showNextButton: function() {
        return this.get('hasValidCompany') && this.get('hasValidOwners')
    }.property('hasValidCompany', 'hasValidOwners'),

    hasValidNominalOwnership: function() {
        var missingNominalOwnership = this.get('organizationOwners').filter(function(owner) {
            return !owner.get('nominalOwnershipPercent')
        })

        return this.get('nominalOwnershipPercent').toNumber() === 100 && missingNominalOwnership.length === 0
    }.property('nominalOwnershipPercent', 'organization.isCompany', 'organizationOwners.@each.nominalOwnershipPercent'),

    actions: {
        didApproveCompanyData: function() {
            var self = this
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { company_data: true })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
