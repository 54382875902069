var postingSides = require('posting-sides')

module.exports = Em.Component.extend({
    layoutName: 'components/transaction-multiple-currency-line',

    classNames: ['field-group', 'manual-transaction-line'],

    content: null,

    isBaseAmountDisabled: true,

    actions: {
        delete: function() {
            this.get('content').deleteRecord()
        },

        didSelectAccount: function(account) {
            if (!account) {
                this.set('account', null)
                this.set('baseAmount', null)
                this.set('isBaseAmountDisabled', true)
                this.set('content.currency', null)
                return
            }

            var baseCurrencyId = this.get('baseCurrency.id')
            var accountCurrencyId = account.get('currency.id')
            var isBaseAmountDisabled = baseCurrencyId === accountCurrencyId

            this.set('account', account) // we need that to observe the state later on
            this.set('content.currency', account.get('currency')) // needed for payload to properly handle that on BE side
            this.set('isBaseAmountDisabled', isBaseAmountDisabled)
        },

        onBaseAmountBlur: function() {
            var baseAmount = this.get('baseAmount')

            if (baseAmount < 0) {
                this.set('baseAmount', Math.abs(baseAmount))
            }
        },

        onDebitBlur: function() {
            var debit = this.get('debit')

            if (debit < 0) {
                this.set('debit', Math.abs(debit))
            }
        },

        onCreditBlur: function() {
            var credit = this.get('credit')

            if (credit < 0) {
                this.set('credit', Math.abs(credit))
            }
        }
    },

    debit: null,
    debitDidChange: function() {
        if (this.get('debit') !== null) {
            this.set('credit', null)
        }
        this.set('content.side', postingSides.debit)
        this.set('content.amount', this.get('debit'))
    }.observes('debit'),

    credit: null,
    creditDidChange: function() {
        if (this.get('credit') !== null) {
            this.set('debit', null)
        }
        this.set('content.side', postingSides.credit)
        this.set('content.amount', this.get('credit'))
    }.observes('credit'),

    baseAmount: null,
    baseAmountDidChange: function() {
        this.set('content.baseAmount', Math.abs(this.get('baseAmount')))
    }.observes('baseAmount'),

    baseAmountPrefix: function() {
        var side = this.get('content.side')
        return side && side.isCredit ? '—' : ''
    }.property('content.amount', 'content.side'),

    accountOrAmountDidChange: function() {
        var amount = this.get('content.amount') || 0
        var account = this.get('account') || undefined

        if (!amount || !account) {
            this.set('baseAmount', 0)
            return
        }

        var baseCurrency = this.get('baseCurrency')
        var currency = account.get('currency')

        var exchangedAmount = currency.exchangeTo(amount, baseCurrency)
        this.set('baseAmount', exchangedAmount)
    }.observes('account', 'content.amount'),

    hasMoreThanTwoLines: Em.computed.gt('content.daybookTransaction.lines.length', 2)
})
