var t = require('i18n').t

module.exports = Em.ObjectController.extend({
    stateQuery: function() {
        return {
            country: this.get('country')
        }
    }.property('country'),

    cityQuery: function() {
        return {
            country: this.get('country'),
            state: this.get('state')
        }
    }.property('country', 'state'),

    contactTypeOptions: function() {
        return [
            Em.O({
                value: null,
                name: t('sales_tax_ruleset_editor.any_contact_type')
            }),
            Em.O({
                value: 'company',
                name: t('sales_tax_ruleset_editor.company')
            }),
            Em.O({
                value: 'person',
                name: t('sales_tax_ruleset_editor.person')
            })
        ]
    }.property(),

    noTaxPlaceholder: function() {
        return t(this.get('ruleset.organization.hasVat') ? 'sales_tax_ruleset_editor.no_vat' : 'sales_tax_ruleset_editor.no_tax')
    }.property('ruleset.organization.hasVat'),

    isFirst: function() {
        var rules = this.get('parentController.rules')
        return rules.indexOf(this.get('model')) === 0
    }.property('parentController.rules.@each'),

    isLast: function() {
        var rules = this.get('parentController.rules')
        return rules.indexOf(this.get('model')) === rules.length - 1
    }.property('parentController.rules.@each'),

    actions: {
        didSelectCountryGroup: function(countryGroup) {
            if (!countryGroup) {
                return
            }
            var country = this.get('country')
            if (country && !countryGroup.containsCountry(country)) {
                this.set('country', null)
            }
        },

        didSelectCountry: function(country) {
            if (!country) {
                this.set('state', null)
                this.set('city', null)
                return
            }
            var countryGroup = this.get('countryGroup')
            if (countryGroup && !countryGroup.containsCountry(country)) {
                this.set('countryGroup', null)
            }

            // Unset downward
            if (this.get('state.country.id') !== country.get('id')) {
                this.set('state', null)
            }
            if (this.get('city.country.id') !== country.get('id')) {
                this.set('city', null)
            }
        },

        didSelectState: function(state) {
            if (!state) {
                return
            }

            // Unset downward
            if (this.get('city.state') && (this.get('city.state.id') !== state.get('id'))) {
                this.set('city', null)
            }

            // Set upward
            this.set('country', state.get('country'))
        },

        didSelectCity: function(city) {
            if (!city) {
                return
            }

            // Set upward
            this.set('state', city.get('state'))
            this.set('country', city.get('country'))
        }
    }
})
