var getUserType = require('../utils/get-user-type')

module.exports = Em.ObjectController.extend({
    needs: ['application'],

    sideMenu: Em.inject.service(),

    activeOrganization: Em.computed.alias('sideMenu.activeOrganization'),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership'),

    withSideNavbar: function() {
        // wee need this 'string' hack as web-component doesn't see a property updates when we use 'byBooleanAttrVal' decorator
        return this.get('sideMenu.withSideNavbar') ? 'true' : 'false'
    }.property('sideMenu.withSideNavbar'),

    customActions: {
        navigate: function(e) {
            var self = this

            var currentRoute = self.container.lookup('controller:application').get('currentRouteName')
            var nextRoute = e.detail.route

            if (currentRoute === nextRoute) {
                window.location.hash = '' // reload core route
            }

            // TODO: Keep one rule when bank_sync_react replaces the default route
            if (nextRoute === 'bank_sync' || nextRoute === 'bank_sync_react') {
                Billy.Account.filter({
                    query: {
                        organization: self.get('activeOrganization'),
                        isBankAccount: true,
                        isArchived: false
                    },
                    sortProperty: 'name'
                }).promise.then(function(accounts) {
                    self.transitionToRoute(nextRoute, self.get('activeOrganization'), accounts.get('firstObject'))
                })
                return
            }

            if (nextRoute === 'organization_subscription_upsell') {
                self.transitionToRoute('organization_subscription.plans_selection', self.get('activeOrganization'))
                return
            }

            self.transitionToRoute.apply(self, [nextRoute].concat(e.detail.props || []))
        }
    }
})
