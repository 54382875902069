module.exports = require('./invoice-edit').extend({
    queryParams: {
        fromInvoiceId: 'from_invoice_id',
        fromQuoteId: 'from_quote_id',
        newContactId: 'contact_id',
        fromCreditNote: 'from_credit_note',
        userflow: 'userflow'
    },

    fromInvoiceId: null,
    fromQuoteId: null,
    newContactId: null,
    fromCreditNote: null,
    userflow: null // required by queryParams
})
