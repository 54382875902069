module.exports = Em.Component.extend({
    classNames: ['progress-list'],

    tagName: 'ul',

    annualReport: Em.inject.service(),

    /**
     * @property {AnnualReportStep[]}
     */
    steps: null,

    /**
     * @property {AnnualReportStepEnum}
     */
    currentStep: null,

    /**
     * @property {boolean}
     */
    forceLockList: null,

    /**
     * @returns {boolean}
     */
    lockList: function() {
        return this.get('forceLockList') || this.get('annualReport').isSubmittedState(this.get('model.commitment.state'))
    }.property('forceLockList', 'model.commitment.state'),

    /**
     * @property {AnnualReportModel}
     */
    model: null
})
