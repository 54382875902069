module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')
        return Em.RSVP.hash(Object.assign({
            items: this.get('annualReport').getDebtItems(model.commitment)
        }, model))
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('items', model.items)
        controller.set('steps', model.steps)
        controller.set('years', model.years)
        controller.set('currentStep', model.currentStep)
    }
})
