Billy.ContactPerson.reopen({
    nameWithEmail: function() {
        var text = []
        var firstName = this.get('firstName')
        var lastName = this.get('lastName')
        var name = this.get('name')
        var email = this.get('email')
        if (firstName) {
            text.push(firstName)
        }
        if (lastName) {
            text.push(lastName)
        }
        if (!firstName && !lastName && name) {
            text.push(name)
        }
        if (email) {
            text.push('<' + email + '>')
        }
        return text.join(' ')
    }.property('email', 'firstName', 'lastName', 'name'),

    computedName: function() {
        var firstName = this.get('firstName')
        var lastName = this.get('lastName')
        var name = this.get('name')
        var combinedName = ((firstName || '') + ' ' + (lastName || '')).trim()
        return combinedName || name || ''
    }.property('firstName', 'lastName', 'name')
})
