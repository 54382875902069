var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    classNames: ['organization-owner-panel'],

    userOrganizations: Em.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    organizationOwner: null,

    address: function() {
        var address = {}
        address.city = this.get('organizationOwner.city') || this.get('organizationOwner.cityText')
        address.zipcode = this.get('organizationOwner.zipcode') || this.get('organizationOwner.zipcodeText')

        if (address.city && typeof address.city === 'object') {
            address.city = address.city.get('name')
        }

        if (address.zipcode && typeof address.zipcode === 'object') {
            address.zipcode = address.zipcode.get('zipcode')
        }

        return address
    }.property('organizationOwner.{city,cityText,zipcode,zipcodeText}'),

    actions: {
        editOwner: function(organizationOwner) {
            this.container.lookup('component:organizationOwnerEditor').set('content', organizationOwner).show()
        },

        deleteOwner: function(organizationOwner) {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('confirm_deletion', { name: this.get('organizationOwner.name') }), t('yes'), t('no'))
                .then(function() {
                    organizationOwner.deleteRecord()
                        .mask()
                        .on('success', function() {
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.ORGANIZATION_OWNER_DELETE, t('confirmed_deletion', { name: self.get('organizationOwner.name') }))
                        })
                })
        }
    }
})
