module.exports = Em.Route.extend({
    model: function() {
        return this.modelFor('bank_account')
    },

    afterModel: function(model) {
        if (!model.get('payload')) {
            this.replaceWith('bank_import', model)
        }
    },

    resetController: function(controller, isExiting) {
        this._super.apply(this, arguments)

        if (isExiting) {
            controller.set('columnMapping', null)
        }
    }
})
