var tProperty = require('i18n').tProperty
var formatPaymentTerms = require('../utils/format-payment-terms')

module.exports = Em.Component.extend({
    layoutName: 'components/payment-terms-popover-item',

    classNames: ['selector-item'],

    classNameBindings: ['isActive:active'],

    mode: null,

    days: null,

    name: tProperty('name'),

    inferredName: function() {
        return this.get('name') || formatPaymentTerms(this.get('mode'), this.get('days'))
    }.property('name', 'mode', 'days'),

    hasForm: true,

    activeMode: null,

    activeDays: null,

    isActive: function() {
        return (this.get('hasForm') && this.get('mode') === this.get('activeMode'))
    }.property('mode', 'activeMode', 'hasForm'),

    click: function() {
        var mode = this.get('mode') || null
        var days = this.get('days')
        if (this.get('activeMode') === mode && this.get('hasForm')) {
            return
        }
        var self = this
        // We need to do this in its own run loop to make sure that the activeMode propagates to the payment-terms-popover before the bindings in the template is torn down
        Em.run(function() {
            self.set('activeMode', mode)
        })

        if (self.get('hasForm')) {
            self.$('input')[0].focus()
        } else {
            self.sendAction('didSelect', {
                days: days
            })
        }
    }
})
