var t = require('i18n').t
var postingSides = require('posting-sides')
var imageThumbnail = require('../utils/image-thumbnail')

Billy.Bill.reopen(require('../mixins/due-model'), require('../mixins/state-model'), {
    isBill: Em.computed.equal('type', 'bill'),
    isCreditNote: Em.computed.equal('type', 'creditNote'),
    isDraft: Em.computed.equal('state', 'draft'),
    isApproved: Em.computed.equal('state', 'approved'),
    isVoided: Em.computed.equal('state', 'voided'),
    hasMultipleLines: Em.computed.gt('lines', 1),
    isScanning: Em.computed.equal('scannedState', 'scanning'),

    // Generic stuff
    receiptUrl: Em.computed('attachments.firstObject.file.downloadUrl', function() {
        return this.get('attachments.firstObject.file.downloadUrl')
    }),

    receiptUrlThumbnail48: Em.computed('receiptUrl', function() {
        var receiptUrl = this.get('receiptUrl')

        return imageThumbnail(receiptUrl, {
            size: '48x48',
            page: 0
        })
    }),

    voucherNoWithCustomer: function() {
        var s = ''
        if (this.get('voucherNo')) {
            s += '#' + this.get('voucherNo') + ' - '
        }
        s += this.get('contact.name')
        return s
    }.property('invoiceNo', 'contact.name'),

    postingSide: function() {
        return this.get('type') === 'creditNote' ? postingSides.credit : postingSides.debit
    }.property('type'),

    genericDescription: function() {
        var contactName = this.get('contact.name')
        var number = this.get('voucherNo')
        if (this.get('isCreditNote')) {
            if (contactName) {
                return t('bill.description.credit_note_from', { contactName: contactName })
            } else {
                return t('bill.description.credit_note')
            }
        } else {
            if (contactName) {
                return t('bill.description.bill_from', { contactName: contactName, number: number })
            } else {
                return t('bill.description.bill')
            }
        }
    }.property('contact.name', 'isCreditNote'),

    detailedDescription: function() {
        var lines = this.get('lines')
        if (lines.get('length')) {
            return lines.mapProperty('description').join(', ')
        } else {
            return t('bill.no_description')
        }
    }.property('lines.@each.description'),

    shortAccountText: function() {
        var accountNo = this.get('lines.firstObject.account.accountNo')
        var accountName = this.get('lines.firstObject.account.name')
        var text = accountNo || ''

        if (accountName) {
            text += ' - ' + accountName
        }

        return text || null
    }.property('lines.@each.account'),

    shortAccountDescription: function() {
        var accountText = this.get('shortAccountText')
        var accountDescription = this.get('lines.firstObject.account.description')
        var text = accountText || ''

        if (accountDescription) {
            text += '\n' + accountDescription
        }

        return text || null
    }.property('shortAccountText'),

    shortTaxRateText: function() {
        var rate = this.get('lines.firstObject.taxRate.rate')
        var abbr = this.get('lines.firstObject.taxRate.abbreviation')
        var text = ((rate !== null && !isNaN(rate)) && String(rate * 100) + '%') || ''

        if (abbr) {
            text += ' (' + abbr + ')'
        }

        return text || null
    }.property('lines.@each.taxRate'),

    hasCashBasedAccounting: function() {
        return this.get('accountingBasis') === 'cash'
    }.property('accountingBasis')
})
