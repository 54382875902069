var Window = require('ember-window')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/oauth-actor-editor'),

    content: null,

    segment: Em.inject.service(),

    actions: {
        save: function() {
            var self = this
            var actor = this.get('content')
            actor.save()
                .mask()
                .success(function() {
                    self.get('segment').track('API Key Created (FE)')
                    self.close()
                    self.trigger('save', actor)
                })
        }
    }
})
