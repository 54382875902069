var batmask = require('batmask')
var salesTaxPeriodOptions = require('../utils/sales-tax-period-options')
var fiscalYearCalculator = require('../utils/fiscal-year-calculator')
var moment = require('momentjs')

module.exports = Em.Controller.extend({
    needs: ['organization'],

    startGuide: Em.inject.service(),

    organization: Em.computed.alias('controllers.organization.model'),

    fiscalFollowsCalendarYear: Em.computed.equal('organization.fiscalYearEndMonth', 12),

    fiscalYearEndMonth: Em.computed.alias('organization.fiscalYearEndMonth'),

    selectedDate: null,

    salesTaxPeriodOptions: function() {
        return salesTaxPeriodOptions.get()
    }.property(),

    possibleDates: function() {
        var firstFiscalYearStart = this.get('organization.firstFiscalYearStart')
        var firstFiscalYearEnd = this.get('organization.firstFiscalYearEnd')
        var fiscalYearEndMonth = this.get('organization.fiscalYearEndMonth')
        var fiscalYearsEndings = fiscalYearCalculator(firstFiscalYearStart, fiscalYearEndMonth)

        if (moment(firstFiscalYearEnd).isValid()) {
            this.set('selectedDate', moment(firstFiscalYearEnd).format('YYYY-MM-DD'))
        } else {
            this.set('selectedDate', fiscalYearsEndings.get('firstObject.sortable'))
        }

        return fiscalYearsEndings
    }.property('organization.{firstFiscalYearStart,fiscalYearEndMonth}'),

    disableFiscalYearEndRadio: function() {
        return this.get('possibleDates').get('length') === 1
    }.property('possibleDates.each'),

    monthOptions: function() {
        var options = []

        for (var i = 1; i <= 12; i++) {
            var month = moment(i + '', 'M').format('MMMM')
            month = month.charAt(0).toUpperCase() + month.substr(1)

            options.push(Em.Object.create({
                value: i,
                name: month
            }))
        }

        return options
    }.property(),

    adjustFirstFiscalYearEnd: function() {
        var thisYear = moment().format('YYYY')

        if (this.get('organization.firstFiscalYearEnd') < this.get('organization.firstFiscalYearStart')) {
            this.set('organization.firstFiscalYearEnd', moment(thisYear + '-12-31'))
        }
    }.observes('organization.firstFiscalYearStart'),

    actions: {
        didClickFiscalEndMonth: function(value) {
            this.set('fiscalFollowsCalendarYear', value)

            if (value) {
                this.set('organization.fiscalYearEndMonth', 12)
            }
        },

        didClickFirstFiscalYearEnd: function(value) {
            this.set('selectedDate', value)
            this.set('organization.firstFiscalYearEnd', moment(value))
        },

        save: function() {
            var self = this
            var organization = this.get('organization')

            batmask.maskDelayed()

            organization
                .save()
                .then(function() {
                    return self.get('startGuide').completeStep()
                })
                .then(function() {
                    self.send('goToNextStep')
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        skip: function() {
            this.send('goToNextStep')
        }
    }
})
