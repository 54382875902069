var _ = require('lodash')
var t = require('i18n').t
var tracking = require('../utils/tracking')
var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    location: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    contentClass: null,

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    // This may seem a bit odd - why do we do a "contentClass" and not use activeStep directly?
    // -Because- when navigating, the activeStep changes immediately but the page takes a while to load. This makes it so
    // that the container that has the content changes class before the content is unloaded so it looks extremely ugly and
    // flickers during the transition. Doing this, we make 100% sure we only change the content class when the transition is
    // complete. We only set the content class initially if it is not set up, then we only update it in didTransition() which
    // is triggered in this controller through the annual-report route.
    init: function() {
        if (!this.get('contentClass')) {
            this.updateContentClass()
        }

        // Annual Reports have their separate Hubspot chat widget,
        // so we need to reload when entering any of the annual report pages
        tracking.refreshHubspotWidget()
    }.on('init'),

    isReported: function() {
        return this.get('model.commitment.state') === 'reported'
    }.property('model.commitment.state'),

    isNotReported: function() {
        return this.get('model.commitment.state') === 'received'
    }.property('model.commitment.state'),

    updateContentClass: function() {
        this.set('contentClass', _.kebabCase(this.get('annualReport.activeStep')))
    },

    isCompany: function() {
        return this.get('organization.companyType') === 'company'
    }.property('organization.companyType'),

    showProgressList: function() {
        var route = this.get('location.pathname').split('/').pop()
        return !this.get('annualReport').getStepSettings(route).hideProgressList
    }.property('location.pathname'),

    /**
     * @returns {AnnualReportTab[]}
     */
    tabs: function() {
        var commitmentYear = this.get('model.commitment.year')
        return this.get('model.years')
            .map(function(report) {
                return {
                    id: report.year.toString(),
                    title: t('annual_report.fiscal_year', { year: report.year }),
                    year: report.year,
                    nextYear: report.year + 1,
                    isActive: commitmentYear === report.year,
                    isAvailable: report.isAvailable,
                    isFillable: report.isFillable
                }
            })
    }.property('model.years'),

    actions: {
        toggleReportState: function() {
            var commitment = this.get('model.commitment')
            var commitId = this.get('model.commitment.id')
            var currentState = this.get('model.commitment.state')
            var newState = (currentState !== 'reported') ? 'reported' : 'received'

            this.send('progressUpdate', newState)

            Ember.set(commitment, 'state', newState)
            this.get('annualReport').putState(commitId, newState)
        },

        didTransition: function(transition) {
            this.updateContentClass()

            // Only reload hubspot chat when leaving annual reports
            if (!this.get('location.pathname').includes('annual_report')) {
                tracking.refreshHubspotWidget()
            }
        },

        tabActivated: function(year) {
            this.send('invalidateModel')
            this.transitionToRoute('annual_report', year)
        }
    }
})
