Ember.Handlebars.registerHelper('render-component', function(componentPath, options) {
    var component = Ember.Handlebars.get(this, componentPath, options)
    if (typeof component === 'string') {
        var name = component
        component = this.container.lookup('component:' + name)
        var v
        for (var k in options.hash) {
            if (Object.prototype.hasOwnProperty.call(options.hash, k)) {
                v = Ember.Handlebars.get(this, options.hash[k], options)
                component.set(k, v)
            }
        }
        if (!component.get('layoutName')) {
            component.set('layoutName', 'components/' + name)
        }
    }
    options.data.view.appendChild(component)
})
