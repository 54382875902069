module.exports = Em.ObjectController.extend({
    needs: ['organization'],

    linkRoute: null,

    linkContext: null,

    linkUrl: null,

    actionDidChange: function() {
        var linkRoute = null
        var linkContext = null
        var linkUrl = null
        var action = this.get('action')
        var organization = this.get('controllers.organization.model')
        if (action) {
            switch (action.type) {
            case 'salesTaxReturn':
                linkRoute = 'sales_tax_return'
                linkContext = Billy.SalesTaxReturn.find(action.salesTaxReturnId)
                break
            case 'organizationInfo':
                linkRoute = 'organization_info'
                linkContext = organization
                break
            case 'link':
                linkUrl = action.url
                linkContext = organization
                break
            }
        }
        this.set('linkRoute', linkRoute)
            .set('linkContext', linkContext)
            .set('linkUrl', linkUrl)
    }.observes('action').on('init')
})
