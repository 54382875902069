var attr = DS.attr
var belongsTo = DS.belongsTo

module.exports = DS.Model.extend({
    createdTime: attr('moment-datetime'),
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    name: attr('string'),
    size: attr('number'),
    contentType: attr('string'),
    url: attr('string'),
    dowloadUrl: attr('string'),
    priority: attr('number'),

    quote: belongsTo('quote'),

    resource: function() {
        return this.get('quote') // || this.get('otherKind')
    }.property('quote'),

    resourceType: function() {
        var resource = this.get('resource')
        return Em.String.pluralize(resource.constructor.typeKey)
    }.property('resource'),

    resourceId: Em.computed.oneWay('resource.id')
})
