var numeral = require('numeral')

module.exports = Em.Component.extend({
    classNames: ['horizontal-bar-chart'],

    _setupChart: function() {
        this._chart = createChart(this.get('element'))
    }.on('didInsertElement'),

    _dataDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this._redrawChart)
    }.observes('data').on('didInsertElement'),

    _redrawChart: function() {
        this._chart.redraw(this.get('data') || [])
    }
})

function createChart(parentEl) {
    var ret = Em.Object.createWithMixins(Em.Evented)
    var $parentEl = $(parentEl)
    var w = $parentEl.width()
    var h = $parentEl.height()
    var leftPadding = 5
    var bodyW = w - leftPadding
    var categoryLabelWidth = 150 // This is a little hacky. TODO: Measure this on a per content basis
    var categoryValueHeight = 16
    var categoryValuePaddingBottom = 5
    var categoryBarHeight = 12
    var categoryHeight
    var axisSize = 1
    var xLabelsHeight = 8
    var xLabelTopPadding = 6
    var xLabelBottomPadding = 3
    var xLabelHorizontalPadding = 3
    var xMiddleTickHeight = 3

    var x = d3.scale.linear()
        .rangeRound([0, bodyW - axisSize])

    var chart = d3.select(parentEl)
        .append('svg')
        .attr('width', w)
        .attr('height', h)
        .attr('style', 'width:' + w + 'px;' + 'height:' + h + 'px;')

    var body = chart.append('g')
        .attr('transform', 'translate(' + leftPadding + ',0)')

    // X axis
    body.append('rect')
        .attr('class', 'axis')
        .attr('x', 0)
        .attr('y', h - xLabelsHeight - xLabelTopPadding - xLabelBottomPadding - axisSize)
        .attr('width', bodyW)
        .attr('height', axisSize)

    // X axis left tick
    body.append('rect')
        .attr('class', 'axis')
        .attr('x', 0)
        .attr('y', h - xLabelsHeight - xLabelTopPadding - xLabelBottomPadding)
        .attr('width', axisSize)
        .attr('height', xLabelsHeight + xLabelTopPadding)

    // X axis middle tick
    body.append('rect')
        .attr('class', 'axis')
        .attr('x', axisSize + (bodyW - axisSize) / 2)
        .attr('y', h - xLabelsHeight - xLabelTopPadding - xLabelBottomPadding)
        .attr('width', axisSize)
        .attr('height', xMiddleTickHeight)

    // X axis right tick
    body.append('rect')
        .attr('class', 'axis')
        .attr('x', bodyW - axisSize)
        .attr('y', h - xLabelsHeight - xLabelTopPadding - xLabelBottomPadding)
        .attr('width', axisSize)
        .attr('height', xLabelsHeight + xLabelTopPadding)

    // X axis left label
    body.append('text')
        .attr('class', 'x-label')
        .attr('x', axisSize + xLabelHorizontalPadding)
        .attr('y', h - xLabelBottomPadding)
        .text('0')

    // X axis middle label
    var xAxisMiddleLabel = body.append('text')
        .attr('class', 'x-label')
        .attr('x', axisSize + (bodyW - axisSize) / 2)
        .attr('y', h - xLabelBottomPadding)
        .attr('text-anchor', 'middle')

    // X axis middle label
    var xAxisRightLabel = body.append('text')
        .attr('class', 'x-label')
        .attr('x', bodyW - axisSize - xLabelHorizontalPadding)
        .attr('y', h - xLabelBottomPadding)
        .attr('text-anchor', 'end')

    var categoryTransform = function(d, i) {
        return 'translate(' + axisSize + ',' + (i * categoryHeight) + ')'
    }

    var categoryLabelText = function(d) {
        return d.label
    }

    var categoryValueText = function(d) {
        return numeral(d.value).format('0,0')
    }

    var categoryValueX = function(d) {
        return Math.max(categoryLabelWidth, x(d.value))
    }

    var categoryClass = function(d) {
        return 'category ' + d.color
    }

    var categoryBarWidth = function(d) {
        return x(d.value)
    }

    var categoryPartialBarX = function(d) {
        return x(d.value - Math.min(d.value, d.partialValue || 0))
    }

    var categoryPartialBarWidth = function(d) {
        return x(Math.min(d.value, d.partialValue || 0))
    }

    ret.redraw = function(data) {
        var barCount = data.length
        var xMax = niceMax(data)

        categoryHeight = (h - axisSize - xLabelsHeight - xLabelTopPadding - xLabelBottomPadding) / barCount

        x.domain([0, xMax])

        // Update
        var category = body.selectAll('g.category')
            .data(data, function(d) { return d.id })
        category.transition()
            .attr('class', categoryClass)
            .attr('transform', categoryTransform)
        category.select('text.category-label')
            .text(categoryLabelText)
        category.select('text.value')
            .text(categoryValueText)
            .transition()
            .attr('x', categoryValueX)
        category.select('rect.bar')
            .transition()
            .attr('width', categoryBarWidth)
        category.select('rect.partial-bar')
            .transition()
            .attr('x', categoryPartialBarX)
            .attr('width', categoryPartialBarWidth)

        // Enter
        var categoryEnter = category.enter()
            .append('g')
            .attr('class', categoryClass)
            .attr('transform', categoryTransform)
        categoryEnter.append('text')
            .attr('class', 'category-label')
            .attr('x', 10)
            .attr('y', categoryValueHeight)
            .text(categoryLabelText)
        categoryEnter.append('text')
            .attr('class', 'value')
            .attr('x', categoryValueX)
            .attr('y', categoryValueHeight)
            .attr('text-anchor', 'end')
            .text(categoryValueText)
        categoryEnter.append('rect')
            .attr('class', 'bar')
            .attr('x', 0)
            .attr('y', categoryValueHeight + categoryValuePaddingBottom)
            .attr('width', categoryBarWidth)
            .attr('height', categoryBarHeight)
        categoryEnter.append('rect')
            .attr('class', 'partial-bar')
            .attr('x', categoryPartialBarX)
            .attr('y', categoryValueHeight + categoryValuePaddingBottom)
            .attr('width', categoryPartialBarWidth)
            .attr('height', categoryBarHeight)
        categoryEnter.append('rect')
            .attr('class', 'axis')
            .attr('x', -axisSize)
            .attr('y', categoryValueHeight - 10)
            .attr('width', axisSize)
            .attr('height', categoryHeight)
        categoryEnter.append('text')
            .attr('class', 'sign-stroke')
            .attr('x', 0)
            .attr('y', categoryValueHeight)
            .attr('text-anchor', 'middle')
            .text(function(d) { return d.sign })
        categoryEnter.append('text')
            .attr('class', 'sign')
            .attr('x', 0)
            .attr('y', categoryValueHeight)
            .attr('text-anchor', 'middle')
            .text(function(d) { return d.sign })

        // Exit
        category.exit()
            .remove()

        xAxisMiddleLabel.text(numeral(xMax / 2).format('0,0'))
        xAxisRightLabel.text(numeral(xMax).format('0,0'))
    }

    return ret
}

function niceMax(data) {
    var max = data.reduce(function(max, d) {
        return Math.max(max, d.value)
    }, 0)
    var roundBy = Math.pow(10, Math.floor(Math.log(max) / Math.LN10))
    return Math.ceil(max / roundBy) * roundBy
}
