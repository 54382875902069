module.exports = Em.Component.extend({
    needs: ['bankAccount', 'organization'],

    userOrganizations: Em.inject.service(),

    account: Em.computed.oneWay('controllers.bankAccount.model'),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/bank-integration-button'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
