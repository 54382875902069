var Window = require('ember-window')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/contact-person-editor'),

    content: null,

    callback: null,

    actions: {
        save: function() {
            var self = this
            var contactPerson = this.get('content')

            contactPerson
                .save()
                .mask()
                .success(function() {
                    if (self.get('callback')) {
                        self.get('callback')(contactPerson)
                    }
                    self.close()
                })
        }
    },

    rollback: function() {
        this.get('content').rollback()
    }.on('willCancel')
})
