var Window = require('ember-window')
var i18n = require('i18n')
var tProperty = i18n.tProperty
var t = i18n.t

var BankFeeAccountWindow = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/preview-window'),

    classNames: ['preview-window'],

    title: tProperty('title', t('preview_window.default_title')),

    url: null
})

module.exports = BankFeeAccountWindow
