module.exports = Em.Route.extend({
    userUmbrellas: Em.inject.service(),

    sideMenu: Ember.inject.service(),

    model: function(params) {
        this.get('sideMenu').deactivate()

        var routeArray = window.location.pathname.split('/')
        var umbrellaIndex = routeArray.findIndex(function(item) {
            return item === 'umbrellas'
        })
        var umbrellaId = params.umbrella_id || routeArray[umbrellaIndex + 1]

        return this.get('api').getData('/umbrellas/' + umbrellaId)
    },

    afterModel: function(organization, transition) {
        var applicationController = this.controllerFor('application')

        if (applicationController) {
            if (!applicationController.get('generateUrlReadyPromise') && typeof applicationController.createGenerateUrlReadyPromise === 'function') {
                applicationController.createGenerateUrlReadyPromise()
            }

            if (typeof applicationController.resolveWhenUmbrellaLoaded === 'function') {
                applicationController.resolveWhenUmbrellaLoaded()
            }
        }
    },

    setupController: function(controller, umbrella) {
        controller.set('model', umbrella)
        this.get('sideMenu').activateUmbrella(umbrella)
    }
})
