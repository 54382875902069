var FileView = Em.View.extend({
    templateName: 'components/attachments-list-file',

    api: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    actions: {
        removeAttachment: function() {
            var attachmentId = this.get('content.id')
            this.get('controller').sendAction('onDelete', attachmentId)
        }
    }
})

module.exports = Em.Component.extend({
    layoutName: 'components/attachments-list-simple',
    classNameBindings: [':attachments-list', 'hasFiles:attachments-list-with-files'],

    attachments: null,

    onDelete: null,

    hasFiles: function() {
        return this.get('attachments.length') > 0 || this.get('queue.length') > 0
    }.property('attachments.length', 'queue.length'),

    fileViewClass: FileView
})
