var t = require('i18n').t

module.exports = require('./payment-terms-popover-form-abstract').extend({
    daysError: null,

    actions: {
        didClickTick: function() {
            var days = this.get('days')
            this.set('daysError', null)
            if (Em.isEmpty(days)) {
                this.set('daysError', t('required_field'))
                return
            }
            this.sendAction('didSelect', {
                days: days
            })
        }
    }
})
