var numeral = require('numeral')

numeral.fn.money = function(currency) {
    var v = this.format('0,0.00')
    if (currency != null) {
        if (currency instanceof Billy.Currency) {
            currency = currency.get('id')
        }
        v += ' ' + currency
    }
    return v
}
