var SIDE_PADDING = 30
var WIDTH_MULTIPLIER_TO_MIMIC_PDF = 1.18
var PAGE_HEIGHT_TO_MIMIC_PDF = 1123

module.exports = Em.Component.extend({
    classNames: ['document-paper'],

    html: null,

    useGenericPadding: true,

    mimicPDFStyling: false,

    resizeHandler: null,

    initSize: function() {
        Em.run.schedule('afterRender', this, this.adjustSize)
    }.on('didInsertElement').observes('html'),

    adjustSize: function() {
        var mimicPDFStyling = this.get('mimicPDFStyling')
        var el = this.$()
        var parentWidth = el.width()
        var doc = this.$('> div:eq(0)')
        var minHeigt = PAGE_HEIGHT_TO_MIMIC_PDF
        var width = doc.outerWidth()
        var paddingLeft = +(doc.css('padding-left') || '').replace('px', '')
        var paddingRight = +(doc.css('padding-right') || '').replace('px', '')
        var newWidth = this.get('useGenericPadding') ? width - paddingLeft - paddingRight + 2 * SIDE_PADDING : (mimicPDFStyling ? (width * WIDTH_MULTIPLIER_TO_MIMIC_PDF) : width)
        var scale = Math.min(1, parentWidth / newWidth)
        doc.css({
            'padding-left': this.get('useGenericPadding') ? SIDE_PADDING + 'px' : paddingLeft + 'px',
            'padding-right': this.get('useGenericPadding') ? SIDE_PADDING + 'px' : paddingRight + 'px',
            '-webkit-transform': 'scale(' + scale + ')',
            transform: 'scale(' + scale + ')',
            '-webkit-transform-origin': '0 0',
            'transform-origin': '0 0',
            margin: 'auto',
            'padding-bottom': '135px'
        })

        doc.outerWidth(newWidth)

        // Update the height of the paper to cut off the empty spaces due to the scaled element
        var heightBasedOnMimic = (doc.outerHeight() * scale) > minHeigt ? doc.outerHeight() * scale : minHeigt
        var responsiveHeight = el.height(doc.outerHeight() * scale)
        var height = mimicPDFStyling ? heightBasedOnMimic : responsiveHeight
        el.height(height)
    },

    onDestroy: function() {
        $(window).off('resize', this.get('resizeHandler'))
        this.set('resizeHandler', null)
    }.on('willDestroyElement')
})
