module.exports = Em.Component.extend({
    classNames: ['todo-list-item'],

    classNameBindings: ['item.isChecked:is-checked'],

    annualReport: Em.inject.service(),

    item: null,

    actions: {
        didSelectItem: function() {
            var self = this

            var answer = {
                name: this.get('item.translationKey'),
                value: !this.get('item.isChecked')
            }

            var isChecked = !self.get('item.isChecked')
            self.set('item.isChecked', isChecked)

            this.get('annualReport').putAnswer(self.get('item.year'), [answer])
                .catch(function() {
                    self.set('item.isChecked', !isChecked)
                })
        }
    }
})
