module.exports = Em.Mixin.create({
    reloadComponentsDependedOnAccounts: function() {
        var products = this.container.lookup('controller:products-index')

        if (products && products.refreshList) {
            products.refreshList()
        }
    },

    _settingsChartOfAccountsCustomActions: {
        reloadAccounts: function() {
            BD.store.unloadAllByType(Billy.Product)
            BD.store.unloadAllByType(Billy.Account)
            BD.store.unloadAllByType(Billy.Daybook)
            var self = this
            var organizationId = this.get('activeOrganization.id')
            var api = this.container.lookup('api:billy')

            return api.get('/accounts?organizationId=' + organizationId)
                .then(function(payload) {
                    BD.store.loadAll(Billy.Account, payload.accounts)
                    self.reloadComponentsDependedOnAccounts()
                })
        }
    }
})
