var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/bank-payment-difference-popover'),

    classNames: ['bank-payment-difference-popover'],

    differenceTypes: null,

    actions: {
        selectDifferenceType: function(differenceType) {
            this.trigger('didSelect', differenceType.get('value'))
        }
    }
})
