var FiscalYear = require('../utils/period-selector/fiscal-year-period')
var getUserType = require('../utils/get-user-type')

module.exports = Em.Controller.extend({
    queryParams: ['trial-welcome', 'welcome', 'prismic'],

    needs: ['user', 'application'],

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    bankConnections: Ember.inject.service(),

    planSelectedFromSignup: Em.computed.alias('controllers.application.planSelectedFromSignup'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }
            var extra = e.detail.extra || {}
            if (extra.fiscalYear) {
                var entryDatePeriod = FiscalYear.create({
                    value: 'fiscalyear:' + this.get('organization.id') + ',' + extra.fiscalYear
                })
                if (route === 'contact.customer') {
                    this.container.lookup('controller:contact-customer').set('entryDatePeriod', entryDatePeriod)
                }
                if (route === 'contact.supplier') {
                    this.container.lookup('controller:contact-supplier').set('entryDatePeriod', entryDatePeriod)
                }
            }
            if (props) {
                this.transitionToRoute(route, props)
            } else {
                this.transitionToRoute(route)
            }
        },
        connectBank: function() {
            var self = this

            Billy.Account.filter({
                query: {
                    organization: self.get('organization'),
                    isBankAccount: true,
                    isArchived: false
                },
                sortProperty: 'name'
            }).promise.then(function(accounts) {
                var account = accounts.get('firstObject')

                self.transitionToRoute('bank_sync', self.get('organization'), account)
                self.get('bankConnections').setup(account, 'nordic_api')
            })
        }
    }
})
