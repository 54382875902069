var formatPaymentTerms = require('../utils/format-payment-terms')

module.exports = require('ember-text-field').extend({
    classNames: ['select-field'],

    inputReadonly: true,

    allowKeyInput: false,

    picker1Icon: 'icons/caret-down',

    mode: null,

    days: null,

    dueDate: null,

    entryDate: null,

    supportsDate: false,

    supportsNone: false,

    allowUnformatInputValue: false,

    error: function() {
        var messages = [
            this.get('paymentTermsModeError'),
            this.get('paymentTermsDaysError'),
            this.get('dueDateError')
        ].filter(function(message) {
            return !Em.isEmpty(message)
        })
        return messages.length ? messages.join(' ') : null
    }.property('paymentTermsModeError', 'paymentTermsDaysError', 'dueDateError'),

    inputValue: function() {
        return formatPaymentTerms(this.get('mode'), this.get('days'), this.get('dueDate'), this.get('entryDate'))
    }.property('mode', 'days', 'dueDate', 'entryDate'),

    eventManager: {
        click: function(e, view) {
            if (view.get('disabled')) {
                return
            }
            e.stopPropagation()
            var el = $(e.target).closest('.select-field')
            var select = Em.View.views[el.attr('id')]
            select.showPopover()
        }
    },

    didClickPicker1: function() {
        this.showPopover()
    },

    showPopover: function() {
        var self = this
        var popover = this.get('popover')
        if (!popover) {
            popover = this.container.lookup('component:payment-terms-popover')
            this.set('popover', popover)
            popover.setProperties(this.getProperties(['mode', 'days', 'dueDate', 'supportsDate', 'supportsNone']))
            popover.on('didSelect', this, this.popoverDidSelect)
            popover.one('willDestroyElement', function() {
                self.set('popover', null)
            })
            popover.show(this)
        }
    },

    hidePopover: function() {
        var popover = this.get('popover')
        if (popover) {
            popover.destroy()
        }
    },

    popoverDidSelect: function(value) {
        this.hidePopover()
        this.set('mode', value.mode)
        this.set('days', value.days)
        this.set('dueDate', value.dueDate)
    },

    willDestroy: function() {
        this._super()
        this.hidePopover()
    }
})
