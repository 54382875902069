var Cookies = require('js-cookie')
var qs = require('qs')
var i18n = require('i18n')
var t = i18n.t
var cookieKey = require('../constants/cookieKey')
var download = require('downloadjs')
var simplifyFilename = require('../utils/simplify-filename')
var snapshotInvoice = require('../utils/snapshot-invoice')
var LiquidHelper = require('../utils/liquid-helper')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    templates: Em.inject.service(),

    api: Em.inject.service(),

    segment: Em.inject.service(),

    html: null,

    convertedQuotesToInvoices: null,

    htmlId: null,

    resolvedLocale: function() {
        return (
            this.get('contact.locale.id') || this.get('organization.locale.id')
        )
    }.property('contact.locale.id', 'organization.locale.id'),

    getDocumentUrl: function(type) {
        var quote = this.get('model')
        var host = this.container.lookup('adapter:application').host
        return (
            host +
            '/organizations/' +
            quote.get('organization.id') +
            '/quotes/' +
            quote.id +
            '.' +
            type
        )
    },

    getDocumentHeaders: function() {
        var accessToken = this.container
            .lookup('api:billy')
            .storageAdapter.getValue('accessToken')
        return {
            Authorization: 'Bearer ' + accessToken
        }
    },

    loadPdf: function(layout) {
        var self = this
        return new Em.RSVP.Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function() {
                if (this.readyState === 4) {
                    if (this.status >= 200 && this.status < 300) {
                        resolve(this.response)
                    } else {
                        reject(this)
                    }
                }
            }
            xhr.open(
                'GET',
                self.getDocumentUrl('pdf') + (layout ? '?layout=' + layout : '')
            )
            var headers = self.getDocumentHeaders()
            for (var k in headers) {
                if (Object.prototype.hasOwnProperty.call(headers, k)) {
                    xhr.setRequestHeader(k, headers[k])
                }
            }
            xhr.responseType = 'blob'
            xhr.send()
        })
    },

    activate: function() {
        var self = this
        var quote = this.get('model')
        this.get('api').request('GET', '/v2/invoices?&quoteId=' + quote.id + '&organizationId=' + quote.get('organization.id') + '&sortProperty=entryDate&sortDirection=DESC&pageSize=100&offset=0')
            .then(function(result) {
                self.set('convertedQuotesToInvoices', result.invoices)
            })
    },

    loadHtml: function() {
        var self = this
        var quoteId = this.get('model.id')
        // If the currently displayed HTML does not belong to the quote we are going to display, then clear the HTML right away
        if (this.get('htmlId') !== quoteId) {
            this.set('html', '').set('htmlId', null)
        }

        $.ajax({
            type: 'GET',
            url: this.getDocumentUrl('html'),
            headers: this.getDocumentHeaders()
        }).then(
            function(payload) {
                Em.run(function() {
                    self.set('html', payload).set('htmlId', quoteId)
                })
            },
            function() {
                Em.run(function() {
                    // TODO: Translation
                    self.set(
                        'html',
                        'Could not load quote document at this moment.'
                    )
                })
            }
        )
    },

    isOpen: Em.computed.equal('state', 'open'),

    isEstimate: Em.computed.equal('type', 'estimate'),

    isQuote: Em.computed.equal('type', 'quote'),

    isOrderConfirmation: Em.computed.equal('type', 'orderConfirmation'),

    shouldBeSent: function() {
        return !this.get('isSent') && this.get('state') === 'open'
    }.property('isSent', 'state'),

    convertTo: function(toType) {
        var self = this
        var quote = this.get('model')
        var templates = this.get('templates')
        var tplVars = templates.variablesForInvoice(quote)
        quote.set('type', toType)
        quote.set('headerMessage', tplVars[toType + 'HeaderMessage'])
        quote.set('footerMessage', tplVars[toType + 'FooterMessage'])
        return quote.save().then(function() {
            self.loadHtml()
        })
    },

    customerPortalUrl: function() {
        return (
            this.get('contact.customerPortalUrl') + '/quotes/' + this.get('id')
        )
    }.property('contact.customerPortalUrl', 'id'),

    downloadPdf: function(layout) {
        var quote = this.get('model')
        var container = this.container
        var notification = container.lookup('util:notification')
        notification.notify(NOTIFICATION_KEYS.QUOTE_PDF_GENERATING, t('quote.index.generating_pdf'), NOTIFICATION_VARIANT.INFO)

        var originalLocale = i18n.locale()
        i18n.locale(this.get('resolvedLocale'))
        if (layout === 'packing-list') {
            var filename =
                simplifyFilename(
                    t('quote.index.packing_list') + quote.get('contact.name')
                ) + '.pdf'
        } else {
            filename =
                simplifyFilename(
                    t(
                        'quote.index.download_filename.' +
                            quote.get('type').underscore(),
                        { name: quote.get('contact.name') }
                    )
                ) + '.pdf'
        }
        i18n.locale(originalLocale)

        this.loadPdf(layout).then(
            function(blob) {
                download(blob, filename)
            },
            function() {
                notification.warn(t('util.request.default_error'))
            }
        )
    },

    timelineUrl: function() {
        var quote = this.get('model')
        return (
            '/organizations/' +
            quote.get('organization.id') +
            '/quotes/' +
            quote.get('id') +
            '/timeline'
        )
    }.property('model{organization,id}'),

    actions: {
        backToList: function() {
            var quotesListQuery = Cookies.get(cookieKey.quotesListQuery) || ''
            Cookies.remove(cookieKey.quotesListQuery)
            this.transitionToRoute('quotes.index', { queryParams: qs.parse(quotesListQuery, { ignoreQueryPrefix: true }) })
        },

        convertToEstimate: function() {
            this.convertTo('estimate')
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'convert to estimate'
            })
        },

        convertToQuote: function() {
            this.convertTo('quote')
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'convert to quote'
            })
        },

        convertToOrderConfirmation: function() {
            this.convertTo('orderConfirmation')
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'convert to order confirmation'
            })
        },

        navigateTo: function(type, id) {
            if (type === 'invoice') {
                this.get('segment').track('Quote Converted To Invoice (FE)', {
                    quote_id: id
                })

                this.get('segment').track('XXX - Jesper - Action Taken', {
                    context: 'single_quote_view',
                    action: 'convert to invoice'
                })
                this.transitionToRoute(
                    'invoices.new',
                    {
                        queryParams: { fromQuoteId: id }
                    }
                )
            } else if (type === 'recurring_invoice') {
                this.get('segment').track('XXX - Jesper - Action Taken', {
                    context: 'single_quote_view',
                    action: 'convert to recurring invoice'
                })
                this.transitionToRoute(
                    'recurring_invoices.new',
                    {
                        queryParams: { fromQuoteId: id }
                    }
                )
            }
        },

        showInCustomerPortal: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'more: show in customer portal'
            })
            window.open(this.get('customerPortalUrl'), '_blank')
        },

        sendEmail: function() {
            var quote = this.get('model')
            var type = quote.get('type')
            var organization = quote.get('organization')
            var contact = quote.get('contact')
            var snapshot = snapshotInvoice(quote)
            var tplVars = this.get('templates').variablesForInvoice(quote)
            var subject = tplVars[type + 'EmailSubject']
            var message = tplVars[type + 'EmailMessage']
            var liquidHelper = new LiquidHelper(
                contact.get('locale.id') || organization.get('locale.id')
            )
            var email = this.store.createRecord('organization-email', {
                organization: organization,
                subjectType: 'quote',
                subjectId: quote.get('id'),
                subject: subject ? liquidHelper.render(subject, snapshot) : '',
                message: message ? liquidHelper.render(message, snapshot) : '',
                attachmentMode:
                    contact.get('emailAttachmentDeliveryMode') ||
                    organization.get('emailAttachmentDeliveryMode'),
                locale: this.get('resolvedLocale'),
                receiveCopy: false
            })
            var w = this.container.lookup(
                'component:organization-email-window'
            )
            w.showForEmail(contact, email)
            w.on('didSend', function() {
                quote.set('isSent', true)
            })
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'send as email or more: send as email'
            })
        },

        open: function() {
            this.get('model').set('state', 'open').save()
        },

        close: function() {
            var self = this
            this.get('model').set('state', 'closed').save()
                .then(function() {
                    self.container
                        .lookup('util:notification')
                        .notify(NOTIFICATION_KEYS.QUOTE_CLOSE, t('quote.index.close.success'))
                    self.get('segment').track('XXX - Jesper - Action Taken', {
                        context: 'single_quote_view',
                        action: 'close single quote'
                    })
                })
                .catch(function() {
                    self.get('model').set('state', 'open').save()
                    self.container
                        .lookup('util:notification')
                        .notify(NOTIFICATION_KEYS.QUOTE_CLOSE, t('quote.index.close.error'), 'error')
                })
        },

        delete: function() {
            var self = this
            var quote = this.get('model')
            this.container
                .lookup('util:dialog')
                .confirmWarning(null, t('confirm_delete'), t('yes_delete'))
                .then(function() {
                    return quote.destroyRecord()
                })
                .then(function() {
                    self.container
                        .lookup('util:notification')
                        .notify(NOTIFICATION_KEYS.QUOTE_DELETE, t('delete_confirmation'))
                    self.replaceRoute('quotes')
                    self.get('segment').track('XXX - Jesper - Action Taken', {
                        context: 'single_quote_view',
                        action: 'more: delete'
                    })
                })
        },

        downloadPackingList: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'more: download packing list'
            })
            this.downloadPdf('packing-list')
        },

        downloadPdf: function() {
            var self = this
            var quote = this.get('model')
            if (quote.get('isSent')) {
                this.downloadPdf()
            } else {
                this.container
                    .lookup('util:dialog')
                    .dialog(
                        t('quote.index.download_mark_sent.title'),
                        t('quote.index.download_mark_sent.text'),
                        [
                            {
                                value: 'yes',
                                text: t('quote.index.download_mark_sent.yes'),
                                primary: true,
                                align: 'left'
                            },
                            {
                                value: 'no',
                                text: t('quote.index.download_mark_sent.no'),
                                align: 'right'
                            }
                        ],
                        {
                            closable: true
                        }
                    )
                    .then(function(value) {
                        if (value === 'yes') {
                            quote.set('isSent', true).save()
                            self.downloadPdf()
                        } else if (value === 'no') {
                            self.downloadPdf()
                        }
                        // Close should not do anything
                    })
            }
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'download as pdf or more: download as pdf'
            })
        },

        edit: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'more: edit'
            })
            this.transitionTo('quote.edit')
        },

        duplicate: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'single_quote_view',
                action: 'more: duplicate'
            })
            this.transitionTo('quotes.new', {
                queryParams: {
                    fromQuoteId: this.get('id')
                }
            })
        }
    }
})
