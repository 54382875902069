module.exports = Em.Controller.extend(Em.Evented, {
    // Will be injected by dashboard controller
    organization: null,

    hasData: Em.computed.alias('controllers.dashboard.hasData'),

    period: Em.computed.alias('controllers.dashboard.period'),

    viewClass: function() {
        return this.container.lookupFactory('view:' + this.get('view'))
    }.property('view'),

    load: function() {
        // Can be overridden
    }
})
