var t = require('i18n').t
var _ = require('lodash')

module.exports = Em.Component.extend({
    classNames: ['subscription-add-ons'],

    organizationSubscription: Em.inject.service(),

    showTitle: true,

    compact: false,

    organization: null,

    addons: null,

    model: null,

    validatedScansInRedirectMode: false,

    enabledAddons: function() {
        return _(this.get('model'))
            .filter(function(addon) {
                return (addon.value || addon.value > 0)
            })
            .value()
    }.property('model.@each.value'),

    dirtyAddons: function() {
        var addons = this.get('addons')
        var model = this.get('model')

        return _.differenceWith(model, addons, function(addon, initial) {
            return (addon.id === initial.id && addon.value === initial.value)
        })
    }.property('addons', 'model', 'model.@each.value'),

    isDirty: function() {
        return _.size(this.get('dirtyAddons')) > 0
    }.property('dirtyAddons'),

    consolidatedCostText: function() {
        var price = this.get('model').reduce(function(result, addon) {
            var type = addon.type
            var value = addon.value
            var price = addon.price

            if (addon.id !== 'validated_scans') {
                if (type === 'integer' && value > 0) {
                    result += price * value
                } else if (type === 'boolean' && value) {
                    result += price
                }
            }

            return result
        }, 0)

        return Billy.moneyRecurring(price, 'DKK', '0,0', 'monthly')
    }.property('model.@each.value'),

    formattedRenewalDatesForPeriods: function() {
        var nextRenewalPeriods = this.get('enabledAddons').reduce(function(next, addon) {
            var renews = !!addon.nextState
            var addonExpiryDate = moment(addon.expirationDate)

            if (renews && (!next[addon.period] || next[addon.period].isAfter(addonExpiryDate))) {
                next[addon.period] = addonExpiryDate
            }

            return next
        }, {})

        if (!nextRenewalPeriods.monthly && !nextRenewalPeriods.yearly) {
            // stop here if nothing renews
            return null
        }

        var text = t('organization_subscription.addons_next_renewal.renew')

        if (nextRenewalPeriods.monthly) {
            text += ' ' + t(
                'organization_subscription.addons_next_renewal.monthly',
                { date: nextRenewalPeriods.monthly.format('LL') }
            )
        }
        if (nextRenewalPeriods.monthly && nextRenewalPeriods.yearly) {
            text += ' ' + t('organization_subscription.addons_next_renewal.and')
        }
        if (nextRenewalPeriods.yearly) {
            text += ' ' + t(
                'organization_subscription.addons_next_renewal.yearly',
                { date: nextRenewalPeriods.yearly.format('LL') }
            )
        }

        return text + '.'
    }.property('enabledAddons'),

    hasFooter: function() {
        if (this.get('compact')) {
            return this.get('isDirty')
        }

        return this.get('formattedRenewalDatesForPeriods') || this.get('isDirty')
    }.property('formattedRenewalDatesForPeriods', 'isDirty'),

    // Set mutable state from initial value
    resetChanges: function() {
        this.set('model', Em.copy(this.get('addons'), true))
    },

    onInit: Em.on('init', function() {
        this.resetChanges()
    }),

    actions: {
        save: function() {
            var self = this

            this.container.lookup('component:confirm-add-ons-purchase')
                .set('totalCostText', this.get('consolidatedCostText'))
                .set('renewalDay', 10)
                .set('addons', this.get('dirtyAddons'))
                .set('organization', this.get('organization'))
                .one('addonsUpdated', function() {
                    self.resetChanges()
                })
                .show()
        },

        cancel: function() {
            this.resetChanges()
        },

        invalidDisable: function(data) {
            this.sendAction('invalidDisable', data)
        }
    }
})
