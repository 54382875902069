module.exports = Em.Mixin.create({
    _isDirty: false,

    _previousRouteTransition: null,

    _onRouteChange: null,

    _onRouteBlocked: null,

    _customActions: {
        setDirtyRouteFlag: function(e) {
            var isDirty = e.detail.isDirty
            var onRouteChange = e.detail.onRouteChange
            var onRouteBlocked = e.detail.onRouteBlocked

            this.set('_isDirty', isDirty)
            this.set('_onRouteChange', onRouteChange)
            this.set('_onRouteBlocked', onRouteBlocked)
        },

        leaveRoute: function() {
            this.set('_isDirty', false)
            this.get('_previousRouteTransition').retry()
            this.set('_previousRouteTransition', null)
        }
    },

    actions: {
        willTransition: function(transition) {
            var isDirty = this.controller.get('_isDirty')
            var onRouteBlocked = this.controller.get('_onRouteBlocked')
            var onRouteChange = this.controller.get('_onRouteChange')

            this.controller.set('_previousRouteTransition', transition)

            if (isDirty) {
                if (onRouteBlocked) {
                    onRouteBlocked()
                }

                transition.abort()
                return
            }

            if (onRouteChange) {
                onRouteChange()
            }

            this.controller.set('_isDirty', false)
            return true
        }
    }
})
