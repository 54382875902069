module.exports = Em.Component.extend({
    listenSelector: 'input',
    submitSelector: '.submit',

    didInsertElement: function() {
        var self = this
        this.$().delegate(this.get('listenSelector'), 'keydown', function(e) {
            if (e.keyCode === $.keyCode.ENTER) {
                self.$(self.get('submitSelector')).trigger('click')
            }
        })
    }
})
