var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['upgrade-plan-overlay'],

    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    userOrganizations: Em.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/upgrade-plan-overlay'),

    focusSelector: null,

    reason: null,

    trackingContext: null,

    showPostingLimitText: function() {
        var reason = this.get('reason')

        if (reason === 'posting_limit') {
            return true
        }

        return false
    }.property('reason'),

    applyModalMask: false,

    customActions: {
        navigate: function(e) {
            var self = this
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.close()

            this.one('didClose', function() {
                self.container.lookup('router:main').transitionTo(route, props)
            })
        },
        closeEmber: function(e) {
            this.close()
        },
        didClickChangeSubscription: function() {
            var self = this
            this.close()
                .then(function() {
                    self.container.lookup('router:main').transitionTo('organization_subscription')
                })
        }
    }
})
