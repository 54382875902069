var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')

module.exports = Em.Controller.extend(ReactDirtyRouteMixin, {
    needs: ['umbrella'],

    queryParams: [
        'initialRoute',
        'page',
        'pageSize'
    ],

    initialRoute: null,
    page: null,
    pageSize: null,

    umbrella: Em.computed.oneWay('controllers.umbrella.model'),

    userOrganizations: Em.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    sideMenu: Ember.inject.service(),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute(route, props)
        }
    }
})
