module.exports = Em.Mixin.create(require('./dirty-confirmation'), {
    confirmCloseWhenDirty: true,

    model: Em.computed.alias('content'),

    isDirty: function(model) {
        return model.get('isDirty')
    },

    rollback: function(model) {
        model.rollback()
    },

    isClosing: false,

    willClose: function() {
        var self = this
        var model = this.get('model')

        if (model.get('isDestroying') || model.get('isDeleted')) {
            return
        }

        if (this.get('isClosing') || !this.isDirty(model) || !this.get('confirmCloseWhenDirty')) {
            // Rollback in case it's a new and clean record
            this.rollback(model)
            return
        }

        // Show confirm window (if not already open)
        var win = this._getConfirmNavigationWindow()
        return new Em.RSVP.Promise(function(resolve) {
            if (win) {
                win
                    .then(function() {
                        // Rollback record
                        self.set('isClosing', true)
                        self.rollback(model)
                        self.trigger('close')
                        resolve()
                    })
            }
        })
    }
})
