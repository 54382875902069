module.exports = Em.Route.extend({

    activate: function() {
        this._super()
        this.controllerFor('contact.supplier').activate()
    },

    deactivate: function() {
        this._super()
        this.controllerFor('contact.supplier').deactivate()
    }
})
