var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    api: Em.inject.service(),

    classNames: ['task-list-item'],

    classNameBindings: ['isCompleted:task-list-item-completed'],

    organization: null,

    task: null,

    isUmbrella: null,

    setupCheckboxListener: function() {
        this.setChecked(this.get('isCompleted'))
        this.$('input').on('change', this.onCheck.bind(this))
    }.on('didInsertElement'),

    setChecked: function(checked) {
        this.$('input').attr('checked', checked)
    },

    onCheck: function() {
        this.confirmComplete()
    },

    isCompleted: Em.computed.equal('task.state', 'completed'),

    confirmComplete: function() {
        var self = this
        this.container.lookup('util:dialog').dialog(null, t('task_list_item.confirm_complete'), [
            {
                value: 'yes',
                text: t('yes'),
                primary: true,
                align: 'right'
            },
            {
                value: false,
                text: t('no'),
                align: 'left'
            }
        ], {
            focusSelector: '.window-footer .right button',
            closable: true
        })
            .then(function(option) {
                if (option === 'yes') {
                    self.complete()
                } else {
                    self.setChecked(false)
                }
            })
    },

    complete: function() {
        var self = this
        var task = this.get('task')
        this.get('api').request('PUT', '/organizations/' + self.get('organization.id') + '/tasks/' + task.get('id') + '/completion')
            .then(function() {
                task.set('state', 'completed')
            }, function(e) {
                self.setChecked(false)
                if (e.type === 'API_ERROR') {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TASK_ITEM_COMPLETE, e.message)
                } else {
                    throw e
                }
            })
    },

    canApprove: Em.computed.and('isCompleted', 'isUmbrella'),

    actions: {
        dismiss: function() {
            this.sendAction('dismiss')
            this.destroy()
        },

        approve: function() {
            var self = this
            var task = this.get('task')
            this.container.lookup('util:dialog').confirm(null, t('task_list_item.confirm_approve'), t('yes'), t('no'))
                .then(function() {
                    return self.get('api').request('PUT', '/organizations/' + self.get('organization.id') + '/tasks/' + task.get('id') + '/approval')
                })
                .then(function() {
                    self.sendAction('approved', task)
                }, function(e) {
                    if (e.type === 'API_ERROR') {
                        self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TASK_ITEM_APPROVE, e.message)
                    } else {
                        throw e
                    }
                })
        }
    }
})
