var parseNumber = require('../utils/parse-number')
var t = require('i18n').t
var parseDate = require('i18n-parse-date')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')

var controller = module.exports = Em.ObjectController.extend({
    needs: ['bankSync', 'organization', 'bankConnections'],

    model: Em.computed.alias('controllers.bankSync.model'),

    bankConnections: Em.inject.service(),

    organizationSubscription: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    bankConnectionCount: Em.computed.alias('organizationSubscription.bankConnectionCount'),

    hasNordicApiGateway: false,

    init: function() {
        this.get('bankConnections').getBankSessions(this.get('organization.id'))
            .then(function(sessions) {
                if (sessions.length) {
                    this.set('hasNordicApiGateway', true)
                }
            }.bind(this))
    },

    uploadUrl: function() {
        return ENV.apiUrl + '/bankStatements/parse'
    }.property(),

    actions: {
        showAllLines: function() {
            this.set('shouldShowAllLines', true)
        },

        import: function() {
            this.startImport()
        },

        didUploadFile: function(context) {
            this.set('payload', context.payload.data)
            this.transitionToRoute('bank_import_columns')
        },

        backToReconcile: function() {
            this.transitionToRoute('bank_sync')
        }
    },

    importError: null,

    startImport: function() {
        var self = this
        var serializedLines = this.serializeLines()

        if (serializedLines === false) {
            return
        }

        this.set('removeLinesOffset', 0)

        var progressWin = this.container.lookup('component:bank-import-progress-window')
        this.set('progressWin', progressWin)
        progressWin.set('total', serializedLines.length)
        progressWin.show()

        this.sendRemainingLines(serializedLines).then(function(result) {
            if (result && result.failed) {
                progressWin.close()
                self.set('importError', result.errorMessage)
            } else if (progressWin.get('isAborted')) {
                progressWin.close()
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.BANK_IMPORT_ABORT, t('bank_import.aborted'), NOTIFICATION_VARIANT.WARN)
            } else {
                self.set('hasLines', false)
                self.set('controllers.organization.settings.lastBankStatementImport', moment().format('YYYY-MM-DD HH:mm:ss'))
                progressWin.close().then(function() {
                    self.transitionToRoute('bank_sync')
                })
            }
        })
    },

    serializeLines: function() {
        var serializedLines = []
        var accountId = this.get('model.id')
        var lines = this.get('lines')
        var rowIndexes = this.get('rowIndexes')
        var dateIndex = this.get('dateIndex')
        var descriptionIndex = this.get('descriptionIndex')
        var amountIndex = this.get('amountIndex')
        var line
        var amount
        var entryDate
        var hasError = false
        this.set('importError', null)
        rowIndexes.sort(function(a, b) {
            return a - b
        })
        rowIndexes.find(function(rowIndex) {
            line = lines[rowIndex]
            amount = parseNumber(line[amountIndex])
            entryDate = parseDate(line[dateIndex])
            if (!entryDate || isNaN(amount)) {
                hasError = true
                return true
            }
            serializedLines.push({
                rowIndex: rowIndex,
                data: {
                    accountId: accountId,
                    entryDate: entryDate.format('YYYY-MM-DD'),
                    description: line[descriptionIndex],
                    amount: Math.abs(amount),
                    side: amount < 0 ? 'credit' : 'debit'
                }
            })
        })
        if (hasError) {
            this.set('importError', t('bank_import.line_errors'))
            return false
        }
        return serializedLines
    },

    sendRemainingLines: function(remainingLines) {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            if (self.get('progressWin.isAborted') || remainingLines.length === 0) {
                // If aborted or the array is empty, we can resolve right away
                resolve()
            } else {
                // Otherwise we send a batch of size `batchSize`
                var batch = remainingLines.splice(0, controller.batchSize)
                self.sendLinesBatch(batch)
                    .then(function(result) {
                        // If the batch failed, we stop here
                        if (result && result.failed) {
                            resolve(result)
                            return
                        }
                        // When the batch resolves, we run this method again, and won't resolve until the very last batch has been sent
                        self.sendRemainingLines(remainingLines)
                            .then(resolve)
                    })
            }
        })
    },

    sendLinesBatch: function(batch) {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            var data = {
                bankLines: batch.mapProperty('data')
            }
            var api = self.container.lookup('api:billy')
            api.patch('/bankLines', data, {
                success: function() {
                    self.get('progressWin').incrementProperty('completed', batch.length)
                    self.removeLines(batch.mapProperty('rowIndex'))
                    resolve()
                },
                error: function(xhr) {
                    resolve({
                        failed: true,
                        errorMessage: xhr.responseJSON.errorMessage
                    })
                }
            })
        })
    },

    removeLines: function(rowIndexesToRemove) {
        var offset = this.get('removeLinesOffset')
        var lines = this.get('lines')
        var rowIndexes = this.get('rowIndexes')
        rowIndexesToRemove.forEach(function(rowIndexToRemove) {
            // This is the new index after all the other lines that have been removed by this upload
            var offsetIndex = rowIndexToRemove + offset
            // Remove the line
            lines.removeAt(offsetIndex, 1)
            // Remove that the line is checked
            rowIndexes.removeObject(offsetIndex)
            // Decrement the index of all checked lines below this line
            rowIndexes.forEach(function(rowIndex, idx) {
                if (rowIndex > offsetIndex) {
                    rowIndexes.replace(idx, 1, [rowIndex - 1])
                }
            })
            // Decrement the offset so the next lines to be removed will get the correct index
            offset--
        })
        this.set('removeLinesOffset', offset)
    }
})

controller.batchSize = 10
