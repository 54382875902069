var numeral = require('numeral')
var fileThumbnails = require('../utils/file-thumbnails')

Billy.File.reopen({

    init: function() {
        this._super()
        this.thumbnailCollections = {}
    },

    fileSizeNice: function() {
        var size = this.get('fileSize') / 1000
        if (size > 1200) {
            return numeral(size / 1000).format('0,0.00') + 'M'
        } else {
            return numeral(size).format('0,0') + 'K'
        }
    }.property('fileSize'),

    getThumbnails: function(name) {
        if (!this.thumbnailCollections[name]) {
            this.thumbnailCollections[name] = fileThumbnails.create({
                file: this,
                name: name
            })
        }
        return this.thumbnailCollections[name]
    },
    thumbnails: function() {
        return this.getThumbnails('webapp')
    }.property(),

    preload: function() {
        var self = this
        return new Em.RSVP.Promise(function(resolve) {
            if (!self.get('isImage')) {
                return resolve()
            }
            var img = new Image()
            img.onload = function() {
                img = null
                resolve()
            }
            img.src = self.get('downloadUrl')
        })
    }
})
