var t = require('i18n').t
var postingSides = require('posting-sides')

module.exports = Em.ObjectController.extend({
    debit: amountCp(),

    credit: amountCp(),

    vatRequired: false,

    validate: function() {
        var isValid = true

        var type = this.get('daybookTransaction.type')
        if (type === 'entry' && !this.get('account')) {
            this.set('errors.account', t('required_field'))
            isValid = false
        } else if (type === 'invoicePayment' && !this.get('paidInvoice')) {
            this.set('errors.paidInvoice', t('required_field'))
            isValid = false
        } else if (type === 'externalInvoicePayment' && !this.get('paidExternalInvoice')) {
            this.set('errors.paidExternalInvoice', t('required_field'))
            isValid = false
        } else if (type === 'billPayment' && !this.get('paidBill')) {
            this.set('errors.paidBill', t('required_field'))
            isValid = false
        }

        if (Em.isEmpty(this.get('amount')) || Em.isEmpty(this.get('side'))) {
            this.set('errors.amount', t('required_field'))
            isValid = false
        }

        return isValid
    },

    isEntry: Em.computed.equal('daybookTransaction.type', 'entry'),

    isInvoicePayment: Em.computed.equal('daybookTransaction.type', 'invoicePayment'),

    isExternalInvoicePayment: Em.computed.equal('daybookTransaction.type', 'externalInvoicePayment'),

    isBillPayment: Em.computed.equal('daybookTransaction.type', 'billPayment'),

    taxRatePlaceholder: function() {
        return this.get('daybookTransaction.organization.hasVat') ? t('daybook.transaction.line.vat_rate') : t('daybook.transaction.line.tax_rate')
    }.property('daybookTransaction.organization.hasVat'),

    actions: {
        didSelectAccount: function(account) {
            if (account) {
                this.set('vatRequired', account.get('taxRate') !== null)
                this.set('taxRate', account.get('taxRate'))
            }
        },

        didSelectPaidInvoice: function(invoice) {
            if (!invoice) {
                return
            }
            this.set('amount', invoice.get('balance'))
                .set('side', invoice.get('isInvoice') ? postingSides.credit : postingSides.debit)
        },

        didSelectPaidExternalInvoice: function(invoice) {
            if (!invoice) {
                return
            }
            this.set('amount', invoice.get('balance'))
                .set('side', invoice.get('type') === 'invoice' ? postingSides.credit : postingSides.debit)
        },

        didSelectPaidBill: function(bill) {
            if (!bill) {
                return
            }
            this.set('amount', bill.get('balance'))
                .set('side', bill.get('isBill') ? postingSides.debit : postingSides.credit)
        }
    }
})

function amountCp() {
    return function(key, amount) {
        if (arguments.length >= 2) {
            this.set('side', postingSides[key])
                .set('amount', amount)
        } else {
            if (this.get('side.value') === key) {
                amount = this.get('amount')
            } else {
                amount = null
            }
        }
        return amount
    }.property('amount', 'side')
}
