var t = require('i18n').t
var postingSides = require('posting-sides')
var InlineCreator = require('./bank-sync-v2-inline-creator')

module.exports = InlineCreator.extend({
    layoutName: 'components/bank-sync-v2/transaction-creator',

    organization: null,

    match: null,

    transaction: null,

    isAttachmentUploading: false,

    lineAmount: function() {
        if (!this.get('match.lines.length')) {
            return 0
        }
        return this.get('match.lines').reduce(function(total, line) {
            return total + (line.get('side.isDebit') ? 1 : -1) * line.get('amount')
        }, 0)
    }.property('match.lines.@each.amount', 'match.lines.@each.side'),

    lineDescription: function() {
        if (!this.get('match.lines.length')) {
            return ''
        }
        return this.get('match.lines').mapBy('description').join(', ')
    }.property('match.lines.@each.description'),

    taxRatePlaceholder: function() {
        return this.get('organization.hasVat') ? t('bank_sync.select_vat_rate') : t('bank_sync.select_tax_rate')
    }.property('organization.hasVat'),

    initModel: function() {
        var transaction = Billy.DaybookTransaction.createRecord({
            organization: this.get('organization'),
            entryDate: this.get('match.entryDate')
        })
        var predictedContraAccount = this.get('match.lines').filterBy('contraAccount')[0]

        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            text: this.get('lineDescription'),
            account: predictedContraAccount,
            contraAccount: this.get('match.account'),
            amount: Math.abs(this.get('lineAmount')),
            side: this.get('lineAmount') < 0 ? postingSides.debit : postingSides.credit
        })

        this.set('transaction', transaction)

        var nextVoucherNo = this.container.lookup('controller:nextVoucherNo')
        nextVoucherNo.load().then(function() {
            this.set('transaction.voucherNo', nextVoucherNo.getAndIncrement())
        }.bind(this))
    }.on('init'),

    actions: {
        didSelectAccount: function(account) {
            this.set('transaction.lines.firstObject.taxRate', account && account.get('taxRate'))
        },

        match: function() {
            var self = this
            var account = this.get('match.account')
            var daybookTransaction = this.get('transaction')

            // Save the daybook transaction
            daybookTransaction.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            }).promise
                // Find transactions for the daybook transaction
                .then(function() {
                    return Billy.Transaction.find({
                        organizationId: self.get('organization.id'),
                        originatorReference: daybookTransaction.get('reference')
                    }).promise
                })

                // Find postings for a single transaction
                .then(function(transactions) {
                    if (transactions.get('length') !== 1) {
                        throw new Error('Newly created DaybookTransaction should be the originator of exactly 1 Transaction. ' + transactions.get('length') + ' found.')
                    }
                    return transactions.objectAt(0).get('postings').promise
                })

                // Associate and match
                .then(function(postings) {
                    postings = postings.filterBy('account', account)
                    if (postings.get('length') !== 1) {
                        throw new Error('Newly created Transaction for DaybookTransaction should have exactly 1 Posting on the bank account. ' + postings.get('length') + ' found.')
                    }

                    return self.createSubjectAssociationAndMatch(postings.objectAt(0))
                })
        }
    }
})
