var Window = require('ember-window')
var i18n = require('i18n')
var popupWindow = require('../utils/popup-window')

module.exports = Window.extend({
    classNames: ['contact-export-window'],

    layout: Window.proto().layout,

    template: require('../templates/components/contact-export-window'),

    contact: null,

    fileType: 'xlsx',

    fileTypeOptions: function() {
        return [
            Ember.Object.create({
                name: i18n.t('contact_export_window.file_type_select.as.pdf'),
                value: 'pdf'
            }),
            Ember.Object.create({
                name: i18n.t('contact_export_window.file_type_select.as.html'),
                value: 'html'
            }),
            Ember.Object.create({
                name: i18n.t('contact_export_window.file_type_select.as.csv'),
                value: 'csv'
            }),
            Ember.Object.create({
                name: i18n.t('contact_export_window.file_type_select.as.xlsx'),
                value: 'xlsx'
            })
        ]
    }.property(),

    actions: {
        downloadFile: function() {
            var api = this.container.lookup('api:billy')
            var apiUrl = api.options.apiUrl

            var params = {
                accessToken: api.storageAdapter.getValue('accessToken'),
                acceptLanguage: i18n.locale()
            }

            var contact = this.get('contact')
            var fileType = this.get('fileType')
            var period = this.get('accountStatementPeriod')

            if (period) {
                params.period = period.value
            }

            var url = '/organizations/' + contact.get('organization.id') + '/contacts/' + contact.get('id') + '/contactAccountStatement.' + fileType

            popupWindow.open(apiUrl + url + '?' + $.param(params))
        }
    }
})
