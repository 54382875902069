module.exports = Em.Component.extend({
    layoutName: 'components/create-account-button',

    organization: null,

    buttonClass: 'primary',

    align: 'right',

    dropdownMenuClass: function() {
        return this.get('align')
    }.property('align'),

    createAccount: function(properties) {
        properties = properties || {}
        properties.organization = this.get('organization')
        var account = Billy.Account.createRecord(properties)
        var w = this.container.lookup('component:account-editor')
        w.set('content', account)
        w.show()
    },

    actions: {
        createAccount: function() {
            this.createAccount()
        },

        createBankAccount: function() {
            this.createAccount({
                isBankAccount: true,
                isPaymentEnabled: true,
                currency: this.get('organization.baseCurrency')
            })
        }
    }
})
