var Window = require('ember-window')
var t = require('i18n').t
var tracking = require('../utils/tracking')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    classNames: ['confirm-subscription'],

    layout: Window.proto().layout,

    template: require('../templates/components/confirm-subscription'),

    constraints: Ember.inject.service(),

    width: 600,

    error: null,

    organization: null,

    focusSelector: null,

    subscriptionPlan: null,

    isExpiredTrial: null,

    hideChangePlanLink: false,

    organizationSubscription: Em.inject.service(),

    subscriptionAmount: 0,

    isBasicSubscription: Em.computed.equal('subscriptionPlan.id', 'basic'),

    addons: null,

    trackingContext: null,

    initComponents: function() {
        if (!this.get('isBasicSubscription')) {
            return
        }

        this.set('addons', this.get('organizationSubscription').getBasicAddons())
    }.on('didInsertElement'),

    formattedSubscriptionDowngradeDate: function() {
        return this.get('organization.subscriptionExpires').format('Do MMMM YYYY')
    }.property('organization.subscriptionExpires'),

    checkoutAmount: function() {
        var price = this.get('subscriptionAmount')

        return Billy.money(price, this.get('organization.subscriptionCurrency'), '0,0')
    }.property('subscriptionAmount', 'organization.subscriptionCurrency'),

    title: function() {
        var currentPlan = this.get('organizationSubscription').getCurrentPlan()
        var willUpgrade = currentPlan.id === 'basic'
        var ctaType = willUpgrade || this.get('isExpiredTrial') ? 'upgrade' : 'downgrade'

        return t('organization_subscription.confirm_subscription.' + ctaType).htmlSafe()
    }.property('organizationSubscription.currentPlanName'),

    extraSeatsLockText: function() {
        var extraSeatsAddon = this.get('addons').findBy('id', 'extra_seats')

        if (extraSeatsAddon && extraSeatsAddon.locked) {
            return t('organization_subscription.confirm_subscription.extra_seats_restriction', { current: extraSeatsAddon.currentValue })
        }
    }.property('addons'),

    enabledAddons: function() {
        var addons = this.get('addons')

        return addons.filter(function(addon) {
            if (addon.value > 0) {
                return addon
            }
        })
    }.property('addons.@each.value'),

    nextAddonsRenewalDate: function() {
        var enabledAddons = this.get('enabledAddons')
        var sortedAddons = _.orderBy(enabledAddons, function(addon) {
            return addon.expirationDate || ''
        })

        var newestAddon = _.first(sortedAddons)

        if (!Ember.isEmpty(newestAddon) && newestAddon.expirationDate) {
            return moment(newestAddon.expirationDate).format('Do MMMM YYYY')
        } else {
            // if all addons has null as the expirationDate, the renewal will be now
            return moment().format('Do MMMM YYYY')
        }
    }.property('enabledAddons.@each.value'),

    firstPayment: function() {
        var enabledAddons = this.get('enabledAddons')

        var cost = enabledAddons.reduce(function(result, addon) {
            var type = addon.type
            var period = addon.period
            var value = addon.value
            var price = (period === 'yearly') ? addon.price * 12 : addon.price

            if (type === 'integer' && value > 0) {
                result += price * value
            } else if (type === 'boolean' && value) {
                result += price
            }

            return result
        }, 0)

        return cost
    }.property('enabledAddons.@each.value'),

    addonsInfoText: function() {
        var enabledAddons = this.get('enabledAddons')
        var isUnlimitedPostingsEnabled = !!enabledAddons.findBy('id', 'unlimited_postings')
        var isExtraSeatsEnabled = !!enabledAddons.findBy('id', 'extra_seats')
        var addonsAmountYearly = Billy.money(this.get('firstPayment'), 'DKK', '0,0')
        var firstPaymentDate = this.get('nextAddonsRenewalDate')
        var addonsInfoText = ''

        if (!isUnlimitedPostingsEnabled && !isExtraSeatsEnabled) {
            return null
        }

        if (isUnlimitedPostingsEnabled) {
            addonsInfoText += t('organization_subscription.confirm_subscription.unlimited_postings.period') + ' '
        }

        if (isExtraSeatsEnabled) {
            addonsInfoText += t('organization_subscription.confirm_subscription.extra_seats.period') + ' '
        }

        addonsInfoText += t('organization_subscription.confirm_subscription.first_payment', { amount: addonsAmountYearly, date: firstPaymentDate })

        return addonsInfoText
    }.property('addons.@each.value'),

    updateSubscription: function(plan, period, addons, couponCode) {
        var self = this
        var organizationSubscription = self.get('organizationSubscription')

        self.set('error', null) // reset error

        return organizationSubscription.ensureLoaded()
            .then(function() {
                return organizationSubscription.editSubscription(plan, period, addons, couponCode)
            })
            .then(function() {
                if (plan === 'basic') {
                    tracking.emitAnalyticsEvent('plan', 'basic_chosen')
                }
                return organizationSubscription.load() // reload subscription
            })
            .then(function() { }, function(e) {
                self.set('error', e.message)
                throw e
            })
    },

    actions: {
        setSubscriptionAmount: function(price) {
            this.set('subscriptionAmount', price)
        },

        close: function() {
            this.close()
        },

        changeSubscription: function() {
            var self = this

            self.close()
                .then(function() {
                    this.get('constraints').showUpgradeOverlay('', 'confirm_subscription')
                })
        },

        didClickUpgrade: function() {
            var self = this
            var newSubscriptionPlan = self.get('subscriptionPlan.id')
            var newSubscriptionPeriod = self.get('isSubscriptionPeriodYearly') ? 'yearly' : 'monthly'

            return self.updateSubscription(newSubscriptionPlan, newSubscriptionPeriod)
                .then(function() {
                    self.close()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_UPGRADE, t('changes_saved'))
                })
        },

        didClickDowngrade: function() {
            var self = this

            return self.updateSubscription('basic', undefined, self.get('addons'))
                .then(function() {
                    self.close()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_DOWNGRADE, t('changes_saved'))
                })
        },

        redeemCouponError: function(message) {
            this.set('error', message)
        }
    }
})
