var i18n = require('i18n')
var t = i18n.t
var tProperty = i18n.tProperty

module.exports = Em.Component.extend({
    layoutName: 'components/split-button',

    classNames: ['item', 'button-group'],

    buttonClass: null,

    align: 'right',

    dropdownMenuClass: function() {
        return this.get('align')
    }.property('align'),

    text: tProperty('text', t('split_button')),

    moreText: t('more_button'),

    icon: null,

    actions: {
        primary: function() {
            this.sendAction('primaryAction')
        }
    }
})
