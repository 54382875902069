module.exports = Em.Mixin.create({
    _settingsProfileCustomActions: {
        authorizeUser: function(e) {
            var token = e.detail.token

            if (!token) {
                console.error('You have to pass "token" to authorize user')
                return
            }

            var api = this.container.lookup('api:billy')
            api.authorize(token, false)
        }
    }
})
