module.exports = Em.Component.extend({
    layoutName: 'components/inline-error',

    classNames: ['inline-error'],

    classNameBindings: ['error:has-error'],

    record: null,

    error: Em.computed.oneWay('record.error')
})
