module.exports = Em.Controller.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    n: null,

    load: function() {
        var self = this
        return this.container.lookup('api:billy').get('/organizations/' + this.get('organization.id') + '/nextVoucherNo')
            .then(function(payload) {
                var n = payload.nextVoucherNo
                self.set('n', n)
            })
    },

    peek: function() {
        return this.get('n')
    },

    getAndIncrement: function() {
        var n = this.get('n')
        this.incrementProperty('n')
        return n
    },

    release: function(n) {
        if (Number(n) + 1 === this.get('n')) {
            this.decrementProperty('n')
        }
    }
})
