var t = require('i18n').t

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'exports'],

    organization: Em.computed.oneWay('controllers.organization.model'),

    isUploading: false,

    error: null,

    importStats: false,

    helpUrl: function() {
        return t('contacts_import.help_url')
    }.property('organization'),

    uploadUrl: function() {
        return ENV.apiUrl + '/organizations/' + this.get('organization.id') + '/contacts'
    }.property('organization'),

    reloadData: function() {
        BD.store.unloadAllByType(Billy.ContactPerson)
        BD.store.unloadAllByType(Billy.Contact)
        var organizationId = this.get('organization.id')
        var api = this.container.lookup('api:billy')
        return api.get('/contacts?organizationId=' + organizationId)
            .then(function(payload) {
                var paging = payload.meta.paging
                var data = payload.contacts
                if (paging.total <= data.length) {
                    // Only "loadAll" if we got _all_ the contacts (i.e. not paged) from the server
                    BD.store.loadAll(Billy.Contact, data)
                }
            })
    },

    actions: {
        exportContacts: function() {
            this.get('controllers.exports').send('exportContacts', 'xlsx')
        },

        uploadFailed: function(context) {
            this.set('error', (context.payload && context.payload.errorMessage) || t('util.request.default_error'))
                .set('importStats', null)
        },

        didUploadFile: function(context) {
            this.set('error', null)
                .set('importStats', context.payload.data)
            this.reloadData() // we reload in the background since the user stays on the same route
        }
    }
})
