var t = require('i18n').t

module.exports = require('./dashboard-widget-abstract-top').extend({
    topUrlPath: 'topSuppliers',

    title: function() {
        return t('dashboard.widget.top.suppliers_title')
    }.property(),

    emptyText: function() {
        return t('dashboard.widget.top.suppliers_empty_text')
    }.property(),

    noDataTitle: function() {
        return t('dashboard_widget.top.suppliers.no_data_title')
    }.property(),

    noDataText: function() {
        return t('dashboard_widget.top.suppliers.no_data')
    }.property(),

    exampleName: function() {
        return t('dashboard_widget.top.suppliers.example_name')
    }.property()
})
