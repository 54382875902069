var numeral = require('numeral')

Billy.Product.reopen({
    nameWithProductNo: function() {
        var productNo = this.get('productNo')
        return this.get('name') + (productNo ? ' (' + productNo + ')' : '')
    }.property('name', 'productNo'),

    pricesCommaSeparated: function() {
        var r = []
        this.get('prices').forEach(function(price) {
            r.push(numeral(price.get('unitPrice')).money(price.get('currency')))
        })
        return r.join(', ')
    }.property('prices.@each', 'prices.@each.unitPrice', 'prices.@each.currency')
})
