var functionProxy = require('function-proxy')
var i18n = require('i18n')
var FileUploadRequest = window.FileUploadRequest
module.exports = Em.Component.extend({

    layoutName: 'components/bill-index-simple-upload-preview',

    paymentMethod: null,

    description: null,

    uploadRequest: null,

    isProcessing: false,

    isCompleted: false,

    file: null,

    hasError: false,

    errorMessage: null,

    progress: 0,

    method: 'POST',

    url: ENV.apiUrl + '/files',

    isNotValid: function() {
        return !this.get('paymentMethod') || !this.get('description')
    }.property('{paymentMethod,description}'),

    onUploadProgress: function(e) {
        this.set('isProcessing', true)
    },

    handleError: function(payload, errorMessage) {
        this.set('isComplete', true)
        this.set('hasError', true)
        if (errorMessage) {
            this.set('errorMessage', errorMessage)
        } else {
            this.set('errorMessage', payload)
        }
    },

    onReadyStateChange: function() {
        var self = this
        Em.run(function() {
            var uploadRequest = self.get('uploadRequest')
            var payload
            var file
            if (uploadRequest.readyState === 4) {
                try {
                    payload = JSON.parse(uploadRequest.responseText)
                } catch(exception) {
                    payload = uploadRequest.responseText
                }
                self.set('isProcessing', false)
                self.set('isCompleted', true)

                if (uploadRequest.status >= 200 && uploadRequest.status < 300) {
                    if (payload && payload.files && payload.files.length) {
                        file = BD.store.load(Billy.File, payload.files[0])
                        var description = self.get('description')
                        var paymentMethod = self.get('paymentMethod')

                        self.sendAction('createBill', {
                            upload: file,
                            description: description,
                            paymentMethod: paymentMethod
                        })
                        self.sendAction('removePreview', self.get('file'))
                    }
                } else if (uploadRequest.status === 422) {
                    self.handleError(payload, payload.errorMessage)
                } else {
                    self.handleError(payload)
                }
            }
        })
    },

    onUploadError: function(payload, errorMessage) {
        var self = this
        Em.run(function() {
            self.handleError(errorMessage)
        })
    },

    actions: {
        removePreview: function() {
            var file = this.get('file')
            this.sendAction('removePreview', file)
        },

        addBill: function() {
            var file = this.get('file')
            this.send('uploadFile', file)
        },

        uploadFile: function() {
            var method = this.get('method')
            var url = this.get('url')
            var file = this.get('file')
            var uploadRequest = new FileUploadRequest(method, url, file.file)
            var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')

            uploadRequest.setRequestHeader('X-Filename', file.name)
            uploadRequest.setRequestHeader('X-File-Size', file.file.size)
            uploadRequest.setRequestHeader('Accept-Language', i18n.locale())
            uploadRequest.setRequestHeader('X-Access-Token', accessToken)

            this.set('uploadRequest', uploadRequest)

            uploadRequest.on('readystatechange', functionProxy(this.onReadyStateChange, this))
            uploadRequest.on('progress', functionProxy(this.onUploadProgress, this))
            uploadRequest.on('error', functionProxy(this.onUploadError, this))
            uploadRequest.send()
        }
    }
})
