var i18n = require('i18n')
var t = i18n.t
var getThumbnailUrl = require('../utils/get-thumbnail-url')
var _bills = []

module.exports = Em.Component.extend({
    layoutName: 'components/bills-index-simple-uploader',

    classNames: ['bills-index-simple-uploader'],

    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    showUploader: true,

    doneUploading: false,

    uploadRequest: null,

    customButtonText: t('bills.simple_index.drop_area_link'),

    paymentMethodOptions: [],

    bills: [],

    fileNames: [],

    fileNamesDisplay: function() {
        return this.get('fileNames')
    }.property('fileNames'),

    init: function() {
        var self = this

        this._super()

        this.get('api').request('GET', '/v2/accounts?isPaymentEnabled=true&organizationId=' + this.get('organization.id'), { headers: { 'accept-language': i18n.locale() } })
            .then(function(res) {
                var paymentMethodOptions = [Ember.Object.create({
                    value: 'unpaid',
                    name: t('bills.simple_index.unpaid')
                })]

                res.accounts.forEach(function(account) {
                    paymentMethodOptions.push(Ember.Object.create({
                        value: account.id,
                        name: account.name
                    }))
                })

                self.set('paymentMethodOptions', paymentMethodOptions)
            })
    },

    billEmailAddress: function() {
        return this.get('organization.billEmailAddress')
    }.property('organization'),

    billEmailAddressLink: function() {
        return 'mailto:' + this.get('billEmailAddress')
    }.property('billEmailAddress'),

    numBills: function() {
        return this.get('bills').length
    }.property('bills'),

    populateBill: function(upload) {
        var self = this
        var api = this.get('api')
        var organizationId = this.get('organization.id')

        api.request('POST', '/v2/bills', {
            payload: {
                bill: {
                    type: 'bill',
                    entryDate: moment().format('YYYY-MM-DD'),
                    isBare: true,
                    state: 'draft',
                    taxMode: 'excl',
                    origin: 'web',
                    organizationId: organizationId,
                    lines: [{}],
                    attachments: [{
                        organizationId: organizationId,
                        fileId: upload.file.id
                    }]
                }
            }
        }).then(function(res) {
            if (!res.bills.length) {
                self.errorOnFileName(upload.browserFile.name)
                return
            }

            if (!res.attachments.length) {
                self.errorOnFileName(upload.browserFile.name)
                return
            }

            _bills = _bills.map(function(bill) {
                if (bill.file.name === upload.browserFile.name) {
                    bill.id = res.bills[0].id
                    bill.contactId = res.bills[0].contactId
                    bill.entryDate = res.bills[0].entryDate
                    bill.created = true
                    bill.progress = 1
                }

                return bill
            })

            self.set('bills', _bills)
        })
    },

    errorOnFile: function(fileName) {
        _bills = _bills.map(function(bill) {
            if (bill.file.name === fileName) {
                bill.error = true
            }
            return bill
        })

        this.set('bills', _bills)
    },

    resetUi: function() {
        this.set('showUploader', true)
        this.set('doneUploading', false)
        this.set('bills', [])
        _bills = []
    },

    isNotValid: function() {
        return !!_.size(_.filter(this.get('bills'), function(bill) {
            return !bill.paymentMethod || !bill.description
        }))
    }.property('bills.@each.{paymentMethod,description}'),

    actions: {
        didStartUploading: function(upload) {
            this.set('showUploader', false)

            _bills.push({
                uploaded: false,
                progress: 0,
                file: upload.browserFile,
                thumbnailUrl: 'https://mit.billy.dk/releases/default/assets/images/misc/bill-example.png',
                backgroundImageStyle: 'background-image: url(https://mit.billy.dk/releases/default/assets/images/misc/bill-example.png)'
            })

            this.set('bills', _bills)
        },

        createPreview: function(file) {
            this.set('showUploader', false)
            var preview
            if (file.fileType === 'application/pdf') {
                preview = document.createElement('iframe')
                preview.setAttribute('width', '100px')
                preview.setAttribute('height', '100px')
                preview.setAttribute('frameborder', 0)
                preview.setAttribute('src', file.data)
            } else {
                preview = document.createElement('img')
                preview.setAttribute('src', file.data)
            }
            this.get('fileNames').pushObject({ name: file.name, preview: preview, file: file.file })
        },

        didUploadFile: function(upload) {
            _bills = _bills.map(function(bill) {
                if (bill.file.name === upload.browserFile.name) {
                    bill.uploaded = true
                    bill.progress = 0.5
                    bill.thumbnailUrl = getThumbnailUrl(upload.file.get('downloadUrl'), { size: '100x100', fit: 'contain' })
                    bill.backgroundImageStyle = 'background-image: url(' + bill.thumbnailUrl + ')'
                }
                return bill
            })

            this.set('bills', _bills)
            this.populateBill(upload)
        },

        removePreview: function(previewIndex) {
            var list = this.get('fileNames')
            list.removeObject(previewIndex)
            this.set('fileNames', list)
            if (list.length === 0) {
                this.resetUi()
            }
        },

        createBill: function(data) {
            var self = this
            var api = this.get('api')
            var organizationId = this.get('organization.id')

            var payload = {
                bill: {
                    type: 'bill',
                    entryDate: moment().format('YYYY-MM-DD'),
                    isBare: true,
                    state: 'draft',
                    taxMode: 'excl',
                    organizationId: organizationId,
                    lines: [{ description: data.description }],
                    attachments: [{
                        organizationId: organizationId,
                        fileId: data.upload.id
                    }]
                }
            }

            var bill = {}
            if (payload.paymentMethod && payload.paymentMethod !== 'unpaid') {
                bill.paymentDate = moment(new Date()).format('YYYY-MM-DD')
                bill.paymentAccountId = payload.paymentMethod
            }

            api.request('POST', '/v2/bills', { payload: payload })
                .then(function(res) {
                    if (!res.bills.length) {
                        self.errorOnFileName(payload.browserFile.name)
                    }

                    if (!res.attachments.length) {
                        self.errorOnFileName(payload.browserFile.name)
                    }
                    self.sendAction('onUploadDone')
                })
        },

        removeBill: function(billToDelete) {
            var self = this
            var api = this.get('api')

            api.request('DELETE', '/v2/bills/' + billToDelete.id)
                .then(function() {
                    _bills = _.compact(_bills.map(function(bill) {
                        if (bill.id === billToDelete.id) return null

                        return bill
                    }))

                    self.set('bills', _bills)

                    if (_bills.length < 1) {
                        self.resetUi()
                    }
                })
        },

        doneUploading: function() {
            this.set('doneUploading', true)
        },

        addBills: function() {
            var self = this
            var api = this.get('api')

            _bills = _bills
                .filter(function(bill) {
                    return bill.paymentMethod && bill.description
                })
                .map(function(bill) {
                    var payload = {
                        id: bill.id,
                        state: 'draft',
                        isBare: true,
                        entryDate: bill.entryDate,
                        organizationId: self.get('organization.id')
                    }

                    if (bill.paymentMethod && bill.paymentMethod !== 'unpaid') {
                        payload.paymentDate = moment(new Date()).format('YYYY-MM-DD')
                        payload.paymentAccountId = bill.paymentMethod
                    }

                    if (bill.description) {
                        payload.lines = [{
                            description: bill.description
                        }]
                    }

                    return payload
                })

            // If no bills are valid, abandon
            if (!_.size(_bills)) return

            api.request('PATCH', '/v2/bills', { payload: { bills: _bills } })
                .catch(function() { })
                .finally(function() {
                    self.resetUi()
                    self.sendAction('onUploadDone')
                })
        }
    }
})
