var t = require('i18n').t
var Window = require('ember-window')
var postingSides = require('posting-sides')
var Scope = require('../utils/scope')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/transaction-window'),

    auth: Ember.inject.service(),

    classNames: ['transaction-window-view'],

    segment: Em.inject.service(),

    isAuthorizedForTransactionTemplates: false,

    actions: {
        addContribution: function() {
            if (this._shouldContinueTransactionActionOrOpenUpgradeOverlay()) {
                this._selectType('transaction-owner-window', 'contribution')
            }
        },
        addWithdrawal: function() {
            if (this._shouldContinueTransactionActionOrOpenUpgradeOverlay()) {
                this._selectType('transaction-owner-window', 'withdrawal')
            }
        },
        addBankTransfer: function() {
            var self = this

            if (this._shouldContinueTransactionActionOrOpenUpgradeOverlay()) {
                var w = self.container.lookup('component:bank-transfer-window')
                self.replaceWith(w)
                w.set('organization', self.container.lookup('controller:organization').get('content'))
                w.show()

                self.get('segment').track('Transaction Template Viewed (FE)', {
                    type: 'bank-transfer-window'
                })
            }
        },
        addManualTransaction: function() {
            this._selectType('transaction-manual-window')
        },
        addMultipleCurrencyTransaction: function() {
            this._selectType('transaction-multiple-currency-window')
        },
        addAccountCurrencyAdjustmentTransaction: function() {
            if (this._shouldContinueTransactionActionOrOpenUpgradeOverlay()) {
                this._selectType('transaction-account-currency-adjustment-window')
            }
        }
    },
    _selectType: function(component, type) {
        var win = this.container.lookup('component:' + component)
        if (type) {
            win.set('type', type)
        }
        win.set('content', this._createTransaction(type))
        this.replaceWith(win)
        win.show()

        this.get('segment').track('Transaction Template Viewed (FE)', {
            type: component + (type ? '-' + type : '')
        })
    },
    _createTransaction: function(type) {
        var description = ''
        if (type === 'contribution') {
            description = t('transaction.window.contribution')
        } else if (type === 'withdrawal') {
            description = t('transaction.window.withdrawal')
        }
        var daybookTransaction = Billy.DaybookTransaction.createRecord({
            organization: this.container.lookup('controller:organization').get('content'),
            state: 'draft',
            entryDate: moment(),
            description: description,
            priority: 1
        })
        var side = null
        if (type === 'contribution') {
            side = postingSides.debit
        } else if (type === 'withdrawal') {
            side = postingSides.credit
        }
        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: daybookTransaction,
            side: side,
            priority: 1
        })
        if (!type) {
            Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: daybookTransaction,
                side: side,
                priority: 2
            })
        }
        return daybookTransaction
    },
    _checkIsAuthorizedForTransactionTemplates: function() {
        this.set('isAuthorizedForTransactionTemplates', this.get('auth').isAuthorized(Scope.TransactionTemplate))
    }.on('init'),
    _shouldContinueTransactionActionOrOpenUpgradeOverlay: function() {
        var self = this

        if (!this.get('isAuthorizedForTransactionTemplates')) {
            self.container.lookup('component:upgrade-plan-overlay').show()
            self.close()

            return false
        }

        return true
    },

    contactSupportText: function() {
        return t('transaction.window.contact_support', {
            supportLinkStart: '<a href="' + t('support_url') + '" target="_blank" class="link">',
            supportLinkEnd: '</a>'
        }).htmlSafe()
    }.property()
})
