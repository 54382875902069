var Window = require('ember-window')

module.exports = Window.extend({
    template: require('../templates/components/invoice-date-modal'),

    applyModalMask: false,

    entryDate: null, // set when invoking the modal

    approveInvoice: null, // set when invoking the modal

    customActions: {
        closeEmber: function() {
            this.close()
        },
        approveInvoice: function(event) {
            this.approveInvoice(event)
        }
    }
})
