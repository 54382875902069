module.exports = Em.Component.extend({
    classNameBindings: ['canNavigate::display-none'],

    annualReport: Em.inject.service(),

    /**
     * @returns {boolean}
     */
    canNavigate: function() {
        return this.get('annualReport').canRevisitStep(this.get('previousStep'))
    }.property('previousStep'),

    /**
     * @returns {AnnualReportStepEnum}
     */
    previousStep: function() {
        return this.get('annualReport').getPreviousStep(this.get('annualReport.activeStep'))
    }.property('annualReport.activeStep'),

    actions: {
        /**
         * @returns {void}
         */
        didNavigateToPreviousStep: function() {
            this.container.lookup('router:main').transitionTo('annual_report.' + this.get('previousStep'))
        }
    }
})
