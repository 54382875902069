var t = require('i18n').t
var salesTaxPeriodOptions = require('../utils/sales-tax-period-options')
var analytics = require('../utils/analytics')

module.exports = Em.Controller.extend({
    queryParams: {
        q: 'q',
        tasksFilter: 'tasks',
        salesTaxPeriod: 'sales_tax_period',
        hasDrafts: 'drafts',
        hasOpenSalesTaxReturns: 'open_sales_tax'
    },

    needs: ['user'],

    user: Em.computed.alias('controllers.user.model'),

    userOrganizations: Em.inject.service(),

    startGuide: Em.inject.service(),

    q: '',

    tasksFilter: null,

    salesTaxPeriod: null,

    hasDrafts: false,

    hasOpenSalesTaxReturns: false,

    filteredOrganizations: function() {
        var orgs = this.get('organizations')
        var viewModeIsList = this.get('viewModeIsList')
        var q = (this.get('q') || '').toLowerCase()
        var tasksFilter = this.get('tasksFilter')
        var salesTaxPeriod = this.get('salesTaxPeriod')
        var hasDrafts = this.get('hasDrafts')
        var hasOpenSalesTaxReturns = this.get('hasOpenSalesTaxReturns')
        return orgs.filter(function(org) {
            if ((org.data.name || '').toLowerCase().indexOf(q) === -1) {
                return false
            }
            if (!viewModeIsList && org.loaded) {
                if (tasksFilter && !org.tasks.some(function(task) { return task.get('state') === tasksFilter })) {
                    return false
                }

                if (salesTaxPeriod && org.organization.get('salesTaxPeriod') !== salesTaxPeriod) {
                    return false
                }

                if (hasDrafts && !org.get('hasDraftItems')) {
                    return false
                }

                if (hasOpenSalesTaxReturns && org.get('salesTaxReturns.length') === 0) {
                    return false
                }
            }
            return true
        })
    }.property('q', 'viewModeIsList', 'tasksFilter', 'salesTaxPeriod', 'hasDrafts', 'hasOpenSalesTaxReturns', 'organizations.@each.{loaded,organization.salesTaxPeriod,draftBillsCount,draftDaybookTransactionsCount,salesTaxReturns.length}'),

    isFiltered: function() {
        return !!this.get('q') || !!this.get('tasksFilter') || !!this.get('salesTaxPeriod') || this.get('hasDrafts') || this.get('hasOpenSalesTaxReturns')
    }.property('q', 'tasksFilter', 'salesTaxPeriod', 'hasDrafts', 'hasOpenSalesTaxReturns'),

    isNotFiltered: Em.computed.not('isFiltered'),

    viewMode: function(key, value) {
        // Setting the viewMode to list as step 1 of deprecating the details view.
        // TODO: Remove unused code when the deprecation is completed.
        return 'list'

        // if (arguments.length === 1) {
        //     return storage('organizationsViewMode') || 'list'
        // } else {
        //     storage('organizationsViewMode', value)
        //     return value
        // }
    }.property(),

    viewModeIsList: Em.computed.equal('viewMode', 'list'),

    viewModeOptions: function() {
        return [
            Em.O({
                value: 'list',
                label: t('organizations.view_mode.list')
            }),
            Em.O({
                value: 'detailed',
                label: t('organizations.view_mode.detailed')
            })
        ]
    }.property(),

    tasksFilterOptions: function() {
        return [
            Em.O({
                value: null,
                name: t('all')
            }),
            Em.O({
                value: 'pending',
                name: t('organizations.tasks_filter_option.pending')
            }),
            Em.O({
                value: 'completed',
                name: t('organizations.tasks_filter_option.completed')
            })
        ]
    }.property(),

    salesTaxPeriodOptions: function() {
        return [
            Em.O({
                value: null,
                name: t('all')
            })
        ].concat(salesTaxPeriodOptions.get())
    }.property(),

    trackInternalSignupAnalyticsData: function() {
        var attributes = analytics.getInternalAttributes('existing_customer')
        var url = window.location.href + '?' + attributes
        analytics.trackSignupAnalyticsData(url)
    },

    redirectToCreateOrganizationFlow: function() {
        var user = this.get('user')
        var signupGuideController = this.container.lookup('controller:signup-guide')

        signupGuideController.set('user', user)

        this.trackInternalSignupAnalyticsData()
        this.transitionTo('signup-guide', { queryParams: { isInternalFlow: true } })
    },

    actions: {
        resetFilters: function() {
            this.set('q', '')
            this.set('tasksFilter', null)
            this.set('salesTaxPeriod', null)
            this.set('hasDrafts', false)
            this.set('hasOpenSalesTaxReturns', false)
        },

        create: function() {
            // Override Google Analytics UTM parameters
            if (window.ga) {
                window.ga('set', 'campaignSource', 'webapp')
                window.ga('set', 'campaignMedium', 'internal')
            }

            this.get('userOrganizations').reset()
            this.redirectToCreateOrganizationFlow()
        }
    }
})
