var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organization}/{resourceType}/{resourceId}/attachments',
    urlParamMap: {
        organization: 'resource.organization.id',
        resourceType: 'resourceType'
    }
})
