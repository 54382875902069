var imageThumbnail = require('../utils/image-thumbnail')

module.exports = Em.Component.extend({
    classNames: ['v2-attachments-editor-item'],

    attachment: null,

    thumbnailUrl: function() {
        // it is `url` property when loading the view and `downloadUrl` property right after uploadig
        return imageThumbnail(this.get('attachment.downloadUrl') || this.get('attachment.url'), { standard: 'attachment-s' })
    }.property('attachment'),

    actions: {
        deleteAttachment: function() {
            this.sendAction('deleteAttachment', this.get('attachment'))
        }
    }
})
