var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

var tokenName = 'Online card payment integration'

module.exports = Em.Component.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    app: null,

    userController: function() {
        return this.container.lookup('controller:user')
    }.property(),

    user: Em.computed.oneWay('userController.model'),

    getActorToken: function(actor) {
        return actor.get('accessTokens').promise
            .then(function(tokens) {
                return tokens.get('firstObject.material')
            })
    },

    findToken: function() {
        var self = this
        var organization = this.get('organization')
        return Billy.OAuthActor.findByQuery({
            organizationId: organization.get('id')
        }).promise
            .then(function(actors) {
                var actor = actors.findBy('name', tokenName)
                if (actor) {
                    return self.getActorToken(actor)
                } else {
                    return null
                }
            })
    },

    createToken: function() {
        var self = this
        var organization = this.get('organization')
        var actor = Billy.OAuthActor.createRecord({
            organization: organization,
            name: tokenName,
            description: 'Used by the online card payment integration. Do not edit.'
        })
        return actor.save()
            .then(function() {
                return self.getActorToken(actor)
            })
    },

    findOrCreateToken: function() {
        var self = this
        return this.findToken()
            .then(function(token) {
                if (token) {
                    return token
                } else {
                    return self.createToken()
                }
            })
    },

    auth: function() {
        var self = this
        var email = this.get('user.email')
        batmask.maskDelayed()
        return this.findOrCreateToken()
            .then(function(token) {
                var url = ENV.PAYLIKE_WEBAPP_HOST + 'auth?access_token=' + token + '&email=' + encodeURIComponent(email) + '&referer=' + encodeURIComponent(document.location.href)
                window.location.href = url
            }, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.APP_STORE_AUTH, t('util.request.default_error'))
                throw e
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    actions: {
        auth: function() {
            return this.auth()
        }
    }
})
