var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListModelController = require('billy-webapp/ui/list/model-controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var ListAction = require('billy-webapp/ui/list/action')

module.exports = ListModelController.extend({
    needs: ['user'],

    query: function() {
        return {
            organization: this.get('controllers.user.activeOrganization'),
            isNew: false
        }
    }.property('controllers.user.activeOrganization'),

    type: Billy.SalesTaxRuleset,

    sortProperty: 'name',

    isLazy: false,

    tableRowHeight: ListController.tableRowHeightAdditionalSmallLine,

    itemControllerClass: ListItemController.extend({
        formattedRate: function() {
            return this.get('ratePercent') + ' %'
        }.property('rate')
    }),

    columns: [
        Column.create({
            header: t('name'),
            name: 'name',
            sortable: true,
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '<div class="two-rows-cell">' +
                        '<div>' +
                            '{{name}}' +
                            '{{#if isPredefined}}' +
                                ' <span class="label blue">' + t('tax_rates.predefined') + '</span>' +
                            '{{/if}}' +
                        '</div>' +
                        '<div class="small-font downtoned-text ellipsis">{{description}}&nbsp;</div>' +
                    '</div>'
                )
            })
        }),
        Column.create({
            header: t('tax_rate_editor.abbreviation'),
            name: 'abbreviation',
            width: 100
        })
    ],

    listActions: [
        ListAction.create({
            icon: 'icons/pencil',
            tip: t('edit'),
            click: 'editRuleset'
        }),
        ListAction.create({
            icon: 'icons/trashcan',
            tip: t('delete'),
            click: 'deleteRuleset'
        })
    ]
})
