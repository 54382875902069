var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    classNames: ['inline-creator'],

    match: null,

    createSubjectAssociationAndMatch: function(subject) {
        var self = this

        var association = Billy.BankLineSubjectAssociation.createRecord({
            match: this.get('match'),
            subject: subject
        })

        association.save()
            .then(function() {
                self.sendAction('didClose')
            }, function(e) {
                association.rollback()
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_CREATE, e.message)
            })
    },

    actions: {
        close: function() {
            this.sendAction('didClose')
        }
    }
})
