var t = require('i18n').t
var Window = require('ember-window')
var numeral = require('numeral')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/bank-import-progress-window'),

    closable: false,

    isAborted: false,

    total: 0,

    completed: 0,

    progress: function() {
        return this.get('completed') / this.get('total')
    }.property('completed', 'total'),

    status: function() {
        return t('bank_import.progress_status', { completed: numeral(this.get('completed')).format('0,0'), total: numeral(this.get('total')).format('0,0') })
    }.property('completed', 'total'),

    actions: {
        abort: function() {
            this.set('isAborted', true)
        }
    }
})
