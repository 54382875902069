var Window = require('ember-window')
var salesTaxPeriodOptions = require('../utils/sales-tax-period-options')
var eventBus = require('../utils/event-bus')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-return-period-change-window'),

    salesTaxReturn: null,
    hasVat: Em.computed.alias('salesTaxReturn.organization.hasVat'),

    periodChange: function() {
        return BD.AnonymousRecord.createRecord({
            targetSalesTaxPeriod: null
        })
    }.property(),

    salesTaxPeriodOptions: function() {
        return salesTaxPeriodOptions.get()
    }.property(),

    isButtonDisabled: Em.computed.not('periodChange.targetSalesTaxPeriod'),

    actions: {
        submit: function() {
            var self = this
            this.get('periodChange').save('/salesTaxReturns/' + this.get('salesTaxReturn.id') + '/periodChange', {
                root: 'periodChange'
            })
                .mask()
                .success(function() {
                    eventBus.trigger('accounting-changed')
                    self.close()
                    self.trigger('didSubmit')
                })
        }
    }
})
