var i18n = require('i18n')
var moment = require('moment')
var _ = require('lodash')
var t = i18n.t
var popupWindow = require('../utils/popup-window')
var InfiniteScrollController = require('../mixins/infinite-scroll-controller')
var datePeriodToRange = require('../utils/date-period-to-range')
var NOTIFICATION_KEYS = require('../notificationKeys')
var Scope = require('../utils/scope')

module.exports = Em.Controller.extend(InfiniteScrollController, {
    auth: Em.inject.service(),

    needs: ['organization', 'bankSync', 'bankSyncPossibleSubjects', 'nextVoucherNo'],

    account: Em.computed.alias('controllers.bankSync.model'),

    organization: Em.computed.oneWay('controllers.organization.model'),

    hasBankIntegration: Em.computed.alias('controllers.bankSync.hasBankIntegration'),

    bankIntegrations: Em.computed.alias('model'),

    bankConnections: Ember.inject.service(),

    init: function() {
        this._super()
        this.set('selectedLines', [])
    },

    isLoaded: false,

    remoteHasMore: false,

    entryDatePeriod: null,

    q: null,

    accountId: function() {
        return this.get('account.id')
    }.property('account'),

    organizationId: function() {
        return this.get('organization.id')
    }.property('organization'),

    bankLinesIds: null,

    matchSuggestions: null,

    segment: Em.inject.service(),

    loadMatchSuggestions: function() {
        var scopes = [Scope.BankReconciliationAutoMatch]
        if (!this.get('auth').isAuthorized(scopes)) {
            return
        }

        var self = this
        var api = this.get('api')
        var endpointUrl = '/api/matching-service/' + this.get('organizationId') + '/matches/' + this.get('accountId') + '/bank_lines'
        var bankLinesIds = this.get('bankLinesIds')

        if (!this.get('isLoaded')) {
            return Em.RSVP.resolve()
        }

        if (bankLinesIds.length) {
            return new Em.RSVP.Promise(function(resolve, reject) {
                api.request('POST', endpointUrl, { accept: 'application/json', payload: { bank_line_ids: bankLinesIds } })
                    .then(function(payload) {
                        self.set('matchSuggestions', _.toArray(payload.suggestions).filter(function(suggestion) {
                            var transaction = suggestion.transactions[0]
                            var reconcilable = suggestion.reconcilables[0]
                            // filter out suggestions that has reconcilable with different currency than the transaction
                            if (reconcilable.currency !== transaction.currency) {
                                return false
                            }
                            return !transaction.matchId && reconcilable.state !== 'draft'
                        }))
                        resolve()
                    })
                    .catch(function(error) {
                        reject(error)
                    })
            })
        }
    }.observes('accountId', 'isLoaded', 'bankLinesIds'),

    load: function() {
        var self = this
        var account = this.get('account')
        var sortProperty = this.get('sortProperty')
        var findProperties = {
            accountId: account.get('id'),
            isApproved: false,
            sortProperty: sortProperty,
            include: 'bankLineMatch.lines,bankLineMatch.subjectAssociations,bankLineSubjectAssociation.subject',
            pageSize: module.exports.maxMatches
        }

        var bankSyncStartDate = this.get('account.organization.bankSyncStartDate')

        if (self.get('entryDatePeriod.value')) {
            findProperties.entryDatePeriod = self.get('entryDatePeriod.value')
        } else if (bankSyncStartDate) {
            findProperties.entryDatePeriod = 'from:' + bankSyncStartDate.format('YYYY-MM-DD')
        }

        if (self.get('q')) {
            findProperties.q = self.get('q')
        }
        this.set('remoteHasMore', false)
            .set('content', null)
            .set('isLoaded', false)
            .set('editMode', false)
            .set('selectedLines', [])

        return new Em.RSVP.Promise(function(resolve, reject) {
            Em.RSVP.all([
                Billy.BankLineMatch.find(findProperties).promise,
                self.get('controllers.nextVoucherNo').load()
            ])
                .then(function(values) {
                    var matchesRecordArray = values[0]
                    var matches = matchesRecordArray.get('content').copy() // we just want the raw array, not the BD.RecordArray
                    var model = Em.ArrayController.create({
                        container: self.container,
                        parentController: self,
                        model: matches,
                        itemController: 'bank-sync-match',
                        sortProperties: [sortProperty + 'Local']
                    })
                    self.set('bankLinesIds', _.flatten(matches.map(function(match) { return match.get('lines.ids') })))
                    self.set('content', model)
                        .set('isLoaded', true)
                        .set('remoteHasMore', matches.get('length') < matchesRecordArray.get('paging.total'))
                    matchesRecordArray.destroy()
                    resolve()
                }, function() {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_LOAD, t('util.request.default_error'))
                    self.set('isLoaded', true)
                    reject()
                })
        })
    }.observes('sortProperty', 'q', 'entryDatePeriod'),

    isBankSyncReadAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.BankSyncRead)
    }.property(),

    lineCount: function() {
        var model = this.get('model')
        if (!model) {
            return 0
        }
        return model.reduce(function(result, match) {
            return result + match.get('lineCount')
        }, 0)
    }.property('model.@each.lineCount'),

    checkLoadMore: function() {
        // If the remote is empty or this page is not empty, then we don't need to load any more
        if (!this.get('remoteHasMore') || this.get('model.length') > 0) {
            return
        }

        // If the controller is currently saving/deleting something, we set the template into loading mode, but we do not load anything yet
        if (this.get('isManipulating')) {
            this.set('isLoaded', false)
            return
        }

        this.load()
    }.observes('model.length'),

    addMatch: function(match) {
        this.get('model.model').addObject(match)
    },

    removeMatch: function(match, keepSelectedLines) {
        var self = this
        if (keepSelectedLines !== true) {
            match.get('lines').forEach(function(line) {
                self.send('deselectLine', line)
            })
        }
        this.get('model.model').removeObject(match)
    },

    sortProperty: 'entryDate',

    sortPropertyOptions: Em.computed.alias('controllers.bankSync.sortPropertyOptions'),

    formattedLineCount: function() {
        return this.get('lineCount') + (this.get('remoteHasMore') ? '+' : '')
    }.property('lineCount'),

    editMode: false,

    selectedLines: null,

    areAllSelected: function() {
        return this.get('lineCount') === this.get('selectedLines.length')
    }.property('lineCount', 'selectedLines.length'),

    areSomeSelected: function() {
        var selectedLinesLength = this.get('selectedLines.length')
        return selectedLinesLength > 0 && this.get('lineCount') > selectedLinesLength
    }.property('lineCount', 'selectedLines.length'),

    entryDateRange: function() {
        var period = this.get('entryDatePeriod.value')
        if (!period) {
            return
        }

        return datePeriodToRange(period, {
            firstFiscalYearStart: this.get('account.organization.fiscalYearEndMonth'),
            firstFiscalYearEnd: this.get('account.organization.firstFiscalYearEnd')
        })
    }.property('entryDatePeriod', 'account.organization.firstFiscalYearStart', 'account.organization.firstFiscalYearEnd'),

    actions: {
        downloadBankLines: function() {
            var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
            var organizationId = this.get('account.organization.id')
            var accountId = this.get('account.id')
            var entryDateRange = this.get('entryDateRange')
            var url = new URL(ENV.newApiUrl + '/organizations/' + organizationId + '/bankLines/download')
            url.searchParams.append('access_token', accessToken)
            url.searchParams.append('acceptLanguage', i18n.locale())
            url.searchParams.append('accountId', accountId)
            url.searchParams.append('isReconciled', 'false')

            var period = 'all'

            if (entryDateRange) {
                var startDate = moment(entryDateRange.start).format('YYYY-MM-DD')
                var endDate = moment(entryDateRange.end).format('YYYY-MM-DD')
                url.searchParams.append('minEntryDate', startDate)
                url.searchParams.append('maxEntryDate', endDate)
                period = 'dates:' + startDate + '...' + endDate
            }

            popupWindow.open(url.toString())
            this.get('segment').track('XXX - Mikkel - Exports', {
                export_type: 'Bank lines',
                file_format: 'xlsx',
                period: period
            })
        },

        toggleEditMode: function() {
            if (this.get('editMode')) {
                this.set('selectedLines', [])
                this.set('editMode', false)
            } else {
                this.set('editMode', true)
            }
        },

        toggleSelectAll: function() {
            if (this.get('selectedLines.length')) {
                this.set('selectedLines', [])
            } else {
                var all = this.get('model').mapBy('lines').reduce(function(result, lines) {
                    lines.forEach(function(line) {
                        result.push(line.get('model'))
                    })
                    return result
                }, [])
                this.get('selectedLines').addObjects(all)
            }
        },

        selectLine: function(line) {
            this.get('selectedLines').addObject(line)
        },

        deselectLine: function(line) {
            this.get('selectedLines').removeObject(line)
        },

        deleteSelectedLines: function() {
            var self = this
            var possibleSubjectsController = this.get('controllers.bankSyncPossibleSubjects')
            this.container.lookup('util:dialog').confirmWarning(null, t('bank_sync.confirm_delete_postings'), t('bank_sync.confirm_delete_postings_yes'), t('cancel'))
                .then(function() {
                    self.set('isManipulating', true)
                    var selectedLines = self.get('selectedLines')
                    var matches = []
                    var subjects = []

                    // Reset the selection
                    self.set('selectedLines', [])

                    // Remove all matches that will be empty afterwards
                    selectedLines.forEach(function(line) {
                        var match = line.get('match')
                        matches.push(match)
                        if (match.get('lines').every(selectedLines.contains.bind(selectedLines))) {
                            self.removeMatch(match)
                            subjects = subjects.concat(match.get('subjectAssociations').mapBy('subject'))
                        }
                    })

                    // Add subjects to possible subjects since they're now free for grabs
                    subjects.forEach(possibleSubjectsController.addSubject.bind(possibleSubjectsController))

                    // Uniq'ify the matches array since multiple lines might belong to the same match
                    matches.uniq()

                    // If the page is now empty we disable editMode
                    if (self.get('model.length') === 0) {
                        self.set('editMode', false)
                    }

                    BD.deleteRecords(selectedLines)
                        .success(function() {
                            // Forget about matches that was deleted in other ways. As of writing this, the server automatically deletes matches that do not have any lines. This behavior might change in the future, and the following is also necessary for tests.
                            matches = matches.filterBy('isDestroying', false)

                            // Delete the matches, too
                            BD.deleteRecords(matches)
                                .on('complete', function() {
                                    // Stop the delete sequence
                                    self.set('isManipulating', false)

                                    // Load more in case we deleted all the lines on the page
                                    self.checkLoadMore()
                                })
                        })
                        .error(function() {
                            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_LINE_DELETE, t('bank_sync.delete_failed'))

                            // If the delete request failed, we need to re-add the matches
                            selectedLines.forEach(function(line) {
                                self.addMatch(line.get('match'))
                            })

                            // And remove the subjects from possible subjects again
                            subjects.forEach(possibleSubjectsController.removeSubject.bind(possibleSubjectsController))

                            // Re-add the lines to the selection
                            self.get('selectedLines').addObjects(selectedLines)
                            self.set('editMode', true)

                            // If checkLoadMore() set this flag to false, we have to set it back to true so the model is shown
                            self.set('isLoaded', true)

                            // Stop the delete sequence
                            self.set('isManipulating', false)
                        })
                })
        }
    },

    customActions: {
        emptyViewButtonClick: function() {
            if (this.get('isBankSyncReadAuthorized')) {
                this.get('bankConnections').connectBank(this.get('account'))
                return
            }

            /* only transition left */
            this.transitionToRoute('bank_import')
        }
    }
})

module.exports.maxMatches = 200
