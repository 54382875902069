var Window = require('ember-window')
var t = require('i18n').t

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/product-editor'),

    segment: Em.inject.service(),

    callback: null,

    width: 800,

    productIsArchived: null,

    initIsArchived: function() {
        this.set('productIsArchived', this.get('content.isArchived'))
    }.on('init'),

    title: function() {
        return this.get('content.isNew') ? t('products.create_product') : t('products.edit_product')
    }.property('content.isNew'),

    accountNatures: function() {
        return [Billy.AccountNature.find('revenue'), Billy.AccountNature.find('liability'), Billy.AccountNature.find('equity')]
    }.property(),

    inventoryAccountNatures: function() {
        return [Billy.AccountNature.find('asset')]
    }.property(),

    addInitialPrice: function() {
        var product = this.get('content')
        if (product.get('isNew') && product.get('prices.length') === 0) {
            Billy.ProductPrice.createRecord({
                currency: this.container.lookup('controller:organization').get('baseCurrency'),
                product: product
            })
        }
    }.observes('content'),

    isArchivedObserver: function() {
        var oldState = this.get('productIsArchived')
        var newState = this.get('content.isArchived')
        if (oldState !== undefined && !oldState && newState) {
            this.get('segment').track('Product Archived (FE)')
        }
        this.set('productIsArchived', this.get('content.isArchived'))
    }.observes('content.isArchived'),

    uploadHeaders: {
        'X-Image-Max-Width': 300,
        'X-Image-Max-Height': 300
    },

    imageUploadText: function() {
        return this.get('content.image') ? t('change') : t('products.editor.upload_image')
    }.property('content.image'),

    helpText: function() {
        return new Em.Handlebars.SafeString(t('products.editor.help_text'))
    }.property(),

    showSuppliersProductNo: function() {
        // Only Danish companies should see the supplier's product no. field
        return this.get('content.organization.country.id') === 'DK'
    }.property('content.organization.country.id'),

    actions: {
        addPrice: function() {
            Billy.ProductPrice.createRecord({
                product: this.get('content')
            })
        },

        deletePrice: function(price) {
            price.deleteRecord()
        },

        unarchive: function() {
            this.set('content.isArchived', false)
        },

        didUploadImage: function(context) {
            this.get('content')
                .set('image', context.file)
                .set('imageUrl', context.file.get('downloadUrl'))
        },

        didRemoveImage: function() {
            this.get('content')
                .set('image', null)
                .set('imageUrl', null)
        },

        save: function() {
            var self = this
            var product = this.get('content')
            var firstPrice = product.get('prices.firstObject')
            var isNewProduct = product.get('isNew')
            if (isNewProduct && product.get('prices.length') === 1 && Em.isEmpty(firstPrice.get('unitPrice'))) {
                firstPrice.deleteRecord()
            }

            product
                .save({
                    embed: ['prices']
                })
                .mask()
                .on('success', function() {
                    if (isNewProduct) {
                        self.get('segment').track('Create Product Completed (FE)')
                    } else {
                        self.get('segment').track('Product Updated (FE)')
                    }
                    self.close()
                    if (self.get('callback')) {
                        self.get('callback')(product)
                    }
                })
        }
    }
})
