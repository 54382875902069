var batmask = require('batmask')
var postingSides = require('posting-sides')

function DataOperationError(message) {
    this.message = message
    this.name = 'DataOperationError'
}
DataOperationError.prototype = new Error()
module.exports.DataOperationError = DataOperationError

BD.ModelOperationPromise.reopen({
    init: function() {
        var self = this
        this._super()
        this.promise = new Em.RSVP.Promise(function(resolve, reject) {
            self.success(resolve)
                .error(function(e) {
                    reject(new DataOperationError(e))
                })
        })
        this.then = this.promise.then.bind(this.promise)
    },

    mask: function() {
        batmask.maskDelayed()
        this.on('complete', function() {
            batmask.unmask()
        })
        return this
    }

})

BD.transforms.side = {
    deserialize: function(serialized) {
        if (Em.isNone(serialized)) {
            return null
        }
        return postingSides.resolveSide(serialized)
    },
    serialize: function(deserialized) {
        return Em.isNone(deserialized) ? null : deserialized.value
    }
}
