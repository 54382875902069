var Window = require('ember-window')

module.exports = Window.extend({
    template: require('../templates/components/void-invoice-modal'),

    applyModalMask: false,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    invoiceId: null, // set when invoking the modal

    voidInvoice: null, // set when invoking the modal

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        },
        closeEmber: function() {
            this.close()
        },
        voidInvoice: function() {
            if (typeof this.voidInvoice === 'function') {
                this.voidInvoice()
            }
        }
    }
})
