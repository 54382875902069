// This mixin will allow to easily restore the scrolling for a specific view.
// It is possible to use it inside a lazy-scroll view, or custom scrolling
// elements.

module.exports = Em.Mixin.create({
    // Used to identify the scrolling element (default: window)
    scrollingElement: '',

    activate: function() {
        this._super.apply(this, arguments)

        var self = this
        var scrollTo = this.get('lastScroll')

        // Restoring the scroll position.
        if (scrollTo) {
            // Note: This will make the UI blink...
            Ember.run.next(function() {
                var $element = Ember.$(self.get('scrollingElement'))
                $element.scrollTop(scrollTo)
            })
        }
        // else don't do anything to not break stuff.
    },

    deactivate: function() {
        this._super.apply(this, arguments)

        // Saving the scroll position, based on the scrolling element
        this.set('lastScroll', Ember.$(this.get('scrollingElement')).scrollTop())
    }
})
