var t = require('i18n').t

module.exports = require('./payment-terms-popover-form-abstract').extend({
    layoutName: 'components/payment-terms-popover-form-date',

    dueDateError: null,

    actions: {
        didClickTick: function() {
            var dueDate = this.get('dueDate')
            this.set('dueDateError', null)
            if (!dueDate) {
                this.set('dueDateError', t('required_field'))
                return
            }
            this.sendAction('didSelect', {
                dueDate: dueDate
            })
        }
    }
})
