module.exports = Em.Component.extend({
    layoutName: 'components/bank-connection-error-alert',

    organization: null,

    accountId: null,

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
