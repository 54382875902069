var _ = require('lodash')

module.exports = Em.Route.extend({
    queryParams: {
        sort_direction: {
            replace: true
        },
        period: {
            replace: true
        },
        include_voided: {
            replace: true
        },
        q: {
            replace: true
        }
    },

    queryParamsCached: null,

    beforeModel: function(transaction) {
        this.set('queryParamsCached', transaction.queryParams)
    },

    afterModel: function(model) {
        var accountId = model.get('id')
        var queryParamsCached = this.get('queryParamsCached')

        this.transitionTo('accounts', {
            queryParams: _.assign({
                initialRoute: '/' + accountId
            }, queryParamsCached)
        })
    },

    setupController: function(controller, account) {
        controller.set('model', account)
        this.controllerFor('account-postings').loadRecords()
    }
})
