var i18n = require('i18n')
var t = i18n.t

module.exports = Em.Component.extend({
    layoutName: 'components/loading-spinner',

    classNames: ['loading-spinner'],

    classNameBindings: ['autoCenter:loading-spinner-auto-center'],

    text: i18n.tProperty('text', t('loading_ellipsis')),

    autoCenter: false
})
