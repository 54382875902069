var colors = ['blue', 'green', 'red', 'yellow', 'purple', 'gray']

module.exports = Em.Component.extend({
    classNames: ['pie-chart'],

    setupChart: function() {
        this.chart = createChart(this.get('element'))
    }.on('didInsertElement'),

    dataDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this.redrawChart)
    }.observes('data').on('didInsertElement'),

    redrawChart: function() {
        this.chart.redraw(this.get('data') || [])
    }
})

module.exports.colors = colors

function createChart(parentEl) {
    var ret = {}
    var $parentEl = $(parentEl)
    var diameter = Math.min($parentEl.width(), $parentEl.height())

    var chart = d3.select(parentEl).append('svg')
        .attr('width', diameter)
        .attr('height', diameter)

    var radius = diameter / 2
    var paddinInPixels = 2
    var padAngle = paddinInPixels / (diameter * Math.PI) * 2 * Math.PI

    var g = chart.append('g')
        .attr('transform', 'translate(' + radius + ',' + radius + ')')

    var arc = d3.svg.arc()
        .outerRadius(radius)
        .innerRadius(radius / 2)

    var pie = d3.layout.pie()
        .sort(null)
        .value(function(d) {
            return d.value
        })
        .padAngle(padAngle)

    var fillClass = function(d, idx) {
        return 'svg-fill-' + colors[idx]
    }

    ret.redraw = function(data) {
        var pieData = pie(data)

        var path = g.selectAll('path')
            .data(pieData, function(d) {
                return d.data.key
            })

        path
            .attr('d', arc)
            .attr('class', fillClass)
            .attr('transform', 'scale(1)')

        path.enter().append('path')
            .attr('d', arc)
            .attr('class', fillClass)
            .each(function(d) { this._current = d })

        path.exit()
            .remove()
    }

    return ret
}
