var t = require('i18n').t
var AccountSelectorMixin = require('../mixins/account-selector')
var _ = require('lodash')
var moment = require('momentjs')
var Scope = require('../utils/scope')

module.exports = Em.Controller.extend(AccountSelectorMixin, {
    queryParams: {
        nordicApiSuccess: 'success',
        nordicApiSessionId: 'session_id',
        nordicApiError: 'error',
        fromRenewing: 'fromRenewing'
    },

    api: Em.inject.service(),

    auth: Em.inject.service(),

    needs: ['organization', 'bankAccount'],

    account: Em.computed.alias('controllers.bankAccount.model'),

    bankConnections: Ember.inject.service(),

    lastBankEntryDate: null,

    shouldShowConnectButton: false,

    fromRenewing: false, // from route

    shouldFinishSetupIfNeeded: false, // from route

    shouldDisplayRenewConnectionButton: false,

    hasBankIntegration: false,

    lastUpdate: null,

    expiredBankSession: null,

    sortPropertyOptions: function() {
        return [
            {
                value: 'entryDate',
                label: t('bank_sync.entry_date')
            },
            {
                value: 'sideAmount',
                label: t('bank_sync.amount')
            }
        ]
    }.property(),

    isBankSyncReadAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.BankSyncRead)
    }.property(),

    canConnectToBank: function() {
        return !this.get('hasBankIntegration') && this.get('isBankSyncReadAuthorized')
    }.property('hasBankIntegration'),

    shouldShowBankStatement: function() {
        return !this.get('isBankSyncReadAuthorized')
    }.property(),

    checkIfAccountHasBankIntegration: function() {
        var self = this
        var bankConnections = this.get('bankConnections')
        var currentAccountId = this.get('account.id')

        return bankConnections.getBankIntegrations(this.get('organization.id'))
            .then(function(bankIntegrations) {
                var matchingAccount = bankIntegrations.findBy('accountId', currentAccountId)

                if (!matchingAccount) {
                    self.set('hasBankIntegration', false)
                    self.set('shouldShowConnectButton', true)
                    self.set('lastUpdate', null)
                    return
                }

                var lastPullTimeFormatted = matchingAccount.lastPullTime ? moment(matchingAccount.lastPullTime).format('MMM DD YYYY, HH:mm') : null

                self.set('hasBankIntegration', true)
                self.set('shouldShowConnectButton', false)
                self.set('lastUpdate', lastPullTimeFormatted)
            })
    }.on('init').observes('account'),

    // after connection is set up, but not renewed, display the modal so user
    // can link system account to the bank account
    finishAccountSetup: function() {
        if (!this.get('nordicApiSuccess')) {
            return
        }

        var notification = this.container.lookup('util:notification')
        var wasSuccessful = this.get('nordicApiSuccess') === 'true'
        var sessionId = this.get('nordicApiSessionId')
        var shouldFinishSetupIfNeeded = this.get('shouldFinishSetupIfNeeded')
        var error = this.get('nordicApiError')
        var fromRenewing = this.get('fromRenewing')

        if (shouldFinishSetupIfNeeded && sessionId && wasSuccessful && !fromRenewing) {
            this.container.lookup('component:bank-connection-setup-nordic-api')
                .set('sessionId', sessionId)
                .set('account', this.get('account'))
                .set('isSuccessful', wasSuccessful)
                .set('onSubmit', this.renewConnectionStatuses.bind(this))
                .set('error', error)
                .show()
        } else if (error) {
            notification.warn(error)
        }
    }.observes('nordicApiSuccess', 'shouldFinishSetupIfNeeded'),

    // after user comes back from renewing, show bank setting modal
    afterRenewing: function() {
        var fromRenewing = this.get('fromRenewing')

        if (fromRenewing) {
            this.openBankSettingsModal()
        }
    }.observes('fromRenewing'),

    deleteEmptyBankConnections: function() {
        if (this.get('nordicApiSuccess') === 'true') { // from queryParams, so needs to be compared to string
            return
        }
        // cleans up bank connections not associated with any account
        this.get('bankConnections').deleteEmptyBankConnections()
    }.observes('account', 'nordicApiSuccess'),

    checkIfBankConnectionShouldBeRenewed: function() {
        var self = this
        var bankConnections = self.get('bankConnections')

        bankConnections.find(self.get('model.id'))
            .then(function(connection) {
                var isNordicApiConnection = connection.type === 'nordic_api'
                var session = connection.get('session')
                var hasConnectionError = !!connection.errorCode
                var hasSessionError = session && !!session.errorCode
                var isExpiredBankConnection = session &&
                    session.loginTokenExpiryTime &&
                    moment().isAfter(moment(session.loginTokenExpiryTime)) &&
                    isNordicApiConnection

                if (isExpiredBankConnection) {
                    self.set('expiredBankSession', session)
                }
                self.set('shouldDisplayRenewConnectionButton', isExpiredBankConnection || hasConnectionError || hasSessionError)
            })
    }.on('init').observes('model.id'),

    init: function() {
        var self = this
        var api = this.container.lookup('api:billy')
        var url = '/bankLineMatches?accountId=' + this.get('account.id')

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')
        if (bankSyncStartDate) {
            url += '&entryDatePeriod=from:' + bankSyncStartDate.format('YYYY-MM-DD')
        }

        api.get(url)
            .then(function(payload) {
                var lastBankEntry = _.chain(payload.bankLineMatches)
                    .sortBy('entryDate')
                    .last()
                    .get('entryDate')
                    .value()

                self.set('lastBankEntryDate', lastBankEntry)
            })
    },

    renewConnectionStatuses: function() {
        this.get('bankConnections').invalidate()
        this.checkIfBankConnectionShouldBeRenewed()
        this.checkIfAccountHasBankIntegration()
    },

    openBankSettingsModal: function() {
        this.container.lookup('component:bank-settings-modal')
            .set('accountId', this.get('account.id'))
            .set('onRemoveAccount', this.renewConnectionStatuses.bind(this))
            .show()
    },

    actions: {
        didClickImport: function() {
            this.transitionToRoute('bank_import')
        },

        didClickArchive: function() {
            this.transitionToRoute('bank_approved_lines')
        },

        openBankConnectionModal: function() {
            this.get('bankConnections').connectBank(this.get('account'))
        },

        didClickBankSettings: function() {
            this.openBankSettingsModal()
        },

        renewBankConnection: function() {
            var organizationId = this.get('organization.id')
            var bankId = this.get('expiredBankSession.id')

            this.get('bankConnections').renewNordicApi(organizationId, bankId)
        }
    }
})
