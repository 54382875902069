var t = require('i18n').t

var attr = DS.attr
var hasMany = DS.hasMany

module.exports = DS.Model.extend({
    createdTime: attr('moment-datetime'),
    updatedTime: attr('moment-datetime'),
    type: attr('string'),
    entryDate: attr('moment-date'),
    dueDate: attr('moment-date'),
    state: attr('string'),
    taxMode: attr('string', {
        defaultValue: 'excl'
    }),
    headerMessage: attr('string'),
    footerMessage: attr('string'),
    isSent: attr('boolean'),
    acceptState: attr('string'),
    netAmount: attr('number'),
    tax: attr('number'),
    grossAmount: attr('number'),
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    contact: attr('billy-data-contact', { apiKey: 'contactId' }),
    attContactPerson: attr('billy-data-contact-person', { apiKey: 'attContactPersonId' }),
    currency: attr('billy-data-currency', { apiKey: 'currencyCode' }),
    signatureUrl: attr('string'),
    templateId: attr('string'),
    lines: hasMany('quote-line', { async: true }),
    attachments: hasMany('v2-attachment', { async: true }),
    orderNo: attr('string'),

    localizedType: function() {
        switch (this.get('type')) {
        case 'estimate':
            return t('quote.type.estimate')
        case 'quote':
            return t('quote.type.quote')
        case 'orderConfirmation':
            return t('quote.type.order_confirmation')
        }
    }.property('type'),

    capitalizedType: function() {
        switch (this.get('type')) {
        case 'estimate':
            return t('quote.type.capitalized.estimate')
        case 'quote':
            return t('quote.type.capitalized.quote')
        case 'orderConfirmation':
            return t('quote.type.capitalized.order_confirmation')
        }
    }.property('type'),

    lineDescription: function() {
        var desc = this.get('lines').reduce(function(result, line) {
            var description = line.get('description')
            if (description.length > 0) {
                result.push(description)
            }
            return result
        }, []).join(', ')

        var maxLength = 100
        var ellipsis = '...'
        if (desc.length > maxLength) {
            desc = desc.substring(0, maxLength - ellipsis.length) + ellipsis
        }

        return desc
    }.property('lines.@each.{product.name,description}'),

    isSigned: function() {
        return this.get('type') === 'quote' && this.get('signatureUrl')
    }.property('type', 'signatureUrl'),

    isEditable: function() {
        return !this.get('isSigned')
    }.property('isSigned'),

    contactName: Em.computed.alias('contact.name'),
    description: Em.computed.alias('lineDescription')
})
