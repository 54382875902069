var Window = require('ember-window')

module.exports = Window.extend({
    layout: require('../templates/components/payment-method-mobilepay-setup'),

    applyModalMask: false,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        },

        closeEmber: function() {
            this.close()
        }
    }
})
