module.exports = Em.Controller.extend({
    needs: ['bankAccount', 'organization', 'user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    account: Em.computed.alias('controllers.bankAccount.model'),

    bankConnections: Em.inject.service(),

    customActions: {
        selectBankAccount: function(e) {
            var selectedBankAccount = e.detail

            if (selectedBankAccount) {
                this.replaceRoute('bank_sync_react', selectedBankAccount.id)
            }
        },

        import: function() {
            this.transitionToRoute('bank_import')
        },

        checkBankIntegration: function(e) {
            this.set('bankConnections.bankConnection', e.detail.connectedBankData)
            this.set('bankConnections.isLoadingBankConnection', false)
        }
    }
})
