var getUserType = require('../utils/get-user-type')

module.exports = Em.ObjectController.extend({
    needs: ['application'],

    sideMenu: Em.inject.service(),

    activeOrganization: Em.computed.alias('sideMenu.activeOrganization'),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    activeMembership: Em.computed.oneWay('userOrganizations.activeMembership'),

    userType: function() {
        return getUserType(this.get('activeMembership'))
    }.property('activeMembership')
})
