var paths = require('./annual-report/steps')
var revisedDesignRoutes = require('./annual-report/revisedDesignRoutes')
var settings = require('./annual-report/settings')
var questionToPackageMap = require('./annual-report/questionToPackageMap')
var checklistAdditionalQuestions = require('./annual-report/checklistAdditionalQuestions')

var allSteps = _.flatten([
    paths.billy.proprietorship,
    paths.billy.company
])

module.exports = {
    allSteps: allSteps,
    settings: settings,
    paths: paths,
    questionToPackageMap: questionToPackageMap,
    revisedDesignRoutes: revisedDesignRoutes,
    checklistAdditionalQuestions: checklistAdditionalQuestions
}
