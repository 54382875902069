var t = require('i18n').t
var numeral = require('numeral')
var parseDate = require('i18n-parse-date')
var parseNumber = require('../utils/parse-number')
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var Column = require('billy-webapp/ui/list/columns/column')

module.exports = ListController.extend({
    needs: ['bankImport'],

    ic: Em.computed.alias('controllers.bankImport'),

    init: function() {
        this._super()
        this.linesAndIndexesDidChange()
    },

    records: Em.computed.alias('ic.lines'),

    linesAndIndexesDidChange: function() {
        var selection = Em.A()
        var lines = this.get('ic.lines')
        this.get('ic.rowIndexes').forEach(function(index) {
            selection.push(lines.objectAt(index))
        })
        this.set('selection', selection)
    }.observes('ic.lines', 'ic.rowIndexes'),

    hasHeader: false,

    isSelectable: true,

    didAddSelected: function(line) {
        this.get('ic.rowIndexes').addObject(this.get('ic.lines').indexOf(line))
    },

    didRemoveSelected: function(line) {
        this.get('ic.rowIndexes').removeObject(this.get('ic.lines').indexOf(line))
    },

    columnCount: function() {
        return this.get('ic.lines').reduce(function(columnCount, line) {
            return Math.max(columnCount, line.length)
        }, 0)
    }.property('ic.lines'),

    allowOverflow: true,

    selectHeaderCtStyle: function() {
        return 'width:' + this.get('width') + 'px;'
    }.property('width'),

    itemControllerClass: ListItemController.extend({
        date: function() {
            var date = this.get('' + this.get('listController.ic.dateIndex'))
            var parsedDate = parseDate(date)
            if (parsedDate) {
                this.set('dateClass', 'success')
                return parsedDate.format('LL')
            } else {
                this.set('dateClass', 'error')
                return t('bank_import.invalid_date', { date: date })
            }
        }.property('listController.ic.dateIndex'),

        descriptionClass: 'success',

        amount: function() {
            var amount = this.get('' + this.get('listController.ic.amountIndex'))
            var parsedAmount = parseNumber(amount)
            if (!isNaN(parsedAmount)) {
                this.set('amountClass', 'success')
                return numeral(parsedAmount).format('0,0.00')
            } else {
                this.set('amountClass', 'error')
                return t('bank_import.invalid_amount', { amount: amount })
            }
        }.property('listController.ic.amountIndex')
    }),

    columns: function() {
        var columns = []
        var columnCount = this.get('columnCount')
        var ic = this.get('ic')
        var dateIndex = ic.get('dateIndex')
        var descriptionIndex = ic.get('descriptionIndex')
        var amountIndex = ic.get('amountIndex')
        var columnWidth = 150
        var i
        for (i = 0; i < columnCount; i++) {
            if (i === dateIndex) {
                columns.push(Column.create({
                    name: 'date',
                    width: columnWidth,
                    cellCssClassPath: 'dateClass'
                }))
            } else if (i === descriptionIndex) {
                columns.push(Column.create({
                    name: i,
                    width: columnWidth,
                    cellCssClassPath: 'descriptionClass'
                }))
            } else if (i === amountIndex) {
                columns.push(Column.create({
                    align: 'right',
                    name: 'amount',
                    width: columnWidth,
                    cellCssClassPath: 'amountClass'
                }))
            } else {
                columns.push(Column.create({
                    name: i,
                    width: columnWidth
                }))
            }
        }
        return columns
    }.property('columnCount', 'ic.dateIndex', 'ic.amountIndex'),

    columnIndexes: function() {
        var columnIndexes = []
        var columnCount = this.get('columnCount')
        var i
        for (i = 0; i < columnCount; i++) {
            columnIndexes.push(i)
        }
        return columnIndexes
    }.property('columnCount'),

    instructions: function() {
        var instructions = []
        var ic = this.get('ic')
        if (Em.isEmpty(ic.get('dateIndex'))) {
            instructions.push(t('bank_import.select_date_column'))
        }
        if (Em.isEmpty(ic.get('descriptionIndex'))) {
            instructions.push(t('bank_import.select_description_column'))
        }
        if (Em.isEmpty(ic.get('amountIndex'))) {
            instructions.push(t('bank_import.select_amount_column'))
        }
        if (ic.get('rowIndexes.length') === 0) {
            instructions.push(t('bank_import.select_at_least_one_row'))
        }
        return instructions
    }.property('ic.dateIndex', 'ic.descriptionIndex', 'ic.amountIndex', 'ic.rowIndexes.length')
})
