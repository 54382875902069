var t = require('i18n').t
var BigNumber = require('bignumber.js')
/**
 * Implementations must also implement `mixins/due-model`.
 *
 * @type {*}
 */
module.exports = Em.Mixin.create({
    _stateInfo: function() {
        switch (this.get('state')) {
        case 'draft':
            return ['icons/dot', 'blue', t('state.draft')]
        case 'approved':
            if (this.get('isPaid')) {
                return ['icons/dot', 'green', t('state.paid')]
            } else {
                var diff = new BigNumber(this.get('amount') || 0)
                    .plus(this.get('tax') || 0)
                    .minus(this.get('balance') || 0)
                var isPartiallyPaid = diff.greaterThan(0)
                var isOverdue = this.get('isOverdue')
                // Defines the icon Type
                var iconType = isPartiallyPaid ? 'icons/split-dot' : 'icons/dot'
                // Defines the icon Color
                var iconColor = isOverdue ? 'red' : 'yellow'
                if (isPartiallyPaid) {
                    iconColor = 'split-dot-green-' + iconColor
                }
                // Sets the Icon Description
                var iconDescription = 'state.unpaid'
                if (isPartiallyPaid) {
                    iconDescription = 'state.partiallypaid'
                } else if (isOverdue) {
                    iconDescription = 'state.overdue'
                }

                return [iconType, iconColor, t(iconDescription, { due: this.get('dueDateFromNow') })]
            }
        case 'voided':
            return ['icons/dot', 'blue-gray-light', t('state.voided')]
        default:
            return [null, '', 'Unknown']
        }
    }.property('state', 'isPaid', '_dueStats'),

    stateIcon: function() {
        var v = this.get('_stateInfo')
        return v[0] ? v[0] + '.svg-' + v[1] + '@' + v[2] : null
    }.property('_stateInfo'),

    stateColor: function() {
        return this.get('_stateInfo')[1]
    }.property('_stateInfo'),

    stateText: function() {
        return this.get('_stateInfo')[2]
    }.property('_stateInfo')
})
