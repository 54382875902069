module.exports = Em.Route.extend({
    actions: {
        willTransition: function(transition) {
            var allowTransition = true
            if (require('ember-window').hasWindows()) {
                allowTransition = false
            }
            if (allowTransition) {
                return true
            } else {
                transition.abort()
            }
        }
    }
})
