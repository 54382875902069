var moment = require('momentjs')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    queryParams: {
        auditTrailVisible: 'audit_trail'
    },

    api: Ember.inject.service(),

    needs: ['user'],

    userController: function() {
        return this.container.lookup('controller:user')
    }.property(),

    user: Em.computed.oneWay('userController.model'),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    auditTrailVisible: false,

    auditTrail: null,

    auditTrailIsLoaded: false,

    isConnectedToSkat: false,

    skatConnectionChecked: null,

    approvedInSkat: false,

    reported: false,

    connectionStatusFetched: false,

    loadAuditTrail: function() {
        if (!this.get('auditTrailVisible')) {
            return
        }

        var self = this

        var salesTaxReturn = this.get('model')
        var hasVat = salesTaxReturn.get('organization.hasVat')
        var reportName = hasVat ? 'accountAuditTrail' : 'taxRateAuditTrail'
        var url = '/salesTaxReturns/' + salesTaxReturn.get('id') + '/' + reportName

        this.set('auditTrail', null)
            .set('auditTrailIsLoaded', false)

        var promise = this.auditTrailPromise = this.container.lookup('api:billy').get(url)

        promise
            .then(function(payload) {
                if (promise === self.auditTrailPromise) {
                    self.set('auditTrail', self.formatAuditTrailHtml(payload.report.html))
                        .set('auditTrailIsLoaded', true)
                }
            }, function(err) {
                // eslint-disable-next-line no-console
                console.error(err)
                if (promise === self.auditTrailPromise) {
                    self.set('auditTrail', t('document_load_error'))
                        .set('auditTrailIsLoaded', true)
                }
            })
    },

    formatAuditTrailHtml: function(html) {
        var doc = $('<div>' + html + '</div>')
        doc.find('td[data-transaction-id], td[data-originator-type]').wrapInner(function() {
            return '<a href="#"></a>'
        })
        var output = doc.html()
        doc.remove()
        return output
    },

    periodCanBeSettled: function() {
        return (!this.get('isSettled') && this.get('endDate').isBefore(moment(), 'day') && !this.get('isConnectedToSkat'))
    }.property('isSettled', 'endDate'),

    periodCanBeSentToSkat: function() {
        return (!this.get('skatTransactionId') && this.get('endDate').isBefore(moment(), 'day') && this.get('isConnectedToSkat'))
    }.property('isSettled', 'endDate'),

    periodCanBeReSentToSkat: function() {
        return (this.get('skatTransactionId') && this.get('endDate').isBefore(moment(), 'day') && this.get('isConnectedToSkat'))
    }.property('isSettled', 'endDate'),

    periodCanBeApproved: function() {
        return (this.get('skatTransactionId') && this.get('isConnectedToSkat') && !this.get('approvedInSkat'))
    }.property('isSettled', 'skatTransactionId'),

    periodCanBePaid: function() {
        if (this.get('isConnectedToSkat')) {
            return (this.get('isSettled') && this.get('approvedInSkat') && !this.get('isPaid'))
        } else {
            return (this.get('isSettled') && !this.get('isPaid'))
        }
    }.property('isSettled', 'isPaid'),

    periodCanBeChanged: Em.computed.not('isSettled'),

    canBeUnsettled: function() {
        return this.get('isSettled') && !this.get('isPaid')
    }.property('isSettled', 'isPaid'),

    paymentCanBeVoided: function() {
        return this.get('isSettled') && this.get('isPaid')
    }.property('isSettled', 'isPaid'),

    timelineUrl: function() {
        var salesTaxReturn = this.get('model')
        return '/organizations/' + salesTaxReturn.get('organization.id') + '/salesTaxReturns/' + salesTaxReturn.get('id') + '/timeline'
    }.property('model{organization,id}'),

    displayErrorNotification: function(message) {
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, message)
    },

    displayErrorCodeNotification: function(errorCode) {
        var errorMessage
        switch (errorCode) {
        case '4802':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        case '4804':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        default:
            errorMessage = t('sales_tax_returns.request_error.code_default')
            break
        }
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, errorMessage)
    },

    actions: {
        sendToSkat: function() {
            var self = this
            var salesTaxReturn = self.get('model')
            var modal = self.container.lookup('component:send-tax-modal')
            modal.set('period', salesTaxReturn.get('periodText'))
            modal.set('organizationId', salesTaxReturn.get('organization.id'))
            modal.set('registrationNo', salesTaxReturn.get('organization.registrationNo'))
            modal.set('salesTaxReturnId', salesTaxReturn.get('id'))
            modal.set('onTaxApproved', this.set('approvedInSkat', true))

            this.get('api').post('/integrations/skat/organizations/' + salesTaxReturn.get('organization.id') + '/vat-returns', {
                payload: {
                    salesTaxReturnId: salesTaxReturn.get('id'),
                    registrationNumber: salesTaxReturn.get('organization.registrationNo')
                }
            })
                .then(function(res) {
                    window.open(res.deeplink)
                    modal.show()
                })
                .catch(function(e) {
                    modal.destroy()

                    if (e.payload.error) {
                        self.displayErrorCodeNotification(e.payload.error.code)
                    } else {
                        self.displayErrorNotification(e.payload.message || 'Unknown error')
                    }
                })
        },

        approveTax: function() {
            var self = this
            var salesTaxReturn = self.get('model')
            var modal = self.container.lookup('component:approve-tax-modal')
            modal.set('organizationId', salesTaxReturn.get('organization.id'))
            modal.set('registrationNo', salesTaxReturn.get('organization.registrationNo'))
            modal.set('onTaxApproved', this.set('approvedInSkat', true))
            modal.show()
        },

        showAuditTrail: function() {
            this.set('auditTrailVisible', true)
            this.loadAuditTrail()
        },

        hideAuditTrail: function() {
            this.set('auditTrailVisible', false)
        },

        settle: function() {
            var self = this
            var organization = this.get('organization')
            var confirm2 = t('sales_tax_return.index.settle_confirm2')
            if (organization.get('country.id') === 'DK') {
                confirm2 = t('sales_tax_return.index.settle_confirm2_with_link', { authority: 'Skat', linkStart: '<a href="https://indberet.virk.dk/myndigheder/stat/SKST/Indberet_moms" target="_blank">', linkEnd: '</a>' })
            }
            var message =
                '<p>' + t('sales_tax_return.index.settle_confirm1', { salesTax: organization.get('salesTaxTerm') }) + '</p>' +
                '<p>' + confirm2 + '</p>' +
                '<p>' + t('sales_tax_return.index.settle_confirm3') + '</p>' +
                '<p>' + t('sales_tax_return.index.settle_confirm4') + '</p>'
            this.container.lookup('util:dialog').confirm(t('sales_tax_return.index.settle_button'), message.htmlSafe(), t('sales_tax_return.index.settle_confirm_button')).then(function() {
                self.get('model')
                    .save({ properties: { isSettled: true } })
                    .on('success', function() {
                        self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_SETTLE, t('sales_tax_return.index.settled_notification'))
                    })
            })
        },

        pay: function() {
            this.container.lookup('component:sales-tax-pay-window').showForSalesTaxReturn(this.get('model'))
        },

        changePeriod: function() {
            var self = this
            var w = this.container.lookup('component:sales-tax-return-period-change-window')
                .set('salesTaxReturn', this.get('model'))
            w.show()
            w.on('didSubmit', function() {
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_PERIOD_CHANGE, t('sales_tax_return.period_was_changed'))
                self.container.lookup('controller:sales-tax-returns-index').clearAndLoadRecords()
                self.replaceRoute('sales_tax_returns')
            })
        },

        unsettle: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(t('sales_tax_return.unsettle'), t('sales_tax_return.unsettle_confirm_text'), t('yes'), t('no')).then(function() {
                    return self.get('model').save({ properties: { isSettled: false } })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_UNSETTLE, t('sales_tax_return.unsettle_success'))
                })
                .catch(function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_UNSETTLE, e.message)
                })
        },

        voidPayment: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(t('sales_tax_return.void_payment'), t('sales_tax_return.void_payment_confirm_text'), t('yes'), t('no')).then(function() {
                    return Billy.SalesTaxPayment.find({
                        returnId: self.get('model.id'),
                        isVoided: false
                    }).promise
                })
                .then(function(payments) {
                    if (payments.get('length') !== 1) {
                        throw new Error('No sales tax payment was found for this return.')
                    }
                    return payments.objectAt(0).save({ properties: { isVoided: true } })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.SALES_TAX_VOID_PAYMENT, t('sales_tax_return.void_payment_success'))
                })
                .catch(function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_VOID_PAYMENT, e.message)
                })
        },

        download: function() {
            this.container.lookup('component:sales-tax-return-download-window')
                .set('salesTaxReturn', this.get('model'))
                .show()
        }
    },

    customActions: {
        updateSkatConnection: function(e) {
            this.set('isConnectedToSkat', e.detail.connected)
            this.set('connectionStatusFetched', true)
        }
    }
})
