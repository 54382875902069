module.exports = Em.Component.extend({
    classNames: ['long-term-debt-list'],

    annualReport: Em.inject.service(),

    year: null,

    actions: {
        saveValue: function(value, context) {
            if (context.error) {
                return
            }

            var property = {
                name: context.get('name'),
                value: value
            }

            this.get('annualReport').putAnswer(this.get('year'), [property])
        }
    }
})
