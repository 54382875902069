module.exports = Em.Component.extend({
    classNames: ['confirm-summary-subscription'],

    organization: null,

    subscriptionPlan: null,

    isSubscriptionPeriodYearly: null,

    organizationSubscription: Em.inject.service(),

    isBasicSubscription: Em.computed.equal('subscriptionName', 'Basic'),

    subscriptionName: function() {
        var subscriptionType = this.get('subscriptionPlan.id')

        if (subscriptionType === 'basic') {
            return 'Basic'
        } else if (subscriptionType === 'premium') {
            return 'Premium'
        }
    }.property('subscriptionPlan.id'),

    formattedSubscriptionExpires: function() {
        if (this.get('isSubscriptionPeriodYearly')) {
            return moment().add(1, 'years').format('Do MMMM YYYY')
        }

        return moment().add(1, 'months').format('Do MMMM YYYY')
    }.property('isSubscriptionPeriodYearly'),

    formattedSubscriptionDowngradeDate: function() {
        return this.get('organization.subscriptionExpires').format('Do MMMM YYYY')
    }.property('organization.subscriptionExpires'),

    subscriptionPrice: function() {
        var plan = this.get('subscriptionPlan')
        var couponDiscountPercent = this.get('organization.couponDiscountPercent')
        var price = this.get('isSubscriptionPeriodYearly') ? plan.yearlyPrice : plan.monthlyPrice

        if (!couponDiscountPercent) {
            this.sendAction('subscriptionPriceChanged', price)
        }

        return price
    }.property('subscriptionPlan', 'isSubscriptionPeriodYearly', 'organization.couponDiscountPercent'),

    subscriptionPriceWithCoupon: function() {
        var plan = this.get('subscriptionPlan')
        var couponDiscountPercent = this.get('organization.couponDiscountPercent')

        if (!couponDiscountPercent) {
            return null
        }

        var couponPeriodInMonths = {
            yearly: 12,
            monthly: 1
        }[this.get('organization.couponPeriod')]
        var price = plan.monthlyPrice * couponPeriodInMonths
        price = price * (100 - couponDiscountPercent) / 100

        this.sendAction('subscriptionPriceChanged', price)

        return price
    }.property('subscriptionPlan', 'isSubscriptionPeriodYearly', 'organization.couponDiscountPercent'),

    formattedSubscriptionPriceWithCoupon: function() {
        return Billy.money(this.get('subscriptionPriceWithCoupon'), this.get('organization.subscriptionCurrency'), '0,0')
    }.property('subscriptionPriceWithCoupon'),

    formattedSubscriptionPrice: function() {
        return Billy.money(this.get('subscriptionPrice'), this.get('organization.subscriptionCurrency'), '0,0')
    }.property('subscriptionPrice'),

    yearlySavings: function() {
        var savings = this.get('organizationSubscription').getYearlySavings()

        return Billy.money(savings, this.get('organization.subscriptionCurrency'), '0,0')
    }.property('organizationSubscription', 'organization.subscriptionCurrency'),

    actions: {
        toggleSubscriptionPeriod: function() {
            var currentValue = this.get('isSubscriptionPeriodYearly')

            this.set('isSubscriptionPeriodYearly', !currentValue)
        },

        didClickChangeSubscription: function() {
            this.sendAction('didClickChangeSubscription')
        }
    }
})
