var t = require('i18n').t
var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    isReportingNonFillable: function() {
        return !this.get('model.commitment.isFillable')
    }.property('model.commitment.isFillable'),

    todoListIsInvalid: function() {
        return this.get('todos').filter(function(item) {
            return !item.isChecked
        }).length > 0 || this.get('validationErrors').length > 0
    }.property('todos.@each.isChecked', 'validationErrors'),

    approveButtonDisabled: function() {
        return this.get('todoListIsInvalid') || this.get('isReportingNonFillable')
    }.property('todoListIsInvalid', 'isReportingNonFillable'),

    fiscalYear: function() {
        var annualReportService = this.get('annualReport')
        var organization = this.get('organization')

        return annualReportService.getFiscalYearForYear(
            this.get('model.commitment.year'),
            organization.get('firstFiscalYearStart'),
            organization.get('firstFiscalYearEnd')
        )
    }.property('organization.{firstFiscalYearStart,firstFiscalYearEnd}'),

    fiscalYearEndDate: function() {
        var fiscalYearEndDate = this.get('fiscalYear.endDate')

        return fiscalYearEndDate.clone().format('LL')
    }.property('fiscalYear.endDate'),

    reportingAvailableFromDate: function() {
        var fiscalYearEndDate = this.get('fiscalYear.endDate')

        return fiscalYearEndDate.clone().add(1, 'day').format('LL')
    }.property('fiscalYear.endDate'),

    validationErrors: function() {
        var validationErrors = this.get('model.commitment.validationErrors')
        var link

        if (!validationErrors || !validationErrors.length) {
            return []
        }

        return validationErrors
            .filter(function(error) {
                return [
                    'E_ACCOUNT_NOT_FOUND',
                    'E_ACCOUNT_GROUP_NEGATIVE_SUM',
                    'E_INCORRECT_ACCOUNT_GROUP_BALANCE',
                    'E_INCORRECT_ACCOUNT_BALANCE'
                ].contains(error.code)
            })
            .map(function(error) {
                var errorType = error.code.replace('E_', '').toLowerCase()
                var subErrorTypeKey = ''

                switch (error.code) {
                case 'E_ACCOUNT_GROUP_NEGATIVE_SUM':
                    link = t('annual_report.complete_bookkeeping.error.account_group_negative_sum.help_link')
                    break
                case 'E_INCORRECT_ACCOUNT_GROUP_BALANCE':
                    link = t('annual_report.complete_bookkeeping.error.incorrect_account_group_balance.help_link')
                    if (error.data.number === '2600') {
                        subErrorTypeKey = '.yearly_result'
                    }
                    break
                case 'E_INCORRECT_ACCOUNT_BALANCE':
                    var min = error.data.min
                    var max = error.data.max

                    if (min === 0 && max === 0) {
                        subErrorTypeKey = '.not_zero'
                        link = t('annual_report.complete_bookkeeping.error.incorrect_account_balance.not_zero.help_link')
                    } else if (min && Em.isEmpty(max)) {
                        subErrorTypeKey = '.minimum_not_reached'
                        link = t('annual_report.complete_bookkeeping.error.incorrect_account_balance.minimum_not_reached.help_link')
                    }
                    break
                }

                return {
                    link: link,
                    text: t('annual_report.complete_bookkeeping.error.' + errorType + subErrorTypeKey, { number: error.data.number, name: error.data.name, minimum: error.data.min, maximum: error.data.max })
                }
            })
    }.property('model.commitment.validationErrors'),

    actions: {
        didClickApprove: function() {
            var self = this
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { complete_bookkeeping: true })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
