var annualReportPurchasing = require('../mixins/annual-report-purchasing')
var annualReportNavigation = require('../mixins/annual-report-navigation')
var ADDONS = require('../utils/addons')

module.exports = Em.Controller.extend(annualReportNavigation, annualReportPurchasing, {
    needs: ['user'],

    cvr: Em.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    commitmentYear: Em.computed.alias('model.commitment.year'),

    queryParams: {
        reason: 'reason'
    },

    header: '',

    description: '',

    note: '',

    reason: '',

    source: 'start',

    init: function() {
        this.set('accountingPackagePrice', ADDONS.ACCOUNTING_PACKAGE.unitPrice)
    },

    isReasonStaggeredYear: function() {
        return this.get('reason') === 'staggered-year'
    }.property(),

    formattedAccountingPackagePrice: function() {
        return Billy.money(this.get('accountingPackagePrice'), 'kr.', '0,0.[00]')
    }.property('accountingPackagePrice'),

    isInvalidRegistrationNo: function() {
        return this.get('annualReport').isInvalidRegistrationNo()
    }.property('organization.registrationNo')
})
