module.exports = Em.Component.extend({
    classNames: ['subscription-summary'],

    organization: null,

    organizationSubscription: Em.inject.service(),

    constraints: Ember.inject.service(),

    isBasicSubscription: Em.computed.equal('subscriptionName', 'basic'),

    isSubscriptionPeriodYearly: Em.computed.equal('organization.subscriptionPeriod', 'yearly'),

    subscriptionName: function() {
        return this.get('organization.subscriptionPlan')
    }.property('organization.subscriptionPlan'),

    subscriptionPrice: function() {
        var subscriptionName = this.get('subscriptionName')
        var isSubscriptionPeriodYearly = this.get('isSubscriptionPeriodYearly')
        var price = this.get('organizationSubscription').getSubscriptionPriceMonthly(subscriptionName, isSubscriptionPeriodYearly)

        return Billy.moneyRecurring(price, this.get('organization.subscriptionCurrency'), '0,0', 'monthly')
    }.property('organizationSubscription', 'subscriptionName', 'isSubscriptionPeriodYearly', 'organization.subscriptionCurrency'),

    yearlyPrice: function() {
        var price = this.get('organizationSubscription').getSubscriptionPriceYearly()

        return Billy.money(price, this.get('organization.subscriptionCurrency'), '0,0')
    }.property('organizationSubscription', 'organization.subscriptionCurrency'),

    yearlySavings: function() {
        var savings = this.get('organizationSubscription').getYearlySavings()

        return Billy.money(savings, this.get('organization.subscriptionCurrency'), '0,0')
    }.property('organizationSubscription', 'organization.subscriptionCurrency'),

    formattedSubscriptionExpires: function() {
        var expires = this.get('organization.subscriptionExpires')

        return expires ? expires.format('Do MMMM YYYY') : ''
    }.property('organization.subscriptionExpires'),

    actions: {
        toggleSubscriptionPeriod: function() {
            this.get('organizationSubscription').changePeriod(this.get('isSubscriptionPeriodYearly') ? 'monthly' : 'yearly')
        },

        didClickUpdateSubscription: function() {
            this.get('constraints').showUpgradeOverlay('', 'subscription_summary')
        }
    }
})
