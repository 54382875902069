var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    organization: null,

    app: null,

    initInstalled: function() {
        var installSetting = this.get('app.installSetting')

        if (installSetting) {
            this.set('installed', !!this.get('organization.settings.' + installSetting))
        }
    }.on('init').observes('organization', 'app.installSetting'),

    actions: {
        install: function() {
            var installSetting = this.get('app.installSetting')

            if (installSetting) {
                this.set('organization.settings.' + installSetting, '1')
                this.set('installed', true)
                this.container.lookup('util:notification').success(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, this.get('app.installedMessage'))
            }
        },

        uninstall: function() {
            var self = this
            var installSetting = this.get('app.installSetting')

            this.container.lookup('util:dialog').confirmWarning(t('app_store.uninstall_confirm_title'), t('app_store.uninstall_confirm_text'), t('app_store.uninstall_confirm_yes'))
                .then(function() {
                    if (installSetting) {
                        var organization = self.get('organization')
                        var didUninstall = self.get('app.didUninstall')

                        organization.set('settings.' + installSetting, '')
                        self.set('installed', false)

                        if (didUninstall) {
                            didUninstall(organization)
                        }

                        if (self.get('app.uninstalledMessage')) {
                            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.APP_STORE_APP_UNINSTALL, self.get('app.uninstalledMessage'))
                        }
                    }
                })
        }
    }
})
