var t = require('i18n').t
var Window = require('ember-window')

module.exports = Window.extend({
    segment: Em.inject.service(),

    layout: Window.proto().layout,

    template: require('../templates/components/organization-reset-window'),

    organization: null,

    verification: '',
    verificationError: null,

    record: null,

    actions: {
        save: function() {
            var record = this.get('record')
            // Check for empty password field and verification text
            var hasErrors = false
            var errors = {}
            if (!record.get('password')) {
                hasErrors = true
                errors.password = t('required_field')
            }
            if (!this.get('verification')) {
                hasErrors = true
                errors.verification = t('required_field')
            } else if (this.get('verification').toLowerCase() !== t('organization.reset.verification_text')) {
                hasErrors = true
                errors.verification = t('organization.reset.incorrect_verification')
            }
            if (hasErrors) {
                if (errors.password) {
                    record.set('errors', errors)
                    record.trigger('didValidate')
                }
                if (errors.verification) {
                    this.set('verificationError', errors.verification)
                }
                return
            }
            var self = this
            var organization = this.get('organization')
            record.save('/organizations/' + organization.get('id') + '/reset', {
                root: 'record'
            })
                .success(function(payload) {
                    self.close()
                    BD.store.sideload(payload)
                    self.get('segment').track('Account Data Deleted (FE)')
                    self.container.lookup('router:main').transitionTo('organization.locked', organization)
                })
        },
        cancel: function() {
            this.close()
        }
    }
})
