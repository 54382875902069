var t = require('i18n').t
var Iso8601RepeatingInterval = require('../utils/iso-8601-repeating-interval')

var attr = DS.attr
var hasMany = DS.hasMany

module.exports = DS.Model.extend({
    createdTime: attr('moment-datetime'),
    updatedTime: attr('moment-datetime'),
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    contact: attr('billy-data-contact', { apiKey: 'contactId' }),
    attContactPerson: attr('billy-data-contact-person', { apiKey: 'attContactPersonId' }),
    recurringInterval: attr('string'),
    recurringSpawnMode: attr('string'),
    emailSubject: attr('string'),
    emailMessage: attr('string'),
    paymentTermsMode: attr('string'),
    paymentTermsDays: attr('number'),
    taxMode: attr('string', {
        defaultValue: 'excl'
    }),
    netAmount: attr('number'),
    tax: attr('number'),
    grossAmount: attr('number'),
    headerMessage: attr('string'),
    footerMessage: attr('string'),
    templateId: attr('string'),
    currency: attr('billy-data-currency', { apiKey: 'currencyCode' }),
    lines: hasMany('recurring-invoice-line', { async: true }),
    paymentMethods: attr(null, { defaultValue: function() { return [] } }),
    orderNo: attr('string'),
    recipientContactPersonIds: attr(),
    nextSpawnTimestamp: attr('moment-datetime'),
    spawnErrorCode: attr('string'),
    sendErrorCode: attr('string'),

    lineDescription: function() {
        var desc = this.get('lines').reduce(function(result, line) {
            var productName = line.get('product.name') || ''
            var description = line.get('description') || ''
            var d = productName
            if (productName && description) {
                d += ': '
            }
            d += description
            result.push(d)
            return result
        }, []).join(', ')

        var maxLength = 100
        var ellipsis = '...'
        if (desc.length > maxLength) {
            desc = desc.substring(0, maxLength - ellipsis.length) + ellipsis
        }

        return desc
    }.property('lines.@each.{product.name,description}'),

    parsedRecurringInterval: function() {
        return Iso8601RepeatingInterval.parse(this.get('recurringInterval'))
    }.property('recurringInterval'),

    nextInvoiceDate: Em.computed.alias('nextSpawnTimestamp'),

    lastInvoiceDate: Em.computed.alias('parsedRecurringInterval.end'),

    repetitions: Em.computed.alias('parsedRecurringInterval.repetitions'),

    repeatHuman: function() {
        var i = this.get('parsedRecurringInterval')
        if (!i.isValid()) {
            return null
        }
        return t('recurring_invoice.duration_human.' + i.durationUnit.toLowerCase(), { count: i.durationCount })
    }.property('parsedRecurringInterval'),

    recurringSpawnModeHuman: function() {
        return t('recurring_invoice.spawn_mode.' + this.get('recurringSpawnMode') + '.short')
    }.property('recurringSpawnMode'),

    recurringSpawnModeHumanLong: function() {
        return t('recurring_invoice.spawn_mode.' + this.get('recurringSpawnMode') + '.long')
    }.property('recurringSpawnMode'),

    spawnState: function() {
        if (!this.get('nextSpawnTimestamp')) return 'inactive'

        if (this.get('spawnErrorCode') || this.get('sendErrorCode')) return 'failing'

        return 'active'
    }.property('nextSpawnTimestamp', 'spawnErrorCode', 'sendErrorCode'),

    spawnErrorMessage: function() {
        if (!this.get('spawnErrorCode')) return null

        switch (this.get('spawnErrorCode')) {
        case 'E_INVALID_INVOICE_NO':
            return t('recurring_invoice.error.invalid_invoice_no')
        case 'E_INVALID_CONTACT':
            return t('recurring_invoice.error.invalid_contact')
        case 'E_INVALID_CONTACT_PERSON':
            return t('recurring_invoice.error.invalid_contact_person')
        case 'E_INVALID_PRODUCT':
            return t('recurring_invoice.error.invalid_product')
        case 'E_INVALID_TEMPLATE':
            return t('recurring_invoice.error.invalid_template')
        default:
            return t('recurring_invoice.error.unknown')
        }
    }.property('spawnErrorCode'),

    sendErrorMessage: function() {
        if (!this.get('sendErrorCode')) return null

        switch (this.get('sendErrorCode')) {
        case 'E_INVALID_CONTACT':
            return t('recurring_invoice.error.invalid_contact')
        case 'E_INVALID_CONTACT_PERSON':
            return t('recurring_invoice.error.invalid_contact_person')
        case 'E_EMAILS_LIMIT':
            return t('recurring_invoice.error.email_limit')
        default:
            return t('recurring_invoice.error.unknown')
        }
    }.property('sendErrorCode')
})
