var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.BankStatementImport],

    model: function() {
        return this.modelFor('bank_account')
    }
})
