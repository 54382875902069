module.exports = Em.Component.extend({
    classNames: ['button-filters'],

    actions: {
        toggleFilter: function(filter) {
            filter.toggleProperty('show')
            this.sendAction('filtersChanged', filter)
        }
    }
})
