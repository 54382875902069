module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/bank-line',

    classNames: ['bank-sync-v2-bank-line'],

    selectMode: false,

    line: null,

    canUngroup: Em.computed('index', function() {
        return this.get('index') !== 0
    }),

    formattedAmount: function() {
        return (this.get('line.side.value') === 'debit' ? '+' : '-') + Billy.money(this.get('line.amount'))
    }.property('line.side.value', 'line.amount'),

    notifySelection: function() {
        this.sendAction('toggledLineSelection', this.get('line'))
    }.observes('line.selected'),

    actions: {}
})
