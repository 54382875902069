var t = require('i18n').t
var ValueResolver = require('../utils/value-resolver')

module.exports = require('./dashboard-widget').extend({
    needs: ['dashboard'],

    view: 'dashboard-widget-easy-numbers',

    useBarChart: function() {
        // Only Danish users see the bar chart (for legacy reasons). One day we'll hopefully have a customizable dashboard, where users can add the kind they want
        return this.get('organization.country.id') === 'DK'
    }.property('organization.country.id'),

    data: function() {
        var self = this
        return ValueResolver.create({
            invalidateOn: 'accounting-changed',
            resolver: function() {
                return new Em.RSVP.Promise(function(resolve, reject) {
                    var organizationId = self.get('organization.id')
                    var period = self.get('period.value')
                    var compareWith = self.get('compareWith')
                    var api = self.container.lookup('api:billy')
                    api.get('/dashboard/easyNumbers?organizationId=' + organizationId + (period ? '&period=' + period : '') + (compareWith ? '&compareWith=' + compareWith : ''), {
                        success: function(payload) {
                            var d = payload.easyNumbers
                            self.set('hasData', d.hasData)
                            if (!d.hasData) {
                                d.period = {
                                    revenue: 345820,
                                    unpaidRevenue: 54800,
                                    expense: 251091,
                                    unpaidExpense: 21874
                                }
                                d.previous = {
                                    revenue: 242074,
                                    unpaidRevenue: 38414,
                                    expense: 208405,
                                    unpaidExpense: 20840
                                }
                            }
                            var result = d.period.revenue - d.period.expense
                            var previousResult = (d.previous && (d.previous.revenue - d.previous.expense)) || 0
                            resolve({
                                d: d,
                                barChartData: [
                                    {
                                        id: 'revenue',
                                        sign: '+',
                                        label: t('dashboard_widget.easy_numbers.revenue'),
                                        value: d.period.revenue,
                                        partialValue: d.period.unpaidRevenue,
                                        color: 'green'
                                    },
                                    {
                                        id: 'expense',
                                        sign: '–',
                                        label: t('dashboard_widget.easy_numbers.expense'),
                                        value: d.period.expense,
                                        partialValue: d.period.unpaidExpense,
                                        color: 'red'
                                    },
                                    {
                                        id: 'result',
                                        sign: '=',
                                        label: result > 0 ? t('dashboard_widget.easy_numbers.profit') : t('dashboard_widget.easy_numbers.loss'),
                                        value: Math.abs(result),
                                        color: result > 0 ? 'green' : 'red'
                                    }
                                ],
                                textData: [
                                    {
                                        label: t('dashboard_widget.easy_numbers.revenue'),
                                        value: d.period.revenue,
                                        valueClass: null,
                                        previousValue: (d.previous && d.previous.revenue) || 0,
                                        previouValueClass: null
                                    },
                                    {
                                        label: t('dashboard_widget.easy_numbers.expense'),
                                        value: d.period.expense,
                                        valueClass: null,
                                        previousValue: (d.previous && d.previous.expense) || 0,
                                        previouValueClass: null
                                    },
                                    {
                                        label: t('dashboard_widget.easy_numbers.profit_loss'),
                                        value: result,
                                        valueClass: result > 0 ? 'green-text' : (result < 0 ? 'red-text' : null),
                                        previousValue: previousResult,
                                        previouValueClass: previousResult > 0 ? 'green-text' : (previousResult < 0 ? 'red-text' : null)
                                    }
                                ]
                            })
                        },
                        error: reject
                    })
                })
            }
        })
    }.property(),

    reloadData: function() {
        var data = this.get('data')
        Em.run.once(data, data.reload)
    }.observes('period', 'compareWith'),

    willDestroy: function() {
        this.get('data').destroy()
    },

    compareWith: 'period'
})
