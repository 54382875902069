module.exports = Em.Component.extend({
    classNames: ['page-tabs'],

    tabs: Ember.A(),

    actions: {
        didClickTab: function(tab) {
            this.sendAction('tabActivated', tab.id)
        }
    }
})
