var storage = require('storage')

module.exports = Em.Mixin.create({
    getLocalStorageFilterKey: function(filterName) {
        return 'report_filter_' + filterName
    },

    isLocalStorageFilter: function(filterName) {
        var localStorageFilters = ['hideZeroes', 'invertAmounts', 'ytd']
        return localStorageFilters.indexOf(filterName) !== -1
    },

    isLocalStorageFilterApplied: function(filterName) {
        return storage(this.getLocalStorageFilterKey(filterName)) === 'true'
    },

    setFilterToLocalStorage: function(filterName, filterValue) {
        var localStorageFilterKey = this.getLocalStorageFilterKey(filterName)

        if (this.isLocalStorageFilter(filterName)) {
            storage(localStorageFilterKey, filterValue)
        }
    }
})
