var Scope = require('../utils/scope')

module.exports = Em.Component.extend({
    auth: Ember.inject.service(),

    layout: require('../templates/components/bank-connection-status-buttons'),

    bankConnections: Em.inject.service(),

    needs: ['bankAccount'],

    account: Em.computed.oneWay('controllers.bankAccount.model'),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    hasBankConnections: false,

    organization: null,

    hasExpirationDate: function() {
        return !!this.get('bankConnections.bankConnection.connection.expiresAt')
    }.property('bankConnections.bankConnection.connection.expiresAt'),

    withAuthorization: function(onAuthorized) {
        return this.get('auth').withAuthorization(
            onAuthorized,
            Scope.BankSyncWrite,
            'bank_account_overview_connect_bank'
        )
    },

    isBankIntegrationLoaded: function() {
        return !this.get('bankConnections.isLoadingBankConnection')
    }.property('bankConnections.isLoadingBankConnection'),

    actions: {
        openSettings: function() {
            this.withAuthorization(function() {
                this.container.lookup('component:bank-settings-modal')
                    .set('accountId', this.get('account.id'))
                    .show()
            }.bind(this))
        }
    },

    customActions: {
        checkBankIntegration: function(e) {
            this.set('bankConnections.bankConnection', e.detail.connectedBankData)
            this.set('bankConnections.isLoadingBankConnection', false)
        }
    }
})
