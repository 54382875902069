var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['fixed-assets-list-item'],

    classNameBindings: ['item.isActive:is-active', 'isValid::has-validation-errors'],

    item: null,

    years: null,

    annualReport: Em.inject.service(),

    isValid: true,

    didInsertElement: function() {
        if (this._nestedDropdownIsVisible()) {
            Em.run.scheduleOnce('render', this, function() {
                this._openHiddenNestedDropdown()
            })
        }
    },

    group: function() {
        return this.get('item.last_year_influx.group')
    }.property('item.last_year_influx.group'),

    section: function() {
        return this.get('item.last_year_influx.section')
    }.property('item.last_year_influx.section'),

    _openDropdown: function() {
        var contentHeight = this.$('.collapse-body-inner').css('height')

        this.$('.collapse-body').css({
            height: contentHeight
        })
    },

    _closeDropdown: function() {
        this.$().removeClass('disable-transition')
        this.$('.collapse-body').css({
            height: 0
        })
    },

    _openHiddenNestedDropdown: function() {
        this.$('.open-nested-dropdown-action').hide()

        var nestedHeight = this.$('.nested-collapse-body-inner').outerHeight()

        this.$('.nested-collapse-body').css({
            height: nestedHeight + 'px'
        })
    },

    _openNestedDropdown: function() {
        this.$('.open-nested-dropdown-action').hide()
        this.$().addClass('disable-transition')

        var contentHeight = this.$('.collapse-body-inner').outerHeight()
        var nestedHeight = this.$('.nested-collapse-body-inner').outerHeight()

        this.$('.nested-collapse-body').css({
            height: nestedHeight + 'px'
        })
        this.$('.collapse-body').css({
            height: contentHeight + nestedHeight + 'px'
        })
    },

    _nestedDropdownIsVisible: function() {
        // Checks if any of the target fields has a value
        return this.get('item.this_year_departure.value') !== null || this.get('item.last_year_departure.value') !== null || this.get('item.this_year_profits.value') !== null || this.get('item.last_year_profits.value') !== null
    },

    errors: function() {
        return Em.O()
    }.property(),

    validate: function() {
        var item = this.get('item')
        var errors = this.get('errors')
        var baseFieldHasValue = false

        // If any of these has a value, then 'requiredFields' will be required
        var baseFields = [
            'last_year_departure',
            'last_year_depreciations',
            'last_year_influx',
            'last_year_primo',
            'last_year_profits',
            'this_year_departure',
            'this_year_depreciations',
            'this_year_influx',
            'this_year_profits'
        ]

        var requiredFields = [
            'depreciation_period_from',
            'depreciation_period_to',
            'residual_value_in_percent_from',
            'residual_value_in_percent_to'
        ]

        baseFields.forEach(function(f) {
            if (!Em.isEmpty(item[f].value)) {
                baseFieldHasValue = true
            }
        })
        if (baseFieldHasValue) {
            requiredFields.forEach(function(f) {
                if (Em.isEmpty(item[f].value)) {
                    errors.set(f, t('required_field'))
                }
            })
        } else {
            errors.set('depreciation_period_from', null)
            errors.set('depreciation_period_to', null)
            errors.set('residual_value_in_percent_from', null)
            errors.set('residual_value_in_percent_to', null)
        }

        // range validation
        var depricationFrom = item.depreciation_period_from.value
        var depricationTo = item.depreciation_period_to.value
        if (baseFieldHasValue && !Em.isEmpty(depricationFrom) && !Em.isEmpty(depricationTo)) {
            if (depricationFrom > depricationTo) {
                errors.set('depreciation_period_from', t('annual_report.must_be_smallest_in_range'))
                errors.set('depreciation_period_to', t('annual_report.must_be_largest_in_range'))
            } else {
                errors.set('depreciation_period_from', null)
                errors.set('depreciation_period_to', null)
            }
        }

        var residualFrom = item.residual_value_in_percent_from.value
        var residualTo = item.residual_value_in_percent_to.value
        if (baseFieldHasValue && !Em.isEmpty(residualFrom) && !Em.isEmpty(residualTo)) {
            if (residualFrom > residualTo) {
                errors.set('residual_value_in_percent_from', t('annual_report.must_be_smallest_in_range'))
                errors.set('residual_value_in_percent_to', t('annual_report.must_be_largest_in_range'))
            } else {
                errors.set('residual_value_in_percent_from', null)
                errors.set('residual_value_in_percent_to', null)
            }
        }

        // departure/profits validation
        var departureLastYear = item.last_year_departure.value
        var profitsLastYear = item.last_year_profits.value
        if (!Em.isEmpty(profitsLastYear) && Em.isEmpty(departureLastYear)) {
            errors.set('last_year_departure', t('required_field'))
        } else {
            errors.set('last_year_departure', null)
        }

        var departureThisYear = item.this_year_departure.value
        var profitsThisYear = item.this_year_profits.value
        if (!Em.isEmpty(profitsThisYear) && Em.isEmpty(departureThisYear)) {
            errors.set('this_year_departure', t('required_field'))
        } else {
            errors.set('this_year_departure', null)
        }

        // count the number of errors
        var errorsCount = Em.keys(errors).filter(function(error) {
            return errors[error] != null
        }).length

        this.set('isValid', errorsCount === 0)
    },

    actions: {
        openNestedDropdown: function() {
            this._openNestedDropdown()
        },

        didClickChoice: function() {
            var self = this

            this.set('item.isActive', !this.get('item.isActive'))

            if (this.get('item.isActive')) {
                self._openDropdown()
            } else {
                self._closeDropdown()
            }
        },

        saveValue: function(value, context) {
            this.validate()

            var self = this

            var property = {
                name: this.get('group') + '.' + this.get('section') + '.' + context.get('name'),
                value: value
            }

            this.get('annualReport').putAnswer(this.get('item.year'), [property])
                .then(function(res) {
                    var key = self.get('item.key')
                    var primo = res.data.questions.findBy('name', key + '.this_year.primo')
                    var value = primo.value

                    self.set('item.this_year_primo.value', value)
                    self.sendAction('updateAssets', res)
                })
        }
    }
})
