var Window = require('ember-window')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-meta-field-editor'),

    actions: {
        save: function() {
            var self = this
            this.get('content')
                .save()
                .mask()
                .on('success', function() {
                    self.close()
                })
        }
    }
})
