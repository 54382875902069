var highestZIndex = require('highest-z-index')

module.exports = Ember.Component.extend({
    layoutName: 'components/stacked-bar-chart-popover',

    classNames: ['popover', 'stacked-bar-chart-popover'],

    item: null,

    category: null,

    totalCategoryValue: function() {
        var category = this.get('category')
        if (!category) {
            return 0
        }
        return category.items.reduce(function(total, item) {
            return total + item.value
        }, 0)
    }.property('category'),

    showAt: function(x, y) {
        this._x = x
        this._y = y
        if (this.get('element')) {
            this._updatePosition()
        } else {
            this.appendTo(Billy.get('rootElement'))
        }
    },

    didInsertElement: function() {
        this._super()
        this.$().css('z-index', 1 + highestZIndex())
        this._updatePosition()
    },

    _updatePosition: function() {
        var el = this.$()
        var notch = this.$('.notch')
        var w = el.outerWidth()
        var h = el.outerHeight()
        var notchW = notch.outerWidth()
        var notchH = notch.outerHeight()
        var left
        var top

        left = this._x - w / 2

        top = this._y - h - notchH

        el.css({
            left: left + 'px',
            top: top + 'px'
        })

        el.addClass('popover-above')

        notch.css({
            left: (w / 2 - notchW / 2) + 'px'
        })
    }
})
