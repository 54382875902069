var annualReportData = require('../data/annual-report')

var EMV_BILLY_HIDDEN = [
    'checklist.has_suspected_termination'
]

var HIDDEN = [
    'checklist.wants_accounting_and_book_validation'
]

module.exports = require('./abstract-annual-report').extend({
    needs: ['annualReport'],

    annualReportCredits: Em.inject.service(),

    routerUtils: Em.inject.service(),

    annualReportController: Em.computed.alias('controllers.annualReport'),

    cvr: Em.inject.service(),

    queryParams: {
        hasBoughtPackage: {
            replace: true
        }
    },

    model: function() {
        return Em.RSVP.all([
            _.get(this.modelFor('annual_report'), 'commitment'),
            this.get('annualReport').calculatePrice(),
            this.get('annualReport').getAnnualReportSubscription(),
            this.get('annualReportCredits').loadUnusedCredits()
        ])
    },

    /**
     * @param {AnnualReportQuestion} question
     * @param {string} companyType
     * @returns {boolean}
     */
    isQuestionHidden: function(question, companyType) {
        var isEmv = ['ENK', 'PMV'].includes(companyType)

        if (HIDDEN.includes(question.name)) {
            return true
        }

        return isEmv ? EMV_BILLY_HIDDEN.includes(question.name) : false
    },

    /**
     * @param {AnnualReportQuestion} question
     * @param {[AnnualReportQuestion]} questions
     * @returns {boolean}
     */
    getSectionQuestions: function(question, questions) {
        if (!question.section) {
            return []
        }

        return questions.filter(function(q) {
            return q.section === question.section
        })
    },

    /**
     * @param {[AnnualReportCommitment]} values
     * @returns {AnnualReportCommitment}
     */
    afterModel: function(values) {
        var self = this
        var commitment = values[0]
        var annualReportService = this.get('annualReport')

        if (!commitment.questionsParsed) {
            commitment.questionsParsed = true

            return this.get('cvr')
                .loadSafe()
                .then(function(cvrData) {
                    var companyType = cvrData.companyType
                    commitment.questions = commitment.questions
                        .map(function(question) {
                            var isFauxHidden = self.isQuestionHidden(question, companyType)

                            if (isFauxHidden && question.value === null) {
                                annualReportService.putAnswer(commitment.year, [{
                                    name: question.name,
                                    value: false
                                }])
                            }

                            return Object.assign({}, question, {
                                isFauxHidden: isFauxHidden
                            })
                        })
                        .filter(function(question) {
                            return !question.hidden && question.group === 'checklist'
                        })
                        .map(function(question) {
                            return Em.Object.create({
                                name: question.name,
                                price: question.price || 0,
                                value: question.isFauxHidden ? 0 : question.value,
                                isLocked: question.is_locked,
                                isStopQuestion: question.is_stop_question,
                                stopAnswer: question.stop_answer,
                                createdTime: question.createdTime,
                                updatedTime: question.updatedTime,
                                isFauxHidden: question.isFauxHidden,
                                purchasablePackage: annualReportData.questionToPackageMap[question.name] || null,
                                sectionQuestions: self.getSectionQuestions(question, commitment.questions),
                                // computed here because the "question" scope leaks in the controller with addon_label translation
                                translationKeyPrefix: (
                                    (commitment.companyType === 'company' && 'annual_report.multiple_choice.company.') ||
                                    (commitment.companyType === 'proprietorship' && 'annual_report.multiple_choice.')
                                ) + question.name
                            })
                        })
                    return commitment
                })
        }
        return commitment
    },

    setupController: function(controller, values, transition) {
        var commitment = values[0]
        var basePrice = values[1].annual
        var basePriceOneoff = values[1].oneoff
        var arSubscription = values[2]
        var unusedCredits = this.get('annualReportCredits.hasUnusedCredits')

        var hasARSubscription = !!(arSubscription && arSubscription.ID)

        controller.set('isProcessing', false) // reset state

        this._super(controller, commitment, transition)

        var packageBought = transition.queryParams.package_bought
        if (packageBought === 'companyClosing') {
            packageBought = true
        }

        controller.set('acceptTerms', false)
        controller.set('hasBoughtPackage', transition.queryParams.hasBoughtPackage || transition.queryParams.package_bought)
        controller.set('basePrice', basePrice)
        controller.set('basePriceOneoff', basePriceOneoff)

        if (hasARSubscription) {
            controller.set('isSubscription', false) // needs to be reset to set correct prices for extending the subscription
        }

        controller.set('hasARSubscription', hasARSubscription)
        controller.set('showSubscriptionInfo', commitment.year >= 2022 && hasARSubscription)
        controller.set('subscriptionRenewalDate', this.get('annualReport').getSubscriptionRenewalDate(arSubscription))
        controller.set('hasARSubscriptionRenewed', unusedCredits)
    }
})
