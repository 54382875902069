var t = require('i18n').t
var moment = require('momentjs')

module.exports = Em.ObjectController.extend({
    needs: ['organization'],

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    contacts: Ember.inject.service(),

    activeAttachment: null,

    activeImage: null,

    voucherId: null,

    hasMultipleImages: Em.computed.gt('images.length', 1),

    initModel: function() {
        var self = this
        var attachment = self.get('activeAttachment')

        if (!attachment) {
            return
        }

        this.updateModelWithScanData()
    }.on('init'),

    loadVoucherId: function() {
        var self = this
        var attachment = self.get('activeAttachment')

        if (!attachment) {
            return
        }

        this.updateModelWithScanData()
    }.observes('activeAttachment'),

    billDidChange: function() {
        if (this.get('activeAttachment.owner') === this.get('model')) {
            return
        }
        this.set('activeAttachment', null)
        this.set('activeImage', null)
        this.attachmentsDidChange()
    }.observes('model'),

    allBillThumbnails: function() {
        var attachments = this.get('attachments')
        return attachments ? attachments.mapBy('file.thumbnails') : []
    }.property('attachments.@each.file.thumbnails'),

    images: function() {
        // eslint-disable-next-line no-unused-vars
        var allBillThumbnails = this.get('allBillThumbnails') // [sebastianseilund] We don't use this, but we need to invoke it to kick-start some observes I guess (why, Ember?)
        var attachments = this.get('attachments')
        if (!attachments) {
            return []
        }

        var images = []
        attachments.forEach(function(attachment) {
            var attachmentImages = attachment.get('file.thumbnails')
            if (attachmentImages && attachmentImages.get('isLoaded')) {
                attachmentImages.forEach(function(image) {
                    image.preload()
                    images.push(Em.O({
                        attachment: attachment,
                        image: image
                    }))
                })
            } else {
                images.push(Em.O({
                    attachment: attachment,
                    image: null
                }))
            }
        })
        return images
    }.property('allBillThumbnails.@each.isLoaded'),

    attachmentsDidChange: function() {
        if (!this.get('activeAttachment')) {
            this.set('activeImage', null)
            this.set('activeAttachment', this.get('attachments').objectAt(0))
        }
    }.observes('attachments.@each'),

    activeAttachmentImagesIsLoadedDidChange: function() {
        if (!this.get('activeImage') && this.get('activeAttachment.file.thumbnails.isLoaded')) {
            this.set('activeImage', this.get('activeAttachment.file.thumbnails').objectAt(0))
        }
    }.observes('activeAttachment.file.thumbnails.isLoaded'),

    activeImageIndex: function() {
        var index = -1
        var activeAttachment = this.get('activeAttachment')
        var activeImage = this.get('activeImage')
        this.get('images').find(function(image, i) {
            if (image.get('attachment') === activeAttachment && image.get('image') === activeImage) {
                index = i
                return true
            }
            return false
        })
        return index
    }.property('activeImage', 'images.@each'),

    activeImageNo: function() {
        return 1 + this.get('activeImageIndex')
    }.property('activeImageIndex'),

    isFirstImage: function() {
        return this.get('activeImageIndex') === 0
    }.property('activeImageIndex'),

    isLastImage: function() {
        return this.get('activeImageIndex') === this.get('images.length') - 1
    }.property('activeImageIndex', 'images.length'),

    activeImageName: function() {
        var name = this.get('activeAttachment.file.fileName')
        var count = this.get('activeAttachment.file.thumbnails.length')
        if (!name) {
            return ''
        }
        if (count > 1) {
            var page = 1 + this.get('activeAttachment.file.thumbnails').indexOf(this.get('activeImage'))
            name += ' page ' + page + '/' + count
        }
        return name
    }.property('activeAttachment.file.fileName', 'activeAttachment.file.thumbnails.@each', 'activeImage'),

    sourceText: function() {
        // TODO: This should (maybe) be displayed somewhere in the template
        if (!this.get('source') && !this.get('subject')) {
            return ''
        }
        return t('bill.edit.attachments.sent_via_source', { date: this.get('createdTime').format('L'), source: this.get('source'), subject: this.get('subject') })
    }.property('createdTime', 'source', 'subject'),

    navigateImage: function(delta) {
        var images = this.get('images')
        var index = delta + this.get('activeImageIndex')
        if (index >= images.get('length')) {
            index = 0
        } else if (index < 0) {
            index = images.get('length') - 1
        }
        var item = images.objectAt(index)
        this.set('activeAttachment', item.get('attachment'))
        this.set('activeImage', item.get('image'))
    },

    navigateToLatestImage: function() {
        var images = this.get('images')
        var index = images.get('length') - 1
        var item = images.objectAt(index)

        this.set('activeAttachment', item.get('attachment'))
        this.set('activeImage', item.get('image'))
    },

    helpText: function() {
        return new Em.Handlebars.SafeString(t('bill.edit.help_text'))
    }.property(),

    timelineUrl: function() {
        return '/organizations/' + this.get('model.organization.id') + '/bills/' + this.get('model.id') + '/timeline'
    }.property('model.id', 'model.organization.id'),

    updateModelWithScanData: function() {
        var self = this
        var attachment = self.get('activeAttachment')
        var file = attachment.get('file')
        if (!file) {
            return
        }

        file.promise.then(function() {
            self.processFile(file)
        })
    },

    processFile: function(file) {
        var self = this
        var data
        try {
            data = JSON.parse(file.get('dataJson'))
        } catch(error) {
            return
        }

        if (!data) {
            return
        }

        this.resolveContact(data).then(function(contact) {
            self.processData(data, contact)
        })
    },

    resolveContact: function(data) {
        var self = this
        return new Em.RSVP.Promise(function(resolve, reject) {
            if (!self.get('model.isBare') || !data.supplier) {
                resolve(null)
                return
            }

            if (data.supplier.contact_id) {
                resolve(Billy.Contact.find(data.supplier.contact_id))
            } else if (data.supplier.vat_no) {
                self.get('contacts')
                    .createContactFromRegistrationNo(data.supplier.vat_no)
                    .then(function(contact) {
                        if (contact && data.supplier.name) {
                            contact.set('name', data.supplier.name)
                        }

                        resolve(contact)
                    })
                    .catch(function(e) {
                        if (e.code === 'E_NOT_FOUND') {
                            return resolve(null)
                        }

                        return reject(e)
                    })
            }
        })
    },

    updateLinesWithContactDefaults: function() {
        var lines = this.get('model.lines')
        for (var i = 0; i < lines.length; i++) {
            var line = lines.objectAt(i)
            line.populateContactDefaults()
        }
    },

    processData: function(data, contact) {
        var model = this.get('model')
        var isBare = this.get('model.isBare')

        if (!data || !isBare) {
            return
        }

        if (contact) {
            model.set('contact', contact)
            this.get('billEditController').set('isContactAutofilled', true)
            this.updateLinesWithContactDefaults()
        }

        var today = moment().format('YYYY-MM-DD')
        var entryDate = moment(model.get('entryDate')).format('YYYY-MM-DD')
        if (data.document_issue_date && entryDate === today) {
            model.set('entryDate', moment(data.document_issue_date))
            this.get('billEditController').set('isEntryDateAutofilled', true)
        }

        if (data.document_currency) {
            model.set('currency', Billy.Currency.find(data.document_currency))
            this.get('billEditController').set('isCurrencyAutofilled', true)
        }

        if (data.document_total_incl_vat) {
            model.set('taxMode', 'incl')
        }

        var lineCount = model.get('lines').get('length')
        if (typeof data.document_total_incl_vat === 'number' && lineCount <= 1) {
            if (lineCount === 0) {
                // We will most likely never get to this point because we have guards against having no lines
                // in the route and elsewhere here, but better safe than sorry
                Billy.BillLine.createRecord({
                    isAmountAutofilled: true,
                    bill: model,
                    amount: data.document_total_incl_vat
                })
            } else {
                var originalBillLine = model.get('lines').objectAt(0)
                Billy.BillLine.createRecord({
                    isAmountAutofilled: true,
                    bill: model,
                    description: originalBillLine.get('description'),
                    taxRate: originalBillLine.get('taxRate'),
                    account: originalBillLine.get('account'),
                    amount: originalBillLine.get('amount')
                        ? originalBillLine.get('amount')
                        : data.document_total_incl_vat
                })
                originalBillLine.deleteRecord()
            }
        }

        // eslint-disable-next-line no-unused-vars
        var allBillThumbnails = this.get('allBillThumbnails') // [andres] We don't use this, but we need to invoke it to kick-start some observes I guess (why, Ember?)
    },

    actions: {
        deleteAttachment: function() {
            var self = this
            this.container.lookup('util:dialog')
                .confirmWarning(
                    null,
                    t('bill.edit.attachments.confirm_delete_attachment'),
                    t('bill.edit.attachments.yes_delete_attachment'),
                    t('cancel')
                )
                .then(function() {
                    var attachment = self.get('activeAttachment')
                    self.set('activeAttachment', null)
                    attachment.deleteRecord()
                })
        },

        showBillAttachments: function() {
            var self = this
            var selector = this.container.lookup('component:bill-attachments-selector')
            selector.on('didSelect', function(attachment) {
                self.send('handleAttachmentSelected', selector, attachment)
            })
            selector.showForOrganization(this.get('model.organization'))
        },

        handleAttachmentSelected: function(selector, attachment) {
            var file = attachment.get('file')
            var bill = attachment.get('owner')
            var self = this

            Billy.Attachment.createRecord({
                organization: this.get('controllers.organization.model'),
                owner: this.get('model'),
                file: file
            })

            this.get('model')
                .save({ embed: ['attachments'] })
                .then(function() {
                    // Delete the attachment from where it was before and the whole draft if it was the last attachment
                    attachment.deleteRecord()

                    if (bill.get('attachments.length') === 0) {
                        bill.deleteRecord()
                    } else {
                        bill.save({ embed: ['attachments'] })
                    }

                    // Close the popup and navigate to the new image after it has been uploaded
                    selector.close()
                    self.navigateToLatestImage()
                })
        },

        splitAttachment: function() {
        },

        previousImage: function() {
            this.navigateImage(-1)
        },

        nextImage: function() {
            this.navigateImage(1)
        },

        didUploadFile: function(context) {
            var file = context.file
            var attachment = Billy.Attachment.createRecord({
                organization: this.get('controllers.organization.model'),
                owner: this.get('model'),
                file: file
            })
            this.set('activeAttachment', attachment)
            this.set('activeImage', null)
        }
    }
})
