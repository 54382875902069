Billy.TaxRateDeductionComponent.reopen({
    sharePercent: function(key, value) {
        if (arguments.length === 1) {
            value = this.get('share') * 100
        } else {
            this.set('share', value / 100)
        }
        return value
    }.property('share')
})
