var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = require('./bank-sync-abstract-item').extend({
    needs: ['bankSyncMatches'],

    matchesController: Em.computed.alias('controllers.bankSyncMatches'),

    canBeRemoved: function() {
        return !this.get('matchesController.editMode') && this.get('match.lines.length') > 1
    }.property('matchesController.editMode', 'match.lines.length'),

    isSelectable: Em.computed.alias('matchesController.editMode'),

    isSelected: function() {
        return this.get('matchesController.selectedLines').contains(this.get('model'))
    }.property('matchesController.selectedLines.@each'),

    actions: {
        remove: function() {
            var self = this
            var line = this.get('model')
            var matchController = this.get('matchesController')
            var newMatch
            newMatch = Billy.BankLineMatch.createRecord({
                account: line.get('account'),
                entryDate: line.get('entryDate'),
                amount: line.get('amount'),
                side: line.get('side'),
                isApproved: false
            })
            line.set('match', newMatch)
            matchController.addMatch(newMatch)
            newMatch.save()
                .then(line.save.bind(line))
                .then(null, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_REMOVE, e.message)
                    matchController.removeMatch(newMatch)
                    newMatch.deleteRecord()
                    line.rollback()
                })
        },

        toggleSelect: function() {
            if (this.get('isSelected')) {
                this.get('matchesController').send('deselectLine', this.get('model'))
            } else {
                this.get('matchesController').send('selectLine', this.get('model'))
            }
        }
    }
})
