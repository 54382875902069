module.exports = Em.Route.extend({
    api: Ember.inject.service(),

    organizationFeatures: Em.inject.service(),

    queryParams: {
        currentPage: {
            refreshModel: true
        },
        sortProperty: {
            refreshModel: true
        },
        sortDirection: {
            refreshModel: true
        }
    },

    model: function(params) {
        var organization = this.modelFor('organization')

        var billsQuery = {
            state: 'draft',
            organizationId: organization.get('id'),
            sortProperty: params.sortProperty,
            sortDirection: params.sortDirection,
            page: params.currentPage,
            pageSize: 10,
            include: 'bill.lines,bill.attachments,attachment.file'
        }

        var billsPromise = Billy.Bill.findByQuery(billsQuery).promise

        return Em.RSVP.hash({
            bills: billsPromise
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model)

        var selected = Em.copy(controller.get('selectedDraftBills'))

        // Remove draft bills from selectedDraftBills if it does not exist in the model
        selected.forEach(function(draftBill) {
            if (!controller.get('model.bills.content').contains(draftBill)) {
                controller.get('selectedDraftBills').removeObject(draftBill)
            }
        })

        controller.schedulePoll()
    },

    resetController: function(controller) {
        controller.cancelPoll()
        controller.setProperties({
            currentPage: 1,
            sortProperty: 'createdTime',
            sortDirection: 'ASC'
        })
    },

    actions: {
        reloadDraftBills: function() {
            this.refresh()
        }
    }
})
