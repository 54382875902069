// Instantiate models and append to Billy namespace
var models = require('billy-models')
for (var model in models) {
    if (Object.prototype.hasOwnProperty.call(models, model)) {
        window.Billy[model] = models[model]
    }
}

BD.Model.reopen({
    route: function() {
        return Em.get(this.constructor, 'root').underscore()
    }.property()
})

// Instantiate "mixins"
require('./model-extensions/account')
require('./model-extensions/account-group')
require('./model-extensions/balance-modifier')
require('./model-extensions/bank-line-match')
require('./model-extensions/bill')
require('./model-extensions/bill-line')
require('./model-extensions/contact')
require('./model-extensions/contact-person')
require('./model-extensions/country')
require('./model-extensions/country-group')
require('./model-extensions/currency')
require('./model-extensions/daybook-transaction')
require('./model-extensions/file')
require('./model-extensions/industry-group')
require('./model-extensions/invoice')
require('./model-extensions/external-invoice')
require('./model-extensions/invoice-line')
require('./model-extensions/locale')
require('./model-extensions/organization')
require('./model-extensions/organization-invoice')
require('./model-extensions/organization-owner')
require('./model-extensions/posting')
require('./model-extensions/product')
require('./model-extensions/sales-tax-return')
require('./model-extensions/sales-tax-ruleset')
require('./model-extensions/scheduled-invoice')
require('./model-extensions/tax-rate')
require('./model-extensions/tax-rate-deduction-component')
require('./model-extensions/user')
