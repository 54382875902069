var moment = require('momentjs')

module.exports = Em.Component.extend({
    layoutName: 'components/campaign-offer-bar',

    classNames: ['top-bar', 'top-bar-blue'],

    organization: null,

    campaign: null,

    setBackgroundImage: function() {
        var bgImg = this.get('data.variables.campaignBannerBackgroundUrl')

        if (bgImg) {
            var el = this.$()
            el.css('background-image', 'url(' + bgImg + ')')
            // overwrite 1:2 constraint defined in .top-bar-gray in css
            el.css('background-size', 'inherit')
        }
    }.on('didInsertElement'),

    daysLeft: function() {
        var now = moment()
        var endDate = moment(this.get('data.endDate')).endOf('day')
        return endDate.diff(now, 'days')
    }.property('data.endDate'),

    hasLessThanOneDayLeft: Em.computed.lt('daysLeft', 1)
})
