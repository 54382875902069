var ValueResolver = require('../utils/value-resolver')

var nonSalesSystemRoles = [
    'accountsPayable',
    'currentEarnings',
    'prepaymentLiability',
    'realizedCurrencyDifference',
    'retainedEarnings',
    'unrealizedCurrencyDifference'
]

var taxAccountNames = [
    // Danish VAT
    'Afregnet moms',
    'Købsmoms (indgående moms)',
    'Moms af varer købt i udlandet',
    'Moms af ydelser købt i udlandet',
    'Salgsmoms (udgående moms)',

    // English tax
    'Sales tax',
    'Use tax',
    'Sales tax payable',

    // English VAT
    'Sales VAT (output)',
    'Purchase VAT (input)',
    'Settled VAT'
]

Billy.Account.reopen({
    nameWithAccountNo: function() {
        var accountNo = this.get('accountNo')
        var name = this.get('name')
        if (accountNo) {
            name = accountNo + ' - ' + name
        }
        return name
    }.property('accountNo', 'name'),

    isSalesApplicable: function() {
        if (nonSalesSystemRoles.indexOf(this.get('systemRole')) !== -1) {
            return false
        }
        // Hack
        if (taxAccountNames.indexOf(this.get('name')) !== -1) {
            return false
        }
        return true
    }.property('systemRoles', 'name'),

    bankConnectionResolver: function() {
        var id = this.get('id')
        if (!id) {
            return ValueResolver.create({
                resolver: function() {
                    return null
                }
            })
        }
        // [sebastianseilund] Billy.__container__ hack since these models do not have a container (usually we would inject the service with `bankConnections: Em.inject.service`)
        var bankConnections = Billy.__container__.lookup('service:bankConnections')
        return ValueResolver.create({
            resolver: bankConnections.find.bind(bankConnections, id)
        })
    }.property('id'),

    bankConnection: Em.computed.oneWay('bankConnectionResolver.value')
})

Billy.Account.registerSortMacro('nameWithAccountNo', ['accountNo', 'name'], function(a, b) {
    var an = a.get('accountNo')
    var bn = b.get('accountNo')
    if (an && bn) {
        return an - bn
    }
    if (an) {
        return -1
    }
    if (bn) {
        return 1
    }
    return a.get('name').localeCompare(b.get('name'))
})

Billy.Account.reopenClass({
    findBySystemRole: function(organization, systemRole) {
        var organizationId = typeof organization === 'string' ? organization : organization.get('id')
        var account = this.allLocal().find(function(account) {
            return account.get('organization.id') === organizationId && account.get('systemRole') === systemRole
        })
        if (!account) {
            throw new Error('The system account `' + account + '` was not found for organization `' + organizationId + '`.')
        }
        return account
    }
})
