var t = require('i18n').t
var postingSides = require('posting-sides')
var f = require('float')
var BankFeeAccountWindow = require('../components/bank-fee-account-window')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'bankSyncV2Matches', 'bankSyncV2PossibleSubjects', 'nextVoucherNo', 'bankApprovedLines'],

    organization: Em.computed.alias('controllers.organization.model'),

    customEvent: Ember.inject.service(),

    subscriptionLimits: Ember.inject.service(),

    lines: function() {
        return Em.ArrayController.create({
            container: this.container,
            parentController: this,
            model: this.get('model.lines'),
            itemController: 'bank-sync-v2-match-line',
            sortProperties: ['entryDate']
        })
    }.property('model.lines'),

    lineCount: function() {
        return this.get('lines.length')
    }.property('lines.length'),

    lineDescription: function() {
        if (!this.get('lines.length')) {
            return ''
        }
        return this.get('lines').mapProperty('description').join(', ')
    }.property('lines.@each.description'),

    lineAmount: function() {
        if (!this.get('lines.length')) {
            return 0
        }
        return this.get('lines').reduce(function(total, line) {
            return total + (line.get('side.isDebit') ? 1 : -1) * line.get('amount')
        }, 0)
    }.property('lines.@each.amount', 'lines.@each.side'),

    subjectAssociations: function() {
        return Em.ArrayController.create({
            container: this.container,
            parentController: this,
            model: this.get('model.subjectAssociations'),
            itemController: 'bank-sync-v2-match-subject-association'
        })
    }.property('model.subjectAssociations'),

    subjectAmount: function() {
        return this.get('subjectAssociations').reduce(function(total, subjectAsscociation) {
            return total + subjectAsscociation.get('sideAmount')
        }, 0)
    }.property('subjectAssociations.@each.sideAmount'),

    isFulfilled: function() {
        if (!this.get('lines.length') || !this.get('subjectAssociations.length')) {
            return false
        }
        return (!Em.isEmpty(this.get('differenceType')) || f.equals(this.get('lineAmount'), this.get('subjectAmount')))
    }.property('lines.length', 'subjectAssociations.length', 'lineAmount', 'subjectAmount', 'differenceType'),

    differenceLabel: function() {
        switch (this.get('differenceType')) {
        case 'overpayment':
            return t('bank_sync.overpayment')
        case 'underpayment':
            return t('bank_sync.underpayment')
        case 'bankFee':
            return t('bank_sync.bank_fee')
        }
    }.property('differenceType'),

    differenceAmount: function() {
        return this.get('lineAmount') - this.get('subjectAmount')
    }.property('lineAmount', 'subjectAmount'),

    formattedDifferenceAmount: function() {
        return Billy.money(this.get('differenceAmount'), this.get('account.currency.id'))
    }.property('differenceAmount'),

    didSelectDifferenceType: function(differenceType) {
        var self = this
        var match = this.get('model')

        function fail(e) {
            match.rollback()
            self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SELECT_DIFFERENT_TYPE, e.message)
        }

        if (differenceType === 'bankFee') {
            BankFeeAccountWindow.getDefaultBankFeeAccount(match.get('account.organization'), this.container)
                .then(function(bankFeeAccount) {
                    match.set('feeAccount', bankFeeAccount)
                        .set('differenceType', differenceType)
                        .save()
                        .then(null, fail)
                })
        } else {
            match.set('differenceType', differenceType)
                .save()
                .then(null, fail)
        }
    },

    linesOrsubjectAssociationsDidChange: function() {
        if (!Em.isEmpty(this.get('differenceType'))) {
            this.set('differenceType', null)
        }
    }.observes('lines.@each', 'subjectAssociations.@each'),

    doMatch: function() {
        var self = this
        var match = this.get('model')
        var matchesController = self.get('controllers.bankSyncV2Matches')
        var possibleSubjectsController = self.get('controllers.bankSyncV2PossibleSubjects')

        // Remember the subjects. If one of them is an invoice/bill that was only partially paid, then we must add it back to the possible-subjects list on success
        var subjects = match.get('subjectAssociations').map(function(a) { return a.get('subject') })

        matchesController.removeMatch(match)

        match.set('isApproved', true)

        return match.save()
            .then(function() {
                // Add any still unpaid invoices/bills back to the possible-subjects list
                subjects.forEach(function(subject) {
                    if ((subject instanceof Billy.Invoice || subject instanceof Billy.Bill) && !subject.get('isPaid')) {
                        possibleSubjectsController.addSubject(subject)
                    }
                })
            }, function(e) {
                matchesController.addMatch(match)
                match.rollback()
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_SAVE, e.message)
                throw e
            })
    },

    inlineCreator: null,

    hasInvoiceEditor: Em.computed.equal('inlineCreator', 'invoice'),

    hasBillEditor: Em.computed.equal('inlineCreator', 'bill'),

    hasTransactionEditor: Em.computed.equal('inlineCreator', 'transaction'),

    inlineCreateOptions: function() {
        var options = []
        var lineAmount = this.get('lineAmount')
        if (lineAmount > 0) {
            options.push(Ember.Object.create({
                value: 'invoice',
                name: t('bank_sync.create_invoice')
            }))
        } else if (lineAmount < 0) {
            options.push(Ember.Object.create({
                value: 'bill',
                name: t('bank_sync.create_bill')
            }))
        }
        options.push(Ember.Object.create({
            value: 'transaction',
            name: t('bank_sync.create_transaction')
        }))
        return options
    }.property('lineAmount'),

    invoice: null,

    invoiceIsUploading: false,

    enableInvoiceCreator: function() {
        var invoice = Billy.Invoice.createRecord({
            organization: this.get('organization'),
            currency: this.get('account.currency'),
            taxMode: 'incl'
        })
        Billy.InvoiceLine.createRecord({
            invoice: invoice
        })
        this.set('invoice', invoice)
    },

    bill: null,

    billIsUploading: false,

    billAccountNatures: function() {
        return [Billy.AccountNature.find('expense'), Billy.AccountNature.find('asset')]
    }.property(),

    taxRatePlaceholder: function() {
        return this.get('organization.hasVat') ? t('bank_sync.select_vat_rate') : t('bank_sync.select_tax_rate')
    }.property('organization.hasVat'),

    getPredictedContraAccount: function() {
        var contraAccounts = this.get('model.lines')
            .map(function(line) { return line.get('contraAccount') })
            .filter(function(contraAccount) { return !!contraAccount })
        return contraAccounts[0]
    },

    enableBillCreator: function() {
        var organization = this.get('organization')
        var bill = Billy.Bill.createRecord({
            organization: organization,
            currency: this.get('account.currency'),
            taxMode: 'incl',
            voucherNo: organization.get('hasBillVoucherNo') ? this.get('controllers.nextVoucherNo').getAndIncrement() : null
        })
        Billy.BillLine.createRecord({
            bill: bill,
            description: this.get('lineDescription'),
            account: this.getPredictedContraAccount()
        })
        this.set('bill', bill)
    },

    transaction: null,

    transactionIsUploading: false,

    enableTransactionCreator: function() {
        var organization = this.get('organization')
        if (this.get('account.currency.id') !== organization.get('baseCurrency.id')) {
            this.set('inlineCreator', null)
            this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_TRANSACTION_CREATE, t('bank_sync.transaction_not_allowed_with_foreign_currencies'))
            return
        }
        var transaction = Billy.DaybookTransaction.createRecord({
            organization: organization,
            voucherNo: this.get('controllers.nextVoucherNo').getAndIncrement()
        })
        Billy.DaybookTransactionLine.createRecord({
            daybookTransaction: transaction,
            text: this.get('lineDescription'),
            account: this.getPredictedContraAccount()
        })
        this.set('transaction', transaction)
    },

    createSubjectAssociationAndMatch: function(subject) {
        var self = this

        var association = Billy.BankLineSubjectAssociation.createRecord({
            match: this.get('model'),
            subject: subject
        })

        association.save()
            .then(function() {
                self.set('inlineCreator', null)
                return self.doMatch()
            }, function(e) {
                association.rollback()
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_CREATE, e.message)
            })
    },

    actions: {
        doMatch: function() {
            this.doMatch()
        },

        doUnapproveMatch: function() {
            this.doUnapproveMatch()
        },

        createInline: function(inlineCreator) {
            var self = this
            switch (inlineCreator) {
            case 'invoice':
                self.get('subscriptionLimits').checkForInvoiceLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableInvoiceCreator()
                })
                break
            case 'bill':
                self.get('subscriptionLimits').checkForExpenseLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableBillCreator()
                })
                break
            case 'transaction':
                self.get('subscriptionLimits').checkForExpenseLimitAndExecute(function() {
                    self.set('inlineCreator', inlineCreator)
                    self.enableTransactionCreator()
                })
                break
            }
        },

        disableInvoiceCreator: function() {
            var invoice = this.get('invoice')
            invoice.rollback()
            this.set('invoice', null)
            this.set('inlineCreator', null)
        },

        saveInvoice: function() {
            if (this.get('invoiceIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var invoice = this.get('invoice')
            var line = invoice.get('lines.firstObject')
            invoice.setProperties({
                entryDate: this.get('entryDate')
            })
            line.setProperties({
                quantity: 1,
                unitPrice: this.get('lineAmount')
            })
            this.get('customEvent').dispatchEvent(CustomEvent.InvoiceUpdated)
            invoice.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            })
                .then(this.createSubjectAssociationAndMatch.bind(this, invoice))
        },

        didSelectBillContact: function(contact) {
            this.get('bill.lines').forEach(function(line) {
                line.populateContactDefaults()
            })
        },

        didSelectBillAccount: function(account) {
            this.set('bill.lines.firstObject.taxRate', account.get('taxRate'))
        },

        disableBillCreator: function() {
            var bill = this.get('bill')
            this.get('controllers.nextVoucherNo').release(bill.get('voucherNo'))
            bill.rollback()
            this.set('bill', null)
            this.set('inlineCreator', null)
        },

        saveBill: function() {
            if (this.get('billIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var bill = this.get('bill')
            var line = bill.get('lines.firstObject')
            var amount = -1 * this.get('lineAmount')
            bill.setProperties({
                entryDate: this.get('entryDate')
            })
            line.setProperties({
                amount: amount
            })
            this.get('customEvent').dispatchEvent(CustomEvent.BillUpdated)
            bill.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            })
                .then(this.createSubjectAssociationAndMatch.bind(this, bill))
        },

        didSelectTransactionAccount: function(account) {
            this.set('transaction.lines.firstObject.taxRate', account && account.get('taxRate'))
        },

        disableTransactionCreator: function() {
            var transaction = this.get('transaction')
            this.get('controllers.nextVoucherNo').release(transaction.get('voucherNo'))
            transaction.rollback()
            this.set('transaction', null)
            this.set('inlineCreator', null)
        },

        saveTransaction: function() {
            if (this.get('transactionIsUploading')) {
                this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.STILL_UPLOADING, t('still_uploading'), NOTIFICATION_VARIANT.INFO)
                return
            }

            var self = this
            var match = this.get('model')
            var account = match.get('account')
            var lineAmount = this.get('lineAmount')
            var daybookTransaction = this.get('transaction')
            var line = daybookTransaction.get('lines.firstObject')

            // Update daybook transaction and line with properties
            daybookTransaction.setProperties({
                entryDate: this.get('entryDate')
            })
            line.setProperties({
                contraAccount: account,
                amount: Math.abs(lineAmount),
                side: lineAmount < 0 ? postingSides.debit : postingSides.credit
            })
            this.get('customEvent').dispatchEvent(CustomEvent.TransactionUpdated)

            // Save the daybook transaction
            daybookTransaction.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            }).promise
                // Find transactions for the daybook transaction
                .then(function() {
                    return Billy.Transaction.find({
                        organizationId: self.get('organization.id'),
                        originatorReference: daybookTransaction.get('reference')
                    }).promise
                })

                // Find postings for a single transaction
                .then(function(transactions) {
                    if (transactions.get('length') !== 1) {
                        throw new Error('Newly created DaybookTransaction should be the originator of exactly 1 Transaction. ' + transactions.get('length') + ' found')
                    }
                    return transactions.objectAt(0).get('postings').promise
                })

                // Associate and match
                .then(function(postings) {
                    postings = postings.filterBy('account', account)
                    if (postings.get('length') !== 1) {
                        throw new Error('Newly created Transaction for DaybookTransaction should have exactly 1 Posting on the bank account. ' + postings.get('length') + ' found')
                    }

                    return self.createSubjectAssociationAndMatch(postings.objectAt(0))
                })
        }
    },

    didDropLine: function(line) {
        var self = this
        var match = this.get('model')
        var otherMatch = line.get('match')
        var matchesController = this.get('controllers.bankSyncV2Matches')
        var possibleSubjectsController = this.get('controllers.bankSyncV2PossibleSubjects')
        var isOnly = otherMatch.get('lines.length') === 1
        var otherMatchSubjects

        // Remove otherMatch from matches controller, and add any subjects to possible subjects, if the line is the only one
        if (isOnly) {
            matchesController.removeMatch(otherMatch, true)
            otherMatchSubjects = otherMatch.get('subjectAssociations').mapBy('subject')
            otherMatchSubjects.forEach(possibleSubjectsController.addSubject.bind(possibleSubjectsController))
        }

        // Add the line to the this match and save
        line.set('match', match)
        line.save()
            .then(function() {
                // If the request succeeded, we delete the otherMatch if the line was the only one
                if (!otherMatch.get('isDestroying') && isOnly) {
                    otherMatch.deleteRecord()
                }
            }, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_LINE_DROP, e.message)
                // If the request failed, we add the otherMatch back and remove the possible subjects again
                line.rollback()
                if (!otherMatch.get('isDestroying') && isOnly) {
                    matchesController.addMatch(otherMatch)
                    otherMatchSubjects.forEach(possibleSubjectsController.removeSubject.bind(possibleSubjectsController))
                }
            })
    },

    didDropSubjectAssociation: function(association) {
        var self = this
        var match = this.get('model')
        association.set('match', match)
        association.save()
            .then(null, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_DROP, e.message)
                association.rollback()
            })
    },

    didDropSubject: function(subject) {
        var self = this
        var match = this.get('model')
        var possibleSubjectsController = this.get('controllers.bankSyncV2PossibleSubjects')

        possibleSubjectsController.removeSubject(subject)

        var association = Billy.BankLineSubjectAssociation.createRecord({
            match: match,
            subject: subject
        })
        association.save()
            .then(null, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_SUBJECT_ASSOCIATION_DROP, e.message)
                association.rollback()
                possibleSubjectsController.addSubject(subject)
            })
    },

    doUnapproveMatch: function() {
        var bankApprovedLinesController = this.get('controllers.bankApprovedLines')
        var match = this.get('model')

        bankApprovedLinesController.unapproveMatch(match)
    }
})
