var t = require('i18n').t

module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')

        return Object.assign({
            items: this.get('annualReport').getDownloadItems(model.commitment)
        }, model)
    },

    afterModel: function(model) {
        var translationPath = ''
        var commitment = model.commitment

        // Toggle translations whether it is a company or proprietorship
        if (commitment.companyType === 'company') {
            translationPath = 'annual_report.report_done.download_items.company.'
        } else if (commitment.companyType === 'proprietorship') {
            translationPath = 'annual_report.report_done.download_items.'
        }

        return model.items.map(function(item) {
            item.title = t(translationPath + item.name + '.title', { year: commitment.year })
            item.support_link = t(translationPath + item.name + '.support_link')
            return item
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('items', model.items)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
        controller.set('years', model.years)
    }
})
