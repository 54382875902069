var t = require('i18n').t
var Window = require('ember-window')
var moment = require('momentjs')
var ContactBalance = require('../utils/contact-balance')
var CustomEvent = require('../constants/customEvent')

var Component = module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    customEvent: Em.inject.service(),

    template: require('../templates/components/contact-balance-payment-window'),

    classNames: ['contact-balance-payment-window'],

    confirmCloseWhenDirty: false,

    subject: null,

    prepaymentDate: moment(),

    showForSubject: function(subject, contactBalance) {
        var contact = subject.get('contact')
        var currency = subject.get('currency')
        if (!contactBalance) {
            contactBalance = ContactBalance.create({
                contact: contact,
                currency: currency
            })
            contactBalance.get('amount') // trigger load
        }

        var payment = Billy.ContactBalancePayment.createRecord({
            organization: contact.get('organization'),
            contact: contact,
            entryDate: this.get('prepaymentDate')
        })
        Billy.BalanceModifier.createRecord({
            entryDate: this.get('prepaymentDate'),
            modifier: payment,
            subject: subject
        })
        this.set('content', payment)
            .set('subject', subject)
            .set('contactBalance', contactBalance)
        return this.show()
    },

    whoOwesWhom: function() {
        var contactBalance = this.get('contactBalance')
        if (!contactBalance) {
            return null
        }
        var params = {
            name: this.get('content.contact.name'),
            amount: Billy.money(contactBalance.get('amount'), contactBalance.get('currency.id'))
        }
        if (contactBalance.get('side.isDebit')) {
            return t('contact_balance_payment_window.contact_owes_you', params)
        } else {
            return t('contact_balance_payment_window.you_owe_contact', params)
        }
    }.property('contactBalance.side', 'contactBalance.amount', 'contactBalance.currency', 'content.contact.name'),

    actions: {
        goToBankPayment: function() {
            var contact = this.get('content.contact')
            var subject = this.get('subject')
            Component.openBankPaymentWindow(this, contact, subject)
        },

        save: function() {
            var self = this
            var payment = this.get('content')
            var subject = this.get('subject')
            var subjectId = subject.get('id')
            var subjectType = subject.get('type')
            payment.set('amount', Math.min(this.get('subject.balance'), this.get('contactBalance.amount')))
            payment.set('entryDate', this.get('prepaymentDate'))
            payment
                .save({
                    embed: ['associations']
                })
                .mask()
                .success(function() {
                    if (subjectId && subjectType) {
                        self.get('customEvent').dispatchEvent(CustomEvent.PaymentUpdated, { subjectId: subjectId, subjectType: subjectType })
                    }
                    self.close()
                })
        }
    }
})

Component.openBankPaymentWindow = function(contactBalancePaymentWindow, contact, subject) {
    var w = contactBalancePaymentWindow.container.lookup('component:bank-payment-window')
    contactBalancePaymentWindow.replaceWith(w)
    w.showForContact(contact, subject)
}
