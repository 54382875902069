var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/match',

    classNames: ['bank-sync-v2-match'],

    classNameBindings: ['match.isApproved:approved'],

    organization: null,

    account: null,

    match: null,

    selectMode: false,

    inlineCreator: null,

    hasInvoiceCreator: Em.computed.equal('inlineCreator', 'invoice'),

    hasBillCreator: Em.computed.equal('inlineCreator', 'bill'),

    hasTransactionCreator: Em.computed.equal('inlineCreator', 'transaction'),

    isDebit: function() {
        return this.get('match.lines').reduce(function(balance, line) {
            return balance + (
                line.get('amount') *
                (line.get('side.value') === 'debit' ? 1 : -1)
            )
        }, 0) > 0
    }.property('match.lines.@each'),

    actions: {
        toggledLineSelection: function(line) {
            this.sendAction('toggledLineSelection', line)
        },

        setInlineCreator: function(type) {
            if (type === 'transaction' && this.get('account.currency.id') !== this.get('organization.baseCurrency.id')) {
                this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_INLINE_CREATOR_SET, t('bank_sync.transaction_not_allowed_with_foreign_currencies'))
                return
            }

            this.set('inlineCreator', type)
        },

        resetInlineCreator: function() {
            this.set('inlineCreator', null)
        }
    }
})
