var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    year: function() {
        return this.get('model.commitment.year')
    }.property('model'),

    isYearDisabled: function() {
        var self = this
        var years = this.get('model.years')
        var currentYear = years.find(function(yearData) {
            return yearData.year === self.get('year')
        })

        if (!currentYear) {
            return true
        }

        return !currentYear.isAvailable
    }.property('model.years'),

    organizationName: function() {
        return this.get('organization.name')
    }.property('organization'),

    actions: {
        startClientFlow: function() {
            var self = this
            return this.get('api')
                .post('/organizations/' + this.get('organization.id') + '/annualReport/' + this.get('year') + '/commit', {
                    payload: {
                        companyType: this.get('organization.companyType'),
                        context: 'meneto'
                    }
                })
                .then(function() {
                    self.send('navigateToNextStep')
                })
        }
    }
})
