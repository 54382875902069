var i18n = require('i18n')
var t = i18n.t

module.exports = Em.Component.extend({
    layoutName: 'components/more-button',

    classNames: ['item', 'button-group'],

    buttonClass: null,

    align: 'right',

    dropdownMenuClass: function() {
        return this.get('align')
    }.property('align'),

    text: function() {
        var text = this.get('textT')
            ? t(this.get('textT'))
            : t('more_button')

        return text
    }.property('textT')
})
