var Window = require('ember-window')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/simple-tax-rate-editor'),

    customEvent: Ember.inject.service(),

    type: null, // Should be 'sales' or 'use'.  At present, 'use' is not supported

    isSales: Em.computed.equal('type', 'sales'),

    isUse: Em.computed.equal('type', 'use'),

    callback: null,

    createAndShow: function(organization, props) {
        var self = this

        // Setup TaxRate
        var taxRate = Billy.TaxRate.createRecord({
            organization: organization,
            isActive: true
        })
        if (props) {
            taxRate.setProperties(props)
        }
        taxRate.setProperties({
            appliesToSales: true,
            appliesToPurchases: false
        })

        // Set the TaxRate as the content of this component
        this.set('type', 'sales')
            .set('content', taxRate)

        // Find sales tax account
        var filter = Billy.Account.filter({
            query: {
                organization: organization,
                systemRole: 'salesTax'
            }
        })
        filter.promise.then(function(accounts) {
            var salesTaxAccount = accounts.get('firstObject')
            filter.destroy()

            // Should not happen.
            if (!salesTaxAccount) {
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TAX_CREATE, t('tax.sales_tax_account_not_found'))
                return
            }

            // Create a 100% deduction component for salesTax system role account
            var dc = Billy.TaxRateDeductionComponent.createRecord({
                taxRate: taxRate,
                share: 1,
                source: 'tax',
                account: salesTaxAccount,
                priority: 1
            })

            // Make sure the model is clean
            dc.resetClean()
            taxRate.resetClean()

            // Show yourself
            self.show()
        })
    },

    actions: {
        save: function() {
            var self = this
            var taxRate = this.get('content')
            taxRate
                .save({
                    embed: ['deductionComponents']
                })
                .mask()
                .on('success', function() {
                    self.close()
                    self.get('customEvent').dispatchEvent(CustomEvent.TaxRuleUpdated)
                    if (self.get('callback')) {
                        self.get('callback')(taxRate)
                    }
                })
        }
    }
})
