var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organization}/quotes/{quote}/lines',
    urlParamMap: {
        organization: 'quote.organization.id',
        quote: 'quote.id'
    }
})
