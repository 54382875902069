var addressFormat = require('address-format')
var get = Em.get

module.exports = Em.Component.extend({
    layoutName: 'components/formatted-address',

    tagName: 'span',

    content: null,

    formattedAddress: function() {
        var content = this.get('content')
        if (!content) {
            return ''
        }

        var street = get(content, 'street')
        var city = get(content, 'city') || get(content, 'cityText')
        var state = get(content, 'state') || get(content, 'stateText')
        var zipcode = get(content, 'zipcode') || get(content, 'zipcodeText')
        var country = get(content, 'country')

        if (city && typeof city === 'object') {
            city = get(city, 'name')
        }
        if (state && typeof state === 'object') {
            state = get(state, 'stateCode')
        }
        if (zipcode && typeof zipcode === 'object') {
            zipcode = get(zipcode, 'zipcode')
        }

        var a = addressFormat({
            address: street,
            city: city,
            subdivision: state,
            postalCode: zipcode,
            countryCode: country && get(country, 'id')
        })

        if (country) {
            a.push(get(country, 'name'))
        }

        return a.join('\n')
    }.property('content.{street,city,cityText,state,stateText,zipcode,zipcodeText,country}')
})
