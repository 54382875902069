var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    userOrganizations: Em.inject.service(),

    debtData: function() {
        var annualReportService = this.get('annualReport')
        var userOrganizations = this.get('userOrganizations')
        var organization = userOrganizations.get('activeOrganization')
        var commitmentYear = parseInt(this.get('model.commitment.year'), 10)
        var fiscalYear = annualReportService.getFiscalYearForYear(
            commitmentYear,
            organization.get('firstFiscalYearStart'),
            organization.get('firstFiscalYearEnd')
        )
        var fiscalYears = {
            prev: organization.get('firstFiscalYearEnd').isBefore(fiscalYear.startDate)
                ? annualReportService.getFiscalYearForYear(commitmentYear - 1, organization.get('firstFiscalYearStart'), organization.get('firstFiscalYearEnd'))
                : null, // there is no previous fiscal year
            current: fiscalYear,
            next: annualReportService.getFiscalYearForYear(commitmentYear + 1, fiscalYear.startDate, fiscalYear.endDate),

            // Some more
            next_1: annualReportService.getFiscalYearForYear(commitmentYear + 1, fiscalYear.startDate, fiscalYear.endDate),
            next_2: annualReportService.getFiscalYearForYear(commitmentYear + 2, fiscalYear.startDate, fiscalYear.endDate),
            next_3: annualReportService.getFiscalYearForYear(commitmentYear + 3, fiscalYear.startDate, fiscalYear.endDate),
            next_4: annualReportService.getFiscalYearForYear(commitmentYear + 4, fiscalYear.startDate, fiscalYear.endDate),
            next_5: annualReportService.getFiscalYearForYear(commitmentYear + 5, fiscalYear.startDate, fiscalYear.endDate),
            next_6: annualReportService.getFiscalYearForYear(commitmentYear + 6, fiscalYear.startDate, fiscalYear.endDate)
        }

        return {
            prev_year: commitmentYear - 1,
            year: commitmentYear,
            next_year: commitmentYear + 1,

            fiscal_years: _.transform(fiscalYears, function(res, year, key) {
                if (year) {
                    res[key] = {
                        // TODO: Should we use 'L' format (MM/DD/YYYY for eng)?
                        start: year.startDate.format('DD.MM.YYYY'),
                        end: year.endDate.format('DD.MM.YYYY')
                    }
                }
            }, {})
        }
    }.property('model.commitment.year'),

    actions: {
        didClickNext: function() {
            var self = this
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport').putSteps(this.get('model.commitment.year'), { long_term_debt: true })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
