module.exports = require('./recurring-invoice-edit').extend({
    queryParams: {
        newContactId: 'contact_id',
        fromInvoiceId: 'from_invoice_id',
        fromQuoteId: 'from_quote_id'
    },

    newContactId: null,

    fromInvoiceId: null,

    fromQuoteId: null
})
