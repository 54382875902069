var i18n = require('i18n')

module.exports = Em.Component.extend({
    name: i18n.tProperty('name'),

    description: i18n.tProperty('description'),

    checked: false,

    content: null,

    confirmation: i18n.tProperty('confirmation'),

    termsBoxHeight: 200,

    needToScroll: false,

    reachedBottom: false,

    forceCheckboxEnabled: false,

    isCheckboxDisabled: function() {
        if (this.get('needToScroll')) {
            return !this.get('reachedBottom') && !this.get('checked')
        }

        return false
    }.property('needToScroll', 'reachedBottom', 'checked'),

    adjustBoxSize: function() {
        var scrollContainer = this.$('.terms-content')
        scrollContainer.css({
            height: this.get('termsBoxHeight')
        })
    }.on('didInsertElement'),

    initializeListeners: function() {
        var self = this
        var checkbox = this.$('.terms-checkbox')

        checkbox.on('change', function(e) {
            self.sendAction('onChange', e.target.checked)
            self.set('checked', e.target.checked)
        })

        if (!self.get('needToScroll')) {
            return
        }

        var scrollContainer = this.$('.terms-content')
        var scrollContent = this.$('.scroll-content').get()[0]
        var threshold = 30

        scrollContainer.on('scroll', function() {
            var scrollHeight = scrollContainer.height()
            var scrollOffset = scrollContainer.scrollTop()
            var contentHeight = scrollContent.scrollHeight

            if (scrollHeight + scrollOffset >= contentHeight - threshold) {
                self.set('reachedBottom', true)
            }
        })
    }.on('didInsertElement'),

    actions: {
    }
})
