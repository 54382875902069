var t = require('i18n').t
var annualReportActivityOptions = require('../utils/annual-report-activity-options')
var translationExists = require('../utils/translation-exists').translationExists

module.exports = Em.Component.extend({
    classNames: ['additional-info-list-item'],

    classNameBindings: ['item.dropdownIsVisible:is-open'],

    item: null,

    organizationOwners: null,

    annualReport: Em.inject.service(),

    annualReportActivityOptions: annualReportActivityOptions,

    telephonyPeriod: null,

    telephonyPeriodMonths: null,

    spouseTaxed: false,

    profitLoss: false,

    isProfitLossDisabled: function() {
        return !this.get('profitLoss')
    }.property('profitLoss'),

    fixedCost: false,

    isFixedCostDisabled: function() {
        return !this.get('fixedCost')
    }.property('fixedCost'),

    salaryExpense: false,

    isSalaryExpenseDisabled: function() {
        return !this.get('salaryExpense')
    }.property('salaryExpense'),

    telephonyMonthlyCost: 250,

    section: function() {
        return this.get('item.firstObject.section')
    }.property('item.firstObject.section'),

    sectionNameTranslation: function() {
        return t(this.get('section'))
    }.property('section'),

    showBooleanChoices: function() {
        return !['company_activity', 'average_employees', 'general_assembly', 'years_result'].contains(this.get('section'))
    }.property('section'),

    showOnlyBooleanChoices: function() {
        return ['company_car_taxed'].contains(this.get('section'))
    }.property('section'),

    initTelephonyValues: function() {
        this.set('spouseTaxed', this.get('item.2.value'))

        var periodValue = this.get('item.1.value')

        if (!periodValue) {
            return
        }

        // periodValue is 3600 (12*300) when its for the full year
        if (periodValue === 3600) {
            this.set('telephonyPeriod', 'year')
            return
        }

        this.set('telephonyPeriod', 'month')
        this.set('telephonyPeriodMonths', periodValue / this.get('telephonyMonthlyCost'))
    },

    didInsertElement: function() {
        var item = this.get('item')
        var section = this.get('item.firstObject.section')
        item[section] = true

        if (section === 'free_private_phone_or_internet') {
            this.initTelephonyValues()
        }

        if (this.get('item.dropdownIsVisible') || this.get('item.company_activity') || this.get('item.general_assembly') || this.get('item.average_employees') || this.get('item.years_result')) {
            // Force visible dropdowns to remain visible when returning from another route
            Em.run.scheduleOnce('render', this, function() {
                this.$().addClass('disable-transition')
                this._openDropdown()
            })
        }
    },

    _openDropdown: function() {
        var contentHeight = this.$('.collapse-body-inner').css('height')

        this.set('item.dropdownIsVisible', true)
        this.$('.collapse-body').css({
            height: contentHeight
        })
    },

    _closeDropdown: function() {
        this.set('item.dropdownIsVisible', false)
        this.$().removeClass('disable-transition')
        this.$('.collapse-body').css({
            height: 0
        })
    },

    _resizeDropdown: function() {
        var contentHeight = this.$('.collapse-body-inner').outerHeight()

        this.$().addClass('disable-transition')
        this.$('.collapse-body').css({
            height: contentHeight + 'px'
        })
    },

    _resetChoiceState: function() {
        this.get('item.choices').forEach(function(value) {
            value.set('isSelected', false)
        })
    },

    ordinary1Label: function() {
        return t('annual_report.about_your_taxes.paid_tax_in_advance.ordinary_1', { year: this.get('item.year') })
    }.property('item.year'),

    voluntary1Label: function() {
        return t('annual_report.about_your_taxes.paid_tax_in_advance.voluntary_1', { year: this.get('item.year') })
    }.property('item.year'),

    ordinary2Label: function() {
        return t('annual_report.about_your_taxes.paid_tax_in_advance.ordinary_2', { year: this.get('item.year') })
    }.property('item.year'),

    voluntary2Label: function() {
        return t('annual_report.about_your_taxes.paid_tax_in_advance.voluntary_2', { year: this.get('item.year') })
    }.property('item.year'),

    voluntary3Label: function() {
        return t('annual_report.about_your_taxes.paid_tax_in_advance.voluntary_3', { year: this.get('item.year') + 1 })
    }.property('item.year'),

    leasingAmountLabel: function() {
        return t('annual_report.additional_info.has_leasing_contracts_of_financial_nature.amount', { year: this.get('item.year') + 1 })
    }.property('item.year'),

    didUpdateTelephonyCost: function(months) {
        var totalCost = months * this.get('telephonyMonthlyCost')

        var item = this.get('item')
        this.get('annualReport').putAnswer(item.year, [{
            name: 'about_your_taxes.free_private_phone_or_internet.period',
            value: totalCost
        }])
    },

    hasLink: function() {
        return translationExists(this.get('item.link'))
    }.property('item.link'),

    actions: {
        saveTelephonyPeriodMonths: function(value, context) {
            if (!value) {
                return
            }

            var period = this.get('telephonyPeriodMonths')
            if (period) {
                this.set('telephonyPeriodMonths', value)
            } else {
                this.set('telephonyPeriodMonths', 1)
            }

            if (this.get('telephonyPeriod') === 'month') {
                this.didUpdateTelephonyCost(period)
            }
        },

        didClickTelephonyYear: function() {
            this.set('telephonyPeriod', 'year')
            this.didUpdateTelephonyCost(12)
        },

        didClickTelephonyMonth: function() {
            this.set('telephonyPeriod', 'month')

            if (this.get('telephonyPeriodMonths')) {
                this.didUpdateTelephonyCost(this.get('telephonyPeriodMonths'))
            }
        },

        didSelectSpouseCheckbox: function(value) {
            this.toggleProperty('spouseTaxed')

            var item = this.get('item')
            this.get('annualReport').putAnswer(item.year, [{
                name: 'about_your_taxes.free_private_phone_or_internet.spouse_taxed',
                value: this.get('spouseTaxed')
            }])
        },

        didSelectProfitLossCheckbox: function(value) {
            this.toggleProperty('profitLoss')
        },

        didSelectFixedCostCheckbox: function(value) {
            this.toggleProperty('fixedCost')
        },

        didSelectSalaryExpenseCheckbox: function(value) {
            this.toggleProperty('salaryExpense')
        },

        expirationDateChanged: function(value) {
            var item = this.get('item')
            this.get('annualReport').putAnswer(item.year, [{
                name: 'additional_info.has_leasing_contracts_of_financial_nature_expiration_date',
                value: value.toDate()
            }])
        },

        resizeCollapse: function() {
            this._resizeDropdown()
        },

        onActivityIdChanged: function(activityId) {
            var item = this.get('item')
            item.set('isValid', true)

            var answer = {
                name: item.get('firstObject.name'),
                value: activityId
            }

            this.get('annualReport').putAnswer(item.year, [answer])
        },

        didClickChoice: function(choice) {
            var self = this
            var item = this.get('item')

            var answer = {
                name: item.get('firstObject.name'),
                value: choice.get('value')
            }

            // Finds previous selected choice
            var prevChoice = item.get('choices').find(function(value) {
                return value.get('isSelected')
            })

            // Toggle isSelected class on choices
            this._resetChoiceState()
            choice.set('isSelected', true)

            if (choice.value) {
                this._openDropdown()
            } else {
                this._closeDropdown()
            }

            item.set('isValid', true)

            this.get('annualReport').putAnswer(item.year, [answer])
                .catch(function() {
                    // Reset UI state if not answered before
                    if (self.get('item.createdTime') === null) {
                        self._resetChoiceState()
                        self._closeDropdown()
                        item.set('isValid', false)
                    // Only change UI if another choice is selected
                    } else if (choice.value !== prevChoice.value) {
                        choice.set('isSelected', false)
                        prevChoice && prevChoice.set('isSelected', true)
                    }
                })
        }
    }
})
