module.exports = Em.Component.extend({
    classNames: ['avatar'],

    src: null,

    name: null,

    fixedSrc: function() {
        return Billy.image('icons/key-gray.png')
    }.property('src')
})
