var t = require('i18n').t

module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')
        var annualReportService = this.get('annualReport')

        return Object.assign({
            items: annualReportService.getIntangibleFixedAssets(model.commitment),
            missingAccounts: annualReportService.getMissingAccounts(model.commitment, 'intangible_fixed_assets')
        }, model)
    },

    afterModel: function(model) {
        /*
        @TODO AR-2 disable fields instead of reloading
        var commitment = model.commitment
        if (['completed', 'delivered', 'received', 'reported'].indexOf(commitment.state) > -1) {
            this.replaceWith('annual_report.report_completed')
        }
        */

        return model.items.map(function(item) {
            item.title = t('annual_report.' + item.key + '.title')

            if (item.key !== 'intangible_fixed_assets.completed_development_projects') {
                item.label = t('annual_report.' + item.key + '.label')
            }
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('items', model.items)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
        controller.set('companyType', model.commitment.companyType)
        controller.set('fixedAssets', model.commitment.fixedAssetsMovements.intangible)
        controller.set('missingAccounts', model.missingAccounts)
    }
})
