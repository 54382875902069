module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    bankAccountId: function() {
        return this.get('organization.defaultBillBankAccount.id')
    }.property('organization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute(route, props)
        }
    }
})
