var t = require('i18n').t

module.exports = Ember.Component.extend({
    channel: function() {
        var channel = this.get('entry.properties.details.channel')

        switch (channel) {
        case 'EMAIL':
            return channel.charAt(0) + channel.slice(1).toLowerCase()
        }

        return channel
    }.property('entries.properties.details.channel'),
    escalationIcon: function() {
        var channel = this.get('entry.properties.details.channel')

        switch (channel) {
        case 'SMS':
            return 'icons/sms'
        default:
            return 'icons/chat'
        }
    }.property('entries.properties.details.channel'),
    escalationSubject: function() {
        var subject = this.get('entry.properties.details.messageData.subject')

        if (subject) return subject

        return t('collectai.timeline.escalated.notification')
    }.property('entry.properties.details.messageData'),
    recipient: function() {
        return this.get('entry.properties.details.messageData.to')
    }.property('entry.properties.details.messageData')
})
