var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    classNames: ['coupon-offer'],

    organizationSubscription: Ember.inject.service(),

    api: Ember.inject.service(),

    coupons: Ember.inject.service(),

    couponOffer: null,

    organization: null,

    isExpired: function() {
        return this.get('organization.lockedCode') === 'expiredTrial'
    }.property('organization.lockedCode'),

    skipText: function() {
        return (this.get('organization.isSubscriptionPlanPaid')) ? t('coupon.offer.continue_trial') : t('coupon.offer.continue_free')
    }.property('organization.isSubscriptionPlanPaid'),

    setCampaignContent: function() {
        var data = this.get('couponOffer').getContent()
        this.set('content', data)
    }.on('didInsertElement'),

    actions: {
        skip: function() {
            this.sendAction('skip')
        },

        redeemCoupon: function() {
            var self = this
            var coupons = this.get('coupons')
            var organization = this.get('organization')

            batmask.mask()

            coupons.redeemCoupon(this.get('organization.id'), this.get('couponCode'))
                .then(function() { // coupon is valid and redeemed
                    batmask.unmask()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, self.get('couponOffer.data.confirmationText'))
                    self.toggleProperty('showCoupon')

                    if (!organization.get('isSubscriptionPlanPaid')) {
                        // upgrade user to premium
                        self.get('organizationSubscription').updateOrganizationSubscription('premium', 'monthly')
                            .then(function() {
                                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.upgraded_to_premium'))
                            })
                    }

                    // Invalidate coupon offer to hide the bar
                    self.get('couponOffer').invalidate()

                    // Non-free coupon, ask for CC
                    if (self.get('organization.couponDiscountPercent') !== 100.0000) {
                        if (self.get('organization.isBillyProviderDk')) {
                            self.sendAction('goToQuickpay')
                        } else {
                            self.sendAction('goToStripe')
                        }
                    } else {
                        // Do whatever skip does
                        self.sendAction('skip')
                    }
                })
                .catch(function() { // invalid coupon
                    batmask.unmask()
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.invalid_coupon'))
                })
        }
    }
})
