var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organization}/inventory/status',

    urlParamMap: {
        organization: 'organization.id',
        product: 'product.id'
    }
})
