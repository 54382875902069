var moment = require('momentjs')
var Window = require('ember-window')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')
var t = require('i18n').t

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-pay-window'),

    showForSalesTaxReturn: function(salesTaxReturn) {
        var payment = Billy.SalesTaxPayment.createRecord({
            return: salesTaxReturn,
            entryDate: moment()
        })
        payment.resetClean()
        this.set('content', payment)
        this.show()
    },

    absAmount: function() {
        return Math.abs(this.get('content.return.report.result'))
    }.property('content.return.report.result'),

    amountSuffix: function() {
        var amount = this.get('content.return.report.result')

        if (amount > 0) {
            return '(' + t('sales_tax_pay_window.amount_suffix.positive') + ')'
        } else if (amount < 0) {
            return '(' + t('sales_tax_pay_window.amount_suffix.negative') + ')'
        } else {
            return ''
        }
    }.property('content.return.report.result'),

    accountQuery: function() {
        return {
            isPaymentEnabled: true
        }
    }.property(),

    actions: {
        submit: function() {
            var self = this
            this.get('content').save()
                .mask()
                .success(function() {
                    self.close()
                })
                .error(function(e) {
                    self.set('confirmCloseWhenDirty', false)
                    self.close()
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.VAT_SETTLEMENTS, e.message || e, NOTIFICATION_VARIANT.ERROR)
                })
        }
    }
})
