var Window = require('ember-window')
var Scope = require('../utils/scope')
var t = require('i18n').t
var batmask = require('batmask')

module.exports = Window.extend({
    classNames: ['bank-settings-modal'],

    needs: ['organization', 'user'],

    accountId: null, // from places which invoke the modal
    onRemoveAccount: null, // from places which invoke the modal

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    auth: Em.inject.service(),

    bankConnections: Em.inject.service(),

    organizationConsentEntries: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/bank-settings-modal'),

    applyModalMask: false,

    accountName: Em.computed.alias('account.name'),

    onLegalNoteConfirm: function(bankConnection, bankAccount) {
        var currentBankConnection = this.get('bankConnections').findSync(bankAccount.id)
        var bankId = currentBankConnection.session.bankId

        var organizationId = this.get('activeOrganization.id')

        if (bankConnection.type === 'nordic_api') {
            this.get('bankConnections').renewNordicApi(organizationId, bankId)
        }
    },

    updateBankConnection: function(bankIntegration, bankAccount, consentEntry) {
        var consentEntryId = consentEntry.id
        var account = Billy.Account.find(bankAccount.id)

        var currentBankConnection = this.get('bankConnections').findSync(bankAccount.id)
        var shouldUpdateBankConnection = currentBankConnection && consentEntryId && currentBankConnection.consentEntryId !== consentEntryId

        if (shouldUpdateBankConnection) {
            var changes = {
                type: currentBankConnection.type,
                consentEntryId: consentEntryId,
                accountName: currentBankConnection.accountName,
                accountNo: currentBankConnection.accountNo,
                referenceId: currentBankConnection.referenceId
            }

            this.get('bankConnections').updateBankConnection(account, changes)
        }
    },

    withAuthorization: function(onAuthorized) {
        return this.get('auth').withAuthorization(
            onAuthorized,
            Scope.BankSyncWrite,
            'bank_account_overview_connect_bank'
        )
    },

    errorHandler: function(e) {
        if (e.code === 'UNATTENDED_LOGIN_NOT_SUPPORTED_DANSKE') {
            this.set('error', t('nordic_api.setup.unattended_error_danske'))
        } else if (e.code === 'UNATTENDED_LOGIN_NOT_SUPPORTED') {
            this.set('error', t('nordic_api.setup.unattended_error'))
        } else {
            this.set('error', e.message)
        }
    },

    customActions: {
        closeEmber: function(e) {
            this.close()
        },

        renewConnection: function(event) {
            var bankConnection = event.detail.bankConnection
            var bankAccount = event.detail.bankAccount

            this.withAuthorization(function() {
                var self = this

                this.container.lookup('component:bank-legal-note-modal')
                    .set('organizationId', self.get('activeOrganization.id'))
                    .set('consentContext', self.get('organizationConsentEntries.context.BANK_INTEGRATION'))
                    .set('consentContextVersion', self.get('organizationConsentEntries.context.BANK_INTEGRATION_VERSION'))
                    .set('onConfirm', function(consentEntry) {
                        return self.get('bankConnections').ensureLoaded()
                            .then(function() {
                                self.updateBankConnection(bankConnection, bankAccount, consentEntry)
                                self.onLegalNoteConfirm(bankConnection, bankAccount)
                            })
                    })
                    .show()
            }.bind(this))
        },

        removeConnection: function(event) {
            var bankAccount = event.detail.bankAccount
            var account = Billy.Account.find(bankAccount.id)

            var self = this
            this.container.lookup('util:dialog').confirmWarning(
                null,
                t('nordic_api.account_connected.confirm_disconnect_info'),
                t('nordic_api.account_connected.confirm_disconnect_yes')
            )
                .then(function() {
                    batmask.maskDelayed()
                    return self.get('bankConnections').disconnectBankConnection(account)
                })
                .then(function() {
                    if (typeof self.onRemoveAccount === 'function') {
                        self.onRemoveAccount()
                    }
                    self.close()
                }, this.errorHandler.bind(this))
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
