var t = require('i18n').t

module.exports = Em.Component.extend({
    layoutName: 'bank-import/column-selector',

    value: null,

    error: false,

    didChange: 'columnDidChange',

    selectionDidChange: function() {
        this.sendAction('didChange', {
            value: this.get('value')
        })
    }.observes('value'),

    options: function() {
        return Em.A([
            Em.Object.create({
                value: 'date',
                name: t('bank_sync.entry_date')
            }),
            Em.Object.create({
                value: 'description',
                name: t('bank_sync.description')
            }),
            Em.Object.create({
                value: 'amount',
                name: t('bank_sync.amount')
            })
        ])
    }.property()
})
