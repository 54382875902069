var t = require('i18n').t
var ListModelController = require('billy-webapp/ui/list/model-controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var Column = require('billy-webapp/ui/list/columns/column')
var NumberColumn = require('billy-webapp/ui/list/columns/number')
var ListAction = require('billy-webapp/ui/list/action')

module.exports = ListModelController.extend({
    needs: ['user'],

    query: function() {
        return {
            organization: this.get('controllers.user.activeOrganization'),
            isNew: false
        }
    }.property('controllers.user.activeOrganization'),

    type: Billy.SalesTaxAccount,

    sortProperty: 'priority',

    itemControllerClass: ListItemController.extend({
        localizedType: function() {
            return t('sales_tax_account.type.' + this.get('type'))
        }.property('type')
    }),

    columns: [
        Column.create({
            header: t('name'),
            name: 'account.name',
            flex: 1
        }),
        Column.create({
            header: t('type'),
            name: 'localizedType',
            flex: 1
        }),
        NumberColumn.create({
            header: t('priority'),
            name: 'priority',
            width: 100
        })
    ],

    listActions: [
        ListAction.create({
            icon: 'icons/pencil',
            tip: t('edit'),
            click: 'editTaxAccount'
        }),
        ListAction.create({
            icon: 'icons/trashcan',
            tip: t('delete'),
            click: 'deleteTaxAccount'
        })
    ]
})
