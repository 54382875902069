module.exports = Em.Mixin.create({
    // Must be set or aliased
    account: null,

    balance: null,

    balanceIsLoaded: false,

    balanceIsNegative: Em.computed.lt('balance', 0),

    balanceIsPositive: Em.computed.gt('balance', 0),

    loadBalance: function() {
        var self = this
        var api = this.container.lookup('api:billy')
        var account = this.get('account')

        self.set('balance', null)
            .set('balanceIsLoaded', false)

        return api.get('/accounts/' + account.get('id') + '/balance?currencyId=' + account.get('currency.id'))
            .then(function(payload) {
                self.set('balance', payload.balance)
                    .set('balanceIsLoaded', true)
            })
    }
})
