var tracking = require('../utils/tracking')

module.exports = Em.Route.extend({
    annualReport: Em.inject.service(),

    /**
     * @param {EmberTransition} transition
     * @returns {void}
     */
    beforeModel: function(transition) {
        var activeStep = transition.targetName.split('.').pop()
        if (activeStep !== 'index') {
            this.get('annualReport').set('activeStep', activeStep)
        }
    },

    actions: {
        // This is really, really important. We must invalidate every time a transition will happen (BEFORE it
        // happens, not during or after) so that the model is refreshed and the lists/content is updated. Remove
        // this, and you'll see a lot of empty content which will be fixed when refreshing the page.
        willTransition: function(transition) {
            this.send('invalidateModel')

            // Annual Reports have their separate Hubspot chat widget,
            // so we need to reload when exiting from any of AR pages to any other page
            if (!transition.targetName.includes('annual_report')) {
                tracking.refreshHubspotWidget()
            }
        }
    }
})
