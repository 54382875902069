module.exports = Em.Route.extend({
    queryParams: {
        sort_direction: {
            replace: true
        },
        period: {
            replace: true
        },
        q: {
            replace: true
        },
        bankConnectionSuccess: {
            replace: true
        },
        bankConnectionError: {
            replace: true
        }
    },

    setupController: function(controller) {
        controller.loadRecords()
        controller.loadBalance()
    },

    resetController: function(controller) {
        controller.set('bankConnectionError', undefined)
        controller.set('bankConnectionSuccess', undefined)
    }
})
