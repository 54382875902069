module.exports = {
    APIPartner: 'apipartner',
    Archive: 'archive',
    Basic: 'basic',
    BasicPaid: 'basic_paid',
    Complete: 'complete',
    Employee: 'employee',
    Free: 'free',
    FriendsofBilly: 'friendofbilly',
    InternalLicense: 'internallicense',
    NonProfit: 'nonprofit',
    Plus: 'plus',
    Premium: 'premium',
    Pro: 'pro',
    SpecialFree: 'specialfree'
}
