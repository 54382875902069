var ListController = require('billy-webapp/ui/list/controller')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')

module.exports = ListController.extend(ReactDirtyRouteMixin, {
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    categoryKey: null,
    contactId: null,
    contactName: null,
    initialRoute: null,
    page: null,
    pageSize: null,
    period: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,
    status: null,

    queryParams: {
        categoryKey: 'category',
        contactId: 'contactId',
        contactName: 'contactName',
        initialRoute: 'initialRoute',
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        status: 'status'
    },

    isSourceZervant: undefined,

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, props)
        }
    }
})
