var Window = require('ember-window')
var t = require('i18n').t

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-account-editor'),

    accountNatures: function() {
        return [Billy.AccountNature.find('liability')]
    }.property(),

    typeOptions: function() {
        return [
            Em.O({
                value: 'tax',
                name: t('sales_tax_account.type.tax')
            }),
            Em.O({
                value: 'deduction',
                name: t('sales_tax_account.type.deduction')
            })
        ]
    }.property(),

    actions: {
        save: function() {
            var self = this
            this.get('content')
                .save()
                .mask()
                .on('success', function() {
                    self.close()
                })
        }
    }
})
