var moment = require('momentjs')

moment.fn.isAfterOrSame = function(input, units) {
    units = typeof units !== 'undefined' ? units : 'millisecond'
    return +this.clone().startOf(units) >= +moment(input).startOf(units)
}

moment.fn.isBeforeOrSame = function(input, units) {
    units = typeof units !== 'undefined' ? units : 'millisecond'
    return +this.clone().startOf(units) <= +moment(input).startOf(units)
}

var unpack = function(o) {
    return o instanceof Em.Moment ? o.get('content') : o
}

var props = {
    format: function(inputString) {
        return this.get('content').format(inputString)
    },
    startOf: function(units) {
        return Em.moment(this.get('content').startOf(units))
    },
    endOf: function(units) {
        return Em.moment(this.get('content').endOf(units))
    },
    isSame: function(input, units) {
        return this.get('content').isSame(unpack(input), units)
    },
    isBefore: function(input, units) {
        return this.get('content').isBefore(unpack(input), units)
    },
    isBeforeOrSame: function(input, units) {
        return this.get('content').isBeforeOrSame(unpack(input), units)
    },
    isAfter: function(input, units) {
        return this.get('content').isAfter(unpack(input), units)
    },
    isAfterOrSame: function(input, units) {
        return this.get('content').isAfterOrSame(unpack(input), units)
    },
    add: function(input, val) {
        return Em.moment(this.get('content').add(input, val))
    },
    subtract: function(input, val) {
        return Em.moment(this.get('content').subtract(input, val))
    },
    toString: function() {
        return '<Ember.Moment:' + this.format('YYYY-MM-DDTHH:mm:ss') + '>'
    }
};

// Format properties
[
    'MMMM YYYY'
].forEach(function(format) {
    props[format] = Em.computed(function() {
        return this.format(format)
    })
})

Ember.Moment = Ember.Object.extend(props)

Ember.moment = function(content) {
    if (Ember.Moment.detectInstance(content)) {
        content = content.get('content')
    } else {
        content = moment.apply(moment, arguments)
    }
    return Ember.Moment.create({
        content: content
    })
}
