module.exports = Em.Component.extend({
    tagName: 'tr',

    classNameBindings: ['hasError:invalid'],

    // Instantianted by the template component
    line: null,

    hasError: Ember.computed.alias('line.hasError')
})
