var t = require('i18n').t
var moment = require('momentjs')

module.exports = Em.Component.extend({
    layoutName: 'components/bank-imports-archive-item',

    classNames: ['bank-imports-archive-item'],

    classNameBindings: ['open'],

    api: Em.inject.service(),

    open: false,

    importDateFormatted: function() {
        var date = this.get('group.createdTime')
        return t(
            'bank_sync.imports_archive.imported_on',
            { date: moment(date).format('DD. MMM YYYY') }
        )
    }.property('group'),

    importedPeriod: function() {
        var earliestLine = moment(this.get('group.earliestLineEntryDate')).format('DD. MMMM YYYY')
        var lastLine = moment(this.get('group.lastLineEntryDate')).format('DD. MMMM YYYY')
        return earliestLine + ' - ' + lastLine
    }.property('group.{earliestLineEntryDate,lastLineEntryDate}'),

    actions: {
        toggle: function() {
            this.set('open', !this.get('open'))
        },

        delete: function() {
            var self = this
            this.container.lookup('component:bank-imports-archive-item-confirm')
                .set('group', this.get('group'))
                .on('didDelete', function() {
                    self.sendAction('didDelete')
                })
                .show()
        }
    }
})
