var t = require('i18n').t
var f = require('float')

Billy.OrganizationInvoice.reopen({
    grossAmount: function() {
        return f.round(this.get('amount') + this.get('tax'))
    }.property('amount', 'tax'),

    stateText: function() {
        switch (this.get('state')) {
        case 'draft':
            return t('organization_invoice.state.draft')
        case 'unpaid':
        case 'notpaid':
        case 'bankUnpaid':
        case 'failed':
        case 'cancelled':
        case 'declined':
        case 'pendingpayment':
        case 'pendingresponse':
        case 'pendingresponsecallback':
        case 'processingwebhook':
        case 'pendingretry':
        case 'dunning':
            return t('organization_invoice.state.unpaid')
        case 'paid':
            return t('organization_invoice.state.paid')
        case 'refunded':
            return t('organization_invoice.state.refunded')
        }
    }.property('state')
})
