module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/bank-line-group',

    classNames: ['bank-sync-v2-bank-line-group', 'match-item'],

    selectMode: false,

    lines: null,

    actions: {
        toggledLineSelection: function(line) {
            this.sendAction('toggledLineSelection', line)
        }
    }
})
