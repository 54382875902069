module.exports = Em.Route.extend({
    beforeModel: function() {
        var bill = this.modelFor('bill')
        var billId = bill.id

        this.transitionTo('bills', {
            queryParams: {
                initialRoute: '/' + billId
            }
        })
    }
})
