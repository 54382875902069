module.exports = Em.Component.extend({
    classNames: ['dashboard-widget-framed', 'annual-report-banner'],

    classNameBindings: ['simple', 'showProgress', 'highlighted'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    annualReport: Em.inject.service(),

    isLoading: true,

    simple: false,

    showProgress: false,

    highlighted: false,

    widgetSteps: null,

    currentStep: null,

    annualReportPrice: null,

    init: function() {
        this._super()
        var self = this
        var annualReportService = this.get('annualReport')
        var lastYear = parseInt(moment().format('YYYY'), 10) - 1

        Em.RSVP.all([
            annualReportService.getYears(),
            annualReportService.calculatePrice()
        ])
            .then(function(values) {
                var model = values[0]
                var price = values[1].annual

                var commitment = _.find(model.years, { year: lastYear })

                if (!commitment) return null

                self.set('showProgress', commitment.state === 'active')
                self.set('highlighted', !self.get('showProgress'))
                self.set('annualReportPrice', price)

                return commitment.state === 'inactive'
                    ? null
                    : annualReportService.getCommitment(commitment.id)
            })
            .then(function(commitment) {
                var data = commitment ? commitment.data : {}
                var widgetSteps = annualReportService.getSteps(data).map(function(step) {
                    // The model isn't available on the dashboard, so step routing has to be manually provided with the year
                    step.doTransition = [step.completeRoute, lastYear]
                    return step
                })

                self.set('widgetSteps', widgetSteps)
                self.set('currentStep', annualReportService.getCurrentStep(data))
                self.set('isLoading', false)
                self.sendAction('didLoad', self.get('showProgress'))
            })
    },

    formattedAnnualReportPrice: function() {
        return Billy.money(this.get('annualReportPrice'), 'DKK', '0,0.[00]')
    }.property('annualReportPrice'),

    actions: {
        remove: function() {
            this.sendAction('didDismiss')
            this.destroy()
        },

        continueReport: function() {
            this.sendAction('didContinue')
            this.container.lookup('router:main').transitionTo('annual_reports')
        }
    }
})
