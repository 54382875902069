var Window = require('ember-window')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({

    api: Ember.inject.service(),

    layout: require('../templates/components/send-tax-modal'),

    classNames: ['send-tax-modal'],

    width: 800,

    period: null,

    salesTaxReturnId: null,

    onTaxApproved: null,

    skatTransactionId: null,

    approvalCheckerInterval: null,

    displayErrorNotification: function(message) {
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, message)
    },

    displayErrorCodeNotification: function(errorCode) {
        var errorMessage
        switch (errorCode) {
        case '4802':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        case '4804':
            errorMessage = t('sales_tax_returns.request_error.code_' + errorCode)
            break
        default:
            errorMessage = t('sales_tax_returns.request_error.code_default')
            break
        }
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.SALES_TAX_SEND, errorMessage)
    },

    initComponents: function() {
        var self = this
        var approvalChecker = setInterval(function() {
            self.get('api').request('GET', '/integrations/skat/organizations/' + self.get('organizationId') + '/status?registrationNumber=' + self.get('registrationNo') + '&transactionId=' + self.get('skatTransactionId'))
                .then(function(res) {
                    if (res.is_approved) {
                        self.onTaxApproved()
                        clearInterval(approvalChecker)
                        self.destroy()
                    }
                })
                .catch(function(err) {
                    clearInterval(approvalChecker)

                    if (err.error.code) {
                        self.displayErrorCodeNotification(err.error.code)
                    } else {
                        self.displayErrorNotification(err.message || 'Unknown error')
                    }

                    self.destroy()
                })
        }, 3000)

        this.set('approvalCheckerInterval', approvalChecker)
    }.on('didInsertElement'),

    willDestroyElement: function() {
        var approvalChecker = this.get('approvalCheckerInterval')

        if (approvalChecker) {
            clearInterval(approvalChecker)
        }

        this._super()
    }
})
