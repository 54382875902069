module.exports = Em.Component.extend({
    classNames: ['ar-employee-fields'],

    annualReport: Em.inject.service(),

    item: null,

    thisYearDescription: function() {
        return this.get('item.year')
    }.property('item.year'),

    lastYearDescription: function() {
        return this.get('item.year') - 1
    }.property('item.year'),

    lastYearFullTime: function() {
        return this.get('item').findBy('name', 'additional_info.full_time_employees.last_year')
    }.property('item.@each'),

    thisYearFullTime: function() {
        return this.get('item').findBy('name', 'additional_info.full_time_employees.this_year')
    }.property('item.@each'),

    lastYearPartTime: function() {
        return this.get('item').findBy('name', 'additional_info.part_time_employees.last_year')
    }.property('item.@each'),

    thisYearPartTime: function() {
        return this.get('item').findBy('name', 'additional_info.part_time_employees.this_year')
    }.property('item.@each'),

    actions: {
        saveValue: function(value, context) {
            if (context.error) {
                return
            }

            var property = {
                name: context.name,
                value: value
            }

            this.get('annualReport').putAnswer(this.get('item.year'), [property])
        }
    }
})
