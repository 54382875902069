module.exports = Em.Component.extend({
    layoutName: 'components/progress-circle',

    classNames: ['progress-circle'],

    progress: null,

    /**
     * Should be either `dark`, `light`, `red` or `green`.
     */
    color: 'dark',

    ringSize: 2,

    setup: function() {
        this._circle = createCircle(
            this.get('element'),
            this.get('color'),
            this.get('ringSize')
        )
        this._redraw()
    }.on('didInsertElement'),

    progressDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this._redraw)
    }.observes('progress'),

    _redraw: function() {
        this._circle.redraw(this.get('progress') || 0)
    }
})

function createCircle(parentEl, color, ringSize) {
    var ret = {}
    var arcTween
    var arc
    var pie
    var circle
    var g

    arcTween = function(a) {
        var i = d3.interpolate(this._current, a)
        this._current = i(0)
        return function(t) {
            return arc(i(t))
        }
    }

    circle = d3.select(parentEl).append('svg')

    var diameter = circle.style('width').replace('px', '')
    var radius = diameter / 2

    g = circle.append('g')
        .attr('transform', 'translate(' + radius + ',' + radius + ')')

    arc = d3.svg.arc()
        .outerRadius(radius)
        .innerRadius(radius - ringSize)

    pie = d3.layout.pie()
        .sort(null)

    ret.redraw = function(progress) {
        var pieData = pie([progress, 1 - progress])

        var path = g.selectAll('path')
            .data(pieData)

        path
            .transition()
            .duration(100)
            .attrTween('d', arcTween)

        path.enter()
            .append('path')
            .attr('class', function(d, index) {
                return index === 0 ? 'segment-completed-' + color : 'segment-remaining-' + color
            })
            .attr('d', arc)
            .each(function(d) { this._current = d })
    }

    return ret
}
