var functionProxy = require('function-proxy')
var highestZIndex = require('highest-z-index')
var svg = require('ember-svg').get
var i18n = require('i18n')
var tProperty = i18n.tProperty
var t = i18n.t

module.exports = Em.Component.extend({
    dropTip: tProperty('dropTip', t('file_drop_area.drop_tip')),

    dropSelector: null,

    overlaySelector: null,

    // large, small or tiny
    size: 'large',

    setupDropTarget: function() {
        this.addOrRemoveDropTargetEvents('on')
    }.on('didInsertElement'),

    willDestroyElement: function() {
        var overlay = this.get('overlay')
        if (overlay) {
            overlay.remove()
        }
        this.addOrRemoveDropTargetEvents('off')
    },

    addOrRemoveDropTargetEvents: function(method) {
        var dropTarget = this.getDropTarget()
        var body = $(this.container.lookup('application:main').get('rootElement'))
        body[method]('dragenter', functionProxy(this.onBodyDragEnter, this))
        body[method]('dragleave', functionProxy(this.onBodyDragLeave, this))
        body[method]('dragover', functionProxy(this.onBodyDragOver, this))
        body[method]('drop', functionProxy(this.onBodyDrop, this))
        dropTarget[method]('dragover', functionProxy(this.onDragOver, this))
        dropTarget[method]('dragleave', functionProxy(this.onDragLeave, this))
        dropTarget[method]('drop', functionProxy(this.onDrop, this))
    },

    onBodyDragEnter: function() {
        this.ignoreNextBodyDragLeave = true
        this.showOverlay()
        return false
    },

    onBodyDragLeave: function() {
        if (this.ignoreNextBodyDragLeave) {
            this.ignoreNextBodyDragLeave = false
        } else {
            this.hideOverlay()
        }
    },

    onBodyDragOver: function(e) {
        this.stopEvent(e)
        return false
    },

    onBodyDrop: function(e) {
        this.hideOverlay()
        this.stopEvent(e)
    },

    onDragOver: function() {
        this.getOverlay().addClass('v2-drop-overlay-hover')
    },

    onDragLeave: function() {
        this.getOverlay().removeClass('v2-drop-overlay-hover')
    },

    onDrop: function(e) {
        var self = this
        Em.run(function() {
            var files = e.dataTransfer.files
            self.sendAction('drop', files)
        })
    },

    showOverlay: function() {
        var target = this.getOverlayTarget()
        var overlay = this.getOverlay()
        overlay.css('display', 'block')
        overlay.css('z-index', 1 + highestZIndex(overlay.siblings()))
        overlay.height(target.outerHeight())
        overlay.width(target.outerWidth())
        overlay.position({
            my: 'top left',
            at: 'top left',
            of: target
        })
    },

    hideOverlay: function() {
        var overlay = this.getOverlay()
        overlay.css('display', 'none')
    },

    stopEvent: function(e) {
        e.stopPropagation()
        e.preventDefault()
    },

    getDropTarget: function() {
        var selector = this.get('dropSelector')
        return this.findBySelector(selector, 'drop target')
    },

    getOverlayTarget: function() {
        var selector = this.get('overlaySelector') || this.get('dropSelector')
        return this.findBySelector(selector, 'drop overlay target')
    },

    findBySelector: function(selector, what) {
        var el = this.$().closest(selector)
        if (el.length) {
            return el
        }
        el = $(selector)
        if (el.length) {
            return el
        }
        throw new Error('No file-uploader ' + what + ' was found by the selector ' + selector)
    },

    getOverlay: function() {
        var overlay = this.get('overlay')
        if (!overlay) {
            var dropTarget = this.getOverlayTarget()
            var size = this.get('size')
            var iconSize = ['small', 'large'].indexOf(size) === -1 ? 'small' : size
            dropTarget.append(
                '<div class="v2-drop-overlay v2-drop-overlay-' + size + '">' +
                    '<div class="v2-drop-overlay-content">' +
                        '<div class="v2-drop-overlay-tip">' +
                            '<div class="v2-drop-overlay-icon">' + svg('icons/arrow-down-' + iconSize) + '</div>' +
                            '<div class="v2-drop-overlay-text">' + this.get('dropTip') + '</div>' +
                        '</div>' +
                        '<div class="v2-file-drop-area-escape-tip">' + t('file_drop_area.escape_tip') + '</div>' +
                    '</div>' +
                '</div>'
            )
            overlay = dropTarget.find(' > .v2-drop-overlay')
            this.set('overlay', overlay)
        }
        return overlay
    }
})
