var t = require('i18n').t
var Window = require('ember-window')
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/app-store-account-installer'),

    callback: null,

    appStoreItem: null,

    error: null,

    needsRegistrationNo: function() {
        return !this.get('appStoreItem.organization.registrationNo')
    }.property('appStoreItem.organization'),

    needsOrganizationEmail: function() {
        return !this.get('appStoreItem.organization.email')
    }.property('appStoreItem.organization'),

    needsAnything: function() {
        return this.get('needsRegistrationNo') || this.get('needsOrganizationEmail')
    }.property('needsRegistrationNo', 'needsOrganizationEmail'),

    installAccount: function() {
        var organizationId = this.get('appStoreItem.organization.id')
        var accountName = this.get('appStoreItem.app.installAccount')
        var host = ENV.isTest ? '' : ENV.newApiUrl
        var url = host + '/organizations/' + organizationId + '/' + accountName
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return Em.RSVP.resolve($.ajax({
            type: 'POST',
            url: url,
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        }))
    },

    isValid: function() {
        var isValid = true
        var organization = this.get('appStoreItem.organization')

        if (Em.isEmpty(organization.get('registrationNo'))) {
            organization.set('errors.registrationNo', t('required_field'))
            isValid = false
        }

        if (Em.isEmpty(organization.get('email'))) {
            organization.set('errors.email', t('required_field'))
            isValid = false
        }

        return isValid
    },

    saveOrganization: function() {
        var organization = this.get('appStoreItem.organization')
        return Em.RSVP.all([
            organization.save().promise
        ])
    },

    actions: {
        install: function() {
            var self = this
            var callback = this.get('callback')

            this.set('error', null)
            if (!this.isValid()) {
                return
            }
            batmask.maskDelayed()
            this.saveOrganization()
                .then(this.installAccount.bind(this))
                .then(function() {
                    self.set('appStoreItem.installed', true)
                    self.close()
                    var message = self.get('appStoreItem.app.installedMessage')
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.APP_STORE_APP_INSTALL, message)

                    if (typeof callback === 'function') {
                        callback()
                    }
                }, function(xhr) {
                    var error
                    if (xhr.status === 400) {
                        error = Em.get(xhr, 'responseJSON.errors.0.detail')
                    }
                    if (!error) {
                        error = t('util.request.default_error')
                    }
                    self.set('error', error)
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
