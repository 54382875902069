var originalEmberCompare = Ember.compare
Ember.compare = function(v, w) {
    if (moment.isMoment(v) && moment.isMoment(w)) {
        return v.valueOf() - w.valueOf()
    }
    return originalEmberCompare.apply(this, arguments)
}

Ember.SortableMixin.reopen({
    sortFunction: Ember.compare
})
