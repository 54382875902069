var Popover = require('ember-popover')
var _ = require('lodash')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/bookkeeping-hero-popover'),

    classNames: ['bookkeeping-hero-popover super-field-selector'],

    selectedTag: null,

    suggestions: [],

    maxHeight: 300,

    minWidth: 400,

    matchWidth: false,

    highlightedElementIndex: null,

    highlightSuggestion: function() {
        var self = this
        this.set('suggestions', this.get('suggestions').map(function(suggestion, index) {
            return Object.assign(suggestion, { isHighlighted: index === self.get('highlightedElementIndex'), index: index })
        }))
    }.observes('highlightedElementIndex'),

    scrollToHighlightedElement: function() {
        var index = this.get('highlightedElementIndex')
        var highlightedElement = this.element.querySelector('[data-index=\'' + index + '\'')

        if (!highlightedElement) {
            return
        }

        var containerHeight = this.element.offsetHeight
        var containerScroll = this.element.scrollTop

        if (highlightedElement.offsetTop > (containerHeight + containerScroll) || highlightedElement.offsetTop - containerScroll - 32 < 0) {
            this.element.scrollTop = highlightedElement.offsetTop - 32
        }
    },

    highlightAnswers: function() {
        if (!this.get('selectedTag')) {
            return
        }

        var self = this
        this.set('selectedTag.question.responses', this.get('selectedTag.question.responses').map(function(response, index) {
            return Object.assign(response, { isHighlighted: index === self.get('highlightedElementIndex'), index: index })
        }))
    }.observes('selectedTag', 'highlightedElementIndex'),

    onArrowLeft: function(event) {
        event.preventDefault()

        var index = this.get('highlightedElementIndex')
        var tag = this.get('selectedTag')

        if (_.isNumber(index) >= 0 && tag) {
            this.selectTag(null)
            this.set('highlightedElementIndex', 0)
        }
    },

    onArrowDown: function(event) {
        event.preventDefault()

        var index = this.get('highlightedElementIndex')

        var items = this.get('selectedTag') === null || !this.get('selectedTag') ? this.get('suggestions') : this.get('selectedTag.question.responses')

        if (this.get('highlightedElementIndex') === null) {
            this.set('highlightedElementIndex', 0)
            this.trigger('inputBlur')
            return
        }

        this.set('highlightedElementIndex', Math.min(index + 1, items.length - 1))
        this.scrollToHighlightedElement()
    },

    onArrowUp: function(event) {
        event.preventDefault()
        var index = this.get('highlightedElementIndex')

        if (index === null) {
            return
        }

        if (index === 0) {
            this.set('highlightedElementIndex', null)
            this.trigger('inputFocus')
            return
        }

        this.set('highlightedElementIndex', Math.max(index - 1, 0))
        this.scrollToHighlightedElement()
    },

    onEnter: function(event) {
        event.preventDefault()

        var highlightedElementIndex = this.get('highlightedElementIndex')

        if (highlightedElementIndex === null) {
            return
        }

        if (this.get('selectedTag') === null) {
            this.set('highlightedElementIndex', 0)
            this.selectTag(this.get('suggestions')[highlightedElementIndex])
            return
        }

        this.set('highlightedElementIndex', 0)
        this.selectResponse(this.get('selectedTag.question.responses')[highlightedElementIndex])
        this.trigger('grossAmountInputFocus')
    },

    onKeyDown: function(event) {
        var self = this

        switch (event.keyCode) {
        case 27: // Esc
            self.trigger('close')
            break

        case 37: // ArrowLeft
            self.onArrowLeft(event)
            break

        case 40: // ArrowDown
            self.onArrowDown(event)
            break

        case 38: // ArrowUp
            self.onArrowUp(event)
            break

        case 9: // Tab
        case 13: // Enter
        case 39: // Right arrow
            self.onEnter(event)
            break

        default:
            break
        }
    },

    onKeyDownBound: null,

    initEvents: function() {
        // need to bind at every reload to make sure that context is up to date
        var onKeyDownBound = this.onKeyDown.bind(this)
        this.set('onKeyDownBound', onKeyDownBound)

        document.addEventListener('keydown', this.get('onKeyDownBound'))
    }.on('didInsertElement'),

    destroyEvents: function() {
        document.removeEventListener('keydown', this.get('onKeyDownBound'))
        this.set('onKeyDownBound', null)
    }.on('willDestroyElement'),

    adjustScroll: function() {
        this.get('element').scrollTop = 0
    },

    selectTag: function(tag) {
        this.set('selectedTag', tag)
        this.adjustScroll()

        if (tag === null) {
            this.set('highlightedElementIndex', null)
            return
        }

        this.trigger('applyTagToDescription', tag.name)
    },

    selectResponse: function(response) {
        this.trigger('didSelect', {
            answer: response,
            tag: this.get('selectedTag')
        })
    },

    actions: {
        setSelectedTag: function(tag) {
            this.selectTag(tag)
        },

        setSelectedResponse: function(response) {
            this.selectResponse(response)
        }
    }
})
