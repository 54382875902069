var t = require('i18n').t

module.exports = Em.Component.extend({
    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    draftBills: [],

    didInsertElement: function() {
        this.loadData()
    },

    willDestroyElement: function() {
        this.cancelPoll()
    },

    loadData: function() {
        var self = this
        var organizationId = this.get('organization.id')
        var bills = Billy.Bill.findByQuery({
            state: 'draft',
            organizationId: organizationId
        })

        bills.promise.then(function(bills) {
            self.set('draftBills', bills)
            self.schedulePoll()
        })
    },

    isDraftBillsLoaded: function() {
        return this.get('draftBills.length') > 0
    }.property('draftBills.length'),

    manualDraftBills: function() {
        return this.get('draftBills').filterBy('scanType', 'none')
    }.property('draftBills.@each'),

    automatedDraftBills: function() {
        return this.get('draftBills').filterBy('scanType', 'automated')
    }.property('draftBills.@each'),

    validatedDraftBills: function() {
        return this.get('draftBills').filterBy('scanType', 'validated')
    }.property('draftBills.@each'),

    manualDraftsLabel: function() {
        return t('bills.draft_overview.list.category.none.' + this.get('organization.subscriptionPlan'))
    }.property('organization.{subscriptionPlan}'),

    totalDraftsLabel: function() {
        return t('bills.draft_overview.list.category.all.' + this.get('organization.subscriptionPlan'))
    }.property('organization.{subscriptionPlan}'),

    cancelPoll: function() {
        Em.run.cancel(this._pollTimer)
    },

    schedulePoll: function() {
        this.cancelPoll()
        this._pollTimer = Em.run.later(this, this.loadData, 30 * 1000)
    }
})
