var SubjectWrapper = require('../utils/subject-wrapper')

module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/match-subject-group',

    classNames: ['bank-sync-v2-match-subject-group', 'match-item'],

    subjectAssociations: Em.A(),

    subjects: function() {
        var subjects = this.get('subjectAssociations').mapBy('subject')

        return subjects.map(function(subject) {
            return SubjectWrapper.create({ subject: subject })
        })
    }.property('subjectAssociations'),

    actions: {}
})
