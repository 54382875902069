var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRoute, {
    queryParams: {
        fromRenewing: {
            replace: true
        }
    },

    scopes: [Scope.BankReconciliationRead],

    bankConnections: Em.inject.service(),

    model: function() {
        return this.modelFor('bank_account')
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        this.controllerFor('bank-sync-matches').load()
        this.controllerFor('bank-sync-possible-subjects').load()
    }
})
