var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    classNames: ['campaign'],

    api: Ember.inject.service(),

    organizationSubscription: Ember.inject.service(),

    subscription: Ember.inject.service(),

    campaign: null,

    organization: null,

    showTrialOption: false,

    setCampaignContent: function() {
        var data = this.get('campaign').getContent()
        this.set('content', data.variables)
    }.on('didInsertElement'),

    changePlan: function(subscriptionPlan) {
        var self = this
        var organization = this.get('organization')

        batmask.maskDelayed()
        this.get('api').request('PUT', '/v2/organizations/' + organization.get('id') + '/subscription', {
            payload: {
                subscription: {
                    subscriptionPlan: subscriptionPlan
                }
            }
        })
            .then(function(payload) {
                if (payload.organizations) {
                    Billy.Organization.loadMany(payload.organizations)
                }
                if (organization.get('lockedCode') === 'expiredTrial') {
                    self.container.lookup('router:main').replaceWith('organization.locked', organization)
                } else {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.PLAN_CHANGE, t('premium_feature.upgraded'))
                    self.sendAction('upgraded')
                    self.container.lookup('router:main').replaceWith('/')
                }
            }, function(e) {
                if (e.type === 'API_ERROR') {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.PLAN_CHANGE, e.message)
                } else {
                    throw e
                }
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    acceptCampaign: function() {
        var subscription = this.get('subscription')
        this.get('campaign').accept()
            .then(function() {
                return subscription.subscribeCard()
            })
    },

    actions: {
        clickAccept: function() {
            var self = this
            var isBasic = !this.get('organization.isSubscriptionPlanPaid')

            if (isBasic) {
                // upgrade to premium first then accept the campaign
                this.get('organizationSubscription').updateOrganizationSubscription('premium', 'monthly')
                    .then(function() {
                        self.acceptCampaign()
                    })
            } else {
                this.acceptCampaign()
            }
        },

        clickDecline: function() {
            batmask.maskDelayed()
            this.get('campaign').decline()
                .then(function() {
                    // consider refreshing the route only
                    window.location.reload(true)
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        clickTryPremium: function() {
            this.changePlan('premium')
        }
    }
})
