var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Mixin.create({
    needs: ['user'],

    model: Em.computed.alias('controllers.user.model'),
    isClean: Em.computed.not('isDirty'),

    actions: {
        save: function() {
            var self = this
            this.get('model')
                .save()
                .on('success', function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.PRIVACY_SETTINGS_UPDATE, t('privacy.settings_updated_successfully'))
                })
        }
    },

    customActions: function() {
        return this.get('_customActions')
    }.property('_customActions')
})
