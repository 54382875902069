var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var Column = require('billy-webapp/ui/list/columns/column')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var FiscalYear = require('../utils/accounting/fiscal-year')

module.exports = ListController.extend({
    needs: ['daybookIndex'],

    daybook: Em.computed.alias('controllers.daybookIndex.daybook'),

    transactions: Em.computed.alias('controllers.daybookIndex.content'),

    balanceAccounts: Em.computed.alias('daybook.balanceAccounts'),

    loadRecords: function() {
        if (this.get('isDestroying')) {
            return
        }
        var self = this
        this.set('isLoaded', false)
        this.get('balanceAccounts').promise
            .then(function(balanceAccounts) {
                var records = []
                balanceAccounts.forEach(function(item) {
                    var account = item.get('account')
                    if (!account) {
                        return
                    }
                    records.pushObject(BalanceAccountRecord.create({
                        container: self.container,
                        account: account
                    }))
                })
                self.set('records', records)
                    .set('isLoaded', true)
                self.loadBalances()
                self.recalcMovements()
            })
    },

    loadBalances: function() {
        if (this.get('isDestroying')) {
            return
        }
        this.get('records').forEach(function(record) {
            record.loadBalance()
        })
    },

    balanceAccountsDidChange: function() {
        Em.run.once(this, this.loadRecords)
    }.observes('balanceAccounts.@each.account'),

    records: null,

    recalcMovements: function() {
        var records = this.get('records')
        var transactions = this.get('transactions')
        if (!transactions || !records) {
            return
        }
        records.forEach(function(record) {
            var account = record.get('account')
            var accountId = account.get('id')
            var movement = new Billy.SideAmount()
            transactions.forEach(function(transaction) {
                var accountMovements = transaction.get('accountMovements')
                if (accountMovements[accountId]) {
                    movement = movement.add(accountMovements[accountId].movement)
                }
            })
            var factor = movement.side === account.get('nature.normalBalance') ? 1 : -1
            record.set('movement', factor * movement.amount)
        })
    }.observes('transactions.@each.accountMovements'),

    columns: [
        Column.create({
            name: 'account.accountNo',
            header: '',
            width: 45
        }),
        Column.create({
            name: 'account.name',
            header: t('account'),
            flex: 1
        }),
        Column.create({
            name: 'startBalance',
            header: t('daybook.index.balance_account_start'),
            width: 120,
            align: 'right',
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if isLoaded}}' +
                        '{{money startBalance}}' +
                    '{{else}}' +
                        '...' +
                    '{{/if}}'
                )
            })
        }),
        Column.create({
            name: 'movement',
            header: t('daybook.index.balance_account_movement'),
            width: 120,
            align: 'right',
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if isLoaded}}' +
                        '{{money movement}}' +
                    '{{else}}' +
                        '...' +
                    '{{/if}}'
                )
            })
        }),
        Column.create({
            name: 'endBalance',
            header: t('daybook.index.balance_account_end'),
            width: 120,
            align: 'right',
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '{{#if isLoaded}}' +
                        '{{money endBalance}}' +
                    '{{else}}' +
                        '...' +
                    '{{/if}}'
                )
            })
        })
    ]
})

var BalanceAccountRecord = Em.Object.extend({
    account: null,

    isLoaded: false,

    loadBalance: function() {
        var self = this
        var account = this.get('account')
        var organization = account.get('organization')
        var api = this.container.lookup('api:billy')
        var url = '/accounts/' + account.get('id') + '/balance'
        if (['revenue', 'expense'].contains(account.get('nature.id'))) {
            url += '?startDate=' + FiscalYear.fromDate(organization, moment()).startDate.format('YYYY-MM-DD')
        }
        this.set('isLoaded', false)
        api.get(url)
            .then(function(payload) {
                self.set('startBalance', payload.balance)
                    .set('isLoaded', true)
            })
    },

    endBalance: function() {
        return this.get('startBalance') + this.get('movement')
    }.property('startBalance', 'movement')
})
