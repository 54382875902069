var t = require('i18n').t

module.exports = Em.Component.extend(require('ember-tooltip').Tooltipable, {
    classNames: ['badge'],

    classNameBindings: ['badgeClass', 'isSmall:small', 'isDisabled:disabled'],

    model: 'inactive',

    description: null,

    badgeClass: function() {
        var classMap = {
            active: 'success',
            inactive: 'attention',
            failing: 'warning'
        }

        return classMap[this.get('model')] || 'attention'
    }.property('model'),

    isSmall: false,

    isDisabled: false,

    label: function() {
        return t('recurring_invoices.state.' + this.get('model'))
    }.property('model'),

    tip: function() {
        return this.get('description')
    }.property('description'),

    tooltipElement: function() {
        return this.$()
    }.property()
})
