module.exports = Em.Controller.extend({
    needs: ['organization'],

    queryParams: {
        initialRoute: 'initialRoute',
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        withVoided: 'withVoided'
    },

    initialRoute: null,
    page: null,
    pageSize: null,
    period: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,
    withVoided: null,

    organization: Em.computed.alias('controllers.organization.model'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var options = e.detail.props || { queryParams: {} }

            if (options) {
                this.transitionToRoute(route, options)
            } else {
                this.transitionToRoute(route)
            }
        }
    }
})
