var Window = require('ember-window')
var i18n = require('i18n')
var t = i18n.t
var LiquidHelper = require('../utils/liquid-helper')
var snapshotInvoice = require('../utils/snapshot-invoice')

module.exports = Window.extend({
    api: Ember.inject.service(),

    layout: Window.proto().layout,

    template: require('../templates/components/recurring-invoice-email-window'),

    templates: Em.inject.service(),

    width: 800,

    invoice: null,

    content: null,

    contactPersons: [],

    errors: function() {
        return DS.Errors.create()
    }.property(),

    useDefault: true,

    _subject: null,

    initializeContactPersons: function() {
        var self = this
        var invoice = this.get('invoice')
        var contactPersons = this.get('contactPersons')
        var recipientContactPersonIds = invoice.get('recipientContactPersonIds') || []

        contactPersons.clear()

        if (recipientContactPersonIds.length > 0) {
            recipientContactPersonIds.forEach(function(id) {
                var url = '/v2/contactPersons/' + id

                self.get('api').request('GET', url)
                    .then(function(payload) {
                        var contactPerson = payload.contactPerson

                        contactPerson.nameWithEmail = contactPerson.name + ' <' + contactPerson.email + '>'
                        self.get('contactPersons').pushObject(contactPerson)
                    })
            })
        }
    }.on('didInsertElement'),

    subject: function(key, value) {
        if (arguments.length === 1) {
            if (this.get('useDefault')) {
                var tplVars = this.get('templates').variablesForInvoice(this.get('invoice'))
                return tplVars.invoiceEmailSubject
            } else {
                return this.get('_subject')
            }
        } else {
            if (!this.get('useDefault')) {
                this.set('_subject', value)
            }
            return value
        }
    }.property('_subject', 'useDefault'),

    _message: null,

    message: function(key, value) {
        if (arguments.length === 1) {
            if (this.get('useDefault')) {
                var tplVars = this.get('templates').variablesForInvoice(this.get('invoice'))
                return tplVars.invoiceEmailMessage
            } else {
                return this.get('_message')
            }
        } else {
            if (!this.get('useDefault')) {
                this.set('_message', value)
            }
            return value
        }
    }.property('_message', 'useDefault'),

    subjectPreview: function() {
        return this.renderPreview(this.get('subject'))
    }.property('subject'),

    messagePreview: function() {
        return this.renderPreview(this.get('message'))
    }.property('message'),

    renderPreview: function(tpl) {
        if (!tpl) {
            return ''
        }
        var invoice = this.get('invoice')
        var entryDate = invoice.get('parsedRecurringInterval.start')
        var dueDate = null
        var paymentTermsMode = invoice.get('paymentTermsMode')

        if (paymentTermsMode === 'net') {
            dueDate = moment(entryDate).add(invoice.get('paymentTermsDays'), 'day')
        } else if (paymentTermsMode === 'netEndOfMonth') {
            dueDate = moment(entryDate).endOf('month').add(invoice.get('paymentTermsDays'), 'day')
        }

        var snapshot = snapshotInvoice(invoice, {
            invoiceNo: '123',
            entryDate: entryDate.format('YYYY-MM-DD'),
            dueDate: dueDate && dueDate.format('YYYY-MM-DD'),
            netAmount: 2000,
            tax: 500,
            grossAmount: 2500,
            balance: 2500
        })
        try {
            return this.liquidHelper.render(tpl, snapshot)
        } catch(e) {
            return ''
        }
    },

    validateLiquid: function(tpl) {
        try {
            this.liquidHelper.render(tpl, {})
        } catch(e) {
            return e
        }
    },

    showForInvoice: function(invoice) {
        var tplVars = this.get('templates').variablesForInvoice(invoice)

        var subject = invoice.get('emailSubject')
        var message = invoice.get('emailMessage')
        if (subject || message) {
            this.set('useDefault', false)
        } else {
            subject = tplVars.invoiceEmailSubject
            message = tplVars.invoiceEmailMessage
        }

        this.liquidHelper = new LiquidHelper(invoice.get('contact.locale.id') || invoice.get('organization.locale.id'))

        this.set('invoice', invoice)
            .set('_subject', subject)
            .set('_message', message)
            .show()
    },

    defaultOptions: function() {
        return [
            Em.O({
                value: true,
                label: t('recurring_invoice_email_window.use_default')
            }),
            Em.O({
                value: false,
                label: t('recurring_invoice_email_window.write_custom_message')
            })
        ]
    }.property(),

    variables: function() {
        var paths = [
            'invoiceNo',
            'entryDate',
            'dueDate',
            'week',
            'month',
            'year',
            'lastMonth',
            'lastMonthYear',
            'nextMonth',
            'nextMonthYear'
        ]
        return paths.map(function(path) {
            return {
                path: '{{' + path + '}}',
                description: t('recurring_invoice.variables.' + Em.String.underscore(path))
            }
        })
    }.property(),

    validate: function() {
        var self = this
        var errors = this.get('errors')
        errors.clear()

        if (this.get('useDefault')) {
            return
        }

        ['subject', 'message'].forEach(function(key) {
            var value = self.get(key)
            if (!value) {
                errors.add(key, t('required_field'))
                return
            }
            var error = self.validateLiquid(value)
            if (error) {
                errors.add(key, error)
            }
        })
    },

    contactPersonQuery: function() {
        if (!this.get('invoice')) {
            return null
        }
        return {
            contact: this.get('invoice.contact')
        }
    }.property('invoice.contact'),

    setContent: function() {
        var invoice = this.get('invoice')

        if (!invoice) {
            return
        }

        this.set('content', BD.AnonymousRecord.createRecord({
            contactPerson: null
        }))
    }.observes('invoice'),

    actions: {
        save: function() {
            var invoice = this.get('invoice')
            var useDefault = this.get('useDefault')

            this.validate()

            if (!this.get('errors.isEmpty')) {
                return
            }

            invoice.set('emailSubject', useDefault ? null : this.get('subject'))
            invoice.set('emailMessage', useDefault ? null : this.get('message'))

            var contactPersonIds = this.get('contactPersons').map(function(contactPerson) { return contactPerson.id })

            invoice.set('recipientContactPersonIds', contactPersonIds)

            this.close()
        },

        didSelectContactPerson: function(contactPerson) {
            var contactPersons = this.get('contactPersons')

            if (!contactPerson) {
                return
            }

            if (!contactPerson.get('email')) {
                return this.set('content.errors.contactPerson', t('invoice_email_sender.contact_person_must_have_email_address'))
            }
            if (contactPersons.find(function(person) { return person.id === contactPerson.id })) {
                return this.set('content.errors.contactPerson', t('invoice_email_sender.contact_person_already_exists'))
            }
            this.send('addContactPerson')
        },

        addContactPerson: function() {
            var contactPerson = this.get('content.contactPerson')
            var contactPersonIsValid = Ember.isEmpty(this.get('content.errors.contactPerson'))
            var contactPersonIsNotDublicate = this.get('contactPersons').indexOf(contactPerson) === -1

            if (contactPerson && contactPersonIsValid && contactPersonIsNotDublicate) {
                this.get('contactPersons').pushObject(contactPerson)
                this.set('content.contactPerson', null)
            }
        },

        removeContactPerson: function(contactPerson) {
            this.get('contactPersons').removeObject(contactPerson)
        }
    }
})
