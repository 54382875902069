var Window = require('ember-window')
var batmask = require('batmask')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/bank-imports-archive-item-confirm'),

    group: null,

    canDelete: function() {
        return this.get('group.reconciledLinesCount') === 0
    }.property('group.reconciledLinesCount'),

    actions: {
        cancel: function() {
            this.close()
        },
        confirm: function() {
            var self = this
            batmask.mask()
            this.get('group').deleteRecord()
                .then(function() {
                    self.close()
                    batmask.unmask()
                    self.trigger('didDelete')
                })
        }
    }
})
