var i18n = require('i18n')

module.exports = Em.Component.extend({
    annualReport: Em.inject.service(),

    classNames: ['ar-input-field'],

    item: null,

    itemIndex: null,

    label: null,

    labelWidth: null,

    min: 0,

    translatedLabel: i18n.tProperty('label'),

    handleDisabled: function() {
        if (this.get('disabled')) {
            this.set('valuedItem.value', 0)
        }
        this.didUpdateAmount()
    }.observes('disabled'),

    resolvedLabel: function() {
        return this.get('label') || this.get('translatedLabel')
    }.property('label', 'translatedLabel'),

    valuedItem: function() {
        return this.get('item')[this.get('itemIndex') || 1]
    }.property('item'),

    labelStyle: function() {
        if (this.get('labelWidth')) {
            return 'width: ' + this.get('labelWidth')
        }

        return ''
    }.property('labelWidth'),

    didUpdateAmount: function() {
        var property = {
            name: this.get('valuedItem.name'),
            value: this.get('valuedItem.value')
        }

        this.get('annualReport').putAnswer(this.get('item.year'), [property])
    },

    actions: {
        saveValue: function(value, context) {
            if (context.error) {
                return
            }

            var property = {
                name: this.get('valuedItem.name'),
                value: this.get('valuedItem.value')
            }

            this.get('annualReport').putAnswer(this.get('item.year'), [property])
        }
    }
})
