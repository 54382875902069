var Window = require('ember-window')
var i18n = require('i18n')
var t = i18n.t

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/prismic-window'),

    title: null,

    displayTitle: function() {
        return t(this.get('title'))
    }.property('title'),

    prismicUid: null,

    prismicType: null,

    prismicTags: null,

    prismicStep: null,

    maxSteps: null,

    nextStepHidden: function() {
        return this.get('maxSteps') === this.get('prismicStep')
    }.property('prismicStep'),

    ps: function() {
        var stepText = this.get('prismicUid')
        return stepText + '-' + this.get('prismicStep')
    }.property('prismicStep'),

    actions: {
        next: function() {
            this.set('prismicStep', this.get('prismicStep') + 1)
        }
    },

    customActions: {
        getMaxSteps: function(steps) {
            this.set('maxSteps', steps.detail)
        }
    }
})
