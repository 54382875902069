var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    // Route props
    code: null,
    coupon: null,
    organization: null,

    showUpgrade: false,

    coupons: Em.inject.service(),

    title: t('coupon.price_table.title'),

    actions: {
        redeem: function() {
            var self = this
            var organization = this.get('organization')
            batmask.maskDelayed()
            this.get('coupons').redeemCoupon(organization.get('id'), this.get('code'))
                .then(function() {
                    batmask.unmask()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, self.get('coupon.confirmationText'))
                    if (organization.get('isTrial')) {
                        self.transitionToRoute('setup', organization)
                    } else {
                        self.transitionToRoute('dashboard', organization)
                    }
                })
                .catch(function(e) {
                    batmask.unmask()
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, e.message)
                })
        }
    }
})
