var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    sideMenu: Ember.inject.service(),

    isInvalid: function() {
        return this.get('items').some(function(item) {
            return item.value !== true
        })
    }.property('commitment.validationErrors', 'items.@each.value'),

    actions: {
        didClickApprove: function() {
            var self = this

            var valid = !this.get('items').some(function(item) {
                return item.get('value') !== true
            })

            // No need for a fancy error message - if one of the buttons is false, it is self-explanatory why you can't continue
            // for this particular step
            if (!valid) {
                return
            }

            this.get('annualReport')
                .putSteps(this.get('model.commitment.year'), { approve_bookkeeping: true })
                .then(function() {
                    self.send('navigateToNextStep')
                })
        }
    }
})
