module.exports = Em.Mixin.create({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    paymentMethods: Ember.inject.service(),

    _settingsInvoicingCustomActions: {
        openCreatePaymentMethodModal: function() {
            var organization = this.get('organization')

            this.get('paymentMethods').createPaymentMethod(organization, organization.get('baseCurrencyId'))
        },

        openEditPaymentMethodModal: function(event) {
            var paymentMethodId = event.detail.paymentMethodId
            var paymentMethod = this.get('paymentMethods').find(paymentMethodId)

            this.get('paymentMethods').editPaymentMethod(paymentMethod)
        },

        reloadPaymentMethods: function() {
            var paymentMethods = this.get('paymentMethods')
            paymentMethods.loadSafe()
        }
    }
})
