var f = require('float')

Billy.BillLine.reopen({
    netAmount: function() {
        var amount = this.get('amount') || 0
        var tax = this.get('tax') || 0
        return this.get('bill.taxMode') === 'excl' ? amount : f.round(amount - tax)
    }.property('bill.taxMode', 'amount', 'tax'),

    grossAmount: function() {
        var amount = this.get('amount') || 0
        var tax = this.get('tax') || 0
        return this.get('bill.taxMode') === 'incl' ? amount : f.round(amount + tax)
    }.property('bill.taxMode', 'amount', 'tax'),

    populateContactDefaults: function() {
        if (!this.get('bill.contact')) {
            return
        }

        var defaultProductDescription = this.get('bill.contact.defaultExpenseProductDescription')
        var defaultExpenseAccount = this.get('bill.contact.defaultExpenseAccount')
        var defaultTaxRate = this.get('bill.contact.defaultTaxRate')
        var bookkeepingTag = this.get('bookkeepingTag')

        if (!this.get('description') && defaultProductDescription) {
            this.set('description', defaultProductDescription)
        }

        if (!this.get('account') && defaultExpenseAccount && !bookkeepingTag) {
            this.set('account', defaultExpenseAccount)
        }

        if (!this.get('taxRate') && !bookkeepingTag) {
            if (defaultTaxRate) {
                this.set('taxRate', defaultTaxRate)
            } else {
                var account = this.get('account')

                // If no defaultTaxRate has been set in the contact and
                // the account has an inner taxRate, fill the line with that.
                if (account && this.get('bill.organization.hasVat')) {
                    this.set('taxRate', account.get('taxRate'))
                }
            }
        }
    }
})
