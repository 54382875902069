module.exports = Em.ObjectController.extend({
    isFirst: function() {
        return this === this.get('parentController.firstObject')
    }.property(),

    actions: {
        deletePerson: function(person) {
            person.deleteRecord()
        }
    }
})
