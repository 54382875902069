var Window = require('ember-window')
var t = require('i18n').t
var batmask = require('batmask')
var SuperField = require('ember-super-field')
var CustomEvent = require('../constants/customEvent')

var PBS_NUMBER_REG_EXP = /^[0-9]{1,8}$/
var DEB_GR_NR_REG_EXP = /^[0-9]{1,5}$/

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/payment-method-editor'),

    customTemplateOptionViewBasicType: SuperField.types.Basic.extend({
        optionViewClass: SuperField.OptionView.extend({
            template: require('../templates/components/payment-method-select-option')
        })
    }),

    api: Em.inject.service(),

    segment: Em.inject.service(),

    customEvent: Ember.inject.service(),

    cvr: Em.inject.service(),

    confirmCloseWhenDirty: false,

    callback: null,

    currencyCode: null,

    paymentMethods: [],

    paymentMethod: null,

    content: Em.computed.oneWay('paymentMethod'), // for use with dirty-window mixin

    errors: {},

    constraints: Ember.inject.service(),

    pbsDeliveryModes: [
        Em.Object.create({
            name: t('payment_methods_editor.pbs.delivery_mode.collection'),
            value: 'collection'
        }),
        Em.Object.create({
            name: t('payment_methods_editor.pbs.delivery_mode.payslip'),
            value: 'payslip'
        }),
        Em.Object.create({
            name: t('payment_methods_editor.pbs.delivery_mode.collection_over_payslip'),
            value: 'collection_over_payslip'
        })
    ],

    isDirty: function(model) {
        if (model.get('isDirty')) {
            return true
        }
        var bankAccount = model.get('bankAccount')
        if (bankAccount && bankAccount.get('isDirty')) {
            return true
        }
        return false
    },

    canLookupCvrInfo: function() {
        return this.get('paymentMethod.organization.isDk')
    }.property('paymentMethod.organization.isDk'),

    rollback: function(model) {
        var bankAccount = model.get('bankAccount')
        if (bankAccount) {
            bankAccount.rollback()
        }
        model.rollback()
    },

    needsType: function() {
        var pm = this.get('paymentMethod')
        return pm.get('isNew') && !pm.get('type')
    }.property('paymentMethod.{isNew,type}'),

    newType: null,

    isPaymentMethodActivated: function(paymentMethodType) {
        var paymentMethods = this.get('paymentMethods')
        return paymentMethods.length !== 0 && paymentMethods.some(function(method) {
            return method.get('type') === paymentMethodType
        })
    },

    switchToReactPaymentMethodModal: function(componentName) {
        this.close()
        this.container.lookup(componentName).show()
    },

    didSelectType: function() {
        var self = this
        var paymentMethod = this.get('paymentMethod')

        var organization = paymentMethod.get('organization')
        var constraintsService = this.get('constraints')
        var type = this.get('newType')

        // Paywall
        if (type && !constraintsService.hasScope('payment_method:' + type)) {
            return constraintsService.showUpgradeOverlay('', 'payment_method_editor')
        }

        if (type === 'mobilepay' && !self.isPaymentMethodActivated(type)) {
            self.switchToReactPaymentMethodModal('component:payment-method-mobilepay-setup')
            return
        }

        if (type === 'stripe' && !self.isPaymentMethodActivated(type)) {
            self.switchToReactPaymentMethodModal('component:payment-method-stripe-setup')
            return
        }

        paymentMethod.set('type', type)
        if (type !== 'other') {
            paymentMethod.set('name', paymentMethod.get('humanType'))
        } else {
            paymentMethod.set('name', '')
        }
        if (type === 'check') {
            paymentMethod.set('text', t('payment_methods_editor.check_text_default', { name: organization.get('name') }))
        }
        if (type === 'pbs') {
            // default delivery mode is collection
            paymentMethod.set('deliveryMode', 'collection')
        }

        if (type === 'fi') {
            // default delivery mode is collection
            paymentMethod.set('text', t('payment_methods_editor.text.fi'))
        }

        if (type === 'other' || type === 'cash') {
            Em.run.schedule('afterRender', function() {
                self.$('textarea[name="text"]').focus()
            })
        }
    }.observes('newType'),

    typeIsStripe: Em.computed.equal('paymentMethod.type', 'stripe'),
    typeIsPBS: Em.computed.equal('paymentMethod.type', 'pbs'),
    typeIsMobilepay: Em.computed.equal('paymentMethod.type', 'mobilepay'),

    bankAccountQuery: function() {
        return { isBankAccount: true }
    }.property(),

    supportsBankAccount: function() {
        var type = this.get('paymentMethod.type')
        return ['bank', 'stripe', 'mobilepay'].indexOf(type) >= 0
    }.property('paymentMethod.type'),

    accountTip: function() {
        var type = this.get('paymentMethod.type')
        if (type === 'stripe') {
            return t('payment_methods_editor.stripe_account_tip')
        } else {
            return ''
        }
    }.property('paymentMethod.type'),

    showBankAccountFields: function() {
        var paymentMethod = this.get('paymentMethod')
        var type = paymentMethod.get('type')
        return type === 'bank' && paymentMethod.get('bankAccount')
    }.property('paymentMethod.{type,bankAccount}'),

    supportsText: function() {
        var type = this.get('paymentMethod.type')
        return type === 'check' || type === 'cash' || type === 'other' || type === 'fi'
    }.property('paymentMethod.type'),

    requiresCreditorNumber: function() {
        var type = this.get('paymentMethod.type')
        return type === 'fi'
    }.property('paymentMethod.type'),

    hasNoTypeFields: function() {
        // var type = this.get('paymentMethod.type')
        // return type === '?'
        return false
    }.property('paymentMethod.type'),

    typeOptions: function() {
        var constraints = this.get('constraints')
        var paymentMethod = this.get('paymentMethod')
        var paymentMethods = this.get('paymentMethods')
        var organization = paymentMethod.get('organization')
        var types = [
            'bank',
            'cash',
            'check',
            'fi',
            'other'
        ]

        var currencyPaymentMethods = this.container.lookup('service:paymentMethods')
            .getPaymentMethodsForCurrency(this.get('currencyCode'))

        if (organization.get('isBillyProviderDk')) {
            types.splice(3, 0, 'mobilepay')
        }

        if (organization.get('settings.featurePBS')) {
            types.splice(types.length - 1, 0, 'pbs')
        }

        if (currencyPaymentMethods.length) {
            currencyPaymentMethods.forEach(function(method) {
                types.splice(-1, 0, method)
            })
        }

        return types
            .filter(function(type) {
                // do not show on the list if payment methods are already in use
                var isMethodActive = ['stripe', 'mobilepay'].indexOf(type) > -1 &&
                    paymentMethods.some(function(method) {
                        return method.get('type') === type
                    })

                return !isMethodActive
            })
            .map(function(type) {
                var key = type === 'other' ? 'other_type' : type
                return Ember.Object.create({
                    value: type,
                    name: t('payment_methods.types.' + key),
                    isPremiumRestricted: !constraints.hasScope('payment_method:' + type)
                })
            })
    }.property('paymentMethod'),

    closeAndCallback: function() {
        if (this.get('callback')) {
            this.get('callback')(this.get('paymentMethod'))
        }
        this.close()
    },

    isValid: function(paymentMethod) {
        this.set('errors', {})

        switch (paymentMethod.get('type')) {
        case 'pbs':
            var isValid = true
            var bankAccount = paymentMethod.get('bankAccount')
            var pbsNumber = paymentMethod.get('pbsNumber')
            var debitorGroupNumber = paymentMethod.get('debitorGroupNumber')
            var deliveryMode = paymentMethod.get('deliveryMode')

            if (!bankAccount) {
                this.set('errors.bankAccount', t('payment_methods_editor.pbs.invalid_bank_account'))
                isValid = false
            }
            if (!pbsNumber || !PBS_NUMBER_REG_EXP.test(pbsNumber)) {
                this.set('errors.pbsNumber', t('payment_methods_editor.pbs.invalid_pbs_number'))
                isValid = false
            }
            if (!debitorGroupNumber || !DEB_GR_NR_REG_EXP.test(debitorGroupNumber)) {
                this.set('errors.debitorGroupNumber', t('payment_methods_editor.pbs.invalid_debitor_group_number'))
                isValid = false
            }
            if (!deliveryMode) {
                this.set('errors.deliveryMode', t('payment_methods_editor.pbs.invalid_delivery_mode'))
                isValid = false
            }

            return isValid
        }

        return true
    },

    getCvrValidity: function(cvr) {
        var sanitizedCvr = cvr.replace(/[^0-9]/g, '')

        return this.get('api')
            .request('GET', this.get('cvr').getCvrCompaniesUrl(sanitizedCvr), {
                mask: false
            })
    },

    savePaymentMethod: function(paymentMethod) {
        var self = this
        var bankAccount = paymentMethod.get('bankAccount')
        var organization = paymentMethod.get('organization')

        batmask.mask()

        Em.RSVP.all([
            paymentMethod.save(),
            bankAccount && bankAccount.save(),
            organization.save()
        ])
            .then(function() {
                self.get('customEvent').dispatchEvent(CustomEvent.PaymentMethodsUpdated)
                self.closeAndCallback()
            })
            .catch(function(resp) {
                var message = t('payment_methods_editor.save_failed_server')

                if (resp.status === 409 && paymentMethod.get('type') === 'pbs') {
                    message = t('payment_methods_editor.pbs.save_failed_dup_data')
                } else if (resp.status >= 400 && resp.status < 500) {
                    message = t('payment_methods_editor.save_failed_client')
                }

                self.set('errors', { save: message })
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    actions: {
        handleSelectedRestrictedOption: function() {
            this.close()
        },

        didSelectCompany: function(company) {
            var organization = this.get('paymentMethod.organization')

            if (!company || !company.cvr) {
                return
            }

            organization.set('registrationNo', company.cvr)
        },
        save: function() {
            var self = this
            var paymentMethod = this.get('paymentMethod')
            var organization = this.get('paymentMethod.organization')

            if (!this.isValid(paymentMethod)) {
                return
            }

            if (this.get('supportsText') && !paymentMethod.get('text')) {
                return self.$('textarea[name="text"]').focus()
            }

            if (this.get('typeIsMobilepay')) {
                var registrationNo = organization.get('registrationNo')

                if (!registrationNo) {
                    return self.$('input[name="companyRegistrationNo"]').focus()
                }

                this.getCvrValidity(registrationNo)
                    .then(function() {
                        self.savePaymentMethod(paymentMethod)
                        self.get('segment').track('xxx - hamster - invoice payment method updated')
                    })
                    .catch(function() {
                        self.set('errors', { save: t('payment_methods.mobilepay.invalid_cvr') })
                    })
            } else {
                self.savePaymentMethod(paymentMethod)
                self.get('segment').track('xxx - hamster - invoice payment method updated')
            }
        }
    }
})
