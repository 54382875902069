var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    supportUrl: t('support_url'),

    actions: {
        changePassword: function() {
            var self = this
            this.get('model')
                .save('/user/passwordReset', {
                    root: 'passwordReset'
                })
                .mask()
                .on('success', function(payload) {
                    self.container.lookup('api:billy').authorize(payload.meta.accessToken)
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.PASSWORD_CHANGE, t('password_reset.password_changed_successfully'))
                    self.replaceRoute('user')
                })
        }
    }
})
