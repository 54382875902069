var Window = require('ember-window')
var LiquidHelper = require('../utils/liquid-helper')
var snapshotInvoice = require('../utils/snapshot-invoice')
var t = require('i18n').t
var storage = require('storage')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/invoice-email-sender'),

    templates: Ember.inject.service(),

    sendCopy: null,

    contactPersons: [],

    currentUser: function() {
        return this.container.lookup('controller:user').get('content')
    }.property(),

    resetContactPersons: function() {
        var contactPersons = this.get('contactPersons')
        var invoice = this.get('invoice')

        // Clear previous selected contactPersons
        contactPersons.clear()

        this.set('content.contactPerson', invoice.get('attContactPerson') || null)
    }.on('didInsertElement'),

    setContent: function() {
        var invoice = this.get('invoice')
        var email = this.get('content')

        if (!invoice) {
            return
        }

        // Roll back the old record if necessary
        if (email && email.get('isNew')) {
            email.rollback()
        }

        // Get the invoice/contactPerson/template
        var type = invoice.get('type')
        var snapshot = snapshotInvoice(invoice)
        // var contactPerson = invoice.get('attContactPerson') || null
        var tplVars = this.get('templates').variablesForInvoice(this.get('invoice'))
        var subject = tplVars[type + 'EmailSubject']
        var message = tplVars[type + 'EmailMessage']
        var liquidHelper = new LiquidHelper(invoice.get('contact.locale.id') || invoice.get('organization.locale.id'))

        // Create/set the email
        this.set('content', BD.AnonymousRecord.createRecord({
            contactPerson: null,
            copyToUserId: null,
            emailSubject: subject ? liquidHelper.render(subject, snapshot) : '',
            emailBody: message ? liquidHelper.render(message, snapshot) : ''
        }))
    }.observes('invoice'),

    isAddPersonButtonVisible: false,

    invoice: null,
    content: null,

    contactPersonQuery: function() {
        if (!this.get('invoice')) {
            return null
        }
        return {
            contact: this.get('invoice.contact')
        }
    }.property('invoice.contact'),

    sendCopyChanged: function() {
        storage('sendInvoiceCopyToUser', this.get('sendCopy'))
    }.observes('sendCopy'),

    deliveryText: function() {
        var invoice = this.get('invoice')
        var mode = invoice.get('contact.emailAttachmentDeliveryMode') || invoice.get('organization.emailAttachmentDeliveryMode')
        var isLinkAndAttachment = mode === 'linkAndAttachment'
        var isAttachment = mode === 'attachment'
        if (invoice.get('isCreditNote')) {
            if (isLinkAndAttachment) {
                return t('invoice_email_sender.credit_note_will_be_attached_and_linked')
            } else if (isAttachment) {
                return t('invoice_email_sender.credit_note_will_be_attached')
            } else {
                return t('invoice_email_sender.credit_note_link_automatically_inserted')
            }
        } else {
            if (isLinkAndAttachment) {
                return t('invoice_email_sender.invoice_will_be_attached_and_linked')
            } else if (isAttachment) {
                return t('invoice_email_sender.invoice_will_be_attached')
            } else {
                return t('invoice_email_sender.invoice_link_automatically_inserted')
            }
        }
    }.property('invoice.isCreditNote', 'invoice.organization.emailAttachmentDeliveryMode', 'invoice.contact.emailAttachmentDeliveryMode'),

    actions: {
        sendEmail: function() {
            var self = this
            var email = this.get('content')
            var currentSelectedContactPerson = this.get('content.contactPerson')
            var contactPersons = this.get('contactPersons')

            if (currentSelectedContactPerson) {
                this.send('addContactPerson')
            }
            if (contactPersons.length === 0) {
                this.set('content.errors.contactPerson', t('required_field'))
            }
            contactPersons.forEach(function(contactPerson, index) {
                // Only send one copy
                email.set('copyToUser', index === 0 && self.get('sendCopy') ? self.get('currentUser') : null)
                email.set('contactPerson', contactPerson)
                email.save('/invoices/' + self.get('invoice.id') + '/emails', {
                    models: ['copyToUser', 'contactPerson'],
                    root: 'email'
                })
                    .mask()
                    .success(function() {
                        if (index === 0) {
                            if (self.get('contactPersons').length > 1) {
                                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.INVOICE_EMAIL_SEND, t('organization_email_window.emails_sent'))
                            } else {
                                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.INVOICE_EMAIL_SEND, t('organization_email_window.email_sent'))
                            }
                            self.close()
                        }
                    })
            })
        },

        didSelectContactPerson: function(contactPerson) {
            if (!contactPerson) {
                return
            }

            var contactPersons = this.get('contactPersons')

            if (!contactPerson.get('email')) {
                this.set('content.errors.contactPerson', t('invoice_email_sender.contact_person_must_have_email_address'))
            }
            if (contactPersons.indexOf(contactPerson) !== -1) {
                this.set('content.errors.contactPerson', t('invoice_email_sender.contact_person_already_exists'))
            }

            if (contactPerson.get('email') && contactPersons.indexOf(contactPerson) === -1) {
                this.set('isAddPersonButtonVisible', true)
            }
        },

        didPrefillContactPerson: function() {
            this.set('isAddPersonButtonVisible', true)
        },

        addContactPerson: function() {
            var contactPerson = this.get('content.contactPerson')
            var contactPersonIsValid = Ember.isEmpty(this.get('content.errors.contactPerson'))
            var contactPersonIsNotDublicate = this.get('contactPersons').indexOf(contactPerson) === -1

            if (contactPerson && contactPersonIsValid && contactPersonIsNotDublicate) {
                this.get('contactPersons').pushObject(contactPerson)
                this.set('content.contactPerson', null)
                this.set('isAddPersonButtonVisible', false)
            }
        },

        removeContactPerson: function(contactPerson) {
            this.get('contactPersons').removeObject(contactPerson)
        }
    }
})
