var t = require('i18n').t
var InlineCreator = require('./bank-sync-v2-inline-creator')

module.exports = InlineCreator.extend({
    layoutName: 'components/bank-sync-v2/bill-creator',

    organization: null,

    account: null,

    match: null,

    bill: null,

    isAttachmentUploading: false,

    lineAmount: function() {
        if (!this.get('match.lines.length')) {
            return 0
        }
        return this.get('match.lines').reduce(function(total, line) {
            return total + (line.get('side.isDebit') ? 1 : -1) * line.get('amount')
        }, 0)
    }.property('match.lines.@each.amount', 'match.lines.@each.side'),

    lineDescription: function() {
        if (!this.get('match.lines.length')) {
            return ''
        }
        return this.get('match.lines').mapBy('description').join(', ')
    }.property('match.lines.@each.description'),

    accountNatures: function() {
        return [Billy.AccountNature.find('expense'), Billy.AccountNature.find('asset')]
    }.property(),

    taxRatePlaceholder: function() {
        return this.get('organization.hasVat') ? t('bank_sync.select_vat_rate') : t('bank_sync.select_tax_rate')
    }.property('organization.hasVat'),

    initModel: function() {
        var bill = Billy.Bill.createRecord({
            organization: this.get('organization'),
            currency: this.get('account.currency'),
            entryDate: this.get('match.entryDate'),
            taxMode: 'incl'
        })
        var predictedContraAccount = this.get('match.lines').filterBy('contraAccount')[0]

        Billy.BillLine.createRecord({
            bill: bill,
            description: this.get('lineDescription'),
            amount: -1 * this.get('lineAmount'),
            account: predictedContraAccount
        })

        this.set('bill', bill)

        if (this.get('organization.hasBillVoucherNo')) {
            var nextVoucherNo = this.container.lookup('controller:nextVoucherNo')
            nextVoucherNo.load().then(function() {
                this.set('bill.voucherNo', nextVoucherNo.getAndIncrement())
            }.bind(this))
        }
    }.on('init'),

    actions: {
        didSelectContact: function(contact) {
            if (contact) {
                this.get('bill.lines').forEach(function(line) {
                    line.populateContactDefaults()
                })
            }
        },

        didSelectAccount: function(account) {
            this.set('bill.lines.firstObject.taxRate', account && account.get('taxRate'))
        },

        showBillAttachments: function() {
            var selector = this.container.lookup('component:bill-attachments-selector')

            selector.on('didSelect', function(attachment) {
                var bill = attachment.get('owner')
                var file = attachment.get('file')

                if (!this.get('bill.contact')) {
                    this.set('bill.contact', bill.get('contact'))
                }

                Billy.Attachment.createRecord({
                    organization: this.get('organization'),
                    owner: this.get('bill'),
                    file: file
                })

                this.get('bill').save({ embed: ['attachments'] }).success(function() {
                    attachment.deleteRecord()

                    if (bill.get('attachments.length') === 0) {
                        bill.deleteRecord()
                    } else {
                        bill.save({ embed: ['attachments'] })
                    }
                })

                selector.close()
            }.bind(this))

            selector.showForOrganization(this.get('organization'))
        },

        match: function() {
            var bill = this.get('bill')

            bill.save({
                properties: {
                    state: 'approved'
                },
                embed: ['lines', 'attachments']
            }).then(this.createSubjectAssociationAndMatch.bind(this, bill))
        }
    }
})
