Em.ComputedProperty.prototype.autoDestroy = function() {
    this._autoDestroy = true
    return this
}

var original = Em.ComputedProperty.prototype.didChange

Em.ComputedProperty.prototype.didChange = function(obj, keyName) {
    if (this._cacheable && this._suspended !== obj) {
        var meta = Em.meta(obj)
        if (keyName in meta.cache) {
            if (this._autoDestroy) {
                meta.cache[keyName].destroy()
            }
        }
    }
    original.apply(this, arguments)
}
