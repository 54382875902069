var i18n = require('i18n')
var t = i18n.t

module.exports = Em.Component.extend({
    classNames: ['button-group export-button'],

    primary: false,

    block: false,

    dropdownRight: false,

    text: i18n.tProperty('text', t('export')),

    formats: 'pdf,html,csv,xlsx',

    formatOptions: function() {
        return this.get('formats').split(',')
            .map(function(format) {
                return {
                    key: format,
                    text: t('export_button.as.' + format)
                }
            })
    }.property('formats'),

    actions: {
        selectFormat: function(format) {
            this.sendAction('action', format)
        }
    }
})
