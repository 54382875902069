module.exports = Em.Component.extend({
    classNames: ['pagination-list'],

    pages: [],

    startArray: [],

    midArray: [],

    endArray: [],

    firstSpacer: function() { return this.get('midArray').length >= 1 }.property('midArray'),

    secondSpacer: function() { return this.get('endArray').length >= 1 }.property('endArray'),

    didInsertElement: function() {
        this.setPagination(this.get('pages'))
    },

    pagination: function() {
        this.setPagination(this.get('pages'))
    }.observes('pages'),

    setPagination: function(pages) {
        var startArray = []
        var endArray = []
        var lastElement = pages[pages.length - 1]
        if (pages) {
            var current = pages.filterBy('isCurrentPage')
            if (pages.length < 5) {
                this.set('startArray', pages)
                this.set('midArray', [])
                this.set('endArray', [])
                return
            }
            if (current[0].pageNo < 3 && pages.length >= 5) {
                startArray = pages.slice(0, 3)
                this.set('startArray', startArray)
                this.set('endArray', [lastElement])
                this.set('midArray', [])
                return
            }
            if (current[0].pageNo === 3 && pages.length >= 5) {
                startArray = pages.slice(0, 4)
                this.set('startArray', startArray)
                this.set('endArray', [lastElement])
                this.set('midArray', [])
                return
            }
            if (current[0].pageNo < pages.length - 2 && current[0].pageNo > 3 && pages.length >= 5) {
                this.set('startArray', pages.slice(0, 1))
                this.set('midArray', pages.slice(current[0].pageNo - 2, current[0].pageNo + 1))
                this.set('endArray', [lastElement])
                return
            }
            if (current[0].pageNo === pages.length - 2 && pages.length >= 5) {
                this.set('startArray', pages.slice(0, 1))
                endArray = pages.slice(pages.length - 4, pages.length)
                this.set('endArray', endArray)
                this.set('midArray', [])
                return
            }
            if (current[0].pageNo > pages.length - 2 && pages.length >= 5) {
                this.set('startArray', pages.slice(0, 1))
                endArray = pages.slice(pages.length - 3, pages.length)
                this.set('endArray', endArray)
                this.set('midArray', [])
            }
        }
    },

    actions: {
        selectPage: function(pageNo) {
            this.sendAction('pageSelected', pageNo)
        }
    }
})
