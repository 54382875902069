var t = require('i18n').t

/**
 * Implementations must have the following attributes:
 * - dueDate
 * - state
 * - isPaid
 *
 */
module.exports = Em.Mixin.create({
    _dueStats: function() {
        var isDue = false
        var isOverdue = false
        var value = null
        var unit = null
        var dueDate = this.get('dueDate')
        if (this.get('state') === 'approved' && !this.get('isPaid')) {
            isDue = true
            if (dueDate) {
                var today = moment().startOf('day');
                ['year', 'month', 'day'].find(function(testUnit) {
                    unit = testUnit
                    value = dueDate.diff(today, unit, true)
                    return Math.abs(value) >= 1
                })
                if (value < 0) {
                    isOverdue = true
                }
            }
        }
        return {
            isDue: isDue,
            isOverdue: isOverdue,
            value: value,
            unit: unit
        }
    }.property('state', 'isPaid', 'dueDate'),

    isDue: Em.computed.alias('_dueStats.isDue'),

    isOverdue: Em.computed.alias('_dueStats.isOverdue'),

    dueDateFromNow: function() {
        var s = this.get('_dueStats')
        var value = s.value
        var unit = s.unit
        if (s.isDue) {
            if (value === null) {
                return t('no_due_date')
            } else if (value === 0) {
                return t('due_today')
            } else if (value > 0) {
                return t('due_future', { time: t('short_date_unit.' + unit, { count: Math.round(value) }) })
            } else {
                return t('due_past', { time: t('short_date_unit.' + unit, { count: Math.round(-value) }) })
            }
        }
        return ''
    }.property('_dueStats')
})
