var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'daybook'],

    organization: Em.computed.oneWay('controllers.organization.model'),

    daybook: Em.computed.oneWay('controllers.daybook.model'),

    isUploading: false,

    error: null,

    helpUrl: function() {
        return t('daybook_import.help_url')
    }.property('organization'),

    uploadUrl: function() {
        return ENV.apiUrl + '/organizations/' + this.get('organization.id') + '/daybooks/' + this.get('daybook.id') + '/transactions'
    }.property('organization', 'daybook'),

    actions: {
        uploadFailed: function(context) {
            this.set('error', context.payload && context.payload.errorMessage)
        },

        didUploadFile: function() {
            var self = this
            this.set('error', null)
            self.container.lookup('util:notification').success(NOTIFICATION_KEYS.DAYBOOK_IMPORT_UPLOAD_FILE, t('daybook_import.import_success'))
            self.transitionToRoute('daybook')
        }
    }
})
