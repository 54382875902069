var Window = require('ember-window')
var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')
var BILLING_TYPES = require('../billingTypes')
var ADDONS = require('../utils/addons')

var PACKAGES = {
    accountingPackage: {
        id: 'accounting_package',
        title: 'Revisorpakken',
        key: 'accounting_package',
        icon: 'misc/accounting-help-1.png',
        analyticsName: 'Annual Report Accountant Package'
    },
    companyClosing: {
        id: 'company_closing_package',
        title: 'Nedlukningspakken',
        key: 'company_closing',
        icon: 'misc/accounting-help-2.png',
        analyticsName: 'Annual Report Company Closing'
    }
}

module.exports = Window.extend({
    api: Ember.inject.service(),

    annualReport: Ember.inject.service(),

    layout: require('../templates/components/upgrade-buy-package-modal'),

    classNames: ['upgrade-buy-package-modal'],

    width: 800,

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    organizationState: Em.inject.service(),

    billingCardSubscription: Em.inject.service(),

    error: null,

    packageName: null,

    additionalPackageInfo: null,

    hasBoughtPackage: false,

    source: null,

    hasAcceptedTerms: false,

    arSubscription: null,
    hasARSubscription: false,
    hasARSubscriptionAndRenewed: false,
    hasARSubscriptionAndNotRenewed: false,
    subscriptionRenewalDate: '',

    init: function() {
        this._super()
        this.initAnnualReportSubscription()
    },

    initAnnualReportSubscription: function() {
        var self = this
        var arService = this.get('annualReport')

        arService.getAnnualReportSubscription()
            .then(function(subscription) {
                if (!subscription || !subscription.ID) {
                    return
                }

                var year = self.get('additionalPackageInfo.year')

                self.set('hasARSubscription', true)
                self.set('subscriptionRenewalDate', arService.getSubscriptionRenewalDate(subscription))
                self.set('showSubscriptionInfo', year >= 2022)

                if (arService.hasSubscriptionRenewedForCommitment(subscription)) {
                    self.set('hasARSubscriptionAndRenewed', true)
                } else {
                    self.set('hasARSubscriptionAndNotRenewed', true)
                }
            })
    },

    willClose: function() {
        if (this.get('hasBoughtPackage') && this.get('packageName') === 'accountingPackage') {
            window.location.href = '/' + this.get('organization.url')
        }
    },

    getPackageText: function(textKey) {
        var companyTypeKey = this.get('isEmv') ? '.' : '.company.'
        var annualReportService = this.get('annualReport')
        var price = annualReportService.getDisplayPriceAccountedForCoupons(ADDONS.ACCOUNTING_PACKAGE.unitPrice, ADDONS.ACCOUNTING_PACKAGE.id)
        return t('upgrade_buy_package' + companyTypeKey + PACKAGES[this.get('packageName')].key + '.' + textKey, { accountingPackagePrice: price }) || ''
    },

    initialiseHasAcceptedTerms: function() {
        if (this.get('hasBoughtPackage')) {
            Em.run.once(this, this.initialiseHasAcceptedTermsOnce)
        }
    }.observes('hasBoughtPackage'),

    initialiseHasAcceptedTermsOnce: function() {
        this.set('hasAcceptedTerms', !!this.get('hasBoughtPackage'))
    },

    isConfirmBtnDisabled: function() {
        return !this.get('hasAcceptedTerms')
    }.property('hasAcceptedTerms'),

    headerText: function() {
        return this.getPackageText('header')
    }.property('packageName', 'isEmv'),

    titleText: function() {
        return this.getPackageText('title')
    }.property('packageName', 'isEmv'),

    descriptionText: function() {
        return this.getPackageText('description')
    }.property('packageName', 'isEmv'),

    soldOutText: function() {
        var additionalInfo = this.get('additionalPackageInfo')
        var companyTypeKey = this.get('isEmv') ? '.' : '.company.'

        return t('upgrade_buy_package' + companyTypeKey + PACKAGES[this.get('packageName')].key + '.soldout', { year: additionalInfo.year }) || ''
    }.property('packageName', 'isEmv'),

    confirmBtnText: function() {
        if (this.get('packageName') === 'accountingPackage' && this.get('hasARSubscription') && this.get('showSubscriptionInfo')) {
            return this.getPackageText('confirm_btn_sub')
        }
        return this.getPackageText('confirm_btn')
    }.property('packageName', 'isEmv', 'hasARSubscription'),

    termsCheckboxText: function() {
        return this.getPackageText('terms_checkbox')
    }.property('packageName', 'isEmv'),

    iconUrl: function() {
        return Billy.image(PACKAGES[this.get('packageName')].icon)
    }.property('packageName'),

    successTitleText: function() {
        return this.getPackageText('success_title')
    }.property('packageName', 'isEmv'),

    successDescriptionText: function() {
        return this.getPackageText('success_description')
    }.property('packageName', 'isEmv'),

    isEmv: function() {
        return ['ENK', 'PMV'].includes(this.get('companyType'))
    }.property('companyType'),

    isPackageSoldOut: function() {
        return this.get('additionalPackageInfo.isSoldOutAccountingPackage')
    }.property('additionalPackageInfo'),

    markPackageAsBought: function() {
        if (!this.get('hasBoughtPackage')) {
            return
        }

        var packageName = this.get('packageName')
        var additionalInfo = this.get('additionalPackageInfo')
        var arStartController = this.container.lookup('controller:annual-report-start')

        var fallbackYear = arStartController && arStartController.get('fiscalYear.year')
        var year = (additionalInfo && additionalInfo.year) || fallbackYear

        if (!year || packageName !== 'accountingPackage') {
            return
        }

        var stateName = 'bought:' + packageName + ':' + year
        this.get('organizationState').putState(stateName, 'true')
    }.observes('hasBoughtPackage', 'additionalPackageInfo'),

    purchasePackageViaUPODI: function() {
        var self = this
        var billingCardSubscription = this.get('billingCardSubscription')
        var annualReportService = this.get('annualReport')
        batmask.maskDelayed()
        var addonPackage = this.get('packageName')

        var arStartController = this.container.lookup('controller:annual-report-start')

        var addon = Object.values(ADDONS).find(function(addon) {
            return addon.id === PACKAGES[addonPackage].id
        })
        addon.year = arStartController.get('fiscalYear.year')

        var url = window.location.href
        var continueUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'package_bought=' + addonPackage

        billingCardSubscription.purchaseAddons(annualReportService.applyCouponPassedFromUrl([addon]), continueUrl)
            .then(function(purchaseResult) {
                if (!purchaseResult.paymentRequired) {
                    self.set('hasBoughtPackage', true)
                    batmask.unmask()
                }
            })
            .catch(function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.ADDON_PURCHASE_FAILED, t('validation.error'))
                batmask.unmask()
            })
    },

    purchasePackageViaOldFlow: function() {
        var self = this
        var packageName = this.get('packageName')
        var organizationId = this.get('organization.id')
        batmask.maskDelayed()

        var url = ('/quickpay/organizations/' + organizationId + '/purchase')
        var continueUrl = new URL(location.href)
        continueUrl.searchParams.append('hasBoughtPackage', packageName)

        self.get('api').post(url, {
            payload: {
                productKey: packageName,
                continueUrl: continueUrl.href,
                cancelUrl: location.href,
                language: self.get('user.quickpayLanguage')
            }
        })
            .then(function(res) {
                if (res.data.paymentRedirectUrl) {
                    window.location = res.data.paymentRedirectUrl
                } else {
                    self.set('hasBoughtPackage', true)
                }
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    actions: {
        confirm: function() {
            this.set('termsNotChecked', false)

            if (this.get('isConfirmBtnDisabled')) {
                this.set('termsNotChecked', true)
                return false
            }

            var organizationBillingType = this.get('organization.billingType')
            var isAllowedForUpodiMigration = this.get('organization.isAllowedForUpodiMigration')

            if (organizationBillingType === BILLING_TYPES.EXTERNAL || isAllowedForUpodiMigration) {
                return this.purchasePackageViaUPODI()
            }

            this.purchasePackageViaOldFlow()
        },
        navigateToDashboard: function() {
            window.location.href = '/' + this.get('organization.url')
        },

        back: function() {
            this.close()
        }
    }
})
