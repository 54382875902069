var attr = DS.attr

module.exports = DS.Model.extend({
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    contactPersons: attr('billy-data-has-many-contact-person'),
    subjectType: attr('string'),
    subjectId: attr('string'),
    subject: attr('string'),
    message: attr('string'),
    receiveCopy: attr('boolean'),
    attachmentMode: attr('string'),
    locale: attr('string')
})
