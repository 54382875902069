var t = require('i18n').t
var translationExists = require('../utils/translation-exists').translationExists

module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')

        model = Object.assign({
            todos: this.get('annualReport').getCompleteBookkeepingItems(model.commitment),
            missingAccounts: this.get('annualReport').getMissingAccounts(model.commitment)
        }, model)

        return Em.RSVP.hash(model)
    },

    afterModel: function(model) {
        return model.todos.map(function(item) {
            item.strongTitle = t('annual_report.todo_list.' + item.translationKey + '.strong_title')
            item.hasStrongTitle = translationExists(item.strongTitle)
            item.title = t('annual_report.todo_list.' + item.translationKey + '.title')
            item.link = t('annual_report.todo_list.' + item.translationKey + '.link')
            item.hasLink = translationExists(item.link)
            item.description = t('annual_report.todo_list.' + item.translationKey + '.description')
            item.hasDescription = translationExists(item.description)

            return item
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('todos', model.todos)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
        controller.set('missingAccounts', model.missingAccounts)
    }
})
