var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['add-on', 'add-on-boolean'],

    classNameBindings: ['isActive::addon-off'],

    layout: require('../templates/components/add-on-boolean'),

    organization: null,

    model: null,

    name: function() {
        return t('organization_subscription.addon.' + this.get('model.id') + '.title')
    }.property('model.id'),

    description: function() {
        return t('organization_subscription.addon.' + this.get('model.id') + '.description')
    }.property('model.id'),

    period: function() {
        return t('organization_subscription.addon.payment_period.' + this.get('model.period'))
    }.property('model.period'),

    price: function() {
        return this.get('model.price')
    }.property('model.price'),

    amount: function() {
        var montlyPrice = this.get('model.price')
        var currencyCode = this.get('model.currencyId')

        return Billy.moneyRecurring(montlyPrice, currencyCode, '0,0', 'monthly')
    }.property('model.{price,currencyId}'),

    isActive: function() {
        return !!this.get('model.value')
    }.property('model.value'),

    isDisabled: function() {
        return false
    }.property(),

    actions: {
        toggle: function() {
            this.set('model.value', !this.get('model.value'))
            this.sendAction('didChange', this.get('model'))
        },

        onInvalidDisable: function() {
            this.sendAction('invalidDisable', this.get('model'))
        }
    }
})
