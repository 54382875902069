var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['choose-receipt-modal'],

    userOrganizations: Em.inject.service(),

    layout: Window.proto().layout,

    template: require('../templates/components/choose-receipt-modal'),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    applyModalMask: false,

    closable: false,

    animated: false,

    idsToSkipStringified: '',

    customActions: {
        receiptSelect: function(e) {
            var attachment = e.detail.attachment
            this.trigger('didSelect', attachment)
        },

        closeEmber: function() {
            this.close()
        }
    }
})
