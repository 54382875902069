Billy.Contact.reopen({
    isCompany: Em.computed.equal('type', 'company'),
    isPerson: Em.computed.equal('type', 'person'),
    isDk: Em.computed.equal('country.id', 'DK'),
    isFr: Em.computed.equal('country.id', 'FR'),
    email: Em.computed.alias('contactPersons.firstObject.email'),

    customerPortalUrl: function() {
        var customerUrl = ENV.customerUrl || document.location.href.replace('webapp', 'customer')
        return customerUrl + this.get('organization.url') + '/' + this.get('accessCode')
    }.property('organization.url', 'accessCode'),

    concatenatedPersons: function() {
        // Enables search by contact persons' name and emails
        if (this._hasManyRecordArrays.contactPersons) {
            var s = this.get('contactPersons').reduce(function(rest, person) {
                return rest + ' ' + person.get('name') + ' ' + person.get('email')
            }, '')
            return s
        } else {
            return null
        }
    }.property('_hasManyRecordArrays.contactPersons.@each.name', '_hasManyRecordArrays.contactPersons.@each.email'),

    nameWithContactNo: function() {
        var text = []
        var name = this.get('name')
        var contactNo = this.get('contactNo')
        if (name) {
            text.push(name)
        }
        if (contactNo) {
            text.push('#' + contactNo)
        }
        return text.join(' ')
    }.property('name', 'contactNo'),

    canLookupCvrInfo: function() {
        return (this.get('isDk') && this.get('organization.isDk')) || (this.get('isFr') || this.get('organization.isFr'))
    }.property('organization.isDk', 'isDk')
})

Billy.Contact.reopenClass({
    qProperties: ['name', 'contactNo', 'street', 'city.name', 'cityText', 'zipcode.zipcode', 'zipcodeText', 'phone', 'fax', 'registrationNo', 'ean', 'concatenatedPersons', 'nameWithContactNo', 'canLookupCvrInfo']
})
