module.exports = Em.Mixin.create(Em.Evented, {
    pageSize: 10,

    scrollContent: null,

    scrollCount: 0,

    _infiniteScrollContentWillChange: function() {
        var content = this.get('content')
        if (content) {
            content.removeArrayObserver(this, {
                willChange: '_infiniteScrollContentArrayWillChange',
                didChange: '_infiniteScrollContentArrayDidChange'
            })
        }
    }.observesBefore('content'),

    _infiniteScrollContentDidChange: function() {
        this.set('scrollContent', [])
        this.set('scrollCount', 0)
        var content = this.get('content')
        if (content) {
            content.addArrayObserver(this, {
                willChange: '_infiniteScrollContentArrayWillChange',
                didChange: '_infiniteScrollContentArrayDidChange'
            })
            this.send('showMore')
        }
    }.observes('content').on('init'),

    _infiniteScrollContentArrayWillChange: function(array, start, removed) {
        if (removed > 0) {
            var scrollContent = this.get('scrollContent')
            var scrollCount = this.get('scrollCount')
            removed = Math.min(scrollContent.get('length'), scrollCount, start + removed) - start
            if (removed > 0) {
                scrollContent.replace(start, removed)
            }
        }
    },

    _infiniteScrollContentArrayDidChange: function(array, start, removed, added) {
        var scrollContent = this.get('scrollContent')
        var scrollCount = this.get('scrollCount')
        if (added > 0) {
            added = Math.min(scrollCount, start + added) - start
            if (added > 0) {
                var items = this.get('content').slice(start, start + added)
                scrollContent.replace(start, 0, items)
            }

            // Remove tail?
            if (scrollContent.get('length') > scrollCount) {
                scrollContent.replace(scrollCount, scrollContent.get('length') - scrollCount)
            }

            this.trigger('infiniteScrollItemsAdded')
        }

        // Add to tail?
        if (removed > 0) {
            this._infiniteScrollPushMore()
        }
    },

    _infiniteScrollPushMore: function() {
        var content = this.get('content')
        var contentLength = content.get('length')
        var scrollCount = this.get('scrollCount')
        var scrollContent = this.get('scrollContent')
        var slideStart = scrollContent.get('length')
        var sliceEnd = Math.min(scrollCount, contentLength)
        if (slideStart < sliceEnd) {
            var items = content.slice(slideStart, sliceEnd)
            scrollContent.pushObjects(items)
        }
    },

    actions: {
        showMore: function() {
            // Stop if content is not set
            var content = this.get('content')
            if (!content) {
                return
            }

            // Increment scrollCount if content's length is greater than current scrollCount
            var contentLength = content.get('length')
            var scrollCount = this.get('scrollCount')
            if (contentLength > scrollCount) {
                scrollCount += this.get('pageSize')
                this.set('scrollCount', scrollCount)
            }

            this._infiniteScrollPushMore()
        },

        showAll: function() {
            // Stop if content is not set
            var content = this.get('content')
            if (!content) {
                return
            }

            // Increment scrollCount if content's length is greater than current scrollCount
            var contentLength = content.get('length')
            var scrollCount = this.get('scrollCount')
            if (contentLength > scrollCount) {
                this.set('scrollCount', contentLength)
            }

            this._infiniteScrollPushMore()
        }
    }
})
