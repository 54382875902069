module.exports = Em.ObjectController.extend({
    queryParams: {
        attachmentId: 'attachment_id',
        attachmentIdBulk: 'attachment_id_bulk',
        fromBillId: 'from_bill_id',
        newAccountId: 'account_id',
        newContactId: 'contact_id'
    },

    attachmentId: null,
    attachmentIdBulk: null,
    fromBillId: null,
    newAccountId: null,
    newContactId: null
})
