var t = require('i18n').t

module.exports = Ember.Component.extend({
    reason: function() {
        return t(
            'collectai.timeline.disputed.' + this.get('entry.properties.details.type')
        )
    }.property('entry.properties.details.type'),
    message: function() {
        if (this.get('entry.properties.details.message')) {
            return this.get('entry.properties.details.message')
        }

        return null
    }.property('entry.properties.details')
})
