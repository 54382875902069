module.exports = Em.Mixin.create({
    urlTemplate: null,

    urlParams: null,

    find: function(store, type) {
        throw new Error('Can\'t find ' + type.typeKey + ' records by id.')
    },

    findAll: function(store, type) {
        throw new Error('Can\'t findAll ' + type.typeKey + ' records.')
    },

    findMany: function(store, type) {
        throw new Error('Can\'t findMany ' + type.typeKey + ' records.')
    },

    findQuery: function(store, type, query) {
        query = Em.copy(query)
        var url = this.renderUrlTemplate(function(key) {
            var value = query[key]
            if (value == null || value === '') {
                throw new Error(type.typeKey + ' records must be queried by `' + key + '`')
            }
            delete query[key]
            return value
        })

        if (query.id) {
            url += '/' + query.id
            delete query.id
        }

        return this.ajax(url, 'GET', { data: query })
    },

    buildURL: function(type, id, record) {
        if (record) {
            var urlParamMap = this.urlParamMap || {}
            var url = this.renderUrlTemplate(function(key) {
                key = urlParamMap[key] || key
                var value = record.get(key)
                if (value == null || value === '') {
                    throw new Error('Can\'t buildURL for ' + type + ' records without `' + key + '` set.')
                }
                return value
            })

            if (id) {
                url += '/' + id
            }

            return url
        } else {
            throw new Error('Can\'t buildURL for ' + type + ' records without a record.')
        }
    },

    renderUrlTemplate: function(callback) {
        var url = this.urlPrefix()

        url += this.urlTemplate.replace(/\{(.+?)\}/g, function(all, key) {
            return callback(key)
        })

        return url
    }
})
