var chartDataLoader = require('../utils/chart-data-loader')
var eventBus = require('../utils/event-bus')
var t = require('i18n').t
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var IconColumn = require('billy-webapp/ui/list/columns/icon')

module.exports = Ember.Mixin.create({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    timePeriodTitle: t('time_period'),

    circles: true,

    contact: null,

    contactDidChange: function() {
        this.set('stackedBarChartItems', [])
        this.set('records', null)
    }.observes('contact'),

    categoryKey: null,

    observeCategoryKey: function() {
        this.container.lookup('util:invoices-helpers').set('categoryKey', this.get('categoryKey'))
    }.on('init').observes('categoryKey'),

    hasSummary: true,
    hasStackedBarChart: false,

    entryDatePeriod: null,

    type: Billy.Bill,

    query: function() {
        var query = {
            organization: this.get('organization'),
            state: 'approved'
        }

        var contact = this.get('contact')
        if (contact) {
            query.contact = contact
        }

        switch (this.get('categoryKey')) {
        case 'overdue':
            query.isPaid = false
            query.isOverdue = true
            break
        case 'unpaid':
            query.isPaid = false
            break
        case 'paid':
            query.isPaid = true
            break
        }

        var entryDatePeriod = this.get('entryDatePeriod')
        if (entryDatePeriod) {
            query.entryDatePeriod = entryDatePeriod.get('value')
        }

        return query
    }.property('organization', 'contact', 'categoryKey', 'entryDatePeriod'),

    isFiltered: function() {
        return (
            !Em.isEmpty(this.get('records.query.isPaid')) ||
            !Em.isEmpty(this.get('records.query.isOverdue')) ||
            this.get('records.query.entryDatePeriod') ||
            !Em.isEmpty(this.get('records.q'))
        )
    }.property('records'),

    remoteQuery: function() {
        return {
            include: 'bill.contact,bill.attachments'
        }
    }.property('hasStackedBarChart'),

    records: null,
    summary: null,
    stackedBarChartItems: null,

    _isActive: false,
    _isInvalidated: false,

    activate: function() {
        this.set('_isActive', true)
        this._invalidate()
        if (this.get('_isInvalidated')) {
            this._load()
        }

        this.set('columns', this.getColumns())
    },

    deactivate: function() {
        this.set('_isActive', false)

        this.set('columns', [])
    },

    _invalidate: function() {
        if (this.get('_isActive')) {
            this._load()
            return
        }

        this.setProperties({
            records: null,
            summary: null,
            stackedBarChartItems: null
        })
        this.set('_isInvalidated', true)
    },

    _currentPromise: null,
    _load: function() {
        var self = this
        var summaryQuery = Em.copy(self.get('query'))

        // Summary needs q as well
        if (this.get('q')) {
            summaryQuery.q = self.get('q')
        }
        delete summaryQuery.state

        // Composited promise for invoice records and summary
        var thisPromise = Ember.RSVP
            .all([
                Billy.Bill.filter({
                    query: self.get('query'),
                    remoteQuery: self.get('remoteQuery'),
                    q: self.get('q'),
                    pageSize: 100,
                    sortProperty: self.get('sortProperty'),
                    sortDirection: self.get('sortDirection')
                }).promise,
                self.get('hasSummary') ? chartDataLoader('bills/summary', summaryQuery) : null,
                self.get('hasStackedBarChart') ? chartDataLoader('bills/stackedBarChart', summaryQuery) : null
            ])
            .then(function(values) {
                // Don't resolve if controller is destroying, or if this promise is not the latest
                var _currentPromise = self.get('_currentPromise')
                if (self.get('isDestroying') || (_currentPromise && thisPromise !== _currentPromise)) {
                    return
                }

                // Resolve all
                self.setProperties({
                    records: values[0],
                    summary: values[1],
                    stackedBarChartItems: values[2],
                    _isInvalidated: false
                })
            })
        self.set('_currentPromise', thisPromise)
    },

    queryDidChange: function() {
        this._load()
    }.observes('query', 'remoteQuery', 'q', 'sortProperty', 'sortDirection').on('init'),

    _addListeners: function() {
        eventBus.on('bill-accounting-changed', this, this._invalidate)
    }.on('init'),

    willDestroy: function() {
        this._super()
        eventBus.off('bill-accounting-changed', this, this._invalidate)
    },

    sortProperty: 'entryDate',

    sortDirection: 'DESC',

    click: function(bill) {
        this.transitionToRoute('bill', bill)
    },

    hasHeader: false,

    itemControllerClass: ListItemController.extend({
        adjustedNetAmount: function() {
            return (this.get('isCreditNote') ? -1 : 1) * this.get('amount')
        }.property('amount', 'isCreditnote'),

        adjustedGrossAmount: function() {
            return (this.get('isCreditNote') ? -1 : 1) * (this.get('amount') + this.get('tax'))
        }.property('amount', 'tax', 'isCreditnote'),

        adjustedBalance: function() {
            return (this.get('isCreditNote') ? -1 : 1) * (this.get('balance'))
        }.property('balance', 'isCreditnote'),

        attachments: function() {
            return this.model.get('attachments').length
                ? 'icons/paperclip.svg-dark svg-small@' + t('bill.index.attachment')
                : ''
        }.property('attachments'),

        amountComment: function() {
            if (this.get('state') === 'approved' && !this.get('isPaid')) {
                var paidAmount = this.get('amount') - this.get('balance')
                if (paidAmount > 0) {
                    return t('partial_payment_note', { amount: Billy.money(paidAmount, this.get('currency')) })
                }
            }
            return null
        }.property('state', 'isPaid', 'amount', 'balance', 'attachments')
    }),

    columnsTogglable: true,

    storageKey: 'bills',

    getColumns: function() {
        var hasVat = this.get('organization.hasVat')

        return _.compact([
            IconColumn.create({
                name: 'stateIcon',
                titleProperty: 'stateText',
                hideable: false,
                width: 40
            }),
            IconColumn.createWithMixins({
                header: t('bill.attachments'),
                sortable: true,
                name: 'attachments',
                hideable: false,
                width: 70
            }),
            (this.get('organization.hasBillVoucherNo') &&
                Column.create({
                    header: t('voucher_no'),
                    name: 'voucherNo',
                    width: 100,
                    sortable: true
                })),
            DateColumn.create({
                header: t('bill.list.entry_date'),
                name: 'entryDate',
                sortable: true,
                width: 140
            }),
            Column.createWithMixins({
                header: t('bill.list.due_date'),
                name: 'dueDate',
                flex: 2,
                sortable: true,
                cellViewClass: TableCellView.extend({
                    colorClass: function() {
                        if (this.get('controller.isOverdue')) {
                            return 'red-text'
                        }
                        return null
                    }.property('controller.isDue', 'controller.isOverdue'),
                    template: Em.Handlebars.compile(
                        '<div {{bind-attr class=":ellipsis view.colorClass"}}>{{dueDateFromNow}}</div>'
                    )
                })
            }),
            Column.create({
                header: t('bill.edit.suppliers_invoice_no_label'),
                name: 'suppliersInvoiceNo',
                defaultVisible: false,
                flex: 2,
                sortable: true
            }),
            (!this.get('contact') &&
                Column.create({
                    header: t('bill.list.supplier'),
                    name: 'contact.name',
                    flex: 2,
                    sortable: true
                })),
            Column.create({
                header: t('bill.list.line_description'),
                name: 'lineDescription',
                flex: 3,
                sortable: true,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '<div class="ellipsis">' +
                            '{{#if isCreditNote}}' +
                                '<div class="label orange" title="' + t('credit_note') + '">' + t('credit_note_abbreviation') + '</div> ' +
                            '{{/if}}' +
                            '{{lineDescription}}' +
                        '</div>'
                    )
                })
            }),
            MoneyColumn.create({
                header: hasVat ? t('excl_vat_short') : t('net_amount_header'),
                name: 'amount',
                width: 120,
                sortable: true,
                defaultVisible: hasVat,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money adjustedNetAmount currencyBinding="currency"}}'
                    )
                })
            }),
            MoneyColumn.create({
                header: hasVat ? t('incl_vat_short') : t('gross_amount_header'),
                name: 'grossAmount',
                width: 120,
                sortable: true,
                defaultVisible: !hasVat,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money adjustedGrossAmount currencyBinding="currency"}}'
                    )
                })
            }),
            MoneyColumn.create({
                header: t('bill.list.balance'),
                name: 'balance',
                width: 100,
                sortable: true,
                defaultVisible: false,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{money balance currencyBinding="currency"}}'
                    )
                })
            })
        ])
    },

    billsTitle: function() {
        var translationKey = this.get('categoryKey') || 'all'
        return t('bill.list.category.' + translationKey, { count: this.get('billsLength') })
    }.property('categoryKey', 'billsLength'),

    billsLength: function() {
        return !this.get('records') ? 0 : this.get('records').length
    }.property('records'),

    badgeType: function() {
        return this.container.lookup('util:invoices-helpers').get('badgeType')
    }.on('init').property('records')
})
