var i18n = require('i18n')
var t = i18n.t
var downloadExport = require('../utils/download-export')
var FiscalYear = require('../utils/accounting/fiscal-year')

module.exports = Em.Controller.extend({
    needs: ['organization'],

    organization: Em.computed.alias('controllers.organization.model'),

    reportHtml: null,

    isLoaded: false,

    reportError: null,

    reload: function() {
        delete this._lastUrl
        this.load()
    },

    load: function() {
        Em.run.once(this, this.loadOnced)
    },

    loadOnced: function() {
        var self = this
        var api = self.container.lookup('api:billy')
        var organizationId = this.get('organization.id')

        // Only load if the filters are currently valid
        var url = this.getReportUrl()
        if (!url) {
            return
        }

        if (url === this._lastUrl) {
            return
        }
        this._lastUrl = url

        this.set('isLoaded', false)

        // Reset the HTML immediately if the last loaded report was for a different organization
        if (organizationId !== this.lastOrganizationId) {
            this.set('reportHtml', '')
            this.lastOrganizationId = organizationId
        }

        api.get(url, {
            success: function(payload) {
                self.set('reportHtml', self.formatHtml(payload.report.html))
                    .set('isLoaded', true)
            },
            error: function(error) {
                self.set('reportError', error.responseJSON.errorMessage)
                self.set('isLoaded', true)
            }
        })
    },

    formatHtml: function(html) {
        var doc = $('<div>' + html + '</div>')
        var accounts = doc.find('tr.account')
        Array.prototype.forEach.call(accounts, function(account) {
            account = $(account)
            var balances = account.find('td.account-balance')
            Array.prototype.forEach.call(balances, function(balance) {
                balance = $(balance)
                var value = balance.html()
                balance.html('<a href="#" class="link account-balance-link" title="' + t('reports.common.show_specification') + '">' + value + '</a>')
            })
        })
        var output = doc.html()
        doc.remove()
        return output
    },

    getReportUrl: function() {},

    navigateToAccount: function(accountId, startDate, endDate) {
        var account = Billy.Account.find(accountId)
        var systemRole = account.get('systemRole')
        var period
        var queryParams
        if (systemRole === 'retainedEarnings' || systemRole === 'currentEarnings') {
            var fiscalYear = FiscalYear.fromDate(this.get('organization'), endDate)
            if (systemRole === 'retainedEarnings') {
                var d = moment(fiscalYear.startDate).subtract(1, 'day')
                period = 'through:' + d.format('YYYY-MM-DD')
            } else {
                period = fiscalYear.stringify()
            }
            queryParams = {
                periodValue: period,
                compareWith: 'period',
                compareCount: 0,
                ytd: false
            }
            this.transitionToRoute('income_statement', { queryParams: queryParams })
        } else {
            if (!startDate) {
                period = 'through:' + endDate
            } else {
                period = 'dates:' + startDate + '...' + endDate
            }
            queryParams = {
                sortDirection: 'DESC',
                periodValue: period,
                includeVoided: false,
                q: ''
            }
            this.transitionToRoute('account', account, { queryParams: queryParams })
        }
    },

    actions: {
        download: function(format) {
            var self = this
            var api = this.container.lookup('api:billy')

            var params = {
                accessToken: api.storageAdapter.getValue('accessToken'),
                acceptLanguage: i18n.locale()
            }

            var exportUrl = self.getReportUrl(format) + '&' + $.param(params)

            downloadExport(self.container, exportUrl)
        }
    },

    showAccountSpecification: function(accountId, startDate, endDate) {
        this.navigateToAccount(accountId, startDate, endDate)
    }
})
