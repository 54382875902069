var Cookies = require('js-cookie')
var qs = require('qs')
var i18n = require('i18n')
var moment = require('momentjs')
var t = i18n.t
var batmask = require('batmask')
var cookieKey = require('../constants/cookieKey')
var numeral = require('numeral')
var popupWindow = require('../utils/popup-window')
var storage = require('storage')
var eventBus = require('../utils/event-bus')
var isValidRegistrationNumber = require('../utils/is-valid-registration-number')
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')
var LiquidHelper = require('../utils/liquid-helper')
var snapshotInvoice = require('../utils/snapshot-invoice')
var Scope = require('../utils/scope')

var DEFAULT_LATE_FEE_DKK = 100

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'invoice', 'user'],

    user: Em.computed.oneWay('controllers.user.model'),

    model: Em.computed.alias('controllers.invoice.model'),

    organization: Em.computed.oneWay('controllers.organization.model'),

    paymentMethods: Ember.inject.service(),

    templates: Ember.inject.service(),

    ais: Ember.inject.service(),

    agerasLoan: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    auth: Ember.inject.service(),

    paymentMethodMobilePay: null,

    justApproved: false,

    isFactoringEnabled: false,

    loanPayoutAmount: null,

    estimateId: null,

    loanCurrency: null,

    segment: Em.inject.service(),

    convertedQuote: null,

    isFetchingQuote: null,

    isLinkButton: true,

    text: t('invoice.index.enter_bank_details'),

    eInvoiceHasBeenSent: false,

    sentType: null,

    hasLoadedEInvoice: false,

    activate: function() {
        var self = this
        this.set('isFetchingQuote', true)

        if (this.get('model.quoteId')) {
            this.store.findQuery('quote', {
                organization: this.get('organization.id'),
                id: this.get('model.quoteId')
            })
                .then(function(quotes) {
                    var quote = quotes.objectAt(0)
                    if (quote.get('contact.name')) {
                        self.set('convertedQuote', quote)
                    }
                })
        } else {
            self.set('convertedQuote', null)
        }

        this.set('isFetchingQuote', false)
    },

    eInvoiceLoadedObserver: function() {
        if (this.get('delivery') !== undefined) {
            this.set('hasLoadedEInvoice', true)
        }
    }.observes('delivery'),

    showAlert: function() {
        return this.get('justApproved') || this.get('isMissingAnyInfo') || this.get('isMissingInternationalBankDetails') || this.get('hasPendingMobilePay')
    }.property('justApproved', 'isMissingAnyInfo', 'isMissingInternationalBankDetails'),

    getPayoutAmount: function() {
        return Billy.money(this.get('loanPayoutAmount'), this.get('loanCurrency'))
    }.property('loanPayoutAmount', 'loanCurrency'),

    isLoanEnabledForInvoice: false,

    invoiceSubmittedForLoan: null,

    registerInvoiceListener: function() {
        eventBus.on('invoice-accounting-changed', this, this.loadHtmlIfActive)
    }.on('init'),

    willDestroy: function() {
        this._super()
        eventBus.off('invoice-accounting-changed', this, this.loadHtmlIfActive)
    },

    hasPendingMobilePay: false,

    agerasFinanceInvoiceId: undefined,

    loadAgerasFinance: function() {
        var self = this

        if (!self.get('model.id')) {
            return
        }

        // simple mechanism to check if the finance estimate checks are already in progress for that invoice
        if (self.get('model.id') === self.get('agerasFinanceInvoiceId')) {
            return
        }

        self.set('agerasFinanceInvoiceId', self.get('model.id'))

        return Em.RSVP.hash({
            financingEnabled: this.get('agerasLoan').isFinancingEnabled(),
            financingDismissed: this.get('agerasLoan').isFinancingDismissed()
        }).then(function(results) {
            if (isValidRegistrationNumber(self.get('organization.registrationNo'))) {
                self.set('isFactoringEnabled', false)
                self.set('isLoanEnabledForInvoice', false)
                self.get('agerasLoan').getInvoiceFactoring(
                    self.get('model.id'),
                    self.get('model.invoiceNo'),
                    self.get('model.currency.id'),
                    self.get('model.grossAmount'),
                    self.get('model.dueDate')
                ).then(function(res) {
                    var enabled = !!res.payout_amount

                    self.set('isFactoringEnabled', results.financingEnabled && !results.financingDismissed)
                    self.set('isLoanEnabledForInvoice', enabled)
                    self.set('loanPayoutAmount', res.payout_amount)
                    self.set('loanCurrency', self.get('model.currency.id'))
                    self.set('estimateId', res.id)
                    self.set('invoiceSubmittedForLoan', !!res.application)
                    self.set('loanAppliedDate', moment(res.created_at).format('MMM DD, YYYY'))
                })
            }
        })
    }.observes('model.id'),

    detectPendingMobilePay: function() {
        var paymentMethods = this.get('paymentMethods')
        var pendingMobilePayPaymentMethod = null

        if (paymentMethods) {
            paymentMethods.get('data').forEach(function(paymentMethod) {
                if (paymentMethod &&
                    paymentMethod.get('type') === 'mobilepay' &&
                    paymentMethod.get('isPending')) {
                    pendingMobilePayPaymentMethod = paymentMethod
                }
            })
        }

        this.set('paymentMethodMobilePay', pendingMobilePayPaymentMethod)

        return !!pendingMobilePayPaymentMethod
    },

    paymentMethodsObserver: function() {
        if (this.get('paymentMethods.isLoaded')) {
            this.set('hasPendingMobilePay', this.detectPendingMobilePay())
        }
    }.observes('paymentMethods.isLoaded').on('init'),

    loadHtmlIfActive: function() {
        var cur = this.container.lookup('controller:application').get('currentPath')
        if (cur === 'user.organization.invoices.invoice.index') {
            Em.run.once(this, this.loadHtml)
        }
    },

    loadHtml: function() {
        var self = this
        var invoiceId = this.get('model.id')

        // If the currently displayed HTML does not belong to the invoice we are going to display, then clear the HTML right away
        if (this.get('htmlId') !== invoiceId) {
            this.set('html', '')
                .set('htmlId', null)
        }

        this.container.lookup('api:billy').get('/invoices/' + invoiceId + '/html')
            .then(function(payload) {
                self.set('html', payload.invoiceContent.html)
                    .set('htmlId', invoiceId)
            }, function() {
                self.set('html', t('document_load_error'))
            })
    },

    isUnsent: Em.computed.equal('sentState', 'unsent'),
    isSent: function() {
        return this.get('sentState') !== 'unsent' || this.get('sentType') === 'email'
    }.property('sentState', 'sentType'),

    isVoided: function() {
        return this.get('state') === 'voided'
    }.property('state'),

    isApprovedAndNotPaid: function() {
        return (this.get('isApproved') && !this.get('isPaid'))
    }.property('isApproved', 'isPaid'),
    isApprovedAndSentAndNotPaid: function() {
        return (this.get('isApproved') && this.get('isSent') && !this.get('isPaid'))
    }.property('isApproved', 'isSent', 'isPaid'),

    shouldBeSent: function() {
        return (!this.get('isSent') && this.get('state') === 'approved') && !this.get('eInvoiceHasBeenSent') && this.get('hasNoEInvoiceDeliveryOrFailed')
    }.property('isSent', 'state', 'eInvoiceHasBeenSent', 'hasNoEInvoiceDeliveryOrFailed'),

    hasNoEInvoiceDeliveryOrFailed: function() {
        var delivery = this.get('delivery')

        return (this.get('hasLoadedEInvoice') && (!delivery || (!!delivery && delivery.sendingState === 'failed')))
    }.property('hasLoadedEInvoice', 'delivery'),

    shouldDisplaySendEInvoiceButton: function() {
        var delivery = this.get('delivery')
        return !delivery || delivery.canResend
    }.property('delivery'),

    hasBeenSent: function() {
        return this.get('isSent') || this.get('eInvoiceHasBeenSent') || !!this.get('sentType')
    }.property('isSent', 'eInvoiceHasBeenSent', 'sentType'),

    canSendEan: function() {
        return !Em.isEmpty(this.get('contact.ean'))
    }.property('contact.ean'),

    isRemindable: function() {
        return (this.get('isOverdue') && !this.get('isCreditNote'))
    }.property('isOverdue', 'isCreditNote'),

    canSendToDebtCollection: function() {
        return ((this.get('organization.settings.featureAIS') === '1') && this.get('isRemindable'))
    }.property('organization.settings.featureAIS', 'isRemindable'),

    sentStateIcon: function() {
        switch (this.get('sentState')) {
        case 'opened':
            return 'icons/mail-received.svg-blue-gray'
        case 'printed':
            return 'icons/printer.svg-blue-gray'
        case 'sent':
            return 'icons/mail-arrow.svg-blue-gray'
        case 'unsent':
            return 'icons/mail-pause.svg-blue-gray'
        case 'viewed':
            return 'icons/mail-open-tick.svg-blue-gray'
        }
        return null
    }.property('sentState'),

    quoteTypeText: function() {
        if (this.get('convertedQuote.type')) {
            return t('invoice.index.converted_quote.type.' + this.get('convertedQuote.type'))
        }
        return ''
    }.property('convertedQuote'),

    load: function() {
        if (this.get('quoteId') !== null && !this.get('isFetchingQuote')) {
            this.activate()
        }
    }.observes('quoteId'),

    isConvertedFromQuote: function() {
        return this.get('quoteId') !== null
    }.property('quoteId'),

    sentStateText: function() {
        switch (this.get('sentState')) {
        case 'opened':
            return t('invoice.index.sent_state.opened')
        case 'printed':
            return t('invoice.index.sent_state.printed')
        case 'sent':
            return t('invoice.index.sent_state.sent')
        case 'unsent':
            return t('invoice.index.sent_state.unsent')
        case 'viewed':
            return t('invoice.index.sent_state.viewed')
        }
        return ''
    }.property('sentState'),

    baseCurrency: Em.computed.alias('controllers.organization.model.baseCurrency'),
    currencyIsForeign: function() {
        return this.get('currency') !== this.get('baseCurrency')
    }.property('currency', 'baseCurrency'),

    guessedBankAccount: function() {
        var pmService = this.get('paymentMethods')
        if (!pmService.get('isLoaded')) {
            return null
        }
        // Find the `bankAccount` of the first `type=bank` payment method for the invoice
        var bankAccount = null
        var paymentMethods = this.get('model.paymentMethods')
        if (paymentMethods) {
            paymentMethods.find(function(r) {
                var paymentMethod = pmService.find(r.paymentMethodId)
                if (paymentMethod && paymentMethod.get('type') === 'bank') {
                    bankAccount = paymentMethod.get('bankAccount')
                    return true
                }
            })
        }
        return bankAccount
    }.property('paymentMethods.data.@each', 'model.paymentMethods'),

    isMissingAnyInfo: function() {
        if (storage('dismissedInvoiceInfoPrompt:' + this.get('organization.id'))) {
            return false
        }
        return this.get('isMissingContactInfo') || !this.get('organization.logoUrl') || this.get('isMissingBankDetails')
    }.property('isMissingContactInfo', 'organization.logoUrl', 'isMissingBankDetails'),

    isMissingContactInfo: function() {
        return !this.get('organization.street')
    }.property('organization.street'),

    isMissingBankDetails: function() {
        if (this.get('organization.country') !== this.get('contact.country')) {
            // Not if contact is from a different country, then isMissingInternationalBankDetails takes over
            return false
        }
        var account = this.get('guessedBankAccount')
        if (account) {
            return !account.get('bankRoutingNo') && !account.get('bankAccountNo') && !account.get('bankSwift') && !account.get('bankIban')
        }
        return false
    }.property('organization.country', 'contact.country', 'guessedBankAccount.{bankRoutingNo,bankAccountNo,bankSwift,bankIban}'),

    isMissingInternationalBankDetails: function() {
        if (this.get('organization.country') === this.get('contact.country')) {
            // Not if contact is from same country
            return false
        }
        var account = this.get('guessedBankAccount')
        if (account) {
            if (storage('dismissedInternationalBankDetailsPrompt_' + account.get('id'))) {
                return false
            }
            return !account.get('bankSwift') && !account.get('bankIban')
        }
        return false
    }.property('organization.country', 'contact.country', 'guessedBankAccount.{bankSwift,bankIban}'),

    exchangeRateNote: function() {
        return '1 ' + this.get('currency.id') + ' = ' + numeral(this.get('exchangeRate')).format('0.[000000]') + ' ' + this.get('baseCurrency.id')
    }.property('currency', 'exchangeRate', 'baseCurrency'),
    gainLossNote: function() {
        var realizedAmount = 0
        var currency = this.get('currency')
        var baseCurrency = this.get('baseCurrency')
        var balance = this.get('balance')
        var currentValue = currency.exchangeTo(balance, baseCurrency)
        var invoiceDateValue = balance * this.get('exchangeRate')
        var unrealizedAmount = currentValue - invoiceDateValue
        this.get('model.balanceModifiers').forEach(function(modifier) {
            realizedAmount += modifier.get('realizedCurrencyDifference')
        })
        if (this.get('type') === 'creditNote') {
            realizedAmount *= -1
            unrealizedAmount *= -1
        }
        return [
            t('invoice.index.realized') + ': ' + Billy.money(realizedAmount) + ' ' + this.get('baseCurrency.id'),
            t('invoice.index.unrealized') + ': ' + Billy.money(unrealizedAmount) + ' ' + this.get('baseCurrency.id'),
            t('invoice.index.total') + ': ' + Billy.money(realizedAmount + unrealizedAmount) + ' ' + this.get('baseCurrency.id')
        ].join(' ')
    }.property('currencyIsForeign', 'currency', 'baseCurrency', 'balance', 'balanceModifiers.@each.realizedCurrencyDifference'),

    download: function() {
        popupWindow.open(this.get('downloadUrl'))
    },

    payUrl: function() {
        var invoice = this.get('model')
        if (!this.get('organization.settings.featurePaylike') || !invoice.get('isInvoice') || invoice.get('isPaid')) {
            return
        }
        var locale = i18n.locale().replace(/_.+$/, '')
        return ENV.PAYLIKE_WEBAPP_HOST + 'pay/' + this.get('organization.id') + '/' + invoice.get('contact.accessCode') + '/' + invoice.get('id') + '?locale=' + locale
    }.property('organization.settings.featurePaylike', 'organization.id', 'model.{type,isPaid,id}'),

    timelineUrl: function() {
        var invoice = this.get('model')
        return '/organizations/' + invoice.get('organization.id') + '/invoices/' + invoice.get('id') + '/timeline'
    }.property('model{organization,id}'),

    openPdf: function() {
        var win = popupWindow.open()
        win.setLocation(this.get('model.downloadUrl'))
    },

    shouldDisplayLoanButton: function() {
        return this.get('isFactoringEnabled') &&
        this.get('isLoanEnabledForInvoice') &&
        !this.get('isPaid') &&
        this.get('type') !== 'creditNote'
    }.property('isFactoringEnabled', 'isLoanEnabledForInvoice'),

    financingCta: function() {
        return t('invoice.index.loan_get_paid_today', { amount: this.get('payoutAmount') })
    }.property('payoutAmount'),

    payoutAmount: function() {
        return Billy.money(this.get('loanPayoutAmount'), this.get('loanCurrency'))
    }.property('loanPayoutAmount', 'loanCurrency'),

    voidInvoice: function() {
        var self = this

        batmask.maskDelayed()
        this.get('model')
            .save({
                properties: {
                    state: 'voided'
                }
            })
            .then(function() {
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_VOID, t('invoice.index.voided_message'))
                self.get('segment').track('XXX - Jesper - Action Taken', {
                    context: 'invoice_viewed',
                    action: 'more: void'
                })
                self.get('segment').track('Invoice Voided (FE)')
                self.get('customEvent').dispatchEvent(CustomEvent.InvoiceUpdated)
            }, function() {
                var model = self.get('model')
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.INVOICE_VOID, model.error)
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    voidCreditNote: function() {
        var self = this
        this.get('balanceModifiers').promise
            .then(function(balanceModifiers) {
                var message
                if (self.get('type') === 'creditNote') {
                    message = t('invoice.index.confirm_void_credit_note')
                } else {
                    message = t('invoice.index.confirm_void_invoice')
                }
                var modifiers = balanceModifiers.filter(function(balanceModifier) {
                    var modifier = balanceModifier.get('modifier')
                    return modifier instanceof Billy.InvoiceLateFee || modifier instanceof Billy.BankPayment
                })
                if (modifiers.length) {
                    message += '<br>'
                    message += t('invoice.index.confirm_void_invoice_modifiers')
                    message += '<ul>'
                    message += modifiers.reduce(function(result, modifier) {
                        result += '<li>' + Em.Handlebars.Utils.escapeExpression(modifier.get('descriptionWithDate')) + ' (' + Billy.money(modifier.get('amount'), self.get('currency')) + ')</li>'
                        return result
                    }, '')
                    message += '</ul>'
                    message = message.htmlSafe()
                }

                return self.container.lookup('util:dialog')
                    .confirmWarning(null, message, t('invoice.index.yes_void'), t('no'))
            })
            .then(function() {
                batmask.maskDelayed()
                return self.get('model')
                    .save({
                        properties: {
                            state: 'voided'
                        }
                    })
            })
            .then(function() {
                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_VOID, t('invoice.index.voided_message'))
                self.get('segment').track('XXX - Jesper - Action Taken', {
                    context: 'invoice_viewed',
                    action: 'more: void'
                })
                self.get('segment').track('Invoice Voided (FE)')
                self.get('customEvent').dispatchEvent(CustomEvent.InvoiceUpdated)
            }, function() {
                var model = self.get('model')
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.INVOICE_VOID, model.error)
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    sendInvoice: function(sendAsEInvoice) {
        var self = this
        var invoice = this.get('model')
        var tplVars = this.get('templates').variablesForInvoice(invoice)
        var type = invoice.get('type')
        var subject = tplVars[type + 'EmailSubject']
        var message = tplVars[type + 'EmailMessage']

        var snapshot = snapshotInvoice(invoice)
        var liquidHelper = new LiquidHelper(invoice.get('contact.locale.id') || invoice.get('organization.locale.id'))

        var modal = this.container.lookup('component:send-invoice-modal')
        modal.set('sendAsEInvoice', sendAsEInvoice ? 'true' : 'false')
        modal.set('invoiceId', invoice.get('id'))
        modal.set('contactEAN', invoice.get('contact.ean'))
        modal.set('contactId', invoice.get('contact.id'))
        modal.set('selectedContactPersonId', invoice.get('attContactPerson.id'))
        modal.set('emailSubject', subject ? liquidHelper.render(subject, snapshot) : '')
        modal.set('emailMessage', message ? liquidHelper.render(message, snapshot) : '')
        modal.onInvoiceSent(self.onInvoiceSent.bind(self))
        modal.show()
    },

    onInvoiceSent: function(sentType) {
        this.set('sentType', sentType)

        if (sentType === 'eInvoice') {
            this.set('eInvoiceHasBeenSent', true)
        }
    },

    duplicate: function() {
        this.set('sentType', null)
        this.set('eInvoiceHasBeenSent', null)
        this.get('segment').track('XXX - Jesper - Action Taken', {
            context: 'invoice_viewed',
            action: 'more: duplicate'
        })
        this.transitionTo('invoices.new', {
            queryParams: {
                fromInvoiceId: this.get('model.id')
            }
        })
    },

    actions: {
        backToList: function() {
            var invoicesListQuery = Cookies.get(cookieKey.invoicesListQuery) || ''
            Cookies.remove(cookieKey.invoicesListQuery)
            this.transitionToRoute('invoices.index', { queryParams: qs.parse(invoicesListQuery, { ignoreQueryPrefix: true }) })
        },

        openQuote: function(quoteConvertedFrom) {
            this.container.lookup('router:main').transitionTo('quote', quoteConvertedFrom.id)
        },

        requestLoanCTAClick: function() {
            var self = this
            this.set('invoiceSubmittedForLoan', true)

            self.get('agerasLoan').hasCustomerIncompleteData().then(function(hasIncompleteData) {
                return self.get('agerasLoan').approveInvoiceFactoring(self.get('estimateId'))
                    .then(function() {
                        if (hasIncompleteData) {
                            self.get('agerasLoan').goToApplicationForm()
                            return
                        }
                        self.send('invalidateModel')
                        self.container.lookup('component:approve-financing-success-modal')
                            .show()
                    })
            })

            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'request loan'
            })
        },

        didDismissInfoPrompt: function() {
            storage('dismissedInvoiceInfoPrompt:' + this.get('organization.id'), true)
        },

        didDismissInternationalBankDetailsPrompt: function() {
            var account = this.get('guessedBankAccount')
            if (account) {
                storage('dismissedInternationalBankDetailsPrompt_' + account.get('id'), true)
            }
        },

        sendAsEInvoice: function() {
            this.sendInvoice(true)
        },

        sendAsEmail: function() {
            this.sendInvoice(false)
        },

        sendEan: function() {
            this.container.lookup('component:invoice-ean-sender')
                .set('invoice', this.get('model'))
                .show()
        },

        sendEmail: function() {
            var self = this
            var invoice = this.get('model')
            invoice.get('lines').promise
                .then(function() {
                    var sendInvoiceCopy = storage('sendInvoiceCopyToUser') === 'true'
                    self.container.lookup('component:invoice-email-sender')
                        .set('sendCopy', sendInvoiceCopy)
                        .set('invoice', invoice)
                        .show()
                    self.get('segment').track('XXX - Jesper - Action Taken', {
                        context: 'invoice_viewed',
                        action: 'send as email again'
                    })
                })
        },

        edit: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'more: edit'
            })
            this.transitionTo('invoice.edit')
        },

        print: function() {
            var self = this
            var invoice = this.get('model')
            if (!this.get('hasBeenSent')) {
                this.container.lookup('util:dialog').dialog(t('invoice_printer.print_invoice'), t('invoice_printer.mark_as_sent_question'), [
                    {
                        value: 'yes',
                        text: t('invoice_printer.mark_as_sent'),
                        primary: true,
                        align: 'right'
                    },
                    {
                        value: false,
                        text: t('no'),
                        align: 'left'
                    }
                ], {
                    focusSelector: '.window-footer .right button',
                    closable: true
                })
                    .then(function(option) {
                        self.get('segment').track('XXX - Jesper - Action Taken', {
                            context: 'invoice_viewed',
                            action: 'more: print / save as pdf'
                        })

                        self.openPdf()
                        if (option === 'yes') {
                            invoice.set('sentState', 'printed')
                                .save()
                        }
                    })
            } else {
                this.openPdf()
            }
        },

        printPackingList: function() {
            if (!this.get('auth').isAuthorized(Scope.InvoicePackingListPrint)) {
                this.container.lookup('component:upgrade-plan-overlay').show()
                return
            }

            popupWindow.open(this.get('downloadUrl') + '?layout=packing-list')
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'more: print packing list'
            })
        },

        markAsSent: function() {
            var invoice = this.get('model')
            var sentState = invoice.get('sentState')
            var message = invoice.get('type') === 'creditNote' ? t('invoice.index.mark_as_sent_confirmation_credit_note') : t('invoice.index.mark_as_sent_confirmation')
            if (['printed', 'sent', 'opened', 'viewed'].contains(sentState)) {
                return
            }
            this.container.lookup('util:dialog')
                .confirm(null, message, t('invoice.index.yes_mark_as_sent'), t('cancel'))
                .then(function() {
                    invoice.set('sentState', 'sent').save()
                })
        },

        registerPayment: function() {
            this.container.lookup('component:register-payment-window')
                .showForSubject(this.get('model'))
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'enter payment'
            })
        },

        sendReminder: function() {
            if (!this.get('auth').isAuthorized(Scope.InvoiceReminderWrite)) {
                this.container.lookup('component:upgrade-plan-overlay').show()
                return
            }

            var lateFee = this.get('model.currency').id === 'DKK' ? DEFAULT_LATE_FEE_DKK : 0
            var invoiceReminder = Billy.InvoiceReminder.createRecord({
                organization: this.get('controllers.organization.model'),
                flatFee: lateFee,
                percentageFee: 0,
                feeCurrency: this.get('model.currency'),
                sendEmail: true,
                contact: this.get('model.contact'),
                contactPerson: this.get('model.attContactPerson'),
                emailSubject: null,
                emailBody: null,
                copyToUser: null
            })
            Billy.InvoiceReminderAssociation.createRecord({
                reminder: invoiceReminder,
                invoice: this.get('model')
            })
            this.container.lookup('component:invoice-reminder-editor')
                .setProperties({
                    content: invoiceReminder,
                    preselectedInvoice: this.get('model')
                })
                .show()
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'send reminder'
            })
        },

        sendToDebtCollection: function() {
            this.get('ais').sendDebtCollection(this.get('model'))
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'send to debt collection'
            })
        },

        void: function() {
            var self = this
            var isCreditNote = this.get('isCreditNote')

            if (isCreditNote) {
                self.voidCreditNote()
                return
            }

            self.container.lookup('component:void-invoice-modal')
                .set('invoiceId', self.get('id'))
                .set('voidInvoice', self.get('voidInvoice').bind(self))
                .show()
        },

        restore: function() {
            var self = this
            self.container.lookup('util:dialog')
                .confirm(null, t('invoice.index.confirm_restore'), t('invoice.index.yes_restore'), t('no'))
                .then(function() {
                    batmask.maskDelayed()
                    return self.get('model').save({
                        properties: {
                            state: 'approved'
                        }
                    })
                })
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_RESTORE, t('invoice.index.restored_message'))
                    self.get('segment').track('XXX - Jesper - Action Taken', {
                        context: 'invoice_viewed',
                        action: 'restore'
                    })
                    self.get('customEvent').dispatchEvent(CustomEvent.InvoiceUpdated)
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.INVOICE_RESTORE, e.message)
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        showInCustomerPortal: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'more: show in customer portal'
            })
            window.open(this.get('customerPortalUrl'), '_blank')
        },

        createCreditNote: function() {
            this.get('segment').track('XXX - Jesper - Action Taken', {
                context: 'invoice_viewed',
                action: 'more: create credit note'
            })
            this.transitionTo('invoice.credit')
        },

        duplicate: function() {
            this.duplicate()
        },

        duplicateOriginalInvoice: function() {
            this.transitionTo('invoices.new', {
                queryParams: {
                    fromInvoiceId: this.get('model.id'),
                    fromCreditNote: true
                }
            })
        }
    },

    customActions: {
        voidInvoice: function() {
            this.voidInvoice()
        },

        openSendInvoiceModal: function() {
            this.sendInvoice(true)
        },

        onAccountSave: function(e) {
            var account = e.detail
            this.set('guessedBankAccount.bankSwift', account.bankSwift)
            this.set('guessedBankAccount.bankIban', account.bankIban)
        },

        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute(route, props)
        },

        voidAndDuplicateInvoice: function() {
            this.voidInvoice()
            this.duplicate()
        }
    }
})
