module.exports = Em.Component.extend({
    needs: ['bankAccount'],

    userOrganizations: Em.inject.service(),

    account: Em.computed.oneWay('controllers.bankAccount.model'),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/bank-connection-status-actions')
})
