var t = require('i18n').t

module.exports = require('./abstract-annual-report').extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organizationOwners: null,

    model: function() {
        var model = this.modelFor('annual_report')

        return Em.RSVP.hash(Object.assign({
            items: this.get('annualReport').getAboutYourTaxesItems(model.commitment)
        }, model))
    },

    afterModel: function(model) {
        var items = []

        model.items.map(function(item) {
            item.title = t('annual_report.about_your_taxes.' + item.translationKey + '.title')
            item.link = t('annual_report.about_your_taxes.' + item.translationKey + '.link.billy')

            items.push(Em.Object.create(item))
        })

        return items
    },

    setupController: function(controller, model, transition) {
        this._super(controller, model, transition)
        controller.set('model', model)
        controller.set('items', model.items)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
        controller.set('companyType', model.commitment.companyType)
    }
})
