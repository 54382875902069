var Scope = require('../utils/scope')
var AuthorizedRoute = require('../mixins/authorized-route')

module.exports = Em.Route.extend(AuthorizedRoute, {
    scopes: [Scope.ContactRead],

    routeToContact: function(isSupplier, isCustomer) {
        if (isSupplier && !isCustomer) {
            this.transitionTo('contact.supplier')
        } else {
            this.transitionTo('contact.customer')
        }
    },

    afterModel: function() {
        var contact = this.modelFor('contact')
        var self = this

        // sometimes when we get here from react's part of the application (e.g. GlobalSearch), the model does not have all data we need
        // that's why we need to fetch this data manually to check to which route we should redirect
        var isContactModelNotComplete = contact.get('id') &&
            contact.get('isSupplier') === undefined &&
            contact.get('isCustomer') === undefined

        if (isContactModelNotComplete) {
            this.get('api').request('GET', '/v2/contacts/' + contact.get('id'))
                .then(function(result) {
                    var isSupplier = result.contact && result.contact.isSupplier
                    var isCustomer = result.contact && result.contact.isCustomer

                    self.routeToContact(isSupplier, isCustomer)
                })

            return
        }

        var isSupplier = contact.get('isSupplier')
        var isCustomer = contact.get('isCustomer')
        this.routeToContact(isSupplier, isCustomer)
    }
})
