var t = require('i18n').t

module.exports = require('./circle-filter').extend({
    summary: null,

    circles: true,

    formatValues: false,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    categories: function() {
        return Em.A([])
    }.property('organization.{subscriptionPlan}'),

    summaryDidChange: function() {
        var summary = this.get('summary') || {}
        var all = summary.all || { count: 0 }

        this.get('categories').forEach(function(category) {
            var key = category.get('key')
            var d = summary[key] || { count: 0 }
            category.set('value', d.count)
        })
        this.set('allName', t('bills.draft_overview.list.category.all.' + this.get('organization.subscriptionPlan')))
        this.set('allValue', all.count)
    }.observes('summary', 'organization.{subscriptionPlan}').on('init')
})
