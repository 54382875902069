var t = require('i18n').t

module.exports = require('./abstract-annual-report').extend({
    queryParams: {
        reason: { replace: true },
        hasBoughtPackage: { replace: true }
    },

    setupController: function(controller, model, transition) {
        this._super(controller, model, transition)

        var reason = transition.queryParams && transition.queryParams.reason
            ? transition.queryParams.reason
            : 'default'
        var header = t('annual_report.not_applicable.' + reason + '.header')
        var description = t('annual_report.not_applicable.' + reason + '.description')
        var note = t('annual_report.not_applicable.' + reason + '.note')

        controller.set('header', header.indexOf('Missing translation:') !== 0 ? header : null)
        controller.set('description', description.indexOf('Missing translation:') !== 0 ? description : null)
        controller.set('note', note.indexOf('Missing translation:') !== 0 ? note : null)
        controller.set('hasBoughtPackage', transition.queryParams.hasBoughtPackage)
        controller.set('reason', reason)
        controller.set('year', transition.params.annual_report && transition.params.annual_report.year)
    }
})
