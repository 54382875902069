var CustomEvent = require('../constants/customEvent')

var FileView = Em.View.extend({
    templateName: 'components/attachments-list-file',

    api: Ember.inject.service(),

    customEvent: Ember.inject.service(),

    removeAttachmentOwnerReference: function(attachmentId) {
        var organizationId = this.container.lookup('controller:organization').get('model.id')

        return this.get('api').putData('/v2/attachments/' + attachmentId + '/removeOwner?organizationId=' + organizationId, {})
            .catch(function(error) {
                console.error(error)
            })
    },

    actions: {
        removeAttachment: function() {
            var attachmentId = this.get('content.id')
            this.get('content').deleteRecord()
            var customEventService = this.get('customEvent')

            if (attachmentId && this.get('parentView.removeOwner')) {
                this.removeAttachmentOwnerReference(attachmentId)
                    .then(function() {
                        // Use Custom events API to notify about updates(React)
                        customEventService.dispatchEvent(CustomEvent.UploadsUpdated)
                    })
            }
        }
    }
})

module.exports = Em.Component.extend({
    layoutName: 'components/attachments-list',
    classNameBindings: [':attachments-list', 'hasFiles:attachments-list-with-files'],

    owner: null,
    attachments: null,

    dropSelector: null,
    dropOverlaySelector: null,
    dropAreaSize: 'large',

    isUploadEnabled: true,

    // Bound from file-uploader in template
    queue: null,
    isUploading: null,
    removeOwner: false,

    fileSizeLimit: null,

    init: function() {
        this._super()
        if (!this.get('dropSelector')) {
            this.set('dropSelector', '.user-main')
        }
    },

    didInsertElement: function() {
        this.$('.button').click(function() {
            var filesEl = $(this).parent().parent().find('.files')
            filesEl.click()
        })
    },

    hasFiles: function() {
        return this.get('attachments.length') > 0 || this.get('queue.length') > 0
    }.property('attachments.length', 'queue.length'),

    actions: {
        didUploadFile: function(context) {
            var owner = this.get('owner')
            var attachment = Billy.Attachment.createRecord({
                organization: this.container.lookup('controller:organization').get('model'),
                owner: owner,
                file: context.file
            })
            if (!owner) {
                // If we don't have an owner yet, then make sure to push the attachments into the array (which is assumed to be a plain native array)
                this.get('attachments').pushObject(attachment)
            }
            // Pass along didUploadFile
            this.sendAction('didUploadFile', context)
        }
    },

    fileViewClass: FileView
})
