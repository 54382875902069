module.exports = Em.Component.extend({
    classNames: ['v2-attachments-editor'],

    changeset: null,

    actions: {
        deleteAttachment: function(attachment) {
            this.get('changeset').deleteAttachment(attachment)
        },

        filesDropped: function(files) {
            this.get('changeset').add(files)
        },

        abort: function(req) {
            this.get('changeset').abort(req)
        }
    }
})
