module.exports = Em.Controller.extend({
    queryParams: {
        initialRoute: 'initialRoute'
    },

    initialRoute: null,

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, props)
        },

        navigateToAccount: function(e) {
            var props = e.detail.props
            var accountId = props.accountId
            var startDate = props.startDate
            var endDate = props.endDate

            this.container.lookup('controller:abstract-report').navigateToAccount(accountId, startDate, endDate)
        }
    }
})
