var t = require('i18n').t
var batmask = require('batmask')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    user: Em.computed.alias('controllers.user.model'),

    startGuide: Em.inject.service(),

    greeting: function() {
        var h = moment().format('HH')

        if (h > 0 && h <= 4) {
            return t('startguide_welcome.hi')
        } else if (h > 4 && h <= 9) {
            return t('startguide_welcome.good_morning')
        } else if (h > 9 && h <= 12) {
            return t('startguide_welcome.good_forenoon')
        } else if (h > 12 && h <= 18) {
            return t('startguide_welcome.good_afternoon')
        } else {
            return t('startguide_welcome.good_evening')
        }
    }.property(),

    isMissingInput: function() {
        return !this.get('user.name') || !this.get('user.phone')
    }.property('user.{phone,name}'),

    actions: {
        nextStep: function() {
            var self = this

            batmask.maskDelayed()

            this.get('user').save()
                .then(function() {
                    self.get('startGuide').completeStep()
                })
                .then(function() {
                    self.send('goToNextStep')
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
