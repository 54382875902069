var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['feature-list-extra-item'],

    classNameBindings: ['available::is-not-available', 'highlight'],

    label: function() {
        return t(this.get('labelT'))
    }.property('labelT'),

    text: function() {
        return t(this.get('textT'))
    }.property('textT'),

    flag: function() {
        if (this.get('flagT')) {
            return t(this.get('flagT'))
        }
    }.property('flagTextT')
})
