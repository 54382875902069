/*
 * custom meta will help us to setup on specific views some custom meta that we want to use.
 * For exmaple login page and sign-up will have different values.
 *
 * Note: Google is using some meta for indexing.
 */

module.exports = Em.Mixin.create({
    // updateMeta will allow us to setup a custom metatag (eg: title or description)
    updateMeta: function(name, value) {
        var meta
        var key

        if (name === 'title') {
            window.document.title = value
        } else {
            key = (name.indexOf('og:') === -1) ? 'name' : 'property'
            // eslint-disable-next-line compat/compat
            meta = document.querySelector('meta[' + key + '="' + name + '"]')

            // Create meta tag HTML element if does not exist
            if (!meta) {
                meta = document.createElement('meta')
                meta.setAttribute(key, name)
                document.getElementsByTagName('head')[0].appendChild(meta)
            }

            meta.setAttribute('data-meta-meta', 'true')
            meta.setAttribute('content', value)
        }
    }
})
