var t = require('i18n').t
var AccountSelectorMixin = require('../mixins/account-selector')
var _ = require('lodash')
var Scope = require('../utils/scope')

module.exports = Em.Controller.extend(AccountSelectorMixin, {
    queryParams: {
        bankConnectionSuccess: 'success',
        /* may not be needed */
        bankConnectionError: 'error'
    },

    api: Em.inject.service(),

    auth: Em.inject.service(),

    needs: ['organization', 'bankAccount'],

    account: Em.computed.alias('controllers.bankAccount.model'),

    bankConnections: Ember.inject.service(),

    lastBankEntryDate: null,

    shouldShowConnectButton: false,

    shouldDisplayRenewConnectionButton: false,

    hasBankIntegration: false,

    hasBankConnections: false,

    lastUpdate: null,

    expiredBankSession: null,

    sortPropertyOptions: function() {
        return [
            {
                value: 'entryDate',
                label: t('bank_sync.entry_date')
            },
            {
                value: 'sideAmount',
                label: t('bank_sync.amount')
            }
        ]
    }.property(),

    isBankSyncReadAuthorized: function() {
        return this.get('auth').isAuthorized(Scope.BankSyncRead)
    }.property(),

    withAuthorization: function(onAuthorized) {
        return this.get('auth').withAuthorization(
            onAuthorized,
            Scope.BankSyncWrite,
            'bank_account_overview_connect_bank'
        )
    },

    canConnectToBank: function() {
        return this.get('isBankSyncReadAuthorized')
    }.property('hasBankIntegration', 'bankConnections.bankConnection'),

    shouldShowBankStatement: function() {
        return !this.get('isBankSyncReadAuthorized')
    }.property(),

    getLastSyncDate: function() {
        var bankAccount = this.get('bankConnections.bankConnection')
        if (bankAccount) {
            var lastTimeUpdated = this.get('bankConnections.bankConnection.lastSynchronizedAt') || this.get('bankConnections.bankConnection.lastPullTime')
            var lastPullTimeFormatted = lastTimeUpdated ? moment(lastTimeUpdated).format('MMM DD YYYY, HH:mm') : null
            this.set('lastUpdate', lastPullTimeFormatted)
        } else {
            this.set('lastUpdate', null)
        }
    }.observes('bankConnections.bankConnection'),

    init: function() {
        var self = this
        var api = this.container.lookup('api:billy')
        var url = '/bankLineMatches?accountId=' + this.get('account.id')

        var bankSyncStartDate = this.get('organization.bankSyncStartDate')
        if (bankSyncStartDate) {
            url += '&entryDatePeriod=from:' + bankSyncStartDate.format('YYYY-MM-DD')
        }

        api.get(url)
            .then(function(payload) {
                var lastBankEntry = _.chain(payload.bankLineMatches)
                    .sortBy('entryDate')
                    .last()
                    .get('entryDate')
                    .value()

                self.set('lastBankEntryDate', lastBankEntry)
            })
    },

    openBankSettingsModal: function() {
        this.withAuthorization(function() {
            this.container.lookup('component:bank-settings-modal')
                .set('accountId', this.get('account.id'))
                .show()
        }.bind(this))
    },

    actions: {
        didClickImport: function() {
            this.transitionToRoute('bank_import')
        },

        didClickArchive: function() {
            this.transitionToRoute('bank_approved_lines')
        },

        didClickBankSettings: function() {
            this.openBankSettingsModal()
        }
    },

    customActions: {
        checkBankIntegration: function(e) {
            if (e.detail.connectedBankData) {
                this.set('hasBankIntegration', true)
                this.set('shouldShowConnectButton', false)
            } else {
                this.set('hasBankIntegration', false)
                this.set('shouldShowConnectButton', true)
            }
            this.set('bankConnections.bankConnection', e.detail.connectedBankData)
            this.set('bankConnections.isLoadingBankConnection', false)
        }
    }
})
