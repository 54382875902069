var _ = require('lodash')
var billyApi = require('./utils/billy-api')
var eventBus = require('./utils/event-bus')
var i18n = require('i18n')
var isTouch = require('./utils/is-touch')
var location = require('./utils/location')
var registerContainerComponents = require('./utils/register-container-components')

var customEvents = {}
if (isTouch) {
    _.merge(customEvents, {
        tap: 'click',
        click: null
    })
}

var jsFiles = require.context('billy-webapp', true, /\.(js)$/)
var hbsFiles = require.context('billy-webapp/templates', true, /\.(hbs)$/)
var resolver = require('ember-module-resolver')(jsFiles, hbsFiles)

// Here we are overwriting the history object used by Ember and we use the same one in the main app and the React app
Ember.HistoryLocation.reopen({
    history: window._sharedHistory
})

var App = Em.Application.extend({
    Resolver: resolver,
    customEvents: customEvents,
    // Override Em.Application.prototype.then since RSVP is trying to call .then on it somewhere but it was deprecated in https://github.com/emberjs/ember.js/pull/5215
    then: null,

    ready: function() {
        $('.loading-screen').remove()
    }
})

App.initializer({
    name: 'locale',
    initialize: function(container) {
        container.register('locale:main', i18n.locale(), { instantiate: false })
    }
})

App.initializer({
    name: 'location',
    initialize: function(container) {
        container.register('location:custom_history', location)
    }
})

App.initializer({
    name: 'billyInjections',
    initialize: function(container) {
        container.injection('super-field-type', 'userController', 'controller:user')
        container.optionsForType('super-field-type', { singleton: false })
    }
})

App.initializer({
    name: 'container-components',
    initialize: registerContainerComponents
})

App.initializer({
    name: 'bd-store-listeners',
    initialize: function() {
        // Forward Billy Data model events
        [
            'account-created',
            'account-updated',
            'account-deleted'
        ].forEach(function(event) {
            BD.store.on(event, function() {
                eventBus.trigger(event)
            })
        });

        // Standard accounting events
        [
            'bank-payment-created',
            'bank-payment-updated',
            'bill-created',
            'bill-updated',
            'contact-balance-payment-created',
            'contact-balance-payment-updated',
            'daybook-transaction-created',
            'daybook-transaction-updated',
            'invoice-created',
            'invoice-updated',
            'invoice-late-fee-created',
            'invoice-late-fee-updated',
            'transaction-created',
            'transaction-updated'
        ].forEach(function(event) {
            BD.store.on(event, function() {
                eventBus.trigger(event)
                eventBus.trigger('accounting-changed')
            })
        });

        // Invoice accounting events
        [
            'bank-payment-created',
            'bank-payment-updated',
            'contact-balance-payment-created',
            'contact-balance-payment-updated',
            'invoice-created',
            'invoice-updated',
            'invoice-deleted',
            'invoice-late-fee-created',
            'invoice-late-fee-updated'
        ].forEach(function(event) {
            BD.store.on(event, function() {
                eventBus.trigger('invoice-accounting-changed')
            })
        });

        // Bill accounting events
        [
            'bank-payment-created',
            'bank-payment-updated',
            'bill-created',
            'bill-updated',
            'bill-deleted',
            'contact-balance-payment-created',
            'contact-balance-payment-updated'
        ].forEach(function(event) {
            BD.store.on(event, function() {
                eventBus.trigger('bill-accounting-changed')
            })
        })
    }
})

App.initializer({
    name: 'request-listeners',
    initialize: function(container) {
        // Instantiate new API client
        var client = billyApi

        // Register on container
        container.register('api:billy', client, { instantiate: false })

        // Any unauthorized request should log out (reset store and transition to login)
        client.on('becameUnauthorized', function() {
            if (container.lookup('controller:user').get('isAuthenticated')) {
                container.lookup('router:main').transitionTo('logout')
            }
        })

        // Override BD's ajax method with client's request method
        BD.reopen({
            ajax: function(hash) {
                return client.request(hash.type, hash.url, hash)
            }
        })

        // Inject the api service into controllers and routes
        container.injection('controller', 'api', 'service:api')
        container.injection('route', 'api', 'service:api')
    }
})

App.initializer({
    name: 'ember-data-store-injections',
    initialize: function(container) {
        container.injection('service', 'store', 'store:main')
    }
})

module.exports = App
