module.exports = Em.Component.extend({
    classNames: ['app-store-item'],

    organization: null,

    app: null,

    containerElementId: function() {
        return this.get('app.key')
    }.property(),

    buttonElementId: function() {
        return this.get('containerElementId') + '-btn'
    }.property('containerElementId'),

    iconUrl: function() {
        return Billy.image(this.get('app.icon'))
    }.property('app.icon')
})
