var _ = require('lodash')

function parseArguments(args, options) {
    return _.flatMap(args, function(path, key) {
        switch (options.types[key]) {
        case 'ID':
            return _.get(options.data.keywords, path)
        default:
            return path
        }
    })
}

function authorizedHelper(args) {
    var auth = this.container.lookup('service:auth')
    var options = args.options
    var scopes = parseArguments(args.scopes, options)

    if (!auth.isAuthorized(scopes, args.isLegacy)) {
        return options.inverse(this)
    }

    return options.fn(this)
}

// Check authorization only for users with new scopes version
Ember.Handlebars.registerHelper('if-authorized', function() {
    var args = [].slice.call(arguments)
    var options = args.pop()

    return authorizedHelper.call(this, {
        scopes: args,
        options: options
    })
})

Ember.Handlebars.registerHelper('authorized-action', function(scope, trackingContext, action, options) {
    var auth = this.container.lookup('service:auth')

    if (!auth.isAuthorized(scope)) {
        return Ember.Handlebars.helpers.action.bind(auth)('showUpgradeOverlay', trackingContext, {
            hash: {
                target: 'controller'
            },
            types: options.types,
            data: {
                view: options.data.view,
                keywords: {
                    controller: auth
                }
            }
        })
    }

    var args = Array.prototype.slice.call(arguments, 2)
    return action ? Ember.Handlebars.helpers.action.apply(this, args) : true
})

Ember.Handlebars.registerHelper('authorized-constrained-action', function(newScope, oldScope, trackingContext, action, options) {
    var auth = this.container.lookup('service:auth')
    var constraints = this.container.lookup('service:constraints')

    if (!auth.isAuthorized(newScope) || !constraints.hasScope(oldScope)) {
        return Ember.Handlebars.helpers.action.bind(auth)('showUpgradeOverlay', trackingContext, {
            hash: {
                target: 'controller'
            },
            types: options.types,
            data: {
                view: options.data.view,
                keywords: {
                    controller: auth
                }
            }
        })
    }

    var args = Array.prototype.slice.call(arguments, 3)
    return action ? Ember.Handlebars.helpers.action.apply(this, args) : true
})

// TODO: Replace with `if-authorized` helper when all users got migrated to the new scope version
// Check authorization for users with any scope version
Ember.Handlebars.registerHelper('if-authorized-legacy', function() {
    var args = [].slice.call(arguments)
    var options = args.pop()

    return authorizedHelper.call(this, {
        scopes: args,
        options: options,
        isLegacy: true
    })
})

Ember.Handlebars.registerHelper('if-authorized-umbrella', function() {
    var args = [].slice.call(arguments)
    var options = args.pop()
    var umbrella = this.container.lookup('service:sideMenu').get('activeUmbrella')

    if (!umbrella) {
        throw new Error('No active umbrella')
    }

    var siloName = 'umbrella'
    var siloId = umbrella.get('id')
    var scopes = args.map(function(scope) {
        return siloName + ':' + siloId + ':' + scope
    })

    return authorizedHelper.call(this, {
        scopes: scopes,
        options: options
    })
})
