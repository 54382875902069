var t = require('i18n').t

module.exports = require('./circle-filter').extend({
    summary: null,

    noPaid: false,

    categories: function() {
        var categories = [
            Em.O({
                key: 'draft',
                value: null,
                name: '',
                badgeType: 'info-highlighted',
                color: 'blue'
            }),
            Em.O({
                key: 'overdue',
                value: null,
                name: '',
                badgeType: 'warning',
                bubble: 0,
                color: 'red'
            }),
            Em.O({
                key: 'unpaid',
                value: null,
                name: '',
                badgeType: 'attention',
                circleValueFn: function(categories) {
                    return categories.findBy('key', 'unpaid').get('value') - categories.findBy('key', 'overdue').get('value')
                },
                color: 'yellow'
            }),
            !this.get('noPaid') && Em.O({
                key: 'paid',
                value: null,
                name: '',
                badgeType: 'success',
                color: 'green'
            })
        ]
        categories = categories.filter(function(category) {
            return !!category
        })
        return categories
    }.property(),

    summaryDidChange: function() {
        var doesSummaryExist = !!this.get('summary')
        var summary = this.get('summary') || {}
        var all = summary.all || { amount: 0, count: 0 }
        this.get('categories').forEach(function(category) {
            var key = category.get('key')
            var d = summary[key] || { amount: 0, count: 0 }

            if (doesSummaryExist) {
                category.set('value', d.amount)
            }

            category.set('amount', d.count)
            category.set('name', t('invoices.list.category.' + key, { count: d.count }))
        })

        if (doesSummaryExist) {
            this.set('allValue', all.amount)
        }

        this.set('allName', t('invoices.list.category.all', { count: all.count }))
        this.set('allAmount', all.count)
    }.observes('summary').on('init')
})
