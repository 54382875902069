var t = require('i18n').t
var OrganizationBrand = require('../constants/organizationBrand')
var OrganizationSource = require('../constants/organizationSource')
var SubscriptionProductPlan = require('../constants/subscriptionProductPlan')

Billy.Organization.reopen({
    settings: null,

    cardSubscriptions: [],

    activeCardSubscriptions: function() {
        return _(this.get('cardSubscriptions'))
            .filter({ state: 'active' })
            .sortBy('createdTimestamp')
            .value()
    }.property('cardSubscriptions'),

    defaultActiveCardSubscription: function() {
        return _.find(this.get('cardSubscriptions'), { state: 'active', isDefault: true }) || null
    }.property('cardSubscriptions'),

    isCompany: Em.computed.equal('companyType', 'company'),

    isProprietorship: Em.computed.equal('companyType', 'proprietorship'),

    isOther: Em.computed.equal('companyType', 'other'),

    unpaidInvoices: function() {
        return Billy.OrganizationInvoice.filter({
            query: {
                organization: this,
                state: 'unpaid'
            },
            sortProperty: 'createdTime',
            sortDirection: 'asc'
        })
    }.property(),

    hasUnpaidInvoices: function() {
        return this.get('unpaidInvoices').length > 0
    }.property(),

    isSubscriptionPlanBasic: Em.computed.equal('subscriptionPlan', 'basic'),

    isSubscriptionPlanPremium: Em.computed.equal('subscriptionPlan', 'premium'),

    isSubscriptionPlanPaid: function() {
        return this.get('subscriptionPlan') !== 'free' && this.get('subscriptionPlan') !== 'basic'
    }.property('subscriptionPlan'),

    isDk: Em.computed.equal('country.id', 'DK'),

    isFr: Em.computed.equal('country.id', 'FR'),

    isBillyProviderDk: Em.computed.equal('billyProviderId', 'dk'),

    isAllowedForUpodiMigration: function() {
        var isLocked = this.get('isLocked')
        var isFree = this.get('isTrial') || this.get('subscriptionPlan') === 'basic'
        var isConsolidatedBilling = this.get('consolidatedBilling')
        var hasUnpaidInvoices = this.get('hasUnpaidInvoices')

        return !isConsolidatedBilling &&
            !hasUnpaidInvoices &&
            (isLocked || isFree)
    }.property('isTrial', 'subscriptionPlan', 'consolidatedBilling', 'hasUnpaidInvoices'),

    salesTaxTerm: function() {
        return this.get('hasVat') ? t('organization.sales_tax_term.vat') : t('organization.sales_tax_term.sales_tax')
    }.property('hasVat'),

    useSimpleTaxRates: function() {
        return !this.get('hasVat')
    }.property('hasVat'),

    supportsEnumBanks: function() {
        return ['DK', 'FO', 'GL', 'US'].contains(this.get('country.id'))
    }.property('country.id'),

    isFECReportEnabled: function() {
        return this.get('country.id') === 'FR'
    }.property('country.id'),

    isVoucherScanSettingVisible: function() {
        return this.get('isSubscriptionPlanPremium') &&
            ['DK', 'FO', 'GL'].contains(this.get('country.id'))
    }.property('isSubscriptionPlanPremium', 'country.id'),

    isBrandBilly: function() {
        return this.get('brand') === OrganizationBrand.Billy
    }.property('brand'),

    isBrandAgeras: function() {
        return this.get('brand') === OrganizationBrand.Ageras
    }.property('brand'),

    isSourceBilly: function() {
        return this.get('source') === OrganizationSource.Billy
    }.property('source'),

    isSourceZervant: function() {
        return this.get('source') === OrganizationSource.Zervant
    }.property('source'),

    isCompletePlan: function() {
        return this.get('subscriptionProductPlan') === SubscriptionProductPlan.Complete
    }.property('subscriptionProductPlan'),

    apiCountryKey: function() {
        return this.get('isFr') ? 'fr' : 'dk'
    }.property('isFr'),

    hasCashBasedAccounting: function() {
        return this.get('accountingBasis') === 'cash'
    }.property('accountingBasis')
})
