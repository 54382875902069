module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/match-subject',

    classNames: ['bank-sync-v2-match-subject'],

    selectMode: false,

    subject: null,

    canUngroup: function() {
        return this.get('index') !== 0
    }.property('index'),

    actions: {}
})
