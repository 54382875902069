var postingSides = require('posting-sides')

module.exports = Em.Component.extend({
    layoutName: 'components/transaction-manual-line',

    classNames: ['field-group', 'manual-transaction-line'],

    content: null,

    actions: {
        delete: function() {
            this.get('content').deleteRecord()
        }
    },

    debit: null,
    debitDidChange: function() {
        if (this.get('debit') !== null) {
            this.set('credit', null)
        }
        this.set('content.side', postingSides.debit)
        this.set('content.amount', this.get('debit'))
    }.observes('debit'),

    credit: null,
    creditDidChange: function() {
        if (this.get('credit') !== null) {
            this.set('debit', null)
        }
        this.set('content.side', postingSides.credit)
        this.set('content.amount', this.get('credit'))
    }.observes('credit'),

    hasMoreThanTwoLines: Em.computed.gt('content.daybookTransaction.lines.length', 2)
})
