var t = require('i18n').t
var tProperty = require('i18n').tProperty
var imageThumbnail = require('../utils/image-thumbnail')

module.exports = Em.Component.extend({
    classNames: ['v2-file-upload-field'],

    placeholder: tProperty('placeholder', t('v2_file_upload_field.default_placeholder')),

    error: null,

    url: null,

    downloadUrl: null,

    accept: null,

    thumbnailUrl: function() {
        var url = this.get('downloadUrl')
        if (!url) {
            return null
        }
        return imageThumbnail(url, {
            size: '256x128'
        })
    }.property('downloadUrl'),

    uploadToolText: function() {
        return this.get('url') ? t('change') : t('v2_file_upload_field.upload_tool_text')
    }.property('url'),

    uploadReq: null,

    upload: function(file) {
        var self = this

        if (this.get('accept') && this.get('accept').indexOf(file.type) < 0) {
            self.set('error', t('v2_file_upload_field.error'))
            return
        }
        var req = this.container.lookup('util:v2-file-upload-request', { singleton: false })
        this.sendAction('uploadInProgress', true)
        req.set('file', file)
        self
            .set('error', null)
            .set('uploading', true)
            .set('uploadReq', req)

        return req.upload()
            .then(function(result) {
                self.set('url', result.url)
                self.set('downloadUrl', result.downloadUrl)
                self.sendAction('uploaded', result.url)
                return result.url
            }, function(e) {
                if (e.code !== 'FILE_UPLOAD_ABORTED') {
                    var error = e.code === 'FILE_UPLOAD_ERROR' ? e.message : t('attachments_changeset.upload_failed')
                    self.set('error', error)
                        .set('url', null)
                }
            })
            .finally(function() {
                self.set('uploadReq', null)
                self.sendAction('uploadInProgress', false)
            })
    },

    actions: {
        filesSelected: function(files) {
            if (this.get('uploadReq')) {
                return
            }
            this.upload(files[0])
        },

        remove: function() {
            this.set('url', null)
            this.sendAction('removed')
        },

        abortUpload: function() {
            this.get('uploadReq').abort()
        }
    }
})
