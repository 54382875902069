module.exports = Em.Component.extend({
    layoutName: 'components/external-invoices-sync-top-bar',

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
