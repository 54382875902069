var parseDate = require('i18n-parse-date')
var parseNumber = require('../utils/parse-number')
var _ = require('lodash')
var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    api: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    lines: null,

    columnMapping: null,

    columnCount: null,

    duplicateLines: null,

    duplicatesCount: 0,

    selectDuplicates: null,

    minTimeUploading: false,

    uploadComplete: false,

    bankLineGroupId: null,

    completionModal: null,

    hasAbortedImport: false,

    mappingOptions: {},

    hasUploadFailed: false,

    importDisabled: function() {
        var selectedLines = _.filter(this.get('lines'), { isSelected: true })
        var errorsInSelectedLines = _.some(selectedLines, { hasError: true })

        return selectedLines.length === 0 || errorsInSelectedLines
    }.property('lines.@each.isSelected'),

    hasSelectedLines: function() {
        return _.filter(this.get('lines'), { isSelected: true }).length > 0
    }.property('lines.@each.isSelected'),

    // Bootstraps the lines model, and will be controlled from `mappingDidChange` after
    // initial execution. Will re-bootstrap whenever the (sideloaded) model changes.
    bootstrapLinesModel: function() {
        var self = this
        var payload = this.get('model.payload')

        // This is used to reset hasUploadFailed when a new file is imported
        this.set('hasUploadFailed', false)

        this.set('columnCount', payload.lines[0].length)

        var lines = payload.lines.map(function(line, index) {
            return {
                index: index,
                // Will be overwritten by `mappingDidChange`
                hasError: false,
                isSelected: self.get('indexes').indexOf(index) >= 0,
                fields: line.map(function(value) {
                    return {
                        // Will be overwritten by `mappingDidChange`
                        hasError: false,
                        value: value
                    }
                })
            }
        })

        this.set('lines', lines)
    }.observes('model.payload.lines'),

    mappingDidChange: function() {
        var self = this

        var lines = this.get('lines').map(function(line, index) {
            var fields = line.fields.map(function(field, index) {
                return {
                    hasError: !self.validateField(field.value, index),
                    value: field.value
                }
            })

            var hasInvalidFields = fields.filter(function(field) {
                return field.hasError
            }).length > 0

            return {
                index: index,
                hasError: hasInvalidFields,
                isSelected: self.get('lines.' + index + '.isSelected'),
                fields: fields
            }
        })

        this.set('lines', lines)
    }.observes('columnMapping.@each.value'),

    checkForTooManyColumnErrors: function() {
        var lines = this.get('lines')
        var totalLines = lines.length

        var mappings = this.get('columnMapping')

        // If our column mappings are undefined, we can't have invalid fields
        if (!mappings) {
            return
        }

        var validColumnErrors = lines.reduce(function(acc, line) {
            var lineErrors = line.fields.reduce(function(lineAcc, field, index) {
                if (field.hasError) {
                    lineAcc[index] = true
                }
                return lineAcc
            }, [])

            lineErrors.forEach(function(item, index) {
                var value = mappings[index].value
                acc[value] += 1
            })

            return acc
        }, {
            amount: 0,
            date: 0,
            description: 0
        })

        // Reset all column mappings, just in case a mapping has changed, and we
        // don't hit that one again
        this.get('columnMapping').setEach('error', false)

        var hasTooManyAmountErrors = (validColumnErrors.amount / totalLines) > 0.5
        var hasTooManyDateErrors = (validColumnErrors.date / totalLines) > 0.5
        var hasTooManyDescriptionErrors = (validColumnErrors.description / totalLines) > 0.5

        var amountMapping = _.find(mappings, { value: 'amount' })
        var dateMapping = _.find(mappings, { value: 'date' })
        var descriptionMapping = _.find(mappings, { value: 'description' })

        var amountIndex = _.get(amountMapping, 'index')
        var dateIndex = _.get(dateMapping, 'index')
        var descriptionIndex = _.get(descriptionMapping, 'index')

        if (typeof amountIndex !== 'undefined') {
            this.set('columnMapping.' + amountIndex + '.error', hasTooManyAmountErrors ? t('bank_import.column_selector.invalid_value') : false)
        }

        if (typeof dateIndex !== 'undefined') {
            this.set('columnMapping.' + dateIndex + '.error', hasTooManyDateErrors ? t('bank_import.column_selector.invalid_value') : false)
        }

        if (typeof descriptionIndex !== 'undefined') {
            this.set('columnMapping.' + descriptionIndex + '.error', hasTooManyDescriptionErrors ? t('bank_import.column_selector.invalid_value') : false)
        }
    }.observes('lines'),

    checkForDuplicateLines: function() {
        var self = this

        this.set('duplicatesCount', 0)
        this.set('duplicateLines', null)

        // Upon a recomputation of our lines, we check whether we have three mapped columns (just enough for a valid import)
        var mappings = _.filter(this.get('columnMapping'), function(mapping) {
            // We assume that there can only be one mapping to each type
            if (['date', 'amount', 'description'].includes(mapping.value)) {
                return true
            }

            return false
        })

        // If we don't have three mappings, we abort
        if (mappings.length !== 3) {
            // Reset mapping options
            this.set('mappingOptions', {})
            return
        }

        // Find the column indices, so we can use them to get the proper fields
        // from our lines
        var dateIndex = _.find(mappings, { value: 'date' }).index
        var amountIndex = _.find(mappings, { value: 'amount' }).index
        var descriptionIndex = _.find(mappings, { value: 'description' }).index

        // Set mapping options, so we can use it while importing
        this.set('mappingOptions', {
            dateIndex: dateIndex,
            amountIndex: amountIndex,
            descriptionIndex: descriptionIndex
        })

        // Map our lines to something our API endpoint can understand
        var mappedLines = _.filter(this.get('lines'), { hasError: false }).map(function(line) {
            var amount = parseNumber(line.fields[amountIndex].value)
            var date = parseDate(line.fields[dateIndex].value)
            return {
                csvLineId: line.index,
                entryDate: date.format('YYYY-MM-DD'),
                amount: Math.abs(amount),
                description: line.fields[descriptionIndex].value
            }
        })

        // Abort if we didn't have any OK lines
        if (mappedLines.length < 1) {
            return
        }

        // Call our API to find duplicate lines on a given bank account.
        // This endpoint accept an array of `lines`, with three required
        // properties: `entryDate`, `amount` and `description`.
        // It allows to send more properties, at will, and will also inject any
        // other properties provided, along with the `bankLineId` of the
        // existing line to the response, if any duplicates were found.
        batmask.mask()
        this.get('api').request('POST', '/v2/bankStatements/check?accountId=' + this.get('model.id'), {
            payload: {
                record: {
                    lines: mappedLines
                }
            }
        }).then(function(res) {
            var lines = res.record.lines
            var duplicateLines = _.reject(lines, { bankLineId: null })

            self.set('duplicateLines', duplicateLines)
        }).finally(function() {
            batmask.unmask()
        })
    }.observes('lines'),

    duplicateLinesDidChange: function() {
        var duplicateLines = this.get('duplicateLines')

        if (!duplicateLines) {
            this.set('selectDuplicates', null)
            return
        }

        this.set('duplicatesCount', duplicateLines ? duplicateLines.length : 0)
        this.set('selectDuplicates', false)
    }.observes('duplicateLines'),

    selectDuplicatesDidChange: function() {
        if (this.get('selectDuplicates') === null) {
            return
        }

        var self = this
        var duplicateLines = this.get('duplicateLines')

        // We want to (un)select any duplicate lines depending on the
        // selectDuplicates toggle
        duplicateLines.forEach(function(duplicateLine) {
            var csvLineByIndex = self.get('lines').findBy('index', duplicateLine.csvLineId)
            var lineIndex = self.get('lines').indexOf(csvLineByIndex)

            self.set('lines.' + lineIndex + '.isSelected', !!self.get('selectDuplicates'))
        })
    }.observes('selectDuplicates'),

    validateField: function(value, index) {
        var columnMapping = this.get('columnMapping')

        // We have no column mapping yet, so we assume every field
        // is valid.
        if (columnMapping === null) {
            return true
        }

        var mapping = columnMapping.get(index)
        if (!mapping) {
            return true
        }

        var columnType = mapping.value
        switch (columnType) {
        case 'date':
            return !!parseDate(value)
        case 'amount':
            return !!parseNumber(value)
        }

        return true
    },

    parsedHeaderMapping: function() {
        var payload = this.get('model.payload')
        var mapping = {}
        for (var key in payload.columns) {
            mapping[payload.columns[key]] = key
        }
        return mapping
    }.property('model.payload'),

    indexes: function() {
        return this.get('model.payload.indexes')
    }.property('model.payload.indexes'),

    accountName: function() {
        return this.get('model.name')
    }.property('model'),

    allColumnsAutoMapped: function() {
        return Object.keys(this.get('parsedHeaderMapping')).length === this.get('columnCount')
    }.property('parsedHeaderMapping', 'columnCount'),

    readyToClose: function() {
        if (
            this.get('uploadComplete') &&
            this.get('minTimeUploading') &&
            this.get('completionModal')
        ) {
            this.get('completionModal').close()

            this.set('uploadComplete', false)
            this.set('minTimeUploading', false)
            this.set('completionModal', false)

            // Transition to bank sync, if the import wasn't aborted
            if (!this.get('hasAbortedImport') && !this.get('hasUploadFailed')) {
                this.transitionToRoute('bank_sync')
            }
        }
    }.observes('uploadComplete', 'minTimeUploading'),

    // Recursive function for sending a batch of lines one at a time,
    // and notifying the import progress component slowly about progress.
    importBatch: function(index, selectedLines) {
        var self = this

        if (this.get('hasAbortedImport')) {
            return Ember.RSVP.all([
                this.removeBankLineGroup(this.get('bankLineGroupId')),
                this.get('completionModal').doAbortProgress(this.get('completionModal.uploadProgress'))
            ])
        }

        var batches = _.chunk(selectedLines, 10)

        if (index > batches.length - 1) {
            // TODO: Warning dialog or something?
            return Em.RSVP.Promise.resolve()
        }

        var batch = batches[index]

        var mappedLines = _.map(batch, function(line) {
            var amount = parseNumber(line.fields[self.get('mappingOptions.amountIndex')].value)
            var date = parseDate(line.fields[self.get('mappingOptions.dateIndex')].value)
            return {
                accountId: self.get('model.id'),
                groupId: self.get('bankLineGroupId'),
                entryDate: date.format('YYYY-MM-DD'),
                description: line.fields[self.get('mappingOptions.descriptionIndex')].value,
                amount: Math.abs(amount),
                side: amount < 0 ? 'credit' : 'debit'
            }
        })

        return self.get('api').patch('/v2/bankLines', {
            payload: {
                bankLines: mappedLines
            }
        }).then(function() {
            var importedLines = self.get('completionModal.importedLines')
            self.get('completionModal').set('importedLines', importedLines + batch.length)

            return self.importBatch(index + 1, selectedLines)
        }).catch(function() {
            self.set('hasUploadFailed', true)
            self.removeBankLineGroup(self.get('bankLineGroupId'))
            return Em.RSVP.Promise.resolve()
        })
    },

    // Will delete a bank line group, and underlying the associated bank lines
    // will also be deleted
    // A future improvement could be to do some diffing on imported and deleted
    // lines, but we'll just assume that everything gets deleted correctly for
    // now.
    removeBankLineGroup: function(bankLineGroupId) {
        return this.get('api').request('DELETE', '/v2/bankLineGroups/' + bankLineGroupId)
    },

    actions: {
        columnMappingDidChange: function(columnMapping) {
            this.set('columnMapping', columnMapping)
        },

        startImport: function() {
            var self = this

            // The active import hasn't been aborted, bus should be abortable
            this.set('hasAbortedImport', false)

            // We can't import without our mapping indices
            if (
                Ember.isEmpty(this.get('mappingOptions.amountIndex')) ||
                Ember.isEmpty(this.get('mappingOptions.dateIndex')) ||
                Ember.isEmpty(this.get('mappingOptions.descriptionIndex'))
            ) {
                this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_IMPORT_START, t('bank_import.error_no_column_selected'))
                return false
            }

            // First we'll look for any selected lines containing errors. If there's any, we'll show an error modal
            var selectedLines = _.filter(this.get('lines'), { isSelected: true })
            // If the selected lines contains any lines containing an error
            if (_.some(selectedLines, { hasError: true })) {
                this.container.lookup('util:dialog').dialog(
                    t('bank_import.errornous_lines.title'),
                    t('bank_import.errornous_lines.description').htmlSafe(),
                    [
                        {
                            text: t('bank_import.errornous_lines.go_back'),
                            align: 'center'
                        }
                    ],
                    {
                        focusSelector: '.window-footer button.button',
                        closable: true
                    }
                )

                return
            }

            // Otherwise we'll open a popup, and start the import.
            var completionModal = this.container.lookup('component:bank-imports-upload-progress')
            this.set('completionModal', completionModal)

            // If the modal triggers an aborted import, we'll set a flag, so our
            // import function can pick it up
            completionModal.on('importAborted', function() {
                self.set('hasAbortedImport', true)
            })

            // Open the popup
            completionModal.show()

            // The popup should be open for a minimum of 2000ms, so we set up
            // a setter to be run in 2000ms, to change a property which we're
            // observing to close our popup along with the actual import completion
            setTimeout(function() {
                self.set('minTimeUploading', true)
            }, 2000)

            // We'll initiate the import by creating one single bank line group,
            // which will correspond to this particular import.
            Billy.BankLineGroup.createRecord({
                organization: self.get('organization'),
                name: self.get('model.payload.file.name')
            }).save().then(function(response) {
                self.set('bankLineGroupId', response.bankLineGroups[0].id)

                self.get('completionModal').set('totalLines', selectedLines.length)

                // Upon creation of the bank line group, we can start importing
                // our lines. We'll PATCH them in batches of 10 lines, to minify
                // the load, and also be able to count towards progress
                self.importBatch(0, selectedLines).then(function() {
                    self.set('uploadComplete', true)
                })
            })
        }
    }
})
