var AuthorizedRoute = require('../mixins/authorized-route')
var Scope = require('../utils/scope')

module.exports = require('./abstract-annual-report').extend(AuthorizedRoute, {
    scopes: [Scope.AnnualReportRead],

    userOrganizations: Em.inject.service(),

    activate: function() {
        this.transitionTo('annual_report', 0)
    }
})
