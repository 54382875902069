var _ = require('lodash')
var ReactDirtyRouteMixin = require('../mixins/react-dirty-route')

module.exports = Em.Controller.extend(ReactDirtyRouteMixin, {
    queryParams: {
        bulkPage: 'bulkPage',
        bulkPageSize: 'bulkPageSize',
        bulkSortDirection: 'bulkSortDirection',
        bulkSortProperty: 'bulkSortProperty',
        bulkUploadType: 'bulkUploadType',
        categoryKey: 'category',
        contactId: 'contactId',
        contactName: 'contactName',
        initialRoute: 'initialRoute',
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        status: 'status'
    },

    bulkPage: null,
    bulkPageSize: null,
    bulkSortDirection: null,
    bulkSortProperty: null,
    bulkUploadType: null,
    categoryKey: null,
    contactId: null,
    contactName: null,
    initialRoute: null,
    page: null,
    pageSize: null,
    period: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,
    status: null,

    needs: ['user'],

    segment: Em.inject.service(),

    activeOrganization: Em.computed.alias('controllers.user.activeOrganization'),

    customActions: function() {
        var self = this

        return _.merge(this.get('_customActions'), {
            navigate: function(e) {
                var route = e.detail.route
                var props = e.detail.props || { queryParams: {} }

                self.transitionToRoute(route, props)
            },

            registerPayment: function(e) {
                var self = this
                var props = e.detail.props || {}
                var billId = props ? props.id : undefined
                var accountId = props ? props.accountId : undefined
                var onBack = props ? props.onBack : undefined

                return Billy.Bill.find(billId).promise
                    .then(function(bill) {
                        self.container.lookup('component:register-payment-window')
                            .set('onBack', onBack)
                            .set('accountId', accountId)
                            .showForSubject(bill)
                    })
            },

            invalidateBill: function(e) {
                var props = e.detail.props || {}
                var billId = props ? props.id : undefined

                if (!billId) {
                    return
                }

                Billy.Bill.find(billId).promise.then(function(billInstance) {
                    billInstance.unload()
                })
            },

            connectBank: function(event) {
                var bankAccountId = event.detail.bankAccountId

                Billy.Account.findByIdQuery(bankAccountId).promise.then(function(bankAccount) {
                    self.transitionToRoute('bank_sync', self.get('activeOrganization'), bankAccount)
                })
            }
        })
    }.property('_customActions')
})
