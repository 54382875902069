var organizationIcon = require('../utils/organization-icon')

module.exports = Em.Component.extend({
    classNames: ['avatar'],

    src: null,

    name: null,

    fixedSrc: function() {
        return organizationIcon(this.get('src'))
    }.property('src')
})
