var t = require('i18n').t
var AddOnIntegerComponent = require('./add-on-integer')

module.exports = AddOnIntegerComponent.extend({
    classNames: ['add-on-extra-seats'],

    organizationSubscription: Em.inject.service(),

    // FIXME User usage should come from a organizationUsers service which would keep the count.
    // Having it on the backend, causes invalid state if users and subscription mutates at the same time.
    usedSeats: Em.computed.alias('organizationSubscription.usedSeats'),

    min: Em.computed.alias('usedSeats'),

    max: null,

    description: function() {
        return t(
            'organization_subscription.addon.extra_seats.description',
            { price: Billy.money(this.get('price'), 'DKK', '0,0') }
        )
    }.property('price'),

    isDisabled: function() {
        return this.get('usedSeats') > 0
    }.property('usedSeats'),

    setupModel: function() {
        // Force users to buy at least what they use
        if (!this.get('model.value') || this.get('model.value') < this.get('usedSeats')) {
            this.set('model.value', this.get('usedSeats'))
        }
    }.on('init')
})
