var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    coupons: Em.inject.service(),

    classNames: ['coupon-code'],

    organization: null,

    isFormVisible: false,

    isCouponCodeRedeemed: function() {
        return this.get('organization.couponCode')
    }.property('organization.couponCode'),

    displayErrorNotification: function() {
        this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.invalid_coupon'))
    },

    displaySuccessNotification: function() {
        this.container.lookup('util:notification').success(NOTIFICATION_KEYS.COUPON_REDEEM, t('coupon.upgraded_to_premium'))
    },

    actions: {
        toggleCouponForm: function() {
            this.toggleProperty('isFormVisible')
        },

        redeemCoupon: function() {
            var self = this
            var coupons = this.get('coupons')

            batmask.maskDelayed()
            coupons.redeemCoupon(this.get('organization.id'), this.get('couponCode'))
                .then(function() { // coupon is valid and redeemed
                    batmask.unmask()
                    self.toggleProperty('isFormVisible')

                    if (self.get('redeemCouponSuccess')) {
                        self.sendAction('redeemCouponSuccess')
                    } else {
                        self.displaySuccessNotification()
                    }
                })
                .catch(function() { // invalid coupon
                    batmask.unmask()

                    if (self.get('redeemCouponError')) {
                        self.sendAction('redeemCouponError', t('coupon.invalid_coupon'))
                    } else {
                        self.displayErrorNotification()
                    }
                })
        }
    }
})
