var Scope = require('../utils/scope')

var tProperty = require('i18n').tProperty

module.exports = Em.Component.extend({
    classNames: ['upgrade-plan'],

    auth: Ember.inject.service(),

    organizationSubscription: Em.inject.service(),

    title: tProperty('title'),

    subtitle: tProperty('subtitle'),

    text: tProperty('text'),

    youtubeId: tProperty('youtubeId'),

    changeSubscriptionComponent: 'upgrade-plan-overlay',

    youtubeVideoSrc: function() {
        var id = this.get('youtubeId')

        if (!id) {
            return null
        }

        return '//www.youtube.com/embed/' + id + '?rel=0&color=white&showinfo=0&wmode=transparent'
    }.property('youtubeId'),

    actions: {
        didClickChangeSubscription: function() {
            var auth = this.get('auth')

            if (!auth.isAuthorized(Scope.OrganizationSubscriptionWrite)) {
                this.container.lookup('component:upgrade-plan-overlay').show()
                return
            }

            this.container.lookup('router:main').transitionTo('organization_subscription')
        }
    }
})
