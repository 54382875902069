var imageThumbnail = require('../utils/image-thumbnail')

module.exports = Em.Component.extend({
    classNames: ['v2-attachments-list-item'],

    attachment: null,

    thumbnailUrl: function() {
        return imageThumbnail(this.get('attachment.url'), {
            standard: 'attachment-s'
        })
    }.property('attachment.url')
})
