var t = require('i18n').t
var AddOnBooleanComponent = require('./add-on-boolean')

module.exports = AddOnBooleanComponent.extend({
    classNames: ['add-on-unlimited-postings'],

    description: function() {
        if (this.get('organization.hasUnlimitedPostingsAddon')) {
            return t(
                'organization_subscription.addon.unlimited_postings.description.without_limit',
                { postingCount: this.get('model.postingCount') }
            )
        }

        return t(
            'organization_subscription.addon.unlimited_postings.description',
            { postingCount: this.get('model.postingCount'), postingLimit: this.get('model.postingLimit') }
        )
    }.property('model.postingCount,model.postingLimit'),

    isDisabled: function() {
        return this.get('model.postingCount') >= this.get('model.postingLimit')
    }.property('model.postingCount,model.postingLimit'),

    setupModel: function() {
        // Force activation if used postings exceed the limit
        if (this.get('model.postingCount') >= this.get('model.postingLimit')) {
            this.set('model.value', true)
        }
    }.on('init')
})
