module.exports = Em.Component.extend({
    classNames: ['bank-import-table'],

    classNameBindings: ['compressed', 'verticalScrollDisabled', 'horizontalScrollDisabled'],

    allLinesSelected: false,

    hideValidLines: false,

    // Initialized by template component
    parsedHeaderMapping: null,

    // Initialized by template component
    columnCount: null,

    columnMapping: null,

    verticalScrollDisabled: false,

    horizontalScrollDisabled: false,

    compressed: function() {
        return this.get('duplicatesCount') > 0
    }.property('duplicatesCount'),

    // Contains a mapping of what value each column is mapped to
    columnMappingInit: function() {
        var indices = Ember.A()

        for (var i = 0; i < this.get('columnCount'); i++) {
            if (this.get('parsedHeaderMapping')[i]) {
                indices.pushObject({
                    index: i,
                    value: this.get('parsedHeaderMapping')[i],
                    error: false
                })
            } else {
                indices.pushObject({ index: i, value: null, error: false })
            }
        }

        this.set('columnMapping', indices)
    }.observes('columnCount', 'parsedHeaderMapping').on('init'),

    columnUpdateAction: 'columnMappingDidChange',

    columnMappingDidChange: function() {
        this.sendAction('columnUpdateAction', this.get('columnMapping'))
    }.observes('columnMapping.@each.value'),

    // Initialized by template component
    lines: null,

    // Compute an array of objects, containing the intended values which will
    // be imported as bank lines.
    serializedLines: function() {
        var self = this

        return self.get('lines').map(function(line) {
            var mappedLine = line.map(function(field, index) {
                var serializedLine = {}
                var mapping = self.get('columnMapping').findBy('index', index)

                if (mapping.value) {
                    serializedLine[mapping.value] = field
                    return serializedLine
                }
            })

            return _.reduce(mappedLine, function(collection, a) {
                return _.merge(collection, a)
            }, {})
        })
    }.property('lines', 'columnMapping.@each.value'),

    allLinesSelectedDidChange: function() {
        var allLinesSelected = this.get('allLinesSelected')

        this.get('lines').setEach('isSelected', allLinesSelected)
    }.observes('allLinesSelected'),

    invalidLines: function() {
        return _.filter(this.get('lines'), { hasError: true })
    }.property('lines'),

    didInsertElement: function() {
        var self = this
        var intent

        this._super()
        this.setupPositionedScrollbar()

        // Redraw when window resizes (throttled)
        $(window).resize(function() {
            clearTimeout(intent)
            intent = setTimeout(function() {
                self.setupPositionedScrollbar()
            }, 250)
        })
    },

    setupPositionedScrollbar: function() {
        var $scrollContentVertical = $(this.element).find('tbody')
        var $scrollContentHorizontal = $(this.element).find('.sticky-header-scroll-area')
        var $scrollbarVertical = $(this.element).find('.positioned-scrollbar--vertical')
        var $scrollbarHorizontal = $(this.element).find('.positioned-scrollbar--horizontal')
        var scrollContentHeight = $scrollContentVertical.outerHeight(true)
        var scrollContentWidth = $scrollContentHorizontal.outerWidth(true)
        var scrollContentScrollHeight = $scrollContentVertical.prop('scrollHeight')
        var scrollContentScrollWidth = $scrollContentHorizontal.prop('scrollWidth')
        var scrollbarHorizontalWidth = scrollContentWidth * scrollContentWidth / scrollContentScrollWidth
        var scrollbarVerticalHeight = scrollContentHeight * scrollContentHeight / scrollContentScrollHeight

        if (scrollContentHeight < scrollContentScrollHeight) {
            $scrollbarVertical
                .height(scrollbarVerticalHeight)
                .draggable({
                    axis: 'y',
                    containment: 'parent',
                    drag: function(evt, ui) {
                        $scrollContentVertical.scrollTop(scrollContentScrollHeight / scrollContentHeight * ui.position.top)
                    }
                })

            $scrollContentVertical.on('scroll', function() {
                $scrollbarVertical.css({ top: ($scrollContentVertical.scrollTop() / scrollContentHeight * scrollbarVerticalHeight) + 'px' })
            })
        } else {
            this.set('verticalScrollDisabled', true)
        }

        if (scrollContentWidth < scrollContentScrollWidth) {
            $scrollbarHorizontal
                .width(scrollbarHorizontalWidth)
                .draggable({
                    axis: 'x',
                    containment: '.content-width',
                    drag: function(evt, ui) {
                        $scrollContentHorizontal.scrollLeft(scrollContentScrollWidth / scrollContentWidth * ui.position.left)
                    }
                })

            $scrollContentHorizontal.on('scroll', function() {
                $scrollbarHorizontal.css({ left: ($scrollContentHorizontal.scrollLeft() / scrollContentWidth * scrollbarHorizontalWidth) + 'px' })
            })
        } else {
            this.set('horizontalScrollDisabled', true)

            // Setting `display: block;` on `table-row-group` elements restricts them
            // to `width: auto` so, if the content does not overflow horizontally,
            // the column headings need to be forced into position
            var $headings = $('thead th') // Assume there will only be one of these tables per page
            var $cells = $('tbody tr:first-child td') // Assume there will only be one of these tables per page

            var summedWidths = 0
            $cells.each(function(index) {
                var $heading = $($headings.get(index))
                var width = $(this).outerWidth()
                summedWidths = summedWidths + width

                if (
                    $heading && // If this condition is not met then column count is off between header and body. Should not occur though.
                    index > 0 && // The first cell will be a fixed-width checkbox cell
                    index < $headings.length - 1 // The last cell will not stretch to fit, so the heading has to be forced
                ) {
                    $heading.width(width)
                } else if (index === $headings.length - 1) {
                    var tableWidth = $('table').outerWidth()
                    var lastColWidth = $heading.outerWidth() + tableWidth - summedWidths
                    $heading.width(lastColWidth)

                    // Preserve full width of yellow error background.
                    // Has to be compensated after the table has fully rendered.
                    Em.run.next(function() {
                        $('tbody td:last-child').width(lastColWidth)
                    })
                }
            })
        }
    },

    actions: {
        columnDidChange: function(action) {
            // Remove the mapping from the column this value was previously mapped to
            var previousMapping = this.get('columnMapping').findIndex(function(entry) {
                return entry.value === action.value
            })

            if (previousMapping >= 0) {
                this.get('columnMapping').set(previousMapping + '.value', null)
            }
        }
    }
})
