module.exports = Em.ObjectController.extend({
    needs: ['application'],

    sideMenu: Em.inject.service(),

    activeOrganization: Em.computed.alias('sideMenu.activeOrganization'),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
