var t = require('i18n').t
var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/bank-sync-difference-popover'),

    classNames: ['bank-sync-difference-popover'],

    position: 'below',

    differenceTypes: function() {
        var differenceTypes = []
        var matchController = this.get('matchController')
        var lineAmount = matchController.get('lineAmount')
        var subjectAmount = matchController.get('subjectAmount')
        var isPositive = lineAmount > 0 || subjectAmount > 0
        var underpayment = Em.Object.create({
            value: 'underpayment',
            text: t('bank_sync.underpayment')
        })
        var overpayment = Em.Object.create({
            value: 'overpayment',
            text: t('bank_sync.overpayment')
        })
        var bankFee = Em.Object.create({
            value: 'bankFee',
            text: t('bank_sync.bank_fee')
        })
        if (isPositive) {
            if (lineAmount > subjectAmount) {
                differenceTypes.push(overpayment)
            } else {
                differenceTypes.push(underpayment)
                differenceTypes.push(bankFee)
            }
        } else {
            if (lineAmount > subjectAmount) {
                differenceTypes.push(underpayment)
            } else {
                differenceTypes.push(overpayment)
                differenceTypes.push(bankFee)
            }
        }
        return differenceTypes
    }.property('matchController.lineAmount', 'matchController.subjectAmount'),

    actions: {
        selectDifferenceType: function(differenceType) {
            this.trigger('didSelect', differenceType.get('value'))
        }
    }
})
