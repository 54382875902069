var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

var PAGE_SIZE = 20

module.exports = Em.Component.extend({
    layoutName: 'components/bank-sync-v2/matches',

    classNames: ['bank-sync-v2-matches'],

    // state
    account: null,
    matches: Em.A([]),
    deleteMode: false,
    searchMode: false,

    // FILTERING
    // query params
    query: null,
    period: null,
    sortProperty: null,
    approved: null,
    matched: null,
    unmatched: null,

    // local filters
    search: null,
    filters: Em.Object.create({
        approved: Em.Object.create({
            label: t('bank_sync.matches.filter.approved'),
            value: false,
            enabled: true
        }),
        matched: Em.Object.create({
            label: t('bank_sync.matches.filter.matched'),
            value: true,
            enabled: true
        }),
        unmatched: Em.Object.create({
            label: t('bank_sync.matches.filter.unmatched'),
            value: true,
            enabled: true
        })
    }),

    updateQuery: function() {
        if (this.get('search') !== this.get('query')) {
            this.set('query', this.get('search') || null)
        }
    }.observes('search'),
    updateApprovedFilter: function() {
        this.set('approved', this.get('filters.approved.value'))
    }.observes('filters.approved.value'),
    updateMatchedFilter: function() {
        this.set('matched', this.get('filters.matched.value'))
    }.observes('filters.matched.value'),
    updateUnmatchedFilter: function() {
        this.set('unmatched', this.get('filters.unmatched.value'))
    }.observes('filters.unmatched.value'),

    // LINE SELECTION
    bankLinesCount: 0,
    bankLinesTotal: 0,
    bankLinesSelectedCount: 0,

    someLinesSelected: function() {
        return this.get('bankLinesSelectedCount') > 0 && this.get('bankLinesSelectedCount') < this.get('bankLinesCount')
    }.property('bankLinesCount', 'bankLinesSelectedCount'),
    allLinesSelected: function() {
        return this.get('bankLinesSelectedCount') === this.get('bankLinesCount')
    }.property('bankLinesCount', 'bankLinesSelectedCount'),

    setLineSelection: function(value) {
        this.get('matches').forEach(function(match) {
            match.get('lines').forEach(function(line) {
                line.set('selected', value)
            })
        })
    },
    updateSelectionCount: function() {
        this.set(
            'bankLinesSelectedCount',
            this.get('matches').reduce(function(count, match) {
                return count + match.get('lines')
                    .filter(function(line) {
                        return line.get('selected')
                    })
                    .get('length')
            }, 0)
        )
    },

    // FETCHING DATA
    isLoading: true,

    lastLoadedPage: 0,

    loadDebounced: null,

    isLoadingFirstPage: function() {
        return this.get('isLoading') && this.get('lastLoadedPage') === 0
    }.property('isLoading', 'lastLoadedPage'),

    triggerLoadDebounced: function() {
        this.set('isLoading', true)
        this.set('lastLoadedPage', 0) // reset page
        this.set('bankLinesCount', 0) // reset count

        this.get('loadDebounced') && this.get('loadDebounced')()
    }.observes('account.id', 'query', 'period', 'sortProperty', 'approved', 'matched', 'unmatched'),

    load: function() {
        this.set('isLoading', true) // Set loading

        if (this.get('lastLoadedPage') === 0) {
            // reset state if loading first page
            this.set('matches', Em.A())
            this.set('bankLinesCount', 0)
            this.set('remoteHasMore', false)
        }

        var qs = _.defaultsDeep(
            this.get('query') ? { q: this.get('query') } : {},
            this.get('period') ? { entryDatePeriod: this.get('period') } : {},
            this.get('sortProperty') ? { sortProperty: this.get('sortProperty') } : {},
            !this.get('approved') ? { isApproved: false } : {},
            this.get('approved') && !this.get('matched') && !this.get('unmatched') ? { isApproved: true } : {},
            (this.get('matched') || this.get('unmatched')) && this.get('matched') !== this.get('unmatched')
                ? { isMatched: (this.get('matched') && true) || (this.get('unmatched') && false) }
                : {},
            {
                accountId: this.get('account.id'),
                include: 'bankLineMatch.lines,bankLineMatch.subjectAssociations,bankLineSubjectAssociation.subject',
                page: this.get('lastLoadedPage') + 1,
                pageSize: PAGE_SIZE
            }
        )
        var someCategorySelected = this.get('matched') || this.get('unmatched') || this.get('approved')

        return Em.RSVP.resolve(someCategorySelected ? Billy.BankLineMatch.find(qs).promise : Promise.resolve(Em.A()))
            .then(function(rows) {
                this.get('matches').pushObjects(rows.toArray()) // .pushObjects(Em.A(rows.toArray()))
                this.set('bankLinesCount', this.get('matches').reduce(function(count, match) {
                    return count + match.get('lines.length')
                }, 0))
                this.set('bankLinesTotal', rows.get('paging.total') || 0)
                this.set('lastLoadedPage', this.get('lastLoadedPage') + 1) // increment page
                this.set('remoteHasMore', (rows.get('paging.total') || 0) > PAGE_SIZE)
            }.bind(this))
            .catch(function() {
                this.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_V2_LOAD, t('util.request.default_error'))
            }.bind(this))
            .finally(function() {
                this.set('isLoading', false)
            }.bind(this))
    },

    // LIFECYCLE HOOKS
    initFn: function() {
        // init filters
        this.set('search', this.get('query'))
        this.set('searchMode', !!this.get('search'))

        // init filters
        this.set('filters.approved.value', !!this.get('approved'))
        this.set('filters.matched.value', !!this.get('matched'))
        this.set('filters.unmatched.value', !!this.get('unmatched'))

        // Set load debounce function
        this.set('loadDebounced', _.debounce(this.load.bind(this), 1000))

        // Trigger initial load
        this.load()
    }.on('init'),

    _selectAll: function() {
        this.$().delegate('input[name="selectAll"]', 'change', function(e) {
            this.setLineSelection(e.target.checked)
        }.bind(this))
    }.on('didInsertElement'),

    actions: {
        toggleSearchMode: function() {
            this.set('searchMode', !this.get('searchMode'))
        },
        toggleDeleteMode: function() {
            this.set('deleteMode', !this.get('deleteMode'))

            if (!this.get('deleteMode')) {
                this.setLineSelection(false)
            }
        },
        toggledLineSelection: function() {
            Em.run.debounce(this, this.updateSelectionCount, 100)
        },
        loadMore: function() {
            if (!this.get('isLoading') && this.get('remoteHasMore')) {
                this.set('isLoading', true)
                this.load()
            }
        }
    }
})
