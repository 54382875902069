var Window = require('ember-window')

module.exports = Window.extend({

    classNames: ['request-loan-loading-modal'],

    layout: require('../templates/components/request-loan-loading-modal'),

    focusSelector: null,

    width: 600,

    actions: {
        close: function() {
            this.close()
        }
    }
})
