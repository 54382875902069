module.exports = Em.Component.extend({
    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var options = e.detail.props || { queryParams: {} }

            if (options) {
                this.container.lookup('router:main').transitionTo(route, options)
            } else {
                this.container.lookup('router:main').transitionTo(route)
            }
        }
    }
})
