var t = require('i18n').t

module.exports = require('./circle-filter').extend({
    summary: null,

    categories: function() {
        return Em.A([
            Em.O({
                key: 'overdue',
                value: null,
                name: '',
                badgeType: 'warning',
                color: 'red'
            }),
            Em.O({
                key: 'unpaid',
                value: null,
                name: '',
                badgeType: 'attention',
                circleValueFn: function(categories) {
                    return categories.findBy('key', 'unpaid').get('value') - categories.findBy('key', 'overdue').get('value')
                },
                color: 'yellow'
            }),
            Em.O({
                key: 'paid',
                value: null,
                name: '',
                badgeType: 'success',
                color: 'green'
            })
        ])
    }.property(),

    summaryDidChange: function() {
        var summary = this.get('summary') || {}
        var all = summary.all || { amount: 0, count: 0 }
        var draft = summary.draft || { amount: 0, count: 0 }
        this.get('categories').forEach(function(category) {
            var key = category.get('key')
            var d = summary[key] || { amount: 0, count: 0 }
            category.set('value', d.amount)
            category.set('name', t('bill.list.category.' + key, { count: d.count }))
            category.set('amount', d.count)
        })

        if (this.get('summary')) {
            this.set('allValue', all.amount - draft.amount)
        }

        this.set('allName', t('bill.list.category.all', { count: all.count - draft.count }))
        this.set('allAmount', all.count - draft.count)
    }.observes('summary').on('init')
})
