var numeral = require('numeral')
var i18n = require('i18n')
var t = i18n.t

module.exports = Em.Component.extend({
    layoutName: 'components/circle-filter',

    classNames: ['circle-filter'],

    classNameBindings: ['circles:circles', 'isLoaded::hidden'],

    isLoaded: true,

    circles: true,

    noAll: false,

    categories: null, // Should be injected in template

    allowSelection: true,

    selected: { key: 'selectedKey' },

    selectedKey: function(key, selectedKey) {
        if (arguments.length > 1) {
            var selected = this.get('categories').findBy('key', selectedKey) || null
            this.set('selected', selected)
        } else {
            selectedKey = this.get('selected.key') || null
        }
        return selectedKey
    }.property('selected'),

    currency: null,

    formatValues: true,

    allName: i18n.tProperty('allName', t('circle_filter.all')),
    allValue: null,

    actions: {
        selectCategory: function(category) {
            if (this.get('allowSelection')) {
                this.set('selected', category)
            }
            this.sendAction('didSelect', category)
        }
    },

    hasDrawn: false
})

function formatValue(value, currency) {
    if (currency) {
        return Billy.money(value, undefined, '0,0')
    } else {
        return numeral(value).format('0,0')
    }
}

module.exports.formatValue = formatValue
