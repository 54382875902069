module.exports = Em.Controller.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    customActions: {
        openUpgradePlanOverlay: function() {
            this.container.lookup('component:upgrade-plan-overlay')
                .set('trackingContext', 'salary_signup')
                .show()
        },

        navigate: function(e) {
            var self = this
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            self.container.lookup('router:main').transitionTo(route, props)
        }
    }
})
