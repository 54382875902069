var f = require('float')

module.exports = Em.Controller.extend({
    needs: ['daybookIndex'],

    transaction: Em.computed.alias('controllers.daybookIndex.focusedTransaction'),

    debitEntries: sideEntries('debit'),

    debitTotal: sideTotal('debit'),

    creditEntries: sideEntries('credit'),

    creditTotal: sideTotal('credit'),

    hasMovement: function() {
        return this.get('debitEntries.length') > 0 || this.get('creditEntries.length') > 0
    }.property('debitEntries.length', 'creditEntries.length'),

    debitCreditDifference: function() {
        return this.get('debitTotal') - this.get('creditTotal')
    }.property('debitTotal', 'creditTotal')
})

function sideEntries(side) {
    return function() {
        return reduceSideMovements(this.get('transaction.accountMovements'), side, function(entries, item) {
            entries.push({
                account: item.name,
                value: item.movement.amount
            })
            return entries
        }, [])
    }.property('transaction.accountMovements')
}

function sideTotal(side) {
    return function() {
        return reduceSideMovements(this.get('transaction.accountMovements'), side, function(total, item) {
            return f.round(total + item.movement.amount)
        }, 0)
    }.property('transaction.accountMovements')
}

function reduceSideMovements(movements, side, callback, initial) {
    return _.reduce(movements, function(result, item) {
        var movement = item.movement
        if (!movement.isZero() && movement.side.value === side) {
            result = callback(result, item)
        }
        return result
    }, initial)
}
