// DDAU = Data Down, Actions Up
module.exports = Em.Component.extend({
    tagName: 'input',

    classNames: ['ember-checkbox'],

    attributeBindings: ['type', 'checked', 'indeterminate', 'disabled'],

    type: 'checkbox',

    checked: false,

    indeterminate: false,

    actionContext: null,

    setupListeners: function() {
        this.$().on('click', this.onChange.bind(this))
    }.on('didInsertElement'),

    onChange: function(e) {
        e.preventDefault()
        var checked = this.get('checked')
        this.sendAction('changed', !checked, this.get('actionContext'))
    }
})
