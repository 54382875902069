var t = require('i18n').t

var ERROR_MESSAGES = {
    E_NEGATIVE_BALANCE: 'payments.error.invoice_negative_balance',
    E_INVOICE_NO_DUE_DATE: 'payments.error.invoice_missing_due_date',
    E_INVOICE_FOREIGN_CURRENCY: 'payments.error.invoice_foreign_currency_dkk',
    E_INVOICE_CUSTOMER_NO: 'payments.error.invoice_no_customer_no',
    E_INVOICE_MULTI_PBS: 'payments.error.invoice_multi_pbs',
    E_PAYMENT_REJECTED: 'payments.error.invoice_payment_rejected',
    E_PAYMENT_WINDOW_EXPIRED: 'payments.error.payment_window_expired'
}

module.exports = Ember.Component.extend({
    paymentMethods: Em.inject.service(),

    paymentMethodName: function() {
        var properties = this.get('entry.properties')
        var paymentMethodsSvc = this.get('paymentMethods')
        var pm = paymentMethodsSvc && paymentMethodsSvc.find(properties.paymentMethodId)

        return pm ? '(' + pm.get('name') + ')' : ''
    }.property('entry.properties.paymentMethodId', 'entry.properties.paymentMethodType'),

    message: function() {
        var properties = this.get('entry.properties')
        var error = this.get('entry.properties.error')

        return t(ERROR_MESSAGES[error.code] || 'payments.error.unknown', properties)
    }.property('entry.properties.error')
})
