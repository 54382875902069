module.exports = Em.Component.extend({
    layoutName: 'components/unsupported-browser-bar',

    classNames: ['top-bar', 'top-bar-yellow'],

    organization: null,

    limited: false,

    actions: {
        close: function() {
            window.sessionStorage && window.sessionStorage.setItem('hideBrowserUnsupportedBar', 'true')
            this.container.lookup('controller:application').notifyPropertyChange('hasBrowserUnsupportedBar')
        }
    }
})
