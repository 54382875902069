var postingSides = require('posting-sides')

Billy.BankLineMatch.reopen({
    subjects: function() {
        return this.get('subjectAssociations').mapProperty('subject')
    }.property('subjectAssociations.@each.subject'),

    // Hack until SortableMixin supports looking up properties on the itemController. Needed in controllers/bank-sync-matches
    entryDateLocal: function() {
        // We like to sort on this locally, so it updates faster than when the server responds
        return this.get('lines').reduce(function(min, line) {
            var entryDate = line.get('entryDate')
            if (!min || entryDate.isBefore(min)) {
                min = entryDate
            }
            return min
        }, null)
    }.property('lines.@each.entryDate'),

    // Hack until SortableMixin supports looking up properties on the itemController. Needed in controllers/bank-sync-matches
    sideAmountLocal: function() {
        return this.get('lines').reduce(function(result, line) {
            var sideAmount = (line.get('side').isCredit ? -1 : 1) * line.get('amount')
            return result + sideAmount
        }, null)
    }.property('lines.@each.amount', 'lines.@each.side')
})

Billy.BankLineMatch.registerSortMacro('sideAmount', ['amount', 'side'], function(a, b) {
    var av = (postingSides.resolveSide(a.get('side')).isCredit ? -1 : 1) * a.get('amount')
    var bv = (postingSides.resolveSide(b.get('side')).isCredit ? -1 : 1) * b.get('amount')
    return av - bv
})

Billy.BankLineMatch.registerSortMacro('sortDirection', ['entryDate'], function(a, b) {
    var av = (postingSides.resolveSide(a.get('side')).isCredit ? -1 : 1) * a.get('amount')
    var bv = (postingSides.resolveSide(b.get('side')).isCredit ? -1 : 1) * b.get('amount')
    return av - bv
})
