module.exports = Em.Controller.extend({
    needs: ['organization'],

    queryParams: {
        originatorType: 'originatorType',
        originatorTypeGroup: 'originatorTypeGroup',
        page: 'page',
        pageSize: 'pageSize',
        period: 'period',
        searchQuery: 'searchQuery',
        sortDirection: 'sortDirection',
        sortProperty: 'sortProperty',
        withVoided: 'withVoided'
    },

    originatorType: null,
    originatorTypeGroup: null,
    pageSize: null,
    period: null,
    searchQuery: null,
    sortDirection: null,
    sortProperty: null,
    withVoided: null,

    organization: Em.computed.alias('controllers.organization.model'),

    organizationSubscription: Em.inject.service(),

    constraints: Ember.inject.service(),

    subscriptionLimits: Ember.inject.service(),

    customActions: {
        navigate: function(e) {
            var route = e.detail.route
            var options = e.detail.props || { queryParams: {} }
            this.transitionToRoute(route, options)
        },

        addTransaction: function() {
            var self = this

            self.get('subscriptionLimits').checkForExpenseLimitAndExecute(function() {
                self.container.lookup('component:transaction-window').show()
            })
        }
    }
})
