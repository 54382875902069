var t = require('i18n').t
var batmask = require('batmask')

module.exports = Em.Component.extend({
    needs: ['user'],

    userOrganizations: Ember.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/payment-methods'),

    paymentMethods: Ember.inject.service(),

    // Route props:
    useCurrencyDefaults: false,

    queryParams: ['mobilepaystatus', 'mobilepayerror'],

    mobilepaystatus: null,

    mobilepayerror: null,

    addingCurrencyDefault: false,

    newCurrencyDefaultCurrency: null,

    newCurrencyDefaultPaymentMethod: null,

    mobilePayMessage: function() {
        var status = this.get('mobilepaystatus')
        var error = this.get('mobilepayerror')
        var message = 'default'

        if (!status) {
            return null
        }

        if (status === 'success') {
            message = t('payment_methods.mobilepay.success')
        } else if (status === 'error') {
            switch (error) {
            case 'E_INSUFFICIENT_SCOPE':
                message = t('payment_methods.mobilepay.error.insufficient_scope')
                break
            case 'E_INVALID_STATE':
                message = t('payment_methods.mobilepay.error.invalid_state')
                break
            case 'E_MISSING_ISSUER':
                message = t('payment_methods.mobilepay.error.missing_issuer')
                break
            default:
                message = t('payment_methods.mobilepay.failed')
                break
            }
        }

        return Ember.Object.create({
            message: message,
            messageType: (status === 'success') ? 'success' : 'warn'
        })
    }.property('mobilepaystatus', 'mobilepayerror'),

    paymentMethodOptions: function() {
        var options = []
        this.get('paymentMethods.data').forEach(function(paymentMethod) {
            options.push(Ember.Object.create({
                value: paymentMethod,
                name: paymentMethod.get('name')
            }))
        })
        return options
    }.property('paymentMethods.data.@each.name'),

    actions: {
        edit: function(paymentMethod) {
            this.get('paymentMethods').editPaymentMethod(paymentMethod)
        },

        create: function() {
            var organization = this.get('organization')

            this.get('paymentMethods').createPaymentMethod(organization, organization.get('baseCurrencyId'))
        },

        delete: function(paymentMethod) {
            var self = this

            batmask.mask()
            this.container.lookup('util:dialog')
                .confirmWarning(null, t('confirm_delete'), t('yes_delete'), t('no'))
                .then(function() {
                    return self.get('paymentMethods').destroyPaymentMethod(paymentMethod)
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        toggleCurrencyDefaults: function() {
            this.toggleProperty('useCurrencyDefaults')
        },

        toggleAddCurrencyDefault: function() {
            if (this.get('addingCurrencyDefault')) {
                this.set('addingCurrencyDefault', false)
                this.set('newCurrencyDefaultCurrency', null)
                this.set('newCurrencyDefaultPaymentMethod', null)
            } else {
                this.set('addingCurrencyDefault', true)
            }
        },

        saveCurrencyDefault: function() {
            var self = this
            this.get('paymentMethods').putCurrencyDefault(this.get('newCurrencyDefaultCurrency.id'), this.get('newCurrencyDefaultPaymentMethod'))
            self.send('toggleAddCurrencyDefault')
        },

        deleteCurrencyDefault: function(def) {
            this.get('paymentMethods').deleteCurrencyDefault(def.currencyCode, def.paymentMethod)
        }
    }
})
