var t = require('i18n').t

module.exports = Ember.Component.extend({
    paymentMethods: Em.inject.service(),

    paymentMethodName: function() {
        var properties = this.get('entry.properties')
        var paymentMethodsSvc = this.get('paymentMethods')
        var pm = paymentMethodsSvc && paymentMethodsSvc.find(properties.paymentMethodId)

        return pm ? '(' + pm.get('name') + ')' : ''
    }.property('entry.properties.paymentMethodId', 'entry.properties.paymentMethodType'),

    message: function() {
        var paymentDate = this.get('entry.properties.paymentDate')

        return !paymentDate
            ? t('timeline.entry.invoice_payment_accepted.message_without_date')
            : t(
                'timeline.entry.invoice_payment_accepted.message_with_date',
                { paymentDate: moment(paymentDate).format('Do MMM YYYY') }
            )
    }.property('entry.properties.paymentEntryDate')
})
