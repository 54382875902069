var t = require('i18n').t
var ValueResolver = require('../utils/value-resolver')
var colors = require('../components/pie-chart').colors

module.exports = require('./dashboard-widget').extend({
    needs: ['dashboard'],

    view: 'dashboard-widget-abstract-top',

    topUrlPath: null, // Set by subclasses

    data: function() {
        var self = this
        return ValueResolver.create({
            invalidateOn: 'accounting-changed',
            resolver: function() {
                var organizationId = self.get('organization.id')
                var period = self.get('period.value')
                var api = self.container.lookup('api:billy')
                return api.get('/organizations/' + organizationId + '/stats/' + self.get('topUrlPath') + '?n=4' + (period ? '&period=' + period : ''))
                    .then(function(payload) {
                        return payload.data.map(self.mapRows)
                    })
            }
        })
    }.property(),

    rows: function() {
        if (this.get('hasData')) {
            return this.get('data.value')
        }
        var rows = []
        var count = 5
        for (var i = 1; i <= count; i++) {
            rows.push({
                contactId: 'dummy-' + i,
                amount: Math.round(Math.random() * 100000),
                name: this.get('exampleName') + ' ' + i
            })
        }
        rows.sort(function(a, b) {
            return b.amount - a.amount
        })
        rows = rows.map(this.mapRows)
        return rows
    }.property('data.value', 'hasData'),

    mapRows: function(item, idx) {
        item.color = colors[idx]
        return item
    },

    pieData: function() {
        var data = this.get('rows')
        if (!data) {
            return []
        }
        return data.map(function(item) {
            return {
                key: item.rest ? 'rest' : item.contactId,
                label: item.rest ? t('dashboard.widget.top.rest') : item.name,
                value: item.amount
            }
        })
    }.property('rows'),

    reloadData: function() {
        this.get('data').reload()
    }.observes('period'),

    willDestroy: function() {
        this.get('data').destroy()
    }
})
