var Window = require('ember-window')
var i18n = require('i18n')
var t = i18n.t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')
var postingSides = require('posting-sides')
var roundToTwoDecimalPlaces = require('../utils/round-to-two-decimal-places')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    api: Em.inject.service(),

    template: require('../templates/components/transaction-account-currency-adjustment-window'),

    classNames: ['transaction-window-view'],

    customEvent: Ember.inject.service(),

    segment: Em.inject.service(),

    content: null,

    width: 800,

    // Bound to attachments-list in template
    isUploading: null,

    baseCurrency: function() {
        return this.container.lookup('controller:organization').get('baseCurrency')
    }.property('organization'),

    balanceAccountLabel: function() {
        var accountCurrencyId = this.get('content.account.currency.id')
        return t('transaction.window.account_currency_adjustment.account_balance_currency', { currency: accountCurrencyId })
    }.property('content.account'),

    balanceAccountBaseLabel: function() {
        var baseCurrencyId = this.get('baseCurrency.id')
        return t('transaction.window.account_currency_adjustment.account_balance_currency', { currency: baseCurrencyId })
    }.property('baseCurrency'),

    // Balance in the UI
    balanceAccount: 0,
    balanceAccountBase: 0,

    // Balance fetched based on the account and date
    balanceAccountFetched: 0,
    balanceAccountBaseFetched: 0,

    isFetchingBalance: false,

    secondStepInfo: function() {
        var accountCurrencyId = this.get('content.account.currency.id')
        var baseCurrencyId = this.get('baseCurrency.id')

        if (!accountCurrencyId || !baseCurrencyId) {
            return ''
        }

        return t('transaction.window.account_currency_adjustment.second_step_info', {
            accountCurrencyId: accountCurrencyId,
            baseCurrencyId: baseCurrencyId
        })
    }.property('content.account', 'baseCurrency'),

    isSecondStepVisible: function() {
        var entryDate = this.get('content.entryDate')
        var account = this.get('content.account')
        return entryDate && account
    }.property('content.entryDate', 'content.account'),

    isSubmitButtonDisabled: function() {
        return !this.get('isSecondStepVisible')
    }.property('isSecondStepVisible'),

    didEntryDateOrAccountChange: function() {
        var self = this
        var entryDate = this.get('content.entryDate')
        var account = this.get('content.account')
        var accountCurrency = this.get('content.account.currency')
        var baseCurrency = this.get('baseCurrency')

        if (!account || !entryDate || !accountCurrency || !baseCurrency) {
            return
        }

        // Fetch balances:
        this.set('isFetchingBalance', true)
        this.getBalanceForAccount(account, accountCurrency.get('id'), entryDate)
            .then(function(balanceAccount) {
                self.set('balanceAccount', balanceAccount)
                self.set('balanceAccountFetched', balanceAccount)
                return self.getBalanceForAccount(account, baseCurrency.get('id'), entryDate)
            })
            .then(function(balanceAccountBase) {
                var balanceAccount = self.get('balanceAccount')
                var balanceAccountBaseExchanged = accountCurrency.exchangeTo(balanceAccount, baseCurrency)

                self.set('balanceAccountBase', roundToTwoDecimalPlaces(balanceAccountBaseExchanged))
                self.set('balanceAccountBaseFetched', balanceAccountBase)
            })
            .finally(function() {
                self.set('isFetchingBalance', false)
            })
            .catch(function(e) {
                console.error(e)
                self.set('isFetchingBalance', false)
            })
    }.observes('content.entryDate', 'content.account'),

    getBalanceForAccount: function(account, currencyId, dateMoment) {
        var api = this.get('api')

        if (!account || !currencyId || !dateMoment) {
            console.error('Some data is missing for getBalanceForAccount!')
            return
        }

        var accountId = account.get('id')
        var dateString = dateMoment.format('YYYY-MM-DD')

        return new Em.RSVP.Promise(function(resolve, reject) {
            api.request('GET', '/v2/accounts/' + accountId + '/balance?currencyId=' + currencyId + '&endDate=' + dateString, { headers: { 'accept-language': i18n.locale() } })
                .then(function(response) {
                    var balance = response && response.balance

                    if (typeof balance === 'number') {
                        resolve(balance)
                    } else {
                        reject()
                    }
                })
                .catch(function(e) {
                    console.error(e)
                    reject()
                })
        })
    },

    actions: {
        didUploadFile: function() {
            this.get('segment').track('File Uploaded (FE)', {
                context: 'transactions'
            })
        },

        approve: function() {
            if (this.get('isFetchingBalance')) {
                return
            }

            // Set transaction to approved and save
            var transaction = this.get('content')

            var self = this

            // Check for unfinished attachment uploads
            if (this.get('isUploading')) {
                transaction.set('error', t('still_uploading'))
                return
            }

            var organization = this.container.lookup('controller:organization')
            var account = this.get('content.account')
            var balanceAccountBase = this.get('balanceAccountBase')
            var balanceAccountBaseFetched = this.get('balanceAccountBaseFetched')
            var baseCurrency = this.get('baseCurrency')

            // First line
            var firstLineAccount = account
            var firstLineAmount = 0
            var firstLineBaseAmount = roundToTwoDecimalPlaces(balanceAccountBaseFetched - balanceAccountBase)
            var firstLineSide = firstLineBaseAmount < 0 ? postingSides.debit : postingSides.credit
            var firstLineCurrency = account.get('currency')

            // Second line
            var secondLineAccount = Billy.Account.findBySystemRole(organization, 'realizedCurrencyDifference')
            var secondLineAmount = firstLineBaseAmount
            var secondLineSide = firstLineSide.isCredit ? postingSides.debit : postingSides.credit
            var secondLineCurrency = baseCurrency

            // Reset lines
            transaction.set('lines._content', {})

            // And create new ones based on current data in the form

            Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: transaction,
                account: firstLineAccount,
                amount: firstLineAmount,
                baseAmount: Math.abs(firstLineBaseAmount),
                side: firstLineSide,
                currency: firstLineCurrency,
                priority: 1
            })

            Billy.DaybookTransactionLine.createRecord({
                daybookTransaction: transaction,
                account: secondLineAccount,
                amount: Math.abs(secondLineAmount),
                side: secondLineSide,
                currency: secondLineCurrency,
                priority: 2
            })

            // Reset error message
            transaction.set('error', null)
            // Save
            transaction.set('state', 'approved')
            transaction.save({
                embed: ['lines', 'attachments']
            })
                .mask()
                .success(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.TRANSACTION_APPROVED, t('transaction.window.approval_success'))
                    self.get('customEvent').dispatchEvent(CustomEvent.TransactionCreated)
                    self.close()

                    self.get('segment').track('Transaction Template Completed (FE)', {
                        type: 'transaction-account-currency-adjustment-window'
                    })
                })
                .error(function(errorMessage, payload) {
                    if (payload.validationErrors) {
                        // Find possible amount/side error and set as error message
                        var amountError = false
                        Object.keys(payload.validationErrors).forEach(function(key) {
                            var attributes = payload.validationErrors[key].attributes
                            if (attributes && (attributes.amount || attributes.side)) {
                                amountError = true
                            }
                        })
                        if (amountError) {
                            transaction.set('error', t('transaction.window.amount_side_error'))
                        }
                    } else {
                        // Set error message, if not an error on a field
                        transaction.set('error', errorMessage)
                    }
                })
        }
    }
})
