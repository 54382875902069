var Window = require('ember-window')

module.exports = Window.extend({
    layout: require('../templates/components/payment-method-stripe-setup'),

    applyModalMask: false,

    userOrganizations: Em.inject.service(),

    paymentMethods: Ember.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    openAddPaymentMethodModal: function() {
        var self = this
        this.get('paymentMethods')
            .load() // support opening right after adding a new integration
            .then(function(result) {
                var stripe = result.find(function(method) { return method.get('type') === 'stripe' })

                self.container.lookup('component:payment-method-editor')
                    .set('paymentMethod', stripe)
                    .show()
            })
    },

    customActions: {
        closeEmber: function() {
            this.close()
        },

        reloadPaymentMethods: function() {
            this.get('paymentMethods').loadSafe()
        },

        openPaymentMethodEditor: function() {
            this.openAddPaymentMethodModal()
        }
    }
})
