module.exports = Em.Component.extend({
    classNames: ['color-field'],

    value: '',

    spectrum: function() {
        var el = this.$('.color-field-spectrum')
        return el.spectrum.apply(el, arguments)
    },

    setupSpectrum: function() {
        var self = this

        var opts = {
            color: this.get('value'),
            preferredFormat: 'hex',
            chooseText: 'Choose',
            cancelText: 'Cancel',
            move: function(newColor) {
                Em.run.join(function() {
                    self.set('value', newColor.toString())
                })
            }
        }

        this.spectrum(opts)
    }.on('didInsertElement'),

    valueDidChange: function() {
        this.spectrum('set', this.get('value'))
    }.observes('value')

})
