var t = require('i18n').t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    needs: ['organization'],

    startGuide: Em.inject.service(),

    organization: Em.computed.alias('controllers.organization.model'),

    logoUploadText: function() {
        return this.get('organization.logoFile') ? t('change') : t('settings.organization.upload_logo')
    }.property('organization.logoFile'),

    logoUploadUrl: function() {
        return ENV.apiUrl + '/organizations/' + this.get('organization.id') + '/logo'
    }.property('organization'),

    isSaveDisabled: function() {
        return !this.get('organization.logoUrl')
    }.property('organization.logoUrl'),

    actions: {
        didUploadLogo: function() {
            this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.ORGANIZATION_LOGO_UPLOADED, t('settings.organization.logo_uploaded'))
        },

        removeLogo: function() {
            this.get('organization')
                .set('logoFile', null)
                .set('logoUrl', null)
                .save()
        },

        save: function() {
            var self = this

            batmask.maskDelayed()

            this.get('startGuide').completeStep()
                .then(function() {
                    self.send('goToNextStep')
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        skip: function() {
            this.send('goToNextStep')
        }
    }
})
