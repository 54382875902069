module.exports = Em.Component.extend({
    classNames: ['v2-attachments-editor-item'],

    req: null,

    actions: {
        abort: function() {
            this.sendAction('abort', this.get('req'))
        }
    }
})
