var _ = require('lodash')
var formatValue = require('./circle-filter').formatValue

var INITIAL_FONT_TIMEOUT = 50
var ATTEMPTS_COUNTER_LIMIT = 10

module.exports = Em.Component.extend({
    layoutName: 'components/circle-filter-category',

    classNames: ['circle-filter-category'],

    classNameBindings: ['color', 'isSelected:selected'],

    attributeBindings: ['style', 'cy:data-cy'],

    cy: function() {
        return _.toLower(this.get('color')) + '-circle-filter'
    }.property('color'),

    title: function() {
        return this.get('formattedValue') + ' ' + this.get('currency.id')
    }.property('formattedValue', 'currency'),

    selected: null, // Should be injected in template

    category: null, // Should be injected in template,

    color: Em.computed.oneWay('category.color'),

    value: Em.computed.oneWay('category.value'),

    name: Em.computed.oneWay('category.name'),

    description: Em.computed.oneWay('category.description'),

    bubble: Em.computed.oneWay('category.bubble'),

    amount: Em.computed.oneWay('category.amount'),

    badgeType: Em.computed.oneWay('category.badgeType'),

    newValueFontSize: 28,

    currency: null,

    formatValues: true,

    formattedValue: function() {
        if (!this.get('formatValues')) {
            return this.get('value')
        }

        return formatValue(this.get('value'), this.get('currency'))
    }.property('value', 'currency'),

    isSelected: function() {
        return this.get('selected') === this.get('category')
    }.property('selected', 'category'),

    click: function() {
        this.sendAction('select', this.get('category'))
    },

    handleNewValueFontSize: function(timeout, attemptsCounter) {
        var self = this
        var valueWrapper = this.$('.value-wrapper')
        var value = this.$('.value')
        var timeoutValue = timeout !== undefined ? timeout : INITIAL_FONT_TIMEOUT
        var attemptsCounterValue = attemptsCounter !== undefined ? attemptsCounter : 0
        // eslint-disable-next-line compat/compat
        var isFontLoading = document.fonts && !document.fonts.check('28px "Aeonik"')
        var isValueSet = this.get('value') !== null
        var isNumberOfAttemptsExceeded = attemptsCounterValue >= ATTEMPTS_COUNTER_LIMIT

        if ((isFontLoading || !isValueSet) && !isNumberOfAttemptsExceeded) {
            setTimeout(function() {
                self.handleNewValueFontSize(timeoutValue * 2, attemptsCounterValue + 1)
            }, timeoutValue)

            return
        }

        // Wait until fonts are loaded
        setTimeout(function() {
            var isTextBiggerThanWrapper = value.width() > valueWrapper.width()

            if (isTextBiggerThanWrapper) {
                self.set('newValueFontSize', self.get('newValueFontSize') - 1)
                self.set('calcDelay', 0)
                self.handleNewValueFontSize(0)
            } else {
                value.css({
                    opacity: 1
                })
            }

            value.css({
                'font-size': self.get('newValueFontSize') + 'px'
            })
        }, timeoutValue)
    }.on('didInsertElement')
})
