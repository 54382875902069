var isReactRootPath = function(hash) {
    // It is '#/' or starts with '#/?'
    var pattern = /^#\/(?:\?[^]*)?$/

    if (!hash) {
        return false
    }

    return pattern.test(hash)
}

var isEmberRootPath = function(hash) {
    // Does not start with '#/'
    var pattern = /^(?!#\/).*/

    return pattern.test(hash)
}

module.exports = Em.Route.extend({
    beforeModel: function(transition) {
        var self = this
        var initialRoute = _.get(transition, 'queryParams.initialRoute')

        /**
         * shouldRedirectToChartOfAccounts examples:
         * https://mit.develop.billy.dk/puraaaa/accounts/hlUHS0wfTayfc4G3TQMDQw // false
         * https://mit.develop.billy.dk/puraaaa/accounts#/hlUHS0wfTayfc4G3TQMDQw?page=1&pageSize=100 // false
         * https://mit.develop.billy.dk/puraaaa/accounts // true
         * https://mit.develop.billy.dk/puraaaa/accounts#/ // true
         * https://mit.develop.billy.dk/puraaaa/accounts#/?period=through%3A2024-02-29 // true
         */
        var shouldRedirectToChartOfAccounts = !initialRoute && (isReactRootPath(window.location.hash) || isEmberRootPath(window.location.hash))

        if (shouldRedirectToChartOfAccounts) {
            self.transitionTo('settings', {
                queryParams: {
                    initialRoute: '/organization/accounting/chart_of_accounts'
                }
            })
        }
    },

    setupController: function(controller) {
        this._super(controller)
    }
})
