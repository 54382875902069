var Window = require('ember-window')
var ContactBalance = require('../utils/contact-balance')

var Component = module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/register-payment-window'),

    classNames: ['register-payment-window'],

    confirmCloseWhenDirty: false,

    subject: null,

    onBack: null,

    accountId: null,

    showForSubject: function(subject) {
        var self = this
        var contact = subject.get('contact')
        this.set('subject', subject)
        var subjectIsCashBased = subject.get('hasCashBasedAccounting')

        Em.RSVP.all([
            this.show(),
            ContactBalance.load(contact, subject.get('currency'))
        ]).then(function(values) {
            var balance = values[1]
            // We don't allow CBA subjects to be paid using contact balance
            if (balance.get('side') === subject.get('postingSide') && !subjectIsCashBased) {
                Component.openContactBalancePaymentWindow(self, subject, balance)
            } else {
                Component.openBankPaymentWindow(self, contact, subject)
            }
        })
    }
})

Component.openBankPaymentWindow = function(registerPaymentWindow, contact, subject) {
    var w = registerPaymentWindow.container.lookup('component:bank-payment-window').set('onBack', registerPaymentWindow.get('onBack')).set('accountId', registerPaymentWindow.get('accountId'))
    registerPaymentWindow.replaceWith(w)
    w.showForContact(contact, subject)
}

Component.openContactBalancePaymentWindow = function(registerPaymentWindow, subject, balance) {
    var w = registerPaymentWindow.container.lookup('component:contact-balance-payment-window')
    registerPaymentWindow.replaceWith(w)
    w.showForSubject(subject, balance)
}
