var storage = require('storage')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    loginEvent: Em.inject.service(),

    layoutName: 'components/login-form',

    passwordResetIsActive: false,

    fixedEmail: null,

    hasFixedEmail: Em.computed.bool('fixedEmail'),

    credentials: function() {
        return BD.AnonymousRecord.createRecord({
            email: this.get('fixedEmail') || this.get('email') || storage('loginUserEmail'),
            password: '',
            remember: false
        })
    }.property(),

    passwordResetRequest: function() {
        return BD.AnonymousRecord.createRecord({
            email: '',
            providerId: ENV.providerId
        })
    }.property(),

    didInsertElement: function() {
        var self = this
        this.$().enter(function() {
            if (self.get('passwordResetIsActive')) {
                self.send('submitPasswordResetRequest')
            } else {
                self.send('submitCredentials')
            }
        })
    },

    focusFirst: function() {
        if (this.get('credentials.email') || this.get('hasFixedEmail')) {
            this.focusPassword()
        } else {
            this.focusEmail()
        }
    }.on('didInsertElement'),

    focusEmail: function() {
        this.$('input[name="email"]').focus()
    },

    focusPassword: function() {
        this.set('password', '')
        this.$('input[name="password"]').focus()
    },

    actions: {
        submitCredentials: function() {
            var self = this
            var credentials = this.get('credentials')
            credentials
                .save('/user/login', {
                    root: 'credentials'
                })
                .mask()
                .success(function(payload) {
                    var user = Billy.User.load(payload.user)
                    var api = self.container.lookup('api:billy')
                    var accessToken = payload.meta.accessToken
                    storage('loginUserEmail', user.get('email'))
                    // Authorize the API
                    api.authorize(accessToken, credentials.get('remember'))

                    self.get('loginEvent').setLoginNeedsTracking()

                    // Send loggedIn action to proceed
                    self.sendAction('loggedIn', {
                        user: user,
                        payload: payload
                    })
                })
                .error(function() {
                    Em.run.next(self, self.focusFirst)
                })
        },

        submitPasswordResetRequest: function() {
            var self = this
            this.get('passwordResetRequest')
                .save('/user/passwordResetRequest', {
                    root: 'passwordResetRequest'
                })
                .mask()
                .success(function() {
                    self.send('togglePasswordReset')
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.LOGIN_EMAIL_SENT, t('login_component.reset_email_was_sent'))
                })
        },

        togglePasswordReset: function() {
            this.toggleProperty('passwordResetIsActive')
            if (this.get('passwordResetIsActive')) {
                this.set('passwordResetRequest.email', this.get('credentials.email'))
                Em.run.schedule('afterRender', this, this.focusEmail)
            } else {
                this.set('credentials.email', this.get('passwordResetRequest.email'))
                Em.run.schedule('afterRender', this, this.focusFirst)
            }
        }
    }
})
