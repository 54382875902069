var t = require('i18n').t
var batmask = require('batmask')
var annualReportPurchasing = require('../mixins/annual-report-purchasing')
var moment = require('momentjs')
var storage = require('storage')
var annualReportNavigation = require('../mixins/annual-report-navigation')
var checklistAdditionalQuestions = require('../data/annual-report').checklistAdditionalQuestions
var ADDONS = require('../utils/addons')
var isCompanyEmv = require('../utils/isCompanyEmv')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend(annualReportNavigation, annualReportPurchasing, {
    needs: ['user'],

    cvr: Em.inject.service(),

    intercom: Em.inject.service(),

    organizationSubscription: Ember.inject.service(),

    annualReport: Em.inject.service(),

    annualReportCredits: Em.inject.service(),

    segment: Em.inject.service(),

    commitment: Em.computed.alias('model'),

    questions: Em.computed.alias('model.questions'),

    year: Em.computed.alias('commitment.year'),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    user: Em.computed.oneWay('controllers.user'),

    acceptTerms: null,

    basePrice: null,

    isProcessing: false,

    isSubscription: false, // for toggle

    isSubscriptionToggleDisabled: false, // for toggle

    showSubscriptionInfo: false, // whether to show AR Subscription related informations, combines AR year && subscription presence (see route)

    hasARSubscription: false, // from route, determines if AR subscription is bought

    hasARSubscriptionRenewed: false, // from route, if AR subscription present, was it already renewed?

    subscriptionRenewalDate: '',

    source: 'checklist',

    processingError: false,

    processAfterPayment: function() {
        var queryString = window.location.search
        var urlParams = new URLSearchParams(queryString)
        var payment = urlParams.get('payment_successful')
        var self = this
        var year = this.get('year')
        var isSubscription = this.isPurchasingSubscription()

        if (payment === '1' && year) {
            batmask.maskDelayed()
            this.get('annualReportCredits').loadUnusedCredits()

            this.get('annualReport').completeChecklistAfterPayment(year, isSubscription)
                .then(function() {
                    self.send('navigateToNextStep')
                })
                .catch(function() {
                    self.set('processingError', true)
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_STEPS, t('annual_report.request.credits_error'))
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }.observes('year'),

    hasARTokenScope: function() {
        return this.get('annualReport.hasARTokenScope')
    }.property(),

    initEvent: function() {
        if (!this.get('year')) {
            return
        }

        this.get('segment').track('Annual Report Pre-Purchase Checklist Viewed (FE)', {
            year: this.get('year')
        })
    }.observes('year'),

    hasARSubscriptionAndNotRenewed: function() {
        return this.get('hasARSubscription') && !this.get('hasARSubscriptionRenewed')
    }.property('hasARSubscription', 'hasARSubscriptionRenewed'),

    hasARSubscriptionAndRenewed: function() {
        return this.get('hasARSubscription') && this.get('hasARSubscriptionRenewed')
    }.property('hasARSubscription', 'hasARSubscriptionRenewed'),

    hasARSubscriptionAndRenewedAndComparisonSelected: function() {
        return this.get('hasARSubscriptionAndRenewed') && this.get('isComparisonSelected')
    }.property('hasARSubscriptionAndRenewed', 'isComparisonSelected'),

    hasARSubscriptionAndRenewedAndComparisonNotSelected: function() {
        return this.get('hasARSubscriptionAndRenewed') && !this.get('isComparisonSelected')
    }.property('hasARSubscriptionAndRenewed', 'isComparisonSelected'),

    termsNotAccepted: function() {
        return !this.get('acceptTerms')
    }.property('acceptTerms'),

    isPurchaseDisabled: function() {
        return (this.get('termsNotAccepted') || this.get('isProcessing')) && !this.get('hasARTokenScope')
    }.property('termsNotAccepted', 'isProcessing'),

    isLocked: function() {
        var locked = this.get('questions')
            .filter(function(q) {
                return q.isLocked
            })

        return locked.length
    }.property('questions.@each.isLocked'),

    isValid: function() {
        return this.isBillyValid()
    }.property('commitment.validationErrors', 'questions.@each.value'),

    isBillyValid: function() {
        var invalid = this.get('questions')
            .filter(function(q) {
                if (Em.empty(q.value)) {
                    return false
                }

                return q.isStopQuestion ? q.value === q.stopAnswer : false
            })

        return !invalid.length
    },

    isComparisonSelected: function() {
        var addons = this.collectAddonsFromQuestion()

        return !!addons.find(function(addon) {
            return [ADDONS.AR_COMPARISON_EMV.id, ADDONS.AR_COMPARISON_LTD.id].indexOf(addon.id) >= 0
        })
    }.property('questions.@each.{value,price,translationKeyPrefix}'),

    buttonText: function() {
        if (this.get('hasARTokenScope')) {
            return t('annual_report.subscription.get_started')
        }

        if (this.get('hasARSubscriptionRenewed') && !this.get('isComparisonSelected')) {
            return t('annual_report.subscription.continue')
        }

        if (this.get('hasARSubscription')) {
            return t('annual_report.subscription.buy_now')
        }

        return this.get('isSubscription') ? t('annual_report_buy_subscription') : t('annual_report_buy')
    }.property('isSubscription', 'isComparisonSelected'),

    showUpsell: function() {
        var subscriptionPlan = this.get('organization.subscriptionPlan')
        return subscriptionPlan === 'basic' && this.get('isSubscription')
    }.property('isSubscription', 'organization'),

    showSummary: function() {
        var unanswered = this.get('questions')
            .filter(function(q) {
                return Em.empty(q.value) && !checklistAdditionalQuestions.includes(q.name)
            })

        // show if there are no unanswered questions
        return !unanswered.length
    }.property('questions.@each.value'),

    annualReportPrice: function() {
        var annualReportService = this.get('annualReport')
        var basePrice = annualReportService.getPriceAccountedForCoupons(this.get('basePrice'))
        var basePriceOneoff = annualReportService.getPriceAccountedForCoupons(this.get('basePriceOneoff'))
        return this.get('isSubscription') ? basePrice : basePriceOneoff
    }.property('isSubscription'),

    totalPrice: function() {
        // Add addon price if an choice that enables an addon is selected
        var priceSum = this.get('questions')
            .reduce(function(price, item) {
                return price + (item.value && item.price)
            }, this.get('annualReportPrice'))
        return Billy.money(priceSum, 'DKK', '0,0.[00]')
    }.property('questions.@each.{value,price}', 'basePrice', 'isSubscription'),

    priceSummary: function() {
        var self = this
        var priceSummary = this.get('questions')
            .filter(function(q) {
                return q.price && q.value
            })
            .reduce(function(summary, question) {
                if (summary) {
                    summary += '<br />'
                }

                var addonDescription = summary + t(question.translationKeyPrefix + '.addon_label', {
                    price: Billy.money(question.price, 'DKK', '0,0.[00]')
                })

                if (self.get('isSubscription') && !self.get('hasARSubscription')) {
                    addonDescription += ' ' + t('annual_report.multiple_choice.checklist.addon_oneoff')
                }

                return addonDescription
            }, '')

        if (priceSummary && !self.get('hasARSubscription')) {
            priceSummary += '<br />' + t('annual_report.checklist.total_to_be_charged') + ' <strong>' + this.get('totalPrice') + '</strong>'
        }

        if (self.get('showSubscriptionInfo')) {
            return t('annual_report.subscription.paid_by_subscription', {
                priceSummary: priceSummary
            })
        }

        if (self.get('isSubscription') && !self.get('hasARSubscription')) {
            var renewalDate = moment().add(1, 'years').format(t('date_format.full_month'))
            return t('annual_report.multiple_choice.summary.price_summary_subscription', {
                price: Billy.money(this.get('annualReportPrice'), 'DKK', '0,0.[00]'),
                renewalDate: renewalDate,
                priceSummary: priceSummary,
                totalPrice: this.get('totalPrice')
            })
        }

        return t('annual_report.multiple_choice.summary.price_summary', {
            price: Billy.money(this.get('annualReportPrice'), 'DKK', '0,0.[00]'),
            priceSummary: priceSummary,
            totalPrice: this.get('totalPrice')
        })
    }.property('questions.@each.{value,price,translationKeyPrefix}', 'basePrice', 'totalPrice', 'annualReportPrice'),

    collectAddonsFromQuestion: function() {
        var year = this.get('year')
        var companyType = this.get('cvr.data.companyType')
        var isPurchasingSubscription = this.isPurchasingSubscription()
        var companyTypeShort = 'ltd'
        if (isCompanyEmv(companyType)) {
            companyTypeShort = 'emv'
        }

        var addonsPurchasing = []

        addonsPurchasing.push(this.get('annualReport').getAnnualReportAddonData(isPurchasingSubscription, year))

        this.get('questions')
            .filter(function(q) {
                return q.price && q.value
            })
            .forEach(function(question) {
                var addonsCollectedFromQuestions = Object.values(ADDONS).find(function(addon) {
                    return addon.id === t(question.translationKeyPrefix + '.' + companyTypeShort + '.addon_id')
                })

                addonsPurchasing.push(addonsCollectedFromQuestions)
            })

        return addonsPurchasing
    },

    isPurchasingSubscription: function() {
        return this.get('hasARSubscriptionAndNotRenewed')
    },

    actions: {
        showIntercomMessenger: function() {
            this.get('intercom').showNewMessage()
        },

        didToggleAcceptTerms: function() {
            this.toggleProperty('acceptTerms')
        },

        toggleSubscription: function() {
            var updatedIsSubscription = !this.get('isSubscription')
            this.set('isSubscription', updatedIsSubscription)
            storage('annual-report-subscription-toggle', updatedIsSubscription)
        },

        didClickUpsell: function() {
            this.transitionToRoute('organization_subscription')
        },

        didClickBuyAnnualReport: function() {
            var self = this
            batmask.maskDelayed()
            this.set('isProcessing', true)
            var collectedAddonsFromQuestions = this.collectAddonsFromQuestion()
            var isPurchasingSubscription = this.isPurchasingSubscription()

            this.get('annualReport')
                .completeChecklist(this.get('year'), isPurchasingSubscription, collectedAddonsFromQuestions)
                .then(function() {
                    self.send('navigateToNextStep')
                })
                .then(function() {
                    batmask.unmask()
                })
        },

        continueToNextStep: function() {
            this.send('navigateToNextStep')
        }
    }
})
