var AccountBalanceMixin = require('../mixins/account-balance')

module.exports = Em.Component.extend(AccountBalanceMixin, {
    classNames: ['bank-accounts-index-item'],

    didInsertElement: function() {
        this._super()
        this.loadBalance()
    }
})
