var Window = require('ember-window')
var i18n = require('i18n')
var downloadExport = require('../utils/download-export')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/sales-tax-return-download-window'),

    salesTaxReturn: null,

    auditTrail: false,

    segment: Em.inject.service(),

    actions: {
        download: function(format) {
            this.close()

            var salesTaxReturn = this.get('salesTaxReturn')
            var id = salesTaxReturn.get('id')
            var reportName = salesTaxReturn.get('organization.hasVat') ? 'accountReport' : 'taxRateReport'
            var api = this.container.lookup('api:billy')

            var params = {
                accessToken: api.storageAdapter.getValue('accessToken'),
                acceptLanguage: i18n.locale()
            }

            if (this.get('auditTrail')) {
                params.auditTrail = true
            }

            var exportUrl = '/salesTaxReturns/' + id + '/' + reportName + '.' + format + '?' + $.param(params)

            downloadExport(this.container, exportUrl)
            this.get('segment').track('XXX - Mikkel - Exports', {
                export_type: 'VAT settlement',
                file_format: format,
                period: 'all'
            })
        }
    }
})
