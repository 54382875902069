var numeral = require('numeral')
var f = require('float')

Billy.TaxRate.reopen({
    deductionRate: function() {
        var rate = this.get('rate')

        if (!this.get('deductionComponents').length) {
            return numeral(rate)
        }

        var deductionComponentRate = this.get('deductionComponents').reduce(function(rate, deductionComponent) {
            return rate + deductionComponent.get('share')
        }, 0)

        return numeral(rate * deductionComponentRate)
    }.property(),

    deductionRatePercent: function() {
        var rate = this.get('deductionRate')
        return rate ? f.round(rate * 100, 2) : null
    }.property('rate', 'deductionComponents.@each'),

    nameWithRate: function() {
        return this.get('name') + ' (' + this.get('deductionRate').format('0.[00]%') + ')'
    }.property('name', 'rate'),

    ratePercent: function(key, value) {
        var rate
        if (arguments.length === 1) {
            rate = this.get('rate')
            value = Em.isEmpty(this.get('rate')) ? null : f.round(rate * 100, 2)
        } else {
            rate = Em.isEmpty(value) ? null : f.round(value / 100, 4)
            this.set('rate', rate)
        }
        return value
    }.property('rate'),

    grossRate: function() {
        var rate = this.get('rate')
        return rate / (1 + rate)
    }.property('rate'),

    calcTaxFromNetAmount: function(netAmount, decimals) {
        return f.round(netAmount * this.get('rate'), decimals)
    },

    calcTaxFromGrossAmount: function(grossAmount, decimals) {
        return f.round(grossAmount * this.get('grossRate'), decimals)
    }
})
