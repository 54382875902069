var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    stepsIsComplete: function() {
        var completion = this.get('model.commitment.completion')
        var complete = true
        var annualReportService = this.get('annualReport')

        this.get('annualReport').getStepOrder().some(function(stepRoute) {
            // We want to test all steps until we get to submit_for_processing. You exit the some() loop by returning true
            if (stepRoute === 'submit_for_processing') {
                return true
            }

            var settings = annualReportService.getStepSettings(stepRoute)
            if (!completion[stepRoute] && settings.isNeededForCompletion) {
                console.error('Step is not completed:', stepRoute)
                complete = false
                return true
            }
        })

        return complete
    }.property('steps.@each.{route,completed}'),

    disableCompleteButton: function() {
        return !this.get('stepsIsComplete') || this.get('missingAccounts').length > 0
    }.property('stepsIsComplete', 'missingAccounts'),

    actions: {
        didCompleteReport: function() {
            var self = this
            var commitmentId = this.get('commitment.id')
            var promise = this.isActiveStepCompleted()
                ? Em.RSVP.Promise.resolve()
                : this.get('annualReport')
                    .putSteps(this.get('model.commitment.year'), { submit_for_processing: true })
                    .then(function() {
                        return self.get('annualReport').putState(commitmentId, 'completed')
                    })

            promise.then(function() {
                self.send('navigateToNextStep')
            })
        }
    }
})
