var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var annualReportNavigation = require('../mixins/annual-report-navigation')

module.exports = Em.Controller.extend(annualReportNavigation, {
    sideMenu: Ember.inject.service(),

    userOrganizations: Ember.inject.service(),

    activeUmbrella: Em.computed.alias('sideMenu.activeUmbrella'),

    additionalInfoListIsInvalid: null,

    actions: {
        didClickApprove: function() {
            var self = this

            this.get('items').forEach(function(section) {
                section.validate && section.validate()
            })

            if (this.get('items').filterBy('isValid', false).length === 0) {
                this.get('annualReport')
                    .putSteps(this.get('model.commitment.year'), { about_your_taxes: true })
                    .then(function() {
                        self.send('navigateToNextStep')
                    })
            } else {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_INFO_APPROVE, t('annual_report.field_validation_errors.incomplete_fields'))
            }
        }
    }
})
