var storage = require('storage')
module.exports = Em.Route.extend({
    beforeModel: function(transition) {
        var api = this.container.lookup('api:billy')
        var params = transition.params.access_token
        var queryParams = transition.queryParams

        if (queryParams['organisation-url']) {
            storage('lastOrganizationUrl', queryParams['organisation-url'])
        }
        if (params.token) {
            api.authorize(params.token, 3600 / 20)
        }

        this.replaceWith('user', { queryParams: queryParams })
    }
})
