var AddOnBooleanComponent = require('./add-on-boolean')

module.exports = AddOnBooleanComponent.extend({
    classNames: ['add-on-auto-bookkeeping'],

    organizationSubscription: Em.inject.service(),

    bankConnectionCount: Em.computed.alias('organizationSubscription.bankConnectionCount'),

    isDisabled: function() {
        return this.get('bankConnectionCount') > 0 || this.get('isForcedDisabled')
    }.property('bankConnectionCount'),

    setupModel: function() {
        // Force activation if user has bank connections
        if (this.get('bankConnectionCount') > 0) {
            this.set('model.value', true)
        }
    }.on('init')
})
