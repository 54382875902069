var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var getDisplayDate = require('../utils/get-display-date')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    actions: {
        void: function() {
            var self = this
            var invoice = this.get('model')
            var entryDate = invoice.get('entryDate')
            var fiscalLockingDate = this.get('organization.fiscalLockingDate')
            var formattedFiscalLockingDate = getDisplayDate(fiscalLockingDate)
            var shouldAbortVoiding = entryDate.isBefore(fiscalLockingDate) || entryDate.isSame(fiscalLockingDate)

            if (shouldAbortVoiding) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.INVOICE_LATE_FEE_VOID_ABORT, t('invoice_late_fee.abort_notification', { fiscalLockingDate: formattedFiscalLockingDate }))
                return
            }

            this.container.lookup('util:dialog')
                .confirmWarning(null, t('invoice_late_fee.confirm_void'), t('invoice_late_fee.yes_void'), t('no'))
                .then(function() {
                    self.get('model')
                        .save({
                            properties: {
                                isVoided: true
                            }
                        })
                        .mask()
                        .on('success', function() {
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_LATE_FEE_VOID, t('invoice_late_fee.late_fee_voided_successfully'))
                        })
                })
        }
    }
})
