var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['bank-settings-modal'],

    needs: ['organization', 'user'],

    accountId: null, // from places which invoke the modal

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    auth: Em.inject.service(),

    bankConnections: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    layout: require('../templates/components/bank-settings-modal'),

    applyModalMask: false,

    accountName: Em.computed.alias('account.name'),

    customActions: {
        closeEmber: function(e) {
            this.close()
        },

        checkBankIntegration: function(e) {
            if (e.detail.connectedBankData) {
                this.set('hasBankIntegration', true)
            } else {
                this.set('hasBankIntegration', false)
            }
            this.set('bankConnections.bankConnection', e.detail.connectedBankData)
            this.set('bankConnections.isLoadingBankConnection', false)
        }
    }
})
