var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/table-column-toggler'),

    classNames: ['table-column-toggler'],

    matchWidth: false,

    minWidth: 200,

    align: 'right',

    listController: null,

    hideableColumns: function() {
        return this.get('listController.columns').filterBy('hideable', true)
    }.property('listController.columns.@each'),

    actions: {
        done: function() {
            this.destroy()
        }
    }
})
