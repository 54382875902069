var ApplicationAdapter = require('./application')
var NestedUrlAdapter = require('./nested-url')

module.exports = ApplicationAdapter.extend(NestedUrlAdapter, {
    urlTemplate: '/organizations/{organization}/emails',

    urlParamMap: {
        organization: 'organization.id'
    }
})
