module.exports = Em.Component.extend({
    layoutName: 'components/billy-welcome-modal',

    modalVariant: 'billyWelcomeModal',

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    subscriptionPlan: function() {
        var queryString = window.location.search
        var urlParams = new URLSearchParams(queryString)
        var planSelectedFromSignup = urlParams.get('plan_selected_from_signup')

        return planSelectedFromSignup
    }.property()
})
