var Window = require('ember-window')
var t = require('i18n').t

var CLOSING_TIMEOUT = 5000

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/bank-legal-note-modal'),

    organizationConsentEntries: Em.inject.service(),

    callback: null,

    willClose: null,

    onConfirm: null,

    isRedirecting: false,

    giveConsentOnConfirm: true,

    consentChecked: false,

    isContinueDisabled: function() {
        return this.get('isRedirecting') || !this.get('consentChecked')
    }.property('isRedirecting', 'consentChecked'),

    actions: {
        confirm: function() {
            var giveConsentOnConfirm = this.get('giveConsentOnConfirm')

            if (!giveConsentOnConfirm) {
                if (typeof this.onConfirm === 'function') {
                    this.onConfirm()
                }

                this.close()
                return
            }

            var self = this
            this.set('isRedirecting', true)

            this.get('organizationConsentEntries')
                .giveConsent(
                    self.get('organizationId'),
                    self.get('consentContext'),
                    self.get('consentContextVersion'),
                    t('bank_connection.legal_note')
                )
                .then(function(response) {
                    var consentEntry = response.organizationConsentEntries[0]

                    if (typeof self.onConfirm === 'function') {
                        self.onConfirm(consentEntry)
                    }

                    // we have to wait some time before closing modal as confirm action
                    // routes to some external resource - so we wait until view is reloaded
                    window.setTimeout(function() {
                        self.close()
                        self.set('isRedirecting', false)
                    }, CLOSING_TIMEOUT)
                })
        }
    }
})
