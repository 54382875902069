module.exports = Em.Component.extend({
    layoutName: 'components/lazy-content',

    classNames: ['lazy-content'],

    scrollThreshold: 1,

    debouceTimeout: 100,

    observeScrolling: function() {
        var scrollContent = this.$()
        var scrollContainer = scrollContent.scrollParent()
        var threshold = this.get('scrollThreshold')
        var triggerPrev = _.debounce(function() {
            this.sendAction('prevLazyContent')
        }.bind(this), this.get('debouceTimeout'))
        var triggerNext = _.debounce(function() {
            this.sendAction('nextLazyContent')
        }.bind(this), this.get('debouceTimeout'))

        scrollContainer.on('scroll', function() {
            var scrollHeight = scrollContainer.height()
            var scrollOffset = scrollContainer.scrollTop()
            var contentHeight = scrollContent.height()

            if (1 - (scrollOffset / (contentHeight - scrollHeight)) >= threshold) {
                // If top reached load prev page
                triggerPrev()
            }

            if (scrollOffset / (contentHeight - scrollHeight) >= threshold) {
                // If bottom reached load next page
                triggerNext()
            }
        })
    }.on('didInsertElement'),

    actions: {}
})
