module.exports = Em.Component.extend({
    classNames: ['invoices-list'],

    actions: {
        openInvoice: function(invoice) {
            var model = Billy.Invoice.find(invoice.id)
            this.container.lookup('router:main').transitionTo('invoice', model)
        }
    }
})
