var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['ar-general-assembly-fields'],

    annualReport: Em.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    item: null,

    initGeneralAssemblyAddress: function() {
        if (!Em.empty(this.get('address.value'))) {
            return
        }
        var org = this.get('organization')
        var street = org.get('street')
        var city = org.get('city')
        var zipcode = org.get('zipcode')

        if (!street || !zipcode || !city) {
            return
        }

        var address = street + ', ' + zipcode + ', ' + city

        Em.set(this.get('address'), 'value', address)
        this.send('saveValue', address, this.get('address'))
    },

    assemblyDate: function() {
        return this.get('item').findBy('name', 'additional_info.general_assembly.date')
    }.property('item.@each'),

    address: function() {
        return this.get('item').findBy('name', 'additional_info.general_assembly.address')
    }.property('item.@each'),

    conductor: function() {
        return this.get('item').findBy('name', 'additional_info.general_assembly.conductor')
    }.property('item.@each'),

    didInsertElement: function() {
        this.initGeneralAssemblyAddress()
    },

    actions: {
        saveValue: function(value, context) {
            var property = {
                name: context.name,
                value: value
            }

            this.get('annualReport').putAnswer(this.get('item.year'), [property])
        },

        saveDate: function(value) {
            var self = this
            var item = this.get('item')

            var property = {
                name: this.get('assemblyDate.name'),
                value: value ? value.format('YYYY-MM-DD') : null
            }

            if (!value) {
                self.get('assemblyDate').error.set('code', 'ValidationError')
                self.get('assemblyDate').error.set('text', t('annual_report.general_assembly_date_validation_error'))
                item.set('isValid', false)
                return
            }

            self.get('assemblyDate').error.set('code', null)
            self.get('assemblyDate').error.set('text', null)
            item.set('isValid', true)

            this.get('annualReport').putAnswer(this.get('item.year'), [property])
                .catch(function(error) {
                    if (error === 'additional_info.general_assembly.date') {
                        self.get('assemblyDate').error.set('code', 'ValidationError')
                        self.get('assemblyDate').error.set('text', t('annual_report.general_assembly_date_validation_error'))
                        item.set('isValid', false)
                    }
                })
        }
    }
})
