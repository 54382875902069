Billy.OrganizationOwner.reopen({
    isCompany: Em.computed.equal('type', 'company'),

    isPerson: Em.computed.equal('type', 'person'),

    isDk: Em.computed.equal('country.id', 'DK'),

    canLookupCvrInfo: function() {
        return (this.get('isDk') && this.get('organization.isDk') && this.get('isCompany'))
    }.property('organization.isDk', 'isDk', 'isCompany')
})
