var AuthorizedRouteLegacy = require('../mixins/authorized-route-legacy')
var Scope = require('../utils/scope')
var ReactDirtyRoute = require('../mixins/react-dirty-route')
var DirtyRoute = require('../mixins/dirty-route')

module.exports = Em.Route.extend(DirtyRoute, ReactDirtyRoute, AuthorizedRouteLegacy, {
    scopes: [Scope.SettingTaxWrite],

    model: function() {
        var organization = this.controllerFor('user').get('activeOrganization')

        return Em.RSVP.hash({
            organization: organization
        })
    },

    setupController: function(controller, models) {
        var organization = models.organization

        controller.set('model', organization)

        var organizationSettings = organization.get('settings')

        // Cast to boolean values
        var settings = Em.Object.create({
            isSummaryInclVat: !!organizationSettings.get('isSummaryInclVat'),
            isOrderNoEnabled: !!organizationSettings.get('isOrderNoEnabled'),
            isDuplicateInvoiceCheckDisabled: !organizationSettings.get('isDuplicateInvoiceCheckDisabled'),
            isDuplicateBillCheckDisabled: !organizationSettings.get('isDuplicateBillCheckDisabled'),
            supplierInvoiceNoEnabled: !!organizationSettings.get('supplierInvoiceNoEnabled')
        })

        controller.set('cleanSettings', settings)
        controller.set('settings', Em.Object.create(settings))
    }
})
