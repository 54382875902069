var i18n = require('i18n')
var t = i18n.t
var moment = require('momentjs')
var getThumbnailUrl = require('../utils/get-thumbnail-url')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Component.extend({
    layoutName: 'components/bills-index-simple',

    classNames: ['bills-index-simple'],

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    api: Em.inject.service(),

    activated: false,

    uploadCount: 0,

    isInboxEnabled: function() {
        return !!this.get('organization.settings.isInboxEnabled')
    }.property(),

    init: function() {
        var self = this

        if (this.get('isInboxEnabled')) {
            var organizationUrl = this.get('organization.url')
            document.location.replace('/' + organizationUrl + '/vouchers')
            return
        }

        this._super()
        this.load()

        this.get('api').request('GET', '/v2/accounts?organizationId=' + this.get('organization.id'), { headers: { 'accept-language': i18n.locale() } })
            .then(function(res) {
                if (!res || !res.accounts) return
                self.set('expenseCategories', res.accounts.map(function(account) {
                    return {
                        paymentAccountId: account.id,
                        name: account.name
                    }
                }))
            })
    },

    load: function(params) {
        var self = this
        var api = this.get('api')
        var queryString = 'organizationId=' + this.get('organization.id')

        this.set('params', params || {})

        if (!this.get('params.page')) this.set('params.page', 1)
        if (!this.get('params.sortProperty')) this.set('params.sortProperty', 'createdTime')
        if (!this.get('params.sortDirection')) this.set('params.sortDirection', 'desc')

        queryString += '&page=' + this.get('params.page')
        queryString += '&pageSize=10'
        queryString += '&sortProperty=' + this.get('params.sortProperty')
        queryString += '&sortDirection=' + this.get('params.sortDirection')
        queryString += '&include=bill.attachments,bill.lines,bill.contact'

        if (this.get('params.filter') && this.get('params.filter') !== 'ALL') {
            queryString += this.get('params.filter')
        } else {
            queryString += '&state%5B%5D=draft&state%5B%5D=approved'
        }
        if (this.get('params.entryDatePeriod')) {
            queryString += '&entryDatePeriod=' + this.get('params.entryDatePeriod')
        }

        if (this.get('params.q')) {
            queryString += '&q=' + this.get('params.q')
        }

        api.request('GET', '/v2/bills?' + queryString)
            .then(function(res) {
                if (!res.bills.length) {
                    return Em.RSVP.resolve(null)
                }

                if (!res.attachments.length) {
                    return Em.RSVP.resolve(null)
                }

                if (res.contacts) {
                    res.bills.forEach(function(bill) {
                        var contact = res.contacts.find(function(contact) {
                            return contact.id === bill.contactId
                        })

                        bill.vendorName = contact && contact.name
                    })
                }

                return Em.RSVP.all(
                    _.uniq(_.map(res.attachments, 'fileId'))
                        .map(function(fileId) {
                            return api.request('GET', '/v2/files/' + fileId)
                                .then(function(fileRes) {
                                    return fileRes.file
                                })
                        })
                ).then(function(files) {
                    res.files = files

                    return res
                })
            })
            .then(function(res) {
                if (!res) {
                    self.set('pageCount', 1)
                    self.set('page', 1)
                    self.setBills({})
                } else {
                    self.set('pageCount', res.meta.paging.pageCount)
                    self.set('page', res.meta.paging.page)
                    self.setBills(res)
                }
            })
    },

    billStateText: function(rawBill) {
        if (rawBill.state === 'approved') {
            if (rawBill.isPaid) {
                return 'paid'
            } else {
                var dueDate = moment(rawBill.dueDate, 'YYYY-MM-DD')
                var today = moment()

                if (dueDate.isBefore(today)) {
                    return 'overdue'
                } else {
                    return 'unpaid'
                }
            }
        }

        return 'draft'
    },

    billEntryDate: function(rawBill) {
        if (rawBill.state !== 'draft') {
            return moment(rawBill.entryDate, 'YYYY-MM-DD').format('LL').toLowerCase()
        }

        return null
    },

    setBills: function(rawBillsResult) {
        if (!rawBillsResult || !rawBillsResult.bills) {
            this.set('bills', [])
            return
        }

        var self = this
        var bills = rawBillsResult.bills.map(function(rawBill, i) {
            var firstLine = _.find(rawBillsResult.billLines, { billId: rawBill.id })
            var expenseCategories = self.get('expenseCategories')
            var expenseCategory = ''

            if (firstLine) {
                var expenseCategoryObj = _.find(expenseCategories, { paymentAccountId: firstLine.accountId })
                if (expenseCategoryObj) {
                    expenseCategory = expenseCategoryObj.name
                }
            }

            var thumbnailUrl = ''
            var firstAttachment = _.find(rawBillsResult.attachments, { ownerReference: 'bill:' + rawBill.id })

            if (firstAttachment) {
                var firstAttachmentFile = _.find(rawBillsResult.files, { id: firstAttachment.fileId })

                thumbnailUrl = getThumbnailUrl(firstAttachmentFile.downloadUrl, { size: '48x48', fit: 'contain' })
            }

            var bill = {
                rawEntryDate: rawBill.entryDate,
                entryDate: self.billEntryDate(rawBill),
                createdTime: moment(rawBill.createdTime).format('LL').toLowerCase(),
                expenseCategory: expenseCategory,
                id: rawBill.id,
                imageUrl: thumbnailUrl,
                lineDescription: firstLine && firstLine.description,
                selected: false,
                isDraft: rawBill.state === 'draft',
                state: rawBill.state,
                stateClassName: self.billStateText(rawBill),
                stateText: t('bills.simple_index.filter.' + self.billStateText(rawBill)),
                type: t('bills.simple_index.category.' + rawBill.type),
                amount: rawBill.amount,
                amountFormatted: Billy.money(rawBill.amount, self.get('organization.subscriptionCurrency'), '0,0'),
                currencyId: rawBill.currencyId,
                vendorName: rawBill.vendorName
            }

            return bill
        })

        self.set('bills', bills)
    },

    actions: {
        load: function() {
            this.load()
        },

        onUploadDone: function() {
            this.set('uploadCount', this.get('uploadCount') + 1)
            this.load()
        },

        setParams: function(params) {
            this.load(params)
        },

        deleteBills: function(idsToDelete) {
            var self = this
            var api = this.get('api')

            return Em.RSVP.all(
                _.map(idsToDelete, function(id) {
                    return api.request('DELETE', '/v2/bills/' + id)
                })
            ).then(function() {
                self.container.lookup('util:notification').success(NOTIFICATION_KEYS.BILLS_DELETE, t('bills.draft_overview.selected_bills.deleted'))
            }).catch(function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BILLS_DELETE, t('util.request.default_error'))
            }).finally(function() {
                self.load()
            })
        }
    }
})
