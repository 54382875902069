module.exports = Em.Component.extend({
    layoutName: 'components/attachment-viewer-example',
    classNames: ['attachment-viewer-example'],

    segment: Em.inject.service(),

    attachments: null,

    approvedContext: null,

    userController: function() {
        return this.container.lookup('controller:user')
    }.property(),

    user: Em.computed.oneWay('userController.model'),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    isOwner: function() {
        var currentUserId = this.get('user.id')
        var ownerUserId = this.get('organization.ownerUser.id')

        return currentUserId === ownerUserId
    }.property('user.id', 'organization.ownerUser.id'),

    actions: {
        showSubscriptionOverview: function() {
            var approvedContext = this.get('approvedContext')
            if (approvedContext) {
                this.get('segment').track('xxx - hamster - approved ' + approvedContext + ' link clicked 2023-01')
            }
            this.container.lookup('router:main').transitionTo('organization_subscription.plans_selection')
        }
    }
})
