module.exports = require('ember-number-field').extend({
    classNames: ['number-picker-field'],

    step: 1,

    noPadding: true,

    disableDecrease: function() {
        var min = this.get('min')

        return min !== null && min !== undefined && this.get('value') <= this.get('min')
    }.property('value', 'min'),

    disableIncrease: function() {
        var max = this.get('max')

        return max !== null && max !== undefined && this.get('value') >= this.get('max')
    }.property('value', 'max'),

    init: function() {
        this._super()
        var step = this.get('step')
        if (typeof step !== 'number') {
            this.set('step', 1 * step)
        }
    },

    pickerIncreaseIcon: 'icons/small-plus',
    pickerDecreaseIcon: 'icons/small-minus',

    keyDown: function(e) {
        var allowedKeys = [
            48, 49, 50, 51, 52, 52, 53, 54, 55, 56, 57, // Numbers
            96, 97, 98, 99, 100, 101, 102, 103, 104, 105, // Numbers (keypad)
            188, // Comma
            189, // Minus
            190, // Period
            110, // Period (keypad)
            13, // Enter
            46, // Delete
            8, // Backspace
            37, // Left
            39, // Right
            9 // Tab
        ]

        if (!e.metaKey && !allowedKeys.contains(e.which)) {
            // Note: The `dispatchKeyboardEvent` ember-testing helper isn't capable of sending the keycode when running in PhantomJS.
            // So we don't prevent default when testing through PhantomJS.
            if (!window.isCli) {
                e.preventDefault()
            }
        }

        if (e.keyCode === $.keyCode.UP) {
            this.move(1)
        } else if (e.keyCode === $.keyCode.DOWN) {
            this.move(-1)
        }
    },

    move: function(factor) {
        var v = 1 * (this.get('value') || 0)
        var step = this.get('step')
        var min = this.get('min')
        var max = this.get('max')

        v += factor * step

        if (!Em.isEmpty(min)) {
            v = Math.max(v, this.get('minIncluded') ? min : min + step)
        }

        if (!Em.isEmpty(max)) {
            v = Math.min(v, this.get('maxIncluded') ? max : max - step)
        }

        if (v === this.get('value')) {
            this.sendAction('boundaryReached')
        }

        this.set('value', v)
    },

    actions: {
        didClickPickerIncrease: function() {
            this.move(1)
            this.sendAction('onChange')
        },

        didClickPickerDecrease: function() {
            this.move(-1)
            this.sendAction('onChange')
        }
    }
})
