module.exports = Em.Controller.extend({
    needs: ['bankAccount', 'organization', 'user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    account: Em.computed.alias('controllers.bankAccount.model'),

    customActions: {
        selectBankAccount: function(e) {
            var selectedBankAccount = e.detail

            if (selectedBankAccount) {
                this.replaceRoute('bank_sync_react', selectedBankAccount.id)
            }
        },

        connectBankAccount: function() {
            return this.container.lookup('component:bank-connection-setup-nordic-api')
                .set('account', this.get('account'))
                .showPromise()
        },

        import: function() {
            this.transitionToRoute('bank_import')
        }
    }
})
