module.exports = Em.Route.extend({

    activate: function() {
        this._super()
        this.controllerFor('contact.customer').activate()
    },

    deactivate: function() {
        this._super()
        this.controllerFor('contact.customer').deactivate()
    }
})
