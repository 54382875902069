var Window = require('ember-window')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/bill-attachments-selector'),

    classNames: ['bill-attachments-selector'],

    width: 800,

    fullHeight: true,

    isLoading: true,

    attachments: null,

    showForOrganization: function(organization) {
        var self = this

        Billy.Bill.findByQuery({
            organizationId: organization.get('id'),
            state: 'draft',
            include: 'bills.attachments,attachments.file'
        }).promise
            .then(function(bills) {
                var attachments = []
                bills.forEach(function(bill) {
                    bill.get('attachments').forEach(function(attachment) {
                        attachments.push(attachment)
                    })
                })
                self.set('attachments', attachments)
                    .set('isLoaded', true)
            })

        this.show()
    },

    setupMouseListeners: function() {
        this.$().delegate('.bill-attachments-selector-item', 'mouseenter', this.onItemMouseEnter.bind(this))
        this.$().delegate('.bill-attachments-selector-item', 'mouseleave', this.onItemMouseLeave.bind(this))
    }.on('didInsertElement'),

    onItemMouseEnter: function(e) {
        var attachmentId = e.currentTarget.dataset.attachmentId
        if (attachmentId) {
            Em.run.join(this, function() {
                this.set('preview', Billy.Attachment.find(attachmentId))
            })
        }
    },

    onItemMouseLeave: function() {
        if (this.get('isDestroying')) {
            return
        }
        Em.run.join(this, function() {
            this.set('preview', null)
        })
    },

    actions: {
        select: function(attachment) {
            this.trigger('didSelect', attachment)
        }
    }
})
