var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var ListModelController = require('billy-webapp/ui/list/model-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var NumberColumn = require('billy-webapp/ui/list/columns/number')
var ListAction = require('billy-webapp/ui/list/action')

module.exports = ListModelController.extend({
    needs: ['user'],

    query: function() {
        return {
            organization: this.get('controllers.user.activeOrganization'),
            isNew: false
        }
    }.property('controllers.user.activeOrganization'),

    type: Billy.SalesTaxMetaField,

    sortProperty: 'priority',

    tableRowHeight: ListController.tableRowHeightAdditionalSmallLine,

    columns: [
        Column.create({
            header: t('name'),
            name: 'name',
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '<div class="two-rows-cell">' +
                        '<div>' +
                            '{{name}}' +
                            '{{#if isPredefined}}' +
                                ' <span class="label blue">' + t('predefined') + '</span>' +
                            '{{/if}}' +
                        '</div>' +
                        '<div class="small-font downtoned-text ellipsis">{{description}}&nbsp;</div>' +
                    '</div>'
                )
            })
        }),
        NumberColumn.create({
            header: t('priority'),
            name: 'priority',
            width: 100
        })
    ],

    listActions: [
        ListAction.create({
            icon: 'icons/pencil',
            tip: t('edit'),
            click: 'editMetaField'
        }),
        ListAction.create({
            icon: 'icons/trashcan',
            tip: t('delete'),
            click: 'deleteMetaField'
        })
    ]
})
