var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')
var CustomEvent = require('../constants/customEvent')

module.exports = Em.Mixin.create({
    needs: ['user'],

    customEvent: Ember.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    organizationOwners: null,

    _loadOrganizationOwners: function() {
        var self = this

        Billy.OrganizationOwner.findByQuery({
            organizationId: this.get('organization.id'),
            include: 'organizationOwner.city,organizationOwner.zipcode'
        }).promise.then(function(organizationOwners) {
            self.set('organizationOwners', organizationOwners)
        })
    },

    openCreateOwnerModal: function() {
        var self = this
        var organization = this.get('organization')

        this.container.lookup('component:organization-owner-editor')
            .set('content', Billy.OrganizationOwner.createRecord({
                organization: organization,
                country: organization.get('country'),
                type: 'person'
            }))
            .set('callback', function() {
                self._loadOrganizationOwners()
            })
            .show()
    },

    _settingsOrganizationCustomActions: {
        openCreateOwnerModal: function() {
            this.openCreateOwnerModal()
        },

        openEditOwnerModal: function(event) {
            var self = this

            if (event.detail.id) {
                var organizationOwner = Billy.OrganizationOwner.find(event.detail.id)

                self.container.lookup('component:organizationOwnerEditor').set('content', organizationOwner).show()
            }
        },

        openDeleteOwnerModal: function(event) {
            var self = this

            if (event.detail.id) {
                var organizationOwner = Billy.OrganizationOwner.find(event.detail.id)

                self.container.lookup('util:dialog')
                    .confirmWarning(null, t('confirm_deletion', { name: self.get('organizationOwner.name') }), t('yes'), t('no'))
                    .then(function() {
                        organizationOwner.deleteRecord()
                            .mask()
                            .on('success', function() {
                                self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.ORGANIZATION_OWNER_DELETE, t('confirmed_deletion.organization_owner'))
                                self.get('customEvent').dispatchEvent(CustomEvent.OrganizationOwnersUpdated)
                            })
                    })
            }
        }
    }
})
