module.exports = Ember.Component.extend({
    formattedRecipients: function() {
        var rs = this.get('entry.properties.recipients')
        if (!rs || rs.length === 0) {
            return '?'
        }
        return rs
            .map(function(r) {
                return r.email
            })
            .join(', ')
    }.property('entry.properties.recipients')
})
