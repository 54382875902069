var t = require('i18n').t
var FileUploadBatch = require('ember-file-uploader').Batch

module.exports = Em.ArrayController.extend({
    needs: ['organization'],

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    isInboxEnabled: function() {
        return !!this.get('organization.settings.isInboxEnabled')
    }.property(),

    billScanRequests: [],

    isLoaded: function() {
        return this.get('model.isLoaded')
    }.property('model.isLoaded'),

    uploadUrl: function() {
        var orgId = this.get('controllers.organization.model.id')

        var fileUrl = ENV.apiUrl + '/files'
        var voucherUrl = ENV.newApiUrl + '/organizations/' + orgId + '/vouchers'

        return this.get('isInboxEnabled') ? voucherUrl : fileUrl
    }.property(),

    sendAsFormData: function() {
        return this.get('isInboxEnabled')
    }.property(),

    formDataParams: function() {
        return {
            inboxState: 'received'
        }
    }.property(),

    model: function() {
        return Billy.Bill.filter({
            pageSize: 1000,
            query: {
                organization: this.get('controllers.organization.model'),
                state: 'draft'
            },
            remoteQuery: {
                sortProperty: 'createdTime',
                include: 'bill.contact,bill.paymentAccount,bill.lines,billLine.account,bill.attachments,attachment.file'
            },
            remote: true,
            comparator: 'createdTime'
        })
    }.property('controllers.organization.model'),

    uploadBatch: function() {
        return FileUploadBatch.create({})
    }.property(),

    _uploadBatchSizeDidChange: function() {
        var uploadBatch = this.get('uploadBatch')
        if (!this._uploadBackgroundTask && uploadBatch.get('fileCount') > 0) {
            this._uploadBackgroundTask = Billy.BackgroundTask.createWithMixins({
                container: this.container,

                draftBills: this,

                uploadBatch: uploadBatch,

                progress: Em.computed.alias('uploadBatch.progress'),

                icon: function() {
                    if (this.get('uploadBatch.pendingCount') === 0) {
                        if (this.get('uploadBatch.completedCount') === 0) {
                            return 'icons/cross-fat'
                        }

                        return 'icons/tick'
                    }
                    return null
                }.property('uploadBatch.pendingCount', 'uploadBatch.completedCount'),

                title: function() {
                    var completed = this.get('uploadBatch.completedCount')
                    var count = this.get('uploadBatch.fileCount')
                    if (this.get('uploadBatch.pendingCount') === 0 && completed === count) {
                        return t('bills.index.upload_background_task.title_done')
                    } else {
                        return t('bills.index.upload_background_task.title', { completed: completed, count: count })
                    }
                }.property('uploadBatch.pendingCount', 'uploadBatch.completedCount', 'uploadBatch.fileCount'),

                text: function() {
                    if (this.get('draftBills.length') > 0) {
                        return t('bills.index.upload_background_task.start_filling_in')
                    } else {
                        return t('please_wait_ellipsis')
                    }
                }.property('draftBills.length'),

                closeOnClick: Em.computed.equal('uploadBatch.pendingCount', 0),

                click: function() {
                    var self = this
                    var draftBills = this.get('draftBills')
                    if (draftBills.get('length') > 0) {
                        return function() {
                            self.container.lookup('router:main').transitionTo('bill.bulk_edit', draftBills.objectAt(0))
                        }
                    } else {
                        return null
                    }
                }.property('draftBills.length'),

                didClose: function() {
                    this.get('uploadBatch').abort()
                }
            })
            this._uploadBackgroundTask.on('didClose', function() {
                self._uploadBackgroundTask = null
            })
        }
    }.observes('uploadBatch.@each'),

    actions: {
        didUploadFinish: function() {
            if (this.get('uploadBatch.pendingCount') !== 0) {
                return
            }

            this.set('billScanRequests', [])
        },

        didFailDraftBill: function(context) {
            var file = context.file
            var error = new Error()

            error.type = 'BillScanError'
            error.code = 'E_UPLOAD_FAILURE'
            error.fileName = file.name

            this.billScanRequests.push(Em.RSVP.resolve(error))
            this.send('didUploadFinish')
        },

        didUploadDraftBill: function(context) {
            var file = context.file
            var bill = Billy.Bill.createRecord({
                organization: this.get('controllers.organization.model'),
                entryDate: moment(),
                state: 'draft',
                isBare: true
            })

            Billy.Attachment.createRecord({
                organization: this.get('controllers.organization.model'),
                owner: bill,
                file: file
            })

            bill.save({
                embed: ['lines', 'attachments']
            })
        }
    }
})
