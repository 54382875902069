var tracking = require('../utils/tracking')

module.exports = Em.Mixin.create({
    userOrganizations: Em.inject.service(),

    subscription: Ember.inject.service(),

    activeOrganization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    addons: Em.computed.alias('organizationSubscription.addons'),

    organizationSubscription: Em.inject.service(),

    updateSubscription: function(plan, period, addons, couponCode, price) {
        var self = this
        var organizationSubscription = this.get('organizationSubscription')

        this.set('error', null) // reset error

        return organizationSubscription.ensureLoaded()
            .then(function() {
                return organizationSubscription.editSubscription(plan, period, addons, couponCode, price)
            })
            .then(function() {
                if (plan === 'basic') {
                    tracking.emitAnalyticsEvent('plan', 'basic_chosen')
                }
                return organizationSubscription.load() // reload subscription
            })
            .catch(function(e) {
                self.set('error', e.message)
                throw e
            })
    },

    shouldMigrateCouponCode: function() {
        var billingType = this.get('activeOrganization.billingType')
        var couponCode = this.get('activeOrganization.couponCode')

        return billingType === 'own' && couponCode
    },

    _settingsSubscriptionCustomActions: {
        // This is the place called from inside React module when confirming subscription change. You're welcome
        editSubscription: function(e) {
            var self = this
            var callback = e.detail.callback
            var couponCode = e.detail.coupon

            if (!couponCode && this.shouldMigrateCouponCode()) {
                couponCode = this.get('activeOrganization.couponCode')
            }

            return self.updateSubscription(e.detail.plan, e.detail.period, undefined, couponCode, e.detail.price)
                .then(function() {
                    if (callback) {
                        callback()
                    }
                }, function(error) {
                    if (callback) {
                        self.container.lookup('util:notification').clear()
                        callback(error)
                    }
                })
        },

        // This is called from react-app when organization is fetched when entering Subscription settings
        fetchOrganization: function(event) {
            var data = event.detail

            if (data && Object.keys(data).length) {
                Billy.Organization.load(data)
            }
        },

        editCardSubscription: function(event) {
            var options = event.detail
            var subscription = this.get('subscription')
            var organization = this.get('activeOrganization')

            var forceExternal = organization.get('isAllowedForUpodiMigration')

            return subscription.subscribeCard(Object.assign({}, options || {}, {}), forceExternal)
        }
    }
})
