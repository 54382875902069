Ember.computed.sum = function(dependentKey) {
    return Ember.reduceComputed.call(null, dependentKey, {
        initialValue: 0,

        addedItem: function(accumulatedValue, item) {
            return accumulatedValue + item
        },

        removedItem: function(accumulatedValue, item) {
            return accumulatedValue - item
        }
    })
}
