var dnd = require('../utils/dnd')

module.exports = Em.Mixin.create({
    _setupDraggableLine: function() {
        var self = this.controller || this
        var $self = this.$()

        this._draggable = dnd.draggable(this.$('.draggable-column .tool-icons-reorder'), {
            helper: function() {
                var el = $self.clone()
                el.width($self.outerWidth())
                el.css('position', 'absolute')
                el.css('background-color', '#fff')
                el.css('opacity', 0.5)
                el.css('pointer-events', 'none')
                return el
            },

            appendTo: $('.droppable-overlay'),

            priority: function() {
                return self.get('priority')
            },

            start: function() {
                Em.run.schedule('sync', function() {
                    self.send('assertPriorities')
                })
                $('.droppable-overlay').show()
            },

            stop: function() {
                $('.droppable-overlay').hide()
            },

            view: this
        })

        this._droppable = dnd.droppable(this.$(), {
            drop: function(e, draggable, isAboveCenter) {
                var targetPriority = self.get('priority')
                var sourcePriority = draggable.priority()
                if (targetPriority > sourcePriority && isAboveCenter) {
                    targetPriority = targetPriority - 1
                } else if (targetPriority < sourcePriority && !isAboveCenter) {
                    targetPriority = targetPriority + 1
                }
                if (targetPriority === sourcePriority) {
                    return
                }
                Em.run.schedule('sync', function() {
                    self.send('updatePriorities', sourcePriority, targetPriority)
                })
            }
        })
    }.on('didInsertElement'),

    _teardownDraggableLine: function() {
        if (this._draggable) {
            this._draggable.destroy()
        }
        if (this._droppable) {
            this._droppable.destroy()
        }
    }.on('willDestroyElement')
})
