var t = require('i18n').t
var numeral = require('numeral')
var ListController = require('billy-webapp/ui/list/controller')
var ListModelController = require('billy-webapp/ui/list/model-controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var ListAction = require('billy-webapp/ui/list/action')

module.exports = ListModelController.extend({
    needs: ['user'],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    query: function() {
        var organization = this.get('organization')
        var query = {
            organization: organization,
            isNew: false
        }

        return query
    }.property(),

    type: Billy.TaxRate,

    sortProperty: 'name',

    tableRowHeight: ListController.tableRowHeightAdditionalSmallLine,

    isLazy: false,

    itemControllerClass: ListItemController.extend({
        formattedRate: function() {
            return numeral(this.get('deductionRatePercent')).format('0.[00]') + ' %'
        }.property('rate')
    }),

    columns: [
        Column.create({
            header: t('name'),
            name: 'name',
            sortable: true,
            flex: 1,
            cellViewClass: TableCellView.extend({
                template: Em.Handlebars.compile(
                    '<div class="two-rows-cell">' +
                        '<div>' +
                            '{{name}}' +
                            '{{#if isPredefined}}' +
                                ' <span class="label blue">' + t('tax_rates.predefined') + '</span>' +
                            '{{/if}}' +
                            '{{#unless isActive}}' +
                                ' <span class="label">' + t('tax_rates.deactivated') + '</span>' +
                            '{{/unless}}' +
                        '</div>' +
                        '<div class="small-font downtoned-text ellipsis">{{description}}&nbsp;</div>' +
                    '</div>'
                )
            })
        }),
        Column.create({
            header: t('tax_rate_editor.abbreviation'),
            name: 'abbreviation',
            width: 100
        }),
        Column.create({
            header: t('tax_rate_editor.rate'),
            name: 'formattedRate',
            width: 100,
            align: 'right'
        })
    ],

    listActions: [
        ListAction.create({
            icon: 'icons/pencil',
            tip: t('edit'),
            click: 'editRate'
        }),
        ListAction.create({
            icon: 'icons/trashcan',
            tip: t('delete'),
            click: 'deleteRate'
        })
    ]
})
