var Window = require('ember-window')
var i18n = require('i18n')
var t = i18n.t
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')
var doesStringContainHtml = require('../utils/does-string-contain-html')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/organization-email-window'),

    email: null,

    contactPerson: null,

    showForEmail: function(contact, email) {
        email.set('contactPersons', [])
        this.set('contact', contact)
            .set('email', email)
            .show()
    },

    contactPersonQuery: function() {
        return {
            contact: this.get('contact')
        }
    }.property('contact'),

    contactPersonDidChange: function() {
        var contactPerson = this.get('contactPerson')
        if (contactPerson && !contactPerson.get('email')) {
            this.set('error', t('organization_email_window.contact_person_must_have_email_address'))
            return
        }
        this.set('email.contactPersons', contactPerson ? [contactPerson] : [])
    }.observes('contactPerson'),

    isAttachment: Em.computed.equal('email.attachmentMode', 'attachment'),

    validate: function() {
        var email = this.get('email')
        var errors = email.get('errors')
        var isValid = true

        var requiredFields = [
            'subject',
            'message'
        ]
        requiredFields.forEach(function(f) {
            if (Em.isEmpty(email.get(f))) {
                errors.add(f, t('required_field'))
                isValid = false
            }
        })

        if (email.get('contactPersons.length') === 0) {
            errors.add('contactPersons', t('required_field'))
            isValid = false
        }

        if (doesStringContainHtml(email.get('message'))) {
            errors.add('message', t('invoice_reminder_editor.email_contains_html'))
            isValid = false
        }

        return isValid
    },

    actions: {
        send: function() {
            var self = this
            if (!this.validate()) {
                return
            }
            batmask.maskDelayed()
            this.get('email')
                .save()
                .then(function() {
                    self.close()
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.NOTIFICATION_KEYS, t('organization_email_window.email_sent'))
                    self.trigger('didSend')
                }, function(e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                    self.set('error', t('util.request.default_error'))
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
