module.exports = Em.Component.extend({
    classNames: ['v2-file-selector-invader'],

    accept: '*/*',

    multiple: false,

    hoverTo: null,

    hoverClass: 'hover',

    setupFileInput: function() {
        var self = this
        this.$('input').on('change', function(e) {
            var input = e.target
            self.sendAction('select', input.files)
            input.value = null
        })

        var parent = this.$().parent()
        parent.addClass('v2-file-selector-invader-parent')

        // Add .hover class to the upload button, since it's masked by the invisible file input field
        var hoverTo = this.get('hoverTo')
        if (hoverTo) {
            var hoverClass = this.get('hoverClass')
            parent.mouseenter(this._parentMouseEnter = function() {
                parent.find(hoverTo).addClass(hoverClass)
            })
            parent.mouseleave(this._parentMouseLeave = function() {
                parent.find(hoverTo).removeClass(hoverClass)
            })
        }
    }.on('didInsertElement'),

    willDestroyElement: function() {
        this._super()
        var parent = this.$().parent()
        parent.removeClass('v2-file-selector-invader-parent')
        parent.off('mouseenter', this._parentMouseEnter)
        parent.off('mouseleave', this._parentMouseLeave)
    }
})
