var Window = require('ember-window')
var batmask = require('batmask')
var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/organization-subscription-plan-window'),

    api: Em.inject.service(),

    // The organization to change owner of
    organization: null,

    // The currently logged in umbrella,\
    umbrella: null,

    // Will be called on success
    callback: null,

    items: [],

    findCurrentOrganizationProductPlan: function(items) {
        var subscriptionProductPlan = this.get('organization.subscriptionProductPlan')
        var subscriptionPeriod = this.get('organization.subscriptionPeriod')

        return items.find(function(item) {
            return item.product_plan_key === subscriptionProductPlan && item.product_plan_period === subscriptionPeriod
        })
    },

    onItemsLoaded: function(items) {
        var currentProductPlan = this.findCurrentOrganizationProductPlan(items)
        this.setItemChecked(currentProductPlan, items)
    },

    setItemChecked: function(checkedItem, items) {
        var newItems = items.map(function(item) {
            if (!checkedItem) {
                return item
            }

            item.checked =
                item.product_plan_key === checkedItem.product_plan_key &&
                item.product_plan_period === checkedItem.product_plan_period
            return item
        })

        this.set('items', newItems)
    },

    show: function() {
        this._super()

        var self = this
        var items = this.get('items')

        // If product plans already loaded - skip initial loading
        if (items.length) {
            this.onItemsLoaded(items)
            return
        }

        // Load product plans
        this.set('isLoading', true)
        Em.RSVP.all([
            this.get('api').getData('/umbrellas/' + this.get('umbrella.id') + '/organizations/' + this.get('organization.id') + '/getProductPlans')
        ])
            .then(function(res) {
                var items = res[0] || []

                self.onItemsLoaded(items)
                self.set('isLoading', false)
            })
    },

    error: null,

    actions: {
        didClickItem: function(clickedItem) {
            // Set checked flag
            var items = this.get('items')
            this.setItemChecked(clickedItem, items)
        },

        save: function() {
            var self = this
            var organizationId = this.get('organization.id')
            var umbrellaId = this.get('umbrella.id')
            var checkedPlan = this.get('items').find(function(item) { return item.checked })

            if (!checkedPlan) {
                return
            }

            var data = {
                organizationId: organizationId,
                umbrellaId: umbrellaId,
                plan: checkedPlan.product_plan_key,
                period: checkedPlan.product_plan_period
            }

            batmask.maskDelayed()

            var url = '/umbrellas/' + umbrellaId + '/subscriptions/organizations/' + organizationId + '/create'
            this.get('api').request('POST', url, {
                payload: {
                    data: data
                }
            })
                .then(function(res) {
                    // Update current organization plan details
                    var updatedOrg = res.data.organizations[0]
                    self.set('organization.subscriptionProductPlan', updatedOrg.subscriptionProductPlan)
                    self.set('organization.subscriptionPeriod', updatedOrg.subscriptionPeriod)
                    self.set('organization.subscriptionExpires', updatedOrg.subscriptionExpires)

                    self.close()
                    self.container.lookup('util:notification').success(
                        NOTIFICATION_KEYS.SUBSCRIPTION_UPDATE,
                        t('organization_subscription_window.saved')
                    )
                    var cb = self.get('callback')
                    if (cb) {
                        cb(res.data)
                    }
                }, function(e) {
                    if (e.type === 'API_ERROR') {
                        self.set('error', e.message)
                    } else {
                        throw e
                    }
                })
                .finally(function() {
                    batmask.unmask()
                })
        }
    }
})
