module.exports = Em.Route.extend({
    queryParams: {
        sort_direction: {
            replace: true
        },
        period: {
            replace: true
        },
        q: {
            replace: true
        },
        nordicApiSuccess: {
            replace: true
        },
        nordicApiSessionId: {
            replace: true
        },
        nordicApiError: {
            replace: true
        },
        fromRenewing: {
            replace: true
        }
    },

    setupController: function(controller) {
        controller.loadRecords()
        controller.loadBalance()
    },

    resetController: function(controller) {
        controller.set('nordicApiSessionId', undefined)
        controller.set('nordicApiError', undefined)
        controller.set('nordicApiSuccess', undefined)
        controller.set('fromRenewing', undefined)
    }
})
