Ember.Handlebars.registerBoundHelper('date', function(value, options) {
    if (!value) {
        return null
    }
    var format = options.hash.format || 'LL'
    var momentValue = moment(value)
    if (typeof momentValue[format] === 'function') {
        return momentValue[format]()
    } else {
        return momentValue.format(format)
    }
})
