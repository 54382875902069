var Window = require('ember-window')
var t = require('i18n').t
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/tax-rate-editor'),

    customEvent: Ember.inject.service(),

    callback: null,

    width: 600,

    salesOrPurchasesChecked: function() {
        return this.get('content.appliesToSales') || this.get('content.appliesToPurchases')
    }.property('content.appliesToSales', 'content.appliesToPurchases'),

    validateSalesOrPurchasesCheckboxes: function() {
        var taxRate = this.get('content')
        var oneFieldIsRequired = ['appliesToSales', 'appliesToPurchases']
        var salesOrPurchasesChecked = false

        oneFieldIsRequired.forEach(function(fieldName) {
            if (taxRate.get(fieldName)) {
                salesOrPurchasesChecked = true
            }
        })

        return salesOrPurchasesChecked
    },

    createAndShow: function(organization, props) {
        var taxRate = Billy.TaxRate.createRecord({
            organization: organization,
            isActive: true
        })
        if (props) {
            taxRate.setProperties(props)
        }
        taxRate.resetClean()
        this.set('content', taxRate)
        this.show()
    },

    deductionComponentSourceOptions: function() {
        return [
            Em.O({
                value: 'tax',
                name: this.get('content.organization.hasVat') ? t('tax_rates.vat.deduction_component_source.tax') : t('tax_rates.tax.deduction_component_source.tax')
            }),
            Em.O({
                value: 'netAmount',
                name: t('tax_rates.deduction_component_source.net_amount')
            })
        ]
    }.property('content.organization.hasVat'),

    actions: {
        addDeductionComponent: function() {
            Billy.TaxRateDeductionComponent.createRecord({
                taxRate: this.get('content'),
                share: 1,
                source: 'tax'
            })
        },

        deleteDeductionComponent: function(deductionComponent) {
            deductionComponent.deleteRecord()
        },

        close: function() {
            this.close()
        },

        save: function() {
            if (!this.validateSalesOrPurchasesCheckboxes()) {
                return
            }

            var self = this
            var taxRate = this.get('content')
            // the api wont allow sending deduction components for predefined taxrates, so don't send them if they're predefined
            var sendOptions = {}
            if (!taxRate.get('isPredefined')) {
                sendOptions = {
                    embed: ['deductionComponents']
                }
            }
            taxRate
                .save(sendOptions)
                .mask()
                .on('success', function() {
                    self.close()
                    self.get('customEvent').dispatchEvent(CustomEvent.TaxRuleUpdated)
                    if (self.get('callback')) {
                        self.get('callback')(taxRate)
                    }
                })
        }
    }
})
