var AccountBalanceMixin = require('../mixins/account-balance')
var AccountSelectorMixin = require('../mixins/account-selector')
var periodCp = require('../utils/period-computed-property')
var InfiniteScrollController = require('../mixins/infinite-scroll-controller')
var t = require('i18n').t

module.exports = Em.Controller.extend(InfiniteScrollController, AccountBalanceMixin, AccountSelectorMixin, {
    queryParams: {
        sortDirection: {
            as: 'sort_direction',
            scope: 'controller'
        },
        periodValue: {
            as: 'period',
            scope: 'controller'
        },
        q: {
            scope: 'controller'
        },
        nordicApiSuccess: {
            as: 'success',
            scope: 'controller'
        },
        nordicApiSessionId: {
            as: 'session_id',
            scope: 'controller'
        },
        nordicApiError: {
            as: 'error',
            scope: 'controller'
        },
        fromRenewing: {
            as: 'from_renewing',
            scope: 'controller'
        }
    },

    bankConnections: Em.inject.service(),

    addons: Em.computed.alias('organizationSubscription.addons'),

    organizationSubscription: Ember.inject.service(),

    needs: ['bankAccount'],

    account: Em.computed.alias('controllers.bankAccount.model'),

    limit: 500,

    sortProperty: 'entryDate',

    sortDirection: 'DESC',

    period: periodCp('periodValue'),

    q: '',

    isLoaded: false,

    isTruncated: false,

    query: function() {
        var period = this.get('period')
        var q = this.get('q')

        var query = {
            sortDirection: this.get('sortDirection'),
            includeVoided: false
        }
        if (period) {
            query.period = period.value
        }
        if (q) {
            query.q = q
        }
        return query
    }.property('period', 'q', 'sortDirection'),

    content: null,

    loadRecordsOnced: function() {
        var self = this
        var accountId = this.get('account.id')
        var query = this.get('query')
        var api = this.container.lookup('api:billy')

        if (!accountId) {
            return
        }

        var params = _.extend({
            limit: this.get('limit')
        }, query)

        this.set('isLoaded', false)
            .set('isTruncated', false)

        // Reset the records immediately if the last loaded records were from a different account
        if (accountId !== this.lastAccountId) {
            this.set('content', [])
            this.lastAccountId = accountId
        }

        api.get('/accounts/' + accountId + '/statement?' + $.param(params))
            .then(function(payload) {
                var accountStatement = payload.accountStatement

                var content = accountStatement.rows.reduce(function(content, item) {
                    if (!item.isSpecial) {
                        item.amount = item.debit ? item.debit : (item.credit ? -item.credit : 0)
                        item.isPositive = item.amount > 0
                        item.isNegative = item.amount < 0
                        item.description = formatDescription(item)
                        item.hasBalance = item.balance != null
                        content.push(item)
                    }
                    return content
                }, [])
                self.set('content', content)
                    .set('isLoaded', true)
                    .set('isTruncated', accountStatement.isTruncated)
            })
    },

    loadRecords: function() {
        Em.run.once(this, this.loadRecordsOnced)
    }.observes('query'),

    hasErrorFetchingTransactions: function() {
        var now = moment()
        var lastPullTime = this.get('account.bankConnection.lastPullTime')
        var hoursSinceLastFetch = now.diff(moment(lastPullTime), 'hours')

        if (hoursSinceLastFetch > 35) {
            return true
        }

        return false
    }.property('account.bankConnection.lastPullTime'),

    errorFetchingTransactionsMessage: function() {
        var bankName = this.get('account.bankConnection.bankName')
        var lastPullTime = this.get('account.bankConnection.lastPullTime')
        var lastPullTimeFormatted = moment(lastPullTime).format('LL')

        return t(
            'bank_connection_error_fetching_transactions',
            {
                bankName: bankName,
                lastPullTime: lastPullTimeFormatted
            }
        )
    }.property('account.bankConnection.bankName', 'account.bankConnection.lastPullTime'),

    actions: {
        edit: function() {
            this.container.lookup('component:account-editor')
                .set('content', this.get('account'))
                .show()
        },

        setupBankConnectionNordicApi: function() {
            this.get('bankConnections').setup(this.get('account'), 'nordic_api')
        }
    }
})

function formatDescription(item) {
    var originatorName = item.originatorName || ''
    var text = item.text || ''
    if (originatorName === text) {
        return text
    }
    return originatorName + ((originatorName && text) ? ' - ' : '') + text
}
