var Window = require('ember-window')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/daybook-edit-window'),

    content: null,

    width: 600,

    actions: {
        addBalanceAccount: function() {
            var daybook = this.get('content')
            var priority = 1 + (daybook.get('balanceAccounts.lastObject.priority') || 0)

            Billy.DaybookBalanceAccount.createRecord({
                daybook: daybook,
                priority: priority
            })
        },

        deleteBalanceAccount: function(balanceAccount) {
            balanceAccount.deleteRecord()
        },

        save: function() {
            var self = this
            var daybook = this.get('content')
            daybook.save({
                embed: ['balanceAccounts']
            })
                .mask()
                .on('success', function() {
                    self.close()
                    if (self.get('callback')) {
                        self.get('callback')(daybook)
                    }
                })
        }
    }
})
