var numeral = require('numeral')
var t = require('i18n').t

module.exports = Em.Component.extend({
    api: Em.inject.service(),

    organization: null,

    tasks: null,

    isUmbrella: false, // If true, extra features will be enabled such as create+accept

    completedTasks: function() {
        return this.get('tasks').reduce(function(completed, task) {
            if (task.get('state') === 'completed') {
                completed++
            }
            return completed
        }, 0)
    }.property('tasks.@each.state'),

    progress: function() {
        return this.get('completedTasks') / this.get('tasks.length')
    }.property('completedTasks', 'tasks.length'),

    progressFormatted: function() {
        return numeral(this.get('progress')).format('0%')
    }.property('progress'),

    createTaskVisible: false,

    newTask: null,

    newTaskError: null,

    newTaskSaving: false,

    actions: {
        toggleCreateTask: function() {
            this.toggleProperty('createTaskVisible')
            if (this.get('createTaskVisible')) {
                this.set('newTask', Em.Object.create())
                Em.run.schedule('afterRender', this, function() {
                    var field = this.$('input:first')
                    if (field) {
                        field.focus()
                    }
                })
            }
        },

        saveTask: function() {
            var self = this
            this.set('newTaskError', null)
            this.set('newTaskDescriptionError', null)

            var newTask = this.get('newTask')
            if (!newTask.get('description')) {
                this.set('newTaskDescriptionError', t('required_field'))
                return
            }

            this.set('newTaskSaving', true)

            this.get('api').request('POST', '/organizations/' + this.get('organization.id') + '/tasks', {
                payload: {
                    data: newTask
                }
            })
                .then(function(payload) {
                    newTask.set('id', payload.data.id)
                    newTask.set('state', 'pending')
                    self.get('tasks').unshiftObject(newTask)
                    self.toggleProperty('createTaskVisible', false)
                }, function(e) {
                    if (e.type === 'API_ERROR') {
                        self.set('newTaskError', e.message)
                    } else {
                        throw e
                    }
                })
                .finally(function() {
                    self.set('newTaskSaving', false)
                })
        },

        taskApproved: function(task) {
            this.get('tasks').removeObject(task)
        }
    }
})
