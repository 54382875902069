var evaluateMathExpression = require('../utils/evaluate-math-expression')

module.exports = require('ember-money-field').extend({
    format: '0,0.00',

    onEnter: function(value) {
        this.set('value', evaluateMathExpression(value))
    },

    // override, inherited from number-field
    unformatInputValue: function(value) {
        var inputValue = String(evaluateMathExpression(value))
        return (Em.isEmpty(inputValue) || !this.isValidNumberString(inputValue)) ? null : inputValue
    },

    // override, inherited from number-field
    isValidNumberString: function(value) {
        var inputValue = String(evaluateMathExpression(value))
        return this._super(inputValue)
    }
})
