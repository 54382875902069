module.exports = Em.Component.extend({
    classNames: ['feature-list-preview'],

    organization: null,

    isBasicSubscription: Em.computed.equal('subscriptionName', 'basic'),

    featureList: Em.inject.service(),

    getFeatures: function() {
        return this.get('featureList').getFeatures(true)
    }.property('organization.subscriptionPlan', 'featureList'),

    subscriptionName: function() {
        return this.get('organization.subscriptionPlan')
    }.property('organization.subscriptionPlan'),

    actions: {
        didClickUpdateSubscription: function() {
            var organization = this.get('organization')

            this.sendAction('didClickUpdateSubscription', organization)
        }
    }
})
