// DDAU = Data Down, Actions Up
module.exports = Em.Component.extend({
    // Inputs
    name: null,
    selectedValue: null,
    value: null,

    tagName: 'input',

    classNames: ['ember-radio'],

    attributeBindings: ['type', 'name', 'checked', 'disabled'],

    type: 'radio',

    disabled: false,

    checked: function() {
        return this.get('value') === this.get('selectedValue')
    }.property('value', 'selectedValue'),

    setupListeners: function() {
        this.$().on('click', this.onChange.bind(this))
    }.on('didInsertElement'),

    onChange: function(e) {
        e.preventDefault()
        this.sendAction('check', this.get('value'))
    }
})
