var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['annual-report-info-window'],

    layout: Window.proto().layout,

    template: require('../templates/components/annual-report-info-window'),

    organization: null,

    width: 800,

    focusSelector: null,

    actions: {
        close: function() {
            this.close()
        }
    }
})
