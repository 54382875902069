module.exports = Em.Component.extend(require('ember-tooltip').Tooltipable, {
    layoutName: 'components/table-cell-comment',

    classNames: ['table-cell-comment'],

    classNameBindings: ['text:visible'],

    tooltipElement: function() {
        return this.get('parentView').$()
    }.property(),

    tip: Em.computed.alias('text')
})
