var updateInvoiceLineTaxRate = require('../utils/invoice-line/update-tax-rate')
var invoiceTotalHelper = require('../utils/invoice-total-helper')
var DraggableLineMixin = require('../mixins/draggable-line')
var UpdateLinePriorityMixin = require('../mixins/update-line-priority')

module.exports = Em.Component.extend(DraggableLineMixin, UpdateLinePriorityMixin, {
    classNames: ['invoice-line-editor'],

    // Injected by invoice-editor.hbs
    line: null,
    showTaxRateFields: false,

    priority: Em.computed.alias('line.priority'),

    lines: Em.computed.alias('invoice.lines'),

    invoice: function() {
        var line = this.get('line')
        if (line instanceof Billy.InvoiceLine) {
            return line.get('invoice')
        } else if (this.container.lookupFactory('model:quote-line').detectInstance(line)) {
            return line.get('quote')
        } else if (this.container.lookupFactory('model:recurring-invoice-line').detectInstance(line)) {
            return line.get('recurringInvoice')
        }
    }.property('line.{invoice,quote,recurringInvoice}'),

    isRecurringInvoiceModel: function() {
        return this.container.lookupFactory('model:recurring-invoice').detectInstance(this.get('invoice'))
    }.property('invoice'),

    discountModeOptions: function() {
        return [
            Ember.Object.create({
                value: 'percent',
                name: '%'
            }),
            Ember.Object.create({
                value: 'cash',
                name: this.get('invoice.currency.id')
            })
        ]
    }.property('invoice.currency'),

    hasDiscount: function() {
        var discountMode = this.get('line.discountMode')
        return discountMode && discountMode !== 'none'
    }.property('line.discountMode'),

    discountIsPercent: Em.computed.equal('line.discountMode', 'percent'),

    // We need this since billy-api still serves discountValue for invoice-lines with 100-based numbers
    discountValuePercent: function(k, v) {
        var line = this.get('line')
        if (arguments.length > 1) {
            if (!(line instanceof Billy.InvoiceLine)) {
                v = v ? v / 100 : v
            }
            line.set('discountValue', v)
        } else {
            v = line.get('discountValue')
            if (!(line instanceof Billy.InvoiceLine)) {
                v = v ? v * 100 : v
            }
        }
        return v
    }.property('line.discountValue'),

    discountModeWillChange: function() {
        this._prevDiscountMode = this.get('line.discountMode')
    }.observesBefore('line.discountMode'),

    discountModeDidChange: function() {
        var line = this.get('line')
        if (line instanceof Billy.InvoiceLine) {
            return
        }
        var v = line.get('discountValue')
        if (v) {
            var prev = this._prevDiscountMode
            var mode = line.get('discountMode')
            if (prev === 'percent' && mode === 'cash') {
                line.set('discountValue', v * 100)
            } else if (prev === 'cash' && mode === 'percent') {
                line.set('discountValue', v / 100)
            }
        }
    }.observes('line.discountMode'),

    isInvoiceOrCreditNote: function() {
        return (this.get('invoice.type') === 'invoice' || this.get('invoice.type') === 'creditNote')
    }.property('invoice.type'),

    isDescriptionDisabled: function() {
        if (this.get('line.isNew') || !this.get('isInvoiceOrCreditNote')) {
            return false
        }

        return !this.get('invoice.isDraft')
    }.property('invoice'),

    isOnlyLine: Em.computed.equal('invoice.lines.length', 1),

    hasProductImage: function() {
        return this.get('invoice.lines').any(function(line) {
            return line.get('product.imageUrl')
        })
    }.property('invoice.lines.@each.product.imageUrl'),

    total: function() {
        return invoiceTotalHelper.line(this.get('invoice'), this.get('line'))
    }.property('line.{quantity,unitPrice,currentTaxRate,discountMode,discountValue}'),

    negativeDiscountAmount: function() {
        return -1 * this.get('total.discountAmount')
    }.property('total.discountAmount'),

    taxRateDidChange: function() {
        var line = this.get('line')

        // `currentTaxRate` it's used as an alias for the taxRate.rate. Required for "new" invoices:
        // quotes, recurring invoices, estimates, etc.
        if (!(line instanceof Billy.InvoiceLine)) {
            var taxRate = line.get('taxRate.rate') || 0
            var currentTaxRate = line.get('currentTaxRate') || 0

            if (currentTaxRate !== taxRate) {
                line.set('currentTaxRate', taxRate)
            }
        }
    }.observes('line.taxRate'),

    actions: {
        didSelectProduct: function(product) {
            if (!product) {
                return
            }
            var line = this.get('line')
            var invoice = this.get('invoice')
            product.promise.then(function() {
                line.set('description', product.get('description'))
                updateInvoiceLineTaxRate(line, invoice.get('organization'), invoice.get('contact'))
            })
            product.get('prices').promise.then(function(prices) {
                // Find product's default unit price (prioritizes invoice currency, followed by organization base currency)
                var invoiceCurrency = invoice.get('currency')
                var baseCurrency = invoice.get('organization.baseCurrency')
                var price = prices.findBy('currency', invoiceCurrency) || prices.findBy('currency', baseCurrency) || prices.objectAt(0)
                if (price) {
                    var unitPrice = price.get('currency').exchangeTo(price.get('unitPrice'), invoiceCurrency)
                    line.set('unitPrice', unitPrice)
                }
            })
        },

        viewProductImage: function() {
            var url = this.get('line.product.imageUrl')
            this.container.lookup('component:preview-window')
                .set('url', url)
                .show()
        },

        toggleDiscount: function() {
            var line = this.get('line')
            var isInvoiceLine = line instanceof Billy.InvoiceLine
            if (this.get('hasDiscount')) {
                line.set('discountMode', isInvoiceLine ? null : 'none')
                    .set('discountText', '')
                    .set('discountValue', isInvoiceLine ? null : 0)
            } else {
                line.set('discountMode', 'percent')
                    .set('discountValue', null)
            }
        },

        deleteLine: function() {
            // Emit an action from here, which should be handled by invoice-editor
            this.sendAction('deleteLine', this.get('line'))
        },

        toggleOnetime: function() {
            this.get('line').toggleProperty('isOnetime')
        }
    }
})
