var Popover = require('ember-popover')

module.exports = Popover.extend({
    layout: Popover.proto().layout,

    template: require('../templates/components/payment-terms-popover'),

    classNames: ['payment-terms-popover'],

    mode: null,

    days: null,

    dueDate: null,

    supportsDate: false,

    supportsNone: false,

    matchWidth: false,

    actions: {
        didSelect: function(value) {
            value = value || {}
            value.mode = this.get('mode')
            if (typeof value.days === 'undefined') {
                value.days = null
            }
            if (typeof value.dueDate === 'undefined') {
                value.dueDate = null
            }
            this.trigger('didSelect', value)
        }
    }
})
