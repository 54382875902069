var t = require('i18n').t
var AccountSelectorMixin = require('../mixins/account-selector')
var periodCp = require('../utils/period-computed-property')

var year = 2018

function getDateFromOrg(org, date) {
    return moment(org.get(date)).format('YYYY-MM-DD')
}

module.exports = Em.ObjectController.extend(AccountSelectorMixin, {
    needs: ['organization'],

    account: Em.computed.alias('model'),

    organization: Em.computed.alias('controllers.organization.model'),

    queryParams: {
        periodValue: 'period',
        sortProperty: 'sort',
        matchesQ: 'matchesQ',
        matchesApproved: 'approved',
        matchesMatched: 'matched',
        matchesUnmatched: 'unmatched',
        subjectsQ: 'subjectsQ',
        subjectsInvoices: 'invoices',
        subjectsBills: 'bills',
        subjectsPostings: 'postings',
        subjectsPaid: 'paid',
        subjectsUnpaid: 'unpaid',
        subjectsDraft: 'draft'
    },

    period: periodCp('periodValue'),
    sortProperty: 'entryDate',

    // lines filters
    matchesQ: null,
    matchesApproved: false,
    matchesMatched: true,
    matchesUnmatched: true,

    // subject filters
    subjectsQ: null,

    subjectsInvoices: true,

    subjectsBills: true,

    subjectsPostings: true,

    subjectsPaid: true,

    subjectsUnpaid: true,

    subjectsDraft: true,

    fiscalYearEndMonth: Em.computed.alias('organization.fiscalYearEndMonth'),

    firstFiscalYearStart: function() {
        return getDateFromOrg(this.get('organization'), 'firstFiscalYearStart')
    }.property('firstFiscalYearStart'),

    firstFiscalYearEnd: function() {
        return getDateFromOrg(this.get('organization'), 'firstFiscalYearEnd')
    }.property('firstFiscalYearEnd'),

    lastBankStatementImport: Em.computed.alias('controllers.organization.settings.lastBankStatementImport'),

    sortPropertyOptions: function() {
        return [
            {
                value: 'entryDate',
                label: t('bank_sync.entry_date')
            },
            {
                value: 'sideAmount',
                label: t('bank_sync.amount')
            }
        ]
    }.property(),

    actions: {
        didClickImport: function() {
            this.transitionToRoute('bank_import')
        },

        didClickArchive: function() {
            this.transitionToRoute('bank_approved_lines')
        },

        changePeriod: function() {
            this.set('periodValue', 'year:' + year++)
        },

        getPeriodValue: function() {
            var h = document.getElementsByTagName('datepicker-component')[0]
            var p = h.getAttribute('period')
            if (h !== this.get('periodValue', p)) {
                this.set('periodValue', p)
            }
        }
    }
})
