var _ = require('lodash')
var t = require('i18n').t
var storage = require('storage')

module.exports = Em.Controller.extend(Em.Evented, {
    afterLoginTransition: null,

    afterLoginHref: null,

    supportUrl: t('support_url'),

    blogUrl: function() {
        return t('blog_link')
    }.property(),

    isSupportEmpty: function() {
        return !this.get('articles.length') && !this.get('videos.length')
    }.property('articles.length', 'videos.length'),

    actions: {
        loggedIn: function() {
            var transition = this.get('afterLoginTransition')
            var href = this.get('afterLoginHref')
            var orgUrl = _.get(transition, 'params.organization.url')

            if (orgUrl && orgUrl !== 'in') {
                storage('lastOrganizationUrl', orgUrl)
            }

            // when whole "href" is passed, it has higher priority and it has to be handled first
            // please check "showLogin" method in app/routes/user.js
            if (href) {
                this.set('afterLoginHref', null)
                window.location.replace(href)
            } else if (transition && transition.targetName !== 'user.index') {
                this.set('afterLoginTransition', null)
                transition.retry()
            } else {
                this.replaceRoute('user')
            }
        },
        signup: function() {
            this.transitionToRoute('signup-guide')
        }
    }
})
