module.exports = Em.Component.extend({
    classNameBindings: [':badge', ':info-highlighted', ':clickable', 'isAuthorized:hidden'],
    tagName: 'span',

    auth: Em.inject.service(),

    scope: null,

    onOpenUpgrade: null,

    onOpenUpgradeContext: null,

    isAuthorized: function() {
        var scope = this.get('scope')
        if (!scope) {
            return true
        }

        return this.get('auth').isAuthorized(scope)
    }.property(),

    isHidden: function() {
        return this.get('isAuthorized')
    }.property('isAuthorized'),

    click: function() {
        var onOpenUpgrade = this.get('onOpenUpgrade')
        var onOpenUpgradeContext = this.get('onOpenUpgradeContext')

        if (typeof onOpenUpgrade === 'function' && onOpenUpgradeContext) {
            onOpenUpgrade.call(onOpenUpgradeContext)
        }

        this.container.lookup('component:upgrade-plan-overlay')
            .set('trackingContext', 'create_contact')
            .show()
    }
})
