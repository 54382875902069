var _ = require('lodash')

module.exports = function(locale, translations) {
    var processEnv = ENV
    var themeOverrides = require('./source/themes/' + processEnv.theme + '/' + locale)

    for (var key in themeOverrides) {
        translations[key] = themeOverrides[key]
    }

    // TODO: Replace # links!
    var themeMap = {
        Billy: processEnv.title,
        'https://billy.dk': processEnv.domainUrl,
        'https://www.billy.dk': processEnv.domainUrl,
        'billy@billy.dk': processEnv.supportEmail
    }

    // TODO: Why is this fn called _both_ at build time and runtime?
    // Why does it only seem to matter if ENV is correct at runtime?
    // To test, view this console output in both terminal and browser
    // ENV values are wrong in terminal, but correct in the browser
    // console.log('***')
    // console.log(themeMap)
    // console.log('***')
    return _.reduce(JSON.parse(JSON.stringify(translations)), function(acc, translation, key) {
        acc[key] = translation

        for (var p in themeMap) {
            if (Object.prototype.hasOwnProperty.call(themeMap, p)) {
                acc[key] = acc[key].replace(new RegExp(p, 'g'), themeMap[p])
            }
        }

        return acc
    }, {})
}
