var t = require('i18n').t

var attr = DS.attr
var premiumPaymentMethodTypes = [
    'mobilepay',
    'stripe',
    'pbs'
]

module.exports = DS.Model.extend({
    organization: attr('billy-data-organization', { apiKey: 'organizationId' }),
    createdTime: attr('moment-datetime'),
    updatedTime: attr('moment-datetime'),
    name: attr('string'),
    type: attr('string'),
    isDefault: attr('boolean'),
    isDisabled: attr('boolean'),
    isPending: attr('boolean'),
    productionMerchantId: attr('string'),
    supportsCardPayments: attr('boolean'),

    // PBS type
    pbsNumber: attr('string'),
    debitorGroupNumber: attr('string'),
    deliveryMode: attr('string'),

    // FI type
    creditorNumber: attr('string'),

    bankAccount: attr('billy-data-account', { apiKey: 'bankAccountId' }),

    text: attr('string'),

    stripeAuthorizationCode: attr('string'),

    humanType: function() {
        var type = this.get('type')
        if (type) {
            var key = type === 'other' ? 'other_type' : type
            return t('payment_methods.types.' + key)
        } else {
            return ''
        }
    }.property('type'),

    isPremiumRestricted: function() {
        return !this.get('organization.isSubscriptionPlanPremium') &&
            premiumPaymentMethodTypes.indexOf(this.get('type')) >= 0
    }.property('type', 'organization.isSubscriptionPlanPremium'),

    isCurrencyBlocked: function(currencyCode) {
        // if currency is DKK, do not block any payment methods
        if (currencyCode === 'DKK') {
            return false
        }

        // if currency is not DKK, block payment method if it's restricted
        var paymentMethods = this.container.lookup('service:paymentMethods')
        return paymentMethods.get('dkkPaymentMethodTypes').includes(this.get('type'))
    }
})
