var Window = require('ember-window')

module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/organization-subscription-locked-plan-window'),

    expirationDate: null,

    actions: {
        close: function() {
            this.close()
        }
    }
})
