var t = require('i18n').t
var Window = require('ember-window')
var numeral = require('numeral')
var moment = require('momentjs')
var postingSides = require('posting-sides')
var f = require('float')
var roundExchangeRate = require('../utils/round-exchange-rate')
var BankFeeAccountWindow = require('../components/bank-fee-account-window')
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var CustomEvent = require('../constants/customEvent')
var getDisplayDate = require('../utils/get-display-date')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/bank-payment-window'),

    paymentMethods: Em.inject.service(),

    customEvent: Ember.inject.service(),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    classNames: ['bank-payment-window'],

    confirmCloseWhenDirty: false,

    width: 800,

    primarySubject: null,

    model: Em.computed.alias('payment'),

    showForContact: function(contact, primarySubject) {
        var payment = Billy.BankPayment.createRecord({
            organization: contact.get('organization'),
            contact: contact,
            entryDate: moment(),
            cashAccount: this.guessBankAccount(primarySubject)
        })
        Billy.BalanceModifier.createRecord({
            modifier: payment,
            subject: primarySubject
        })
        this.set('primarySubject', primarySubject)
            .set('payment', payment)
        this.updateCashAmount()
        return this.show()
    },

    guessBankAccount: function(subject) {
        // accountId is initialized on open
        var accountId = this.get('accountId')

        if (subject instanceof Billy.Invoice) {
            // Prefill with the defaultInvoiceBankAccount, if exists
            if (subject.get('organization.defaultInvoiceBankAccount')) {
                return subject.get('organization.defaultInvoiceBankAccount')
            }

            var pmService = this.get('paymentMethods')
            if (!pmService.get('isLoaded')) {
                return null
            }
            // If no defaultInvoiceBankAccount, prefill with the first`type=bank` account.
            var bankAccount = null
            var paymentMethods = subject.get('paymentMethods')
            if (paymentMethods) {
                paymentMethods.find(function(r) {
                    var paymentMethod = pmService.find(r.paymentMethodId)
                    if (paymentMethod.get('type') === 'bank') {
                        bankAccount = paymentMethod.get('bankAccount')
                        return true
                    }
                })
            }
            return bankAccount
        } else if (subject instanceof Billy.Bill) {
            if (typeof accountId === 'string') {
                return Billy.Account.findByIdQuery(accountId)
            }

            return subject.get('organization.defaultBillBankAccount')
        }
        return null
    },

    subjectsListIsVisible: function() {
        var isSubjectCashBased = this.get('primarySubject.hasCashBasedAccounting')
        // We don't allow CBA subjects to be combined with other subjects when entering the payment
        return !isSubjectCashBased && (!this.get('primarySubject') || this.get('listController.records.length') > 1)
    }.property('primarySubject', 'listController.records.length'),

    isPaymentAmountHidden: function() {
        // We don't allow modifying payment amounts on CBA subjects, only full amounts are allowed
        return this.get('primarySubject.hasCashBasedAccounting')
    }.property('primarySubject'),

    subjects: function() {
        return this.get('payment.associations')
            .mapBy('subject')
            .filter(function(subject) {
                return !Em.isEmpty(subject)
            })
    }.property('payment.associations.@each.subject'),

    subjectCurrency: Em.computed.alias('subjects.firstObject.currency'),

    subjectSideAmount: function() {
        return this.get('subjects').reduce(function(sideAmount, subject) {
            return sideAmount.add(subject.get('balance'), subject.get('postingSide'))
        }, new Billy.SideAmount())
    }.property('subjects.@each.balance', 'subjects.@each.postingSide'),

    subjectAmount: function() {
        return this.get('subjectSideAmount.amount')
    }.property('subjectSideAmount').volatile(), // This has to be volatile, to counteract weird Ember behavior (I think?), where the subjectAmount is not updated when subjectSideAmount is updated

    updateCashAmount: function() {
        // The list controller might call this when the selection changes, but after this window has been destroyed
        if (this.get('isDestroying')) {
            return
        }
        var payment = this.get('payment')
        var subjectSideAmount = this.get('subjectSideAmount')
        var subjectAmount = subjectSideAmount.amount
        var cashSide = subjectSideAmount.side ? subjectSideAmount.side.opposite : null
        var subjectCurrency = this.get('subjectCurrency')
        var cashAccount = payment.get('cashAccount')
        var cashAmount = (cashAccount && subjectCurrency) ? subjectCurrency.exchangeTo(subjectAmount, cashAccount.get('currency')) : subjectAmount
        var cashExchangeRate = roundExchangeRate(cashAmount / subjectAmount)
        payment.set('cashSide', cashSide)
            .set('cashAmount', cashAmount)
            .set('cashExchangeRate', cashExchangeRate)
        this.set('cashSubjectAmount', subjectAmount)
    },

    cashAccountQuery: function() {
        return {
            isPaymentEnabled: true
        }
    }.property(),

    isDeposit: Em.computed.equal('payment.cashSide', postingSides.debit),

    currenciesAreDifferent: function() {
        var payment = this.get('payment')
        var cashCurrency = payment.get('cashAccount.currency')
        var subjectCurrency = this.get('subjectCurrency')
        return cashCurrency && subjectCurrency && cashCurrency !== subjectCurrency
    }.property('payment.cashAccount.currency', 'subjectCurrency'),

    cashSubjectAmount: null,

    updateCashSubjectAmount: function() {
        var payment = this.get('payment')
        var cashAmount = payment.get('cashAmount')
        if (this.get('currenciesAreDifferent')) {
            if (!this.get('isCashExchangeRateLocked')) {
                var cashExchangeRate = payment.get('cashExchangeRate')
                this.set('cashSubjectAmount', (!cashExchangeRate || cashExchangeRate === 0) ? null : f.round(cashAmount / cashExchangeRate))
            }
        } else {
            this.set('cashSubjectAmount', cashAmount)
        }
    }.observes('payment.{cashAmount,cashExchangeRate}'),

    updateCashExchangeRate: function() {
        if (!this.get('isCashSubjectAmountLocked')) {
            var payment = this.get('payment')
            var cashAmount = payment.get('cashAmount')
            var cashSubjectAmount = this.get('cashSubjectAmount')
            payment.set('cashExchangeRate', (!cashSubjectAmount || cashSubjectAmount === 0) ? null : roundExchangeRate(cashAmount / cashSubjectAmount))
        }
    }.observes('payment.cashAmount', 'cashSubjectAmount'),

    isCashExchangeRateLocked: true,

    isCashSubjectAmountLocked: Em.computed.not('isCashExchangeRateLocked'),

    expectedExchangeRate: function() {
        var subject = this.get('subjectCurrency.exchangeRate')
        var cash = this.get('payment.cashAccount.currency.exchangeRate')
        return cash > 0 ? subject / cash : 1
    }.property('payment.cashAccount.currency.exchangeRate', 'subjectCurrency.exchangeRate'),

    cashExchangeRateWarning: function() {
        if (!this.get('currenciesAreDifferent')) {
            return null
        }
        var expected = this.get('expectedExchangeRate')
        var actual = this.get('payment.cashExchangeRate')
        var deviation = Math.abs(expected - actual) / expected
        if (deviation > 0.05) {
            return t('bank_payment_window.exchange_rate_warning', { percentage: numeral(deviation).format('0,0%'), rate: numeral(expected).format('0.0[00000]') })
        }
        return null
    }.property('currenciesAreDifferent', 'payment.cashExchangeRate', 'expectedExchangeRate'),

    difference: function() {
        return Number(this.get('cashSubjectAmount')) - Number(this.get('subjectAmount'))
    }.property('cashSubjectAmount', 'subjectAmount'),

    hasDifference: function() {
        return this.get('difference') !== 0
    }.property('difference'),

    isDifferencePositive: function() {
        return this.get('difference') > 0
    }.property('difference'),

    absDifference: function() {
        return Billy.money(Math.abs(this.get('difference')), this.get('subjectCurrency'))
    }.property('difference', 'subjectCurrency'),

    differenceType: null,

    differenceDidChange: function() {
        // Only unset differenceType if difference really did change (Ember will fire observes even if set to the same value)
        if (this._lastDifference !== this.get('difference')) {
            this.set('differenceType', null)
        }
        this._lastDifference = this.get('difference')
    }.observes('difference'),

    differenceTypes: function() {
        var differenceTypes = []
        var difference = this.get('difference')
        var cashSide = this.get('payment.cashSide')
        if (difference < 0) {
            differenceTypes.push(Em.Object.create({
                value: 'underpayment',
                text: t('bank_payment_window.difference_type.underpayment')
            }))
        }
        if (difference > 0) {
            differenceTypes.push(Em.Object.create({
                value: 'overpayment',
                text: t('bank_payment_window.difference_type.overpayment')
            }))
        }
        if ((cashSide.isDebit && difference < 0) || (cashSide.isCredit && difference > 0)) {
            differenceTypes.push(Em.Object.create({
                value: 'bankFee',
                text: t('bank_payment_window.difference_type.bank_fee')
            }))
        }
        return differenceTypes
    }.property('difference', 'payment.cashSide'),

    differenceTypeDidChange: function() {
        var self = this
        var payment = this.get('payment')
        var differenceType = this.get('differenceType')
        if (differenceType === 'bankFee') {
            payment.set('feeAmount', f.round(Math.abs(this.get('difference')) * payment.get('cashExchangeRate')))
            BankFeeAccountWindow.getDefaultBankFeeAccount(payment.get('organization'), this.container)
                .then(function(bankFeeAccount) {
                    payment.set('feeAccount', bankFeeAccount)
                }, function() {
                    self.set('differenceType', null)
                })
        } else {
            payment.set('feeAmount', null)
                .set('feeAccount', null)
        }
    }.observes('differenceType'),

    differenceTypeText: function() {
        var absDifference = this.get('absDifference')
        var contactName = this.get('payment.contact.name')
        var differenceType = this.get('differenceType')
        switch (this.get('differenceType')) {
        case 'underpayment':
        case 'overpayment':
            var params = {
                name: contactName,
                amount: absDifference
            }
            if (this.get('isDeposit')) {
                if (differenceType === 'underpayment') {
                    return t('bank_payment_window.difference_result.underpayment_deposit', params)
                } else {
                    return t('bank_payment_window.difference_result.overpayment_deposit', params)
                }
            } else {
                if (differenceType === 'underpayment') {
                    return t('bank_payment_window.difference_result.underpayment_withdrawal', params)
                } else {
                    return t('bank_payment_window.difference_result.overpayment_withdrawal', params)
                }
            }

        case 'bankFee':
            return t('bank_payment_window.difference_result.bank_fee', { amount: absDifference })
        }
        return null
    }.property('differenceType', 'absDifference', 'payment.contact.name'),

    invoices: function() {
        var contact = this.get('payment.contact')
        if (!contact) {
            return null
        }

        var primarySubject = this.get('primarySubject')
        if (!(primarySubject instanceof Billy.Invoice)) {
            return Em.A().set('isLoaded', true)
        }

        var query = {
            contactId: contact.get('id'),
            state: 'approved',
            isPaid: false
        }
        if (primarySubject) {
            query.type = primarySubject.get('type')
            query.currencyId = primarySubject.get('currency.id')
        }
        return Billy.Invoice.find(query)
    }.property('payment.contact'),

    bills: function() {
        var contact = this.get('payment.contact')
        if (!contact) {
            return null
        }

        var primarySubject = this.get('primarySubject')
        if (!(primarySubject instanceof Billy.Bill)) {
            return Em.A().set('isLoaded', true)
        }

        var query = {
            contactId: contact.get('id'),
            state: 'approved',
            isPaid: false
        }
        if (primarySubject) {
            query.type = primarySubject.get('type')
            query.currencyId = primarySubject.get('currency.id')
        }
        return Billy.Bill.find(query)
    }.property('payment.contact'),

    possibleSubjects: function() {
        var a = Ember.MergedArray.create({
            owner: this,
            sortProperty: 'entryDate'
        })
        a.addSource(this, 'invoices')
        a.addSource(this, 'bills')
        return a
    }.property(),

    possibleSubjectsIsLoaded: function() {
        return (this.get('invoices.isLoaded') && this.get('bills.isLoaded'))
    }.property('invoices.isLoaded', 'bills.isLoaded'),

    paymentEntryDate: function() {
        return getDisplayDate(this.get('payment.entryDate'))
    }.property('payment.entryDate'),

    addPrimarySubjectToSelection: function() {
        var primarySubject = this.get('primarySubject')
        if (primarySubject && this.get('possibleSubjectsIsLoaded')) {
            this.get('listController').addSelected(primarySubject)
        }
    }.on('init').observes('primarySubject', 'possibleSubjectsIsLoaded'),

    listController: function() {
        return SubjectsListController.create({
            paymentEditor: this
        })
    }.property(),

    isButtonDisabled: function() {
        return this.get('subjects.length') === 0 || !this.get('payment.cashAccount') || (this.get('hasDifference') && !this.get('differenceType'))
    }.property('subjects.length', 'payment.cashAccount', 'hasDifference', 'differenceType'),

    setupLockedFieldListeners: function() {
        var self = this
        this.$().delegate('input[readonly][name="cashSubjectAmount"], input[readonly][name="cashExchangeRate"]', 'focus', function(e) {
            var el = this
            Em.run(function() {
                e.preventDefault()
                self.toggleProperty('isCashExchangeRateLocked')
                el.focus()
            })
        })
    }.on('didInsertElement'),

    actions: {
        onBack: function() {
            var onBack = this.get('onBack')

            if (typeof onBack === 'function') {
                this.close()
                onBack()
            }
        },

        didSelectCashAccount: function() {
            this.updateCashAmount()
        },

        toggleCashCurrencyLock: function() {
            this.toggleProperty('isCashExchangeRateLocked')
        },

        selectDifferenceType: function() {
            var self = this
            var p = this.get('differencePopover')
            if (p) {
                p.destroy()
            } else {
                p = this.container.lookup('component:bank-payment-difference-popover')
                this.set('differencePopover', p)
                p.set('differenceTypes', this.get('differenceTypes'))
                p.one('willDestroyElement', function() {
                    self.set('differencePopover', null)
                })
                p.one('didSelect', function(differenceType) {
                    self.set('differenceType', differenceType)
                    p.destroy()
                })
                p.show(this, this.$('.difference'))
            }
        },

        save: function() {
            var self = this
            var payment = this.get('payment')
            var subject = self.get('primarySubject')
            var subjectType = subject.get('type')
            var subjectId = subject.get('id')

            payment
                .save({
                    embed: ['associations']
                })
                .mask()
                .success(function() {
                    var subjectDetails = {
                        subjectId: subjectId,
                        subjectType: subjectType
                    }
                    self.get('customEvent').dispatchEvent(CustomEvent.PaymentSaved, subjectDetails)
                    self.close()
                })
        }
    }
})

var SubjectItemController = ListItemController.extend({
    paymentEditor: Em.computed.alias('listController.paymentEditor'),

    balanceWithCurrency: function() {
        return Billy.money(this.get('balance'), this.get('currency'))
    }.property('balance', 'currency')
})

var SubjectsListController = ListController.extend({
    paymentEditor: null,

    records: Em.computed.alias('paymentEditor.possibleSubjects'),

    isSelectable: true,

    itemControllerClass: SubjectItemController,

    didAddSelected: function(subject) {
        var paymentEditor = this.get('paymentEditor')
        var payment = paymentEditor.get('payment')
        if (!payment.get('associations').findBy('subject', subject)) {
            Billy.BalanceModifier.createRecord({
                modifier: payment,
                subject: subject
            })
            paymentEditor.updateCashAmount()
        }
    },

    didRemoveSelected: function(subject) {
        var paymentEditor = this.get('paymentEditor')
        var payment = paymentEditor.get('payment')
        if (!payment) {
            return
        }
        var modifiers = payment.get('associations')
        var modifier = modifiers.findBy('subject', subject)
        if (modifier) {
            modifier.deleteRecord()
            paymentEditor.updateCashAmount()
        }
    },

    columns: function() {
        var columns = []
        var primarySubject = this.get('paymentEditor.primarySubject')

        columns.push(DateColumn.create({
            header: t('bank_payment_window.date'),
            name: 'entryDate',
            width: 100
        }))

        var noField
        if (primarySubject instanceof Billy.Invoice) {
            noField = 'invoiceNo'
        } else if (primarySubject instanceof Billy.Bill) {
            noField = 'voucherNo'
        }
        if (noField) {
            columns.push(Column.create({
                header: '#',
                name: noField,
                flex: 0.55
            }))
        }

        columns.push(
            Column.create({
                header: t('bank_payment_window.description'),
                name: 'detailedDescription',
                flex: 1,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if isCreditNote}}' +
                        '<span class="label orange" title="' + t('credit_note') + '">' + t('credit_note_abbreviation') + '</span> ' +
                        '{{/if}}' +
                        '{{detailedDescription}}'
                    )
                })
            }),
            Column.create({
                header: t('bank_payment_window.due'),
                name: 'balanceWithCurrency',
                width: 110,
                align: 'right',
                cellViewClass: TableCellView.extend({
                    classNameBindings: ['controller.isCreditNote:red-text']
                })
            })
        )

        return columns
    }.property('paymentEditor.primarySubject'),

    totals: function() {
        var totalBalance = this.get('selection').reduce(function(totalBalance, subject) {
            return totalBalance + subject.get('balance')
        }, 0)
        return [
            {
                label: t('bank_payment_window.total_unpaid'),
                values: {
                    balanceWithCurrency: Billy.money(totalBalance, this.get('paymentEditor.subjectCurrency'))
                }
            }
        ]
    }.property('selection.@each.balance', 'paymentEditor.subjectCurrency')
})
