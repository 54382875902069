var periodCp = require('../utils/period-computed-property')

var moment = require('momentjs')

var DEFAULT_COMPARE_COUNT = 1

var ReportLocalStorageFilters = require('../mixins/report-local-storage-filters')
var isValueTruthy = require('../utils/is-value-truthy')

module.exports = require('./abstract-report').extend(ReportLocalStorageFilters, {
    // <implement>
    urlName: null,

    title: null,
    // </implement>

    organization: Em.computed.oneWay('controllers.organization.model'),

    queryParams: {
        periodValue: 'period',
        compareWith: 'compare_with',
        compareCount: 'compare_count',
        hideZeroes: 'hide_zeroes',
        invertAmounts: 'inverted',
        ytd: 'ytd'
    },

    periodValue: 'default', // route's setupController will set it to a proper default value

    period: periodCp('periodValue'),

    compareWith: 'period',

    compareCount: DEFAULT_COMPARE_COUNT,

    init: function() {
        this._super()
        this._initFiltersByLocalStorageValues()
    },

    reload: function() {
        this._super()
        this._initFiltersByLocalStorageValues()
    },

    _initFiltersByLocalStorageValues: function() {
        this.set('hideZeroes', this.isLocalStorageFilterApplied('hideZeroes'))
        this.set('invertAmounts', this.isLocalStorageFilterApplied('invertAmounts'))
        this.set('ytd', this.isLocalStorageFilterApplied('ytd'))
    },

    getNumberOfCompareOptions: function() {
        var firstFiscalYearStart = this.get('organization.firstFiscalYearStart')
        var periodValue = this.get('periodValue')
        var periodInfo = periodValue.split(':')[1]
        var periodSelected = periodValue.split(':')[0]
        var MAX_OPTIONS = 12
        var numberOfCompareOptions = 0

        switch (periodSelected) {
        case 'year':
            var selectedYear = periodInfo
            var dateFromYear = moment().year(selectedYear).startOf('year')
            numberOfCompareOptions = (dateFromYear.diff(firstFiscalYearStart, 'year', true))
            break
        case 'fiscalyear':
            var selectedYearFromFiscalYear = periodInfo.split(',')[1]
            var dateFromFiscalYear = moment().year(selectedYearFromFiscalYear).startOf('year')
            numberOfCompareOptions = (dateFromFiscalYear.diff(firstFiscalYearStart, 'year', true))
            break
        case 'quarter':
            var selectedYearFromQuarter = periodInfo.split('-')[0]
            var selectedQuarterFromQuarter = periodInfo.split('-')[1]
            var quarterPeriod = moment().year(selectedYearFromQuarter)
            quarterPeriod.quarter(selectedQuarterFromQuarter)
            numberOfCompareOptions = quarterPeriod.diff(firstFiscalYearStart, 'quarter')
            break
        case 'month':
            var selectedYearFromMonth = periodInfo.split('-')[0]
            var selectedMonthFromMonth = periodInfo.split('-')[1]
            var monthPeriod = moment().year(selectedYearFromMonth)
            monthPeriod.month(selectedMonthFromMonth)
            numberOfCompareOptions = monthPeriod.diff(firstFiscalYearStart, 'month')
            break
        default:
            numberOfCompareOptions = MAX_OPTIONS
        }

        var totalNumberOfOptions = Math.ceil(numberOfCompareOptions)

        return totalNumberOfOptions > MAX_OPTIONS ? MAX_OPTIONS : totalNumberOfOptions
    },

    resetCompareCount: function(newCount) {
        this.set('compareCount', newCount)
    },

    compareCountOptions: function() {
        var options = Em.A()

        var numberOfOptions = this.getNumberOfCompareOptions()

        if (numberOfOptions < this.get('compareCount')) {
            this.resetCompareCount(numberOfOptions > 0 ? DEFAULT_COMPARE_COUNT : 0)
        }

        var i
        for (i = 0; i <= numberOfOptions; i++) {
            options.pushObject(Ember.Object.create({
                value: i,
                name: i
            }))
        }
        return options
    }.property('period'),

    filterDidChange: function(state, filterName) {
        this.load()
        this.setFilterToLocalStorage(filterName, state[filterName])
    }.observes('period', 'compareWith', 'compareCount', 'hideZeroes', 'invertAmounts', 'ytd'),

    getReportUrl: function(format) {
        var url = '/organizations/' + this.get('organization.id') + '/' + this.get('urlName')
        var extension = format ? '.' + format : ''
        var params = {}
        var period = this.get('period.value')
        var compareWith = this.get('compareWith')
        var compareCount = this.get('compareCount')
        // since Ember checkboxes store their 'checked' values as strings, we need to check whether its boolean true or string true
        var hideZeroes = isValueTruthy(this.get('hideZeroes'))
        var invertAmounts = isValueTruthy(this.get('invertAmounts'))
        var ytd = isValueTruthy(this.get('ytd'))

        // For some reason it is set to "undefined" string. This is a thruty value
        // so everything falls appart
        // TODO investigate why it's set to "undefined" string and fix it
        compareWith = compareWith !== 'undefined' ? compareWith : undefined

        if (period) params.period = period
        if (compareWith) params.compareWith = compareWith
        if (compareWith && compareCount) params.compareCount = compareCount
        if (hideZeroes) params.hideZeroes = true
        if (invertAmounts) params.inverted = true
        if (ytd) params.ytd = true

        return url + extension + '?' + $.param(params)
    }
})
