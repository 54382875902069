var t = require('i18n').t
var Window = require('ember-window')
var CustomEvent = require('../constants/customEvent')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    api: Ember.inject.service(),

    cvr: Em.inject.service(),

    layout: Window.proto().layout,

    template: require('../templates/components/organization-owner-editor'),

    customEvent: Ember.inject.service(),

    content: null,

    callback: null,

    focusSelector: ':input:not(button):first',

    errors: function() {
        return Em.O()
    }.property(),

    nameLabel: function() {
        if (this.get('content.organization.isProprietorship')) {
            return t('proprietor_name')
        }

        return this.get('content.isCompany') ? t('contact_editor.company_name') : t('name')
    }.property('content.isCompany', 'content.organization.isProprietorship'),

    registrationNoLabel: function() {
        return this.get('content.isCompany') ? t('tax_no') : t('ssn')
    }.property('content.isCompany'),

    typeOptions: function() {
        return [
            Em.Object.create({
                label: t('contact.type.company'),
                value: 'company'
            }),
            Em.Object.create({
                label: t('contact.type.private_person'),
                value: 'person'
            })
        ]
    }.property(),

    _formatRegistrationNo: function(str) {
        if (str) {
            return str.replace(/\s/g, '').toUpperCase()
        }

        return ''
    },

    validate: function() {
        var errors = this.get('errors')
        var organizationOwner = this.get('content')
        var registrationNo = this._formatRegistrationNo(organizationOwner.get('registrationNo'))
        var isValid = true
        var hasZipcodeText = !Em.empty(organizationOwner.get('zipcodeText'))

        var requiredFields = [
            'name',
            'street',
            'country'
        ]

        if (organizationOwner.get('isCompany')) {
            requiredFields.push('registrationNo')
            // Validate nominalOwnershipPercent percentage
            if ((!Em.isEmpty(organizationOwner.get('nominalOwnershipPercent')) && organizationOwner.get('nominalOwnershipPercent') > 100) || organizationOwner.get('nominalOwnershipPercent') <= 0) {
                errors.set('nominalOwnershipPercent', t('validate_percentage_range'))
                isValid = false
            }
        }

        if (organizationOwner.get('organization.isCompany')) {
            requiredFields.push('nominalOwnershipPercent')
            requiredFields.push('startDate')
        }

        if (organizationOwner.get('country.hasFiniteZipcodes') && !hasZipcodeText) {
            requiredFields.push('zipcode', 'city')
        } else {
            requiredFields.push('zipcodeText', 'cityText')
        }

        if (organizationOwner.get('country.hasStates') && organizationOwner.get('country.hasFiniteStates')) {
            requiredFields.push('state')
        } else if (organizationOwner.get('country.hasStates')) {
            requiredFields.push('stateText')
        }

        requiredFields.forEach(function(f) {
            if (Em.isEmpty(organizationOwner.get(f))) {
                errors.set(f, t('required_field'))
                isValid = false
            }
        })

        // Validate CVR if type is company
        if (organizationOwner.get('canLookupCvrInfo') && !/^((?:DK)?\d{8})$/.test(registrationNo) && organizationOwner.get('isCompany')) {
            errors.set('registrationNo', t('validate_cvr_message'))
            isValid = false
        }

        return isValid
    },

    actions: {
        getCvrInfo: function() {
            var organizationOwner = this.get('content')
            if (!(organizationOwner.get('registrationNo') && Boolean(organizationOwner.get('registrationNo').match(/^\d{8}$/)))) {
                organizationOwner.set('errors.registrationNo', t('contact_editor.invalid_tax_id'))
                return
            }

            var model = this.get('model')
            this.get('api')
                .request('GET', this.get('cvr').getCvrCompaniesUrl(organizationOwner.get('registrationNo')), {
                    mask: true
                })
                .then(function(payload) {
                    var cvrInfo = payload.data
                    var streetText = _.chain(['street', 'number', 'numberTo'])
                        .map(_.partial(_.get, cvrInfo))
                        .reject(_.isNull)
                        .join(' ')
                        .value()

                    model.set('name', cvrInfo.name)
                    model.set('cityText', cvrInfo.city)
                    model.set('zipcodeText', cvrInfo.zipcode)
                    model.set('street', streetText)
                    model.set('stateText', cvrInfo.state)
                }).catch(function() {
                    organizationOwner.set('errors.registrationNo', t('contact_editor.get_cvr_data.error'))
                })
        },

        save: function() {
            var self = this
            var organizationOwner = this.get('content')

            if (!this.validate()) {
                return
            }

            organizationOwner
                .save()
                .mask()
                .success(function() {
                    if (self.get('callback')) {
                        self.get('callback')(organizationOwner)
                    }
                    self.get('customEvent').dispatchEvent(CustomEvent.OrganizationOwnersUpdated)
                    self.close()
                })
        }
    }
})
