var Window = require('ember-window')
var LiquidHelper = require('../utils/liquid-helper')
var serializer = require('../utils/model-serializer')
var t = require('i18n').t
var ListController = require('billy-webapp/ui/list/controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var CustomEvent = require('../constants/customEvent')
var NOTIFICATION_KEYS = require('../notificationKeys')
var doesStringContainHtml = require('../utils/does-string-contain-html')

module.exports = Window.extend(require('../mixins/dirty-window'), {
    layout: Window.proto().layout,

    template: require('../templates/components/invoice-reminder-editor'),

    templates: Em.inject.service(),

    customEvent: Ember.inject.service(),

    // Window options
    width: 800,
    focusSelector: ':input:not(button):first',
    preselectedInvoice: null,
    sendCopy: false,

    currentUser: function() {
        return this.container.lookup('controller:user').get('content')
    }.property(),

    sendCopyDidChange: function() {
        this.set('content.copyToUser', this.get('sendCopy') ? this.get('currentUser') : null)
    }.observes('sendCopy'),

    populateEmailAndSubject: function() {
        var reminder = this.get('content')
        var invoice = reminder.get('associations.firstObject.invoice')
        var tplVars = this.get('templates').variablesForInvoice(invoice)
        var snapshot = serializer(reminder)
        var subject = tplVars.invoiceReminderEmailSubject
        var body = tplVars.invoiceReminderEmailMessage
        var reminderMessage = tplVars.invoiceReminderMessage
        var liquidHelper = new LiquidHelper(invoice.get('contact.locale.id') || invoice.get('organization.locale.id'))

        reminder.set('emailSubject', subject ? liquidHelper.render(subject, snapshot) : '')
            .set('emailBody', body ? liquidHelper.render(body, snapshot) : '')
            .set('message', reminderMessage ? liquidHelper.render(reminderMessage, snapshot) : '')
    }.observes('content'),

    addFee: false,
    addFeeDidChange: function() {
        var reminder = this.get('content')
        if (!this.get('addFee')) {
            reminder.set('flatFee', 0)
                .set('percentageFee', 0)
        }
    }.observes('addFee'),

    actionType: 'email',
    actionTypes: function() {
        return [
            Ember.Object.create({
                label: t('invoice_reminder_editor.send_email'),
                value: 'email'
            }),
            Ember.Object.create({
                label: t('invoice_reminder_editor.download_pdf'),
                value: 'pdf'
            })
        ]
    }.property(),
    isActionTypeEmail: Em.computed.equal('actionType', 'email'),

    feeMode: 'flat',
    feeModes: function() {
        return [
            Ember.Object.create({
                name: t('invoice_reminder_editor.percent_fee'),
                value: 'percent'
            }),
            Ember.Object.create({
                name: t('invoice_reminder_editor.flat_fee'),
                value: 'flat'
            })
        ]
    }.property(),
    feeModeTypeIsFlat: Em.computed.equal('feeMode', 'flat'),
    feeModeTypeIsPercent: Em.computed.equal('feeMode', 'percent'),

    contactPersonQuery: function() {
        return {
            contact: this.get('content.contact')
        }
    }.property('content.contact'),

    sendEmailObserver: function() {
        this.set('content.sendEmail', this.get('isActionTypeEmail'))
    }.observes('isActionTypeEmail'),

    buttonLabel: function() {
        if (this.get('addFee')) {
            if (this.get('isActionTypeEmail')) {
                return t('invoice_reminder_editor.add_fee_send_email')
            } else {
                return t('invoice_reminder_editor.add_fee_download_pdf')
            }
        } else {
            if (this.get('isActionTypeEmail')) {
                return t('invoice_reminder_editor.send_email')
            } else {
                return t('invoice_reminder_editor.download_pdf')
            }
        }
    }.property('addFee', 'isActionTypeEmail'),

    // List
    invoices: function() {
        var contact = this.get('content.contact')
        if (!contact) {
            return Em.A()
        }
        return Billy.Invoice.filter({
            query: {
                contact: contact,
                state: 'approved',
                isPaid: false,
                isInvoice: true,
                isOverdue: true
            },
            sortProperty: 'entryDate',
            sortDirection: 'desc'
        })
    }.property('content.contact'),

    invoicesDidChange: function() {
        var invoice = this.get('preselectedInvoice')
        if (invoice) {
            this.get('listController.selection').pushObject(invoice)
            this.set('preselectedInvoice', null)
        }
    }.observes('invoices.@each'),

    listController: function() {
        return ListController.createWithMixins({
            reminderWindow: this,
            records: Em.computed.alias('reminderWindow.invoices'),
            isSelectable: true,
            columns: [
                DateColumn.create({
                    header: t('invoices.index.entry_date'),
                    name: 'entryDate',
                    width: 120
                }),
                Column.create({
                    header: t('invoice_reminder_editor.number'),
                    name: 'invoiceNo',
                    width: 40
                }),
                Column.create({
                    header: t('description'),
                    name: 'detailedDescription',
                    flex: 1
                }),
                MoneyColumn.create({
                    header: t('invoices.index.balance'),
                    name: 'balance',
                    width: 80,
                    cellViewClass: TableCellView.extend({
                        classNameBindings: ['controller.isCreditNote:red-text']
                    })
                }),
                Column.create({
                    header: '',
                    name: 'currency.id',
                    width: 50
                })
            ]
        })
    }.property(),

    actions: {
        save: function() {
            var self = this
            var reminder = this.get('content')
            var updatedInvoicesIds = []

            if (doesStringContainHtml(reminder.get('emailBody'))) {
                this.set('content.errors.emailBody', t('invoice_reminder_editor.email_contains_html'))
                return
            }

            if (this.get('addFee')) {
                if (this.get('feeModeTypeIsFlat') && reminder.get('flatFee') === null) {
                    this.set('content.errors.flatFee', t('required_field'))
                    return
                } else if (!this.get('feeModeTypeIsFlat') && reminder.get('percentageFee') === null) {
                    this.set('content.errors.percentageFee', t('required_field'))
                    return
                }
            }

            // Remove existing associations
            reminder.get('associations').forEach(function(association) {
                association.rollback()
            })

            // Create new associations
            this.get('listController.selection').forEach(function(invoice) {
                updatedInvoicesIds.push(invoice.get('id'))
                Billy.InvoiceReminderAssociation.createRecord({
                    reminder: reminder,
                    invoice: invoice
                })
            })

            // Coerce fee values (they should be mutually exclusive)
            if (this.get('addFee')) {
                if (this.get('feeModeTypeIsFlat')) {
                    reminder.set('percentageFee', 0)
                } else {
                    reminder.set('flatFee', 0)
                }
            } else {
                reminder.set('percentageFee', 0)
                reminder.set('flatFee', 0)
            }

            // Save reminder
            reminder
                .save({
                    embed: ['associations']
                })
                .mask()
                .on('success', function(payload) {
                    var url = payload.meta.downloadUrl

                    // Show download file dialog
                    if (url && !self.get('isActionTypeEmail')) {
                        var iframe = $('<iframe id="pdf-download" style="position: absolute; width: 10px; height: 10px; left: -1000px;"></iframe>')
                        iframe.attr('src', url)
                        $('body').append(iframe)
                        self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.INVOICE_REMINDER_EDITOR_DOWNLOAD_STARTED, t('invoice_reminder_editor.download_started'))
                    }

                    if (self.get('addFee')) {
                        updatedInvoicesIds.forEach(function(invoiceId) {
                            self.get('customEvent').dispatchEvent(CustomEvent.PaymentUpdated, { subjectId: invoiceId, subjectType: 'invoice' })
                        })
                    }

                    // Close editor
                    self.close()
                })
                .on('error', function(errorMessage, error) {
                    if (error.errorCode === 'EMAILS_LIMIT') {
                        self.set('confirmCloseWhenDirty', false)
                        self.close()
                        self.container.lookup('component:upgrade-plan-overlay')
                            .set('trackingContext', 'email_rate_exceeded')
                            .show()
                    }
                })
        },

        didSelectContactPerson: function(contactPerson) {
            if (!this.get('isActionTypeEmail') || !contactPerson) {
                return
            }
            if (!contactPerson.get('email')) {
                this.set('content.errors.contactPerson', t('invoice_reminder_editor.contact_person_must_have_email_address'))
            }
        }
    }
})
