var t = require('i18n').t
var SelectField = require('ember-select-field')

module.exports = SelectField.extend({
    period: null,

    plural: false,

    value: 'period',

    periodType: function() {
        var period = this.get('period')
        return period && period.get('value').split(':')[0]
    }.property('period'),

    initPeriodCheckValid: function() {
        var value = this.get('value')
        if (!this.get('content').findBy('value', value)) {
            this.set('value', this.get('content.firstObject.value') || null)
        }
    }.on('init'),

    periodDidChange: function() {
        this.set('value', this.get('content.firstObject.value'))
    }.observes('periodType'),

    disabled: function() {
        var periodType = this.get('periodType')
        return !periodType
    }.property('periodType'),

    content: function() {
        // var formKey = this.get('plural') ? 'plural' : 'singular'
        var periodType = this.get('periodType')
        var values
        if (periodType === 'month') {
            values = ['period', 'year']
        } else if (periodType === 'quarter') {
            values = ['period', 'year']
        } else if (periodType === 'dates') {
            values = ['period', 'year']
        } else if (periodType === 'year') {
            values = ['period']
        } else if (periodType === 'fiscalyear') {
            values = ['period']
        } else {
            values = []
        }
        return values.map(function(value) {
            return Ember.Object.create({
                value: value,
                name: t('compare_with_period_field.' + periodType + '.' + value)
            })
        })
    }.property('periodType')
})
