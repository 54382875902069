module.exports = Em.Component.extend({
    actions: {},

    eventHandlers: [],

    didInsertElement: function() {
        var element = this.get('element')
        var actions = this.get('actions')
        var target = this.get('target')

        if (!actions || !target || !element) {
            return
        }

        var eventNames = Object.keys(actions)
        var eventHandlers = []

        for (var i = 0; i < eventNames.length; i++) {
            var eventName = eventNames[i]
            var listener = element.addEventListener(eventName, actions[eventName].bind(target))
            eventHandlers.push({
                eventName: eventName,
                listener: listener
            })
        }

        this.set('eventHandlers', eventHandlers)
    },

    willDestroyElement: function() {
        var element = this.get('element')
        var eventHandlers = this.get('eventHandlers')

        for (var handler in eventHandlers) {
            element.removeEventListener(handler.eventName, handler.listener)
        }
    }
})
