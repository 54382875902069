module.exports = Em.Component.extend({
    classNames: ['form'],

    setupEnterListener: function() {
        var self = this
        this.$('input').enter(function() {
            Em.run(function() {
                self.send('didClickTick')
            })
        })
    }.on('didInsertElement')
})
