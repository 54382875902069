module.exports = {
    APIPartner: 'apipartner',
    Archive: 'archive',
    Basic: 'basic',
    Complete: 'complete',
    Employee: 'employee',
    FriendsofBilly: 'friendofbilly',
    InternalLicense: 'internallicense',
    NonProfit: 'nonprofit',
    Premium: 'premium',
    Pro: 'pro',
    SpecialFree: 'specialfree'
}
