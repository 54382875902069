module.exports = Em.Component.extend({
    classNames: ['assets-summary'],

    classNameBindings: ['isValid'],

    year: null,

    lastYear: function() {
        return this.get('year') - 1
    }.property('year'),

    lastYearAssets: function() {
        return this.get('assets.previous')
    }.property('assets.previous'),

    thisYearAssets: function() {
        return this.get('assets.current')
    }.property('assets.current'),

    isValid: function() {
        return this.get('assets.validates')
    }.property('assets.validates')
})
