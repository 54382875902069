var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['app-store'],

    labsController: function() {
        return this.container.lookup('controller:labs')
    }.property(),

    organization: null,

    highlighted: false,

    allApps: function() {
        var labs = this.get('labsController')
        var apps = [
            {
                icon: 'apps/izettle.png',
                name: t('app_store.izettle.name'),
                key: 'i-zettle',
                description: t('app_store.izettle.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.izettle.url'),
                dkOnly: true
            },
            {
                icon: 'apps/billy-mobile-app.png',
                name: t('app_store.mobile_app.name'),
                key: 'billy-mobile-app',
                description: t('app_store.mobile_app.description'),
                price: t('app_store.free'),
                url: t('app_store.mobile_app.url'),
                highlighted: true
            },
            {
                icon: 'apps/billy-payment-methods.png',
                name: t('app_store.payment_methods.name'),
                key: 'payment-method',
                description: t('app_store.payment_methods.description'),
                price: t('app_store.free'),
                url: t('app_store.payment_methods.url'),
                highlighted: true
            },
            {
                icon: 'apps/billy-templates.png',
                name: t('app_store.templates.name'),
                key: 'invoice-designer',
                description: t('app_store.templates.description'),
                price: t('app_store.free'),
                url: t('app_store.templates.url'),
                highlighted: true
            },
            {
                icon: 'apps/billy-ean.png',
                name: t('app_store.ean.name'),
                key: 'gln-invoicing',
                description: t('app_store.danaweb.description'),
                price: t('app_store.free'),
                url: t('app_store.ean.url'),
                highlighted: true
            },
            labs.get('pbs') && {
                icon: 'apps/betalingsservice.png',
                name: t('app_store.pbs.name'),
                key: 'betaling-service',
                description: t('app_store.pbs.description'),
                price: t('app_store.free'),
                installSetting: 'featurePBS',
                installedMessage: t('app_store.pbs.installed'),
                uninstalledMessage: t('app_store.pbs.uninstalled'),
                dkOnly: true,
                highlighted: true
            },
            {
                icon: 'apps/ajour.png',
                name: t('app_store.ajour.name'),
                key: 'ajour',
                description: t('app_store.ajour.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.ajour.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/dataloen.png',
                name: t('app_store.dataloen.name'),
                key: 'datalon',
                description: t('app_store.dataloen.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.dataloen.url'),
                dkOnly: true
            },
            {
                icon: 'apps/salary.png',
                name: t('app_store.salary.name'),
                key: 'salary',
                description: t('app_store.salary.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.salary.url'),
                dkOnly: true
            },
            {
                icon: 'apps/minuba.png',
                name: t('app_store.minuba.name'),
                key: 'minuba',
                description: t('app_store.minuba.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.minuba.url'),
                dkOnly: true
            },
            {
                icon: 'apps/smartweb.png',
                name: t('app_store.smartweb.name'),
                key: 'smart-web',
                description: t('app_store.smartweb.description'),
                price: t('app_store.from_0'),
                url: t('app_store.smartweb.url'),
                dkOnly: true,
                highlighted: true
            },
            {
                icon: 'apps/shoporama.png',
                name: t('app_store.shoporama.name'),
                key: 'shoporama',
                description: t('app_store.shoporama.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.shoporama.url'),
                dkOnly: true
            },
            {
                icon: 'apps/magento.png',
                name: t('app_store.magento.name'),
                key: 'magento',
                description: t('app_store.magento.description'),
                price: t('app_store.from_0'),
                url: t('app_store.magento.url')
            },
            // Temporarily disabled due to bad bugs :(
            // {
            //  icon: 'apps/shopify.png',
            //  name: t('app_store.shopify.name'),
            //  description: t('app_store.shopify.description'),
            //  price: t('app_store.price_per_month', {price: '$12'}),
            //  url: t('app_store.shopify.url'),
            //  highlighted: true
            // },
            {
                icon: 'apps/prestashop.png',
                name: t('app_store.prestashop.name'),
                key: 'prestashop',
                description: t('app_store.prestashop.description'),
                price: t('app_store.from_0'),
                url: t('app_store.prestashop.url')
            },
            {
                icon: 'apps/danaweb.png',
                name: t('app_store.danaweb.name'),
                key: 'danaweb',
                description: t('app_store.danaweb.description'),
                price: t('app_store.from_0'),
                url: t('app_store.danaweb.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/timestarter.png',
                name: t('app_store.timestarter.name'),
                key: 'timestarter',
                description: t('app_store.timestarter.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.timestarter.url'),
                highlighted: true
            },
            {
                icon: 'apps/scannet.png',
                name: t('app_store.scannet.name'),
                key: 'scannet',
                description: t('app_store.scannet.description'),
                price: t('app_store.scannet.price'),
                url: t('app_store.scannet.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/bluegarden.png',
                name: t('app_store.bluegarden.name'),
                key: 'lonadministration',
                description: t('app_store.bluegarden.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.bluegarden.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/gratisal.png',
                name: t('app_store.gratisal.name'),
                key: 'gratisal',
                description: t('app_store.gratisal.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.gratisal.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/golden-planet.png',
                name: t('app_store.golden_planet.name'),
                key: 'golden-planet',
                description: t('app_store.golden_planet.description'),
                price: t('app_store.golden_planet.price'),
                url: t('app_store.golden_planet.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/thetispack.png',
                name: t('app_store.thetispack.name'),
                key: 'thetis-pack',
                description: t('app_store.thetispack.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.thetispack.url'),
                dkOnly: true
            },
            {
                icon: 'apps/iglobe-outlook.png',
                name: t('app_store.outlook.iglobe.name'),
                key: 'outlook',
                description: t('app_store.outlook.iglobe.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.outlook.iglobe.url'),
                dkOnly: true
            },
            {
                icon: 'apps/smartday.png',
                name: t('app_store.smartday.name'),
                key: 'smartday',
                description: t('app_store.smartday.description'),
                price: t('app_store.smartday.price'),
                url: t('app_store.smartday.url'),
                dkOnly: true
            },
            {
                icon: 'apps/woocommerce.png',
                name: t('app_store.woocommerce.name'),
                key: 'woocommerce',
                description: t('app_store.woocommerce.description'),
                price: t('app_store.from_0'),
                url: t('app_store.woocommerce.url'),
                dkOnly: true
            },
            {
                icon: 'apps/dandomain.png',
                name: t('app_store.dandomain.name'),
                key: 'dandomain',
                description: t('app_store.dandomain.description'),
                price: t('app_store.from_0'),
                url: t('app_store.dandomain.url'),
                dkOnly: true
            },
            {
                icon: 'apps/wannafind.png',
                name: t('app_store.wannafind.name'),
                key: 'wannafind',
                description: t('app_store.wannafind.description'),
                price: t('app_store.from_0'),
                url: t('app_store.wannafind.url'),
                dkOnly: true
            },
            {
                icon: 'apps/hikashop.png',
                name: t('app_store.hikashop.2bs.name'),
                key: 'hikashop',
                description: t('app_store.hikashop.2bs.description'),
                price: t('app_store.hikashop.2bs.price'),
                url: t('app_store.hikashop.2bs.url'),
                dkOnly: true
            },
            {
                icon: 'apps/virtuemart.png',
                name: t('app_store.virtuemart.2app.name'),
                key: 'virtuemart',
                description: t('app_store.virtuemart.2app.description'),
                price: t('app_store.virtuemart.2app.price'),
                url: t('app_store.virtuemart.2app.url'),
                dkOnly: true
            },
            {
                icon: 'apps/salecto.png',
                name: t('app_store.salecto.storebuddy.name'),
                key: 'salecto',
                description: t('app_store.salecto.storebuddy.description'),
                price: t('app_store.from_0'),
                url: t('app_store.salecto.storebuddy.url'),
                dkOnly: true
            },
            {
                icon: 'apps/danhost.png',
                name: t('app_store.danhost.storebuddy.name'),
                key: 'danhost',
                description: t('app_store.danhost.storebuddy.description'),
                price: t('app_store.from_0'),
                url: t('app_store.danhost.storebuddy.url'),
                dkOnly: true
            },
            {
                icon: 'apps/sofier.png',
                name: t('app_store.sofier.name'),
                key: 'sofier-pos',
                description: t('app_store.sofier.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.sofier.url')
            },
            {
                icon: 'apps/rykkerportalen-advokaternes-inkasso-service.png',
                name: t('app_store.rykkerportalen.name'),
                key: 'rykkerportalen',
                description: t('app_store.rykkerportalen.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.rykkerportalen.url'),
                dkOnly: true
            },
            {
                icon: 'apps/kala.png',
                name: t('app_store.kala.name'),
                key: 'kala',
                description: t('app_store.kala.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.kala.url'),
                dkOnly: true
            },
            {
                icon: 'apps/danloen.png',
                name: t('app_store.danloen.name'),
                key: 'danlon',
                description: t('app_store.danloen.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.danloen.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/paylike.png',
                name: t('app_store.paylike.name'),
                key: 'paylike',
                description: t('app_store.paylike.description'),
                price: t('app_store.paylike.price'),
                installButton: 'app-store-install-button-paylike',
                highlighted: true
            },
            {
                icon: 'apps/apacta.png',
                name: t('app_store.apacta.name'),
                key: 'apacta',
                description: t('app_store.apacta.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.apacta.url'),
                dkOnly: true
            },
            {
                icon: 'apps/maguru-icon.png',
                name: t('app_store.maguru.name'),
                key: 'maguru',
                description: t('app_store.maguru.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.maguru.url'),
                dkOnly: true
            },
            {
                icon: 'apps/anttext.png',
                name: t('app_store.anttext.name'),
                key: 'ant-text',
                description: t('app_store.anttext.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.anttext.url'),
                dkOnly: true
            },
            {
                icon: 'apps/hairtools.png',
                name: t('app_store.hairtoolsskaludafbeta.name'),
                key: 'hairtools',
                description: t('app_store.hairtoolsskaludafbeta.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.hairtoolsskaludafbeta.url'),
                dkOnly: true
            },
            {
                icon: 'apps/mileage-book.png',
                name: t('app_store.mileage_book.name'),
                key: 'mileage-book',
                description: t('app_store.mileage_book.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.mileage_book.url'),
                highlighted: true,
                dkOnly: true
            },
            {
                icon: 'apps/ordrestyring.png',
                name: t('app_store.ordrestyring.name'),
                key: 'ordrestyring',
                description: t('app_store.ordrestyring.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.ordrestyring.url'),
                dkOnly: true
            },
            {
                icon: 'apps/2bs.png',
                name: t('app_store.2bs.finansposteringer.name'),
                key: 'twobs-journal-entries',
                description: t('app_store.2bs.finansposteringer.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.2bs.finansposteringer.url'),
                dkOnly: true
            },
            // {
            //     icon: 'apps/shopify.png',
            //     name: t('app_store.shopify.iex.name'),
            //     description: t('app_store.shopify.iex.description'),
            //     price: t('app_store.shopify.iex.price'),
            //     url: t('app_store.shopify.iex.url'),
            //     dkOnly: true
            // },
            {
                icon: 'apps/shopify.png',
                name: t('app_store.shopify.storebuddy.name'),
                key: 'shopify',
                description: t('app_store.shopify.storebuddy.description'),
                price: t('app_store.from_0'),
                url: t('app_store.shopify.storebuddy.url'),
                dkOnly: true
            },
            {
                icon: 'apps/360-business-tool.png',
                name: t('app_store.360businesstool.name'),
                key: 'threesixty-business-tool',
                description: t('app_store.360businesstool.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.360businesstool.url'),
                dkOnly: true
            },
            {
                icon: 'apps/iglobe.png',
                name: t('app_store.iglobe.name'),
                key: 'iglobe',
                description: t('app_store.iglobe.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.iglobe.url'),
                dkOnly: true
            },
            {
                icon: 'apps/intempus.png',
                name: t('app_store.intempus.name'),
                key: 'intempus',
                description: t('app_store.intempus.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.intempus.url'),
                dkOnly: true
            },
            {
                icon: 'apps/pakkelabels.png',
                name: t('app_store.pakkelabels.name'),
                key: 'pakkelabels',
                description: t('app_store.pakkelabels.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.pakkelabels.url'),
                dkOnly: true
            },
            {
                icon: 'apps/optipos.png',
                name: t('app_store.optipos.name'),
                key: 'optipos',
                description: t('app_store.optipos.description'),
                price: t('app_store.free_integration'),
                url: t('app_store.optipos.url'),
                dkOnly: true
            }
        ]
        return apps.filter(function(app) {
            return !!app
        })
    }.property('labsController.{pbs}'),

    apps: function() {
        var apps = this.get('allApps')
        var countryId = this.get('organization.country.id')
        if (this.get('highlighted')) {
            apps = apps.filterBy('highlighted')
        }
        if (countryId !== 'DK') {
            apps = apps.filter(function(app) {
                return !app.dkOnly
            })
        }
        return apps
    }.property('allApps', 'organization', 'highlighted')
})
