var t = require('i18n').t
var postingSides = require('posting-sides')

module.exports = require('./bank-sync-abstract-item').extend({
    subjectWrapper: function() {
        var cls
        var subject = this.get('subject')
        if (subject instanceof Billy.Invoice) {
            cls = InvoiceWrapper
        } else if (subject instanceof Billy.Bill) {
            cls = BillWrapper
        } else if (subject instanceof Billy.SalesTaxReturn) {
            cls = SalesTaxReturnWrapper
        } else if (subject instanceof Billy.Posting) {
            cls = PostingWrapper
        } else {
            throw new Error('Unknown subject type of class ' + subject.constructor.toString())
        }
        return cls.create({
            subject: subject
        })
    }.property('subject'),

    // Alias the wrapped subject's properties so that abstract-item can access them
    description: Em.computed.alias('subjectWrapper.description'),
    amount: Em.computed.alias('subjectWrapper.amount'),
    side: Em.computed.alias('subjectWrapper.side'),
    sideAmount: Em.computed.alias('subjectWrapper.sideAmount'),
    entryDate: Em.computed.alias('subjectWrapper.entryDate'),
    orderNo: Em.computed.alias('subjectWrapper.orderNo')
})

var BaseWrapper = Em.Object.extend({
    subject: null,

    sideAmount: function() {
        return (this.get('side.isCredit') ? -1 : 1) * this.get('amount')
    }.property('amount', 'side')
})

var InvoiceWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.genericDescription'),

    amount: Em.computed.alias('subject.balance'),

    side: function() {
        return this.get('subject.isCreditNote') ? postingSides.credit : postingSides.debit
    }.property('subject.isCreditNote'),

    entryDate: Em.computed.alias('subject.entryDate'),

    orderNo: Em.computed.alias('subject.orderNo')
})

var BillWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.genericDescription'),

    amount: Em.computed.alias('subject.balance'),

    side: function() {
        return this.get('subject.isCreditNote') ? postingSides.debit : postingSides.credit
    }.property('subject.isCreditNote'),

    entryDate: Em.computed.alias('subject.entryDate')
})

var SalesTaxReturnWrapper = BaseWrapper.extend({
    description: function() {
        var ret = this.get('subject')
        if (ret.get('organization.hasVat')) {
            return t('sales_tax_return.index.vat_title', { period: ret.get('periodText') })
        } else {
            return t('sales_tax_return.index.sales_tax_title', { period: ret.get('periodText') })
        }
    }.property('subject.organization.hasVat', 'subject.periodText'),

    amount: function() {
        return Math.abs(this.get('subject.report.result'))
    }.property('subject.report.result'),

    side: function() {
        return this.get('subject.report.result') > 0 ? postingSides.credit : postingSides.debit
    }.property('subject.report.result'),

    entryDate: Em.computed.alias('subject.reportDeadline')
})

var PostingWrapper = BaseWrapper.extend({
    description: Em.computed.alias('subject.transaction.originatorName'),

    amount: Em.computed.alias('subject.amount'),

    side: Em.computed.alias('subject.side'),

    entryDate: Em.computed.alias('subject.entryDate')
})
