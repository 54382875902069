module.exports = Em.Component.extend({
    classNames: ['transaction-trail'],

    originator: null,

    isLoaded: false,

    error: false,

    transactions: null,

    loadTransactions: function() {
        var self = this
        var originator = this.get('originator')
        Billy.Transaction.find({
            originatorReference: originator.get('reference'),
            sortProperty: 'transactionNo',
            withCreator: true
        }).promise
            .then(function(transactions) {
                self.set('transactions', transactions)
                    .set('isLoaded', true)
            }, function() {
                self.set('error', true)
            })
    }.on('didInsertElement')
})
