var Window = require('ember-window')

var BankFeeAccountWindow = module.exports = Window.extend({
    layout: Window.proto().layout,

    template: require('../templates/components/bank-fee-account-window'),

    classNames: ['bank-fee-account-window'],

    width: 600,

    bankFeeAccount: null,

    expenseAccountQuery: function() {
        return {
            nature: Billy.AccountNature.find('expense')
        }
    }.property(),

    actions: {
        select: function() {
            this.trigger('didSelectBankFeeAccount', this.get('bankFeeAccount'))
            this.close()
        }
    }
})

BankFeeAccountWindow.getDefaultBankFeeAccount = function(organization, container) {
    return new Em.RSVP.Promise(function(resolve, reject) {
        var bankFeeAccount = organization.get('defaultBankFeeAccount')
        if (bankFeeAccount) {
            resolve(bankFeeAccount)
            return
        }

        var w = container.lookup('component:bank-fee-account-window')
        w.on('didSelectBankFeeAccount', function(bankFeeAccount) {
            organization.set('defaultBankFeeAccount', bankFeeAccount)
            organization.save()
            resolve(bankFeeAccount)
        })
        w.on('didCancel', reject)
        w.show()
    })
}
