var storage = require('storage')

// to pass data to React please keep the keys list updated in
// react-app/src/modules/app/settings/features.ts
module.exports = Em.Controller.extend({
    fixedAssets: storageCp(),

    pbs: storageCp(),

    bankSyncV2: storageCp(),

    reactContacts: storageCp(),

    reactCreateContacts: storageCp(),

    reactBankReconciliation: storageCp(),

    salesTaxReturnWithSkatConnection: storageCp(),

    actions: {
        toggle: function(feature) {
            this.toggleProperty(feature)
        }
    }
})

function storageCp() {
    return Em.computed(function(key, value) {
        var storageKey = 'labs-flag:' + key
        if (arguments.length > 1) {
            if (value) {
                storage(storageKey, '1')
            } else {
                storage.remove(storageKey)
            }
        } else {
            value = storage(storageKey) === '1'
        }
        return value
    })
}
