var get = Em.get

module.exports = DS.RESTAdapter.extend({
    host: ENV.isTest ? '' : ENV.newApiUrl,

    headers: function() {
        var accessToken = this.container.lookup('api:billy').storageAdapter.getValue('accessToken')
        return {
            Authorization: 'Bearer ' + accessToken,
            Accept: 'application/vnd.billy.v2+json'
        }
    }.property().volatile(),

    // Just load it directly
    findHasMany: function(store, record, url) {
        return this.ajax(this.urlPrefix(url), 'GET')
    },

    // Use PATCH instead of PUT. That was the only change made here.
    updateRecord: function(store, type, record) {
        var data = {}
        var serializer = store.serializerFor(type.typeKey)

        serializer.serializeIntoHash(data, type, record)

        var id = get(record, 'id')

        return this.ajax(this.buildURL(type.typeKey, id, record), 'PATCH', { data: data })
    },

    ajax: function(url, method, options) {
        if (method === 'POST' || method === 'PATCH') {
            options.headers = options.headers || {}
            options.headers['Content-Type'] = 'application/vnd.billy.v2+json'
        }
        return this._super(url, method, options)
    },

    ajaxError: function(jqXHR) {
        var error = this._super(jqXHR)

        if (jqXHR && jqXHR.status === 422) {
            return new DS.InvalidError(Ember.$.parseJSON(jqXHR.responseText))
        } else {
            return error
        }
    }
})
