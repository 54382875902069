var f = require('float')
var roundExchangeRate = require('../utils/round-exchange-rate')

Billy.Currency.reopen({
    exchangeTo: function(amount, toCurrency) {
        return Billy.Currency.exchange(amount, this, toCurrency)
    },

    exchangeRateTo: function(toCurrency) {
        return Billy.Currency.getExchangeRate(this, toCurrency)
    }
})

Billy.Currency.reopenClass({
    exchange: function(amount, fromCurrency, toCurrency) {
        return f.round(amount * Billy.Currency.getExchangeRate(fromCurrency, toCurrency))
    },

    getExchangeRate: function(fromCurrency, toCurrency) {
        fromCurrency = (fromCurrency instanceof Billy.Currency) ? fromCurrency : Billy.Currency.find(fromCurrency)
        toCurrency = (toCurrency instanceof Billy.Currency) ? toCurrency : Billy.Currency.find(toCurrency)
        return roundExchangeRate(fromCurrency.get('exchangeRate') / toCurrency.get('exchangeRate'))
    }
})
