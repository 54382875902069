var t = require('i18n').t

module.exports = Em.Controller.extend({
    needs: ['user'],

    userOrganizations: Em.inject.service(),

    organizationName: Em.computed.alias('userOrganizations.bootstrappingOrganization.name'),

    text: function() {
        return t('user_loading.text', { name: this.get('organizationName') })
    }.property('organizationName')
})
