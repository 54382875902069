var i18n = require('i18n')
var t = i18n.t
var _ = require('lodash')
var popupWindow = require('../utils/popup-window')
var ListController = require('billy-webapp/ui/list/controller')
var ListItemController = require('billy-webapp/ui/list/item-controller')
var TableCellView = require('billy-webapp/ui/list/table/cell-view')
var ListAction = require('billy-webapp/ui/list/action')
var Column = require('billy-webapp/ui/list/columns/column')
var DateColumn = require('billy-webapp/ui/list/columns/date')
var MoneyColumn = require('billy-webapp/ui/list/columns/money')
var NOTIFICATION_KEYS = require('../notificationKeys')
var NOTIFICATION_VARIANT = require('../notificationVariant')

module.exports = ListController.extend({
    needs: ['account'],

    account: Em.computed.alias('controllers.account.model'),

    limit: 1000,

    sortProperty: 'entryDate',

    sortDirection: Em.computed.alias('controllers.account.sortDirection'),

    period: Em.computed.alias('controllers.account.period'),

    includeVoided: Em.computed.alias('controllers.account.includeVoided'),

    q: Em.computed.alias('controllers.account.q'),

    query: function() {
        var period = this.get('period')
        var q = this.get('q')

        var query = {
            sortDirection: this.get('sortDirection'),
            includeVoided: this.get('includeVoided')
        }
        if (period) {
            query.period = period.value
        }
        if (q) {
            query.q = q
        }
        return query
    }.property('period', 'includeVoided', 'q', 'sortDirection'),

    records: null,

    loadRecordsOnced: function() {
        var self = this
        var accountId = this.get('account.id')
        var query = this.get('query')
        var api = this.container.lookup('api:billy')

        if (!accountId) {
            return
        }

        var params = _.extend({
            limit: this.get('limit')
        }, query)

        this.set('isLoaded', false)
            .set('isTruncated', false)

        // Reset the records immediately if the last loaded records were from a different account
        if (accountId !== this.lastAccountId) {
            this.set('records', [])
            this.lastAccountId = accountId
        }

        api.get('/accounts/' + accountId + '/statement?' + $.param(params))
            .then(function(payload) {
                var accountStatement = payload.accountStatement
                self.set('records', accountStatement.rows)
                    .set('isLoaded', true)
                    .set('isTruncated', accountStatement.isTruncated)
            })
    },

    loadRecords: function() {
        Em.run.once(this, this.loadRecordsOnced)
    }.observes('query'),

    click: function(row) {
        if (row.transactionId) {
            var transaction = Billy.Transaction.find(row.transactionId)
            this.transitionToRoute('transaction', transaction)
        } else {
            this.container.lookup('util:notification').notify(NOTIFICATION_KEYS.ACCOUNT_POSTINGS_CLICK, t('account.postings.clicked_special'), NOTIFICATION_VARIANT.INFO)
        }
    },

    hasHeader: false,

    tableRowHeight: 56,

    itemControllerClass: ListItemController.extend({
        rowClass: function() {
            return this.get('isVoided') ? 'downtoned-text' : null
        }.property('isVoided'),

        originatorAndText: function() {
            var originatorName = this.get('originatorName') || ''
            var text = this.get('text') || ''
            if (originatorName === text) {
                return text
            }
            return originatorName + ((originatorName && text) ? ' - ' : '') + text
        }.property('originator', 'text'),

        hasGrossAmount: Em.computed.notEmpty('grossAmount'),

        hasBalance: Em.computed.notEmpty('balance')
    }),

    columns: function() {
        return [
            DateColumn.create({
                header: t('account.postings.entry_date'),
                name: 'entryDate',
                sortable: true,
                width: 120
            }),
            Column.create({
                header: t('account.postings.transaction_no'),
                name: 'transactionNo',
                width: 50
            }),
            Column.create({
                header: t('account.postings.voucher_no'),
                name: 'voucherNo',
                width: 50
            }),
            Column.create({
                header: t('account.postings.origin'),
                name: 'originatorAndText',
                flex: 1
            }),
            Column.create({
                header: t('account.postings.currency'),
                name: 'currencyId',
                width: 50
            }),
            MoneyColumn.create({
                name: 'grossAmount',
                header: t('account.postings.gross_amount'),
                width: 120,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if hasGrossAmount}}' +
                            '{{money grossAmount}}' +
                        '{{/if}}'
                    )
                })
            }),
            MoneyColumn.create({
                name: 'debit',
                header: t('account.postings.debit'),
                width: 120,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if debit}}' +
                            '{{money debit}}' +
                        '{{/if}}'
                    )
                })
            }),
            MoneyColumn.create({
                name: 'credit',
                header: t('account.postings.credit'),
                width: 120,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if credit}}' +
                            '{{money credit}}' +
                        '{{/if}}'
                    )
                })
            }),
            MoneyColumn.createWithMixins({
                accountPostingsController: this,
                name: 'balance',
                header: function() {
                    return t('account.postings.balance', { currency: this.get('accountPostingsController.account.currency.id') })
                }.property('accountPostingsController.account.currency'),
                width: 100,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if hasBalance}}' +
                            '{{money balance}}' +
                        '{{/if}}'
                    )
                })
            }),
            Column.create({
                name: 'note',
                header: t('account.postings.note'),
                width: 100,
                cellViewClass: TableCellView.extend({
                    template: Em.Handlebars.compile(
                        '{{#if isVoided}}' +
                            '{{t account.postings.voided}}' +
                            '{{/if}}'
                    )
                })
            })
        ]
    }.property(),

    listActions: [
        ListAction.create({
            icon: 'icons/duplicate',
            tip: t('account.postings.copy_balance'),
            click: 'copyBalanceToClipboard'
        })
    ],

    actions: {
        export: function(format) {
            // TODO: come up with a better solution
            var api = this.container.lookup('api:billy')
            var apiUrl = api.options.apiUrl
            var params = _.extend({
                accessToken: api.storageAdapter.getValue('accessToken'),
                acceptLanguage: i18n.locale()
            }, this.get('query'))
            popupWindow.open(apiUrl + '/accounts/' + this.get('account.id') + '/statement.' + format + '?' + $.param(params))
        },

        copyBalanceToClipboard: function(data) {
            var self = this

            navigator.clipboard.writeText(Billy.money(data.balance || 0))
                .then(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.CLIPBOARD, t('copied'))
                })
                .catch(function() {
                    self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.CLIPBOARD, t('copy_failed'))
                })
        }
    }
})
