var i18n = require('i18n')

module.exports = Em.Component.extend({
    classNames: ['ar-boolean-multiple-input-array'],

    annualReport: Em.inject.service(),

    api: Em.inject.service(),

    organizationOwners: null,

    item: null,

    title: i18n.tProperty('title'),

    selectedOrganization: null,

    setSelectedOrganization: function() {
        var selectedOrgId = this.get('item')[1].value
        var organizationOwners = this.get('organizationOwners')

        // default to first organization
        if (!selectedOrgId) {
            this.send('didSelectOrganization', organizationOwners[0])
        } else {
            this.set('selectedOrganization', organizationOwners.findBy('id', selectedOrgId))
        }
    }.on('didInsertElement'),

    actions: {
        didSelectOrganization: function(organization) {
            this.set('selectedOrganization', organization)
            var item = this.get('item')

            var answer = {
                name: item[1].name,
                value: organization.id
            }

            this.get('annualReport').putAnswer(item.year, [answer])
        }
    }
})
