var t = require('i18n').t
var Iso8601RepeatingInterval = require('../utils/iso-8601-repeating-interval')

module.exports = Em.Mixin.create({
    recurringStart: null,

    recurringEnd: null,

    recurringDurationCount: null,

    recurringDurationUnit: null,

    parseRecurringInterval: function() {
        if (this._isSerializingRecurringInterval) {
            return
        }
        var interval = Iso8601RepeatingInterval.parse(this.get('invoice.recurringInterval'))
        if (!interval.isValid()) {
            return
        }
        this._isParsingRecurringInterval = true
        this.set('recurringStart', interval.start)
        this.set('recurringEnd', interval.end)
        this.set('recurringDurationCount', interval.durationCount)
        this.set('recurringDurationUnit', interval.durationUnit)
        this._isParsingRecurringInterval = false
    }.on('init').observes('invoice.recurringInterval'),

    validateRecurringInterval: function() {
        var self = this
        var errors = this.get('errors')
        errors.remove('recurringStart')
        errors.remove('recurringEnd')
        errors.remove('recurringDurationCount')
        errors.remove('recurringDurationUnit')

        var isValid = true

        var fields = ['recurringStart', 'recurringDurationCount', 'recurringDurationUnit']
        fields.forEach(function(f) {
            if (!self.get(f)) {
                errors.add(f, t('required_field'))
                isValid = false
            }
        })

        var start = this.get('recurringStart')
        var end = this.get('recurringEnd')
        if (start && end && this.toTimeOfDay(start).isAfter(this.toTimeOfDay(end))) {
            errors.add('recurringEnd', t('recurring_interval_serializer.end_must_be_after_start'))
            isValid = false
        }

        if (this.get('recurringDurationCount') < 1) {
            // The spinner-field should take care of the error message
            isValid = false
        }

        return isValid
    },

    serializeRecurringInterval: function() {
        if (this._isParsingRecurringInterval) {
            return
        }
        this._isSerializingRecurringInterval = true

        var invoice = this.get('invoice')

        if (!this.validateRecurringInterval()) {
            invoice.set('recurringInterval', null)
        } else {
            var end = this.get('recurringEnd')
            var interval = new Iso8601RepeatingInterval({
                start: this.toTimeOfDay(this.get('recurringStart')).format(),
                // calculate end including the given date by using the .endOf('day')
                end: end && this.toTimeOfDay(end).endOf('day').format(),
                durationCount: this.get('recurringDurationCount'),
                durationUnit: this.get('recurringDurationUnit')
            })
            invoice.set('recurringInterval', interval.serialize())
        }

        this._isSerializingRecurringInterval = false
    }.observes('recurringStart', 'recurringEnd', 'recurringDurationCount', 'recurringDurationUnit'),

    toTimeOfDay: function(d) {
        return moment(d.format('YYYY-MM-DD') + 'T12:00:00')
    },

    recurringDurationUnitOptions: function() {
        var count = this.get('recurringDurationCount')
        var units = ['D', 'W', 'M', 'Y']
        return units.map(function(unit) {
            return Em.O({
                value: unit,
                name: t('recurring_interval_serializer.duration_unit.' + (count === 1 ? 'singular' : 'plural') + '.' + unit.toLowerCase())
            })
        })
    }.property('recurringDurationCount')
})
