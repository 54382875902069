var batmask = require('batmask')

module.exports = Em.Controller.extend({
    needs: ['organization'],

    paymentMethods: Ember.inject.service(),

    startGuide: Ember.inject.service(),

    organization: Ember.computed.alias('controllers.organization.model'),

    isInvoiceNoModeSequential: Ember.computed.equal('organization.invoiceNoMode', 'sequential'),

    bankAccount: function() {
        var paymentMethods = this.get('paymentMethods')
        var bankAccount = null

        if (!paymentMethods.get('isLoaded')) {
            return null
        }
        paymentMethods.get('data').find(function(paymentMethod) {
            if (paymentMethod.get('type') === 'bank') {
                bankAccount = paymentMethod.get('bankAccount')
                return true
            }
        })

        return bankAccount
    }.property('paymentMethods.data.@each.bankAccount'),

    actions: {
        save: function() {
            var self = this
            var organization = this.get('organization')
            var bankAccount = this.get('bankAccount')
            var promises = [
                organization.save()
            ]

            if (bankAccount) {
                promises.push(bankAccount.save())
            }

            batmask.maskDelayed()

            Em.RSVP.all(promises)
                .then(function() {
                    self.get('startGuide').completeStep()
                })
                .then(function() {
                    self.send('goToNextStep')
                })
                .finally(function() {
                    batmask.unmask()
                })
        },

        skip: function() {
            this.send('goToNextStep')
        }
    }
})
