var t = require('i18n').t
var InfiniteScrollController = require('../mixins/infinite-scroll-controller')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend(InfiniteScrollController, {
    needs: ['bankAccount', 'user'],

    account: Em.computed.alias('controllers.bankAccount.model'),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    sortProperty: 'entryDate',

    sortDirection: 'DESC',

    remoteHasMore: false,

    entryDatePeriod: null,

    q: null,

    isLoaded: false,

    query: function() {
        var q = this.get('q')

        var query = {
            sortDirection: this.get('sortDirection'),
            includeVoided: false
        }

        if (q) {
            query.q = q
        }

        return query
    }.property('q', 'sortDirection'),

    content: null,

    loadRecordsOnce: function() {
        var self = this
        var accountId = this.get('account.id')
        var sortProperty = this.get('sortProperty')
        var findObject = null

        if (!accountId) {
            return
        }

        this.set('isLoaded', false)

        // Reset the records immediately if the last loaded records were from a different account
        if (accountId !== this.lastAccountId) {
            this.set('content', [])
            this.lastAccountId = accountId
        }

        findObject = {
            accountId: accountId,
            isApproved: true,
            sortProperty: 'entryDate',
            include: 'bankLineMatch.lines,bankLineMatch.subjectAssociations,bankLineSubjectAssociation.subject',
            pageSize: module.exports.maxMatches
        }

        if (self.get('entryDatePeriod.value')) {
            findObject.entryDatePeriod = self.get('entryDatePeriod.value')
        }

        if (self.get('q')) {
            findObject.q = self.get('q')
        }

        Billy.BankLineMatch.find(findObject)
            .promise
            .then(function(payload) {
                var matchesRecordArray = payload
                var matches = matchesRecordArray.get('content').copy() // we just want the raw array, not the BD.RecordArray
                var model = Em.ArrayController.create({
                    container: self.container,
                    parentController: self,
                    model: matches,
                    itemController: 'bank-sync-match',
                    sortProperties: [sortProperty + 'Local']
                })

                self.set('content', model)
                    .set('isLoaded', true)
                    .set('remoteHasMore', matches.get('length') < matchesRecordArray.get('paging.total'))
                matchesRecordArray.destroy()
            }, function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_LINES_LOAD, t('util.request.default_error'))
                self.set('isLoaded', true)
            })
    }.observes('sortProperty', 'q', 'entryDatePeriod'),

    loadRecords: function() {
        Em.run.once(this, this.loadRecordsOnce)
    }.observes('query'),

    unapproveMatch: function(match) {
        var model = this.get('content.model')

        match.set('isApproved', false)

        return match.save()
            .then(function() {
                model.removeObject(match)
            }, function(e) {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_LINES_MATCH_UNAPPROVE, e.message)
                throw e
            })
    },

    actions: {
        backToReconcile: function() {
            this.transitionToRoute('bank_sync')
        }
    }
})

module.exports.maxMatches = 200
