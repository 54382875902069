var _ = require('lodash')

module.exports = Em.Route.extend({
    segment: Em.inject.service(),

    organizationFeatures: Em.inject.service(),

    beforeModel: function(transition) {
        var self = this
        var billIdToCreateCreditNote = _.get(transition, 'params.bill.bill_id')

        if (billIdToCreateCreditNote) {
            self.transitionTo('bills', {
                queryParams: _.assign(
                    { initialRoute: '/create-credit-note/' + billIdToCreateCreditNote }
                )
            })
        }
    }
})
