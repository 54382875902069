var Window = require('ember-window')

module.exports = Window.extend({
    classNames: ['approve-financing-success-modal'],

    template: require('../templates/components/approve-financing-success-modal'),

    userOrganizations: Em.inject.service(),

    organization: Em.computed.alias('userOrganizations.activeOrganization'),

    modalVariant: 'approveFinancingSuccessModal',

    applyModalMask: false,

    customActions: {
        navigate: function(e) {
            var self = this
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.close()

            this.one('didClose', function() {
                self.container.lookup('router:main').transitionTo(route, props)
            })
        },

        closeEmber: function(e) {
            this.close()
        }
    }
})
