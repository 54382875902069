var i18n = require('i18n')
var t = i18n.t

module.exports = Em.Component.extend({
    layoutName: 'components/dropdown-button',

    classNames: ['item', 'button-group'],

    align: 'right',

    dropdownMenuClass: function() {
        return this.get('align')
    }.property('align'),

    text: t('exports.as'),

    icon: null,

    actions: {
        primary: function() {
            this.sendAction('primaryAction')
        }
    }
})
