var Window = require('ember-window')

module.exports = Window.extend({
    layout: require('../templates/components/send-invoice-modal'),

    applyModalMask: false,

    userOrganizations: Em.inject.service(),

    templates: Ember.inject.service(),

    organization: Em.computed.oneWay('userOrganizations.activeOrganization'),

    sendAsEInvoice: 'true',

    invoiceId: '',

    contactId: '',

    contactCVR: '',

    emailSubject: '',

    emailMessage: '',

    contactEAN: '',

    selectedContactPersonId: '',

    onInvoiceSentCallback: null,

    onInvoiceSent: function(callback) {
        this.set('onInvoiceSentCallback', callback)
    },

    customActions: {
        onInvoiceSent: function(event) {
            if (typeof this.onInvoiceSentCallback === 'function') {
                this.onInvoiceSentCallback(event.detail.invoiceWasSentVia)
            }
        },

        closeEmber: function(e) {
            this.close()
        },

        navigate: function(e) {
            var self = this
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.close()

            this.one('didClose', function() {
                self.container.lookup('router:main').transitionTo(route, props)
            })
        }

    }
})
