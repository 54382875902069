var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    needs: ['organization', 'exports'],

    organization: Em.computed.oneWay('controllers.organization.model'),

    isUploading: false,

    isReloading: false,

    isWorking: Em.computed.or('isUploading', 'isReloading'),

    error: null,

    helpUrl: function() {
        return t('accounts_import.help_url')
    }.property('organization'),

    uploadUrl: function() {
        return ENV.apiUrl + '/organizations/' + this.get('organization.id') + '/chartOfAccounts'
    }.property('organization'),

    reloadModel: function(type, name) {
        BD.store.unloadAllByType(type)
        var organizationId = this.get('organization.id')
        var api = this.container.lookup('api:billy')
        return api.get('/' + name + '?organizationId=' + organizationId)
            .then(function(payload) {
                BD.store.loadAll(type, payload[name])
            })
    },

    actions: {
        exportChartOfAccounts: function() {
            this.get('controllers.exports').send('exportChartOfAccounts', 'xlsx')
        },

        uploadFailed: function(context) {
            this.set('error', context.payload && context.payload.errorMessage)
        },

        didUploadFile: function() {
            var self = this
            this.set('isReloading', true)
            this.set('error', null)
            Em.RSVP.all([
                this.reloadModel(Billy.Account, 'accounts'),
                this.reloadModel(Billy.AccountGroup, 'accountGroups')
            ])
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.ACCOUNTS_IMPORT_FILE_UPLOAD, t('accounts_import.import_success'))
                    self.transitionToRoute('accounts')
                })
                .finally(function() {
                    self.set('isReloading', false)
                })
        }
    }
})
