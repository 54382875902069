module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')

        return Object.assign({
            missingAccounts: this.get('annualReport').getMissingAccounts(model.commitment)
        }, model)
    },

    afterModel: function(model) {
        var commitment = model.commitment
        if (commitment.state === 'complete') {
            this.replaceWith('annual_report.report_processing')
        }
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
        controller.set('years', model.years)
        controller.set('commitment', model.commitment)
        controller.set('missingAccounts', model.missingAccounts)
    }
})
