var AttachmentsList = require('./attachments-list')

module.exports = AttachmentsList.extend({
    layoutName: 'components/attachments-list-constrained',

    isDisabled: function() {
        var constraints = this.container.lookup('service:constraints')
        return !constraints.hasScope('transaction_attachment')
    }.property(),

    isUploadButtonDisabled: function() {
        return this.get('isDisabled')
    }.property(),

    isFileUploadAllowed: function() {
        return !this.get('isDisabled')
    }.property()
})
