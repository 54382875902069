module.exports = Em.Mixin.create({
    annualReport: Em.inject.service(),

    /**
     * @returns {AnnualReportStepEnum}
     */
    getNextStep: function() {
        return this.get('annualReport').getNextStep(this.get('annualReport.activeStep'))
    },

    /**
     * @returns {boolean}
     */
    isActiveStepCompleted: function() {
        return this.get('annualReport')
            .isStepCompleted(this.get('annualReport.activeStep'), this.get('model.commitment'))
    },

    actions: {
        /**
         * @returns {void}
         */
        navigateToNextStep: function() {
            this.transitionToRoute('annual_report.' + this.getNextStep())
        }
    }
})
