var i18n = require('i18n')

module.exports = Em.Component.extend({
    annualReport: Em.inject.service(),

    classNames: ['ar-text-input'],

    error: null,

    year: null,

    multiline: false,

    item: null,

    label: i18n.tProperty('label'),

    placeholder: i18n.tProperty('placeholder'),

    initValue: function() {
        var value = this.get('item.value')
        this.set('item.value', value ? value.trim() : '')
    }.on('didInsertElement'),

    actions: {
        saveValue: function(value, context) {
            if (context.error) {
                return
            }

            var property = {
                name: this.get('item.name'),
                value: this.get('item.value')
            }

            this.get('annualReport').putAnswer(this.get('year'), [property])
        }
    }
})
