module.exports = Ember.Component.extend({
    layoutName: 'components/stacked-bar-chart',

    classNames: ['stacked-bar-chart'],

    attributeBindings: ['style'],

    height: 300,

    style: function() {
        return 'height:' + this.get('height') + 'px'
    }.property('height'),

    _popoverIsVisible: false,

    _popover: null,

    _setupChart: function() {
        var self = this

        var c = this._chart = createChart(this.get('element'), this.get('currency'))

        c.on('categoryMouseEnter', function(category, x, y) {
            clearTimeout(self._togglePopoverTimeout)
            self._showPopover(category, null, x, y)
        })

        c.on('itemMouseMove', function(item, x, y) {
            clearTimeout(self._togglePopoverTimeout)
            self._showPopover(null, item, x, y)
        })

        c.on('itemMouseLeave', function() {
            if (self._popoverIsVisible) {
                self._togglePopoverTimeout = setTimeout(function() {
                    self._hidePopover()
                }, 100)
            }
        })

        c.on('itemClick', function(item) {
            if (['invoice', 'bill'].contains(item.type)) {
                self.get('parentController').transitionToRoute(item.type, Billy[item.type.classify()].find(item.id))
            }
        })
    }.on('didInsertElement'),

    _tearDownChart: function() {
        this._hidePopover()
    }.on('willDestroyElement'),

    _dataDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this._redrawChart)
    }.observes('data').on('didInsertElement'),

    _redrawChart: function() {
        this._chart.redraw(this.get('data') || [])
    },

    _showPopover: function(category, item, x, y) {
        var self = this
        var p = this._popover

        Em.run(function() {
            if (!p) {
                p = self.container.lookup('component:stacked-bar-chart-popover')
                self._popover = p
                self._popoverIsVisible = true
            }
            p.set('category', category)
            p.set('item', item)
            p.set('currency', self.get('currency'))
        })

        Em.run(function() {
            p.showAt(x, y)
        })
    },

    _hidePopover: function() {
        var self = this
        Em.run(function() {
            if (self._popover) {
                self._popover.destroy()
                self._popover = null
                self._popoverIsVisible = false
            }
        })
    }
})

function createChart(parentEl, currency) {
    var ret = Em.Object.createWithMixins(Em.Evented)
    var $parentEl = $(parentEl)
    var w = $parentEl.width()
    var h = $parentEl.height()
    var topPadding = 20
    var bottomPadding = 20
    var axisSize = 1
    var yTickSize = 3
    var yAxisSpacing = 20
    var ySpacing = 1
    var fontSize = 11
    var categoryLabelSize = 15
    var periodSeparatorSize = 1
    var xAxisLabelY = h - (bottomPadding - fontSize) / 2 - 2
    var duration = 500

    var x = xScale()
        .rangeRoundBands([0, w - axisSize - yTickSize - yAxisSpacing], 0.25)

    var y = d3.scale.linear()
        .range([0, h - topPadding - axisSize - bottomPadding])
        .interpolate(d3.interpolateRound)

    var chart = d3.select(parentEl).append('svg')
        .attr('class', 'chart')
        .attr('width', w)
        .attr('height', h)

    // X axis
    chart.append('rect')
        .attr('class', 'axis')
        .attr('x', 0)
        .attr('y', h - bottomPadding - axisSize)
        .attr('width', w)
        .attr('height', axisSize)

    // Y axis
    chart.append('rect')
        .attr('class', 'axis')
        .attr('x', 0)
        .attr('y', topPadding)
        .attr('width', axisSize)
        .attr('height', h - topPadding - bottomPadding)

    // Body
    var body = chart.append('g')
        .attr('transform', 'translate(' + (axisSize + yTickSize + yAxisSpacing) + ', 0)')

    // Y ticks
    var yTick = chart.selectAll('rect.y-tick')
        .data([0.25, 0.5, 0.75, 1])
    yTick.enter()
        .append('rect')
        .attr('class', 'y-tick')
        .attr('x', axisSize)
        .attr('y', function(d) { return topPadding + (1 - d) * (h - topPadding - bottomPadding) })
        .attr('width', yTickSize)
        .attr('height', 1)

    // Y max label
    var yMaxText = chart.append('text')
        .attr('class', 'y-max-label')
        .attr('x', 0)
        .attr('y', topPadding - (topPadding - fontSize) / 2)
        .attr('text-anchor', 'start')

    // First period label
    var firstPeriodLabel = chart.append('text')
        .attr('class', 'period-label')
        .attr('x', 0)
        .attr('y', xAxisLabelY)
        .attr('text-anchor', 'start')

    var categoryTransform = function(d) {
        return 'translate(' + x(d) + ', 0)'
    }

    var itemY = function(d) {
        return h - bottomPadding - axisSize - y(d.value) - y(d.value0)
    }

    var itemEnterY = function(d, i) {
        var ret = h - bottomPadding - axisSize - (i + 1) * ySpacing
        if (i > 0) {
            var below = d.category.items[i - 1]
            var extra = y(below.value0) + y(below.value) - ySpacing
            ret -= extra
        }
        return ret
    }

    var itemHeight = function(d) {
        return y(d.value) - ySpacing
    }

    ret.redraw = function(data) {
        // Calculate stack baselines (value0)
        data.forEach(function(category) {
            var value0 = 0
            category.items.forEach(function(item) {
                item.value0 = value0
                item.category = category
                value0 += item.value
            })
        })

        // Update x domain
        x.domain(data)

        // Update y domain
        var yDomain = niceDomain(data)
        y.domain(yDomain)

        // Update y max label
        var yMax = y.domain()[1]
        yMaxText.text(Billy.money(yMax, currency, yMax > 9999 ? '0a' : '0,0'))

        // Add category (month/draft)
        var category = body.selectAll('g.category')
            .data(data, function(d) { return d.id })
        var categoryIsEmpty = category.empty()
        category.transition()
            .duration(duration)
            .attr('transform', categoryTransform)
        category.enter()
            .append('g')
            .attr('class', 'category')
            .attr('transform', categoryTransform)
        category.exit()
            .remove()

        // Add rect for each item in each category
        var item = category.selectAll('rect.item')
            .data(function(d) { return d.items }, function(d) { return d.id })
        item.transition()
            .duration(duration)
            .attr('y', itemY)
            .attr('height', itemHeight)
        var itemEnter = item.enter()
            .append('rect')
            .attr('class', function(d) {
                return 'item ' + d.color
            })
            .attr('x', 0)
            .attr('y', itemEnterY)
            .attr('width', x.rangeBand())
            .attr('height', 0)
            .style('z-index', 2)
            .on('mousemove', function(d) {
                var el = $(this)
                ret.trigger('itemMouseMove', d, el.offset().left + el.attr('width') / 2, d3.event.pageY - 10)
            })
            .on('mouseleave', function() {
                ret.trigger('itemMouseLeave')
            })
            .on('click', function(d) {
                ret.trigger('itemClick', d)
            })
        if (!categoryIsEmpty) {
            itemEnter = itemEnter.transition()
                .duration(duration)
        }
        itemEnter
            .transition()
            .duration(duration)
            .attr('y', itemY)
            .attr('height', itemHeight)
        item.exit()
            .style('z-index', 1)
            .transition()
            .duration(duration / 2)
            .style('opacity', 0)
            .remove()

        // Add label for each category
        var categoryLabel = category.append('g')
            .attr('class', function(d) {
                var c = ['category-label']
                if (d.type === 'month') {
                    var now = moment()
                    var date = moment(d.id + '-01')
                    if (now.isSame(date, 'month')) {
                        c.push('current')
                    } else if (now.isBefore(date, 'month')) {
                        c.push('future')
                    }
                } else {
                    c.push('future')
                }
                return c.join(' ')
            })
            .style('opacity', 0)
            .on('mouseenter', function(d) {
                var offset = $(this).offset()
                var rect = this.getBoundingClientRect()
                ret.trigger('categoryMouseEnter', d, offset.left + rect.width / 2, offset.top)
            })
            .on('mouseleave', function() {
                ret.trigger('itemMouseLeave')
            })
        categoryLabel.transition()
            .duration(duration)
            .style('opacity', 1)
        categoryLabel.append('rect')
            .attr('x', (x.rangeBand() - categoryLabelSize) / 2)
            .attr('y', h - bottomPadding + (bottomPadding - categoryLabelSize) / 2)
            .attr('width', categoryLabelSize)
            .attr('height', categoryLabelSize)
        categoryLabel.append('text')
            .attr('x', x.rangeBand() / 2)
            .attr('y', xAxisLabelY)
            .attr('text-anchor', 'middle')
            .text(function(d) {
                return d.type === 'month' ? moment(d.id + '-01').format('MMMM').substring(0, 1) : '?'
            })

        // Update period separators
        var periods = x.periods()
        var firstPeriod = periods.shift()
        var periodSeparator = body.selectAll('rect.period-separator')
            .data(periods, function(d) { return d.text })
        periodSeparator
            .transition()
            .duration(duration)
            .attr('x', function(d) { return d.x })
        periodSeparator.enter()
            .append('rect')
            .attr('class', 'period-separator')
            .attr('x', function(d) { return d.x })
            .attr('y', topPadding)
            .attr('width', periodSeparatorSize)
            .attr('height', h - topPadding - axisSize - bottomPadding)
        periodSeparator.exit()
            .remove()

        // Update period labels
        var periodLabel = body.selectAll('text.period-label')
            .data(periods, function(d) { return d.text })
        periodLabel
            .transition()
            .duration(duration)
            .attr('x', function(d) { return d.x })
        periodLabel.enter()
            .append('text')
            .attr('class', 'period-label')
            .attr('x', function(d) { return d.x })
            .attr('y', topPadding - (topPadding - fontSize) / 2)
            .attr('text-anchor', 'start')
            .text(function(d) {
                return d.text
            })
        periodLabel.exit()
            .remove()

        // Update first period
        firstPeriodLabel.text(firstPeriod ? firstPeriod.text : '')
    }

    return ret
}

function xScale() {
    var minBarWidth = 2
    var maxBarWidth = 20
    var barWidth = null
    var spacing = 7
    var start
    var end
    var domain = null

    var ret = function(d) {
        var index = domain.indexOf(d)
        return index * barWidth + (index + 1) * spacing
    }

    ret.rangeRoundBands = function(interval) {
        if (arguments.length > 0) {
            start = interval[0]
            end = interval[1]
            return this
        } else {
            return [start, end]
        }
    }

    ret.rangeBand = function() {
        return barWidth
    }

    ret.domain = function(d) {
        if (arguments.length > 0) {
            domain = d
            barWidth = Math.max(minBarWidth, Math.min(maxBarWidth, Math.floor((end - start - spacing * (domain.length + 1)) / domain.length)))
            return this
        } else {
            return domain
        }
    }

    ret.periods = function() {
        var periods = []
        var lastYear = null
        domain.forEach(function(d, index) {
            var text = null
            if (d.type === 'month') {
                var year = d.id.split('-', 2)[0]
                if (year !== lastYear) {
                    text = year
                    lastYear = year
                }
            } else if (d.type === 'special') {
                text = 'Draft'
            }
            if (text) {
                periods.push({
                    x: index * barWidth + Math.floor((index + 0.5) * spacing),
                    text: text
                })
            }
        })
        return periods
    }

    return ret
}

// Will return the optimal interval (0 to a number that starts with an even number and only has zeroes after that)
function niceDomain(data) {
    // Find max value
    var max = d3.max(data, function(d) {
        return d.items.reduce(function(total, item) {
            return total + item.value
        }, 0)
    })

    if (!max) {
        return [0, 0]
    }

    // Subtract one to support max values that touch the top
    max *= 1.05

    // Find multiplier and exponent
    var multiplier = max
    var exponent = 0

    while (multiplier >= 10) {
        multiplier = multiplier / 10
        exponent++
    }

    // Find best option
    var evenNumbers = [
        2,
        4,
        6,
        8,
        10
    ]
    var bestNumber = evenNumbers.find(function(n) {
        return multiplier < n
    })

    return [0, bestNumber * Math.pow(10, exponent)]
}
