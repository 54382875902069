var Service = {
    // Invoices
    paylike: 'paylike',
    invoice_financing: 'invoice_financing',
    // Control books
    momsintegration: 'momsintegration',
    annual_report_diy: 'annual_report_diy',
    annual_report_get_help: 'annual_report_get_help',
    find_bookkeeper: 'find_bookkeeper',
    // Other
    salary: 'salary',
    get_loan: 'get_loan'
}

module.exports = Em.Controller.extend({
    needs: ['user'],

    queryParams: [
        'mobilepaystatus',
        'mobilepayerror'
    ],

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    paymentMethods: Ember.inject.service(),

    ais: Ember.inject.service(),

    executeCallback: function(callback) {
        return function() {
            if (callback) {
                callback()
            }
        }
    },

    customActions: {
        activateIntegration: function(e) {
            var self = this
            var integrationName = e.detail.integration
            var callback = e.detail.callback

            switch (integrationName) {
            case Service.paylike:
                return self.container.lookup('component:app-store-install-button-paylike')
                    .auth()
                    .then(self.executeCallback(callback))
            }
        },

        deactivateIntegration: function(e) {
            var self = this
            var integrationName = e.detail.integration
            var callback = e.detail.callback

            switch (integrationName) {
            case Service.paylike:
                return self.container.lookup('component:app-store-install-button-paylike')
                    .auth()
                    .then(self.executeCallback(callback))
            }
        },

        navigate: function(e) {
            var route = e.detail.route
            var props = e.detail.props || { queryParams: {} }

            this.transitionToRoute.apply(this, [route].concat(props || []))
        },

        reloadPaymentMethods: function(e) {
            var paymentMethods = this.get('paymentMethods')
            paymentMethods.loadSafe()
        }
    }
})
