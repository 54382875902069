var Window = require('ember-window')

module.exports = Window.extend({
    api: Ember.inject.service(),

    layout: Window.proto().layout,

    template: require('../templates/components/template-preview-window'),

    width: 800,

    top: 40,

    closable: true,

    externalCloseIcon: true,

    isLoading: true,

    html: null
})
