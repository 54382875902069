var t = require('i18n').t

module.exports = Em.Component.extend({
    classNames: ['progress-list-item'],

    tagName: 'li',

    location: Em.inject.service(),

    annualReport: Em.inject.service(),

    classNameBindings: ['step.completed:is-completed:is-locked', 'isActive', 'lockList', 'isCurrent'],

    /**
     * @property {AnnualReportStep}
     */
    step: null,

    /**
     * @property {string}
     */
    currentStep: null,

    /**
     * @property {AnnualReportModel}
     */
    model: null,

    /**
     * @property {AnnualReportCommitment}
     */
    commitment: Em.computed.alias('model.commitment'),

    lockList: null,

    text: function() {
        return t('annual_report.progress_list.' + this.get('step.route') + '.title')
    }.property('step.route'),

    shouldLink: function() {
        return !this.get('annualReport').isSubmittedState(this.get('commitment.state')) &&
            this.get('enableRouting') &&
            !this.get('step.doTransition') &&
            this.get('step.settings.canBeRevisited')
    }.property('enableRouting', 'step.doTransition', 'commitment.state'),

    isActive: function() {
        var activeRoute = this.get('location.pathname').split('/').pop()
        return activeRoute === this.get('step.route')
    }.property('step.route', 'location.pathname'),

    isCurrent: function() {
        return this.get('step.route') === this.get('currentStep')
    }.property('step.route', 'currentStep'),

    enableRouting: function() {
        if (this.get('lockList')) {
            return false
        }

        return this.get('step.completed') || this.get('currentStep') === this.get('step.route')
    }.property('currentStep', 'step.completed', 'step.route', 'lockList'),

    actions: {
        doTransition: function() {
            var transitionArgs = this.get('step.doTransition')
            if (!transitionArgs) {
                transitionArgs = [this.get('step.completeRoute'), this.get('model.commitment.year')]
            }

            this.container.lookup('router:main').send('invalidateModel')
            this.container.lookup('router:main').transitionTo(
                transitionArgs[0],
                transitionArgs[1]
            )
        }
    }
})
