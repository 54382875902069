var tProperty = require('i18n').tProperty

module.exports = Em.Component.extend({
    classNames: 'sort-tool',

    classNameBindings: ['isActive:active', 'isDesc:sort-tool-desc'],

    property: null,

    sortProperty: null,

    sortDirection: null,

    isDesc: Em.computed.equal('sortDirection', 'DESC'),

    text: tProperty('text'),

    position: 'right',

    isRight: Em.computed.equal('position', 'right'),

    isActive: function() {
        return this.get('property') === this.get('sortProperty')
    }.property('property', 'sortProperty'),

    actions: {
        toggle: function() {
            var property = this.get('property')
            var dir = 'ASC'
            if (property === this.get('sortProperty')) {
                dir = this.get('sortDirection') === 'ASC' ? 'DESC' : 'ASC'
            }
            this.set('sortProperty', property)
                .set('sortDirection', dir)
        }
    }
})
