module.exports = Em.Component.extend({
    classNames: ['credit-card'],

    cardSubscription: null,

    showControls: false,

    mouseEnter: function() {
        this.set('showControls', true)
    },

    mouseLeave: function() {
        this.set('showControls', false)
    },

    isDefault: function() {
        return this.get('cardSubscription.isDefault')
    }.property('cardSubscription.isDefault'),

    actions: {
        didClickMakeDefault: function() {
            this.sendAction('didClickMakeDefault', this.get('cardSubscription.id'))
        },

        didCancelCard: function() {
            this.sendAction('didCancelCard', this.get('cardSubscription.id'))
        }
    }
})
