module.exports = require('./abstract-annual-report').extend({
    model: function() {
        var model = this.modelFor('annual_report')
        var organization = this.controllerFor('user').get('activeOrganization')

        return Em.RSVP.hash(Object.assign({
            owner: Billy.OrganizationOwner.findByQuery({
                organizationId: organization.get('id'),
                include: 'organizationOwner.city,organizationOwner.zipcode'
            }).promise
        }, model))
    },

    afterModel: function(model) {
        model.organizationOwners = model.owner.map(function(owner) {
            owner.flagIcon = Billy.flagIcon(owner.get('country.icon'))

            if (owner.get('startDate')) {
                owner.startDateText = owner.get('startDate').format('DD-MM-YYYY')
            }

            var address = {}

            address.city = owner.get('city') || owner.get('cityText')
            address.zipcode = owner.get('zipcode') || owner.get('zipcodeText')

            if (address.city && typeof address.city === 'object') {
                address.city = address.city.get('name')
            }

            if (address.zipcode && typeof address.zipcode === 'object') {
                address.zipcode = address.zipcode.get('zipcode')
            }

            owner.address = address

            return owner
        })
    },

    setupController: function(controller, model) {
        controller.set('model', model)
        controller.set('organizationOwners', model.organizationOwners)
        controller.set('steps', model.steps)
        controller.set('currentStep', model.currentStep)
    }
})
