var AuthorizedRouteLegacy = require('../mixins/authorized-route-legacy')
var Scope = require('../utils/scope')

module.exports = Em.Route.extend(AuthorizedRouteLegacy, {
    scopes: [Scope.BankReconciliationWrite],

    queryParams: {
        period: { replace: true },
        sort: { replace: true },
        matchesQ: { replace: true },
        approved: { replace: true },
        matched: { replace: true },
        unmatched: { replace: true },
        subjectsQ: { replace: true },
        invoices: { replace: true },
        bills: { replace: true },
        postings: { replace: true },
        paid: { replace: true },
        unpaid: { replace: true },
        draft: { replace: true }
    },

    model: function() {
        return this.modelFor('bank_account')
    },

    setupController: function(controller, model) {
        controller.set('model', model)
    }
})
