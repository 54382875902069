var t = require('i18n').t
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = require('./bank-sync-abstract-subject-item').extend({
    needs: ['bankSyncPossibleSubjects'],

    canBeRemoved: true,

    label: function() {
        var subject = this.get('subject')
        if (!(subject instanceof Billy.Posting)) {
            return t('bank_sync.unpaid_label')
        }
    }.property('subject'),

    actions: {
        remove: function() {
            var self = this
            var subjectAssociation = this.get('model')
            var subject = subjectAssociation.get('subject')
            var possibleSubjectsController = this.get('controllers.bankSyncPossibleSubjects')
            possibleSubjectsController.addSubject(subject)
            subjectAssociation.deleteRecord()
                .then(null, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_SYNC_MATCH_REMOVE, e.message)
                    subjectAssociation.rollback()
                    possibleSubjectsController.removeSubject(subject)
                })
        }
    }
})
