module.exports = Em.Component.extend({
    layoutName: 'components/address-editor',

    classNames: ['field-group'],
    record: null,

    allowStringValues: true,

    zipcodeObserver: function() {
        var zipcode = this.get('record.zipcode')
        if (!zipcode) {
            return
        }
        this.set('record.city', zipcode.get('city'))
        this.set('record.state', zipcode.get('state'))
    }.observes('record.zipcode'),

    cityObserver: function() {
        var city = this.get('record.city')
        var zipcode = this.get('record.zipcode')
        if (!city) {
            this.set('record.zipcode', null)
            return
        }
        if (zipcode && zipcode.get('city') !== city) {
            this.set('record.zipcode', null)
        }
        this.set('record.state', city.get('state'))
    }.observes('record.city'),

    stateObserver: function() {
        var country = this.get('record.country')
        var state = this.get('record.state')
        var city = this.get('record.city')
        var zipcode = this.get('record.zipcode')
        if (!country || !country.get('hasStates')) {
            return
        }
        if (!state) {
            this.set('record.zipcode', null)
            this.set('record.city', null)
            return
        }
        if (zipcode && zipcode.get('state') !== state) {
            this.set('record.zipcode', null)
        }
        if (city && city.get('state') !== state) {
            this.set('record.city', null)
        }
    }.observes('record.state'),

    countryObserver: function() {
        var record = this.get('record')
        if (!record) {
            return
        }
        var country = record.get('country')
        if (!country) {
            return
        }
        record.set('city', null)
        record.set('cityText', null)
        record.set('state', null)
        record.set('stateText', null)
        record.set('zipcodeText', null)
        record.set('zipcode', null)
        record.set('errors.zipcode', null)
    }.observesBefore('record.country'),

    cityFirst: function() {
        return this.get('organization.country.id') === 'US'
    }.property('organization.country')
})
